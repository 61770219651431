import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { SupplierSearchCriteria } from './models/SupplierSearchCriteria';
import { SupplierDto } from './models/SupplierDto';
import CustomFile from 'common/models/CustomFile';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { CatalogSupplierSearchCriteria } from 'api/catalog/models/CatalogSupplierSearchCriteria';

class SuppliersService {

    public getList(criteria: SupplierSearchCriteria) {
        return Http.get<Paged<SupplierDto>>('suppliers', criteria);
    }
    
    public catalog(criteria?: CatalogSupplierSearchCriteria) {
        return Http.get<SelectValueLabel[]>('suppliers/catalog', criteria);
    }

    public getById(id: string) {
        return Http.get<SupplierDto>('suppliers/' + id);
    }

    public getFuelSuppliers(criteria?: CatalogSupplierSearchCriteria) {
        return Http.get<SelectValueLabel[]>('suppliers/fuel-suppliers', criteria);
    }

    public create(model: SupplierDto, logo: CustomFile[]) {
        return Http.postWithFile<string>('suppliers', model, logo);
    }

    public update(model: SupplierDto, logo: CustomFile[]) {
        return Http.putWithFile('suppliers', model, logo);
    }

    public remove(model: SupplierDto) {
        return Http.put('suppliers/deactivate', model);
    }
}

export default new SuppliersService();