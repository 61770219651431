import React, { useRef } from 'react';
import DateTimePicker, { Props as DateTimePickerProps } from './DateTimePicker';
import { Controller, RegisterOptions, UseFormMethods } from 'react-hook-form';
import { DATEPICKER_FORMAT_DEFAULT } from 'Config';
import { FaCalendarAlt } from 'react-icons/fa';
import InputGroup from '../inputGroup/InputGroup';

type Props = {
    form: UseFormMethods<any>;
    name: string;
    onChange?: (date: Date | [Date, Date] | null) => void;
    rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
} & Omit<DateTimePickerProps, 'selected' | 'onChange'>;

const DateTimePickerControllerNew = ({ form, name, rules, onChange, ...props }: Props) => {
    const ref = useRef(null);
    return (
        <Controller
            render={({ value, onChange: onChangeCtrl }) => {
                return (
                    <DateTimePicker
                        {...props}
                        ref={ref}
                        dateFormat={DATEPICKER_FORMAT_DEFAULT}
                        customInput={<InputGroup
                            icon={<FaCalendarAlt />}
                            clickInText={true}
                            onClickInText={() => (ref.current as any)?.setOpen(true)}
                        />}
                        onChange={date => {
                            onChangeCtrl(date);
                            if (onChange) {
                                onChange(date)
                            }
                        }}
                        selected={value}
                    />
                );
            }}
            onFocus={() => (ref.current as any).input.focus()}
            control={form.control}
            name={name}
            defaultValue={form.getValues(name)}
            rules={rules}
        />
    );
};

export default DateTimePickerControllerNew;
