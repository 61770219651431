import Http from '../../common/services/Http';
import { CountriesDto } from './models/CountriesDto';

class CountriesService {

    public catalog() {
        return Http.get<CountriesDto[]>('Countries/catalog');
    }
    
}

export default new CountriesService();