import React from 'react';
import styles from './CheckBox.module.scss';
import { FaCheck } from 'react-icons/fa';

export type Props = {
    label?: string;
    round?: boolean;
    color?: string;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const CheckBox = ({ label, children, round = false, color, ...props }: Props, ref: React.ForwardedRef<HTMLInputElement>) => {

    return (
        <label className={styles.container} style={{ cursor : props.disabled ? 'not-allowed' : '' }}>
            {Boolean(label) && <span className={styles.label}>{label}</span>}
            {children}
            <input
                {...props}
                ref={ref}
                type="checkbox"
                className={`${styles.input} ${props.className || ''}`}
            />
            <span
                className={`${styles.checkMark} ${round ? styles.round : ''} ${props.disabled ? styles.disabled : ''}`}
                style={{
                    ...(color ? { borderColor: color } : {}),
                }}
            >
                <FaCheck
                    className={styles.icon}
                    color={color}
                />
            </span>
        </label>
    );
};

export default React.forwardRef<HTMLInputElement, Props>(CheckBox);
