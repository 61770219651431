import { VehicleViewModel } from 'api/vehicles/models/VehicleViewModel';
import React from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import styles from './ToggleSwitch.module.scss';

type Props = {
    name: string;
    form: UseFormMethods<VehicleViewModel> | any;
    isDetails?: boolean;
    changeToggle?: () => void;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLInputElement>;

const ToggleSwitch: React.FC<Props> = ({ name, form, isDetails, changeToggle, ...props }: Props) => {

    return (

        <Controller
            render={({ value, onChange, ref }) => {
                return (
                    <label
                        ref={ref}
                        className={`${styles.toggleSwitch} ${isDetails ? styles.disabled : styles.enabled}`}>
                        <input
                            {...props}
                            type='checkbox'
                            checked={value ? true : false}
                            onChange={e => {
                                if (!isDetails) {
                                    onChange(e.target.checked);
                                    changeToggle && changeToggle();
                                }
                            }}
                            ref={ref}
                        />
                        <span className={styles.switch} />

                    </label>
                );
            }}
            key={name}
            control={form.control}
            name={name}
            defaultValue={form.getValues(name) || null}
        />
    );


}

export default ToggleSwitch;
