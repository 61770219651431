import { MediaItemDto } from 'api/common/models/MediaItemDto';
import CustomFile from 'common/models/CustomFile';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactPainter from 'react-painter';
import Button from '../button/Button';
import Modal from '../modal/Modal';
import styles from './ModalImageEditor.module.scss';

const width = 450;
const height = 450;

type Props = {
    isOpen: boolean;
    media: MediaItemDto;
    containerName: string;
    onSave: (media: MediaItemDto) => void;
    onCancel: () => void;
    disabled: boolean,
};

const ModalImageEditor = ({ isOpen, media, containerName, onSave, onCancel, disabled }: Props) => {
    const { t } = useTranslation();

    const save = (b: Blob) => {
        const file: CustomFile = new File([b], media.fileName) as any;
        file.containerName = containerName;

        onSave({
            ...media,
            tempFile: file,
        });
    }

    return (
        <Modal isOpen={isOpen} onRequestClose={onCancel} style={{ content: { width: 'auto', minWidth: 'unset' } }}>
            <div>
                {!disabled && <ReactPainter
                    onSave={save}
                    image={media.url}
                    width={width}
                    height={height}
                    initialColor="red"
                    render={({ triggerSave, canvas }) => (
                        <div className={styles.editorContent}>
                            <div className={styles.canvasContainer}>{canvas}</div>
                            <div className={styles.buttonsContainer}>
                                <Button
                                    text={t('common.cancel')}
                                    preset={'secondary'}
                                    onClick={onCancel}
                                    size={'small'}
                                />
                                <Button
                                    text={t('common.save')}
                                    size={'small'}
                                    onClick={e => { e.preventDefault(); e.stopPropagation(); triggerSave(); }}
                                />
                            </div>
                        </div>
                    )}
                />}

                {disabled && <div>
                    <img
                        src={media.url}
                        style={{
                            width: width,
                        }}
                    />

                    <div className={styles.buttonsContainer}>
                        <Button
                            text={t('common.close')}
                            preset={'secondary'}
                            onClick={onCancel}
                            size={'small'}
                        />
                    </div>
                </div>}

            </div>
        </Modal>
    );
}

export default ModalImageEditor;
