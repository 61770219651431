import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import { useToasts } from 'react-toast-notifications';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { Paged } from 'api/common/types/Page';
import styles from './DamageTypesList.module.scss';
import InputSearch from 'common/components/inputSearch/InputSearch';
import { useDebouncedCallback } from 'use-debounce';
import Dropdown from 'common/components/dropdown/Dropdown';
import DropdownItem from 'common/components/dropdown/DropdownItem';
import { FaEllipsisH } from 'react-icons/fa';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { DiagnosticTypesDto } from 'api/diagnosticTypes/models/DiagnosticTypesDto';
import { DiagnosticTypesSearchCriteria } from 'api/diagnosticTypes/models/DiagnosticTypesSearchCriteria';
import DiagnosticTypesService from 'api/diagnosticTypes/DiagnosticTypesService';
import { useHistory } from 'react-router-dom';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import AddIcon from 'assets/svg/desktop_add_new.svg';

type Props = {
};

const DamageTypesList: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const [damageTypesPage, setDamageTypesPage] = useState<Paged<DiagnosticTypesDto>>();
    const [criteria, setCriteria] = useState<DiagnosticTypesSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderColumn: 'name',
        orderBy: 'asc'
    });

    const history = useHistory();

    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<DiagnosticTypesDto | null>(null);

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasDiagnosticTypesWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_DIAGNOSTIC_TYPES_WRITE']);

    const showRemoveItemDialog = (item: DiagnosticTypesDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    };

    const renderBooleanCell = (row: DiagnosticTypesDto) => {
        return <>-</>;
    };

    const renderTableActionCell = (row: DiagnosticTypesDto) => {
        return (
            <Dropdown
                options={
                    <>
                        <DropdownItem url={`/settings/damageTypes/details/${row.id ?? ''}`}>
                            {t('common.details')}
                        </DropdownItem>
                        {hasDiagnosticTypesWritePolicy && <DropdownItem url={`/settings/damageTypes/edit/${row.id ?? ''}`}>
                            {t('common.edit')}
                        </DropdownItem>}
                        {hasDiagnosticTypesWritePolicy && <DropdownItem onClick={() => showRemoveItemDialog(row)}>
                            {t('common.remove')}
                        </DropdownItem>}
                    </>
                }
            >
                <div>
                    <FaEllipsisH />
                </div>
            </Dropdown>
        );
    };

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const tableColumns: ListingTableColumn<DiagnosticTypesDto>[] = useMemo(() => {
        const cols: ListingTableColumn<DiagnosticTypesDto>[] = [
            {
                field: 'name',
                name: t('diagnosis_types.list.name'),
                onSearch: onTableFilter,
                searchField: 'dt.name'
            },
            {
                name: '',
                width: '60px',
                cellAlignment: 'right',
                preventClick: true,
                renderCell: renderTableActionCell,
                cellStyle: { overflow: 'unset' },
            },
        ];
        return cols;
    }, []);

    const getData = async () => {
        try {
            Loading.show();

            const page = await DiagnosticTypesService.getList(criteria);
            setDamageTypesPage(page);

            Loading.hide();
        } catch (error) {
            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                `Couldn't get vehicle brands list`,
                error
            );
            addToast(t('common.messages.error_load_info'), {
                appearance: 'error',
            });
            Loading.hide();
        }
    };

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }

        try {
            await DiagnosticTypesService.remove(itemToRemove);
            setCriteria({ ...criteria, page: 1 });
            onCancelRemove();
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete damage type`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
        }
    };

    useEffect(() => {
        getData();
    }, [criteria]);

    const debounced = useDebouncedCallback((value: string) => {
        setCriteria({ ...criteria, page: 1, allIn: value });
    }, 500);

    return (
        <div className='list'>
            <div className={styles.tabHeader}>
                <div>
                    <InputSearch
                        onChangeValue={debounced}
                        placeholder={t('common.search')}
                        defaultValue={criteria.allIn}
                    ></InputSearch>
                </div>
                <div>
                    {hasDiagnosticTypesWritePolicy && <ScreenHeaderButton icon={AddIcon} onClick={() => history.push(`/settings/damageTypes/new`)} />}
                </div>
            </div>
            <ListingTable
                columns={tableColumns}
                rows={damageTypesPage?.items || []}
                onRowClick={row => history.push(`/settings/damageTypes/details/${row.id ?? ''}`)}
                onHref={row => `/settings/damageTypes/details/${row.id ?? ''}`}
                allowHover={true}
                initialSearch={{ colField: 'dt.name', isOrderAsc: true }}
            />
            <PaginationWithInfo
                itemName={t('diagnosis_types.title')}
                currentPage={damageTypesPage?.currentPage || 1}
                pageItems={damageTypesPage?.items.length || 0}
                totalItems={damageTypesPage?.totalItems || 0}
                onChange={page => setCriteria({ ...criteria, page })}
            />
            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('common.messages.remove_damage_type', { type: itemToRemove?.name ?? '' })}/>

        </div>
    );
};

export default DamageTypesList;
