import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ReservationFilters.module.scss';
import { useToasts } from 'react-toast-notifications';
import { DATE_FORMAT_DEFAUT, LOGGER_LOG_TYPE, removeAccents } from 'Config';
import Logger from 'common/services/Logger';
import { FaCalendarAlt } from 'react-icons/fa';
import Button from 'common/components/button/Button';
import { Col, Row } from 'react-flexbox-grid';
import FormItem from 'common/components/formItem/FormItem';
import Label from 'common/components/label/Label';
import SelectController from 'common/components/select/SelectController';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { useForm } from 'react-hook-form';
import VehicleBrandsService from 'api/vehicleBrands/VehicleBrandsService';
import VehicleModelsService from 'api/vehicleModels/VehicleModelsService';
import Input from 'common/components/input/Input';
import InputGroup from 'common/components/inputGroup/InputGroup';
import { CatalogModelsSearchCriteria } from 'api/catalog/models/CatalogModelsSearchCriteria';
import { CatalogBrandsSearchCriteria } from 'api/catalog/models/CatalogBrandsSearchCriteria';
import DateWithHourController from 'common/components/dateWithHour/DateWithHourController';
import VehicleSegmentsService from 'api/vehicleSegments/VehicleSegmentsService';

export interface Filters {
    vehicleBrandId?: string | null,
    vehicleModelId?: string | null,
    vehicleSegmentId?: string | null,
    vehicleRegistrationNumber?: string | null,
    reservationStartDate?: Date | null,
    reservationEndDate?: Date | null,
    page?: number,
}

type Props = {
    filters: Filters;
    onFilter: (filters: Filters) => void;
    load?: boolean;
}

const ReservationFilters: React.FC<Props> = ({ filters, onFilter, load }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const form = useForm<Filters>({ shouldUnregister: false, shouldFocusError: true, defaultValues: filters });
    const { getValues, setValue, reset, watch } = form;
    const [brandsOptions, setBrandsOptions] = useState<SelectValueLabel[]>([]);
    const [modelsOptions, setModelsOptions] = useState<SelectValueLabel[]>([]);
    const [segmentsOptions, setSegmentsOptions] = useState<SelectValueLabel[]>([]);

    useEffect(() => {
        if (load) {
            form.reset(filters);
            onSubmit(filters);
        } else {
            form.reset(filters);
        }
    }, [filters]);

    const getData = async () => {
        try {
            const [
                brands,
                models,
                segments
            ] = await Promise.all([
                VehicleBrandsService.catalog(),
                VehicleModelsService.catalog(),
                VehicleSegmentsService.catalog()
            ]);

            setBrandsOptions(brands);
            setModelsOptions(models);
            setSegmentsOptions(segments);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get data`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const getModelByBrand = async () => {
        try {
            const modelsList = await VehicleModelsService.catalog({ brandId: getValues('vehicleBrandId') } as CatalogModelsSearchCriteria);
            setModelsOptions(modelsList);

            if (!getValues('vehicleModelId') && modelsList.length == 1) {
                setValue('vehicleModelId', modelsList.find(x => x.value)?.value);
                getBrandByModel();
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get models of brand`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const getBrandByModel = async () => {
        try {
            const brandsList = await VehicleBrandsService.catalog({ modelId: getValues('vehicleModelId') } as CatalogBrandsSearchCriteria);
            setBrandsOptions(brandsList);

            if (!getValues('vehicleBrandId') && brandsList.length == 1) {
                setValue('vehicleBrandId', brandsList.find(x => x.value)?.value);
                getModelByBrand();
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get brands of model`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const clearFilters = () => {
        const model = {
            vehicleBrandId: null,
            vehicleModelId: null,
            vehicleSegmentId: null,
            vehicleRegistrationNumber: null,
            reservationStartDate: null,
            reservationEndDate: null,
        };
        reset(model);
        onSubmit(model);
        getData();
    }

    const onSubmit = (f: Filters) => {
        onFilter(f);
    }

    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <Row >
                <Col sm={12} md={6} xl={4}>
                    <FormItem>
                        <Label className={styles.filtersLabel}>{t('reservations.brand')}</Label>
                        <SelectController
                            form={form}
                            name="vehicleBrandId"
                            placeholder={t('reservations.brand')}
                            options={brandsOptions}
                            isDisabled={false}
                            isClearable={true}
                            filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                            onChangeSelect={(data: SelectValueLabel) => {
                                setValue('vehicleBrandId', data ? data.value : null);
                                getModelByBrand();
                                getValues();
                            }}
                        />
                    </FormItem>
                </Col>

                <Col sm={12} md={6} xl={4}>
                    <FormItem>
                        <Label className={styles.filtersLabel}>{t('reservations.model')}</Label>
                        <SelectController
                            form={form}
                            name="vehicleModelId"
                            placeholder={t('reservations.model')}
                            options={modelsOptions}
                            isDisabled={false}
                            isClearable={true}
                            filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                            onChangeSelect={(data: SelectValueLabel) => {
                                setValue('vehicleModelId', data ? data.value : null);
                                getBrandByModel();
                                getValues();
                            }}
                        />
                    </FormItem>
                </Col>

                <Col sm={12} md={6} xl={4}>
                    <FormItem>
                        <Label className={styles.filtersLabel}>{t('vehicle.type')}</Label>
                        <SelectController
                            form={form}
                            name="vehicleSegmentId"
                            placeholder={t('vehicle.type')}
                            options={segmentsOptions}
                            isDisabled={false}
                            isClearable={true}
                            filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                            onChangeSelect={(data: SelectValueLabel) => {
                                setValue('vehicleSegmentId', data ? data.value : null);
                                getValues();
                            }}
                        />
                    </FormItem>
                </Col>

                <Col sm={12} md={6} xl={4}>
                    <FormItem>
                        <Label className={styles.filtersLabel}>{t('reservations.registration_number')}</Label>
                        <Input
                            maxLength={255}
                            name="vehicleRegistrationNumber"
                            onKeyUp={e => {
                                setValue('vehicleRegistrationNumber', e.currentTarget.value != '' ? e.currentTarget.value : null);
                                getValues();
                            }}
                            placeholder={t('reservations.registration_number')}
                            autoComplete='off'
                            defaultValue={watch('vehicleRegistrationNumber') || undefined} />

                    </FormItem>
                </Col>

                <Col sm={12} md={6} xl={4}>
                    <FormItem>
                        <Label className={styles.filtersLabel}>{t('reservations.list.date_start')}</Label>

                        <DateWithHourController
                            form={form}
                            datePlaceholder={t('common.date')}
                            dateFormat={DATE_FORMAT_DEFAUT}
                            dateCustomInput={<InputGroup icon={<FaCalendarAlt />} removeIconOrTextBorderRadius={true} />}
                            name="reservationStartDate"
                            isDisabled={false}
                            timePlaceholder={t('common.hour')}
                        />
                    </FormItem>
                </Col>

                <Col sm={12} md={6} xl={4}>
                    <FormItem>
                        <Label className={styles.filtersLabel}>{t('reservations.list.date_end')}</Label>
                        <DateWithHourController
                            form={form}
                            datePlaceholder={t('common.date')}
                            dateFormat={DATE_FORMAT_DEFAUT}
                            dateCustomInput={<InputGroup icon={<FaCalendarAlt />} removeIconOrTextBorderRadius={true} />}
                            name="reservationEndDate"
                            isDisabled={false}
                            timePlaceholder={t('common.hour')}
                            minDate={form.watch('reservationStartDate') || undefined}
                        />
                    </FormItem>
                </Col>

                <Col xl={12} className={styles.searchButton}>
                    <Button
                        text={t('common.remove')}
                        size={'small'}
                        preset={'secondary'}
                        onClick={clearFilters}
                        type='reset'
                    />
                    <Button
                        type='submit'
                        text={t('common.apply')}
                        size={'small'}
                    />
                </Col>

            </Row>
        </form>
    );
}

export default memo(ReservationFilters);