import { SelectValueLabel } from 'common/types/SelectValueLabel';
import Http from 'common/services/Http';



class ExpenseTemplatesService {

    public catalog() {
        return Http.get<SelectValueLabel[]>('expensetemplates/catalog');
    }   
}

export default new ExpenseTemplatesService();
