import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import styles from './CarMonthlyChargesTab.module.scss';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE, DATE_FORMAT_MONTH_YEAR } from 'Config';
import { useHistory, useParams } from 'react-router-dom';
import Logger from 'common/services/Logger';
import { useToasts } from 'react-toast-notifications';
import VehiclesService from 'api/vehicles/VehiclesService';
import Loading from 'common/services/Loading';
import DateFormat from 'common/components/dateFormat/dateFormat';
import CarMonthlyChargesFiltersScreen, { Filters } from './components/CarMonthlyChargesFiltersScreen';
import MoneyFormat from 'common/components/moneyFormat/MoneyFormat';
import { Paged } from 'api/common/types/Page';
import { VehicleMonthlyCostsDto } from 'api/vehicleMonthlyCosts/models/VehicleMonthlyCostsDto';
import { VehicleMonthlyCostsSearchCriteria } from 'api/vehicleMonthlyCosts/models/VehicleMonthlyCostsSearchCriteria';
import Popover from 'common/components/popover/Popover';
import dayjs from 'dayjs';
import FiltersIcon from 'assets/svg/desktop_filtres.svg';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';

type Props = {
    tourReRender: () => void;
} & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const CarMonthlyChargesTab = ({ tourReRender, ...props }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const history = useHistory();

    const { vehicleId } = useParams<{ vehicleId: string }>();

    const [chargePage, setChargePage] = useState<Paged<VehicleMonthlyCostsDto> | null>(null);

    const [criteria, setCriteria] = useState<VehicleMonthlyCostsSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        vehicleId: vehicleId,
        endDate: dayjs().endOf('month').toDate(),
        orderColumn: 'vmc.date',
        orderBy: 'desc'
    });

    const [filters, setFilters] = useState<Filters>({ endDate: criteria.endDate });
    const [filtersTotal, setFiltersTotal] = useState(1);
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);

    const getData = async () => {
        try {
            const charges = await VehiclesService.getMonthlyCosts(criteria);
            setChargePage(charges);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.CHARGES, `Couldn't get charges list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
        finally {
            tourReRender();
        }
    };

    const navigateToUrl = (type: string) => {
        let url = `/vehicles/details/${vehicleId}/`;
        switch (type.toUpperCase()) {
            case 'CHARGE': url += `costs`; break;
            case 'CONTRACT': url += `contract`; break;
            case 'MAINTENANCE': url += `maintenance`; break;
            default: return '';
        }
        return url + '/list/null/null/null/';
    }

    const onRowClick = (event: any, url: string) => {
        if (event.ctrlKey) {
            window.open(url, '_blank');
            return;
        }
        history.push(url);
    }

    const renderCostCell = (type: string, value: number, date: Date) => {
        return <div className={styles.moneyColumn} onClick={event => onRowClick(event, navigateToUrl(type) + dayjs(date).format('YYYY-MM-01'))}>
            <MoneyFormat fixedDecimalScale={true} value={value} />
        </div>
    }

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const tableColumns: ListingTableColumn<VehicleMonthlyCostsDto>[] = useMemo(() => {
        const cols: ListingTableColumn<VehicleMonthlyCostsDto>[] = [
            {
                name: t('vehicle_monthly_costs.month', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) }),
                renderCell: row => <div><DateFormat value={row.date} format={DATE_FORMAT_MONTH_YEAR.toString()} /></div>,
                width: 'fill',
                onSearch: onTableFilter,
                searchField: 'vmc.date'
            },
            {
                name: t('contracts.title'),
                renderCell: row => renderCostCell('contract', row.contractsCosts || 0, row.date),
                width: 200,
                hideOn: ['sm', 'md'],
                onSearch: onTableFilter,
                searchField: 'contracts_costs',
                columnCellAlignment: 'right'
            },
            {
                name: t('charges.title'),
                renderCell: row => renderCostCell('charge', row.chargesCosts || 0, row.date),
                width: 200,
                hideOn: ['sm', 'md'],
                onSearch: onTableFilter,
                searchField: 'charges_costs',
                columnCellAlignment: 'right'
            },
            {
                name: t('maintenances.title'),
                renderCell: row => renderCostCell('maintenance', row.maintenancesCosts || 0, row.date),
                width: 200,
                hideOn: ['sm', 'md'],
                onSearch: onTableFilter,
                searchField: 'maintenances_costs',
                columnCellAlignment: 'right'
            },
            {
                name: t('vehicle_monthly_costs.total', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) }),
                renderCell: row => <div className={styles.moneyColumn}> <MoneyFormat fixedDecimalScale={true} value={(row?.chargesCosts || 0) + (row?.contractsCosts || 0) + (row?.maintenancesCosts || 0)} /> </div>,
                width: 200,
                onSearch: onTableFilter,
                searchField: 'total',
                columnCellAlignment: 'right'
            }
        ];
        return cols;
    }, []);

    const onPageChange = (page: number) => {
        setCriteria({ ...criteria, page: page });
    }

    useEffect(() => {
        getData();
    }, [criteria]);

    const onChangeFilters = (_filters: Filters) => {
        updateTotalFilters(_filters);
        setFilters(_filters);
        criteria.startDate = _filters.startDate;
        criteria.endDate = _filters.endDate;
        setCriteria({ ...criteria });
    }

    const updateTotalFilters = (_filters: Filters) => {
        let total = 0;
        if (_filters.startDate || _filters.endDate) {
            total++;
        }
        setFiltersTotal(total);
    }

    return (
        <div className={styles.container}>
            <ScreenTitle title={t('charges.title')} {...props}>
                <ScreenContainer>
                    <div className={styles.tabHeader}>
                        <div>
                        </div>
                        <div className={styles.rowFlex}>
                            <Popover
                                contentContainerClassName={styles.filtersPopoverContainer}
                                containerStyle={{ boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}
                                positions={['left']}
                                onClickOutside={() => updateTotalFilters(filters)}
                                content={setIsPopoverOpen => <CarMonthlyChargesFiltersScreen
                                    filters={filters}
                                    onFilter={(f: Filters) => { setIsPopoverOpen(false); onChangeFilters(f) }}
                                    onChange={updateTotalFilters}
                                />}>
                                {(isPopoverOpen, setIsPopoverOpen) => (
                                    // <Button onClick={() => setIsPopoverOpen(!isPopoverOpen)}>{t('common.filters')}
                                    //     {filtersTotal > 0 &&
                                    //         <div className={styles.counter}> <div className={styles.counterNumber}>{filtersTotal}</div> </div>
                                    //     }
                                    // </Button>

                                    <ScreenHeaderButton icon={FiltersIcon} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                                        {filtersTotal > 0 &&
                                            <div className={styles.counterList}> <div className={styles.counterNumber}>{filtersTotal}</div> </div>
                                        }
                                    </ScreenHeaderButton>
                                )}
                            </Popover>
                        </div>
                    </div>
                    <div>
                        <ListingTable
                            columns={tableColumns}
                            rows={chargePage?.items ?? []}
                            allowHover={true}
                            initialSearch={{ colField: 'vmc.date', isOrderAsc: false }} />
                        <PaginationWithInfo
                            itemName={t('charges.list.charges')}
                            currentPage={chargePage?.currentPage ?? 1}
                            pageItems={chargePage?.items.length ?? 0}
                            totalItems={chargePage?.totalItems ?? 0}
                            onChange={onPageChange} />
                    </div>
                </ScreenContainer>
            </ScreenTitle>
        </div>
    );
}

export default CarMonthlyChargesTab;
