import VehicleBrandsDetails from './components/vehicleBrandsDetails/VehicleBrandsDetails';
import VehicleBrandsList from './components/vehicleBrandsList/VehicleBrandsList';
import { useParams } from 'react-router-dom';
import styles from './VehicleBrandsTab.module.scss';

const VehicleBrandsTab = () => {
    const { type } = useParams<{ type: string, itemId: string }>();

    return <div className={styles.container}>
    {
        (type === 'list' ? <VehicleBrandsList /> : <VehicleBrandsDetails />)
    }
    </div>;
}

export default VehicleBrandsTab;
