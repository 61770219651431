import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import { useToasts } from 'react-toast-notifications';
import { NotificationDto } from 'api/notifications/models/NotificationDto';
import { NotificationSearchCriteria } from 'api/notifications/models/NotificationSearchCriteria';
import Loading from 'common/services/Loading';
import NotificationsService from 'api/notifications/NotificationsService';
import Logger from 'common/services/Logger';
import { Paged } from 'api/common/types/Page';
import styles from './NotificationsList.module.scss';
import { useDebouncedCallback } from 'use-debounce';
import Dropdown from 'common/components/dropdown/Dropdown';
import DropdownItem from 'common/components/dropdown/DropdownItem';
import { FaEllipsisH } from 'react-icons/fa';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { useHistory } from 'react-router-dom';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';

type Props = {
};

const NotificationsList: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const history = useHistory();

    const [notificationsPage, setNotificationsPage] = useState<Paged<NotificationDto>>();
    const [criteria, setCriteria] = useState<NotificationSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderColumn: 'type',
        orderBy: 'asc'
    });

    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<NotificationDto | null>(null);

    const showRemoveItemDialog = async (item: NotificationDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasNotificationsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_NOTIFICATIONS_WRITE']);

    const renderTableTypeCell = (row: NotificationDto) => {
        return <div>{t(`notifications.list.${row.type}` as any)}</div>
    };


    const renderTableActionCell = (row: NotificationDto) => {
        return <Dropdown
            options={
                <>
                    <DropdownItem url={`/settings/notifications/details/${row.type ?? ''}`}>
                        {t('common.details')}
                    </DropdownItem>
                    {hasNotificationsWritePolicy && <DropdownItem url={`/settings/notifications/edit/${row.type ?? ''}`}>
                        {t('common.edit')}
                    </DropdownItem>}
                    {hasNotificationsWritePolicy && <DropdownItem onClick={() => showRemoveItemDialog(row)}>
                        {t('common.remove')}
                    </DropdownItem>}
                </>
            }
        >
            <div>
                <FaEllipsisH />
            </div>
        </Dropdown>
    };

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const tableColumns: ListingTableColumn<NotificationDto>[] = [
        {
            field: 'type',
            name: t('notifications.list.type'),
           // onSearch: onTableFilter,
            searchField: 'n.type',
            renderCell: renderTableTypeCell
        },
        {
            name: '',
            width: '60px',
            cellAlignment: 'right',
            preventClick: true,
            renderCell: renderTableActionCell,
            cellStyle: { overflow: 'unset' }
        }
    ];

    const getData = async () => {
        try {
            Loading.show();

            const page = await NotificationsService.getList(criteria);
            setNotificationsPage(page);

            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't get notifications list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }

        try {
            await NotificationsService.remove(itemToRemove);
            setCriteria({ ...criteria, page: 1 });
            onCancelRemove();
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete notifications`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
        }
    };

    useEffect(() => {
        getData();
    }, [criteria]);

    const debounced = useDebouncedCallback((value: string) => {
        setCriteria({ ...criteria, page: 1, allIn: value });
    }, 500);

    return (
        <div className={styles.container}>
            <div className={styles.tabHeader}>
                {/* <div>
                    <InputSearch onChangeValue={debounced} placeholder={t('common.search')} ></InputSearch>
                </div>
                <div>
                    {hasNotificationsWritePolicy && <Button
                        preset='success'
                        text={t('common.new')}
                        onClick={() => history.push(`/settings/notifications/new`)}
                    ></Button>}
                </div> */}
            </div>
            <ListingTable
                columns={tableColumns}
                rows={notificationsPage?.items || []}
                onRowClick={row => history.push(`/settings/notifications/details/${row.type ?? ''}`)}
                onHref={row => `/settings/notifications/details/${row.type ?? ''}`}
                allowHover={true}
                initialSearch={{ colField: 'n.type', isOrderAsc: true }}
            />
            <PaginationWithInfo
                itemName={t('notifications.list.notifications')}
                currentPage={notificationsPage?.currentPage || 1}
                pageItems={notificationsPage?.items.length || 0}
                totalItems={notificationsPage?.totalItems || 0}
                onChange={page => setCriteria({ ...criteria, page })}
            />

            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('common.messages.remove_record_with_ident', { name: itemToRemove?.type ?? '' })}/>
        </div>
    );
};

export default NotificationsList;
