import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ScreenTitle from '../../common/components/screenTitle/ScreenTitle';
import ScreenContainer from '../../common/components/screenContainer/ScreenContainer';
import Box from 'common/components/box/Box';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import styles from './VehiclesManagementScreen.module.scss';
import MoneyFormat from 'common/components/moneyFormat/MoneyFormat';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import { DATE_FORMAT_DEFAUT, DATE_FORMAT_MONTH_YEAR, DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE, SEGMENT_FEATURES } from 'Config';
import FiltersIcon from 'assets/svg/desktop_filtres.svg';
import CarIcon from 'assets/svg/car.svg';
import CoinsIcon from 'assets/svg/coins.svg';
import CoinsBlackIcon from 'assets/svg/coins_black.svg';
import MediaIcon from 'assets/svg/media.svg';
import MediaIcon2 from 'assets/svg/Grupo_352.svg';
import ConeBlackIcon from 'assets/svg/cone_black.svg';
import GaugeIcon from 'assets/svg/gauge.svg';
import EuroIcon from 'assets/svg/euro.svg';
import TicketIcon from 'assets/svg/ticket.svg';
import FloorBrushIcon from 'assets/svg/floor-brush.svg';
import { useHistory } from 'react-router-dom';
import Logger from 'common/services/Logger';
import { useToasts } from 'react-toast-notifications';
import VehiclesService from 'api/vehicles/VehiclesService';
import { VehicleViewModel } from 'api/vehicles/models/VehicleViewModel';
import NoImage from 'assets/img/asset-default-image.png';
import Popover from 'common/components/popover/Popover';
import VehiclesManagementFiltersScreen, { Filters } from './components/VehiclesManagementFiltersScreen';
import { VehiclesSearchCriteria } from 'api/vehicles/models/VehiclesSearchCriteria';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { VehicleCounterListDto } from 'api/vehicles/models/VehicleDto';
import DateFormat from 'common/components/dateFormat/dateFormat';
import IconWithText from 'common/components/iconWithText/IconWithText';
import FilterList from 'common/components/filterList/FilterList';
import CounterBox from 'common/components/counterBox/CounterBox';
import ReactTooltip from 'react-tooltip';
import { BiTargetLock } from 'react-icons/bi';
import { FaFileExport } from 'react-icons/fa';
import dayjs from 'dayjs';
import Colors from 'styles/export/colors.module.scss';
import Loading from 'common/services/Loading';
import moment from 'moment';
import { ContractStatusColorEnum } from 'api/contracts/enums/ContractStatusColorEnum';

import DropdownItem from 'common/components/popover/PopoverItem';
import Dropdown from 'common/components/dropdown/Dropdown';
import DeletedVehicleCircle from 'common/components/deletedVehicleCircle/DeletedVehicleCircle';

const defaultcostsEndDate = dayjs().endOf('month').toDate();

const VehiclesManagementScreen = () => {

    const { t } = useTranslation();
    const { addToast } = useToasts();
    const [criteria, setCriteria] = useState<VehiclesSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderColumn: 'v.registration_number',
        orderBy: 'asc',
        costsEndDate: defaultcostsEndDate,
        inactives: true,
        hasKmFeature:false
    });
    const [vehicles, setVehicles] = useState<VehicleViewModel[]>([]);
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);

    const [filters, setFilters] = useState<Filters>({ costsEndDate: defaultcostsEndDate, inactives: true });
    const [filtersTotal, setFiltersTotal] = useState(2);

    const [counters, setCounters] = useState<VehicleCounterListDto>();

    const imageCacheKey = useRef(new Date().getTime());

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasCountersPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['CHARGES_TOTAL_READ']);
    const hasGpsReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_GPS_READ']);
    const hasExportPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_EXPORT_EXPENSES_WRITE']);


    //const hasVehiclesWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_VEHICLES_WRITE']);
    const [pageLoaded, setPageLoaded] = useState(false);

    const getData = async () => {

        try {
            Loading.show();
            let countersDB = null;
            if (hasCountersPolicy) {
                countersDB = await VehiclesService.getCountersList(criteria);
            }
            const vehiclesDB = await VehiclesService.getList(criteria);
            setCurrentPage(vehiclesDB.currentPage);
            setTotalItems(vehiclesDB.totalItems);

            setVehicles(vehiclesDB.items);
            if (countersDB) {
                setCounters(countersDB);
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get vehicles list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
        finally {
            Loading.hide();
        }
    }

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const renderImageCell = (row: VehicleViewModel) => {
        return (
            <div className={styles.imageDescCell}>
                <div className={styles.contentImage} style={{ width: '5rem', height: '3rem' }}>
                    <div className={styles.img} style={{ width: '5rem', height: '3rem', backgroundImage: row?.photoUrl ? 'url(' + row?.photoUrl + '?_=' + imageCacheKey.current + ')' : 'url(' + NoImage + ')' }} />
                </div>
            </div>
        )
    }

    const renderDescCell = (row: VehicleViewModel) => {
        return (
            <div className={styles.descCell}>
                <div className={styles.columnPlate}>
                    {row.registrationNumber}
                    {!row.active && <DeletedVehicleCircle id={row.id ?? ''} />}
                </div>
                <div className={styles.columnBrand}>{(row.vehicleBrandName ?? '') + (row.vehicleBrandName && row.vehicleModelName ? ' | ' : '') + (row.vehicleModelName ?? '')}</div>
                {/*<div className={styles.columnSpec}>{row.vehicleSegmentName + (' | ') + (t(('vehicle.fuels.' + row.fuelName) as any))}</div>*/}
            </div>
        )
    }

    const renderTypeContractCell = (row: VehicleViewModel) => {
        return (
            <div className={styles.cellContainer}>
                <div className={styles.firstRow}>{row.useContractTypeName ? t(`common.use_contract_types.${row.useContractTypeName}` as any) : (row.useContractExpiredEndDate ? <span style={{ color: ContractStatusColorEnum.EXPIRED }}>({t('contracts.states.expired')})<br /><DateFormat value={row.useContractExpiredEndDate} format={DATE_FORMAT_DEFAUT} /></span> : '-')}</div>
                <div className={styles.smallCustomization}>{
                    row.useContractMonthsContract && row.useContractPastMonths
                        ? `${t('vehicles_management.list.months')} ${row.useContractPastMonths} ${t('vehicles_management.list.of')} ${row.useContractMonthsContract}`
                        : ''
                }</div>
            </div>
        )
    }

    const showField = (feature: string,segmentFeatures: string[]) => {
        return (segmentFeatures?.findIndex(x => x == feature) ?? -1) >= 0;
    }

    const renderKilometersCell = (row: VehicleViewModel) => {
        return (
            <div className={styles.cellContainer}>
                <div className={styles.firstRow} data-tip={t('vehicles_management.list.current_kilometers')}>
                    <IconWithText
                        icon={GaugeIcon}
                        value={showField(SEGMENT_FEATURES.KM,row.segmentFeatures ?? []) ? row.currentKilometers : '-'}
                        suffix={t('vehicles_management.list.kilometers_minified')}
                        isNumeric={true}
                    />
                </div>
                <ReactTooltip />
                <div data-tip={t('vehicles_management.list.average_target_month')}>
                    <BiTargetLock />&nbsp;
                    {row.averageTargetMonth ? <MoneyFormat value={row.averageTargetMonth} suffix={t('vehicles_management.list.kilometers_minified')} /> : '-'}
                </div>
                <ReactTooltip />
                {hasGpsReadPolicy && row && !!row.currentKilometersTracking &&
                    <div className={styles.secondRow}>
                        {t('vehicles_management.list.gps')}:&nbsp;<MoneyFormat value={row.currentKilometersTracking} suffix={' '} fixedDecimalScale={false} />
                    </div>}
            </div>
        )
    }

    const renderConsumptionsAndCostsCell = (row: VehicleViewModel) => {
        return (
            <div className={styles.cellContainer}>
                <div className={styles.smallCustomization} data-tip={t('vehicles_management.list.total_cost_tooltip')}>
                    <IconWithText
                        icon={EuroIcon}
                        value={(row?.monthlyCostTotal && row?.monthlyCostTotal > 0 && row.currentKilometers && row.currentKilometers > 0 ? (row.monthlyCostTotal / row.currentKilometers) : 0)}
                        fixedDecimalScale={true}
                        suffix={t('common.euro')}
                    />
                </div>
                <ReactTooltip />
                <div className={styles.smallCustomization} data-tip={t('vehicles_management.list.average_monthly_cost', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) })}>
                    <IconWithText
                        icon={MediaIcon2}
                        value={row.monthlyCostAverage}
                        size={'sm'}
                        fixedDecimalScale={true}
                        suffix={t('vehicles_management.list.euro_months')}
                    />
                </div>
                <ReactTooltip />
                <div className={styles.smallCustomization} data-tip={t('vehicles_management.list.total_monthly_cost', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) })}>
                    <IconWithText
                        icon={CoinsBlackIcon}
                        value={row.monthlyCostTotal}
                        size={'sm'}
                        fixedDecimalScale={true}
                        suffix={t('common.euro')}
                    />
                </div>
                <ReactTooltip />
            </div>
        )
    }

    const renderObservationsCell = (row: VehicleViewModel) => {
        return (
            <div className={styles.cellContainer}>
                {Boolean(row.totalFines) && <div className={styles.smallCustomization}>
                    <IconWithText
                        icon={TicketIcon}
                        value={(row.totalFines ?? '0')}
                        size={'sm'}
                        fixedDecimalScale={true}
                        suffix={t('vehicles_management.list.euro_fines')}
                    />
                </div>}
                {Boolean(row.totalCleaning) && <div className={styles.smallCustomization}>
                    <IconWithText
                        icon={FloorBrushIcon}
                        value={(row.totalCleaning ?? '0') + ' ' + t('vehicles_management.list.cleaning')}
                        size={'sm'}
                    />
                </div>}
                {Boolean(row.totalAccidents) && <div className={styles.smallCustomization}>
                    <IconWithText
                        icon={ConeBlackIcon}
                        value={(row.totalAccidents ?? '0') + ' ' + t('vehicles_management.list.accidents')}
                        size={'sm'}
                    />
                </div>}
            </div>
        )
    }

    const tableColumns: ListingTableColumn<VehicleViewModel>[] = [
        {
            renderCell: renderImageCell,
            width: 90,
            hideOn: ['sm', 'md'],
            onlyImageColumn: true
        },
        {
            name: t('vehicles_management.list.vehicle'),
            renderCell: renderDescCell,
            onSearch: onTableFilter,
            searchField: 'v.registration_number'
        },
        {
            name: t('common.type_funding'),
            renderCell: renderTypeContractCell,
            onSearch: onTableFilter,
            searchField: 'use_contract_info.name',
            hideOn: ['sm', 'md', 'lg', 'xl'],
            width: 'fill'
        },
        {
            name: t('vehicles_management.list.kilometers'),
            renderCell: renderKilometersCell,
            hideOn: ['sm', 'md', 'lg'],
            onSearch: onTableFilter,
            searchField: 'v.current_kilometers',
        },
        {
            name: t('vehicles_management.list.consumption_costs', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) }),
            renderCell: renderConsumptionsAndCostsCell,
            onSearch: onTableFilter,
            searchField: 'monthly_cost_total_kilometers',
            hideOn: ['sm'],
            width: 'fill'
        },
        {
            name: <>
                <div data-tip={t('vehicles_management.list.observations_info')}>
                    {t('vehicles_management.list.observations')}
                </div>
                <ReactTooltip />
            </>,
            renderCell: renderObservationsCell,

            hideOn: ['sm', 'md', 'lg'],
            width: 'fill',
        },
    ];

    const navigateTo = (type?: string, id?: string) => {
        if (id) {
            history.push(`/vehicles/${type}/${id}/informations/list/undefined/undefined/undefined/undefined/${'vehicles-management'}`);
        } else {
            history.push(`/vehicles/${type}`);
        }
    }

    const onRowClick = (event: any, id?: string) => {
        if (id) {
            event.preventDefault();
            event.stopPropagation();

            filters.page = currentPage;
            saveFiltersOnCache(filters);

            if (event.ctrlKey) {
                window.open('vehicles/details/' + id, '_blank');
                return;
            }
            navigateTo('details', id);
        }
    }

    const saveFiltersOnCache = (f: Filters) => {
        setFilters(f);
        localStorage.setItem('VEHICLESMANAGEMENTFILTER', JSON.stringify(f));
    }

    const onPageChange = (page: number) => {
        criteria.page = page;
        getData();
    }

    useEffect(() => {
        loadFilters();
        setPageLoaded(true);
    }, []);

    useEffect(() => {
        if (pageLoaded) {
            getData();
        }
    }, [criteria]);

    const loadFilters = () => {
        const cachedFilters = localStorage.getItem('VEHICLESMANAGEMENTFILTER');
        if (cachedFilters != null) { onChangeFilters(JSON.parse(cachedFilters), true) }
        else { getData() }
    }

    const onChangeFilters = (f: Filters, isOnLoad?: boolean) => {
        updateTotalFilters(f);
        criteria.registrationNumber = f.registrationNumber;
        criteria.registrationDate = f.registrationDate;
        criteria.segmentId = f.segmentId;
        criteria.minCost = f.costAverage && f.costAverage[0];
        criteria.maxCost = f.costAverage && f.costAverage[1];
        criteria.minCostTotal = f.costAccumulated && f.costAccumulated[0];
        criteria.maxCostTotal = f.costAccumulated && f.costAccumulated[1];
        criteria.driverId = f.driverId;
        criteria.costsStartDate = f.costsStartDate;
        criteria.costsEndDate = f.costsEndDate;
        criteria.useContractTypeId = f.useContractTypeId;
        criteria.useContractActive = f.useContractActive;
        criteria.useContractEndsAfter = f.useContractEndsAfter;
        criteria.useContractEndsBefore = f.useContractEndsBefore;
        criteria.inactives = f.inactives;

        if (isOnLoad && f.page) {
            setCurrentPage(f.page);
            criteria.page = f.page
        } else {
            setCurrentPage(1);
            criteria.page = 1;
            f.page = 1;
        }

        saveFiltersOnCache(f);
        getData();
    }

    const updateTotalFilters = (f: Filters) => {
        let total = 0;

        if (f.registrationNumber) {
            total++;
        }
        if (f.registrationDate) {
            total++;
        }
        if (f.segmentId && f.segmentId.length > 0) {
            total++;
        }
        if (f.costAverage) {
            total++;
        }
        if (f.costAccumulated) {
            total++;
        }
        if (f.costsStartDate || f.costsEndDate) {
            total++;
        }

        if (f.useContractTypeId) {
            total++;
        }

        if (f.useContractActive != undefined) {
            total++;
        }

        if (f.useContractEndsAfter || f.useContractEndsBefore) {
            total++;
        }

        if (f.inactives) {
            total++;
        }

        setFiltersTotal(total);
    }

    const renderCounterBoxes = () => {
        return <div className={styles.rowCounters}>
            {counters && renderBox(CarIcon, t('vehicles_management.list.number_vehicles'), counters.numberVehicles ?? 0)}
            {counters && renderBox(MediaIcon, t('vehicles_management.list.average_cost', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) }), counters.costTotalMoyenne ?? 0, true, t('dashboard.average_cost_tooltip'))}
            {counters && renderBox(CoinsIcon, t('vehicles_management.list.total_cost', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) }), counters.costTotalAccumulated ?? 0, true, t('dashboard.cumulative_cost_tooltip', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) }))}
            <ReactTooltip effect="solid" id="main" />
        </div>
    }

    const renderBox = (icon: any, label: string, counter: number, isMoney?: boolean, tooltip?: string) => {
        return <CounterBox
            icon={icon}
            label={label}
            counter={counter}
            isMoney={isMoney}
            data-tip={tooltip}
            data-for="main"
        />
    }

    const getExpensesReport = async (total: boolean) => {
        Loading.show();
        try {
            const report = total ? await VehiclesService.getExpensesTotalsReport(criteria) : await VehiclesService.getExpensesDetailsReport(criteria);
            const title = total ? t('vehicles_management.list.vehicles_expenses_excel_total_title') : t('vehicles_management.list.vehicles_expenses_excel_details_title');
            saveAs(report, `${title}_${moment(new Date()).format('YYYY_MM_DD_HH_mm_ss')}.xlsx`);
            Loading.hide();
        } catch (error) {
            Loading.hide();
            Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get vehicle expenses report`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const getVehiclesDetailsReport = async () => {
        Loading.show();
        try {
            const report = await VehiclesService.getVehiclesDetailsReport(criteria);
            const title = t('vehicles_management.list.vehicles_contracts_excel_details_title');
            saveAs(report, `${title}_${moment(new Date()).format('YYYY_MM_DD_HH_mm_ss')}.xlsx`);
            Loading.hide();
        } catch (error) {
            Loading.hide();
            Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get vehicles report`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    return (
        <ScreenTitle title={t('vehicles_management.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('vehicles_management.title')}>
                    {hasExportPolicy && <Dropdown style={{ right: '10px' }}
                        options={
                            <>
                                <DropdownItem onClick={() => getExpensesReport(true)}>
                                    {t('export_charges.tooltip_total')}
                                </DropdownItem>
                                <DropdownItem onClick={() => getExpensesReport(false)}>
                                    {t('export_charges.tooltip_details')}
                                </DropdownItem>
                                <DropdownItem onClick={() => getVehiclesDetailsReport()}>
                                    {t('export_vehicle_contract.title')}
                                </DropdownItem>
                            </>
                        }>
                        <div>
                            <FaFileExport size={24} className={styles.exportButton} color={Colors.primaryDark} />
                        </div>
                    </Dropdown>}
                    <Popover
                        contentContainerClassName={styles.filtersPopoverContainer}
                        containerStyle={{ boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}
                        positions={['bottom', 'left']}
                        align={'end'}
                        onClickOutside={() => updateTotalFilters(filters)}
                        content={setIsPopoverOpen => <VehiclesManagementFiltersScreen
                            filters={filters}
                            hasCountersPolicy={hasCountersPolicy}
                            onFilter={f => { setIsPopoverOpen(false); onChangeFilters(f) }}
                            onChange={f => updateTotalFilters(f)}
                        />}>
                        {(isPopoverOpen, setIsPopoverOpen) => (
                            <ScreenHeaderButton icon={FiltersIcon} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                                {filtersTotal > 0 &&
                                    <div className={styles.counterList}> <div className={styles.counterNumber}>{filtersTotal}</div> </div>
                                }
                            </ScreenHeaderButton>
                        )}
                    </Popover>
                    <ReactTooltip />
                </ScreenHeader>

                <FilterList filters={[
                    {
                        value: filters.costsStartDate && filters.costsEndDate ? <>{t('common.range.from')} <DateFormat format={DATE_FORMAT_MONTH_YEAR} value={filters.costsStartDate} /> {t('common.range.to').toLowerCase()} <DateFormat format={DATE_FORMAT_MONTH_YEAR} value={filters.costsEndDate} /></>
                            : filters.costsStartDate ? <>{t('common.range.from')} <DateFormat format={DATE_FORMAT_MONTH_YEAR} value={filters.costsStartDate} /></>
                                : filters.costsEndDate ? <>{t('common.range.to')} <DateFormat format={DATE_FORMAT_MONTH_YEAR} value={filters.costsEndDate} /></> : undefined,
                        label: t('vehicles_management.list.total_monthly_cost_date', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) }),
                        onRemove: () => { filters.costsStartDate = filters.costsEndDate = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.registrationNumber,
                        label: t('vehicle.registration_identification'),
                        onRemove: () => { filters.registrationNumber = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.segmentName,
                        label: t('vehicle.type'),
                        onRemove: () => { filters.segmentId = filters.segmentName = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.driverName,
                        label: t('common.state'),
                        onRemove: () => { filters.driverId = filters.driverName = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: !filters.costAverage || !filters.costAverage.length || !filters.costAverage.find(x => x !== undefined) ? undefined :
                            filters.costAverage.filter(i => i !== undefined).length === 2 ? <>{t('common.between')} <MoneyFormat value={filters.costAverage[0]} /> {t('common.and')} <MoneyFormat value={filters.costAverage[1]} /></>
                                : filters.costAverage[0] !== undefined ? <>{t('common.above')} <MoneyFormat value={filters.costAverage[0]} /></>
                                    : <>{t('common.below')} <MoneyFormat value={filters.costAverage[1]} /></>,
                        label: t('vehicles_management.list.average_monthly_cost', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) }),
                        onRemove: () => { filters.costAverage = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: !filters.costAccumulated || !filters.costAccumulated.length || !filters.costAccumulated.find(x => x !== undefined) ? undefined :
                            filters.costAccumulated.filter(i => i !== undefined).length === 2 ? <>{t('common.between')} <MoneyFormat value={filters.costAccumulated[0]} /> {t('common.and')} <MoneyFormat value={filters.costAccumulated[1]} /></>
                                : filters.costAccumulated[0] !== undefined ? <>{t('common.above')} <MoneyFormat value={filters.costAccumulated[0]} /></>
                                    : <>{t('common.below')} <MoneyFormat value={filters.costAccumulated[1]} /></>,
                        label: t('vehicles_management.list.total_monthly_cost', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) }),
                        onRemove: () => { filters.costAccumulated = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.useContractTypeName,
                        label: t('common.type_funding'),
                        onRemove: () => { filters.useContractTypeId = filters.useContractTypeName = undefined; onChangeFilters(filters) }
                    },

                    {
                        value: filters.useContractActive == undefined ? undefined : (filters.useContractActive ? <>{t('contracts.states.in_progress')}</>
                            : <>{t('contracts.states.expired')}</>),
                        label: t('common.state'),
                        onRemove: () => { filters.useContractActive = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.useContractEndsBefore && filters.useContractEndsAfter ? <>{t('common.range.from')} <DateFormat format={DATE_FORMAT_DEFAUT} value={filters.useContractEndsAfter} /> {t('common.range.to').toLowerCase()} <DateFormat format={DATE_FORMAT_DEFAUT} value={filters.useContractEndsBefore} /></>
                            : filters.useContractEndsAfter ? <>{t('common.range.from')} <DateFormat format={DATE_FORMAT_DEFAUT} value={filters.useContractEndsAfter} /></>
                                : filters.useContractEndsBefore ? <>{t('common.range.to')} <DateFormat format={DATE_FORMAT_DEFAUT} value={filters.useContractEndsBefore} /></> : undefined,
                        label: t('contracts.use_contract.expiration_date'),
                        onRemove: () => { filters.useContractEndsBefore = filters.useContractEndsAfter = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.inactives ? t('common.yes') : '',
                        label: t('vehicle.inactives'),
                        onRemove: () => { filters.inactives = false; onChangeFilters(filters) }
                    },
                ]} />

                {counters && renderCounterBoxes()}

                <Box>
                    <ListingTable
                        columns={tableColumns}
                        rows={vehicles}
                        onRowClick={(row, _, event) => onRowClick(event, row.id)}
                        onHref={row => `vehicles/details/${row.id}`}
                        allowHover={true}
                        initialSearch={{ colField: 'v.registration_number', isOrderAsc: true }}
                    />
                    <PaginationWithInfo
                        itemName={t('vehicles_management.list.vehicles')}
                        currentPage={currentPage}
                        pageItems={vehicles.length}
                        totalItems={totalItems}
                        onChange={onPageChange}
                    />
                </Box>

            </ScreenContainer>
        </ScreenTitle>
    );
}

export default VehiclesManagementScreen;
