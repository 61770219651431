/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import React, { memo, useState } from 'react';
import TextareaAutosize from 'react-autosize-textarea/lib';
import { useTranslation } from 'react-i18next';
import styles from './Input.module.scss';

type InputPreset = 'default' | 'login';

export type Props = {
    preset?: InputPreset;
    rows?: number | undefined;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;

const InputResizable = React.forwardRef<HTMLTextAreaElement, Props>(({ preset, rows, onChange, ...props }, ref) => {
    const presetClassName = preset ? styles[preset] : styles.default;

    const { t } = useTranslation();
    const [textLength, setTextLength] = useState<number>(0);

    return (
        <div>
            <TextareaAutosize
                style={{ resize: 'none' }}
                className={`${styles.textArea} ${presetClassName} ${props.className || ''} ${props.disabled ? styles.disabled : ''} `}
                {...props}
                ref={ref}
                onChange={e => {
                    setTextLength(e.currentTarget.value.length)
                    if (onChange) {
                        onChange(e as any);
                    }
                }}
                rows={rows ?? 1}
            />
            {textLength === props.maxLength && <div className={styles.maxLength}>{t('common.field_max_length', { value: textLength })}</div>}
        </div>
    );
});

export default memo(InputResizable);
