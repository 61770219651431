import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ReservationScreen.module.scss';
import { useToasts } from 'react-toast-notifications';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import Box from 'common/components/box/Box';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import { VehicleReservationViewModel } from 'api/vehicleReservations/models/VehicleReservationViewModel';
import { DATE_FORMAT_DEFAUT, DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import NoImage from 'assets/img/asset-default-image.png';
import Logger from 'common/services/Logger';
import AvatarWithText from 'common/components/avatar/AvatarWithText';
import Dropdown from 'common/components/dropdown/Dropdown';
import DropdownItem from 'common/components/popover/PopoverItem';
import { FaEllipsisV, FaInfoCircle } from 'react-icons/fa';
import Button from 'common/components/button/Button';
import Popover from 'common/components/popover/Popover';
import ReservationFormScreen from './components/form/ReservationFormScreen';
import VehicleReservationsService from 'api/vehicleReservations/VehicleReservationsService';
import DateFormat from 'common/components/dateFormat/dateFormat';
import colors from 'styles/export/colors.module.scss';
import ReservationFilters, { Filters } from './components/filters/ReservationFilters';
import { VehicleReservationsSearchCriteria } from 'api/vehicleReservations/models/VehicleReservationsSearchCriteria';
import { VehicleReservationDto } from 'api/vehicleReservations/models/VehicleReservationDto';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import Loading from 'common/services/Loading';
import { useParams } from 'react-router-dom';
import { ReservationsVehicleListItemDto } from 'api/vehicles/models/ReservationsVehicleListItemDto';
import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';

const itemsPerPage = DEFAULT_PAGINATION_ITEMS_PER_PAGE;

const ReservationScreen = () => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const imageCacheKey = useRef(new Date().getTime());

    const { itemId, type } = useParams<{ itemId: string, type: string }>();
    const [isSearch, search] = useState(type === 'search');

    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);

    const [vehicles, setVehicles] = useState<ReservationsVehicleListItemDto[]>([]);

    const [openRows, setOpenRows] = useState<string[]>([]);
    const [openReservationDetailsPopover, setOpenReservationDetailsPopover] = useState<string | null>(null);
    const [openReservationEditPopover, setOpenReservationEditPopover] = useState<string | null>(null);

    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<VehicleReservationDto | null>(null);
    const [parentItemToRemove, setParentItemToRemove] = useState<ReservationsVehicleListItemDto | null>(null);

    const [criteria, setCriteria] = useState<VehicleReservationsSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1
    });

    const [filters, setFilters] = useState<Filters>({});

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const canWrite = UsersService.hasPolicies(userProfile?.policies ?? [], ['SETTINGUP_RESERVATIONS_WRITE'], 'OR');
    const canEditAll = UsersService.hasPolicies(userProfile?.policies ?? [], ['SETTINGUP_RESERVATIONS_EDIT_ALL'], 'OR');

    const loadFilters = () => {
        const cachedFilters = localStorage.getItem('RESERVATIONSFILTER');
        if(cachedFilters != null){onChangeFilters(JSON.parse(cachedFilters),true)}
        else{getData()}
    }
      
    const saveFiltersOnCache = (f:Filters) => {
        setFilters(f);
        localStorage.setItem('RESERVATIONSFILTER', JSON.stringify(f));
    }

    const onChangeFilters = (f: Filters,isOnLoad?:boolean) => {

        criteria.registrationNumber= f.vehicleRegistrationNumber;
        criteria.brandId= f.vehicleBrandId;
        criteria.modelId= f.vehicleModelId;
        criteria.segmentId= f.vehicleSegmentId;
        criteria.startDate= f.reservationStartDate;
        criteria.endDate= f.reservationEndDate;

        if(isOnLoad && f.page){
            setCurrentPage(f.page);
            criteria.page = f.page
        }else{
            criteria.page = 1;
            f.page = 1;
            setCurrentPage(1);
        }
        saveFiltersOnCache(f);
        
        setOpenRows([]);
        getData();
    }

    
    useEffect(() => {
        loadFilters();
    }, []); //criteria

    useEffect(() => {
        setReservationFilters();
    }, [search]);

    const setReservationFilters = async () => {
        if (itemId && type === 'search') {
            const reservation = await VehicleReservationsService.getById(itemId);
            setFilters({
                vehicleRegistrationNumber: reservation.vehicleRegistrationNumber,
                reservationStartDate: reservation.startDate,
                reservationEndDate: reservation.endDate
            });
        }
    }

    const getData = async () => {
        Loading.show();

        try {
            criteria.page = currentPage;

            const vehiclesDB = await VehicleReservationsService.getVehicles(criteria);

            setCurrentPage(vehiclesDB.currentPage);
            setTotalItems(vehiclesDB.totalItems);
            setVehicles(vehiclesDB.items);

            if (type === 'search' && vehiclesDB.totalItems === 1) {
                toggleRowOpen(vehiclesDB.items[0], true);
            }

            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get vehicles list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });

            Loading.hide();
        }
    }

    const getReservations = async (vehicle: ReservationsVehicleListItemDto, page: number) => {
        if (vehicle.id) {

            Loading.show();
            try {
                const reservationsCriteria = {
                    itemsPerPage,
                    page,
                    orderBy: 'desc',
                    orderColumn: 'vr.start_date',
                    vehicleId: vehicle.id,
                    registrationNumber: criteria.registrationNumber,
                    brandId: criteria.brandId,
                    modelId: criteria.modelId,
                    segmentId: criteria.segmentId,
                    startDate: criteria.startDate,
                    endDate: criteria.endDate,
                };

                const reservationsList = await VehicleReservationsService.getListByVehicleId(reservationsCriteria);

                setVehicles(vv => {
                    const tempVehicles = vv.map(v => {
                        if (v.id === vehicle.id) {
                            const reservations = page > 1 ? (v.reservations || []) : [];

                            return {
                                ...v,
                                reservations: [...reservations, ...reservationsList.items],
                                reservationsReachedEnd: (reservationsList.totalPages == 0 || reservationsList.currentPage == reservationsList.totalPages) ? true : false,
                            };
                        }
                        return v;
                    });
                    return tempVehicles;
                });

                Loading.hide();
            } catch (error) {
                Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get reservations list`, error);
                addToast(t('common.messages.error_load_info'), { appearance: 'error' });

                Loading.hide();
            }
        }
    };

    const toggleRowOpen = (row: ReservationsVehicleListItemDto, forceOpen = false) => {
        if (!row.id) {
            return;
        }

        if (openRows.find(id => id === row.id) && !forceOpen) {
            setOpenRows(openRows.filter(id => id !== row.id));
        } else {
            if (!row.reservations || forceOpen) {
                getReservations(row, 1);
            }
            setOpenRows([...openRows, row.id]);
        }
    }

    const onRowClick = (row: ReservationsVehicleListItemDto) => {
        toggleRowOpen(row);
    }

    const onSubRowsLoadMore = (row: ReservationsVehicleListItemDto) => {
        const totalPages = Math.ceil((row.reservations || []).length / itemsPerPage);
        getReservations(row, totalPages + 1);
    }

    const canSubRowsLoadMore = (row: ReservationsVehicleListItemDto): boolean => {
        return !row.reservationsReachedEnd;
    }

    const isRowExpanded = (row: ReservationsVehicleListItemDto) => {
        return Boolean(openRows.find(id => id === row.id));
    }

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }

        try {
            await VehicleReservationsService.remove(itemToRemove);
            onCancelRemove();

            addToast(t('common.messages.record_delete_success'), { appearance: 'success' });

            if (parentItemToRemove) {
                getReservations(parentItemToRemove, 1);
            }
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete reservation`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
        }
    };

    const showRemoveItemDialog = async (item: VehicleReservationDto, parent?: ReservationsVehicleListItemDto) => {
        setItemToRemove(item);
        if (parent) {
            setParentItemToRemove(parent);
        }
        setShowRemoveModal(true);
    }

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onPageChange = (page: number) => {
        setCurrentPage(page);
        filters.page = page;
        
        saveFiltersOnCache(filters);
        getData();
    }

    // render cells
    const renderVehicleCell = (row: ReservationsVehicleListItemDto) => {
        return (
            <div className={styles.vehicleCell}>
                <div className={styles.imageCell}>
                    <div className={styles.contentImage} style={{ width: '5rem', height: '3rem' }}>
                        <div className={styles.img} style={{ width: '5rem', height: '3rem', backgroundImage: row?.photoUrl ? 'url(' + row?.photoUrl + '?_=' + imageCacheKey.current + ')' : 'url(' + NoImage + ')' }} />
                    </div>
                </div>
                <div className={styles.description}>
                    <div className={styles.bold}>{row.vehicleRegistrationNumber}</div>
                    <div className={styles.smallLabel}>{(row.vehicleBrandName ?? '') + (row.vehicleBrandName && row.vehicleModelName ? ' | ' : '')  + (row.vehicleModelName ?? '')}</div>
                </div>
            </div>
        )
    }

    const renderDateCell = (row: VehicleReservationViewModel) => {
        return (
            <div style={{ marginLeft: '2.5rem' }}>
                <div>
                    {row && row.startDate ?
                        <span>
                            <span className={styles.bold}>{t('reservations.list.date_start')}:</span> <DateFormat value={row.startDate} format={DATE_FORMAT_DEFAUT} />
                        </span>
                        : '-'}

                    {row && row.startDate && <span> {new Date(row.startDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>}
                </div>
                <div>
                    {row && row.endDate ?
                        <span>
                            <span className={styles.bold}>{t('reservations.list.date_end')}:</span> <DateFormat value={row.endDate} format={DATE_FORMAT_DEFAUT} />
                        </span>
                        : '-'}

                    {row && row.endDate && <span> {new Date(row.endDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} </span>}
                </div>
            </div>
        )
    }

    const renderDriverCell = (row: VehicleReservationViewModel) => {
        return (
            <div>
                <div>
                    <AvatarWithText src={row.driverPhotoUrl}
                        size="x2"
                        hideAvatar={!row.driverName}><div className={styles.bold}>{row.driverName || '-'}</div></AvatarWithText>
                </div>
                <div className={styles.driverEmail}>{row.driverEmail}</div>
            </div>
        )
    }

    const renderActionsCell = (row: VehicleReservationViewModel, _: any, index: number, parentRow?: ReservationsVehicleListItemDto) => {
        const elem = document.getElementById('root');
        const isOwner = row ? row.userCreatedId === userProfile?.id : true;
        const canEdit = canEditAll || (canWrite && isOwner);

        return (
            <>
                <Popover
                    positions={['left', 'top']}
                    align={'center'}
                    reposition
                    containerParent={elem ? elem : document.getElementsByTagName('body')[0]}
                    isOpen={openReservationDetailsPopover === row.id}
                    onChange={v => setOpenReservationDetailsPopover(v ? row.id! : null)}
                    content={setIsPopoverOpen =>
                        <ReservationFormScreen
                            vehicle={parentRow}
                            reservation={row}
                            mode={'details'}
                            onCancel={() => {
                                setIsPopoverOpen(false);
                            }}
                            onSave={() => {
                                if (parentRow) {
                                    toggleRowOpen(parentRow, true);
                                }
                            }}
                        />
                    } />

                <Popover
                    positions={['left', 'top']}
                    align={'center'}
                    reposition
                    containerParent={elem ? elem : document.getElementsByTagName('body')[0]}
                    isOpen={openReservationEditPopover === row.id}
                    onChange={v => setOpenReservationEditPopover(v ? row.id! : null)}
                    content={setIsPopoverOpen =>
                        <ReservationFormScreen
                            reservation={row}
                            vehicle={parentRow}
                            mode={'edit'}
                            onCancel={() => {
                                setIsPopoverOpen(false);
                            }}
                            onSave={() => {
                                if (parentRow) {
                                    toggleRowOpen(parentRow, true);
                                }
                            }}
                        />
                    } />

                <div style={{ paddingRight: '1rem', position: 'relative' }}>
                    <Dropdown
                        options={
                            <>
                                <DropdownItem onClick={() => setOpenReservationDetailsPopover(row.id!)}>
                                    {t('common.details')}
                                </DropdownItem>

                                {canEdit && <DropdownItem onClick={v => setOpenReservationEditPopover(v ? row.id! : null)}>
                                    {t('common.edit')}
                                </DropdownItem>}

                                {canEdit && <DropdownItem onClick={() => showRemoveItemDialog(row, parentRow)}>
                                    {t('common.remove')}
                                </DropdownItem>}
                            </>
                        }
                    >
                        <div>
                            <FaEllipsisV />
                        </div>
                    </Dropdown>
                </div>
            </>
        );
    }

    const renderButtonCell = (row: ReservationsVehicleListItemDto, column: any, index: number) => {
        const elem = document.getElementById('root');
        const canEdit = canEditAll || canWrite;

        if(canEdit)
        {
            return (
                <Popover
                    positions={['left', 'top']}
                    align={'center'}
                    reposition
                    containerParent={elem ? elem : document.getElementsByTagName('body')[0]}
                    content={setIsPopoverOpen =>
                        <ReservationFormScreen
                            vehicle={row}
                            onCancel={() => {
                                setIsPopoverOpen(false);
                            }}
                            onSave={() => {
                                setIsPopoverOpen(true);
                                toggleRowOpen(row, true);
                            }}
                        />
                    }>
                    {(isPopoverOpen, setIsPopoverOpen) => (
                        <Button
                            text={t('reservations.list.reserve_vehicle')}
                            size={'extraSmall'}
                            className={styles.reserveButton}
                            onClick={() => setIsPopoverOpen(!isPopoverOpen)} />
                    )}
                </Popover>
            );
        }
        else 
        {
            return (<></>);
        }
        
    };

    const formateDate = (date: Date) => {
        const d = dayjs(date);
        const now = dayjs();

        let result = d.format('D MMMM');
        if (d.get('year') !== now.get('year')) {
            result += ` ${d.format('yyyy')}`;
        }

        result += ` ${t('reservations.list.at')} ${d.format('H')}`;

        if (d.get('minute') > 0) {
            result += `:${d.format('mm')}`;
        }

        result += 'h';

        return result;
    }

    const renderAvailability = (row: ReservationsVehicleListItemDto) => (
        <div>
            {row.availability && t('reservations.list.available')}
            {!row.availability && row.availabilities && row.availabilities.length === 0 && t('reservations.list.not_available')}
            {!row.availability && row.availabilities && row.availabilities.length > 0 && row.availabilities.map((a, i) => (
                <span key={i}>{
                    a.endDate
                        ? t('reservations.list.available_between', { dateFrom: formateDate(new Date(a.startDate)), dateTo: formateDate(new Date(a.endDate)) })
                        : t('reservations.list.available_from', { date: formateDate(new Date(a.startDate)) })
                } {row.availabilities && i < row.availabilities.length - 1 ? ', ' : ''}
                </span>
            ))}
        </div>
    );
    // end render cells

    // Table rows
    const tableColumns: ListingTableColumn<ReservationsVehicleListItemDto>[] = [
        {
            name: t('reservations.list.vehicle'),
            renderCell: renderVehicleCell,
            width: 500,
        },
        {
            name: t('reservations.list.driver'),
            width: 600,
            hideOn: ['sm']
        },
        {
            name: t('reservations.list.reason'),
            width: 600,
            hideOn: ['sm', 'md']
        },
        {
            name:
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ display: 'flex' }}>{t('reservations.list.availability')}</span>
                    <span style={{ marginLeft: '0.5rem', cursor: 'pointer', color: colors.gray, display: 'flex' }} data-tip={t('reservations.list.availability_message')}><FaInfoCircle />
                        <ReactTooltip />
                    </span>
                </div>,
            renderCell: renderAvailability,
            hideOn: ['sm', 'md', 'lg'],
            width: 300,
        },
        {
            renderCell: renderButtonCell,
            preventClick: true,
            width: 100,
        }
    ];

    // Table sub rows
    const tableSubColumns: ListingTableColumn<VehicleReservationViewModel, ReservationsVehicleListItemDto>[] = [
        {
            name: t('reservations.list.vehicle'),
            renderCell: renderDateCell,
            width: 500,
        },
        {
            name: t('reservations.list.driver'),
            renderCell: renderDriverCell,
            width: 600,
            hideOn: ['sm']
        },
        {
            name: t('reservations.list.reason'),
            renderCell: row =>
                <div>
                    {row.reason}
                </div>,
            width: 600,
            hideOn: ['sm', 'md']
        },
        {
            width: 300
        },
        {
            renderCell: renderActionsCell,
            width: 100,
            preventClick: true,
            cellStyle: { overflow: 'unset' },
            cellAlignment: 'right',
        }
    ];

    return (
        <ScreenTitle title={t('reservations.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('reservations.title')} />

                <Box>
                    <div className={styles.filters}>
                        <ReservationFilters
                            load={isSearch}
                            filters={filters}
                            onFilter={f => { onChangeFilters(f) }}
                        />
                    </div>

                    <div className={styles.divider} />

                    <div className={styles.alertMessage}>
                        <span className={styles.iconAlertMessage}><FaInfoCircle /></span>
                        <span>{t('reservations.only_show_vehicles_available_for_reservation')}</span>
                    </div>

                    <ListingTable
                        columns={tableColumns}
                        subColumns={tableSubColumns}
                        rows={vehicles}
                        onLoadSubRows={row => row.reservations || []}
                        onRowClick={onRowClick}
                        isExpanded={isRowExpanded}
                        allowHover={true}
                        allowSubRowHover={false}
                        colorSubRow={colors.alabaster}
                        hasSubRows={true}
                        onSubRowsLoadMore={onSubRowsLoadMore}
                        canSubRowsLoadMore={canSubRowsLoadMore}
                        labelSubRowWithoutResults={t('reservations.without_reservations')}
                    />

                    <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('common.messages.remove_record_with_ident')} />

                    <PaginationWithInfo
                        itemName={t('reservations.list.vehicles')}
                        currentPage={currentPage}
                        pageItems={vehicles.length}
                        totalItems={totalItems}
                        onChange={onPageChange}
                    />

                </Box>
            </ScreenContainer>
        </ScreenTitle>
    );
}

export default ReservationScreen;