import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import Button from 'common/components/button/Button';
import styles from './DamageTypesDetails.module.scss';
import InputError from 'common/components/inputError/InputError';
import Input from 'common/components/input/Input';
import Label from 'common/components/label/Label';
import FormItem from 'common/components/formItem/FormItem';
import { useForm } from 'react-hook-form';
import { DEFAULT_INPUT_RULES_WITH_REQUIRED, LOGGER_LOG_TYPE } from 'Config';
import GreyArrow from 'assets/svg/Arrow-grey.svg';
import { useEffect, useState } from 'react';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { DiagnosticTypesDto } from 'api/diagnosticTypes/models/DiagnosticTypesDto';
import DiagnosticTypesService from 'api/diagnosticTypes/DiagnosticTypesService';
import { useHistory, useParams } from 'react-router-dom';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';

type Props = {
};

const DamageTypesDetails: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const history = useHistory();
    // eslint-disable-next-line
    let { type, itemId } = useParams<{ type: string, itemId: string }>();
    const [damageType, setDamageType] = useState<DiagnosticTypesDto | null>(null);

    const [itemToRemove, setItemToRemove] = useState<DiagnosticTypesDto>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);

    const form = useForm<DiagnosticTypesDto>({ shouldUnregister: false });
    const { register, handleSubmit, setValue, reset, errors } = form;

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasDiagnosticTypesWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_DIAGNOSTIC_TYPES_WRITE']);

    const onSubmit = async (formData: DiagnosticTypesDto) => {
        try {
            if (!hasDiagnosticTypesWritePolicy) { return; }

            Loading.show();

            if (damageType != null) {
                damageType.name = formData.name;
                await DiagnosticTypesService.update(damageType);
            } else {
                itemId = await DiagnosticTypesService.create(formData);
                getData();
            }

            Loading.hide();
            addToast(t('common.messages.record_save_success'), {
                appearance: 'success',
            });

            history.push(`/settings/damageTypes/details/${itemId}`);
        } catch (error) {
            Loading.hide();

            if (error?.response?.status === 409) {
                addToast(t('common.messages.name_already_exists'), { appearance: 'warning' });
                return;
            }

            addToast(t('common.messages.record_save_error'), {
                appearance: 'error',
            });

            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                `Couldn't create or update damage type`,
                error
            );
        }
    };

    const getData = async () => {
        if (itemId) {
            try {
                Loading.show();

                const result = await DiagnosticTypesService.getById(itemId);
                setDamageType(result);
                reset(result);

                Loading.hide();
            }
            catch (error) {
                addToast(t('common.messages.error_load_info'), {
                    appearance: 'error',
                });

                Logger.error(
                    LOGGER_LOG_TYPE.REQUEST,
                    `Couldn't get damage type`,
                    error
                );
                Loading.hide();
            }
        }
    }

    const showRemoveItemDialog = (item: DiagnosticTypesDto) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await DiagnosticTypesService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                history.push(`/settings/damageTypes/list`);
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    useEffect(() => {
        getData();
    }, []);


    const onGoBack = () => {
        if(damageType){
            reset(damageType);
        }
        history.goBack();
    }

    const isDanger = form.watch('isDanger');

    return (
        <div>
            <div className={styles.pageNameContainer}>
                <div className={styles.pageNameContent} onClick={() => history.push(`/settings/damageTypes/list`)} >
                    <img className={styles.pageNameContentImage} src={GreyArrow} />
                    <span className={styles.pageNameContentText}>
                        {t('settings.diagnosis_types')}
                    </span>
                </div>
            </div>

            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col xs={12} sm={6}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('diagnosis_types.name')} {type !== 'details' ? '*' : ''}
                            </Label>
                            <Input
                                name='name'
                                placeholder={t('diagnosis_types.name')}
                                defaultValue={damageType?.name}
                                ref={register({ ...DEFAULT_INPUT_RULES_WITH_REQUIRED })}
                                disabled={type === 'details'}
                            />
                            <InputError error={errors.name} maxLength={250} />
                        </FormItem>
                    </Col>
                </Row>

                <div className={styles.buttonContainer}>
                    <Button preset={'secondary'}
                        type='button'
                        text={t('common.cancel')}
                        onClick={() => onGoBack()} />
                    {type === 'details' && hasDiagnosticTypesWritePolicy &&
                        <Button
                            type="button"
                            text={t('common.remove')}
                            preset={'danger'}
                            onClick={() => showRemoveItemDialog({ id: damageType?.id } as DiagnosticTypesDto)} />
                    }
                    {type === 'details' && hasDiagnosticTypesWritePolicy && <Button type='button' text={t('common.edit')} onClick={() => history.push(`/settings/damageTypes/edit/${itemId}`)} />}
                    {type !== 'details' && hasDiagnosticTypesWritePolicy && <Button type='submit' text={t('common.save')} />}

                    <QuestionYesNo message={t('common.messages.remove_damage_type', { type: damageType?.name ?? '' })}
                        isVisible={dialogDeleteItemIsOpen}
                        onYes={() => removeItem()}
                        onNo={() => setDialogDeleteItemIsOpen(false)} />
                </div>
            </form>
        </div>
    );
};

export default DamageTypesDetails;
