import { AlertsTotalsDto } from 'api/alerts/models/AlertsTotalsDto';
import { SetTotalsAction, SET_TOTALS } from './type';
import { Dispatch } from 'redux';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';
import AlertsService from 'api/alerts/AlertsService';

export const updateTotals = () => async (dispatch: Dispatch) => {
    try {
        setTimeout(async () => {
            const totals = await AlertsService.getTotals();
            dispatch(setTotals(totals));
        }, 1000);

    } catch (error) {
        Logger.error(LOGGER_LOG_TYPE.ALERTS, `Couldn't get alerts list`, error);
    }
}

export const setTotals = (totals: AlertsTotalsDto): SetTotalsAction => ({
    type: SET_TOTALS,
    totals,
});
