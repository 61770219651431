import Http from 'common/services/Http';
import TrackingDevice from './models/TrackingDevice';
import TrackingCoordinates from './models/TrackingCoordinates';
import TrackingRoutes from './models/TrackingRoutes';
import { TrackingItemDto, TrackingPointItemDto } from './models/TrackingItemDto';
import TrackingVehicle from './models/TrackingVehicle';
import TrackingVehicleSearchCriteria from './models/TrackingVehicleSearchCriteria';
import { VehiclesSearchCriteria } from 'api/vehicles/models/VehiclesSearchCriteria';
import { VehicleViewModel } from 'api/vehicles/models/VehicleViewModel';
import { Paged } from 'api/common/types/Page';

class TrackingService {

    public getDevicesList() {
        return Http.get<TrackingDevice[]>(`tracking/devices`);
    }

    public getVehiclesList(vehicleIds: string[]) {
        return Http.post<TrackingVehicle[]>(`tracking/vehicles`, { vehicleIds });
    }

    public getCoordinates(vehicleId: string) {
        return Http.get<TrackingCoordinates>(`tracking/coordinates/${vehicleId}`);
    }

    public getRoutes(vehicleId: string, date: Date, orderColumn?: string, orderBy?: string) {
        return Http.post<TrackingRoutes[]>('tracking/routes', { vehicleId, date, orderColumn, orderBy });
    }

    public getRoutesTotals(vehicleId: string, date: Date) {
        return Http.post<TrackingRoutes[]>('tracking/routes-totals', { vehicleId, date });
    }

    public getPosition(vehicleId: string) {
        return Http.post<TrackingItemDto>('tracking/current-position', { vehicleId });
    }

    public getAggregatePositions(criteria: TrackingVehicleSearchCriteria) {
        return Http.post<Paged<TrackingItemDto>>('tracking/aggregate-position', criteria);
    }

    public getAggregatePositionsPoints(criteria: TrackingVehicleSearchCriteria) {
        return Http.post<TrackingPointItemDto[]>('tracking/aggregate-position-points', criteria);
    }

    public getWaypoints(vehicleId: string, start: number, stop: number) {
        return Http.post<TrackingItemDto[]>('tracking/waypoints', { vehicleId, start, stop });
    }

    public hydrateGpsData(criteria: VehiclesSearchCriteria) {
        return Http.get<VehicleViewModel[]>('tracking/hydrate-gps-data', criteria);
    }

    public updateAddress(id: number, address: string) {
        return Http.put<string>('tracking/tracking-routes-address/' + id, { id, address });
    }
}

export default new TrackingService();