import { DATE_FORMAT_DEFAUT, DATE_TIME_FORMAT_DEFAULT } from 'Config';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import DateFormat from 'common/components/dateFormat/dateFormat';
import MoneyFormat from 'common/components/moneyFormat/MoneyFormat';
import colors from 'styles/export/colors.module.scss';
import styles from './IconWithText.module.scss';

type Props = {
    icon: any;
    value?: string | number | null | undefined;
    valueDate?: string | Date | null;
    valueHour?: string | null;
    suffix?: string;
    isNumeric?: boolean;
    isDate?: boolean;
    color?: string;
    size?: 'sm' | 'lg';
    tooltip?: string
    className?: string;
    decimalScale?: number;
    fixedDecimalScale?: boolean;
};

const IconWithText = ({ icon, value, valueDate, valueHour, suffix, isNumeric, isDate, color, size, tooltip, className, decimalScale, fixedDecimalScale, ...props }: Props) => {
    return (
        <>
            <div className={`${!!className && className} ${styles.container}`} data-tip={tooltip}>
                <div className={`${styles.icon} ${size === 'sm' && styles.iconSmall} ${size === 'lg' && styles.iconLarge}`} style={{ backgroundImage: 'url(' + icon + ')', color: color ?? colors.danger }} />
                {isNumeric && <MoneyFormat value={value || value == 0 ? value : '-'} suffix={suffix} fixedDecimalScale={false} decimalScale={decimalScale} />}
                {isDate && valueDate && <DateFormat value={valueDate} format={valueHour ? DATE_TIME_FORMAT_DEFAULT.toString() : DATE_FORMAT_DEFAUT.toString()} />}

                {isNumeric !== true && isDate !== true && fixedDecimalScale !== true ? value ? <span style={{ color: color ?? colors.black }}>{value}</span> : '-' : ''}

                {fixedDecimalScale && <MoneyFormat value={value ? value : '-'} suffix={suffix} fixedDecimalScale={true} />}

            </div>
            <ReactTooltip />
        </>
    );
}

export default IconWithText;