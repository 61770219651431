import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ImportChargeFormScreen.module.scss';
import { useToasts } from 'react-toast-notifications';
import { DATE_TIME_FORMAT_DEFAUT, LOGGER_LOG_TYPE, removeAccents } from 'Config';
import Logger from 'common/services/Logger';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { Controller, useForm } from 'react-hook-form';
import FormItem from 'common/components/formItem/FormItem';
import { Col, Row } from 'react-flexbox-grid';
import Label from 'common/components/label/Label';
import Button from 'common/components/button/Button';
import SelectController from 'common/components/select/SelectController';
import Loading from 'common/services/Loading';
import InputError from 'common/components/inputError/InputError';
import { ImportExpenseFileItemDto } from 'api/import-charges/models/ImportExpenseFileDto';
import DateFormat from 'common/components/dateFormat/dateFormat';
import GenericTypesService from 'api/genericTypes/GenericTypesService';
import Utils from 'common/services/Utils';
import ImportChargeService from 'api/import-charges/ImportChargeService';
import VehiclesService from 'api/vehicles/VehiclesService';
import Select from 'common/components/select/Select';

type Props = {
    item: ImportExpenseFileItemDto;
    mode?: string;
    onCancel: () => void;
    onSave?: (f: ImportExpenseFileItemDto) => void;
    supplierId?: string | undefined;

} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ImportChargeFormScreen: React.FC<Props> = ({ item, mode, onCancel, onSave, supplierId }: Props) => {

    const { t } = useTranslation();
    const { addToast } = useToasts();

    const form = useForm<ImportExpenseFileItemDto>({ shouldUnregister: false });
    const { setValue, getValues, errors } = form;

    const [typesOptions, setTypes] = useState<SelectValueLabel[]>([]);
    const [isDetails, setIsDetails] = useState<boolean>(mode == 'details');
    const [itemState, setItemState] = useState<ImportExpenseFileItemDto | null>();
    const [vehiclesOptions, setVehicles] = useState<SelectValueLabel[]>([]);

    const getData = async () => {
        try {
            Loading.show();

            const [vehiclesOptionsList, __typesOptions] = await Promise.all([
                VehiclesService.catalog(),
                supplierId ? GenericTypesService.catalogChargeBySupplierId(supplierId) : GenericTypesService.catalogCharge()
            ]);

            setVehicles(vehiclesOptionsList);

            const types2 = __typesOptions.map(x => ({ ...x, name: x.label, label: x.isSystem ? t(`common.generic_types.${x.label}` as any) : x.label }));
            const types = Utils.sortAlphabetically(types2);
            setTypes(types);
            if (item) {
                setItemState(item);
                form.reset(item);
            }

        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get generic types`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
        finally {
            Loading.hide();
        }
    }


    const onSubmit = async (r: ImportExpenseFileItemDto) => {
        try {
            Loading.show();
            await ImportChargeService.updateFileItem(r);

            if (onSave) {
                onSave(r);
            }
            else {
                Loading.hide();
            }
        } catch (error) {
            Loading.hide();
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't create or update import expense file`, error);
        }
    }

    useEffect(() => {
        getData();
    }, []);


    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className={styles.formContent}>
                <div>
                    <FormItem>
                        <Row>
                            <div className={styles.details}>
                                <div className={styles.description}>
                                    <div>{t('import_charges.line')} : {item?.line}</div>
                                    <div>{t('common.date')} : <DateFormat value={item.date} format={DATE_TIME_FORMAT_DEFAUT} /></div>
                                    <div>{t('import_charges.registration_number')} : {item?.registrationNumber}</div>
                                    <div>{t('import_charges.expense')} : {item?.typeCode}</div>
                                </div>
                            </div>
                        </Row>

                        <div className={styles.divider} />

                        <Row>
                            <Col xs={12}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('common.type')}</Label>
                                    <SelectController
                                        form={form}
                                        name="typeId"
                                        placeholder={t('common.type')}
                                        options={typesOptions}
                                        rules={{ required: true }}
                                        isDisabled={isDetails}
                                        isClearable={false}
                                        onChange={tp => {
                                            setValue('typeName', tp ? tp.label : null)
                                        }}
                                        filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                    />
                                    <InputError error={errors.vehicleId} />
                                </FormItem>
                            </Col>
                            <Col xs={12}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('vehicle.vehicle')}</Label>
                                    <Controller
                                        render={({ value }) => {
                                            return (
                                                <Select
                                                    options={vehiclesOptions}
                                                    isClearable
                                                    isDisabled={isDetails}
                                                    placeholder={t('vehicle.vehicle')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        setValue('vehicleId', data?.value ?? null);
                                                    }}
                                                    value={vehiclesOptions.find(x => x.value === value) ?? null}
                                                />
                                            );
                                        }}
                                        control={form.control}
                                        name="vehicleId"
                                        defaultValue={getValues('vehicleId')} />
                                </FormItem>
                            </Col>
                        </Row>
                    </FormItem>
                    <br /><br /><br />
                </div>

                <div className={styles.buttonsFooter}>
                    <FormItem>
                        <Button
                            text={t('common.cancel')}
                            size={'normal'}
                            preset={'secondary'}
                            type='reset'
                            onClick={() => onCancel()}
                        />

                        {!isDetails &&
                            < Button
                                type='submit'
                                text={t('common.save')}
                                size={'normal'}
                            />
                        }
                    </FormItem>
                </div>
            </div>
        </form>
    );
}

export default ImportChargeFormScreen;