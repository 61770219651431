import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import styles from './AlertsList.module.scss';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import { DATE_FORMAT_DEFAUT, DATE_TIME_FORMAT_DEFAULT, LOGGER_LOG_TYPE } from 'Config';
import Logger from 'common/services/Logger';
import Loading from 'common/services/Loading';
import AlertsService from 'api/alerts/AlertsService';
import { AlertsListItemDto } from 'api/alerts/models/AlertsListItemDto';
import CheckBox from 'common/components/checkBox/CheckBox';
import { updateTotals } from 'store/alerts/action';
import { AlertsSearchCriteria } from 'api/alerts/models/AlertsSearchCriteria';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { Reducers } from 'store/types';
import NoImage from 'assets/img/asset-default-image.png';
import DateFormat from 'common/components/dateFormat/dateFormat';
import AvatarWithText from 'common/components/avatar/AvatarWithText';
import Badge from 'common/components/badge/Badge';
import { getAlertTypeColor } from 'common/utils/AlertsUtils';

type Props = {
    alerts: AlertsListItemDto[];
    criteria: AlertsSearchCriteria;
    totalItems: number;
    onChangeCriteria: (criteria: AlertsSearchCriteria) => void;
    onReadAlert: () => Promise<void>;
    vehicleId?: string | undefined;
    editable: boolean;
    onPageChangeCallback?: (page?:number,saveOnFilter?:boolean) => void;
}

const AlertsList = ({ alerts, criteria, totalItems, vehicleId, onChangeCriteria, onReadAlert,onPageChangeCallback, editable }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const history = useHistory();

    const imageCacheKey = useRef(new Date().getTime());

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasAlertsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_ALERTS_READ']);

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        onChangeCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const tableColumns: ListingTableColumn<AlertsListItemDto>[] = useMemo(() => {
        const cols: ListingTableColumn<AlertsListItemDto>[] = [            
            {
                name: t('alerts.list.alert_receiver_date'),
                renderCell: row => <div>
                                    <AvatarWithText src={row.userPhotoUrl} 
                                    size="x2" 
                                    hideAvatar={!row.userName}><div className={styles.bold}>{row.userName || '-'}</div></AvatarWithText>
                                <div className={styles.dateCreated}><DateFormat value={row.createdDate} format={DATE_TIME_FORMAT_DEFAULT} /></div>                    
                        </div>,
                onSearch: onTableFilter,
                searchField: 'a.created_date',
                hideOn: ['sm', 'md', 'lg'],
            },
            {
                name: t('alerts.list.type'),
                renderCell: row => (<>
                     <Badge
                        backgroundColor={getAlertTypeColor(row.type).rowColor}
                        textColor={row.color?.textColor}
                    >
                        {row.subject ? t(`alerts.types.${row.type}` as any) : ''}
                    </Badge>                    
                    <div className={styles.typeDescription}>{row.subject}</div>
                    </>
                ),
                onSearch: onTableFilter,
                searchField: 'a.type',
            },
            {
                name: t('alerts.list.message'),
                renderCell: row => (
                    <div className={styles.messageContainer}>
                        <p className={styles.message}>{row.message}</p>
                        {/* <p className={styles.annotation}>{row.annotationDescription}</p> */}
                    </div>
                ),
                onSearch: onTableFilter,
                searchField: 'a.message',
                hideOn: ['sm']
            },           
            {
                name: t('alerts.list.read'),
                renderCell: row => <>{(
                    hasAlertsWritePolicy && editable ? <CheckBox
                        checked={Boolean(row.readDate)}
                        readOnly
                        round
                        color={Boolean(row.readDate) ? row.colorType?.backgroundColor : undefined}
                    /> : <></>
                )}
                {row.readDate && <div className={styles.dateReaded}>{t('alerts.list.read')}:&nbsp;<DateFormat value={row.readDate} format={DATE_FORMAT_DEFAUT} /></div>}
                </>,
                width: 50,
                onSearch: onTableFilter,
                searchField: 'au.read_date is null'
            }
        ];

        if (!vehicleId) {
            cols.unshift(
                {
                    renderCell: row =>
                        <div className={styles.imageDescCell}>
                            <div className={styles.contentImage} style={{ width: '5rem', height: '3rem' }}>
                                <div className={styles.img} style={{ width: '5rem', height: '3rem', backgroundImage: row?.vehiclePhotoUrl ? 'url(' + row?.vehiclePhotoUrl + '?_=' + imageCacheKey.current + ')' : 'url(' + NoImage + ')' }} />
                            </div>
                        </div>,
                    width: 90,
                    onlyImageColumn: true,
                    hideOn: ['sm']
                },
                {
                    name: t('alerts.list.vehicle'),
                    renderCell: row =>
                        <div className={styles.descCell}>
                            <div className={styles.columnPlate}>{row.vehiclePlate}</div>
                            <div className={styles.columnBrand}>{(row.vehicleBrandName ?? '') + (row.vehicleBrandName && row.vehicleModelName ? ' | ' : '')  + (row.vehicleModelName ?? '') }</div>
                        </div>,
                    onSearch: onTableFilter,
                    searchField: 'v.registration_number',
                    width: '10%'
                }
            );
        }

        return cols;
    }, [onTableFilter]);

    const onPageChange = (page: number) => {
       if(onPageChangeCallback){onPageChangeCallback(page,false)}
       else{
            onChangeCriteria({
            ...criteria,
            page,
            });
        }
    }

    const navigate = (row: AlertsListItemDto, event: any) => {

        const url = navigateUrl(row);
        if (url!= '')
        {
            if (event.ctrlKey) {
                window.open(url, '_blank');
            }
            history.push(url);
        }
    }

    const navigateUrl= (row: AlertsListItemDto) =>{
        switch (row.type)
        {
            case 'VERIFICATION_NEW_ALERT': return `/vehicles/details/${row.vehicleId}/verifications/details/${row.referenceId}`;
            case 'ANNOTATION_INTERVENTION_TYPE_INFORMATION_ALERT': return `/vehicles/details/${row.vehicleId}/verifications/details/${row.verificationId}`;
            case 'ANNOTATION_INTERVENTION_TYPE_INTERVENTION_ALERT': return `/vehicles/details/${row.vehicleId}/verifications/details/${row.verificationId}`
            case 'ANNOTATION_INTERVENTION_TYPE_URGENT_ALERT': return `/vehicles/details/${row.vehicleId}/verifications/details/${row.verificationId}`
            case 'MAINTENANCE_NEW_ALERT': return `/vehicles/details/${row.vehicleId}/maintenances/details/${row.referenceId}`;            
            case 'MAINTENANCE_PLANNED_ALERT': return `/vehicles/details/${row.vehicleId}/maintenances/details/${row.referenceId}`;            
            case 'MAINTENANCE_NEW_ALERT': return `/vehicles/details/${row.vehicleId}/maintenances/details/${row.referenceId}`;
            case 'RESERVATION_NEW_ALERT': return `/reservations/search/${row.referenceId}`;
            case 'EXPENSE_NEW_ALERT': return `/vehicles/details/${row.vehicleId}/costs/details/${row.referenceId}`;
            case 'EXPENSE_TREATED_ALERT': return `/vehicles/details/${row.vehicleId}/costs/details/${row.referenceId}`;
            case 'EXPENSE_NOT_TREATED_ALERT': return `/vehicles/details/${row.vehicleId}/costs/details/${row.referenceId}`;
            case 'EXPENSE_REFUND_APPROVED_ALERT': return `/vehicles/details/${row.vehicleId}/costs/details/${row.referenceId}`;
            case 'EXPENSE_REFUND_REFUSED_ALERT': return `/vehicles/details/${row.vehicleId}/costs/details/${row.referenceId}`;
            case 'CONTRACT_INSURANCE_ALERT': return `/vehicles/details/${row.vehicleId}/contract/details/insurance/details/${row.referenceId}`;
            case 'CONTRACT_MAINTENANCE_ALERT': return `/vehicles/details/${row.vehicleId}/contract/details/maintenance/details/${row.referenceId}`;
            case 'CONTRACT_USE_ALERT': return `/vehicles/details/${row.vehicleId}/contract/details/use/details/${row.referenceId}`;
            default: 
                return '';
        }
    }

    const readAlert = async (row: AlertsListItemDto, event: any) => {
        if(onPageChangeCallback){onPageChangeCallback(undefined,true)}
        if (row.readDate) {
            navigate(row, event);
            return;
        }

        try {
            Loading.show();
            if (hasAlertsWritePolicy) {
                await AlertsService.read(row.id);
                dispatch(updateTotals());
            }

            navigate(row, event);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.ALERTS, `Couldn't get alerts list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    return (
        <div>
            <ListingTable
                columns={tableColumns}
                rows={alerts}
                onRowClick={(row, _, event) => readAlert(row, event)}
                allowHover={true}
                initialSearch={{ colField: 'a.created_date', isOrderAsc: false }}
                rowStyle={(row, _1, _2, colIndex) => ({
                    //backgroundColor: colIndex > 0 ? row.color?.rowColor : colors.white,
                    borderBottom: '1px solid #EAEAEA',
                })}
                className={styles.table}
            />
            <PaginationWithInfo
                itemName={t('alerts.list.alerts')}
                currentPage={criteria.page}
                pageItems={alerts.length}
                totalItems={totalItems}
                onChange={onPageChange}
            />
        </div>
    );
}

export default AlertsList;
