import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { UpdateUserDto } from 'api/users/models/UpdateUserDto';
import Loading from 'common/services/Loading';
import UsersService from 'api/users/UsersService';
import Logger from 'common/services/Logger';
import Button from 'common/components/button/Button';
import InputError from 'common/components/inputError/InputError';
import Input from 'common/components/input/Input';
import Label from 'common/components/label/Label';
import FormItem from 'common/components/formItem/FormItem';
import { useForm } from 'react-hook-form';
import { DEFAULT_EMAIL_RULES_WITH_REQUIRED, DEFAULT_INPUT_RULES, DEFAULT_INPUT_RULES_WITH_REQUIRED, LOGGER_LOG_TYPE, STORAGE, removeAccents } from 'Config';
import React, { useEffect, useState } from 'react';
import { CheckAvailabilityDto } from 'api/common/models/CheckAvailabilityDto';
import styles from './AccountScreen.module.scss';
import { Col, Row } from 'react-flexbox-grid';
import CustomFile from 'common/models/CustomFile';
import MediaSelector from 'common/components/mediaSelector/MediaSelector';
import { Reducers } from 'store/types';
import { useDispatch, useSelector } from 'react-redux';
import { UserProfile } from 'api/account/models/UserProfile';
import { setProfile, updateRefreshToken } from 'store/authentication/action';
import { useParams } from 'react-router-dom';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import Utils from 'common/services/Utils';
import authService from '../../common/services/AuthorizeService';
import { ApplicationPaths } from '../../Config';
import LanguagesService from 'api/languages/LanguagesService';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import SelectController from 'common/components/select/SelectController';
import Separator from 'common/components/separator/Separator';
import Tabs, { TabItem } from 'common/components/tabs/Tabs';
import Box from 'common/components/box/Box';
import PlansScreen from './components/PlansScreen';
import Storage from 'common/services/Storage';
import i18n from 'common/services/I18n';
import { CompanyDto } from 'api/companies/models/CompanyDto';
import CompaniesService from 'api/companies/CompaniesService';
import CountriesService from 'api/countries/CountriesService';


const AccountScreen = () => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { type, tabid } = useParams<{ type: string, tabid: string }>();
    const [user, setUser] = useState<UpdateUserDto>();
    const [company, setCompany] = useState<CompanyDto>();
    const [username, setUsername] = useState('');
    const formUser = useForm<UpdateUserDto>({ shouldUnregister: false });
    const formCompany = useForm<CompanyDto>({ shouldUnregister: false });

    const [isDetails, setIsDetails] = useState<boolean>(type == 'details');
    const [mediaFile, setMediaFile] = useState<CustomFile | null>(null);
    const [medias, setMedias] = useState<CustomFile[]>([]);
    const [removeMedia, setRemoveMedia] = useState(false);
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const [languages, setLanguages] = useState<SelectValueLabel[]>([]);
    const [tabId, setTabId] = useState<string>(tabid ? tabid : 'account')
    const [countries, setCountries] = useState<SelectValueLabel[]>([]);
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasCompaniesWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_MY_COMPANY_WRITE']);
    const [vatDisabled, setVatIsDisabled] = useState<boolean>(false);
    const [changePassword, setChangePassword] = useState<boolean>(false);

    const dispatch = useDispatch();

    const onCancel = () => {
        setIsDetails(true);
        setChangePassword(false);
        getData();
    };

    const config2FA = async () => {
        const isAuthenticated = await authService.isAuthenticated();
        if (isAuthenticated) {
            window.location.replace(ApplicationPaths.IdentityTwoFactorAuthenticationPath)
        }
    }

    const onChangePassword = () => {
        setChangePassword(!changePassword);
        if(changePassword){
            formUser.setValue('newPassword', undefined);
            formUser.setValue('confirmPassword', undefined);
        }
    };

    const getData = async () => {
        try {
            Loading.show();
            const [
                user,
                langs,
                company
            ] = await Promise.all([
                UsersService.getLoggedUser(),
                LanguagesService.GetAllForSelectItem(),
                CompaniesService.getCurrent()
            ]);

            setUser(user);
            setUsername(user.realName);
            formUser.reset(user);
            setLanguages(langs)
            setCompany(company);
            setVatIsDisabled(!!company && !!company.taxNumber);

            const coutriesData = await CountriesService.catalog();
            setCountries(coutriesData.map(x=> {
                return { value: x.name, label: (t('common.countries.' + x.name as any)) }
            }));

            formCompany.reset(company);


        }
        catch (error) {
            addToast(t('common.messages.error_load_info'), {
                appearance: 'error',
            });
            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                `Couldn't get user info`,
                error
            );
        }
        finally {
            Loading.hide();
        }
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (Utils.isStringNullOrEmpty(formUser.watch('newPassword') ?? '')) {
            formUser.clearErrors();
        }
    }, [formUser.watch('newPassword')]);

    const onSubmitUser = async (formData: UpdateUserDto) => {
        if (user) {
            try {

                Loading.show();

                if (formData.newPassword && formData.newPassword !== formData.confirmPassword) {
                    addToast(t('account.passwords_doesnt_match'), { appearance: 'warning' });
                    return;
                }

                const emailData: CheckAvailabilityDto = { table: 'asp_net_users', column: 'normalized_email', value: formData.email.toLocaleUpperCase(), companyId: true, itemEdit: loggedUser?.id };
                if (!await UsersService.checkAvailability(emailData)) {
                    addToast(t('common.messages.email_already_exists'), { appearance: 'warning' });
                    return;
                }

                emailData.companyId = false;
                const checkAvailabilityEmailAllEntities = await UsersService.checkAvailability(emailData);
                if (!checkAvailabilityEmailAllEntities) {
                    addToast(t('common.messages.email_already_exists_all_entities'), { appearance: 'warning' });
                    return;
                }

                user.realName = formData.realName;
                user.email = formData.email;
                user.newPassword = formData.newPassword;
                user.removeMedia = removeMedia;
                user.languageId = formData.languageId;
                user.phoneNumber = formData.phoneNumber;
                user.address = formData.address;
                user.zipcode = formData.zipcode;
                user.country = formData.country;

                await UsersService.updateAccount(user, mediaFile);

                const lang = languages.find(x => x.value == user.languageId);
                if (lang) {
                    Storage.set(STORAGE.CURRENT_LOCALE, lang.label);
                    i18n.changeLanguage(lang.label);

                    const profile = Storage.getObject(STORAGE.AUTH_USER_PROFILE);
                    profile.languageCode = lang.label;
                    Storage.setObject(STORAGE.AUTH_USER_PROFILE, profile);

                    dispatch(setProfile(profile));
                }



                user.newPassword = undefined;
                formUser.setValue('newPassword', undefined);
                formUser.setValue('confirmPassword', undefined);

                dispatch(updateRefreshToken());

                addToast(t('common.messages.record_save_success'), {
                    appearance: 'success',
                });

                onCancel();
                setChangePassword(false);

            } catch (error) {
                addToast(t('common.messages.record_save_error'), {
                    appearance: 'error',
                });

                Logger.error(
                    LOGGER_LOG_TYPE.REQUEST,
                    `Couldn't create or update user`,
                    error
                );

            }
            finally {
                Loading.hide();
            }
        }
    };


    const onSubmitCompany = async (form: CompanyDto) => {
        try {
            Loading.show();

            if (company) {
                company.name = form.name;
                if (!vatDisabled) {
                    company.taxNumber = form.taxNumber;
                }
                company.email = form.email;
                company.phone = form.phone;
                company.website = form.website;
                company.addressLine = form.addressLine;
                company.zipCode = form.zipCode;
                company.city = form.city;
                company.country = form.country;
                company.removeAttachment = company.logoId && medias.length === 0 ? true : false;
                company.trackingProvider = form.trackingProvider;
                company.trackingProviderUsername = form.trackingProviderUsername;
                company.trackingProviderPassword = form.trackingProviderPassword;
                await CompaniesService.updateCurrent(company, medias);
                if (!vatDisabled) {
                    setVatIsDisabled(!!company && !!company.taxNumber);
                }
            }

            Loading.hide();
            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't update the company with id: ${form.id}`, error);
            Loading.hide();
        }
    }



    const onAddAvatar = (files: CustomFile[]) => {
        if (files.length > 0) {
            setMediaFile(files[0]);
        }
    }

    const onRemoveAvatar = () => {
        setRemoveMedia(true);
        if (user) {
            setUser({
                ...user,
                media: null,
            });
        }
    }

    const onAddMedias = (medias: CustomFile[]) => {
        setMedias(medias);
    }

    const onRemoveMedia = (mediaId: string) => {
        const filteredMedia = [...medias.filter(x => x.id != mediaId)];
        setMedias(filteredMedia);
    }


    const packsTab = () => (
        <PlansScreen
            isDetails={isDetails}
            companyId={loggedUser?.companyId ?? ''}
            issues={loggedUser?.issues ?? []}
            companyName={user?.companyName}
            companyMedia={user?.companyMedia} ></PlansScreen>
    )

    const accountTab = () => (
        <Box>


            <div className={styles.avatarContainer}>
                <div className={styles.avatarChooser}>
                    <MediaSelector
                        isProfilePic={true}
                        isDetails={isDetails}
                        isMulti={false}
                        onAddMedias={onAddAvatar}
                        onRemoveMedia={onRemoveAvatar}
                        initialMedias={user?.media ? [user?.media] : []}
                    />
                </div>
                <span className={styles.usernameCol}>{username}</span>
            </div>

            {/*             
                    <div className={styles.avatarContainer}>
                    <div className={styles.avatarChooser}>
                        <MediaSelector
                            isProfilePic={true}
                            isDetails={isDetails}
                            isMulti={false}
                            onAddMedias={onAddAvatar}
                            onRemoveMedia={onRemoveAvatar}
                            initialMedias={user?.media ? [user?.media] : []}
                        />
                    </div>
                    <span className={styles.usernameCol}>{username}</span>
                </div> */}


            <form className={styles.form} onSubmit={formUser.handleSubmit(onSubmitUser)}>
                <Row>
                    <Col xs={12} md={6} lg={3}>
                        <FormItem>
                            <Label className={styles.label}>{t('users.name')}*</Label>
                            <Input
                                name="realName"
                                placeholder={t('users.name')}
                                defaultValue={user?.realName}
                                ref={formUser.register({ required: true })}
                                disabled={isDetails}
                                onChange={e => setUsername(e.target.value as any)}
                            />
                            <InputError error={formUser.errors.realName} />
                        </FormItem>
                    </Col>
                    <Col xs={12} md={6} lg={3}>
                        <FormItem>
                            <Label className={styles.label}>{t('users.email')}*</Label>
                            <Input
                                name="email"
                                placeholder={t('users.email')}
                                defaultValue={user?.email}
                                ref={formUser.register({ required: true })}
                                disabled={isDetails}
                            />
                            <InputError error={formUser.errors.email} />
                        </FormItem>
                    </Col>
                    <Col xs={12} md={6} lg={3}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('users.phone_number')}
                            </Label>
                            <Input
                                name='phoneNumber'
                                placeholder={t('users.phone_number')}
                                defaultValue={user?.phoneNumber ?? ''}
                                disabled={isDetails}
                                ref={formUser.register({ required: false })}
                            />
                        </FormItem>
                    </Col>

                    <Col xs={12} md={6}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('users.address')}
                            </Label>
                            <Input
                                name='address'
                                placeholder={t('users.address')}
                                defaultValue={user?.address ?? ''}
                                disabled={isDetails}
                                ref={formUser.register({ required: false })}
                            />
                        </FormItem>
                    </Col>
                    <Col xs={12} md={6} lg={3}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('users.zipcode')}
                            </Label>
                            <Input
                                name='zipcode'
                                placeholder={t('users.zipcode')}
                                defaultValue={user?.zipcode ?? ''}
                                disabled={isDetails}
                                ref={formUser.register({ required: false })}
                            />
                        </FormItem>
                    </Col>
                    <Col xs={12} md={6} lg={3}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('common.country')}
                            </Label>
                            <SelectController
                                form={formUser}
                                isClearable={true}
                                name='country'
                                options={countries}
                                placeholder={t('common.country')}
                                rules={{ required: false }}
                                isDisabled={isDetails}
                                filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                            />
                            <InputError error={formCompany.errors.country} />
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col xs>
                        <FormItem>
                            <Label className={styles.label}>{t('users.language')}</Label>
                            <SelectController
                                form={formUser}
                                name="languageId"
                                options={languages ? languages.map(x => { return { ...x, label: x.fullLabel ?? '' } }) : []}
                                placeholder={t('users.language')}
                                rules={{ required: false }}
                                isDisabled={isDetails}
                            />
                            <InputError error={formUser.errors.languageId} />
                        </FormItem>

                        {!isDetails && changePassword && (
                            <FormItem>
                                <Label className={styles.label}>
                                    {t('account.new_password')}
                                </Label>
                                <Input
                                    type="password"
                                    preset="login"
                                    name="newPassword"
                                    placeholder={t('account.new_password')}
                                    autoComplete={'off'}
                                    disabled={isDetails}
                                    ref={formUser.register()}
                                />
                                <InputError error={formUser.errors.newPassword} />
                            </FormItem>
                        )}
                        {formUser.watch('newPassword') && (
                            <FormItem>
                                <Label className={styles.label}>
                                    {t('account.confirm_new_password')}*
                                </Label>
                                <Input
                                    type="password"
                                    preset="login"
                                    name="confirmPassword"
                                    placeholder={t('account.confirm_new_password')}
                                    disabled={isDetails}
                                    ref={formUser.register({
                                        required: true
                                        /*validate: value =>
                                            value === formUser.watch('newPassword') ||
                                            t('common.errors.password_not_match'),*/
                                    })}
                                />
                                <InputError error={formUser.errors.confirmPassword} />
                            </FormItem>
                        )}
                    </Col>
                </Row>
                <Separator />

                <div className={styles.buttonContainer}>
                    {!isDetails && (
                        <Button
                            preset={'secondary'}
                            type="button"
                            text={t('common.cancel')}
                            onClick={() => onCancel()}
                        />
                    )}

                    {!isDetails && (
                        <Button
                            preset={'primaryAlt'}
                            type="button"
                            text={ changePassword ? t('common.hide_password') : t('common.change_password') }
                            onClick={() => onChangePassword()}
                        />
                    )}

                    {isDetails && (
                        <Button
                            type="button"
                            text={t('common.edit')}
                            onClick={() => setIsDetails(false)}
                        />
                    )}
                    {isDetails && (
                        <Button
                            preset={'secondary'}
                            type="button"
                            text={t('common.2fa')}
                            onClick={() => config2FA()}
                        />
                    )}
                    {!isDetails && <Button type="submit" text={t('common.save')} />}
                </div>
            </form>
        </Box>
    )



    const companyTab = () => (
        <Box className={styles.box}>
            <form className={styles.form} onSubmit={formCompany.handleSubmit(onSubmitCompany)}>
                <Row>
                    <Col xs={12} xl={10}>
                        <Row>
                            <Col xs={12} md={8} lg={8}>
                                <FormItem>
                                    <Label className={styles.label}>
                                        {t('companies.name')}
                                        {!isDetails ? '*' : ''}
                                    </Label>
                                    <Input
                                        name='name'
                                        placeholder={t('companies.name')}
                                        defaultValue={company?.name}
                                        ref={formCompany.register({ ...DEFAULT_INPUT_RULES_WITH_REQUIRED })}
                                        disabled={isDetails || !hasCompaniesWritePolicy}
                                    />
                                    <InputError error={formCompany.errors.name} />
                                </FormItem>
                            </Col>
                            <Col xs={12} md={4} lg={4}>
                                <FormItem>
                                    <Label className={styles.label}>
                                        {t('companies.tax_number')}
                                    </Label>
                                    <Input
                                        name='taxNumber'
                                        placeholder={t('companies.tax_number')}
                                        defaultValue={company?.taxNumber}
                                        ref={formCompany.register({ ...DEFAULT_INPUT_RULES, maxLength: 100 })}
                                        disabled={isDetails || !hasCompaniesWritePolicy || vatDisabled}
                                    />
                                    <InputError error={formCompany.errors.taxNumber} />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={4} lg={4}>
                                <FormItem>
                                    <Label className={styles.label}>
                                        {t('companies.email')}
                                        {!isDetails ? '*' : ''}
                                    </Label>
                                    <Input
                                        name='email'
                                        placeholder={t('companies.email')}
                                        defaultValue={company?.email}
                                        type={'email'}
                                        ref={formCompany.register({ ...DEFAULT_EMAIL_RULES_WITH_REQUIRED, maxLength: 100 })}
                                        disabled={isDetails || !hasCompaniesWritePolicy}
                                    />
                                    <InputError error={formCompany.errors.email} />
                                </FormItem>
                            </Col>
                            <Col xs={12} md={4} lg={4}>
                                <FormItem>
                                    <Label className={styles.label}>
                                        {t('companies.phone')}
                                    </Label>
                                    <Input
                                        name='phone'
                                        placeholder={t('companies.phone')}
                                        defaultValue={company?.phone}
                                        ref={formCompany.register({ ...DEFAULT_INPUT_RULES, maxLength: 50 })}
                                        disabled={isDetails || !hasCompaniesWritePolicy}
                                    />
                                    <InputError error={formCompany.errors.phone} />
                                </FormItem>
                            </Col>
                            <Col xs={12} md={4} lg={4}>
                                <FormItem>
                                    <Label className={styles.label}>
                                        {t('companies.website')}
                                    </Label>
                                    <Input
                                        name='website'
                                        placeholder={t('companies.website')}
                                        defaultValue={company?.website}
                                        ref={formCompany.register({ ...DEFAULT_INPUT_RULES })}
                                        disabled={isDetails || !hasCompaniesWritePolicy}
                                    />
                                    <InputError error={formCompany.errors.website} />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={4} lg={4}>
                                <FormItem>
                                    <Label className={styles.label}>
                                        {t('companies.address')}
                                        {!isDetails ? '*' : ''}
                                    </Label>
                                    <Input
                                        name='addressLine'
                                        placeholder={t('companies.address')}
                                        defaultValue={company?.addressLine}
                                        ref={formCompany.register({ ...DEFAULT_INPUT_RULES_WITH_REQUIRED, maxLength: 200 })}
                                        disabled={isDetails || !hasCompaniesWritePolicy}
                                    />
                                    <InputError error={formCompany.errors.addressLine} />
                                </FormItem>
                            </Col>
                            <Col xs={12} md={4} lg={4}>
                                <FormItem>
                                    <Label className={styles.label}>
                                        {t('companies.zip_code')}
                                        {!isDetails ? '*' : ''}
                                    </Label>
                                    <Input
                                        name='zipCode'
                                        placeholder={t('companies.zip_code')}
                                        defaultValue={company?.zipCode}
                                        ref={formCompany.register({ ...DEFAULT_INPUT_RULES_WITH_REQUIRED, maxLength: 15 })}
                                        disabled={isDetails || !hasCompaniesWritePolicy}
                                    />
                                    <InputError error={formCompany.errors.zipCode} />
                                </FormItem>
                            </Col>
                            <Col xs={12} md={4} lg={4}>
                                <FormItem>
                                    <Label className={styles.label}>
                                        {t('companies.city')}
                                    </Label>
                                    <Input
                                        name='city'
                                        placeholder={t('companies.city')}
                                        defaultValue={company?.city}
                                        ref={formCompany.register({ ...DEFAULT_INPUT_RULES })}
                                        disabled={isDetails || !hasCompaniesWritePolicy}
                                    />
                                    <InputError error={formCompany.errors.city} />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} xl={4}>
                                <FormItem>
                                    <Label className={styles.label}>
                                        {t('common.country')}
                                    </Label>
                                    <SelectController
                                        form={formCompany}
                                        isClearable={true}
                                        name='country'
                                        options={countries}
                                        placeholder={t('common.country')}
                                        rules={{ required: false }}
                                        isDisabled={isDetails || !hasCompaniesWritePolicy}
                                        filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                    />
                                    <InputError error={formCompany.errors.country} />
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} xl={2}>
                        <MediaSelector
                            isMulti={false}
                            isDetails={isDetails || !hasCompaniesWritePolicy}
                            onAddMedias={onAddMedias}
                            onRemoveMedia={onRemoveMedia}
                            initialMedias={company?.medias ?? []}
                        />
                    </Col>
                </Row>
                <div className={styles.buttonContainer}>

                    {!isDetails && (
                        <Button
                            preset={'secondary'}
                            type="button"
                            text={t('common.cancel')}
                            onClick={() => onCancel()}
                        />
                    )}

                    {isDetails && hasCompaniesWritePolicy && (
                        <Button
                            type="button"
                            text={t('common.edit')}
                            onClick={() => setIsDetails(false)}
                        />
                    )}

                    {!isDetails && hasCompaniesWritePolicy && <Button type="submit" text={t('common.save')} />}
                </div>
            </form>
        </Box>
    );

    const tabs: TabItem[] = [
        {
            id: 'account',
            title: t('account.my_account'),
            content: accountTab()
        },
        {
            id: 'company',
            title: t('account.company'),
            content: companyTab()
        },
        {
            id: 'subscription',
            title: t('account.plans'),
            content: packsTab()
        }
    ];


    return (
        <ScreenTitle title={t('account.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('account.title')}></ScreenHeader>
                <Tabs
                    items={tabs}
                    activeTabId={tabId}
                    onChange={(tId: string) => { setTabId(tId) }} />
            </ScreenContainer>
        </ScreenTitle>
    );
};

export default AccountScreen;