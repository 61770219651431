import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ScreenTitle from '../../common/components/screenTitle/ScreenTitle';
import ScreenContainer from '../../common/components/screenContainer/ScreenContainer';
import Box from 'common/components/box/Box';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import styles from './ImportChargesScreen.module.scss';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import { DATE_TIME_FORMAT_DEFAUT, DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import { useHistory } from 'react-router-dom';
import Logger from 'common/services/Logger';
import { useToasts } from 'react-toast-notifications';
import { ImportExpenseFileDto } from 'api/import-charges/models/ImportExpenseFileDto';
import { ImportExpenseFilesSearchCriteria } from 'api/import-charges/models/ImportExpenseFilesSearchCriteria';
import { Paged } from 'api/common/types/Page';
import Dropdown from 'common/components/dropdown/Dropdown';
import DropdownItem from 'common/components/dropdown/DropdownItem';
import { FaEllipsisH } from 'react-icons/fa';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import ImportChargeService from 'api/import-charges/ImportChargeService';
import Loading from 'common/services/Loading';
import DateFormat from 'common/components/dateFormat/dateFormat';
import ImportChargeFilters, { Filters } from './components/filters/ImportChargeFilters';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { Col, Row } from 'react-flexbox-grid';
import IconBack from 'assets/svg/Arrow-grey.svg';

export type Props = {
    criteriaProp?: ImportExpenseFilesSearchCriteria;
} & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ImportChargesScreen = ({ criteriaProp, ...props }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const history = useHistory();
    const [importExpenseFilePage, setImportExpenseFilePage] = useState<Paged<ImportExpenseFileDto> | null>(null);
    const [criteria, setCriteria] = useState<ImportExpenseFilesSearchCriteria>(!criteriaProp ? {
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderColumn: 'date',
        orderBy: 'desc'
    } : criteriaProp);

    const [itemToRemove, setItemToRemove] = useState<ImportExpenseFileDto | null>(null);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [filters, setFilters] = useState<Filters>({});

    const showRemoveItemDialog = (item: ImportExpenseFileDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    };

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasChargesWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_IMPORT_EXPENSES_WRITE']);

    const getData = async () => {
        try {
            Loading.show();
            const files = await ImportChargeService.getList(criteria);
            setImportExpenseFilePage(files);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.CHARGES, `Couldn't get import expense file`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
        finally{
            Loading.hide();
        }
    };

    useEffect(() => {        
        getData();
    }, [criteria]);

    const onClickItem = (item: ImportExpenseFileDto, isDetails: boolean) => {
        history.push(`import-charges/${isDetails ? 'details' : 'edit'}/${item.id}`);
    }

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }

        try {
            await ImportChargeService.remove(itemToRemove);
            setCriteria({ ...criteria, page: 1 });
            onCancelRemove();
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete import charges file`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
        }
    };

    const renderTableActionCell = (
        row: ImportExpenseFileDto,
    ) => {
        const urlDetails = `import-charges/details/${row.id}`;
        const urlEdit = `import-charges/edit/${row.id}`;

        return (
            <Dropdown
                options={
                    <>
                        <DropdownItem url={urlDetails}>
                            {t('common.details')}
                        </DropdownItem>
                        {hasChargesWritePolicy && row.status != 'OK' && <DropdownItem url={urlEdit}>
                            {t('common.edit')}
                        </DropdownItem>}
                        {hasChargesWritePolicy && <DropdownItem onClick={() => showRemoveItemDialog(row)}>
                            {t('common.remove')}
                        </DropdownItem>}
                    </>
                }
            >
                <div>
                    <FaEllipsisH />
                </div>
            </Dropdown>
        );
    };

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const tableColumns: ListingTableColumn<ImportExpenseFileDto>[] =  [
            {
                name: t('common.date'),
                renderCell: row => <div>
                    <DateFormat value={row.date} format={DATE_TIME_FORMAT_DEFAUT} />
                </div>,
                width: 'fill',
                hideOn: ['sm'],
                searchField: 'date',
                onSearch: onTableFilter
            },
            {
                name: t('common.name'),
                renderCell: row => <div>{row.fileName}</div>,
                searchField: 'file_name',
                onSearch: onTableFilter
            },
            {
                name: t('common.supplier'),
                renderCell: row => <>
                    <div>
                        {row.supplierUrl && <img src={row.supplierUrl} style={{ height: '1.25rem' }}></img>} {(row.supplierName || '-')}
                    </div>
                </>,
                hideOn: ['sm', 'md', 'lg', 'xl'],
                searchField: 'supplier.name',
                onSearch: onTableFilter
            },
            {
                name: t('import_charges.status'),
                renderCell: row => <div>{t(('import_charges.process_status.' + row.status) as any)}</div>,
                searchField: 'status',
                onSearch: onTableFilter
            },
            {
                name: '',
                width: '60px',
                cellAlignment: 'right',
                preventClick: true,
                renderCell: renderTableActionCell,
                cellStyle: { overflow: 'unset' },
                hideOn: ['sm', 'md' ]
            }
        ];
   
    const onPageChange = (page: number) => {
        setCriteria({ ...criteria, page: page });
    }

    const onChangeFilters = (f: Filters) => {
        setFilters(f);

        setCriteria({
            ...criteria,
            page: 1,
            startDate: f.startDate,
            endDate: f.endDate,
            supplierId: f.supplierId
        });
    }

    const navigateTo = () => {        
        history.push(`/charges`);
    }

    return (
        <ScreenTitle title={t('import_charges.title')} {...props}>
            <ScreenContainer>
                <ScreenHeader title={t('import_charges.title')}>
                    {hasChargesWritePolicy && <ScreenHeaderButton icon={AddIcon} onClick={() => history.push(`/import-charges/new`)} />}
                </ScreenHeader>
                <Box>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={8} xl={9}>
                            <div className={styles.header}>
                                <img src={IconBack} className={styles.icon} onClick={() => navigateTo()} />
                            </div>
                        </Col>
                    </Row>
                    <div className={styles.filters}>
                        <ImportChargeFilters
                            load={true}
                            filters={filters}
                            onFilter={f => { onChangeFilters(f) }}
                        />
                    </div>
                    <div className={styles.divider} />

                    <ListingTable
                        columns={tableColumns}
                        rows={importExpenseFilePage?.items ?? []}
                        onRowClick={row => onClickItem(row, true)}
                        allowHover={true}
                        onHref={row => `/import-charges/details/${row.id}`}
                        initialSearch={{ colField: 'date', isOrderAsc: false }} 
                        />
                    <PaginationWithInfo
                        itemName={t('import_charges.list.files')}
                        currentPage={importExpenseFilePage?.currentPage ?? 1}
                        pageItems={importExpenseFilePage?.items.length ?? 0}
                        totalItems={importExpenseFilePage?.totalItems ?? 0}
                        onChange={onPageChange} />

                    <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('common.messages.remove_record_with_ident', { name: itemToRemove?.fileName ?? '' })} />
                </Box>
            </ScreenContainer>
        </ScreenTitle>
    );
}

export default ImportChargesScreen;
