import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Box, { Props as BoxProps } from 'common/components/box/Box';
import MonthlyRouteChart from './MonthlyRouteChart';
import { ReactComponent as ArrowDown } from 'assets/svg/chevron_down.svg';
import Button from 'common/components/button/Button';
import Colors from 'styles/export/colors.module.scss'
import styles from './MonthlyRouteChartBox.module.scss';
import { MonthlyRouteDashboardDto, MonthlyRoutesDashboardDto } from 'api/dashboard/dtos/MonthlyRouteDashboardDto';
import LoadingSpinner from 'common/components/loading/LoadingSpinner';
import { useTranslation } from 'react-i18next';

type Props = {
	data: MonthlyRoutesDashboardDto | null;
	isLoading?: boolean;
} & BoxProps;

const MonthlyRouteChartBox = ({ data, isLoading, ...props }: Props) => {
	const { t } = useTranslation();
	const [selectedYearIndex, setSelectedYearIndex] = useState<number | null>(null);
	const allYears = (data?.items || []).map((x: MonthlyRouteDashboardDto) => x.date.getFullYear());

	const yearsOptions = allYears
		.filter((year, i, self) => self.findIndex(d => d === year) === i)
		.sort((a, b) => a - b);

	const existsMoreThanOneYear = allYears.every((year, _, arr) => year === arr[0]) === false;

	const selectedYear = selectedYearIndex !== null ? yearsOptions[selectedYearIndex] : null;


	useEffect(() => {
		if (existsMoreThanOneYear) {
			const currYear = dayjs().year();
			const currYearExistIndex = yearsOptions.findIndex(year => year === currYear);

			setSelectedYearIndex(currYearExistIndex !== null ? currYearExistIndex : yearsOptions[0]);
		} else {
			setSelectedYearIndex(null);
		}
	}, [data]);

	const onPreviousYear = () => {
		if (selectedYearIndex !== null && selectedYearIndex > 0) {
			setSelectedYearIndex(selectedYearIndex - 1);
		}
	};

	const onNextYear = () => {
		if (selectedYearIndex !== null && selectedYearIndex < yearsOptions.length - 1) {
			setSelectedYearIndex(selectedYearIndex + 1);
		}
	};
	
	return (
		<Box
			{...props}
			renderOptions={existsMoreThanOneYear && selectedYear
				? (
					<div className={styles.optionsContainer}>
						<Button preset="primary" size="extraSmall" bw={false} onClick={onPreviousYear} disabled={selectedYear === yearsOptions[0]}>
							<ArrowDown width={12} height={12} fill={Colors.white} style={{ transform: 'rotate(90deg)' }} />
						</Button>
						<div className={styles.year}>
							{selectedYear}
						</div>
						<Button preset="primary" size="extraSmall" bw={false} onClick={onNextYear} disabled={selectedYear === yearsOptions[yearsOptions.length - 1]}>
							<ArrowDown width={12} height={12} fill={Colors.white} style={{ transform: 'rotate(-90deg)' }} />
						</Button>
					</div>
				)
				: <></>}
		>
			{isLoading ? <div className={styles.centerAlign}> <LoadingSpinner /></div> :<MonthlyRouteChart data={data} selectedYear={selectedYear} />}
		</Box>
	);
};

export default MonthlyRouteChartBox;
