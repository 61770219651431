export enum GenericType {
    FUEL = 'FUEL',
    MAINTENANCE = 'MAINTENANCE',
    CLEANING = 'CLEANING',
    INSURANCE = 'INSURANCE',
    RENTAL = 'RENTAL',
    FINES = 'FINES',
    ENERGY = 'ENERGY',
    ACCESSORIES = 'ACCESSORIES',
    LOGO = 'LOGO',
    TRANSPORT = 'TRANSPORT',
    MISCELLANEOUS = 'MISCELLANEOUS'
}