import React, { useRef, useState } from 'react';
import Timekeeper from 'react-timekeeper';
import { FaRegClock } from 'react-icons/fa';
import styles from './DateWithHour.module.scss';
import DateTimePicker from '../dateTimePicker/DateTimePicker';
import InputGroup from '../inputGroup/InputGroup';
import { DATE_FORMAT_DEFAUT } from 'Config';
import moment from 'moment';
import { Controller, RegisterOptions } from 'react-hook-form';
import dayjs from 'dayjs';

const resetTime = (value: Date) => {
    return dayjs(value)
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0);
}


const formatTimeNumber = (n: number) => {
    return n.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
    });
}

type Props = {
    form: any;
    datePlaceholder?: string;
    dateFormat: string;
    dateCustomInput: JSX.Element;
    isDisabled: boolean;
    name: string;
    minDate?: Date | undefined;
    timePlaceholder?: string;
    className?: string;
    rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
    onChangeDate?: (date: Date) => void;
};

const DateWithHourController = ({ form, datePlaceholder, dateFormat, dateCustomInput, timePlaceholder, isDisabled, className, minDate, rules, name, onChangeDate }: Props) => {

    const [showTime, setShowTime] = useState(false);
    const ref = useRef(null);

    return (
        <Controller
            render={({ value, onChange: onChangeCtrl }) => {
                const timeInput = {
                    hour: value ? dayjs(value).get('hours') : 0,
                    minute: value ? dayjs(value).get('minutes') : 0
                };
                return (
                    <div className={`${styles.container} ${className ? className : ''}`}>
                        <div className={styles.leftChild}>
                            <DateTimePicker
                                placeholderText={datePlaceholder}
                                selected={value ? new Date(value) : undefined}
                                dateFormat={dateFormat}
                                customInput={dateCustomInput}
                                autoComplete='off'
                                disabled={isDisabled}
                                minDate={minDate}
                                ref={ref}
                                onChange={date => {
                                    const d = date as Date;
                                    let newDate: Date;

                                    if (minDate && (d.toLocaleDateString() == minDate?.toLocaleDateString())) {
                                        newDate = dayjs(minDate)
                                            .set('date', d.getDate())
                                            .set('month', d.getMonth())
                                            .set('year', d.getFullYear())
                                            .toDate();

                                    } else {
                                        newDate = dayjs(value || resetTime(new Date()))
                                            .set('date', d.getDate())
                                            .set('month', d.getMonth())
                                            .set('year', d.getFullYear())
                                            .toDate();
                                    }

                                    onChangeCtrl(newDate);
                                    if (onChangeDate) {
                                        onChangeDate(newDate)
                                    }
                                }}
                                value={value ? moment(value).format(dateFormat ?? DATE_FORMAT_DEFAUT) : undefined}
                            />
                        </div>
                        <div className={styles.rightChild}>
                            {showTime &&
                                <div style={{ zIndex: 1, position: 'relative', maxHeight: '1rem' }}>
                                    <Timekeeper
                                        closeOnMinuteSelect={true}
                                        hour24Mode={true}
                                        switchToMinuteOnHourSelect
                                        time={timeInput}
                                        onDoneClick={hour => {
                                            setShowTime(false);

                                            if (!hour || !hour.formatted24) {
                                                onChangeCtrl(resetTime(value));
                                            } else {
                                                const time = hour.formatted24.split(':');

                                                const newDate = dayjs(value || resetTime(new Date()))
                                                    .set('hour', Number(time[0]))
                                                    .set('minute', Number(time[1]))
                                                    .toDate();

                                                onChangeCtrl(newDate);
                                                if (onChangeDate) {
                                                    onChangeDate(newDate)
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            }
                            {!showTime &&
                                <InputGroup
                                    value={value ? `${formatTimeNumber(timeInput.hour)}:${formatTimeNumber(timeInput.minute)}` : ''}
                                    placeholder={timePlaceholder}
                                    onClick={() => setShowTime(true)}
                                    removeInputBorderRadius={true}
                                    isClearable={false}
                                    disabled={isDisabled || !value}
                                    icon={<FaRegClock />}
                                />
                            }
                        </div>
                    </div>
                );
            }}
            onFocus={() => (ref.current as any).input.focus()}
            control={form.control}
            name={name}
            defaultValue={form.getValues(name) || undefined}
            rules={rules}
        />
    );
};

export default DateWithHourController;
