import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AnnotationsFormItem.module.scss';
import { Col, Row } from 'react-flexbox-grid';
import Label from 'common/components/label/Label';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { ArrayField, UseFormMethods, useWatch } from 'react-hook-form';
import InputError from 'common/components/inputError/InputError';
import SelectController from 'common/components/select/SelectController';
import { removeAccents, DATE_TIME_FORMAT_DEFAULT } from 'Config';
import { ReactComponent as TrashIcon } from 'assets/svg/trash_no_color.svg';
import Utils from 'common/services/Utils';
import { InterventionTypeEnum } from 'api/carVerification/enums/InterventionTypeEnum';
import InputResizableController from 'common/components/input/InputResizableController';
import colors from 'styles/export/colors.module.scss';
import HorizontalMediaSelectorController from 'common/components/horizontalMediaSelector/HorizontalMediaSelectorController';
import { MediaItemDto } from 'api/common/models/MediaItemDto';
import { VerificationAnnotationViewModel, VerificationViewModel } from 'api/carVerification/models/VerificationViewModel';
import DateTimePickerControllerNew from 'common/components/dateTimePicker/DateTimePickerControllerNew';
import { AnnotationStatusEnum } from 'api/carVerification/enums/AnnotationEnum';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import Badge from 'common/components/badge/Badge';
import { getInterventionTypeStatusColor } from 'common/utils/VerificationsUtils';
import ToggleSwitch from 'common/components/toggleSwitch/ToggleSwitch';
import DateFormat from 'common/components/dateFormat/dateFormat';
import ReactTooltip from 'react-tooltip';

const interventionTypeStatus = Object.keys(InterventionTypeEnum).filter(key => isNaN(Number(key)));
const statusOption = Object.keys(AnnotationStatusEnum).filter(key => isNaN(Number(key)));

type Props = {
    form: UseFormMethods<VerificationViewModel>;
    isDetails: boolean;
    annotationTypes: SelectValueLabel[];
    maintenances: SelectValueLabel[];
    onRemoveMedia: (media: MediaItemDto) => void;
    onRemoveClick: () => void;
    index: number;
    annotation: Partial<ArrayField<VerificationAnnotationViewModel, 'id'>>;
    onClickAddMaintenance: () => void;
    onTouch: (val: boolean) => void;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const AnnotationsFormItem: React.FC<Props> = ({
    form, isDetails, annotationTypes, maintenances, onRemoveMedia, onRemoveClick, index, annotation, onClickAddMaintenance, onTouch, ...props
}: Props) => {
    const { t } = useTranslation();
    const errors = (form.errors?.annotations || [])[index] || null;
    const statusWatch = useWatch<AnnotationStatusEnum>({ control: form.control, name: `annotations[${index}].status` });

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasVehicleMaintenancesWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_VEHICLE_MAINTENANCES_WRITE']);
    const hasAnnotationsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_ANNOTATIONS_WRITE']);
    const disabled = (isDetails || !hasAnnotationsWritePolicy);
    const [selectedStatus, setSelectedStatus] = useState<string>('');

    const interventionTypeStatusList: SelectValueLabel[] = Utils.sortAlphabetically(interventionTypeStatus.map(intervention => {
        return { value: intervention, label: t(`car_verification.intervention_type.${intervention}`.toLowerCase() as any) }
    }));

    const statusOptionList: SelectValueLabel[] = Utils.sortAlphabetically(statusOption.map(a => {
        return { value: a, label: t(`car_verification.annotation_status.${a}`.toLowerCase() as any) }
    }));

    const renderStatusItem = (name: string, onClick: () => void, isSelected: boolean, key: number) => (
        <div
            key={`status-${key}`}
            style={{ width: '50%', backgroundColor: selectedStatus == AnnotationStatusEnum.DONE ? colors.doneStatus : colors.waitingStatus }}
            onClick={
                () => {
                    onTouch(true);
                    onClick();
                }
            }
        >
            <div className={styles.labelContainer}>
                <div className={` ${(isSelected ? styles.selected : styles.notSelected)} ${styles.labelContent}`}>
                    <span>{name}</span>
                </div>
            </div>
        </div>
    );

    const onSelectStatus = (type: any) => {
        if (type) {
            setSelectedStatus(type || null);
            form.setValue(`annotations[${index}].status`, type || null);
        }
    }

    useEffect(() => {
        if (form) {
            setSelectedStatus(form.getValues(`annotations[${index}].status`));
        }
    }, []);

    const toggleImmobilized = (index: number) => {
        if (form) {
            form.setValue(`annotations[${index}].predictedDateEndImmobilization`, null);
        }
    };

    const renderForm = () => <>
            <Row className={styles.rowSpace}>
                <Col xs={6} lg={4} xl={3}>
                    <Label className={styles.label}>{t('car_verification.annotation_type_title')} *</Label>
                    <SelectController
                        form={form}
                        name={`annotations[${index}].typeId`}
                        menuPortalTarget={document.querySelector('body')}
                        options={annotationTypes}
                        placeholder={t('car_verification.annotation_type_title')}
                        rules={{ required: !disabled }}
                        isDisabled={disabled}
                        filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                    />
                    <InputError error={errors?.typeId} />
                </Col>
                <Col xs={6} lg={4} xl={3}>
                    <Label className={styles.label}>{t('car_verification.intervention_type_title')} *</Label>
                    <SelectController
                        form={form}
                        name={`annotations[${index}].intervention`}
                        menuPortalTarget={document.querySelector('body')}
                        options={interventionTypeStatusList}
                        placeholder={t('car_verification.intervention_type_title')}
                        rules={{ required: !disabled }}
                        isDisabled={disabled}
                        filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                    />
                    <InputError error={errors?.intervention} />
                </Col>
                <Col xs={6} lg={4} xl={3}>
                    <Label className={styles.label}>{t('car_verification.annotation_immobilized')}</Label>
                    <ToggleSwitch
                        name={`annotations[${index}].immobilized`}
                        form={form}
                        isDetails={isDetails}
                        changeToggle={() => toggleImmobilized(index)}
                    />
                </Col>
            </Row>

            <Row className={styles.rowSpace}>
                <Col xs={6} lg={4} xl={3}>
                    <Label className={styles.label}>{t('car_verification.annotation_date')}</Label>
                    <DateTimePickerControllerNew
                        form={form}
                        placeholderText={t('car_verification.annotation_date')}
                        name={`annotations[${index}].annotationDate`}
                        autoComplete='off'
                        disabled={isDetails}
                        maxDate={new Date()}
                        rules={{ required: false }}
                    />
                    <InputError error={errors?.annotationDate} />
                </Col>
                <Col xs={6} lg={4} xl={3}>
                    <Label className={styles.label}>{t('car_verification.annotation_predicted_date_end_immobilization')}</Label>
                    <DateTimePickerControllerNew
                        form={form}
                        placeholderText={t('car_verification.annotation_predicted_date_end_immobilization')}
                        name={`annotations[${index}].predictedDateEndImmobilization`}
                        autoComplete='off'
                        disabled={isDetails || !form.watch(`annotations[${index}].immobilized`)}
                        rules={{ required: false }}
                    />
                    <InputError error={errors?.predictedDateEndImmobilization} />
                </Col>
                <Col xs={6} lg={4} xl={3}>
                    <Label className={styles.label}>{t('car_verification.medias')}</Label>
                    <HorizontalMediaSelectorController
                        form={form}
                        name={`annotations[${index}].medias`}
                        container={`annotation_${index}`}
                        isDetails={disabled}
                        onRemove={onRemoveMedia}
                        noItemsText={t('common.no_medias')}
                    />
                </Col>
            </Row>

            <Row className={styles.rowSpace}>
                <Col xs={12}>
                    <Label className={styles.label}>{t('car_verification.description')}</Label>
                    <InputResizableController
                        name={`annotations[${index}].description`}
                        form={form as any}
                        placeholder={t('car_verification.description')}
                        disabled={disabled}
                    />
                </Col>
            </Row>
        </>

    return (
        <>
            {!isDetails && <div
                {...props}
                className={`${styles.containerEdit} ${(errors && styles.containerError) || ''} ${props.className || ''}`}
                style={{
                    ...(props.style || {}),
                }}
            >
                {renderForm()}

                {hasAnnotationsWritePolicy && <div className={styles.removeButton}>
                    <TrashIcon className={styles.removeIcon} onClick={onRemoveClick} />
                </div>}
            </div>}

            {isDetails && <div
                {...props}
                className={`${styles.containerDetails} ${(errors && styles.containerError) || ''} ${props.className || ''}`}
                style={{
                    ...(props.style || {}),
                }}
            >
                <Row className={styles.rowSpaceDescription}>
                    <Col xs={9} lg={6}>
                        <ReactTooltip />
                        <Row style={{ marginBottom: 10 }} data-tip={t('car_verification.annotation_update')}>
                            <Label style={{ fontSize: 'small', fontWeight: 'bold', marginLeft: 8 }}>
                                {annotation.userName}
                            </Label>
                            {annotation.date && <Label style={{ fontSize: 'small', marginLeft: 8 }}>
                                <DateFormat value={annotation.date} format={DATE_TIME_FORMAT_DEFAULT} />
                            </Label>}
                        </Row>
                    </Col>
                    
                    <Col xs={3} lg={6}>
                        <div className={styles.rightInfo}>
                            <div className={styles.statusContainer}>
                                {annotation.intervention && <Badge
                                    backgroundColor={getInterventionTypeStatusColor(annotation.intervention).color}
                                    text={t(`car_verification.intervention_type.${annotation.intervention}`.toLowerCase() as any)}
                                />}
                                {annotation.status && annotation.intervention !== InterventionTypeEnum.INFORMATION && 
                                    <Label className={styles.status}>
                                        {t(`car_verification.annotation_status.${annotation.status}`.toLowerCase() as any)}
                                    </Label>
                                }
                            </div>
                        </div>
                    </Col>
                </Row>

                <div className={styles.rowSpaceForm}>
                    {renderForm()}
                </div>

                {annotation.intervention !== InterventionTypeEnum.INFORMATION && hasAnnotationsWritePolicy && <>
                    <Row className={`${styles.rowSpaceSelects} ${styles.rowDivider}`}>
                        <Col xs={6} lg={3}>
                            <div>
                                <Label className={styles.label}>{t('car_verification.state')}</Label>
                                <div className={styles.spinner}>
                                    {statusOptionList.map((c, i) => {
                                        const isSelected = c.value === selectedStatus ? true : false;
                                        const label = c ? c.label : '';
                                        return renderStatusItem(label, () => onSelectStatus(c.value), isSelected, i);
                                    })}
                                </div>
                            </div>
                        </Col>

                        {statusWatch === AnnotationStatusEnum.DONE && <>
                            <Col xs={6} lg={4}>
                                <Label className={styles.label}>{t('car_verification.date_treated')} *</Label>
                                <DateTimePickerControllerNew
                                    form={form}
                                    placeholderText={t('car_verification.date_treated')}
                                    name={`annotations[${index}].doneDate`}
                                    autoComplete='off'
                                    maxDate={new Date()}
                                    rules={{ required: true }}
                                    onChange={() => onTouch(true)}
                                    disabled={statusWatch !== AnnotationStatusEnum.DONE}
                                />
                                <InputError error={errors?.doneDate} />
                            </Col>
                            <Col xs={12} lg={4}>
                                <Label className={styles.label}>
                                    {t('car_verification.maintenance')}
                                    {hasVehicleMaintenancesWritePolicy && <span onClick={onClickAddMaintenance}>
                                        <img src={AddIcon} className={styles.addIcon} />
                                    </span>}
                                </Label>
                                <SelectController
                                    form={form}
                                    name={`annotations[${index}].maintenanceIds`}
                                    menuPortalTarget={document.querySelector('body')}
                                    options={maintenances}
                                    placeholder={t('car_verification.maintenance')}
                                    isClearable
                                    isMulti={true as any}
                                    isDisabled={statusWatch !== AnnotationStatusEnum.DONE}
                                    onChange={() => onTouch(true)}
                                    filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                />
                            </Col>
                        </>}
                    </Row> 
                </>}
            </div>}
        </>

    );
}

export default AnnotationsFormItem;
