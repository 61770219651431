import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { FaExternalLinkAlt, FaMapMarkerAlt } from 'react-icons/fa';
import styles from './CurrentLocation.module.scss';
import { TrackingItemDto } from 'api/tracking/models/TrackingItemDto';
import { addressFormat, useGeoLocator } from 'common/services/GeoLocator';
import DateFormat from 'common/components/dateFormat/dateFormat';
import { DATE_TIME_FORMAT_DEFAULT, googleMapsQuery } from 'Config';

type Props = {
    position?: TrackingItemDto;
};

const CurrentLocation: React.FC<Props> = ({ position }: Props) => {
    if (!position) return null;

    const { t } = useTranslation();

    const isIgnitionOn = (item?: TrackingItemDto) => {
        return item?.ignition ?? false;
    }
    
    const link = googleMapsQuery(position?.latitude,position?.longitude);

    const hasValidInfo = (data: any) => {
        return data && (data.display_name || (data.results && data.results.length));
    }

    const renderAddress = (data: any) =><div>
            <div className={`${styles.container} ${isIgnitionOn(position) ? styles.containerOn : styles.containerOff}`} data-tip={t('vehicles.list.current_postion')}>
            <div className={styles.pin}>
                <FaMapMarkerAlt />
            </div>
            <div className={styles.content}>
                { <div className={styles.address}>
                    {hasValidInfo(data) && addressFormat(data,position?.latitude ?? 0, position?.longitude ?? 0)}
                    <div className={styles.labelAddress}>
                        <a onClick={ event => { event.stopPropagation(); window.open(link, '_blank'); }}>{t('alarms.notifications_tab.table.open_location')}
                            <span className={styles.linkIcon}>
                                <FaExternalLinkAlt/>
                            </span>                            
                        </a>
                    </div>
                </div> }                
                <div className={styles.info}>
                    {isIgnitionOn(position) ? t('vehicles.list.ignition_on') : t('vehicles.list.ignition_off')}
                    &nbsp;&nbsp;&#x2022;&nbsp;&nbsp;
                    {t('vehicles.list.last_update')}&nbsp;
                    <DateFormat value={position?.date} format={DATE_TIME_FORMAT_DEFAULT.toString()} />
                </div>
            </div>
        </div>
        <ReactTooltip />
    </div>

    const { isLoading, error, data } = useGeoLocator(position?.latitude ?? 0, position?.longitude ?? 0);       
    if (isLoading) return renderAddress(null);
    if (error) console.log('An error occurred while fetching the user data ', error);
    return renderAddress(data);
}

export default CurrentLocation;