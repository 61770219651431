import { VehiclesSearchCriteria } from './../vehicles/models/VehiclesSearchCriteria';
import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { VehicleMaintenancesSearchCriteria } from './models/VehicleMaintenancesSearchCriteria';
import { VehicleMaintenanceCounterListDto, VehicleMaintenanceDto } from './models/VehicleMaintenanceDto';
import CustomFile from 'common/models/CustomFile';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { CatalogVehicleMaintenancesSearchCriteria } from 'api/catalog/models/CatalogVehicleMaintenancesSearchCriteria'

class VehicleMaintenancesService {

    public getList(criteria: VehicleMaintenancesSearchCriteria) {
        return Http.get<Paged<VehicleMaintenanceDto>>('vehicleMaintenances', criteria);
    }

    public getNextMaintenances(criteria: VehicleMaintenancesSearchCriteria) {
        return Http.get<VehicleMaintenanceDto[]>('vehicleMaintenances/nextMaintenances', criteria);
    }

    public getById(id: string) {
        return Http.get<VehicleMaintenanceDto>('vehicleMaintenances/' + id);
    }

    public create(model: VehicleMaintenanceDto, photo?: CustomFile[]) {
        return Http.postWithFile<string>('vehicleMaintenances', model, photo ?? []);
    }

    public update(model: VehicleMaintenanceDto, photo?: CustomFile[]) {
        return Http.putWithFile('vehicleMaintenances', model, photo ? photo : []);
    }

    public remove(model: VehicleMaintenanceDto) {
        return Http.put('vehicleMaintenances/deactivate', model);
    }

    public getFuelList() {
        return Http.get<SelectValueLabel[]>('vehicleMaintenances/getFuelList');
    }

    public getColorsList() {
        return Http.get<SelectValueLabel[]>('vehicleMaintenances/getColorsList');
    }

    public getCountersList(criteria: VehiclesSearchCriteria) {
        return Http.get<VehicleMaintenanceCounterListDto>('vehicleMaintenances/counters-list', criteria);
    }
    
    public catalog(model?: CatalogVehicleMaintenancesSearchCriteria) {
        return Http.get<SelectValueLabel[]>('vehicleMaintenances/catalog', model);
    }

    public catalogWithDate(model?: CatalogVehicleMaintenancesSearchCriteria) {
        return Http.get<SelectValueLabel[]>('vehicleMaintenances/catalog-with-date', model);
    }
}

export default new VehicleMaintenancesService();