import React, { ReactElement } from 'react';
import styles from './Box.module.scss';

export type Props = {
    title?: string;
    subTitle?: string;
    renderOptions?: ReactElement;
    styles?: any;
    bodyClassName?: string;
} & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const Box = ({ children, renderOptions, title, subTitle, bodyClassName, ...props }: Props) => (
    <div {...props} className={`${styles.container} ${props.className || ''}`}>
        {(title || renderOptions) && <div className={styles.header}>
            <div className={styles.headerTitle}>
                <div>{title}</div>
                {subTitle ? <div className={styles.subTitle}>{subTitle}</div> : ''}                
            </div>
            {renderOptions && <div className={styles.headerOptions}>{renderOptions}</div>}
        </div>}
        <div style={props.styles ?? props.styles} className={bodyClassName || ''}>
            {children}
        </div>
    </div>
)

export default Box;
