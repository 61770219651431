
export enum AlertType {
    VERIFICATION_NEW_ALERT = 'VERIFICATION_NEW_ALERT',
    ANNOTATION_INTERVENTION_TYPE_INFORMATION_ALERT = 'ANNOTATION_INTERVENTION_TYPE_INFORMATION_ALERT', 
    ANNOTATION_INTERVENTION_TYPE_INTERVENTION_ALERT = 'ANNOTATION_INTERVENTION_TYPE_INTERVENTION_ALERT',
    ANNOTATION_INTERVENTION_TYPE_URGENT_ALERT = 'ANNOTATION_INTERVENTION_TYPE_URGENT_ALERT',    
    MAINTENANCE_PLANNED_ALERT =  'MAINTENANCE_PLANNED_ALERT',    
    MAINTENANCE_NEW_ALERT = 'MAINTENANCE_NEW_ALERT',
    RESERVATION_NEW_ALERT = 'RESERVATION_NEW_ALERT',
    EXPENSE_NEW_ALERT = 'EXPENSE_NEW_ALERT',
    EXPENSE_TREATED_ALERT = 'EXPENSE_TREATED_ALERT',
    EXPENSE_NOT_TREATED_ALERT = 'EXPENSE_NOT_TREATED_ALERT',
    EXPENSE_REFUND_APPROVED_ALERT = 'EXPENSE_REFUND_APPROVED_ALERT',
    EXPENSE_REFUND_REFUSED_ALERT = 'EXPENSE_REFUND_REFUSED_ALERT',
    CONTRACT_INSURANCE_ALERT = 'CONTRACT_INSURANCE_ALERT',
    CONTRACT_USE_ALERT   = 'CONTRACT_USE_ALERT',
    CONTRACT_MAINTENANCE_ALERT = 'CONTRACT_MAINTENANCE_ALERT',
    REQUEST_VERIFICATION_NEW_ALERT = 'REQUEST_VERIFICATION_NEW_ALERT',
}

export enum AlertTypeColorEnum {
    VERIFICATION_NEW_ALERT = '#56CF76',//green
    ANNOTATION_INTERVENTION_TYPE_INFORMATION_ALERT = '#E2B434', //yellow 
    ANNOTATION_INTERVENTION_TYPE_INTERVENTION_ALERT = '#1781A1',//blue
    ANNOTATION_INTERVENTION_TYPE_URGENT_ALERT = '#DC4C4C',//red    
    MAINTENANCE_PLANNED_ALERT =  '#56CF76',    
    MAINTENANCE_NEW_ALERT = '#56CF76',
    RESERVATION_NEW_ALERT = '#56CF76',
    EXPENSE_NEW_ALERT = '#56CF76',
    EXPENSE_TREATED_ALERT = '#56CF76',
    EXPENSE_NOT_TREATED_ALERT = '#56CF76',
    EXPENSE_REFUND_APPROVED_ALERT = '#56CF76',
    EXPENSE_REFUND_REFUSED_ALERT = '#56CF76',
    CONTRACT_INSURANCE_ALERT = '#56CF76',
    CONTRACT_USE_ALERT   = '#56CF76',
    CONTRACT_MAINTENANCE_ALERT = '#56CF76',
    REQUEST_VERIFICATION_NEW_ALERT = '#1781A1' //todo
}