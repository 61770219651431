import CustomFile from 'common/models/CustomFile';
import { blobToBase64, MIMETYPES_IMAGE } from 'Config';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './HorizontalMediaSelector.module.scss';
import { FaCamera } from 'react-icons/fa';
import QuestionYesNo from '../questionYesNo/QuestionYesNo';
import { ReactComponent as TrashIcon } from 'assets/svg/trash_no_color.svg';
import { MediaItemDto } from 'api/common/models/MediaItemDto';
import Utils from 'common/services/Utils';
import ModalImageEditor from '../modalImageEditor/ModalImageEditor';

export type Props = {
    container: string;
    onChange: (medias: MediaItemDto[]) => void;
    onRemove: (media: MediaItemDto) => void;
    medias: MediaItemDto[];
    isDetails?: boolean;
    noItemsText?: string;
};

const HorizontalMediaSelector: React.FC<Props> = ({ container, onChange, onRemove, medias, isDetails = false, noItemsText }: Props) => {
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement>(null);
    const [selectedMedia, setSelectedMedia] = useState<MediaItemDto | null>(null);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [showImageEdit, setShowImageEdit] = useState(false);

    const importFile = () => {
        if (isDetails) {
            return;
        }

        if (inputRef.current) {
            inputRef.current.click();
        }
    }

    const uploadFile = async (event: any) => {
        const m: MediaItemDto[] = [];
        for (let size = 0; size < event.target.files.length; size++) {
            const file: CustomFile = event.target.files[size];

            const fileUrl = await blobToBase64(file);
            // file.urlBase64 = fileUrl;
            file.containerName = container;

            m.push({
                tempFile: file,
                id: Utils.newGuid(),
                url: fileUrl,
                fileName: file.name,
            });
        }

        onChange([...medias, ...m]);
    }

    const removeMedia = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, media: MediaItemDto) => {
        e.preventDefault();
        e.stopPropagation();

        setShowRemoveModal(true);
        setSelectedMedia(media);
    }

    const onCancelRemove = () => {
        setShowRemoveModal(false);
        setSelectedMedia(null);
    }

    const onRemoveDone = () => {
        setShowRemoveModal(false);

        if (selectedMedia) {
            const filteredMedias = [...medias.filter(x => x.id !== selectedMedia?.id)];
            onChange(filteredMedias);

            onRemove(selectedMedia);
        }

        setSelectedMedia(null);
    }

    const onClickMedia = (media: MediaItemDto) => {
        setSelectedMedia(media);
        setShowImageEdit(true);
    }

    const renderMedia = (media: MediaItemDto, index: number) => (
        <div
            key={`media-${index}`}
            className={styles.itemPreview}
            style={{ backgroundImage: `url('${media.url}')` }}
            onClick={() => onClickMedia(media)}
        >
            {!isDetails && <div className={styles.removeButton}>
                <TrashIcon className={styles.removeIcon} onClick={e => removeMedia(e, media)} />
            </div>}
        </div>
    );

    const onCancelEditMedia = () => {
        setShowImageEdit(false);
        setSelectedMedia(null);
    }

    const onSaveEditMedia = async (media: MediaItemDto) => {
        setShowImageEdit(false);
        setSelectedMedia(null)

        if (!media.tempFile) {
            return;
        }

        const fileUrl = await blobToBase64(media.tempFile);

        onRemove(media);

        onChange(medias.map(m => {
            if (m.id === media.id) {
                return {
                    ...m,
                    id: Utils.newGuid(),
                    tempFile: media.tempFile,
                    url: fileUrl,
                };
            }
            return {
                ...m,
                id: m.id === media.id ? Utils.newGuid() : m.id,
                tempFile: m.id === media.id ? media.tempFile : m.tempFile,
                url: m.id === media.id ? fileUrl : m.url,
            };
        }));
    }

    return (
        <div className={styles.container}>
            <div className={styles.list}>
                {!isDetails && <div className={styles.addMedia} onClick={importFile}>
                    <FaCamera className={styles.addMediaIcon} />
                </div>}
                {medias.map((media, index) => renderMedia(media, index))}
                {isDetails && medias.length === 0 && noItemsText && <div className={styles.noItemsText}>{noItemsText}</div>}
            </div>

            <input
                type="file"
                hidden
                multiple={true}
                accept={MIMETYPES_IMAGE}
                className="multiple-upload"
                onChange={uploadFile}
                ref={inputRef}
            />
            
            <QuestionYesNo
                onNo={onCancelRemove}
                onYes={onRemoveDone}
                isVisible={showRemoveModal}
                message={t('common.messages.remove_media')}/>

            {selectedMedia && <ModalImageEditor
                isOpen={showImageEdit}
                media={selectedMedia}
                onCancel={onCancelEditMedia}
                onSave={onSaveEditMedia}
                containerName={container}
                disabled={isDetails}
            />}
        </div>
    );
}

export default HorizontalMediaSelector;
