import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { RoleDto } from 'api/roles/models/RoleDto';
import Loading from 'common/services/Loading';
import RolesService from 'api/roles/RolesService';
import Logger from 'common/services/Logger';
import Button from 'common/components/button/Button';
import styles from './RolesDetails.module.scss';
import InputError from 'common/components/inputError/InputError';
import Input from 'common/components/input/Input';
import Label from 'common/components/label/Label';
import FormItem from 'common/components/formItem/FormItem';
import { useForm } from 'react-hook-form';
import { DEFAULT_INPUT_RULES_WITH_REQUIRED, LOGGER_LOG_TYPE } from 'Config';
import GreyArrow from 'assets/svg/Arrow-grey.svg';
import React, { useEffect, useState } from 'react';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { useHistory, useParams } from 'react-router-dom';

const RolesDetails = () => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const { id: roleId, type } = useParams<{ id: string, type: string }>();
    const [role, setRole] = useState<RoleDto>();
    const [isDetails, setIsDetails] = useState<boolean>(type === 'details');
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<RoleDto | null>(null);
    const history = useHistory();

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasRolesWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_ROLES_WRITE']);

    const form = useForm<RoleDto>({ defaultValues: { ...role } });
    const { register, handleSubmit, errors } = form;

    const goToList = () => {
        history.push(`/security/roles`);
    };

    const onCancel = () => {
        if (isDetails) {
            goToList();
        } else if (!roleId) {
            goToList();
        }
        else {
            setIsDetails(true);
        }
    };

    const getData = async () => {
        if (!roleId) { return; }
        Loading.show();
        try {
            const role = await RolesService.getById(roleId)
            if (role.readOnly || role.system) {
                role.name = t(('common.roles.' + role.name) as any);
            }
            setRole(role);
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), {
                appearance: 'error',
            });

            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                `Couldn't get catalog roles types`,
                error
            );
            Loading.hide();
        }
    }

    useEffect(() => {
        getData();
    }, [isDetails]);


    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }
        try {
            await RolesService.remove(itemToRemove);
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });
            onCancelRemove();
            goToList();
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete role`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
        }
    };

    const showRemoveItemDialog = async (item: RoleDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const onSubmit = async (formData: RoleDto) => {
        try {
            if (!hasRolesWritePolicy) { return; }

            Loading.show();

            if (role != null) {
                role.name = formData.name;
                await RolesService.update(role);
            } else {
                await RolesService.create(formData);
            }

            Loading.hide();
            addToast(t('common.messages.record_save_success'), {
                appearance: 'success',
            });
            goToList();
        } catch (error) {
            addToast(t('common.messages.record_save_error'), {
                appearance: 'error',
            });

            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                `Couldn't create or update role`,
                error
            );
            Loading.hide();
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.pageNameContainer}>
                <div className={styles.pageNameContent} onClick={() => goToList()} >
                    <img className={styles.pageNameContentImage} src={GreyArrow} />
                    <span className={styles.pageNameContentText}>
                        {t('roles.title')}
                    </span>
                </div>
            </div>

            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <FormItem>
                    <Label className={styles.label}>
                        {t('roles.name')}*
                    </Label>
                    <Input
                        name='name'
                        placeholder={t('roles.name')}
                        defaultValue={role?.name}
                        ref={register({ ...DEFAULT_INPUT_RULES_WITH_REQUIRED })}
                        disabled={isDetails}
                    />
                    <InputError error={errors.name} />
                </FormItem>

                <div className={styles.buttonContainer}>
                    <Button preset={'secondary'}
                        type='button'
                        text={t('common.cancel')}
                        onClick={() => onCancel()} />

                    {isDetails && !role?.readOnly && hasRolesWritePolicy &&
                        <Button
                            type="button"
                            text={t('common.remove')}
                            preset={'danger'}
                            onClick={() => showRemoveItemDialog({ id: role?.id } as RoleDto)} />
                    }
                    {!isDetails && hasRolesWritePolicy &&
                        <Button
                            type='submit'
                            text={t('common.save')} />
                    }
                </div>
            </form>
            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('common.messages.remove_record_with_ident', { name: itemToRemove?.name ?? '' })}/>
        </div>
    );
};

export default RolesDetails;
