import React from 'react';
import styles from './AlertsCounter.module.scss';
import AlertIcon from 'assets/svg/i_alertas_white.svg';

export type Size = 'normal' | 'small'

type Props = {
    totalAlerts: number,
    className?: string,
    size?: Size
};

const AlertsCounter: React.FC<Props> = ({ totalAlerts, className, size }: Props) => {

    return (
        <div className={`${styles.container} ${className || ''}`}  >
            <div className={styles.subContainer}>
                <img src={AlertIcon} className={`${styles.icon} ${(size == 'small' ? styles.iconSmall : '')}`} />
                <span className={`${styles.counter} ${(size == 'small' ? styles.counterSmall : '')}`} >{totalAlerts > 99 ? '99+' : totalAlerts}</span>
            </div>
        </div>
    );
}

export default AlertsCounter;
