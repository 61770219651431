import { useMapEvents } from 'react-leaflet';

export type Props = {
    onZoomChange: (level : number) => void;
};

const ZoomLevelControl = ({ onZoomChange }: Props) => {
    const mapEvents = useMapEvents({
        zoomend: () => {
            onZoomChange(mapEvents.getZoom());
        },
    });

    return <></>
}

export default ZoomLevelControl;