import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { LocalSearchCriteria } from './models/LocalSearchCriteria';
import { LocalDto } from './models/LocalDto';
import CustomFile from 'common/models/CustomFile';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { CatalogLocalSearchCriteria } from 'api/catalog/models/CatalogLocalSearchCriteria';

class LocalsService {

    public getList(criteria: LocalSearchCriteria) {
        return Http.get<Paged<LocalDto>>('locals', criteria);
    }
    
    public catalog(criteria?: CatalogLocalSearchCriteria) {
        return Http.get<SelectValueLabel[]>('locals/catalog', criteria);
    }

    public getById(id: string) {
        return Http.get<LocalDto>('locals/' + id);
    }

    public create(model: LocalDto, logo: CustomFile[]) {
        return Http.postWithFile<string>('locals', model, logo);
    }

    public update(model: LocalDto, logo: CustomFile[]) {
        return Http.putWithFile('locals', model, logo);
    }

    public remove(model: LocalDto) {
        return Http.put('locals/deactivate', model);
    }
}

export default new LocalsService();