import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import styles from './VerificationFiltersScreen.module.scss';
import Button from '../../../../common/components/button/Button';
import FormItem from '../../../../common/components/formItem/FormItem';
import Label from '../../../../common/components/label/Label';
import { Col, Row } from 'react-flexbox-grid';
import { SelectValueLabel } from '../../../../common/types/SelectValueLabel';
import VehiclesService from '../../../../api/vehicles/VehiclesService';
import Select from 'common/components/select/Select';
import { Controller, useForm } from 'react-hook-form';
import UsersService from 'api/users/UsersService';
import { UsersSelectItemDto } from 'api/users/models/UserDto';
import RangeInputPicker from 'common/components/rangeInputPicker/RangeInputPicker';
import DriversService from 'api/drivers/DriversService';
import Utils from 'common/services/Utils';
import { VerificationType } from 'api/carVerification/enums/VerificationType';
import { AlertSubjectEnum } from 'api/alerts/enums/AlertSubjectEnum';
import SelectController from 'common/components/select/SelectController';
import { removeAccents } from 'Config';

export interface Filters {
    vehicleId?: string;
    vehicleName?: string;
    type?: VerificationType;
    responsibleId?: string;
    responsibleName?: string;
    driverId?: string;
    driverName?: string;
    startDate?: Date;
    endDate?: Date;
    interventionType?: AlertSubjectEnum;
    page?:number;
}

type Props = {
    filters: Filters;
    onChange?: (filters: Filters) => void;
    onFilter: (filters: Filters) => void;
    genericFilter?: boolean;
}

const VerificationFiltersScreen: React.FC<Props> = ({ filters, genericFilter = false, onChange, onFilter }: Props) => {
    const { t } = useTranslation();

    const form = useForm<Filters>({ shouldUnregister: false, shouldFocusError: true, defaultValues: filters });
    const { setValue, getValues } = form;
    const [vehiclesOptions, setVehicles] = useState<SelectValueLabel[]>([]);
    const [responsiblesOptions, setResponsibles] = useState<SelectValueLabel[]>([]);
    const [driversOptions, setDrivers] = useState<SelectValueLabel[]>([]);

    const typesOptions: SelectValueLabel[] = Utils.sortAlphabetically(Object.keys(VerificationType).map(type => {
        return { value: type, label: t(`car_verification.annotation_types.${type}`.toLowerCase() as any) }
    }));

    const interventionOptions: SelectValueLabel[] = Utils.sortAlphabetically(Object.keys(AlertSubjectEnum).map(interventionType => {
        return { value: interventionType, label: t(`car_verification.intervention_type.${interventionType}`.toLowerCase() as any) }
    }));

    const getData = async () => {
        const [vehiclesOptionsList, responsiblesOptionsList, driversList] = await Promise.all([
            VehiclesService.catalog(),
            UsersService.getAllForCompany(),
            DriversService.catalog(),
        ]);

        setVehicles(vehiclesOptionsList);
        setResponsibles((responsiblesOptionsList || []).map((x: UsersSelectItemDto) => ({ value: x.id || '', label: x.realName || '' })));
        setDrivers(driversList);
    }

    useEffect(() => {
        getData();
    }, []);

    const clearFilters = () => {
        form.reset({
            startDate: undefined,
            endDate: undefined,
            responsibleId: undefined,
            responsibleName: undefined,
            vehicleId: undefined,
            vehicleName: undefined,
            type: undefined,
            driverId: undefined,
            driverName: undefined,
            interventionType: undefined

        });
        onInputChange();
        onSubmit(form.getValues());
    }

    const onSubmit = (_filters: Filters) => {
        onFilter(_filters);
    }

    const onInputChange = () => {
        if (onChange) {
            onChange(form.getValues());
        }
    }

    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className={styles.formContent}>
                <div>
                    <FormItem>
                        <Row>
                            {genericFilter && <Col xs={12} xl={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('vehicle.vehicle')}</Label>
                                    <Controller
                                        render={({ value }) => {
                                            return (
                                                <Select
                                                    options={vehiclesOptions}
                                                    isClearable
                                                    placeholder={t('vehicle.vehicle')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        setValue('vehicleId', data?.value ?? null);
                                                        setValue('vehicleName', data?.label ?? null);
                                                        onInputChange();
                                                    }}
                                                    value={vehiclesOptions.find(x => x.value === value) ?? null}
                                                />
                                            );
                                        }}
                                        control={form.control}
                                        name="vehicleId"
                                        defaultValue={getValues('vehicleId')} />
                                </FormItem>
                            </Col>}

                            <Col xs={12} xl={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('common.type')}</Label>
                                    <SelectController
                                        form={form}
                                        isClearable={true}
                                        name='type'
                                        options={typesOptions}
                                        placeholder={t('common.type')}
                                        isDisabled={false}
                                        filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                        onChange={() => onInputChange()}
                                    />
                                </FormItem>
                            </Col>

                            <Col xs={12} xl={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('common.responsible')}</Label>
                                    <Controller
                                        render={({ value }) => {
                                            return (
                                                <Select
                                                    options={responsiblesOptions}
                                                    isClearable
                                                    placeholder={t('common.responsible')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        setValue('responsibleId', data?.value ?? null);
                                                        setValue('responsibleName', data?.label ?? null);
                                                        onInputChange();
                                                    }}
                                                    value={responsiblesOptions.find(x => x.value === value) ?? null}
                                                />
                                            );
                                        }}
                                        control={form.control}
                                        name="responsibleId"
                                        defaultValue={getValues('responsibleId')} />
                                </FormItem>
                            </Col>

                            <Col xs={12} xl={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('common.driver')}</Label>
                                    <Controller
                                        render={({ value }) => {
                                            return (
                                                <Select
                                                    options={driversOptions}
                                                    isClearable
                                                    placeholder={t('common.driver')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        setValue('driverId', data?.value ?? null);
                                                        setValue('driverName', data?.label ?? null);
                                                        onInputChange();
                                                    }}
                                                    value={driversOptions.find(x => x.value === value) ?? null}
                                                />
                                            );
                                        }}
                                        control={form.control}
                                        name="driverId"
                                        defaultValue={getValues('driverId')} />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <RangeInputPicker
                                    label={t('car_verification.filter_date')}
                                    onChange={(startValue: any, endValue: any) => {
                                        setValue('startDate', startValue ? moment(startValue).toDate() : undefined);
                                        setValue('endDate', endValue ? moment(endValue).toDate() : undefined);
                                        onInputChange();
                                    }}
                                    defaultStartValue={getValues('startDate')}
                                    defaultEndValue={getValues('endDate')}
                                    isDatePicker
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} xl={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('common.intervention_type')}</Label>
                                    <SelectController
                                        form={form}
                                        isClearable={true}
                                        name='interventionType'
                                        options={interventionOptions}
                                        placeholder={t('common.intervention_type')}
                                        isDisabled={false}
                                        filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                        onChange={() => onInputChange()}
                                    />
                                </FormItem>
                            </Col>
                        </Row>

                    </FormItem>
                </div>
                <div className={styles.buttonsFooter}>
                    <FormItem>
                        <Button
                            text={t('common.remove')}
                            size={'normal'}
                            preset={'secondary'}
                            onClick={clearFilters}
                            type='reset' />
                        <Button
                            type='submit'
                            text={t('common.apply')}
                            size={'normal'}
                        />
                    </FormItem>
                </div>
            </div>
        </form>
    );
}

export default memo(VerificationFiltersScreen);