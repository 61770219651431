import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import AuthorizeRoute from './common/routes/AuthorizeRoute';
import { ApplicationPaths, LoginActions, LogoutActions } from './Config';
import IdentityLoginScreen from './screens/identity/IdentityLoginScreen';
import IdentityLogoutScreen from './screens/identity/IdentityLogoutScreen';
import AuthenticatedLayout from './common/layouts//authenticatedLayout/AuthenticatedLayout';
//import ProtectedRoute from './common/routes/ProtectedRoute';
import AccountScreen from './screens/account/AccountScreen';
import NotFoundScreen from './screens/errors/NotFoundScreen';
import VehiclesManagementScreen from 'screens/vehicles-management/VehiclesManagementScreen';
import PolicyRoute from 'common/routes/PolicyRoute';
import VehicleScreen from 'screens/vehicle/VehicleScreen';
import SettingsScreen from 'screens/settings/SettingsScreen';
import SecurityScreen from 'screens/security/SecurityScreen';
import ListChargesScreen from 'screens/charges/ListChargesScreen';
import ChargeScreen from 'screens/vehicle/components/carChargeTab/components/ChargeScreen';
import CompaniesScreen from 'screens/companies/CompaniesScreen';
import ImportDataScreen from 'screens/import-data/ImportDataScreen';
import CalculateMonthlyCostsScreen from 'screens/god-mode/calculate-monthly-costs/CalculateMonthlyCostsScreen';
import CompanyScreen from 'screens/company/CompanyScreen';
import DriversScreen from 'screens/drivers/DriversScreen';
import DriverScreen from 'screens/driver/DriverScreen';
import VerificationScreen from 'screens/verification/VerificationScreen';
import AlertsScreen from 'screens/alerts/AlertsScreen';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import ForbiddenScreen from 'screens/errors/ForbiddenScreen';
import UsersService from 'api/users/UsersService';
import MaintenancesScreen from 'screens/maintenances/MaintenancesScreen';
import VehiclesScreen from 'screens/vehicles/VehiclesScreen';
import ReservationScreen from 'screens/reservations/ReservationScreen';
import DashboardScreen from 'screens/dashboard/DashboardScreen';
import ImportChargeScreen from 'screens/import-charges/ImportChargeScreen';
import ImportChargesScreen from 'screens/import-charges/ImportChargesScreen';
import VehiclesMileageScreen from 'screens/vehicles-mileage/VehiclesMileageScreen';
import NewAccount from 'screens/new_account/NewAccountScreen';
import VehiclesGpsScreen from 'screens/vehicles-gps/VehiclesGpsScreen';
import AlarmsListScreen from 'screens/alarms/components/alarmsList/AlarmsListScreen';
import AlarmScreen from 'screens/alarms/AlarmScreen';

const HomeRedirection = () => {
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const policies = loggedUser?.policies || [];
    const userIssues = loggedUser?.issues || [];

    if (userIssues.length > 0) {
        return <Redirect to={ { pathname: '/account/details/subscription' } } />
    }
    else if (UsersService.hasPolicies(policies, ['SETTINGUP_VEHICLES_READ'])) {
        return <Redirect to="/vehicles" />;
    } else if (UsersService.hasPolicies(policies, ['SETTINGUP_COMPANIES_READ', 'SETTINGUP_COMPANIES_WRITE'])) {
        return <Redirect to="/companies" />;
    }

    return <Redirect to="/403" />;

}

const DefaultRouter = () => {
    return (
        <Router>
            <Switch>
                <Route path="/404" component={NotFoundScreen} />
                <Route path="/403" component={ForbiddenScreen} />
                <Route path={ApplicationPaths.Login} render={() => (<IdentityLoginScreen action={LoginActions.Login} />)} />
                <Route path={ApplicationPaths.Reset} render={() => (<IdentityLoginScreen action={LoginActions.Reset} />)} />
                <Route path={ApplicationPaths.LoginFailed} render={() => (<IdentityLoginScreen action={LoginActions.LoginFailed} />)} />
                <Route path={ApplicationPaths.LoginCallback} render={() => (<IdentityLoginScreen action={LoginActions.LoginCallback} />)} />
                <Route path={ApplicationPaths.LogOut} render={() => (<IdentityLogoutScreen action={LogoutActions.Logout} />)} />
                <Route path={ApplicationPaths.LogOutCallback} render={() => (<IdentityLogoutScreen action={LogoutActions.LogoutCallback} />)} />

                <AuthorizeRoute path="*">
                    <AuthenticatedLayout>
                        <Switch>
                            <Route path="/" exact component={HomeRedirection} />
                            <Route path="/account/:type/:tabid?" exact component={AccountScreen} />
                            <PolicyRoute path="/create_account" exact component={NewAccount} type='OR' policies={[
                                'SETTINGUP_COMPANIES_READ', 'SETTINGUP_COMPANIES_WRITE'
                            ]} />
                            {/* Enterprises */}
                            <PolicyRoute path="/companies" exact component={CompaniesScreen} type='OR' policies={[
                                'SETTINGUP_COMPANIES_READ', 'SETTINGUP_COMPANIES_WRITE'
                            ]} />
                            {/* Import Data */}
                            <PolicyRoute path="/import-data" exact component={ImportDataScreen} type='OR' policies={[
                                'SETTINGUP_COMPANIES_WRITE', 'SETTINGUP_IMPORT_DATA_WRITE'
                            ]} />
                            <PolicyRoute path="/companies/:type/:id?" exact component={CompanyScreen} type='OR' policies={[
                                'SETTINGUP_COMPANIES_READ', 'SETTINGUP_COMPANIES_WRITE'
                            ]} />
                            <PolicyRoute path="/dashboard" exact component={DashboardScreen} type='OR' policies={[
                                'SETTINGUP_VEHICLES_READ', 'SETTINGUP_VEHICLES_WRITE'
                            ]} />
                            {/* Voitures */}
                            <PolicyRoute path="/vehicles-management" exact component={VehiclesManagementScreen} type='OR' policies={[
                                'SETTINGUP_VEHICLES_MANAGEMENT_READ', 'SETTINGUP_VEHICLES_MANAGEMENT_WRITE'
                            ]} />
                            <PolicyRoute path="/vehicles-mileage" exact component={VehiclesMileageScreen} type='OR' policies={[
                                'SETTINGUP_VEHICLES_MILEAGE_READ', 'SETTINGUP_VEHICLES_MILEAGE_WRITE'
                            ]} />
                            <PolicyRoute path="/vehicles" exact component={VehiclesScreen} type='OR' policies={[
                                'SETTINGUP_VEHICLES_READ', 'SETTINGUP_VEHICLES_WRITE'
                            ]} />
                            <PolicyRoute path="/vehicles/:type/:vehicleId?/:tabId?/:itemType?/:itemId?/:contractType?/:contractId?/:month?/:returnUrl?" exact component={VehicleScreen} type='OR' policies={[
                                'SETTINGUP_VEHICLES_READ', 'SETTINGUP_VEHICLES_WRITE',
                                'SETTINGUP_VEHICLES_MANAGEMENT_READ', 'SETTINGUP_VEHICLES_MILEAGE_READ', 'SETTINGUP_VEHICLES_MANAGEMENT_WRITE'
                            ]} />
                            {/* Conducteurs */}
                            <PolicyRoute path="/drivers" exact component={DriversScreen} type='OR' policies={[
                                'SETTINGUP_DRIVERS_READ', 'SETTINGUP_DRIVERS_WRITE'
                            ]} />
                            <PolicyRoute path="/drivers/:type/:driverId?/:tabId?/:itemType?/:itemId?" exact component={DriverScreen} type='OR' policies={[
                                'SETTINGUP_DRIVERS_READ', 'SETTINGUP_DRIVERS_WRITE'
                            ]} />
                            {/* Frais */}
                            <PolicyRoute path="/charges" exact component={ListChargesScreen} type='OR' policies={[
                                'SETTINGUP_CHARGES_READ', 'SETTINGUP_CHARGES_WRITE'
                            ]} />
                            <PolicyRoute path="/charges/:type/:itemId?" exact component={ChargeScreen} type='OR' policies={[
                                'SETTINGUP_CHARGES_READ', 'SETTINGUP_CHARGES_WRITE'
                            ]} />
                            {/* Alertes */}
                            <PolicyRoute path="/alerts" exact component={AlertsScreen} type='OR' policies={[
                                'SETTINGUP_ALERTS_READ'
                            ]} />
                            {/* Maintenances */}
                            <PolicyRoute path="/maintenances/:type?/:itemId?/" exact component={MaintenancesScreen} type='OR' policies={[
                                'SETTINGUP_VEHICLE_MAINTENANCES_READ', 'SETTINGUP_VEHICLE_MAINTENANCES_WRITE'
                            ]} />
                            {/* Verifications */}
                            <PolicyRoute path="/verifications/:type?/:itemId?/" exact component={VerificationScreen} type='OR' policies={[
                                'SETTINGUP_VERIFICATIONS_READ', 'SETTINGUP_VERIFICATIONS_WRITE'
                            ]} />
                            {/* Reservations*/}
                            <PolicyRoute path="/reservations/:type?/:itemId?/" exact component={ReservationScreen} type='OR' policies={[
                                'SETTINGUP_RESERVATIONS_READ', 'SETTINGUP_RESERVATIONS_WRITE'
                            ]} />
                            {/* Utilisateurs */}
                            <PolicyRoute path="/settings/:tabId?/:type?/:itemId?" exact component={SettingsScreen} type='OR' policies={[
                                'SETTINGUP_GENERIC_TYPES_READ', 'SETTINGUP_GENERIC_TYPES_WRITE',
                                'SETTINGUP_VEHICLE_SEGMENTS_READ', 'SETTINGUP_VEHICLE_SEGMENTS_WRITE',
                                'SETTINGUP_VEHICLE_BRANDS_READ', 'SETTINGUP_VEHICLE_BRANDS_WRITE',
                                'SETTINGUP_VEHICLE_MODELS_READ', 'SETTINGUP_VEHICLE_MODELS_WRITE',
                                'SETTINGUP_SUPPLIERS_READ', 'SETTINGUP_SUPPLIERS_WRITE',
                                'SETTINGUP_LOCALS_READ', 'SETTINGUP_LOCALS_WRITE',
                                'SETTINGUP_DIAGNOSTIC_TYPES_READ', 'SETTINGUP_DIAGNOSTIC_TYPES_WRITE',
                                'SETTINGUP_COMMUNICATIONS_READ', 'SETTINGUP_COMMUNICATIONS_WRITE',
                                'SETTINGUP_NOTIFICATIONS_READ', 'SETTINGUP_NOTIFICATIONS_WRITE'
                            ]} />
                            {/* Paramétrages */}
                            <PolicyRoute path="/security/:tabId?/:type?/:id?" exact component={SecurityScreen} type='OR' policies={[
                                'SETTINGUP_USERS_READ', 'SETTINGUP_USERS_WRITE',
                                'SETTINGUP_ROLES_READ', 'SETTINGUP_ROLES_WRITE',
                                'SETTINGUP_RULES_READ', 'SETTINGUP_RULES_WRITE'
                            ]} />
                            {/* ImportCharges*/}

                            <PolicyRoute path="/import-charges" exact component={ImportChargesScreen} type='OR' policies={[
                                'SETTINGUP_IMPORT_EXPENSES_WRITE', 'SETTINGUP_IMPORT_EXPENSES_READ'
                            ]} />

                            <PolicyRoute path="/import-charges/:type?/:itemId?/" exact component={ImportChargeScreen} type='OR' policies={[
                                'SETTINGUP_IMPORT_EXPENSES_WRITE', 'SETTINGUP_IMPORT_EXPENSES_READ'
                            ]} />
                            <PolicyRoute path="/vehicles-gps" exact component={VehiclesGpsScreen} type='OR' policies={['SETTINGUP_GPS_READ']} />
                            {/* Admin GodMode */}
                            <PolicyRoute path="/god-mode/calculate-monthly-costs/:companyId?" exact component={CalculateMonthlyCostsScreen} type='OR' policies={['SETTINGUP_COMPANIES_WRITE']} />

                            {/* Alarms */}
                            <PolicyRoute path="/alarms" exact component={AlarmsListScreen} type='OR' policies={['SETTINGUP_GPS_READ']} />
                            <PolicyRoute path="/alarms/:type/:alarmId?" exact component={AlarmScreen} type='OR' policies={['SETTINGUP_GPS_READ']} />
                            <Route>
                                <Redirect to="/404" />
                            </Route>
                        </Switch>
                    </AuthenticatedLayout>
                </AuthorizeRoute>
                <Route path="*">
                    <Redirect to="/404" />
                </Route>
            </Switch>
        </Router>
    );
}

export default DefaultRouter;
