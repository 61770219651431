import React, { useState } from 'react';
import Timekeeper, { TimeOutput } from 'react-timekeeper';
import { FaRegClock } from 'react-icons/fa';
import DateTimePickerController from 'common/components/dateTimePicker/DateTimePickerController';
import InputGroupController from 'common/components/inputGroup/InputGroupController';
import styles from './DateWithHour.module.scss';

type Props = {
    form: any;
    //isDetails: boolean;
    datePlaceholder?: string;
    dateSelected: any;
    dateFormat: string;
    dateCustomInput: JSX.Element;
    dateName: string;
    dateRequired?: boolean;
    dateDisabled: boolean;
    minDate?: Date | undefined;
    onChangeDate: (date: Date) => void;

    onTimeChoose: (time: TimeOutput) => void;
    timePlaceholder?: string;
    timeName: string;
    timeDisabled?: boolean;

    className?: string;
};

const DateWithHour = ({ form, datePlaceholder, dateSelected, dateFormat, dateCustomInput, dateName, dateRequired, dateDisabled, onChangeDate,
    onTimeChoose, timePlaceholder, timeName, timeDisabled, className, minDate, ...props }: Props) => {
    const [showTime, setShowTime] = useState(false);

    return (
        <div className={`${styles.container} ${className ? className : ''}`}>
            <div className={styles.leftChild}>
                <DateTimePickerController
                    form={form}
                    placeholderText={datePlaceholder}
                    onChange={onChangeDate}
                    selected={dateSelected}
                    dateFormat={dateFormat}
                    customInput={dateCustomInput}
                    name={dateName}
                    autoComplete='off'
                    rules={{ required: dateRequired }}
                    disabled={dateDisabled}
                    minDate={minDate}
                />
            </div>
            <div className={styles.rightChild}>
                {showTime &&
                    <div style={{ zIndex: 1, position: 'relative', maxHeight: '1rem' }}>
                        <Timekeeper
                            closeOnMinuteSelect={true}
                            hour24Mode={true}
                            switchToMinuteOnHourSelect
                            onDoneClick={hour => { setShowTime(false); onTimeChoose(hour) }}
                        />
                    </div>
                }
                {!showTime &&
                    <InputGroupController
                        icon={<FaRegClock />}
                        placeholder={timePlaceholder}
                        name={timeName}
                        form={form}
                        disabled={timeDisabled}
                        onClick={() => setShowTime(true)}
                        isClearable={true}
                        removeInputBorderRadius={true}
                    />
                }
            </div>
        </div>
    );
};

export default DateWithHour;
