import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import styles from './VehiclesManagementFiltersScreen.module.scss';
import Button from '../../../common/components/button/Button';
import FormItem from '../../../common/components/formItem/FormItem';
import Label from '../../../common/components/label/Label';
import { Col, Row } from 'react-flexbox-grid';
import { SelectValueLabel } from '../../../common/types/SelectValueLabel';
import Select from 'common/components/select/Select';
import { Controller, useForm } from 'react-hook-form';
import VehicleSegmentsService from 'api/vehicleSegments/VehicleSegmentsService';
import Input from 'common/components/input/Input';
import RangeInputPicker from 'common/components/rangeInputPicker/RangeInputPicker';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import UseContractsService from 'api/contracts/useContract/UseContractsService';
import Utils from 'common/services/Utils';
import ToggleSwitch from 'common/components/toggleSwitch/ToggleSwitch';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';
import { useToasts } from 'react-toast-notifications';

export interface Filters {
    registrationNumber?: string;
    registrationDate?: Date | null;
    segmentId?: string;
    segmentName?: string;
    driverId?: string;
    driverName?: string;
    costAverage?: number[];
    costAccumulated?: number[];
    costsStartDate?: Date;
    costsEndDate?: Date;
    useContractTypeId?: string;
    useContractTypeName?: string;
    useContractActive?: boolean;
    useContractEndsBefore?: Date | null;
    useContractEndsAfter?: Date | null;
    inactives?: boolean;
    page?: number;
}

type Props = {
    filters: Filters;
    hasCountersPolicy?: boolean;
    onChange: (filters: Filters) => void;
    onFilter: (filters: Filters) => void;
}

const VehiclesManagementFiltersScreen: React.FC<Props> = ({ filters, hasCountersPolicy, onChange, onFilter }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const guidEmpty = '00000000-0000-0000-0000-000000000000';
    const form = useForm<Filters>({ shouldUnregister: false, shouldFocusError: true, defaultValues: filters });
    const { getValues, setValue, reset, watch, control } = form;
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const [segmentsOptions, setSegments] = useState<SelectValueLabel[]>([]);
    const [useContractTypeOptions, setUseContractTypeOptions] = useState<SelectValueLabel[]>([]);
    const [selectUseContractActive, setSelectUseContractActive] = useState<boolean | undefined>();
    const [selectUseContractTypeId, setSelectUseContractTypeId] = useState<string | undefined>();

    //const [driversOptions, setDrivers] = useState<SelectValueLabel[]>([]);

    const getData = async () => {
        try {
            Loading.show();

            const segmentsOptionsDB = await VehicleSegmentsService.catalog();
            setSegments(segmentsOptionsDB);

            const useContractTypesOrig = await UseContractsService.getUseContractTypes();
            const useContractTypes = useContractTypesOrig.map(x => ({ ...x, label: t(`common.use_contract_types.${x.label}` as any) }));
            const useContractTypesOrdered = Utils.sortAlphabetically(useContractTypes);
            const ___useContractTypesOrdered = [...useContractTypesOrdered, { value: '00000000-0000-0000-0000-000000000000', label: t('contracts.use_contract.without_contract') }];
            setUseContractTypeOptions(___useContractTypesOrdered);

        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get vehicles list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
        finally {
            Loading.hide();
        }
    }

    useEffect(() => {

        if (form.getValues('useContractActive') != undefined) {
            setSelectUseContractActive(form.getValues('useContractActive'));
        }

        if (form.getValues('useContractTypeId') != undefined) {
            setSelectUseContractTypeId(form.getValues('useContractTypeId'));
        }



        getData();
    }, []);

    const clearFilters = () => {
        reset({
            registrationNumber: undefined,
            registrationDate: undefined,
            segmentId: undefined,
            costAverage: undefined,
            costAccumulated: undefined,
            useContractTypeId: undefined,
            useContractActive: undefined,
            useContractEndsAfter: undefined,
            useContractEndsBefore: undefined,
            inactives: false,
        });
        onInputChange();
        onSubmit(getValues());
    }

    const onSubmit = (f: Filters) => {
        onFilter(f);
    }

    const onInputChange = () => {
        onChange(getValues());
    }

    const getVal = (arr: any[] | undefined, index: number): any | undefined => {
        return arr && arr.length > index ? arr[index] : undefined;
    }

    const onSelectUseContractActive = (status: boolean) => {
        setSelectUseContractActive(selectUseContractActive == status ? undefined : status);
        setValue('useContractActive', selectUseContractActive == status ? undefined : status);
        // setValue('useContractEndsAfter', undefined);
        // setValue('useContractEndsBefore', undefined);
        //onInputChange();
    }


    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className={styles.formContent}>
                <div>
                    <FormItem>
                        <Row>
                            <Col xs={hasCountersPolicy ? 6 : 12}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('vehicle.registration_identification')}</Label>
                                    <Input
                                        maxLength={255}
                                        name="registrationNumber"
                                        onKeyUp={e => {
                                            setValue('registrationNumber', e.currentTarget.value != '' ? e.currentTarget.value : null);
                                            onInputChange();
                                        }}
                                        placeholder={t('vehicle.registration_identification')}
                                        autoComplete='off'
                                        defaultValue={watch('registrationNumber')} />
                                </FormItem>
                            </Col>
                            <Col xs={hasCountersPolicy ? 6 : 12}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('vehicle.type')}</Label>
                                    <Controller
                                        render={({ onChange: onSelectChange, value }) => {
                                            return (
                                                <Select
                                                    options={segmentsOptions}
                                                    isClearable
                                                    placeholder={t('vehicle.type')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        onSelectChange(data?.value ?? null);
                                                        setValue('segmentName', data?.label ?? null);
                                                        onInputChange();
                                                    }}
                                                    value={segmentsOptions.find(x => x.value === value) ? { label: segmentsOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                                />
                                            );
                                        }}
                                        control={control}
                                        name="segmentId"
                                        defaultValue={getValues('segmentId')} />
                                </FormItem>
                            </Col>
                        </Row>



                        <Row>
                            <Col xs={hasCountersPolicy ? 6 : 12}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('common.type_funding')}</Label>
                                    <Controller
                                        render={({ onChange: onSelectChange, value }) => {
                                            return (
                                                <Select
                                                    options={useContractTypeOptions}
                                                    isClearable
                                                    placeholder={t('common.type_funding')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        onSelectChange(data?.value);
                                                        setValue('useContractTypeId', data?.value);
                                                        setValue('useContractTypeName', data?.label);
                                                        setSelectUseContractTypeId(data?.value);
                                                        if (data?.value == guidEmpty) {
                                                            setValue('useContractActive', undefined);
                                                            setValue('useContractEndsAfter', undefined);
                                                            setValue('useContractEndsBefore', undefined);
                                                        }
                                                        onInputChange();
                                                    }}
                                                    value={useContractTypeOptions.find(x => x.value === value) ? { label: useContractTypeOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                                />
                                            );
                                        }}
                                        control={control}
                                        name="useContractTypeId"
                                        defaultValue={getValues('useContractTypeId')} />
                                </FormItem>
                            </Col>

                            {selectUseContractTypeId != guidEmpty && <Col xs={hasCountersPolicy ? 6 : 12}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('common.state')}</Label>
                                    <div className={styles.useContractStatus}>
                                        <div onClick={() => onSelectUseContractActive(true)} style={{ width: '50%' }}>
                                            <div className={styles.labelContainer}>
                                                <div className={selectUseContractActive ? styles.selected : styles.notSelected}>
                                                    <span>{t('contracts.states.in_progress')}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div onClick={() => onSelectUseContractActive(false)} style={{ width: '50%' }}>
                                            <div className={styles.labelContainer}>
                                                <div className={selectUseContractActive != undefined && !selectUseContractActive ? styles.selected : styles.notSelected}>
                                                    <span>{t('contracts.states.expired')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </FormItem>
                            </Col>}

                        </Row>


                        <Row>
                            <Col xs={12}>
                                <FormItem>
                                    <RangeInputPicker
                                        label={t('contracts.use_contract.expiration_date')}
                                        onChange={(startValue: any, endValue: any) => {
                                            form.setValue('useContractEndsAfter', startValue ? moment(startValue).toDate() : undefined);
                                            form.setValue('useContractEndsBefore', endValue ? moment(endValue).toDate() : undefined);
                                            onInputChange();
                                        }}
                                        defaultStartValue={form.getValues('useContractEndsAfter')}
                                        defaultEndValue={form.getValues('useContractEndsBefore')}
                                        isDatePicker
                                    />
                                </FormItem>
                            </Col>
                        </Row>



                        {hasCountersPolicy && <Row>
                            <Col xs={12}>
                                <RangeInputPicker
                                    key={'costAverage'}
                                    label={t('vehicles_management.list.average_monthly_cost', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) })}
                                    onChange={(startValue: any, endValue: any) => {
                                        const range = [];
                                        range.push(startValue || startValue === 0 ? Number(startValue) : undefined);
                                        range.push(endValue || endValue === 0 ? Number(endValue) : undefined);
                                        setValue('costAverage', range);
                                        onInputChange();
                                    }}
                                    defaultStartValue={getVal(getValues('costAverage'), 0)}
                                    defaultEndValue={getVal(getValues('costAverage'), 1)}
                                    groupSymbol={t('common.euro')}
                                />
                            </Col>
                            <Col xs={12}>
                                <RangeInputPicker
                                    key={'costAccumulated'}
                                    label={t('vehicles_management.list.total_monthly_cost', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) })}
                                    onChange={(startValue: any, endValue: any) => {
                                        const range = [];
                                        range.push(startValue || startValue === 0 ? Number(startValue) : undefined);
                                        range.push(endValue || endValue === 0 ? Number(endValue) : undefined);
                                        setValue('costAccumulated', range);
                                        onInputChange();
                                    }}
                                    defaultStartValue={getVal(getValues('costAccumulated'), 0)}
                                    defaultEndValue={getVal(getValues('costAccumulated'), 1)}
                                    groupSymbol={t('common.euro')}
                                />
                            </Col>
                            <Col xs={12}>
                                <FormItem>
                                    <RangeInputPicker
                                        label={t('vehicles_management.list.total_monthly_cost_date', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) })}
                                        onChange={(startValue: any, endValue: any) => {
                                            form.setValue('costsStartDate', startValue ? moment(startValue).toDate() : undefined);
                                            form.setValue('costsEndDate', endValue ? moment(endValue).toDate() : undefined);
                                            onInputChange();
                                        }}
                                        defaultStartValue={form.getValues('costsStartDate')}
                                        defaultEndValue={form.getValues('costsEndDate')}
                                        dateFormat="MM/yyyy"
                                        isDatePicker
                                        showMonthYearPicker
                                    />
                                </FormItem>
                            </Col>
                            <Col sm={12} md={3}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('vehicle.inactives')}</Label>
                                    <ToggleSwitch
                                        name="inactives"
                                        form={form}
                                    />
                                </FormItem>
                            </Col>
                        </Row>}
                    </FormItem>
                </div>

                <div className={styles.buttonsFooter}>
                    <FormItem>
                        <Button
                            text={t('common.remove')}
                            size={'normal'}
                            preset={'secondary'}
                            onClick={clearFilters}
                            type='reset' />
                        <Button
                            type='submit'
                            text={t('common.apply')}
                            size={'normal'}
                        />
                    </FormItem>
                </div>
            </div>
        </form>
    );
}

export default memo(VehiclesManagementFiltersScreen);
