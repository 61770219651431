import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './MarkerPopup.module.scss';
import { TrackingItemDto } from 'api/tracking/models/TrackingItemDto';
import DateFormat from 'common/components/dateFormat/dateFormat';
import { DATE_TIME_FORMAT_DEFAULT } from 'Config';
import DistanceFormat from 'common/components/distanceFormat/DistanceFormat';

type Props = {
    position?: TrackingItemDto;
    isRoute?: 'START' | 'END' | 'NO';
};

const MarkerPopup: React.FC<Props> = ({ position, isRoute }: Props) => {
    if (!position) return null;

    const { t } = useTranslation();

    const onMovementLabel = (item: TrackingItemDto) => {
        if (isOnMovement(item)) {
            return t('common.yes');
        } else {
            return t('common.no');
        }
    }

    const onIgnitionOnLabel = (item: TrackingItemDto) => {
        if (isIgnitionOn(item)) {
            return t('common.yes');
        } else {
            return t('common.no');
        }
    }

    const isOnMovement = (item: TrackingItemDto) => {
        return item.movement ?? false;
    }

    const isIgnitionOn = (item: TrackingItemDto) => {
        return item.ignition ?? false;
    }

    return <div className={styles.container}>
        {!isRoute && <div className={styles.item}>
            <span>
                {t('location.vehicle')}
            </span>
            <strong>
                {position.licensePlate ?? ''}
            </strong>
        </div>}
        {(!isRoute || isRoute == 'NO') && <div className={styles.item}>
            <span>
                {t('location.date')}
            </span>
            <strong>
                <DateFormat format={DATE_TIME_FORMAT_DEFAULT} value={position.date} />
            </strong>
        </div>}
        {isRoute && isRoute != 'NO' && <div className={styles.item}>
            <span>
                {t(isRoute == 'START' ? 'location.date_start' : 'location.date_end')}
            </span>
            <strong>
                <DateFormat format={DATE_TIME_FORMAT_DEFAULT} value={position.date} />
            </strong>
        </div>}
        <div className={styles.item}>
            <span>
                {t('location.latitude')}
            </span>
            <strong>
                {position.latitude ?? 0}
            </strong>
        </div>
        <div className={styles.item}>
            <span>
                {t('location.longitude')}
            </span>
            <strong>
                {position.longitude ?? 0}
            </strong>
        </div>
        {isRoute == 'NO' && <div className={styles.item}>
            <span>
                {t('location.speed')}
            </span>
            <strong>
                {position.speed ?? 0} Km/h
            </strong>
        </div>}
        {isRoute && <div className={styles.item}>
            <span>
                {t('location.km')}
            </span>
            <strong>
                <DistanceFormat value={(position.km ?? 0)} />
            </strong>
        </div>}
        {isRoute == 'NO' && <div className={styles.item}>
            <span>
                {t('location.ignition')}
            </span>
            <strong>
                {onIgnitionOnLabel(position)}
            </strong>
        </div>}
        {isRoute == 'NO' && <div className={styles.item}>
            <span>
                {t('location.movement')}
            </span>
            <strong>
                {onMovementLabel(position)}
            </strong>
        </div>}
    </div>
}

export default MarkerPopup;