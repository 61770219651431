import React from 'react';
import styles from './ScreenHeader.module.scss';

type Props = {
    title: string;
    onClickTitle?: () => void;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ScreenHeader = ({ children, title, onClickTitle, ...props }: Props) => (
    <div
        {...props}
        className={`${styles.container} ${props.className || ''}`}
    >
        <h1 className={styles.title} onClick={onClickTitle}>{title}</h1>
        <div className={styles.buttons}>
            {children}
        </div>
    </div>
);

export default ScreenHeader;
