import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-flexbox-grid';
import FormItem from 'common/components/formItem/FormItem';
import { ReactComponent as TrashIcon } from 'assets/svg/trash_no_color.svg';
import { Controller, UseFormMethods } from 'react-hook-form';
import Label from 'common/components/label/Label';
import { CompanyDto } from 'api/companies/models/CompanyDto';
import { PackTypesEnum } from 'api/packs/enums/PackTypesEnum';
import { PlanItem } from '../CompanyScreen';
import styles from './PlanFormItem.module.scss'
import InputGroup from 'common/components/inputGroup/InputGroup';
import Select from 'common/components/select/Select';
import InputError from 'common/components/inputError/InputError';
import { DATE_FORMAT_DEFAUT } from 'Config';
import { FaCalendarAlt } from 'react-icons/fa';
import DateTimePickerControllerNew from 'common/components/dateTimePicker/DateTimePickerControllerNew';

type Props = {
    form: UseFormMethods<CompanyDto>;
    isDetails: boolean
    onRemoveClick: () => void;
    index: number;
    plansList: PlanItem[];
    setRender: () => void;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const PlanFormItem: React.FC<Props> = ({
    form,
    isDetails,
    onRemoveClick,
    index,
    plansList,
    setRender,
    ...props
}: Props) => {
    const { t } = useTranslation();
    const errors = (form.errors?.packs || [])[index] || null;

    return (
        <div
            {...props}
            className={`${styles.container} ${(errors && styles.containerError) || ''} ${props.className || ''}`}
        >
            <Row>
                <Col xs={12} sm={6}  lg={4}>
                    <FormItem>
                        <Label className={styles.label}>
                            {t('plans.plan')}*
                        </Label>
                        <Controller
                            render={({ onChange, value }) => {
                                return (
                                    <Select
                                        options={plansList}
                                        isClearable
                                        placeholder={t('plans.plan')}
                                        onChange={(e: PlanItem) => {
                                            form.setValue(`packs[${index}].packId`, e ? e.id : null);
                                            form.setValue(`packs[${index}].type`, e ? e.value : null);
                                            (e && e.value == PackTypesEnum.FREE) ? form.setValue(`packs[${index}].limit`, 10) : null;
                                            setRender()
                                        }}
                                        isDisabled={isDetails}
                                        value={plansList.find(x => x.value === value) ? { id: plansList.find(x => x.value === value)?.id, label: plansList.find(x => x.value === value)?.label ?? '', value: value } : null}
                                    />
                                );
                            }}
                            rules={{ required: true }}
                            control={form.control}
                            name={`packs[${index}].type`}
                            defaultValue={form.getValues(`packs[${index}].type`)} />
                        <InputError error={errors?.type} />
                    </FormItem>
                </Col>
                <Col xs={12} sm={6}  lg={3}>
                    <FormItem>
                        <Label className={styles.label}>
                            {t('plans.configure_pack.nVehicles')}*
                        </Label>
                        <Controller
                            render={({ }) => {
                                return (
                                    <InputGroup
                                        style={{ textAlign: 'end' }} format={'integer'}
                                        placeholder={t('plans.configure_pack.nVehicles')}
                                        text={t('plans.configure_pack.vehicles')}
                                        value={form.getValues(`packs[${index}].limit`)}
                                        onValueChange={value => {
                                            form.setValue(`packs[${index}].limit`, value);
                                        }}
                                        disabled={isDetails || form.getValues(`packs[${index}].type`) == PackTypesEnum.FREE} />
                                );
                            }}
                            rules={{ required: true }}
                            control={form.control}
                            name={`packs[${index}].limit`}
                        />
                        <InputError error={errors?.limit} />

                    </FormItem>
                </Col>
                <Col xs={12} sm={11}  lg={4}>
                    <Row>
                        <Col className={styles.break}>
                            <Row>
                                <Col xs={12}>
                                    <div style={{ display: 'inline-flex' }}>
                                        <Label className={styles.label} >{t('common.date')}*</Label>
                                        {Boolean(form.getValues(`packs[${index}].date_start`)) && <Label className={styles.light} style={{ marginLeft: '0.5rem' }}>{t('common.range.from')}</Label>}

                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <FormItem>
                                      
                                        <DateTimePickerControllerNew
                                            form={form}
                                            dateFormat={DATE_FORMAT_DEFAUT}
                                            placeholderText={t('common.range.from')}
                                            customInput={<InputGroup icon={<FaCalendarAlt />} />}
                                            autoComplete='off'
                                            disabled={isDetails}
                                            name={`packs[${index}].date_start`}
                                            rules={{ required: true }} />
                                        <InputError error={errors?.date_start} />
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                        <Col className={styles.break}>
                            <Row>
                                <Col xs={12}>
                                    <div style={{ display: 'inline-flex' }}>
                                        {Boolean(form.getValues(`packs[${index}].expire`)) && <Label className={styles.light}>{t('common.range.to')}</Label>}
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <FormItem>
                                        <FormItem>
                                            <DateTimePickerControllerNew
                                                form={form}
                                                dateFormat={DATE_FORMAT_DEFAUT}
                                                placeholderText={t('common.range.to')}
                                                customInput={<InputGroup icon={<FaCalendarAlt />} />}
                                                autoComplete='off'
                                                disabled={isDetails}
                                                name={`packs[${index}].expire`}
                                                rules={{ required: true }} />
                                            <InputError error={errors?.expire} />
                                        </FormItem>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                {!isDetails && index > 0 && <Col xs={1} sm={1}  lg={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                    <div className={styles.removeButton}>
                        <TrashIcon className={styles.removeIcon} onClick={onRemoveClick} />
                    </div>
                </Col>}
            </Row>
        </div>
    );
}

export default PlanFormItem;
