import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ReservationFormScreen.module.scss';
import { useToasts } from 'react-toast-notifications';
import { VehicleReservationViewModel } from 'api/vehicleReservations/models/VehicleReservationViewModel';
import { DATE_FORMAT_DEFAUT, DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE, removeAccents } from 'Config';
import Logger from 'common/services/Logger';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import DriversService from 'api/drivers/DriversService';
import { useForm } from 'react-hook-form';
import FormItem from 'common/components/formItem/FormItem';
import { Col, Row } from 'react-flexbox-grid';
import Label from 'common/components/label/Label';
import InputGroup from 'common/components/inputGroup/InputGroup';
import { FaCalendarAlt, FaExclamationTriangle } from 'react-icons/fa';
import Button from 'common/components/button/Button';
import SelectController from 'common/components/select/SelectController';
import InputController from 'common/components/input/InputController';
import NoImage from 'assets/img/asset-default-image.png';
import Loading from 'common/services/Loading';
import VehicleReservationsService from 'api/vehicleReservations/VehicleReservationsService';
import InputError from 'common/components/inputError/InputError';
import DateWithHourController from 'common/components/dateWithHour/DateWithHourController';
import { ReservationsVehicleListItemDto } from 'api/vehicles/models/ReservationsVehicleListItemDto';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import moment from 'moment';

type Props = {
    vehicle?: ReservationsVehicleListItemDto;
    reservation?: VehicleReservationViewModel;
    mode?: string;
    onCancel: () => void;
    onSave?: () => void;

} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ReservationFormScreen: React.FC<Props> = ({ vehicle, reservation, mode, onCancel, onSave }: Props) => {

    const { t } = useTranslation();
    const { addToast } = useToasts();

    const form = useForm<VehicleReservationViewModel>({ shouldUnregister: false });
    const { setValue, errors } = form;

    const imageCacheKey = useRef(new Date().getTime());

    const [driversOptions, setDriversOptions] = useState<SelectValueLabel[]>([]);

    const [validDate, setValidDate] = useState<boolean>(true);
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);

    let isOwner = reservation ? reservation.userCreatedId === userProfile?.id : true;
    const canWrite = UsersService.hasPolicies(userProfile?.policies ?? [], ['SETTINGUP_RESERVATIONS_WRITE'], 'OR');
    const canEdit =
        UsersService.hasPolicies(userProfile?.policies ?? [], ['SETTINGUP_RESERVATIONS_EDIT_ALL'], 'OR') || isOwner;
    const [isDetails, setIsDetails] = useState<boolean>(mode == 'details' || !canWrite || !canEdit);
    const [reservationState, setReservationState] = useState<VehicleReservationViewModel | null>();

    const getData = async () => {
        try {
            const drivers = await DriversService.catalog();

            setDriversOptions(drivers);

            if (reservation) {
                setReservationState(reservation);
                validateDate(reservation.startDate, reservation.endDate);
                form.reset(reservation);
                form.setValue('startDate', moment(reservation.startDate).toDate())
                form.setValue('endDate', moment(reservation.endDate).toDate())
            }

        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get drivers`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const validateDate = async (startDate?: Date, endDate?: Date) => {
        const criteria = {
            itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
            page: 1,
            vehicleId: vehicle?.id,
            id: reservationState?.id ?? reservation?.id
        }

        if (startDate || endDate) {
            let result = await VehicleReservationsService.validateDates({ ...criteria, startDate: startDate, endDate: endDate });
            
            if (startDate && endDate) {
                result = result && startDate <= endDate;
            }

            setValidDate(result);
        }
    }

    const onSubmit = async (r: VehicleReservationViewModel) => {
        if (!validDate) {
            addToast(t('reservations.unavailable_dates'), { appearance: 'warning' });
            return;
        }

        try {
            Loading.show();

            if (r.id) {
                await VehicleReservationsService.update({ ...r, vehicleId: vehicle?.id });
            } else {
                const idNewReservation = await VehicleReservationsService.create({ ...r, vehicleId: vehicle?.id });
                const reservationDB = await VehicleReservationsService.getById(idNewReservation);
                isOwner = reservationDB ? reservationDB.userCreatedId === userProfile?.id : true;
                setReservationState(reservationDB);
                if (reservationDB) {
                    form.reset(reservationDB);
                    form.setValue('startDate', moment(reservationDB.startDate).toDate())
                    form.setValue('endDate', moment(reservationDB.endDate).toDate())
                }
            }

            Loading.hide();

            addToast(t('common.messages.record_save_success'), { appearance: 'success' });

            setIsDetails(true);

            if (onSave) {
                onSave();
            }

        } catch (error) {
            Loading.hide();

            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't create or update vehicle registration`, error);
        }
    }

    useEffect(() => {
        getData();
    }, []);


    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className={styles.formContent}>
                <div>
                    <FormItem>
                        <Row>
                            <div className={styles.details}>
                                <div className={styles.image}>
                                    <div className={styles.contentImage} style={{ width: '5rem', height: '3rem' }}>
                                        <div className={styles.img}
                                            style={{ width: '5rem', height: '3rem', backgroundImage: vehicle?.photoUrl ? 'url(' + vehicle?.photoUrl ?? + '?_=' + imageCacheKey.current + ')' : 'url(' + NoImage + ')' }} />
                                    </div>
                                </div>
                                <div className={styles.description}>
                                    <div className={styles.bold}>{vehicle?.vehicleRegistrationNumber}</div>
                                    <div className={styles.smallLabel}>
                                        <span>{vehicle?.vehicleBrandName}</span>
                                        {vehicle?.vehicleModelName &&
                                            <>  <span>{(' | ')}</span>
                                                <span>{vehicle.vehicleModelName}</span>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Row>

                        <div className={styles.divider} />

                        <Row>
                            <Col xs={12}>
                                <FormItem>
                                    <Label className={`${validDate ? styles.label : styles.warningLabel}`} required={!isDetails}>{t('reservations.list.date_start')}</Label>
                                    <DateWithHourController
                                        form={form}
                                        datePlaceholder={t('common.date')}
                                        dateFormat={DATE_FORMAT_DEFAUT}
                                        dateCustomInput={<InputGroup icon={<FaCalendarAlt />} removeIconOrTextBorderRadius={true} />}
                                        name="startDate"
                                        onChangeDate={() => validateDate(form.getValues('startDate'), form.getValues('endDate'))}
                                        rules={{ required: true }}
                                        isDisabled={isDetails}
                                        timePlaceholder={t('common.hour')}
                                    />
                                    <InputError error={errors.startDate} />
                                    {!validDate && <div className={styles.warningMessage}><FaExclamationTriangle /> {t('reservations.unavailable_dates')}</div>}
                                </FormItem>
                            </Col>
                            <Col xs={12}>
                                <FormItem>
                                    <Label className={`${!validDate && form.watch('endDate') ? styles.warningLabel : styles.label}`} required={!isDetails}>{t('reservations.list.date_end')}</Label>
                                    <DateWithHourController
                                        form={form}
                                        datePlaceholder={t('common.date')}
                                        dateFormat={DATE_FORMAT_DEFAUT}
                                        dateCustomInput={<InputGroup icon={<FaCalendarAlt />} removeIconOrTextBorderRadius={true} />}
                                        name="endDate"
                                        onChangeDate={() => validateDate(form.getValues('startDate'), form.getValues('endDate'))}
                                        rules={{ required: true }}
                                        isDisabled={isDetails}
                                        timePlaceholder={t('common.hour')}
                                        minDate={form.watch('startDate') ?? reservationState?.startDate}
                                    />
                                    <InputError error={errors.endDate} />
                                    {!validDate && form.watch('endDate') && <div className={styles.warningMessage}><FaExclamationTriangle /> {t('reservations.unavailable_dates')}</div>}
                                </FormItem>
                            </Col>
                            <Col xs={12}>
                                <FormItem>
                                    <Label className={styles.label} required={!isDetails}>{t('reservations.list.driver')}</Label>
                                    <SelectController
                                        form={form}
                                        name="driverId"
                                        placeholder={t('reservations.list.driver')}
                                        options={driversOptions}
                                        rules={{ required: true }}
                                        isDisabled={isDetails}
                                        isClearable={true}
                                        filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                        onChangeSelect={(data: SelectValueLabel) => {
                                            setValue('driverId', data ? data.value : null);
                                        }}
                                    />
                                    <InputError error={errors.driverId} />
                                </FormItem>
                            </Col>
                            <Col xs={12}>
                                <FormItem>
                                    <Label className={styles.label}>{t('reservations.list.reason')}</Label>
                                    <InputController
                                        name="reason"
                                        form={form as any}
                                        autoComplete='off'
                                        placeholder={t('reservations.list.reason')}
                                        disabled={isDetails}
                                        rules={{ maxLength: 250 }}
                                    />
                                    <InputError error={errors.reason} maxLength={250} />
                                </FormItem>
                            </Col>

                        </Row>
                    </FormItem>
                </div>

                <div className={styles.buttonsFooter}>
                    <FormItem>
                        <Button
                            text={t('common.cancel')}
                            size={'normal'}
                            preset={'secondary'}
                            type='reset'
                            onClick={() => onCancel()}
                        />

                        {!isDetails &&
                            < Button
                                type='submit'
                                text={t('reservations.reserve')}
                                size={'normal'}
                            />
                        }
                        {isDetails && (canEdit) &&
                            < Button
                                text={t('common.edit')}
                                size={'normal'}
                                onClick={() => setIsDetails(false)}
                            />
                        }
                    </FormItem>
                </div>
            </div>
        </form>
    );
}

export default ReservationFormScreen;