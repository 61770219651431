import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './VehicleFuelForm.module.scss';
import { useFieldArray, UseFormMethods } from 'react-hook-form';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import VehicleFuelFormItem from '../vehicleFuelFormItem/VehicleFuelFormItem';
import { VehicleFuelViewModel, VehicleViewModel } from 'api/vehicles/models/VehicleViewModel';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { FaInfoCircle } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';

type Props = {
    form: UseFormMethods<VehicleViewModel>;
    isDetails: boolean;
    mode?: string;
    fuelsOptions: SelectValueLabel[];
};

const VehicleFuelForm: React.FC<Props> = ({ form, isDetails, fuelsOptions, mode }: Props) => {
    const { t } = useTranslation();

    const vehicleFuels = useFieldArray<VehicleFuelViewModel>({ control: form.control, name: 'vehicleFuels', keyName: 'formId' as any });
    const [showRemoveVehicleFuel, setShowRemoveVehicleFuel] = useState<boolean>(false);
    const [vehicleFuelToRemoveIndex, setVehicleFuelToRemoveIndex] = useState<number | null>(null);
    const [fuelReRender, setFuelReRender] = useState<number>(0);

    const addVehicleFuel = () => {
        vehicleFuels.append({
            consommationMoyenneReference: 0
        });
    }

    const removeItem = () => {
        if (vehicleFuelToRemoveIndex === null) return;
        vehicleFuels.remove(vehicleFuelToRemoveIndex);
        setShowRemoveVehicleFuel(false);
    }

    const setRemoveItem = (index: number) => {
        setShowRemoveVehicleFuel(true);
        setVehicleFuelToRemoveIndex(index);
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.headerText}>{t('vehicle.vehicle_fuels')}
                    <span style={{ marginLeft: '0.5rem' }}>
                        <FaInfoCircle data-tip={t('vehicle.multiple_fuels_message')} />
                        <ReactTooltip />
                    </span>
                </div>
                {!isDetails && <div>
                    <span className={styles.headerButton} onClick={addVehicleFuel}>
                        <span className={styles.headerButtonText}></span>
                        <img src={AddIcon} className={styles.headerButtonIcon} />
                    </span>
                </div>}
            </div>

            {vehicleFuels.fields.length === 0 && <div className={styles.noInfoText}>
                {t('vehicle.without_fuels')}
            </div>}

            {vehicleFuels.fields.length > 0 && <div className={styles.content}>
                {vehicleFuels.fields.map((ti, index) => {
                    return (
                        <VehicleFuelFormItem
                            key={ti.formId}
                            form={form}
                            isDetails={isDetails}
                            onRemoveClick={() => {
                                setRemoveItem(index);
                            }}
                            index={index}
                            mode={mode}
                            fuelsOptions={fuelsOptions}
                            setFuelReRender={()=>{ setFuelReRender(fuelReRender+1);}}
                            vehicleFuelsCounter={vehicleFuels.fields.length}
                        />
                    );
                })}
            </div>}

            <QuestionYesNo
                message={t('common.messages.remove_record')}
                isVisible={showRemoveVehicleFuel}
                onYes={() => removeItem()}
                onNo={() => { setShowRemoveVehicleFuel(false); setVehicleFuelToRemoveIndex(null) }}
            />
        </div>
    );
}

export default VehicleFuelForm;
