import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

type Props = {
    lat?: number,
    lng?: number,
    zoom?: number
}

const RecenterAutomatically = ({ lat, lng, zoom }: Props) => {
    const map = useMap();

    useEffect(() => {
        if(lat && lng){
            map.setView([lat, lng], zoom);
        }
    }, [lat, lng]);

    return null;
}

export default RecenterAutomatically;