import { PackDto } from './../packs/models/PackDto';
import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { CompanySearchCriteria } from './models/CompanySearchCriteria';
import { CompanyDto } from './models/CompanyDto';
import CustomFile from 'common/models/CustomFile';
import { SelectValueLabelDto } from 'api/common/models/SelectValueLabelDto';
import { CompanyFillInfoDto } from './models/CompanyFillInfoDto';
import { CompanyPacksDto } from './models/CompanyPacksDto';


class CompaniesService {

   
    public getList(criteria: CompanySearchCriteria) {
        return Http.get<Paged<CompanyDto>>('companies', criteria);
    }

    public catalog(criteria: CompanySearchCriteria) {
        return Http.get<SelectValueLabelDto[]>('companies/catalog', criteria);
    }

    public getById(id: string) {
        return Http.get<CompanyDto>('companies/' + id);
    }
    
    public getCurrent() {
        return Http.get<CompanyDto>('companies/current');
    }

    public create(model: CompanyDto, attachment: CustomFile[]) {
        return Http.postWithFile<string>('companies', model, attachment);
    }


    public update(model: CompanyDto, attachment: CustomFile[]) {
        return Http.putWithFile<string>('companies', model, attachment);
    }

    public updateCurrent(model: CompanyDto, attachment: CustomFile[]) {
        return Http.putWithFile<string>('companies/current', model, attachment);
    }

    

    public validateVatNumber(model: CompanyDto) {
        return Http.post('companies/validate-vat-number', model);
    }

    public fillInfo(model: CompanyFillInfoDto, attachment: CustomFile[]) {
        return Http.putWithFile<string>('companies/fill-info', model, attachment);
    }

    public remove(model: CompanyDto) {
        return Http.put('companies/deactivate', model);
    }  

    public getCompanyPacksInfo(companyId: string){
        return Http.get<CompanyPacksDto>('companies/packs/' + companyId)
    }

    public getPacksInfo(){
        return Http.get<PackDto[]>('companies/all-packs')
    }
}

export default new CompaniesService();