import React, { useEffect, useState } from 'react';
import styles from './Popover.module.scss'
import { Popover as TinyPopover, PopoverAlign } from 'react-tiny-popover'

type Props = {
    onClickOutside?: () => void;
    content?: (setIsPopoverOpen: (v: boolean) => void) => JSX.Element;
    contentContainerClassName?: string;
    children?: (isOpen: boolean, setIsPopoverOpen: (v: boolean) => void) => JSX.Element;
    positions?: ('left' | 'top' | 'right' | 'bottom')[];
    align?: Exclude<PopoverAlign, 'custom'>;
    containerParent?: HTMLElement;
    isOpen?: boolean;
    onChange?: (v: boolean) => void;
    customTop?: number;
    containerStyle?: Partial<CSSStyleDeclaration>;
    reposition?: boolean;
    overflowHidden?: boolean;
};

const Popover: React.FC<Props> = ({
    onClickOutside, content, contentContainerClassName, children, positions = ['left', 'top'], align, containerParent, customTop, isOpen, onChange,
    containerStyle, reposition, overflowHidden
}: Props) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(isOpen || false);

    const setIsOpen = (v: boolean) => {
        setIsPopoverOpen(v);
        if (onChange) {
            onChange(v);
        }
    };

    const onClose = () => {
        setIsOpen(false)
        onClickOutside && onClickOutside();
    }

    useEffect(() => {
        if (isOpen !== undefined) {
            setIsOpen(isOpen);
        }
    }, [isOpen]);
    
    return (
        <TinyPopover
            isOpen={isPopoverOpen}
            positions={positions}
            reposition={reposition}
            align={align}
            content={<div className={`${styles.content} ${contentContainerClassName ? contentContainerClassName : ''}`}>
                {content && content(setIsOpen)}
            </div>}
            containerStyle={{
                zIndex: '6',
                overflowY: overflowHidden ? 'hidden !important' : 'auto',
                ...(containerStyle ? containerStyle : {})
            }}
            containerParent={containerParent}
        >
            <div>
                {isPopoverOpen && <div className={styles.overlayContainer} onClick={onClose}></div>}
                {children && children(isPopoverOpen, setIsOpen)}
            </div>
        </TinyPopover>
    );
}

export default Popover;
