import React from 'react';
import { RouteProps, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Reducers } from '../../store/types';
import { ApplicationPaths, QueryParameterNames } from '../../Config';
import WelcomeModal from 'common/components/welcomeModal/WelcomeModal';

type Props = {

} & RouteProps;

type AuthState = {
    authenticated: boolean;
    ready: boolean
}// & AuthenticationState;


const AuthorizeRoute: React.FC<Props> = ({ children, ...props }: Props) => {
    const isAuthenticated = useSelector<Reducers, boolean>(state => state.authentication.isAuthenticated);
    const isCompanyInfoFilled = useSelector<Reducers, boolean>(state => state.authentication.isCompanyInfoFilled);
    const link = document.createElement('a');
    link.href = `${props.path}`;
    const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`.replace('*','');
    const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`

    return (
        <>
          {isCompanyInfoFilled && <WelcomeModal />}
          <Route {...props} render={() => {
            return isAuthenticated ? children : <Redirect to={redirectUrl} />
          }} />
        </>
    );

}

export default AuthorizeRoute;
