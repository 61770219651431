import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import DriversService from 'api/drivers/DriversService';
import UsersService from 'api/users/UsersService';
import { DriversSearchCriteria } from 'api/drivers/models/DriversSearchCriteria';
import { DriversTotalsDto } from 'api/drivers/models/DriversTotalsDto';
import Logger from 'common/services/Logger';
import Loading from 'common/services/Loading';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import Tabs, { TabItem } from 'common/components/tabs/Tabs';
import DriverChargeTab from './components/driverChargeTab/DriverChargeTab';
import DriverAccidentTab from './components/driverAccidentTab/DriverAccidentTab';
import CounterBoxDriver from './components/counterBoxDriver/CounterBoxDriver';
import { Types } from 'screens/vehicle/VehicleScreen';
import DriverUserTab from './components/driverUserTab/DriverUserTab';
import Popover from 'common/components/popover/Popover';
import FiltersIcon from 'assets/svg/desktop_filtres.svg';
import DriversFilters, { Filters as FiltersDriversModel } from '../drivers/components/DriversFilters';
import styles from './DriverScreen.module.scss';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import ChargeFiltersScreen, { Filters as FiltersChargesModel } from '../charges/components/ChargeFiltersScreen';
import { ChargesSearchCriteria } from 'api/charges/models/ChargesSearchCriteria';
import { AccidentsSearchCriteria } from 'api/accidents/models/AccidentsSearchCriteria';
import AccidentFiltersScreen, { Filters as FiltersAccidentsModel } from 'screens/vehicle/components/carAccidentTab/components/AccidentFilters/AccidentFiltersScreen';

export type CloseType = 'edit' | 'details';

const DriverScreen = () => {
    const { t } = useTranslation();
    const { driverId, type, tabId } = useParams<{ driverId: string, type: Types, tabId: string, itemType: string, itemId: string }>();

    const { addToast } = useToasts();
    const history = useHistory();

    const isDriver = !!driverId;

    const [criteriaUsers, setCriteriaUsers] = useState<DriversSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderColumn: 'u.real_name',
        orderBy: 'asc',
        driverId: isDriver ? driverId : undefined,
    });
    const [totals, setTotals] = useState<DriversTotalsDto>({ averageKilometers: 0, totalRefunds: 0, totalFines: 0, totalAccidents: 0 });
    const [filtersUsers, setFiltersUsers] = useState<FiltersDriversModel>({});
    const [filtersUsersTotal, setFiltersUsersTotal] = useState(0);

    const [criteriaCharges, setCriteriaCharges] = useState<ChargesSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        vehicleId: undefined,
        responsibleId: isDriver ? driverId : undefined,
        orderColumn: 'date',
        orderBy: 'desc',
        startDate: undefined,
        endDate: undefined
    });
    const [filtersCharges, setFiltersCharges] = useState<FiltersChargesModel>({});
    const [filtersChargesTotal, setFiltersChargesTotal] = useState(0);

    const [criteriaAccidents, setCriteriaAccidents] = useState<AccidentsSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        vehicleId: undefined,
        driverId: isDriver ? driverId : undefined,
        orderColumn: 'date',
        orderBy: 'desc'
    });
    const [filtersAccidents, setFiltersAccidents] = useState<FiltersAccidentsModel>({});
    const [filtersAccidentsTotal, setFiltersAccidentsTotal] = useState(0);

    const [forceRenderTab, setForceRenderTab] = useState(new Date());

    const navigateToTab = (tabId: string) => {
        history.push(`/drivers/details/${driverId}/${tabId}/list`);
    }

    const getData = async () => {
        try {
            Loading.show();
            const _totals = await DriversService.getTotals(criteriaUsers);
            setTotals(_totals);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.DRIVERS, `Couldn't get driver information `, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const reloadCounters = () => {
        getData();
    }

    const canAddDrivers = async () => {
        try {
            Loading.show();
            await UsersService.candAddUsers();
            Loading.hide();
        } catch (error) {
            if (error?.response?.status === 402) {
                addToast(t('common.errors.pack_limit_exceeded'), { appearance: 'warning' });
                history.push(`/account/details/subscription`);
            }
        }        
    }


    const tabs: TabItem[] = useMemo(() => {
        const items: TabItem[] = [
            {
                id: 'user',
                title: t('drivers.user'),
                content: <DriverUserTab />
            },
            {
                id: 'costs',
                title: t('drivers.costs'),
                content: <DriverChargeTab criteriaProp={criteriaCharges} reloadCounters={reloadCounters}/>
            },
            {
                id: 'accidents',
                title: t('drivers.accidents'),
                content: <DriverAccidentTab criteriaProp={criteriaAccidents} />
            }
            /*,{
                id: 'history',
                title: t('drivers.history'),
                content: <DriverHistoryTab />
            }*/
        ];
        return items;
    }, [criteriaCharges, criteriaAccidents]);   

    useEffect(() => {
        
        if (!driverId) {
            canAddDrivers();
         }
        getData();
    }, [driverId, criteriaUsers]);

    const onChangeFiltersUsers = (f: FiltersDriversModel, forcedChange?: boolean) => {
        updateTotalFiltersUsers(f);
        setFiltersUsers(f);

        criteriaUsers.driverId = isDriver ? driverId : undefined;
        criteriaUsers.minRefunds = f.refunds && f.refunds[0];
        criteriaUsers.maxRefunds = f.refunds && f.refunds[1];
        criteriaUsers.minFines = f.fines && f.fines[0];
        criteriaUsers.maxFines = f.fines && f.fines[1];
        criteriaUsers.minAccidents = f.accidents && f.accidents[0];
        criteriaUsers.maxAccidents = f.accidents && f.accidents[1];
        criteriaUsers.vehicleId = f.vehicleId;
        criteriaUsers.startDate = f.startDate;
        criteriaUsers.endDate = f.endDate;
        setCriteriaUsers({ ...criteriaUsers, page: 1 });

        if(!forcedChange)
        {
            onChangeFiltersAccidents({ ...filtersAccidents, startDate: criteriaUsers.startDate, endDate: criteriaUsers.endDate, vehicleId: criteriaUsers.vehicleId }, true);
            onChangeFiltersCharges({ ...filtersCharges, startDate: criteriaUsers.startDate, endDate: criteriaUsers.endDate, vehicleId: criteriaUsers.vehicleId }, true);
            setForceRenderTab(new Date());
        }
    }

    const updateTotalFiltersUsers = (f: FiltersDriversModel) => {
        let total = 0;

        if (f.startDate || f.endDate) {
            total++;
        }
        if (f.driverId) {
            total++;
        }
        if (f.vehicleId) {
            total++;
        }
        if (f.fines) {// && (filters.fines[0] >= 0 || filters.fines[1] < 1000)) {
            total++;
        }
        if (f.accidents) {// && (filters.accidents[0] > 0 || filters.accidents[1] < 1000)) {
            total++;
        }
        if (f.refunds) {//&& (filters.refunds[0] >= 0 || filters.refunds[1] < 1000)) {
            total++;
        }

        setFiltersUsersTotal(total);
    }


    const onChangeFiltersCharges = (f: FiltersChargesModel, forcedChange?: boolean) => { 
        updateTotalFiltersCharges(f);
        setFiltersCharges(f);    
        criteriaCharges.responsibleId = isDriver ? driverId :  f.responsibleId;    
        criteriaCharges.startDate = f.startDate;
        criteriaCharges.endDate = f.endDate;
        criteriaCharges.vehicleId = f.vehicleId;
        criteriaCharges.typeId = f.typeId;
        criteriaCharges.supplierId = f.supplierId;
        criteriaCharges.minCost = f.cost && f.cost[0];
        criteriaCharges.maxCost = f.cost && f.cost[1];
        criteriaCharges.refundSituation = f.refundSituation;
        setCriteriaCharges({ ...criteriaCharges, page: 1 });
        
        if(!forcedChange)
        {
            onChangeFiltersUsers({ ...filtersUsers, startDate: criteriaCharges.startDate, endDate: criteriaCharges.endDate, vehicleId: criteriaCharges.vehicleId }, true);
            onChangeFiltersAccidents({ ...filtersAccidents, startDate: criteriaCharges.startDate, endDate: criteriaCharges.endDate, vehicleId: criteriaCharges.vehicleId }, true);
            setForceRenderTab(new Date());
        }        
    }

    const updateTotalFiltersCharges = (f: FiltersChargesModel) => {
        let total = 0;

        if (f.startDate || f.endDate) {
            total++;
        }
        if (f.vehicleId && f.vehicleId.length > 0) {
            total++;
        }
        if (f.typeId && f.typeId.length > 0) {
            total++;
        }
        if (f.supplierId && f.supplierId.length > 0) {
            total++;
        }
        if (f.responsibleId && f.responsibleId.length > 0 && !isDriver) {
            total++;
        }
        if (f.refundSituation != null) {
            total++;
        }
        if (f.cost && (f.cost[0] > 0 || f.cost[1] > 0)) {
            total++;
        }

        setFiltersChargesTotal(total);
    }

    const onChangeFiltersAccidents = (f: FiltersAccidentsModel, forcedChange?: boolean) => {
        updateTotalFiltersAccidents(f);
        setFiltersAccidents(f);
        criteriaAccidents.driverId = isDriver ? driverId : undefined;
        criteriaAccidents.startDate = f.startDate;
        criteriaAccidents.endDate = f.endDate;
        criteriaAccidents.guilty = f.guilty;
        criteriaAccidents.allIn = f.allIn;
        criteriaAccidents.vehicleId = f.vehicleId;
        setCriteriaAccidents({ ...criteriaAccidents, page: 1 });
        if(!forcedChange)
        {
            onChangeFiltersUsers({ ...filtersUsers, startDate: criteriaAccidents.startDate, endDate: criteriaAccidents.endDate, vehicleId: criteriaAccidents.vehicleId }, true);
            onChangeFiltersCharges({ ...filtersCharges, startDate: criteriaAccidents.startDate, endDate: criteriaAccidents.endDate, vehicleId: criteriaAccidents.vehicleId }, true);
            setForceRenderTab(new Date());
        }        
    }

    const updateTotalFiltersAccidents = (f: FiltersAccidentsModel) => {
        let total = 0;

        if (f.startDate || f.endDate) {
            total++;
        }
        if (f.allIn) {
            total++;
        }
        if (f.driverId && !isDriver) {
            total++;
        }
        if (f.guilty === false || f.guilty === true) {
            total++;
        }
        if (f.vehicleId && f.vehicleId.length > 0) {
            total++;
        }

        setFiltersAccidentsTotal(total);
    }

    return (
        <ScreenTitle title={t('drivers.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('drivers.title_single')}>
                    {isDriver && <Popover
                        contentContainerClassName={styles.filtersPopoverContainer}
                        containerStyle={{ boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}
                        positions={['bottom', 'left']}
                        align={'end'}
                        onClickOutside={() => tabId == 'user' ? updateTotalFiltersUsers(filtersUsers) 
                                                : tabId == 'costs' ? updateTotalFiltersCharges(filtersCharges)
                                                    : tabId == 'accidents' ? updateTotalFiltersAccidents(filtersAccidents) : undefined}
                        content={setIsPopoverOpen => 
                        tabId == 'user' ? 
                            <DriversFilters
                                isDriver={isDriver}
                                filters={filtersUsers}
                                onFilter={f => { setIsPopoverOpen(false); onChangeFiltersUsers(f) }}
                                onChange={f => updateTotalFiltersUsers(f)}
                            />
                        : tabId == 'costs' ?
                            <ChargeFiltersScreen
                                isVehicle={false}
                                isDriver={isDriver}
                                filters={filtersCharges}
                                onFilter={f => { setIsPopoverOpen(false); onChangeFiltersCharges(f) }}
                                onChange={f => updateTotalFiltersCharges(f)}
                            />
                        : tabId == 'accidents' ? 
                            <AccidentFiltersScreen
                                isVehicle={false}
                                isDriver={isDriver}
                                filters={filtersAccidents}
                                onFilter={f => { setIsPopoverOpen(false); onChangeFiltersAccidents(f) }}
                                onChange={f => updateTotalFiltersAccidents(f)}
                            />
                        : <></>
                    }>
                        {(isPopoverOpen, setIsPopoverOpen) => (
                            <ScreenHeaderButton icon={FiltersIcon} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                                {filtersUsersTotal > 0 &&
                                    <div className={styles.counterList}> <div className={styles.counterNumber}>{
                                        tabId == 'user' ? filtersUsersTotal 
                                        : tabId == 'costs' ? filtersChargesTotal
                                        : tabId == 'accidents' ? filtersAccidentsTotal : undefined}</div> </div>
                                }
                            </ScreenHeaderButton>
                        )}
                    </Popover>}
                </ScreenHeader>

                {totals && <CounterBoxDriver totals={totals}/>}

                {<Tabs
                    key={forceRenderTab + '_tabs'}
                    items={tabs}
                    activeTabId={tabId ?? 'user'}
                    onChange={(tabId: string) => (!type || !!type && type === 'details') && navigateToTab(tabId)}
                />}
            </ScreenContainer>
        </ScreenTitle>
    );
}

export default DriverScreen;