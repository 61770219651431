import React, { ReactNode } from 'react';
import Avatar, { AvatarProps } from './Avatar';

type Props = {
    children: ReactNode | ReactNode[];
    hideAvatar?: boolean;
} & AvatarProps;

const AvatarWithText = ({ children, hideAvatar = false, ...props }: Props) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {!hideAvatar && <Avatar {...props} fw />}
            {children}
        </div>
    );
}

export default AvatarWithText;