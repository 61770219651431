export enum AnnotationTypeEnum {
    EXTERIOR = 'EXTERIOR',
    INTERIOR = 'INTERIOR',
    PANEL = 'PANEL',
}

export enum AnnotationStatusEnum {
    DONE = 'DONE',
    WAITING = 'WAITING'
}

export enum AnnotationStatusColorEnum {
    DONE = '#16CCC3',
    WAITING = '#DC4C4C',
}
