import styles from './ScreenHeaderButton.module.scss';

type Props = {
    id?:string;
    icon: string;
    className?: string;
    classNameIcon?: string;
    onClick?: () => void | undefined;
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const ScreenHeaderButton = ({ children, icon, className,classNameIcon,id, onClick }: Props) => (
    <div id={id} className={`${styles.button} ${className || ''}`} onClick={!!onClick ? onClick : undefined}>
        <img src={icon} className={`${classNameIcon || ''}`}/>
        {children}
    </div>
);

export default ScreenHeaderButton;
