import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CarMonthlyChargesFiltersScreen.module.scss';
import Button from 'common/components/button/Button';
import FormItem from 'common/components/formItem/FormItem';
import { Col, Row } from 'react-flexbox-grid';
import { useForm } from 'react-hook-form';
import RangeInputPicker from 'common/components/rangeInputPicker/RangeInputPicker';
import moment from 'moment';

export interface Filters {
    startDate?: Date | null;
    endDate?: Date | null;
}

type Props = {
    filters: Filters;
    onChange: (filters: Filters) => void;
    onFilter: (filters: Filters) => void;
}

const CarMonthlyChargesFiltersScreen: React.FC<Props> = ({ filters, onChange, onFilter }: Props) => {
    const { t } = useTranslation();

    const form = useForm<Filters>({ shouldUnregister: false, shouldFocusError: true, defaultValues: filters });
    const { setValue, getValues } = form;

    const clearFilters = () => {
        form.reset({
            startDate: undefined,
            endDate: undefined,
        });
        onInputChange();
        onFilter(getValues());
    }

    const onInputChange = () => {
        onChange(getValues());
    }

    return (
        <form onSubmit={form.handleSubmit(onFilter)}>
            <div className={styles.formContent}>
                <div style={{ marginBottom: '4rem' }}>
                    <FormItem>
                        <Row>
                            <Col xs={12}>
                                <RangeInputPicker
                                    label={t('charges.filter_date')}
                                    onChange={(startValue: any, endValue: any) => {
                                        setValue('startDate', startValue ? moment(startValue).toDate() : undefined);
                                        setValue('endDate', endValue ? moment(endValue).toDate() : undefined);
                                        onInputChange();
                                    }}
                                    dateFormat="MM/yyyy"
                                    defaultStartValue={getValues('startDate')}
                                    defaultEndValue={getValues('endDate')}
                                    isDatePicker
                                    showMonthYearPicker
                                />
                            </Col>
                        </Row>
                    </FormItem>
                </div>
                <div className={styles.buttonsFooter}>
                    <FormItem>
                        <Button
                            text={t('common.remove')}
                            size={'normal'}
                            preset={'secondary'}
                            onClick={clearFilters}
                            type='reset' />
                        <Button
                            type='submit'
                            text={t('common.apply')}
                            size={'normal'}
                        />
                    </FormItem>
                </div>
            </div>
        </form>
    );
}

export default memo(CarMonthlyChargesFiltersScreen);