import React from 'react';
import { useParams } from 'react-router-dom';
import NotificationsDetails from './components/notificationsDetails/NotificationsDetails';
import NotificationsList from    './components/notificationsList/NotificationsList';

const NotificationsTab = () => {    
    const { type } = useParams<{ type: string, itemId: string }>();

    return (type === 'list' ? <NotificationsList /> : <NotificationsDetails />);
}

export default NotificationsTab;
