import React from 'react';
import ReactTooltip from 'react-tooltip';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

type Props = {
    tooltip?: string;
} & NumberFormatProps;

const MoneyFormat: React.FC<Props> = ({ value, suffix, tooltip, ...props }: Props) => {

    return (
        <>
            <NumberFormat
                value={value}
                displayType={props.displayType || 'text'}
                thousandSeparator={' '}
                decimalSeparator={','}
                decimalScale={props.decimalScale ?? 2}
                fixedDecimalScale={false}
                suffix={' ' + (suffix ?? '€')}
                prefix={props.prefix}       
                {...props}
                data-tip={tooltip}
            />
            <ReactTooltip />
        </>
    );
}

export default MoneyFormat;
