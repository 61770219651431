import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GaugeIcon from 'assets/svg/gauge.svg';
import styles from './MaintenanceListTable.module.scss';
import { Paged } from 'api/common/types/Page';
import DateFormat from 'common/components/dateFormat/dateFormat';
import AvatarWithText from 'common/components/avatar/AvatarWithText';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import NoImage from 'assets/img/asset-default-image.png';
import { VehicleMaintenanceDto } from 'api/vehicleMaintenances/models/VehicleMaintenanceDto';
import { VehicleMaintenanceStates } from 'api/vehicleMaintenances/enums/VehicleMaintenanceStates';
import RenderSVG from 'common/components/renderSVG/RenderSVG';
import Badge from 'common/components/badge/Badge';
import IconWithText from 'common/components/iconWithText/IconWithText';
import ReactTooltip from 'react-tooltip';
import { DATE_FORMAT_DEFAUT, DATE_FORMAT_MONTH_TEXT_YEAR, LOGGER_LOG_TYPE, SEGMENT_FEATURES } from 'Config';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { UserProfile } from 'api/account/models/UserProfile';
import { Reducers } from 'store/types';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import Dropdown from 'common/components/dropdown/Dropdown';
import DropdownItem from 'common/components/dropdown/DropdownItem';
import { FaEllipsisH } from 'react-icons/fa';
import VehicleMaintenancesService from 'api/vehicleMaintenances/VehicleMaintenancesService';
import Logger from 'common/services/Logger';
import { useToasts } from 'react-toast-notifications';
import MoneyFormat from 'common/components/moneyFormat/MoneyFormat';
import DeletedVehicleCircle from 'common/components/deletedVehicleCircle/DeletedVehicleCircle';
import Loading from 'common/services/Loading';

export type Props = {
    vehicleId?: string;
    vehicleSegmentFeatures:string[]
    editable : boolean;
    data: Paged<VehicleMaintenanceDto>;
    onTableFilter: (field: string, isFilterAsc: boolean) => void;
    onPageChange: (page?: number) => void;
    onClickItem: (item: VehicleMaintenanceDto, isDetails: boolean,event:any) => void;
    onDeleteFromList?: () => void;
};

const MaintenanceListTable = ({ vehicleId,vehicleSegmentFeatures, editable, data, onTableFilter, onPageChange, onClickItem, onDeleteFromList }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const imageCacheKey = useRef(new Date().getTime());
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasVehicleMaintenanceWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_VEHICLE_MAINTENANCES_WRITE']);
    const [itemToRemove, setItemToRemove] = useState<VehicleMaintenanceDto | null>(null);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    
    const renderKilometersCell = (row: VehicleMaintenanceDto) => {
        return (
            <div className={styles.cellContainer}>
                {showField(SEGMENT_FEATURES.KM) && <div className={styles.firstRow}>
                    <IconWithText
                        icon={GaugeIcon}
                        value={row.state == VehicleMaintenanceStates.PERFORM ? row.kilometers : row.adviseMaintenanceKilometers}
                        suffix={t('common.kilometers_minified')}
                        isNumeric={true}
                    />
                </div>}
                {!!row.adviseMaintenanceDate && row.state === VehicleMaintenanceStates.PREDICTED && <div className={styles.secondRow}>
                    <DateFormat value={row.adviseMaintenanceDate} format={DATE_FORMAT_MONTH_TEXT_YEAR} />{/*row.expectedHour && <> | {row.expectedHour}</>*/}
                </div>}
                {!!row.maintenanceDate && row.state === VehicleMaintenanceStates.PERFORM && <div className={styles.secondRow}>
                    <DateFormat value={row.maintenanceDate} format={DATE_FORMAT_DEFAUT} />{row.maintenanceHour && <> | {row.maintenanceHour}</>}
                </div>}
            </div>
        )
    };

    const renderTableActionCell = (
        row: VehicleMaintenanceDto,
    ) => {
	const urlDetails = vehicleId ? `/vehicles/details/${vehicleId}/maintenance/details/${row.id}` : `maintenances/details/${row.id}`;
        const urlEdit = vehicleId ? `/vehicles/details/${vehicleId}/maintenance/edit/${row.id}` : `maintenances/edit/${row.id}`;

        return (
            <Dropdown
                options={
                    <>
                        <DropdownItem url={urlDetails}>
                            {t('common.details')}
                        </DropdownItem>
                        { hasVehicleMaintenanceWritePolicy && editable && <DropdownItem url={urlEdit}>
                            {t('common.edit')}
                        </DropdownItem>}
                        { hasVehicleMaintenanceWritePolicy && editable && <DropdownItem onClick={() => showRemoveItemDialog(row)}>
                            {t('common.remove')}
                        </DropdownItem>}
                    </>
                }
            >
                <div>
                    <FaEllipsisH />
                </div>
            </Dropdown>
        );
    };

    const showRemoveItemDialog = (item: VehicleMaintenanceDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    };
	
	const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };
	
	const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }

        try {
            setShowRemoveModal(false);
            Loading.show();
            await VehicleMaintenancesService.remove(itemToRemove);
            onPageChange();
            onCancelRemove();
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });
            Loading.hide();
            if(onDeleteFromList){
                onDeleteFromList();
            }
            
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete maintenance`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            Loading.hide();
        }
    };

    
    const showField = (feature: string) => {
        return (vehicleSegmentFeatures.findIndex(x => x == feature) ?? -1) >= 0;
    }


    const tableColumns: ListingTableColumn<VehicleMaintenanceDto>[] = useMemo(() => {
        const cols: ListingTableColumn<VehicleMaintenanceDto>[] = [
            {
                name: t('common.type_description'),
                renderCell: row =>
                    <div className={`${styles.rowFlex} ${styles.flexColumn}`}>
                        {row.typeName ? <div className={`${styles.rowFlex}`}>{row.typeIconSvg && <RenderSVG item={row.typeIconSvg} className={styles.divSVG} />}<b> {t(('common.maintenance_contract_type.' + row.typeName) as any)} </b></div> : null}                       
                        <small>{row.description}</small>
                    </div>,
                width: 'fill',
                onSearch: onTableFilter,
                searchField: 'vm.description'
            },
            {
                name: t('common.state'),
                renderCell: row =>
                    <>
                        {row.state == VehicleMaintenanceStates.PREDICTED ? <Badge text={row.state ? (t(('maintenances.states.' + row.state) as any)) : '-'} preset="primaryDark" />
                        //: row.state == VehicleMaintenanceStates.OBLIGATORY ? <Badge text={row.state ? (t(('maintenances.states.' + row.state) as any)) : '-'} preset="danger" />
                        : row.state == VehicleMaintenanceStates.PERFORM ? <Badge text={row.state ? (t(('maintenances.states.' + row.state) as any)) : '-'} preset="success" />
                        : <Badge text={row.state ? (t(('maintenances.states.' + row.state) as any)) : '-'} preset="inactive" />}
                        <div className={`${styles.responsible} ${styles.keepText} ${styles.hideText}`}>
                            <AvatarWithText src={row.responsiblePhotoUrl} size="x2" hideAvatar={!row.responsibleName}>{row.responsibleName || '-'}</AvatarWithText>
                        </div>
                    </>,
                onSearch: onTableFilter,
                hideOn: ['sm'],
                searchField: 'vm.state'
            },
            {
                name:showField(SEGMENT_FEATURES.KM) ? t('maintenances.list.advise_kilometer_date') :  t('maintenances.list.advise_date'),
                renderCell: renderKilometersCell,
                cellAlignment: 'right',
                onSearch: onTableFilter,
                searchField: showField(SEGMENT_FEATURES.KM) ? 'vm.kilometers' : 'vm.maintenance_date',
                hideOn: ['sm', 'md', 'lg']
            },
            {
                name: t('maintenances.list.affected_to_date'),
                renderCell: row =>
                    <div className={`${styles.responsible} ${styles.keepText}`}>
                        <AvatarWithText src={row.responsiblePhotoUrl} size="x2" hideAvatar={!row.responsibleName}>{row.responsibleName || '-'}</AvatarWithText>
                        {!!row.expectedDate && <div className={styles.secondRow}>
                            <DateFormat value={row.expectedDate} format={DATE_FORMAT_DEFAUT} />{row.expectedHour && <> | {row.expectedHour}</>}
                        </div>}                        
                    </div>,
                onSearch: onTableFilter,
                searchField: 'vm.expected_date',
                hideOn: ['xxl'],
            },
            {
                name: t('common.cost', { vat : (userProfile?.useValueWithVat? t('common.with_vat') : t('common.without_vat')) }),
                renderCell: row =>
                    <div className={styles.costColumn} style={{ textAlign: !row.maintenanceContractsId && row.cost !== undefined && row.cost !== null ? 'right': 'left' }}>
                        <div style={{ fontWeight: 'bold' }}>
                            {row.maintenanceContractsId
                                ? <small>{t('maintenances.included_maintenance_contract')}</small>
                                : row.cost !== undefined && row.cost !== null ? <MoneyFormat value={row.cost} suffix={t('common.euro')} fixedDecimalScale={true} /> : '-'
                            }
                        </div>
                        {row.paymentDate && !row.maintenanceContractsId ? (<div>
                            <div
                                className={styles.secondRow}
                                data-tip={t('maintenances.payment_date')}
                                data-for="main1"
                            >
                                <DateFormat value={row.paymentDate} format={DATE_FORMAT_DEFAUT} />
                            </div>
                            <ReactTooltip id="main1" />
                        </div>) : ''}
                    </div>,
                width: 'fill',
                onSearch: onTableFilter,
                searchField: 'vm.cost',
                cellAlignment: 'right',
                hideOn: ['sm', 'md']
            },
            {
                name: t('common.supplier'),
                // renderCell: row => <>
                //     <div className={styles.keepText} data-tip={(row.supplierName || '-')}>
                //         {row.supplierUrl && <img src={row.supplierUrl} style={{ height: '1.25rem' }}></img>} {(row.supplierName || '-')}
                //     </div>
                //     <ReactTooltip />
                // </>,
                renderCell: row => <>
                    <div data-for="main2" data-tip={row.supplierUrl ? (row.supplierName || '-') : undefined}>
                        {row.supplierUrl && <img src={row.supplierUrl} style={{ height: '2.5rem' }}></img>}
                        {!row.supplierUrl && (row.supplierName || '-')}
                    </div>
                    <ReactTooltip id="main2" />
                </>,
                onSearch: onTableFilter,
                searchField: 'supplier.name',
                hideOn: ['sm', 'md', 'lg']
            },
            {
                name: '',
                width: '60px',
                cellAlignment: 'right',
                preventClick: true,
                renderCell: renderTableActionCell,
                cellStyle: { overflow: 'unset' },
                hideOn: ['sm', 'md', 'lg', 'xl']
            }
        ];

        if (!vehicleId) {
            cols.unshift(
                {
                    renderCell: row =>
                        <div className={styles.imageDescCell}>
                            <div className={styles.contentImage}>
                                <div className={styles.img} style={{ width: '5rem', height: '3rem', backgroundImage: row?.photoUrl ? 'url(' + row?.photoUrl + '?_=' + imageCacheKey.current + ')' : 'url(' + NoImage + ')' }} />
                            </div>
                        </div>,
                    width: 90,
                    hideOn: ['sm', 'md'],
                    onlyImageColumn: true
                },
                {
                    name: t('vehicles.list.vehicle'),
                    renderCell: row =>
                        <div className={styles.descCell}>
                            <div className={styles.columnPlate}>
                                {row.registrationNumber}
                                {!row.vehicleActive && <DeletedVehicleCircle id={row.id ?? ''} />}
                            </div>
                            <div className={styles.columnBrand}>{(row.vehicleBrandName ?? '') + (row.vehicleBrandName && row.vehicleModelName ? ' | ' : '') + (row.vehicleModelName ?? '')}</div>
                        </div>,
                    onSearch: onTableFilter,
                    searchField: 'v.registration_number',
                }
            );
        }

        return cols;
    }, []);

    return (
        <div>
            <ListingTable
                columns={tableColumns}
                rows={data.items}
                onRowClick={(row,_,event) => onClickItem(row,true,event)}
                //onHref={row => vehicleId ? `/vehicles/details/${vehicleId}/maintenance/details/${row.id}` : `maintenances/details/${row.id}`}
                allowHover={true}
                initialSearch={{ colField: 'vm.expected_date', isOrderAsc: false }}
            />
            <PaginationWithInfo
                itemName={t('maintenances.list.maintenances')}
                currentPage={data.currentPage}
                pageItems={data.items.length}
                totalItems={data.totalItems}
                onChange={onPageChange} /> 
            <QuestionYesNo
                onNo={onCancelRemove}
                onYes={onRemove}
                isVisible={showRemoveModal}
                message={t('common.messages.remove_maintenance')}/>
        </div>
    );
}

export default MaintenanceListTable;
