import { UserProfile } from 'api/account/models/UserProfile';
import { Paged } from 'api/common/types/Page';
import LocalsService from 'api/locals/LocalsService';
import { LocalDto } from 'api/locals/models/LocalDto';
import { LocalSearchCriteria } from 'api/locals/models/LocalSearchCriteria';
import UsersService from 'api/users/UsersService';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import NoImage from 'assets/svg/semfoto.svg';
import Dropdown from 'common/components/dropdown/Dropdown';
import DropdownItem from 'common/components/dropdown/DropdownItem';
import InputSearch from 'common/components/inputSearch/InputSearch';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEllipsisH } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Reducers } from 'store/types';
import { useDebouncedCallback } from 'use-debounce';
import styles from './LocalsList.module.scss';

type Props = {
};

const LocalsList: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const history = useHistory();

    const [localsPage, setLocalsPage] = useState<Paged<LocalDto>>();
    const [criteria, setCriteria] = useState<LocalSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderColumn: 'name',
        orderBy: 'asc'
    });

    const imageCacheKey = useRef(new Date().getTime());

    const [itemToRemove, setItemToRemove] = useState<LocalDto | null>(null);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasLocalsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_SUPPLIERS_WRITE']);

    const showRemoveItemDialog = (item: LocalDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    };

    const renderTableActionCell = (
        row: LocalDto,
    ) => {
        return (
            <Dropdown
                options={
                    <>
                        <DropdownItem url={`/settings/locals/details/${row.id ?? ''}`}>
                            {t('common.details')}
                        </DropdownItem>
                        {hasLocalsWritePolicy && <DropdownItem url={`/settings/locals/edit/${row.id ?? ''}`}>
                            {t('common.edit')}
                        </DropdownItem>}
                        {hasLocalsWritePolicy && <DropdownItem onClick={() => showRemoveItemDialog(row)}>
                            {t('common.remove')}
                        </DropdownItem>}
                    </>
                }
            >
                <div>
                    <FaEllipsisH />
                </div>
            </Dropdown>
        );
    };

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }


    const renderImageDescCell = (row: LocalDto) => {
        return (
            <div className={styles.imageDescCell}>
                <div className={styles.contentImage} style={{ width: 100, height: 80 }}>
                    <div className={styles.img} style={{ width: 100, height: 80, backgroundImage: row?.logoUrl ? 'url(' + row?.logoUrl + '?_=' + imageCacheKey.current + ')' : 'url(' + NoImage + ')' }} />
                </div>
                <div className={styles.descCell}>
                    {row.name}
                </div>
            </div>
        )
    }

    const tableColumns: ListingTableColumn<LocalDto>[] = [
        {
            field: 'name',
            name: t('vehicle_segments.list.name'),
            onSearch: onTableFilter,
            searchField: 'l.name',
            renderCell: renderImageDescCell
        },
        {
            name: '',
            width: '60px',
            cellAlignment: 'right',
            preventClick: true,
            renderCell: renderTableActionCell,
            cellStyle: { overflow: 'unset' },
        }
    ];

    const getData = async () => {
        try {
            Loading.show();

            const page = await LocalsService.getList(criteria);
            setLocalsPage(page);

            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't get vehicle segments list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }

        try {
            await LocalsService.remove(itemToRemove);
            setCriteria({ ...criteria, page: 1 });
            onCancelRemove();
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete local`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
        }
    };

    useEffect(() => {
        getData();
    }, [criteria]);

    const debounced = useDebouncedCallback((value: string) => {
        setCriteria({ ...criteria, page: 1, allIn: value });
    }, 500);

    return (
        <div className='list'>
            <div className={styles.tabHeader}>
                <div>
                    <InputSearch onChangeValue={debounced} placeholder={t('common.search')} ></InputSearch>
                </div>
                <div className={styles.rowFlex}>
                    {hasLocalsWritePolicy && <ScreenHeaderButton icon={AddIcon} onClick={() => history.push(`/settings/locals/new`)} />}
                </div>
            </div>
            <ListingTable
                columns={tableColumns}
                rows={localsPage?.items || []}
                onRowClick={row => history.push(`/settings/locals/details/${row.id ?? ''}`)}
                onHref={row => `/settings/locals/details/${row.id ?? ''}`}
                allowHover={true}
                initialSearch={{ colField: 'l.name', isOrderAsc: true }}
            />
            <PaginationWithInfo
                itemName={t('locals.list.locals')}
                currentPage={localsPage?.currentPage || 1}
                pageItems={localsPage?.items.length || 0}
                totalItems={localsPage?.totalItems || 0}
                onChange={page => setCriteria({ ...criteria, page })}
            />
            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('common.messages.remove_local', { local: itemToRemove?.name ?? '' })}/>
        </div>
    );
};

export default LocalsList;
