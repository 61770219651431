import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import { DATE_FORMAT_DEFAUT, DATE_TIME_FORMAT_DEFAULT, LOGGER_LOG_TYPE } from 'Config';
import { useToasts } from 'react-toast-notifications';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { Paged } from 'api/common/types/Page';
import styles from './CarVerificationList.module.scss';
import InputSearch from 'common/components/inputSearch/InputSearch';
import { useDebouncedCallback } from 'use-debounce';
import { CarVerificationListItemDto, RemoveItemViewModel } from 'api/carVerification/models/CarVerificationDto';
import { CarVerificationSearchCriteria } from 'api/carVerification/models/CarVerificationSearchCriteria';
import CarVerificationService from 'api/carVerification/CarVerificationService';
import DateFormat from 'common/components/dateFormat/dateFormat';
import AvatarWithText from 'common/components/avatar/AvatarWithText';
import Popover from 'common/components/popover/Popover';
import VerificationFiltersScreen, { Filters } from 'screens/verification/components/list-filters/VerificationFiltersScreen';
import { useHistory, useParams } from 'react-router-dom';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import IconWithText from 'common/components/iconWithText/IconWithText';
import GaugeIcon from 'assets/svg/gauge.svg';
import DriveIcon from 'assets/svg/drive.svg';
import VerificationsIcon from 'assets/svg/i_verificaçoes_carro.svg';
import CarburantIcon from 'assets/svg/desktop_frais_carburant.svg';
import PlayIcon from 'assets/svg/play2.svg';
import FlagBWIcon from 'assets/svg/flag_bw.svg';
import HourGlassIcon from 'assets/svg/hour-glass.svg';
import CalendarvIcon from 'assets/svg/calendar_v_routine.svg';
import FiltersIcon from 'assets/svg/desktop_filtres.svg';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import { VerificationType } from 'api/carVerification/enums/VerificationType';
import moment from 'moment';
import colors from 'styles/export/colors.module.scss';
import NoImage from 'assets/img/asset-default-image.png';
import Badge from 'common/components/badge/Badge';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { FaEllipsisH } from 'react-icons/fa';
import Dropdown from 'common/components/dropdown/Dropdown';
import DropdownItem from 'common/components/dropdown/DropdownItem';
import { getInterventionTypeStatusColor } from 'common/utils/VerificationsUtils';
import { InterventionTypeEnum } from 'api/carVerification/enums/InterventionTypeEnum';
import RequestVerificationScreen from 'screens/verification/components/request-verification/RequestVerificationScreen';
import Button from 'common/components/button/Button';
import ReactTooltip from 'react-tooltip';

type Props = {
    criteria: CarVerificationSearchCriteria;
    updateCriteria: (criteria: CarVerificationSearchCriteria) => void;
    genericPage?: boolean;
    isVehicle?: boolean;
    editable: boolean;
    tourReRender?: () => void;
};

const CarVerificationList: React.FC<Props> = ({ criteria, isVehicle, editable,  genericPage = false, updateCriteria, tourReRender }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const history = useHistory();
    const { vehicleId, tabId } = useParams<{ vehicleId: string, itemType: string, itemId: string, tabId: string, type: string }>();
    const [carVerificationPage, setCarVerificationPage] = useState<Paged<CarVerificationListItemDto>>();
    const [filters, setFilters] = useState<Filters>({});
    const [filtersTotal, setFiltersTotal] = useState(0);

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasVerificationsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_VERIFICATIONS_WRITE']);
    const hasRequestVerificationsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_REQUEST_VERIFICATIONS_WRITE']);
    const imageCacheKey = useRef(new Date().getTime());
    const [itemToRemove, setItemToRemove] = useState<CarVerificationListItemDto | null>(null);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);

    let annotations: any[];

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        updateCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const renderDoubleRows = (row1: any, row2: any) => {
        return (
            <div className={styles.flex}>
                {row1}
                {row2}
                {!row1 && !row2 && '-'}
            </div>
        )
    }

    const renderDates = (row: CarVerificationListItemDto) => {
        const dateBegin = row.verificationDate ? moment(row.verificationDate).toDate() : null;
        if (dateBegin) {
            if (row.verificationHour && row.verificationHour.length > 0) {
                const splittedHoursMinutesStart = row.verificationHour.split(':');
                dateBegin.setHours(Number(splittedHoursMinutesStart[0] ?? 0));
                dateBegin.setMinutes(Number(splittedHoursMinutesStart[1] ?? 0));
            }
        }

        const dateEnd = row.verificationFinishDate ? moment(row.verificationFinishDate).toDate() : null;
        if (dateEnd && row.verificationFinishHour) {
            const splittedHoursMinutesEnd = row.verificationFinishHour.split(':');
            dateEnd.setHours(Number(splittedHoursMinutesEnd[0] ?? 0));
            dateEnd.setMinutes(Number(splittedHoursMinutesEnd[1] ?? 0));
        }

        return renderDoubleRows(
            dateBegin ? <IconWithText
                icon={row.type == VerificationType.TRAVEL.toString() ? PlayIcon : CalendarvIcon}
                valueDate={dateBegin}
                valueHour={row.verificationHour}
                isDate={true}
            /> : null,
            row.type == VerificationType.TRAVEL.toString() && dateBegin && !dateEnd ? <IconWithText
                icon={HourGlassIcon}
                value={t('car_verification.list.in_course')}
                suffix={t('car_verification.list.kms')}
                color={colors.defaultGreen}
                className={styles.labelSmall}
            />
                : row.type == VerificationType.TRAVEL.toString() && dateBegin && dateEnd ? <IconWithText
                    icon={FlagBWIcon}
                    valueDate={dateEnd}
                    valueHour={row.verificationFinishHour ?? null}
                    isDate={true}
                />
                    : null)
    }

    const renderImageCell = (row: CarVerificationListItemDto) => {
        return (
            <div className={styles.imageDescCell}>
                <div className={styles.contentImage} style={{ width: '5rem', height: '3rem' }}>
                    <div className={styles.img} style={{ width: '5rem', height: '3rem', backgroundImage: row?.vehiclePhotoUrl ? 'url(' + row?.vehiclePhotoUrl + '?_=' + imageCacheKey.current + ')' : 'url(' + NoImage + ')' }} />
                </div>
            </div>
        )
    }

    const renderVehicleCell = (row: CarVerificationListItemDto) => {
        return (
            <div className={styles.descCell}>
                <div className={styles.columnPlate}>{row.registrationNumber}</div>
                <div className={styles.columnBrand}>{(row.vehicleBrandName ?? '') + (row.vehicleBrandName && row.vehicleModelName ? ' | ' : '') + (row.vehicleModelName ?? '')}</div>
            </div>
        )
    }

    const renderAnnotationsCell = (row: CarVerificationListItemDto) => {
        const vehicleImmobilizedTooltip = row.predictedDateEndImmobilization
            ? `${t('vehicles.stopped_until')} ${moment(row.predictedDateEndImmobilization).format(DATE_FORMAT_DEFAUT)}`
            : t('vehicles.no_date_end_immobilization');

        if (row) {
            annotations = ([
                { counter: row.numberAnnotationsUrgent, color: getInterventionTypeStatusColor(InterventionTypeEnum.INTERVENTION_URGENT).color },
                { counter: row.numberAnnotationsInformative, color: getInterventionTypeStatusColor(InterventionTypeEnum.INFORMATION).color },
                { counter: row.numberAnnotationsIntervention, color: getInterventionTypeStatusColor(InterventionTypeEnum.INTERVENTION).color }
            ])

        }
        return (
            <div>
                <span className={styles.spanCounter}>
                    {!!annotations && <div className={styles.countersContainer}>
                        {annotations.map((x, i) => <Badge className={styles.counterBadge} key={`roundValue_${i}`}
                            backgroundColor={x.color} size={'round'} outlineColor={x.color}>{x.counter}</Badge>)}
                        {row.immobilized && <div className={styles.stopContainer}>
                            <div className={styles.iconStop} data-tip={vehicleImmobilizedTooltip} />
                        </div>}
                    </div>
                    }
                </span>
            </div>
        )
    }

    const renderTableActionCell = (
        row: CarVerificationListItemDto,
    ) => {
        const urlDetails = isVehicle ? `/vehicles/details/${vehicleId}/${tabId}/details/${row.id}` : `verifications/details/${row.id}`;
        const urlEdit = isVehicle ? `/vehicles/details/${vehicleId}/${tabId}/edit/${row.id}` : `verifications/edit/${row.id}`;

        return (
            <Dropdown
                options={
                    <>
                        <DropdownItem url={urlDetails}>
                            {t('common.details')}
                        </DropdownItem>
                        {hasVerificationsWritePolicy && editable && <DropdownItem url={urlEdit}>
                            {t('common.edit')}
                        </DropdownItem>}
                        {hasVerificationsWritePolicy && editable && <DropdownItem onClick={() => showRemoveItemDialog(row)}>
                            {t('common.remove')}
                        </DropdownItem>}
                    </>
                }
            >
                <div>
                    <FaEllipsisH />
                </div>
            </Dropdown>
        );
    };

    const showRemoveItemDialog = (item: CarVerificationListItemDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    };

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }

        try {
            setShowRemoveModal(false);
            Loading.show();
            await CarVerificationService.remove(itemToRemove as RemoveItemViewModel);
            updateCriteria({ ...criteria, page: 1 });
            onCancelRemove();
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });
            Loading.hide();
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete verification`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const tableColumns: ListingTableColumn<CarVerificationListItemDto>[] = [
        {
            field: 'type',
            name: t('car_verification.list.type'),
            renderCell: row => <IconWithText
                icon={row.type == VerificationType.TRAVEL.toString() ? DriveIcon : VerificationsIcon}
                value={t(`car_verification.annotation_types.${row.type}`.toLowerCase() as any)}
                size={'lg'}
            />,
            onSearch: onTableFilter,
            searchField: 'v.type'
        },
        {
            name: t('car_verification.list.responsible_date'),
            renderCell: row => renderDoubleRows(
                <AvatarWithText src={row.responsiblePhotoUrl} size="x2" hideAvatar={!row.responsibleName}><span className={styles.fontBold}>{row.responsibleName || '-'}</span></AvatarWithText>,
                <DateFormat value={row.dateModified} format={DATE_TIME_FORMAT_DEFAULT.toString()} />),
            onSearch: onTableFilter,
            searchField: 'responsible.real_name',
            hideOn: ['sm', 'md', 'lg'],
        },
        {
            name: t('car_verification.list.driver'),
            renderCell: row => <AvatarWithText src={row.driverPhotoUrl} size="x2" hideAvatar={!row.driverName}><span>{row.driverName || '-'}</span></AvatarWithText>,
            onSearch: onTableFilter,
            hideOn: ['sm', 'md', 'lg', 'xl'],
            searchField: 'driver.real_name'
        },
        {
            name: t('car_verification.list.dates_start_end'),
            renderCell: renderDates,
            onSearch: onTableFilter,
            hideOn: ['sm', 'md'],
            searchField: 'v.verification_date'
        },
        {
            name: t('car_verification.list.autonomy_km'),
            renderCell: row => renderDoubleRows(
                <IconWithText
                    icon={CarburantIcon}
                    value={row.autonomy}
                    suffix={t('car_verification.list.autonomy_symbol')}
                    isNumeric={true}
                />,
                <IconWithText
                    icon={GaugeIcon}
                    value={row.kilometers}
                    suffix={t('car_verification.list.kms')}
                    isNumeric={true}
                />),
            onSearch: onTableFilter,
            hideOn: ['sm', 'md', 'lg', 'xl', 'xxl'],
            searchField: 'v.autonomy',
        },
        {
            name: t('car_verification.list.annotations'),
            renderCell: renderAnnotationsCell,
            hideOn: ['sm', 'md', 'lg'],
        },
        {
            name: '',
            width: '60px',
            cellAlignment: 'right',
            preventClick: true,
            renderCell: renderTableActionCell,
            cellStyle: { overflow: 'unset' },
            hideOn: ['sm', 'md', 'lg', 'xl']
        }
    ];

    if (!vehicleId) {
        tableColumns.unshift({
            renderCell: renderImageCell,
            width: 90,
            hideOn: ['sm'],
            onlyImageColumn: true
        });
    }

    if (genericPage) {
        tableColumns.splice(1, 0, {
            field: 'registrationNumber',
            name: t('car_verification.vehicle'),
            cellStyle: { verticalAlign: 'baseline', textAlign: 'start' },
            onSearch: onTableFilter,
            renderCell: renderVehicleCell,
            searchField: 'vehicles.registration_number'
        });
    }

    const getData = async () => {
        try {
            Loading.show();

            const page = await CarVerificationService.getList({ ...criteria, vehicleId: vehicleId ? vehicleId : criteria.vehicleId });
            setCarVerificationPage(page);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't get vehicle segments list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
        finally{
            tourReRender && tourReRender();
        }
    };

    useEffect(() => {
        getData();
    }, [criteria]);

    const debounced = useDebouncedCallback((value: string) => {
        updateCriteria({ ...criteria, page: 1, allIn: value });
    }, 500);

    const updateTotalFilters = (_filters: Filters) => {
        let count = 0;
        if(_filters.driverId)count++;
        if(_filters.endDate)count++;
        if(_filters.interventionType)count++;
        if(_filters.responsibleId)count++;
        if(_filters.startDate)count++;
        if(_filters.type)count++;
        setFiltersTotal(count);
    }

    const onChangeFilters = (_filters: Filters) => {
        updateCriteria({ ...criteria, ..._filters });
        setFilters(_filters);
        updateTotalFilters(_filters);
    }

    const navigateToNew = () => {
        if (isVehicle) {
            history.push(`/vehicles/details/${vehicleId}/${tabId}/new`);
            return;
        }
        if (genericPage) {
            history.push(`/verifications/new`);
            return;
        }
    }

    const navigateToItem = (row: CarVerificationListItemDto,e: any) => {
        e.preventDefault();
        e.stopPropagation();


        if (isVehicle) {
            if (e.ctrlKey) {
                window.open(`/vehicles/details/${vehicleId}/${tabId}/details/${row.id}`, '_blank');
                return;
            }

            history.push(`/vehicles/details/${vehicleId}/${tabId}/details/${row.id}`);
            return;
        }
        if (genericPage) {

            if (e.ctrlKey) {
                window.open(`/verifications/details/${row.id}`, '_blank');
                return;
            }
            history.push(`/verifications/details/${row.id}`);
        }
    }

    return (
        <ScreenContainer>
            <div className='list'>
                {!genericPage && <div className={styles.tabHeader}>
                    <div>
                        <InputSearch onChangeValue={debounced} placeholder={t('common.search')} ></InputSearch>
                    </div>
                    <div style={{ display: 'inline-flex' }}>

                        {hasRequestVerificationsWritePolicy &&
                        <Popover
                            contentContainerClassName={styles.filtersPopoverContainer}
                            containerStyle={{ boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}
                            positions={['bottom', 'left']}
                            align={'end'}
                            onClickOutside={() => updateTotalFilters(filters)}
                            content={setIsPopoverOpen => <RequestVerificationScreen
                                vehicleId={vehicleId}
                                onCancel={()=> setIsPopoverOpen(false)}
                            />}
                        >
                            {(isPopoverOpen, setIsPopoverOpen) => (
                               <>
                                <Button
                                size={'extraSmall'}
                                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                                preset={'success'}
                                text={t('car_verification.list.new_request_verification')}
                                data-tip={t('car_verification.list.new_request_verification_tooltip')}
                                />
                                <ReactTooltip />
                                </>
                            )}
                        </Popover>}

                        <Popover
                            contentContainerClassName={styles.filtersPopoverContainer}
                            containerStyle={{ boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}
                            positions={['bottom', 'left']}
                            align={'end'}
                            onClickOutside={() => updateTotalFilters(filters)}
                            content={setIsPopoverOpen => <VerificationFiltersScreen
                                filters={filters}
                                onFilter={_filters => { setIsPopoverOpen(false); onChangeFilters(_filters) }}
                                onChange={_filters => updateTotalFilters(_filters)}
                            />}
                        >
                            {(isPopoverOpen, setIsPopoverOpen) => (
                                <ScreenHeaderButton icon={FiltersIcon} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                                    {filtersTotal > 0 &&
                                        <div className={styles.counter}> <div className={styles.counterNumber}>{filtersTotal}</div> </div>
                                    }
                                </ScreenHeaderButton>
                            )}
                        </Popover>
                        {hasVerificationsWritePolicy && editable && <ScreenHeaderButton icon={AddIcon} onClick={() => navigateToNew()} />}
                    </div>
                </div>}

                <ListingTable
                    columns={tableColumns}
                    rows={carVerificationPage?.items || []}
                    onRowClick={(row, _,e:any) => navigateToItem(row,e)}
                    onHref={row => isVehicle ? `/vehicles/details/${vehicleId}/${tabId}/details/${row.id}` : `/verifications/details/${row.id}`}
                    allowHover={true}
                    initialSearch={{ colField: 'v.verification_date', isOrderAsc: false }}
                />

                <PaginationWithInfo
                    itemName={t('car_verification.list.verifications')}
                    currentPage={carVerificationPage?.currentPage || 1}
                    pageItems={carVerificationPage?.items.length || 0}
                    totalItems={carVerificationPage?.totalItems || 0}
                    onChange={page => updateCriteria({ ...criteria, page })}
                />

                <QuestionYesNo
                    onNo={onCancelRemove}
                    onYes={onRemove}
                    isVisible={showRemoveModal}
                    message={t('common.messages.remove_record')} />
            </div>
        </ScreenContainer>
    );
};

export default CarVerificationList;
