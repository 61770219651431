import Http from '../../../common/services/Http';
import { Paged } from '../../common/types/Page';
import { MaintenanceContractsSearchCriteria } from './models/MaintenanceContractsSearchCriteria';
import { MaintenanceContractDto } from './models/MaintenanceContractDto';
import CustomFile from '../../../common/models/CustomFile';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { CatalogMaintenanceContractsSearchCriteria } from 'api/catalog/models/CatalogMaintenanceContractsSearchCriteria';

class MaintenanceContractsService {

    public getList(criteria: MaintenanceContractsSearchCriteria) {
        return Http.get<Paged<MaintenanceContractDto>>('maintenanceContracts', criteria);
    }

    public catalog(criteria?: CatalogMaintenanceContractsSearchCriteria) {
        return Http.get<SelectValueLabel[]>('maintenanceContracts/catalog', criteria);
    }

    public getById(id: string) {
        return Http.get<MaintenanceContractDto>('maintenanceContracts/' + id);
    }

    public create(model: MaintenanceContractDto, attachment: CustomFile[]) {
        return Http.postWithFile<string>('maintenanceContracts', model, attachment);
    }

    public update(model: MaintenanceContractDto, attachment: CustomFile[]) {
        return Http.putWithFile<string>('maintenanceContracts', model, attachment);
    }

    public remove(model: MaintenanceContractDto) {
        return Http.put('maintenanceContracts/deactivate', model);
    }

    public getMaintenanceContractTypes() {
        return Http.get<SelectValueLabel[]>('maintenanceContracts/get-maintenance-contract-types');
    }
}

export default new MaintenanceContractsService();