import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { ChargesChartSearchCriteria, ChargesSearchCriteria } from './models/ChargesSearchCriteria';
import { ChargeCounterListDto, ChargeDto, ChartChargeDto } from './models/ChargeDto';
import CustomFile from 'common/models/CustomFile';
import { SelectValueLabel } from 'common/types/SelectValueLabel';

class ChargesService {

    public getList(criteria: ChargesSearchCriteria) {
        return Http.get<Paged<ChargeDto>>('charges', criteria);
    }

    public catalog(criteria: ChargesSearchCriteria) {
        return Http.get<SelectValueLabel[]>('charges/catalog', criteria);
    }

    public getById(id: string) {
        return Http.get<ChargeDto>('charges/' + id);
    }
    
    public create(model: ChargeDto, attachment: CustomFile[]) {
        return Http.postWithFile<string>('charges', model, attachment);
    }

    public update(model: ChargeDto, attachment: CustomFile[]) {
        return Http.putWithFile<string>('charges', model, attachment);
    }

    public remove(model: ChargeDto) {
        return Http.put('charges/deactivate', model);
    }

    public getChartCharges(criteria: ChargesChartSearchCriteria) {
        return Http.get<ChartChargeDto>('charges/chart', criteria);
    }

    public getCountersList(criteria: ChargesSearchCriteria) {
        return Http.get<ChargeCounterListDto>('charges/counters-list', criteria);
    }
}

export default new ChargesService();