import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './RequestVerificationScreen.module.scss';
import Button from '../../../../common/components/button/Button';
import FormItem from '../../../../common/components/formItem/FormItem';
import Label from '../../../../common/components/label/Label';
import { Col, Row } from 'react-flexbox-grid';
import { SelectValueLabel } from '../../../../common/types/SelectValueLabel';
import { useForm } from 'react-hook-form';
import UsersService from 'api/users/UsersService';
import SelectController from 'common/components/select/SelectController';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';
import Loading from 'common/services/Loading';
import InputError from 'common/components/inputError/InputError';
import AlertsService from 'api/alerts/AlertsService';
import { AlertType } from 'api/alerts/enums/AlertType';
import { useToasts } from 'react-toast-notifications';
import VehiclesService from 'api/vehicles/VehiclesService';

export interface Form {
    vehicleId: string;
    userId?: string;
    userName?: string;
}

type Props = {
    vehicleId: string;
    onCancel: () => void;
}

const RequestVerificationScreen: React.FC<Props> = ({ vehicleId, onCancel }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const form = useForm<Form>({ shouldUnregister: false, shouldFocusError: true, defaultValues: { vehicleId } });
    const { register, handleSubmit, errors, watch, getValues, setValue } = form;
    const [usersOptions, setUsers] = useState<SelectValueLabel[]>([]);
    const [reRenderKey, setReRenderKey] = useState(new Date().getTime());

    const getData = async () => {
        try {
            Loading.show();

            const [driver, usersList] = await Promise.all([
                VehiclesService.getCarCurrentDriver(vehicleId),
                UsersService.catalog(),
            ]);

            setUsers(usersList);

            if (driver) {
                const aux = usersList.find(x => x.value === driver.value);
                if (aux) {
                    setValue('userId', aux.value);
                    setValue('userName', aux.label);
                    setReRenderKey(new Date().getTime());
                }
            }


        } catch (error) {
            addToast(t('common.messages.error_load_info'), {
                appearance: 'error',
            });
            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                `Couldn't load info on request verification modal`,
                error
            );
        } finally {
            Loading.hide();
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const onSubmit = async (formData: Form) => {
        try {

            if (!formData.userId) {
                addToast(t('common.messages.complete_required_fields'), { appearance: 'warning' });
                return;
            }

            Loading.show();

            await AlertsService.createAlert({
                vehicleId: formData.vehicleId,
                userId: formData.userId,
                communicationTypes: [
                    AlertType.REQUEST_VERIFICATION_NEW_ALERT,
                    'REQUEST_VERIFICATION_NEW_EMAIL'
                ]
            });

            Loading.hide();
            addToast(t('common.messages.record_save_success'), {
                appearance: 'success',
            });

            onCancel();

        } catch (error) {
            addToast(t('common.messages.record_save_error'), {
                appearance: 'error',
            });

            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                `Couldn't create or update request verification`,
                error
            );

            Loading.hide();
        }
    }

    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className={styles.formContent}>
                <div>
                    <FormItem>
                        <Row className={styles.messageRow}>
                            <Col xs={12}>
                                <Label>{t('car_verification.request_verification.message')}:</Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} xl={6} key={'col-' + reRenderKey}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('car_verification.request_verification.user')}</Label>
                                    <SelectController
                                        ref={register('userId')}
                                        form={form}
                                        name="userId"
                                        placeholder={t('car_verification.request_verification.user')}
                                        isDisabled={false}
                                        options={usersOptions}
                                        rules={{ required: true }}
                                        onChangeSelect={(data: SelectValueLabel) => {
                                            setValue('userId', data?.value);
                                            setValue('userName', data?.label);
                                        }}
                                    />
                                    <InputError error={errors.userId} />
                                </FormItem>
                            </Col>
                        </Row>
                    </FormItem>
                </div>
                <div className={styles.buttonsFooter}>
                    <FormItem>
                        <Button
                            text={t('common.cancel')}
                            size={'normal'}
                            preset={'secondary'}
                            onClick={onCancel}
                            type='reset' />
                        <Button
                            type='submit'
                            text={t('common.add')}
                            size={'normal'}
                        />
                    </FormItem>
                </div>
            </div>
        </form>
    );
}

export default memo(RequestVerificationScreen);
