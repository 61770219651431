import React, { useState, useRef, useEffect } from 'react';
import styles from './SortingDropdown.module.scss';
import { useTranslation } from 'react-i18next';
import { FaSortAmountDown, FaSortAmountUp } from 'react-icons/fa';

type Props = {
    onSortChange: (value: any) => void;
    defaultOption?: string;
    closeDropOnSelect?: boolean;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

const SortingDropdown: React.FC<Props> = ({ onSortChange, defaultOption, closeDropOnSelect }: Props) => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const [selectedOption, setSelectedOption] = useState<string | undefined>(defaultOption);

    const options = [
        { value: 'DESC', label: t('common.sort.desc'), icon: <FaSortAmountDown /> },
        { value: 'ASC', label: t('common.sort.asc'), icon: <FaSortAmountUp /> },
    ];

    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleSortChange = (e: any) => {
        const option = e.value;
        setSelectedOption(option);
        onSortChange(option);
        if (closeDropOnSelect) {
            setIsOpen(false);
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (
        <>
            <div className={styles.sortingDropdown} ref={dropdownRef}>
                <div className={styles.content} onClick={() => setIsOpen(!isOpen)}>
                    <div style={{ margin: '0 5px' }}>{selectedOption && options.find(opt => opt.value === selectedOption)?.icon}</div>
                    {/* {isOpen ? <FaCaretUp /> : <FaCaretDown />} */}
                </div>
                {isOpen && (
                    <div className={styles.options}>
                        {options.map(option => (
                            <div key={option.value} className={`${styles.option} ${selectedOption === option.value ? styles.selected : ''}`}
                                onClick={() => handleSortChange(option)}                            >
                                {option.label}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
}

export default SortingDropdown;
