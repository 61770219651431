import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { VehicleSegmentDto } from 'api/vehicleSegments/models/VehicleSegmentDto';
import Loading from 'common/services/Loading';
import VehicleSegmentsService from 'api/vehicleSegments/VehicleSegmentsService';
import Logger from 'common/services/Logger';
import Button from 'common/components/button/Button';
import styles from './VehicleSegmentsDetails.module.scss';
import InputError from 'common/components/inputError/InputError';
import Input from 'common/components/input/Input';
import Label from 'common/components/label/Label';
import FormItem from 'common/components/formItem/FormItem';
import { Controller, useForm } from 'react-hook-form';
import { DEFAULT_INPUT_RULES_WITH_REQUIRED, LOGGER_LOG_TYPE, SEGMENT_FEATURES } from 'Config';
import GreyArrow from 'assets/svg/Arrow-grey.svg';
import { useEffect, useState } from 'react';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import Utils from 'common/services/Utils';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { Col, Row } from 'react-flexbox-grid';
import Select from 'common/components/select/Select';

type Props = {
};

const VehicleSegmentsDetails: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const history = useHistory();
    // eslint-disable-next-line
    let { type, itemId } = useParams<{ type: string, itemId: string }>();
    const isDetails = type === 'details';

    const { register, handleSubmit, errors, reset, control, getValues } = useForm<VehicleSegmentDto>();

    const [vehicleSegment, setVehicleSegment] = useState<VehicleSegmentDto | null>(null);
    const [segmentFeatures, setSegmentFeatures] = useState<SelectValueLabel[]>([]);

    const [itemToRemove, setItemToRemove] = useState<VehicleSegmentDto>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasVehicleSegmentsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_VEHICLE_SEGMENTS_WRITE']);

    const onSubmit = async (formData: VehicleSegmentDto) => {
        try {
            if (!hasVehicleSegmentsWritePolicy) { return; }

            Loading.show();

            if (vehicleSegment != null) {
                vehicleSegment.name = formData.name;
                vehicleSegment.features = formData.features;
                await VehicleSegmentsService.update(vehicleSegment);
            } else {
                itemId = await VehicleSegmentsService.create(formData);
                getData();
            }

            Loading.hide();
            addToast(t('common.messages.record_save_success'), {
                appearance: 'success',
            });

            history.push(`/settings/segments/details/${itemId}`);
        } catch (error) {
            Loading.hide();

            if (error?.response?.status === 409) {
                addToast(t('common.messages.name_already_exists'), { appearance: 'warning' });
                return;
            }

            addToast(t('common.messages.record_save_error'), {
                appearance: 'error',
            });

            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                `Couldn't create or update vehicle segments`,
                error
            );
        }
    };

    const getData = async () => {

        const features = [];
        for (const feature in SEGMENT_FEATURES) {
            features.push({ value: feature, label: t(`vehicle_segments.feature_options.${feature}` as any) });
        }
        setSegmentFeatures(Utils.sortAlphabetically(features))

        if (itemId == null) {
            return;
        }

        Loading.show();
        try {
            const result = await VehicleSegmentsService.getById(itemId);
            setVehicleSegment(result);
            reset(result);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't get vehicle segment`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const showRemoveItemDialog = (item: VehicleSegmentDto) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await VehicleSegmentsService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                history.push(`/settings/segments/list`);
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const onGoBack = () => {
        if(vehicleSegment){
            reset(vehicleSegment);
        }
        history.goBack();
    }

    return (
        <div>
            <div className={styles.pageNameContainer}>
                <div className={styles.pageNameContent} onClick={() => history.push(`/settings/segments/list`)} >
                    <img className={styles.pageNameContentImage} src={GreyArrow} />
                    <span className={styles.pageNameContentText}>
                        {t('settings.vehicle_segments')}
                    </span>
                </div>
            </div>

            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col xs={12} md={6}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('vehicle_segments.name')} {!isDetails ? '*' : ''}
                            </Label>
                            <Input
                                name='name'
                                placeholder={t('vehicle_segments.name')}
                                ref={register({ ...DEFAULT_INPUT_RULES_WITH_REQUIRED })}
                                disabled={type === 'details'}
                            />
                            <InputError error={errors.name} maxLength={250} />
                        </FormItem>
                    </Col>

                    <Col xs={12} md={6}>
                        <FormItem>
                            <Label className={styles.label}>{t('vehicle_segments.features')}</Label>
                            <Controller
                                render={({ onChange, value }) => {
                                    return (
                                        <Select
                                            options={segmentFeatures}
                                            isClearable
                                            isMulti
                                            isDisabled={isDetails}
                                            placeholder={t('vehicle_segments.features')}
                                            onChange={(data: SelectValueLabel[]) => {
                                                onChange(data?.map(x => ( x.value )));
                                            }}
                                            value={value ? segmentFeatures.filter(x => value.find((y: any) => y === x.value)) : null}
                                        />
                                    );
                                }}
                                control={control}
                                name="features"
                                defaultValue={getValues('features')} />
                            <InputError error={errors.features as any} />
                        </FormItem>
                    </Col>
                </Row>

                <div className={styles.buttonContainer}>
                    <Button preset={'secondary'} type='button' text={t('common.cancel')} onClick={() => onGoBack()} />
                    {type === 'details' && hasVehicleSegmentsWritePolicy &&
                        <Button
                            type="button"
                            text={t('common.remove')}
                            preset={'danger'}
                            onClick={() => showRemoveItemDialog({ id: vehicleSegment?.id } as VehicleSegmentDto)} />
                    }
                    {type === 'details' && hasVehicleSegmentsWritePolicy && <Button type='button' text={t('common.edit')} onClick={() => history.push(`/settings/segments/edit/${itemId}`)} />}
                    {type !== 'details' && hasVehicleSegmentsWritePolicy && <Button type='submit' text={t('common.save')} />}

                    <QuestionYesNo message={t('common.messages.remove_vehicle_segment', { vehicleSegment: vehicleSegment?.name ?? '' })}
                        isVisible={dialogDeleteItemIsOpen}
                        onYes={() => removeItem()}
                        onNo={() => setDialogDeleteItemIsOpen(false)} />
                </div>
            </form>
        </div>
    );
};

export default VehicleSegmentsDetails;
