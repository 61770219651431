import Http from '../../../common/services/Http';
import { Paged } from '../../common/types/Page';
import { InsuranceContractsSearchCriteria } from './models/InsuranceContractsSearchCriteria';
import { InsuranceContractDto } from './models/InsuranceContractDto';
import CustomFile from '../../../common/models/CustomFile';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { CatalogInsuranceContractsSearchCriteria } from 'api/catalog/models/CatalogInsuranceContractsSearchCriteria';

class InsuranceContractsService {

    public getList(criteria: InsuranceContractsSearchCriteria) {
        return Http.get<Paged<InsuranceContractDto>>('insuranceContracts', criteria);
    }

    public catalog(criteria?: CatalogInsuranceContractsSearchCriteria) {
        return Http.get<SelectValueLabel[]>('insuranceContracts/catalog', criteria);
    }

    public getById(id: string) {
        return Http.get<InsuranceContractDto>('insuranceContracts/' + id);
    }

    public create(model: InsuranceContractDto, attachment?: CustomFile[]) {
        return Http.postWithFile<string>('insuranceContracts', model, attachment ? attachment : []);
    }

    public update(model: InsuranceContractDto, attachment?: CustomFile[]) {
        return Http.putWithFile('insuranceContracts', model, attachment ? attachment : []);
    }

    public remove(model: InsuranceContractDto) {
        return Http.put('insuranceContracts/deactivate', model);
    }

    public getInsuranceContractTypes() {
        return Http.get<SelectValueLabel[]>('insuranceContracts/get-insurance-contract-types');
    }
}

export default new InsuranceContractsService();