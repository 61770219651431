import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle'
import AddIcon from 'assets/svg/desktop_add_new.svg';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import styles from './AlarmsListScreen.module.scss';
import { Filters } from '../alarmsFilters/AlarmsFilters';
import Box from 'common/components/box/Box';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import { DATE_TIME_FORMAT_DEFAUT, DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import { AlarmsSearchCriteria } from 'api/alarms/models/AlarmsSearchCriteria';
import { AlarmsListItemDto } from 'api/alarms/models/AlarmsListItemDto';
import DateFormat from 'common/components/dateFormat/dateFormat';
import toggleSwitchStyle from 'common/components/toggleSwitch/ToggleSwitch.module.scss'
import InputSearch from 'common/components/inputSearch/InputSearch';
import { useDebouncedCallback } from 'use-debounce';
import { Col, Row } from 'react-flexbox-grid';
import Loading from 'common/services/Loading';
import AlarmsService from 'api/alarms/AlarmsService';
import Logger from 'common/services/Logger';
import { useToasts } from 'react-toast-notifications';


const AlarmsListScreen = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { addToast } = useToasts();

    const [alarms, setAlarms] = useState<AlarmsListItemDto[]>([])
    const [totalItems, setTotalItems] = useState<number>(0);
    const [criteria, setCriteria] = useState<AlarmsSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderColumn:'st.name',
        orderBy: 'asc'
    });
    const [filters, setFilters] = useState<Filters>({});

    useEffect(() => {
        loadFilters();
    }, [criteria]);

    const getData = async () => {
        try {
            Loading.show();

            const [alarmsItems] = await Promise.all([
                AlarmsService.getList(criteria),
            ]);

            if ((alarmsItems?.totalPages ?? 0 > 0)  && (alarmsItems.currentPage > (alarmsItems?.totalPages ?? 0))) {
                criteria.page = 1;
                getData()
                return;
            }
            setAlarms(alarmsItems.items ?? []);
            setTotalItems(alarmsItems.totalItems)
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.ALARMS, `Couldn't get alarms list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const loadFilters = () => {
        const cachedFilters = localStorage.getItem('ALARMSFILTER');
        if (cachedFilters != null) { onChangeFilters(JSON.parse(cachedFilters), true) }
        else { getData() }
    }

    const navigateTo = (type?: string, id?: string) => {
        if (id) {
            history.push(`/alarms/${type}/${id}`);
        } else {
            history.push(`/alarms/${type}`);
        }
    }

    const onPageChange = (page: number) => {
        criteria.page = page;
        getData();
    }

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const tableColumns: ListingTableColumn<AlarmsListItemDto>[] = useMemo(() => {
        const cols: ListingTableColumn<AlarmsListItemDto>[] = [
            {
                name: t('alarms.list.type'),
                onSearch: onTableFilter,
                searchField: 'st.type',
                renderCell: row => renderTableTypeCell(row.type),
                width: 'fill'
            },
            {
                name: t('alarms.list.name'),
                renderCell: row => renderTableCell(row.name),
                onSearch: onTableFilter,
                searchField: 'st.name',
                width: 'fill'
            },
            {
                name: t('alarms.list.vehicles'),
                renderCell: row => renderTableCell(row.numberVehicles),
                onSearch: onTableFilter,
                searchField: 'numberVehicles',
                hideOn: ['sm', 'md', 'lg'],
                width: 'fill'
            },
            {
                name: t('alarms.list.triggers'),
                renderCell: row => renderTableCell(row.numberTriggers),
                onSearch: onTableFilter,
                searchField: 'numberTriggers',
                width: 'fill',
                hideOn: ['sm', 'md', 'lg']
            },
            {
                name: t('alarms.list.last_modification'),
                renderCell: row => row.updatedDate ? <DateFormat value={row.updatedDate} format={DATE_TIME_FORMAT_DEFAUT} /> : <>-</>,
                onSearch: onTableFilter,
                searchField: 'st.updated_date',
                width: 'fill',
                hideOn: ['sm', 'md']
            },
            {
                name: t('alarms.list.active'),
                renderCell: row => renderActiveColTableCell(row.enabled),
                onSearch: onTableFilter,
                searchField: 'st.enabled',
                width: 'fill',
                hideOn: ['sm', 'md', 'lg', 'xl']
            },
        ];
        return cols;
    }, [onTableFilter]);

    const renderTableCell = (text: string | number) => {
        return (
            <div>{text}</div>
        )
    }

    const renderTableTypeCell = (text: string | number) => {
        return (
            <div>{t('alarms.types.' + text as any)}</div>
        )
    }

    const renderActiveColTableCell = (value: boolean) => (
        <label
            className={`${toggleSwitchStyle.toggleSwitch} ${toggleSwitchStyle.enabled}`}>
            <input
                type='checkbox'
                defaultChecked={value ? true : false}
                checked={value}
                onChange={() => null}
            />
            <span className={toggleSwitchStyle.switch} />
        </label>
    )

    const onRowClick = (event: any, id?: string) => {
        if (id) {
            event.preventDefault();
            event.stopPropagation();

            filters.page = criteria.page;
            saveFiltersOnCache(filters);

            if (event.ctrlKey) {
                window.open(history.location.pathname + '/details/' + id, '_blank');
                return;
            }
            navigateTo('details', id);
        }
    }

    const saveFiltersOnCache = (f: Filters) => {
        setFilters(f);
        localStorage.setItem('ALARMSFILTER', JSON.stringify(f));
    }

    const onChangeFilters = (f: Filters, isOnLoad?: boolean) => {
        criteria.allIn = f.allIn;

        if (isOnLoad && f.page) {
            criteria.page = f.page
        } else {
            criteria.page = 1;
            f.page = 1;
        }
        saveFiltersOnCache(f);
        getData();
    }

    const renderSearchInput = () => {
        return <InputSearch
            inputContainerClassName={styles.inputSearchContainerCustomization}
            onChangeValue={debounced}
            placeholder={t('alarms.list.search_by_name')}
            defaultValue={criteria.allIn}
        ></InputSearch>
    }

    const debounced = useDebouncedCallback((value: string) => {
        filters.allIn = value;
        saveFiltersOnCache(filters)
        setCriteria({ ...criteria, page: 1, allIn: value })
    }, 500);

    return (
        <ScreenTitle title={t('alarms.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('alarms.title')}>
                    <Row>
                        <Col xs={12}>{renderSearchInput()}</Col>
                    </Row>
                    {/* <Popover
                        contentContainerClassName={styles.filtersPopoverContainer}
                        containerStyle={{ boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}
                        positions={['bottom', 'left']}
                        align={'end'}
                        onClickOutside={() => updateTotalFilters(filters)}
                        content={setIsPopoverOpen => <AlarmsFilters
                            filters={filters}
                            onFilter={f => { setIsPopoverOpen(false); onChangeFilters(f) }}
                            onChange={f => updateTotalFilters(f)}
                        />}>
                        {(isPopoverOpen, setIsPopoverOpen) => (
                            <ScreenHeaderButton icon={FiltersIcon} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                                {filtersTotal > 0 &&
                                    <div className={styles.counterList}> <div className={styles.counterNumber}>{filtersTotal}</div> </div>
                                }
                            </ScreenHeaderButton>
                        )}
                    </Popover> */}
                    <ScreenHeaderButton icon={AddIcon} onClick={() => navigateTo('new')} />
                </ScreenHeader>
                <Box className={styles.box}>
                    <ListingTable
                        columns={tableColumns}
                        rows={alarms}
                        onRowClick={(row, _, event) => onRowClick(event, row.id)}
                        allowHover={true}
                        onHref={row => `alarms/details/${row.id}`}
                        initialSearch={{ colField: 'st.name', isOrderAsc: true }}
                    />
                    <PaginationWithInfo
                        itemName={t('alarms.list.alarms')}
                        currentPage={criteria.page}
                        pageItems={alarms.length}
                        totalItems={totalItems}
                        onChange={onPageChange}
                    />
                </Box>
            </ScreenContainer>
        </ScreenTitle>
    )
}

export default AlarmsListScreen