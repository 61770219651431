import React, { useRef } from 'react';
import DateTimePicker, { Props as DateTimePickerProps } from './DateTimePicker';
import { FieldName, FieldValues, useController, UseControllerOptions } from 'react-hook-form';
import { FaCalendarAlt } from 'react-icons/fa';
import InputGroup from '../inputGroup/InputGroup';
import ReactDatePicker from 'react-datepicker';
import { DATEPICKER_FORMAT_DEFAULT } from '../../../Config';
import styles from './DateTimePicker.module.scss';

type Props<T> = {
    nameFrom: FieldName<T>;
    nameTo: FieldName<T>;
    placeholderFrom?: string;
    placeholderTo?: string;
} & Omit<DateTimePickerProps, 'selected' | 'onChange' | 'placeholderText'> & Omit<UseControllerOptions<T>, 'name'>;

const DateRangePickerController = <T extends FieldValues>({ control, nameFrom, nameTo, rules, defaultValue, placeholderFrom, placeholderTo, ...props }: Props<T>) => {
    const refFrom = useRef<ReactDatePicker | null>(null);
    const refTo = useRef<ReactDatePicker | null>(null);

    const { field: fieldFrom } = useController({
        name: nameFrom,
        rules,
        defaultValue,
        control,
    });
    const { field: fieldTo } = useController({
        name: nameTo,
        rules,
        defaultValue,
        control,
    });

    return (
        <div className={styles.rangeContainer}>
            <DateTimePicker
                dateFormat={DATEPICKER_FORMAT_DEFAULT}
                {...props}
                ref={refFrom}
                customInput={<InputGroup
                    removeIconOrTextBorderRadius
                    icon={<FaCalendarAlt />}
                    clickInText={true}
                    onClickInText={() => refFrom.current?.setOpen(true)}
                />}
                onChange={date => {
                    fieldFrom.onChange(date);
                }}
                selected={fieldFrom.value}
                startDate={fieldFrom.value}
                endDate={fieldTo.value}
                selectsStart
                placeholderText={placeholderFrom || undefined}
            />
            <DateTimePicker
                dateFormat={DATEPICKER_FORMAT_DEFAULT}
                {...props}
                ref={refTo}
                customInput={<InputGroup
                    removeInputBorderRadius
                    icon={<FaCalendarAlt />}
                    clickInText={true}
                    onClickInText={() => refTo.current?.setOpen(true)}
                />}
                onChange={date => {
                    fieldTo.onChange(date);
                }}
                selected={fieldTo.value}
                startDate={fieldFrom.value}
                endDate={fieldTo.value}
                selectsEnd
                placeholderText={placeholderTo || undefined}
            />
        </div>
    );
};

export default DateRangePickerController;
