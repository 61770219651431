import { VehicleViewModel } from 'api/vehicles/models/VehicleViewModel';
import React from 'react';
import { Types } from 'screens/vehicle/VehicleScreen';
import CarForm from '../carForm/CarForm';

type Props = {
    mode?: string;
    item?: VehicleViewModel;
    onCancel: (value?: Types, id?: any) => void;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const CarDocumentTab: React.FC<Props> = ({ mode, item, onCancel }: Props) => {

    return (
        <div id="third-step">
           {item && <CarForm item={item} mode={mode} onCancel={onCancel} />}
        </div>
    );
}

export default CarDocumentTab;