 import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
 import { RoleSearchCriteria } from './models/RoleSearchCriteria';
 import { RoleDto } from './models/RoleDto';

class RoleService {

   
    public getList(criteria: RoleSearchCriteria) {
        return Http.get<Paged<RoleDto>>('roles', criteria);
    }

    public getById(id: string) {
        return Http.get<RoleDto>('roles/' + id);
    }
  
    public create(model: RoleDto) {
        return Http.post('roles', model);
    }

    public update(model: RoleDto) {
        return Http.put('roles', model);
    }

    public remove(model: RoleDto) {
        return Http.put('roles/deactivate', model);
    }
    
    public getAllForCompany() {
        return Http.get<RoleDto[]>('roles/all');
    }

}

export default new RoleService();