import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { LocalContactDto, LocalDto } from 'api/locals/models/LocalDto';
import Loading from 'common/services/Loading';
import LocalsService from 'api/locals/LocalsService';
import Logger from 'common/services/Logger';
import Button from 'common/components/button/Button';
import styles from './LocalsDetails.module.scss';
import InputError from 'common/components/inputError/InputError';
import Label from 'common/components/label/Label';
import FormItem from 'common/components/formItem/FormItem';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { DEFAULT_EMAIL_RULES, DEFAULT_INPUT_RULES_WITH_REQUIRED, LOGGER_LOG_TYPE } from 'Config';
import GreyArrow from 'assets/svg/Arrow-grey.svg';
import React, { useEffect, useState } from 'react';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { Col, Row } from 'react-flexbox-grid';
import CustomFile from 'common/models/CustomFile';
import InputController from 'common/components/input/InputController';
import MediaSelector from 'common/components/mediaSelector/MediaSelector';
import { useHistory, useParams } from 'react-router-dom';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import { ReactComponent as TrashIcon } from 'assets/svg/trash_no_color.svg';

type Props = {
};

const LocalsDetails: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const [local, setLocal] = useState<LocalDto>();

    const history = useHistory();
    // eslint-disable-next-line
    let { type, itemId } = useParams<{ type: string, itemId: string }>();

    const [itemToRemove, setItemToRemove] = useState<LocalDto>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);

    const form = useForm<LocalDto>({ shouldUnregister: false });
    const localContacts = useFieldArray<LocalContactDto>({ control: form.control, name: 'localContacts', keyName: 'formId' as any });
    const { handleSubmit, errors, reset, control, getValues, setValue } = form;

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasLocalsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_SUPPLIERS_WRITE']);
    const isDetails = type === 'details';
    const [localContactToRemoveIndex, setLocalContactToRemoveIndex] = useState<number | null>(null);
    const [showRemoveLocalContact, setShowRemoveLocalContact] = useState<boolean>(false);
    const [localContactsIdsToRemove, setLocalContactsIdsToRemove] = useState<string[]>([]);
    const [loadedFormValues, setLoadedFormValues] = useState<boolean>(false);

    const logo = useWatch<CustomFile>({ name: 'logo', control });

    const getLocal = async () => {
        if (!itemId) {
            setLoadedFormValues(true);
            return;
        }
        Loading.show();
        try {
            const result = await LocalsService.getById(itemId);
            reset(result);
            setLocal(result);     
            setLoadedFormValues(true);
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), {
                appearance: 'error',
            });

            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                `Couldn't get catalog local types`,
                error
            );
            Loading.hide();
        }
    }

    const showRemoveItemDialog = (item: LocalDto) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await LocalsService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                history.push(`/settings/locals/list`);
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    const onAddMedia = (files: CustomFile[]) => {
        setValue('logo', files[0]);
    }

    const onRemoveMedia = () => {
        setValue('logo', null);
        setValue('removeAttachment', true);
    }

    useEffect(() => {
        getLocal();
    }, []);

    const onSubmit = async (formData: LocalDto) => {
        try {

            if (!formData.name) {
                addToast(t('common.messages.complete_required_fields'), { appearance: 'warning' });
                return;
            }

            Loading.show();

            if (local != null) {
                formData.id = local.id;
                await LocalsService.update(formData, formData.logo ? [formData.logo] : []);
            } else {
                itemId = await LocalsService.create(formData, formData.logo ? [formData.logo] : []);
                getLocal();
            }

            Loading.hide();

            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
            history.goBack();
        } catch (error) {
            Loading.hide();

            if (error?.response?.status === 409) {
                addToast(t('common.messages.name_already_exists'), { appearance: 'warning' });
                return;
            }

            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't create or update local`, error);
        }
    };

    const onGoBack = () => {
        if (local) {
            reset(local);
        }
        history.goBack();        
    }

    const addLocalContact = () => {
        localContacts.append({
            id: null,
            description: '',
            name: undefined,
            email: undefined,
            phoneNumber: undefined
        });
    }

    const setRemoveLocalContact = (index: number) => {
        setShowRemoveLocalContact(true);
        setLocalContactToRemoveIndex(index);
    }

    const removeLocalContact = () => {
        if (localContactToRemoveIndex === null) return;
        
        if (localContacts.fields[localContactToRemoveIndex]?.id) {
            setLocalContactsIdsToRemove([...localContactsIdsToRemove, localContacts.fields[localContactToRemoveIndex].id!]);
        }

        localContacts.remove(localContactToRemoveIndex);

        setShowRemoveLocalContact(false);
    }

    const renderLocalContact = () => {
        return localContacts && localContacts.fields.map((x, i) => {
            return (<Row key={x.formId} className={styles.rowLocalContacts}>
                <Col xs={12} md={12} lg={6} xl={3}>
                    <FormItem>
                        {i == 0 && <Label className={styles.labelLocals}>
                            {t('locals.description')} {!isDetails ? '*' : ''}
                        </Label>}
                        <InputController
                            name={`localContacts[${i}].description`}
                            form={form as any}
                            disabled={isDetails}
                            placeholder={t('locals.description')}
                            rules={{ ...DEFAULT_INPUT_RULES_WITH_REQUIRED }}
                        />
                        {errors.localContacts && errors.localContacts.length > 0 && <InputError error={errors.localContacts[i]?.description} maxLength={250} />}
                    </FormItem>
                </Col>
                <Col xs={12} md={12} lg={6} xl={3}>
                    <FormItem>
                        {i == 0 && <Label className={styles.labelLocals}>
                            {t('locals.name')}
                        </Label>}
                        <InputController
                            name={`localContacts[${i}].name`}
                            form={form as any}
                            disabled={isDetails}
                            placeholder={t('locals.name')}
                        />
                        {!!errors.localContacts && errors.localContacts.length > 0 && <InputError error={errors.localContacts[i]?.name} />}
                    </FormItem>
                </Col>
                <Col xs={12} md={12} lg={6} xl={3}>
                    <FormItem>
                        {i == 0 &&<Label className={styles.labelLocals}>
                            {t('locals.email')}
                        </Label>}
                        <InputController
                            name={`localContacts[${i}].email`}
                            form={form as any}
                            disabled={isDetails}
                            placeholder={t('locals.email')}
                            rules={{ ...DEFAULT_EMAIL_RULES }}
                        />
                        {!!errors.localContacts && errors.localContacts.length > 0 && <InputError error={errors.localContacts[i]?.email} />}
                    </FormItem>
                </Col>
                <Col xs={11} md={11} lg={5} xl={2}>
                    <FormItem>
                        {i == 0 && <Label className={styles.labelLocals}>
                            {t('locals.phone_number')}
                        </Label>}
                        <InputController
                            name={`localContacts[${i}].phoneNumber`}
                            form={form as any}
                            disabled={isDetails}
                            placeholder={t('locals.phone_number')}
                        />
                        {!!errors.localContacts && errors.localContacts.length > 0 && <InputError error={errors.localContacts[i]?.phoneNumber} />}
                    </FormItem>
                </Col>
                {!isDetails && <Col xs={1} style={{ display: 'flex', alignItems: 'center' }}>
                    <TrashIcon className={styles.removeIcon} onClick={() => setRemoveLocalContact(i)} />
                </Col>}
            </Row>)
        })
    }

    return (
        <div>
            <div className={styles.pageNameContainer}>
                <div className={styles.pageNameContent} onClick={() => history.push(`/settings/locals/list`)} >
                    <img className={styles.pageNameContentImage} src={GreyArrow} />
                    <span className={styles.pageNameContentText}>
                        {t('settings.locals')}
                    </span>
                </div>
            </div>

            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col xs={12} lg={8} xl={9}>
                        <Row>
                            <Col xs={12} md={12} lg={12}>
                                <FormItem>
                                    <Label className={styles.label}>
                                        {t('locals.name')} {!isDetails ? '*' : ''}
                                    </Label>
                                    <InputController
                                        name="name"
                                        form={form as any}
                                        disabled={isDetails}
                                        placeholder={t('locals.name')}
                                        rules={{ ...DEFAULT_INPUT_RULES_WITH_REQUIRED }}
                                    />
                                    <InputError error={errors.name} maxLength={250} />
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={4} xl={3}>
                        <FormItem>
                            <Label className={styles.label}>{t('common.image')}</Label>
                            <MediaSelector
                                isMulti={false}
                                isDetails={isDetails}
                                onAddMedias={onAddMedia}
                                onRemoveMedia={onRemoveMedia}
                                initialMedias={logo ? [logo] : []}
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className={styles.containerSmallButton}>
                            <Label className={styles.label}>{t('locals.local_contacts')}</Label>
                            {(!isDetails) && <ScreenHeaderButton icon={AddIcon} onClick={addLocalContact} classNameIcon={styles.iconAdd} />}
                        </div>

                        {localContacts.fields.length === 0 && <div className={styles.noInfoText}>
                            {t('locals.without_local_contacts')}
                        </div>}

                        {loadedFormValues && renderLocalContact()}
                    </Col>
                </Row>
                <div className={styles.buttonContainer}>
                    <Button preset={'secondary'}
                        type='button'
                        text={t('common.cancel')}
                        onClick={() => onGoBack()} />
                    {type === 'details' && hasLocalsWritePolicy &&
                        <Button
                            type="button"
                            text={t('common.remove')}
                            preset={'danger'}
                            onClick={() => showRemoveItemDialog({ id: local?.id } as LocalDto)} />
                    }
                    {type === 'details' && hasLocalsWritePolicy &&
                        <Button
                            type='button'
                            text={t('common.edit')}
                            onClick={() => history.push(`/settings/locals/edit/${itemId}`)} />
                    }
                    {type !== 'details' && hasLocalsWritePolicy &&
                        <Button
                            type='submit'
                            text={t('common.save')} />
                    }

                    <QuestionYesNo message={t('common.messages.remove_local', { local: local?.name ?? '' })}
                        isVisible={dialogDeleteItemIsOpen}
                        onYes={() => removeItem()}
                        onNo={() => setDialogDeleteItemIsOpen(false)} />
                </div>

                <QuestionYesNo
                    message={t('common.messages.remove_record')}
                    isVisible={showRemoveLocalContact}
                    onYes={() => removeLocalContact()}
                    onNo={() => { setShowRemoveLocalContact(false); setLocalContactToRemoveIndex(null) }}
                />
            </form>
        </div>
    );
};

export default LocalsDetails;
