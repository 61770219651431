import Http from 'common/services/Http';
import { AlertNoticeDto } from './models/AlertNoticeDto';

class AlertNoticesService {
    public getAll() {
        return Http.get<AlertNoticeDto[]>('alertnotices/all');
    }
}

export default new AlertNoticesService();
