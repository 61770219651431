
import { useTranslation } from 'react-i18next'
import React, { useEffect, useMemo, useState } from 'react'
import {
    DEFAULT_EMAIL_RULES_WITH_REQUIRED,
    DEFAULT_INPUT_RULES,
    DEFAULT_INPUT_RULES_WITH_REQUIRED,
    LOGGER_LOG_TYPE,
    removeAccents,
} from 'Config'
import { Col, Row } from 'react-flexbox-grid'
import FormItem from 'common/components/formItem/FormItem'
import Label from 'common/components/label/Label'
import Input from 'common/components/input/Input'
import InputError from 'common/components/inputError/InputError'
import styles from './NewAccountScreen.module.scss'
import { useForm } from 'react-hook-form'
import Button from 'common/components/button/Button'
import { useHistory } from 'react-router-dom'
import Loading from 'common/services/Loading';
import { useToasts } from 'react-toast-notifications';
import MediaSelector from 'common/components/mediaSelector/MediaSelector'
import CustomFile from 'common/models/CustomFile'
import { TrackingProvider } from 'api/tracking/enums/TrackingProvider';
import Separator from 'common/components/separator/Separator';
import SelectController from 'common/components/select/SelectController'
import CheckBoxController from 'common/components/checkBox/CheckBoxController'
import ScreenTitle from 'common/components/screenTitle/ScreenTitle'
import ScreenContainer from 'common/components/screenContainer/ScreenContainer'
import ScreenHeader from 'common/components/screenHeader/ScreenHeader'
import Box from 'common/components/box/Box'
import { setCompanyInfo } from '../../store/authentication/action';
import CompaniesService from 'api/companies/CompaniesService';
import Logger from 'common/services/Logger';
import { CompanyDto } from 'api/companies/models/CompanyDto'
import { CompanyFillInfoDto } from 'api/companies/models/CompanyFillInfoDto'
import { useDispatch, useSelector } from 'react-redux'
import { Reducers } from 'store/types'
import { UserProfile } from 'api/account/models/UserProfile'
import { SelectValueLabel } from 'common/types/SelectValueLabel'
import CountriesService from 'api/countries/CountriesService'

type Tab = 'company' | 'driver';

const NewAccountScreen = () => {
    const { t } = useTranslation()
    const form = useForm<CompanyFillInfoDto>({ shouldUnregister: false })
    const [medias, setMedias] = useState<CustomFile[]>([]);
    const [activeTab, setActiveTab] = useState<Tab>('company')
    const [countries, setCountries] = useState<SelectValueLabel[]>([]);
    const { register, handleSubmit, errors, watch, clearErrors } = form;
    const history = useHistory();
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);

    const trackingProvidersList = useMemo(() =>
        Object.keys(TrackingProvider).map(key => {
            const e = (TrackingProvider as any)[key];
            return ({ label: t('common.tracking_providers.' + e as any), value: e });
        })
        , []);


    const getData = async () => {
        try {
            Loading.show();
          
            const coutriesData = await CountriesService.catalog();
            setCountries(coutriesData.map(x=> {
                return { value: x.name, label: (t('common.countries.' + x.name as any)) }
            }));

            const result: CompanyDto = await CompaniesService.getCurrent();
            form.reset(result);
            Loading.hide();

        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't get company information `, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    useEffect(() => {
        getData();
    }, []);



    const onSubmit = async (form: CompanyFillInfoDto) => {
        try {

            if (activeTab !== 'driver') {
                setActiveTab('driver')
                return;
            }
            Loading.show();

            // try {
            //     await CompaniesService.validateVatNumber(form);

            // } catch (error) {                
            //     addToast(t('common.errors.invalid_tax_number'), { appearance: 'warning' });                
            //     Loading.hide();
            //     return;
            // }

            await CompaniesService.fillInfo(form, medias);
            if (userProfile) {
                userProfile.companyInfoFilled = true;
            }
            dispatch(setCompanyInfo(userProfile));
            Loading.hide();
            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
        } catch (error) {
            if (error?.response?.status === 409) {
                addToast(t('common.messages.company_already_exists'), { appearance: 'warning' });
            }
            Loading.hide();
        }
    }

    const onError = async () => {
        addToast(t('common.errors.required_fields_empty'), { appearance: 'warning' });
    };

    const onAddMedias = (medias: CustomFile[]) => {
        setMedias(medias);
    }

    const onRemoveMedia = (mediaId: string) => {
        const filteredMedia = [...medias.filter(x => x.id != mediaId)];
        setMedias(filteredMedia);
    }

    const trackingProvider = form.watch('trackingProvider');

    useEffect(() => {
        clearErrors(['trackingProviderUsername', 'trackingProviderPassword']);
    }, [watch('trackingProvider')]);


    return (
        <ScreenTitle title={t('common.new_registration')}>
            <ScreenContainer>
                <ScreenHeader title={t('common.new_registration')}></ScreenHeader>
                <Box>
                    <div className={styles.steps}>
                        <div className={`${styles.step} ${activeTab === 'company' ? styles.active_step : styles.step}`}>
                            <hr />
                            <span className={styles.label}> {t('common.company')}</span>
                        </div>
                        {/* <div className={`${styles.step} ${activeTab === 'manager' ? styles.active_step : styles.step}`}>
                                <hr />
                                <span className={styles.label}> {t('common.manager')}</span>
                            </div> */}
                        <div className={`${styles.step} ${activeTab === 'driver' ? styles.active_step : styles.step}`}>
                            <hr />
                            <span className={styles.label}> {t('common.driver')}</span>
                        </div>
                    </div>
                    { activeTab === 'company' && <div className={styles.infoMessage}>
                        <span>{t('account.info_message_company')}</span>
                    </div> }
                    { activeTab === 'driver' && <div className={styles.infoMessage}>
                        <span>{t('account.info_message_driver')}</span>
                    </div> }
                    <form onSubmit={handleSubmit(onSubmit, onError)}>
                        {activeTab === 'company' && <Row>
                            <Col xs={12} xl={10}>
                                <Row>
                                    <Col xs={12} md={8} lg={8}>
                                        <FormItem>
                                            <Label className={styles.label}>
                                                {t('companies.name')}
                                                {'*'}
                                            </Label>
                                            <Input
                                                name='name'
                                                placeholder={t('companies.name')}
                                                ref={register({ ...DEFAULT_INPUT_RULES_WITH_REQUIRED })}
                                            />
                                            <InputError error={errors.name} />
                                        </FormItem>
                                    </Col>
                                    <Col xs={12} md={4} lg={4}>
                                        <FormItem>
                                            <Label className={styles.label}>
                                                {t('companies.tax_number')}
                                            </Label>
                                            <Input
                                                name='taxNumber'
                                                placeholder={t('companies.tax_number')}
                                                ref={register({ ...DEFAULT_INPUT_RULES, maxLength: 100 })}
                                            />
                                            <InputError error={errors.taxNumber} />
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={4} lg={4}>
                                        <FormItem>
                                            <Label className={styles.label}>
                                                {t('companies.email')}
                                                {'*'}
                                            </Label>
                                            <Input
                                                name='email'
                                                placeholder={t('companies.email')}
                                                type={'email'}
                                                ref={register({
                                                    ...DEFAULT_EMAIL_RULES_WITH_REQUIRED,
                                                    maxLength: 100,
                                                })}
                                            />
                                            <InputError error={errors.email} />
                                        </FormItem>
                                    </Col>
                                    <Col xs={12} md={4} lg={4}>
                                        <FormItem>
                                            <Label className={styles.label}>{t('companies.phone')}</Label>
                                            <Input
                                                name='phone'
                                                placeholder={t('companies.phone')}
                                                ref={register({ ...DEFAULT_INPUT_RULES, maxLength: 50 })}
                                            />
                                            <InputError error={errors.phone} />
                                        </FormItem>
                                    </Col>
                                    <Col xs={12} md={4} lg={4}>
                                        <FormItem>
                                            <Label className={styles.label}>
                                                {t('companies.website')}
                                            </Label>
                                            <Input
                                                name='website'
                                                placeholder={t('companies.website')}
                                                ref={register({ ...DEFAULT_INPUT_RULES })}
                                            />
                                            <InputError error={errors.website} />
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={4} lg={4}>
                                        <FormItem>
                                            <Label className={styles.label}>
                                                {t('companies.address')}
                                                {'*'}
                                            </Label>
                                            <Input
                                                name='addressLine'
                                                placeholder={t('companies.address')}
                                                ref={register({
                                                    ...DEFAULT_INPUT_RULES_WITH_REQUIRED,
                                                    maxLength: 200,
                                                })}
                                            />
                                            <InputError error={errors.addressLine} />
                                        </FormItem>
                                    </Col>
                                    <Col xs={12} md={4} lg={4}>
                                        <FormItem>
                                            <Label className={styles.label}>
                                                {t('companies.zip_code')}
                                                {'*'}
                                            </Label>
                                            <Input
                                                name='zipCode'
                                                placeholder={t('companies.zip_code')}
                                                ref={register({
                                                    ...DEFAULT_INPUT_RULES_WITH_REQUIRED,
                                                    maxLength: 15,
                                                })}
                                            />
                                            <InputError error={errors.zipCode} />
                                        </FormItem>
                                    </Col>
                                    <Col xs={12} md={4} lg={4}>
                                        <FormItem>
                                            <Label className={styles.label}>{t('companies.city')}</Label>
                                            <Input
                                                name='city'
                                                placeholder={t('companies.city')}

                                                ref={register({ ...DEFAULT_INPUT_RULES })}
                                            />
                                            <InputError error={errors.city} />
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} xl={4}>
                                        <FormItem>
                                            <Label className={styles.bold}>
                                                {t('common.country')}
                                            </Label>
                                            <SelectController
                                                ref={register({ ...DEFAULT_INPUT_RULES_WITH_REQUIRED })}
                                                form={form}
                                                isClearable={true}
                                                name='country'
                                                options={countries}
                                                placeholder={t('common.country')}
                                                rules={{ required: false }}
                                                isDisabled={false}
                                                filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                            />
                                            <InputError error={errors.country} />
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Separator />
                                {/* <Row>
                                        <Col xs={12} md={4} lg={4}>
                                            <FormItem>
                                                <Label className={styles.label}>
                                                    {t('companies.tracking_provider')}
                                                </Label>
                                                <SelectController
                                                    form={form}
                                                    name='trackingProvider'
                                                    menuPortalTarget={document.querySelector('body')}
                                                    options={trackingProvidersList}
                                                    placeholder={t('companies.tracking_provider')}
                                                    isDisabled={false}
                                                    filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                                />
                                                <InputError error={errors.trackingProvider} />
                                            </FormItem>
                                        </Col>
                                        {trackingProvider && trackingProvider !== TrackingProvider.NONE && <>
                                            <Col xs={12} md={4} lg={4}>
                                                <FormItem>
                                                    <Label className={styles.label}>
                                                        {t('companies.tracking_provider_username')}*
                                                    </Label>
                                                    <Input
                                                        name='trackingProviderUsername'
                                                        placeholder={t('companies.tracking_provider_username')}
                                                        ref={register({ ...DEFAULT_INPUT_RULES_WITH_REQUIRED })}
                                                        disabled={false}
                                                    />
                                                    <InputError error={errors.trackingProviderUsername} />
                                                </FormItem>
                                            </Col>
                                            <Col xs={12} md={4} lg={4}>
                                                <FormItem>
                                                    <Label className={styles.label}>
                                                        {t('companies.tracking_provider_password')}*
                                                    </Label>
                                                    <Input
                                                        type="password"
                                                        name='trackingProviderPassword'
                                                        placeholder={t('companies.tracking_provider_password')}
                                                        ref={register({ ...DEFAULT_INPUT_RULES_WITH_REQUIRED })}
                                                        disabled={false}
                                                    />
                                                    <InputError error={errors.trackingProviderPassword} />
                                                </FormItem>
                                            </Col>
                                        </>}
                                    </Row>
                                    <Separator /> */}
                                <Row>
                                    <Col xs={12}>
                                        <FormItem>
                                            <CheckBoxController form={form as any}
                                                name={`useValueWithVat`}
                                                label={t('companies.use_value_with_vat')}
                                                disabled={false} />
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} xl={2}>
                                <MediaSelector
                                    isMulti={false}
                                    isDetails={false}
                                    onAddMedias={onAddMedias}
                                    onRemoveMedia={onRemoveMedia}
                                    initialMedias={medias ?? []}
                                />
                            </Col>
                        </Row>}
                        {/* {activeTab === 'manager' && <Row>
                                <Col xs={12} xl={12}>
                                    <Row>
                                        <Col xs={12}>
                                            <FormItem>
                                                <Label className={styles.label}>
                                                    {t('common.name')}
                                                    {'*'}
                                                </Label>
                                                <Input
                                                    name='managerUsername'
                                                    placeholder={t('companies.name')}
                                                    ref={register({ ...DEFAULT_INPUT_RULES_WITH_REQUIRED })}
                                                />
                                                <InputError error={errors.managerUsername} />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} >
                                            <FormItem>
                                                <Label className={styles.label}>
                                                    {t('companies.email')}
                                                    {'*'}
                                                </Label>
                                                <Input
                                                    name='managerEmail'
                                                    placeholder={t('companies.email')}
                                                    type={'email'}
                                                    ref={register({
                                                        ...DEFAULT_EMAIL_RULES_WITH_REQUIRED,
                                                        maxLength: 100,
                                                    })}
                                                />
                                                <InputError error={errors.managerEmail} />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>} */}
                        {activeTab === 'driver' && <Row>
                            <Col xs={12} xl={12}>
                                <Row>
                                    <Col xs={12} >
                                        <FormItem>
                                            <Label className={styles.label}>
                                                {t('common.name')}
                                                {'*'}
                                            </Label>
                                            <Input
                                                name='driverUserName'
                                                placeholder={t('companies.name')}
                                                ref={register({ ...DEFAULT_INPUT_RULES_WITH_REQUIRED })}
                                            />
                                            <InputError error={errors.driverUserName} />
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} >
                                        <FormItem>
                                            <Label className={styles.label}>
                                                {t('companies.email')}
                                                {'*'}
                                            </Label>
                                            <Input
                                                name='driverEmail'
                                                placeholder={t('companies.email')}
                                                type={'email'}
                                                ref={register({
                                                    ...DEFAULT_EMAIL_RULES_WITH_REQUIRED,
                                                    maxLength: 100,
                                                })}
                                            />
                                            <InputError error={errors.driverEmail} />
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>}
                        <div className={styles.buttons_container}>
                            {activeTab === 'driver' && <Button
                                text={t('common.previous')}
                                className={` ${styles.button}`}
                                onClick={() => {
                                    setActiveTab('company');
                                }}
                            />}
                            {/* {activeTab !== 'driver' && <Button       
                            text={t('common.next')}
                            className={`${styles.alignRight} ${styles.button}`}
                            onClick={() => {activeTab === 'company' ? setActiveTab('manager') : setActiveTab('driver')}}
                        />} */}
                            <Button
                                type='submit'
                                text={activeTab === 'driver' ? t('common.save') : t('common.next')}
                                className={` ${styles.button}`}
                            />
                        </div>
                    </form>
                </Box>
            </ScreenContainer>
        </ScreenTitle>

    )
}

export default NewAccountScreen
