import { Link } from 'react-router-dom';

type Props = {
    children: any;
    href: any
}

const HReafWrap: React.FC<Props> = ({ children, href }: Props) => {
    return href
        ? <Link to={href} style={{ textDecoration:'none',color:'inherit' }}>{children}</Link>
        : children;
}

export default HReafWrap;
