import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import { useToasts } from 'react-toast-notifications';
import { VehicleSegmentDto } from 'api/vehicleSegments/models/VehicleSegmentDto';
import { VehicleSegmentSearchCriteria } from 'api/vehicleSegments/models/VehicleSegmentSearchCriteria';
import Loading from 'common/services/Loading';
import VehicleSegmentsService from 'api/vehicleSegments/VehicleSegmentsService';
import Logger from 'common/services/Logger';
import { Paged } from 'api/common/types/Page';
import styles from './VehicleSegmentsList.module.scss';
import InputSearch from 'common/components/inputSearch/InputSearch';
import { useDebouncedCallback } from 'use-debounce';
import Dropdown from 'common/components/dropdown/Dropdown';
import DropdownItem from 'common/components/dropdown/DropdownItem';
import { FaEllipsisH } from 'react-icons/fa';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { useHistory } from 'react-router-dom';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import Utils from 'common/services/Utils';

type Props = {
};

const VehicleSegmentsList: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const history = useHistory();

    const [vehicleSegmentsPage, setVehicleSegmentsPage] = useState<Paged<VehicleSegmentDto>>();
    const [criteria, setCriteria] = useState<VehicleSegmentSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderColumn: 'name',
        orderBy: 'asc'
    });

    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<VehicleSegmentDto | null>(null);

    const showRemoveItemDialog = async (item: VehicleSegmentDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasVehicleSegmentsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_VEHICLE_SEGMENTS_WRITE']);

    const renderFeaturesCell = (row: VehicleSegmentDto) => {
        if (!row.features?.length) {
            return <></>;
        }
        const translatedFeatures = row.features.map(feature => t(`vehicle_segments.feature_options.${feature}` as any));
        const sortedFeatures = Utils.sortStringsAlphabetically(translatedFeatures);
        return <>
            {sortedFeatures.join(', ')}
        </>;
    }

    const renderTableActionCell = (row: VehicleSegmentDto) => {
        return <Dropdown
            options={
                <>
                    <DropdownItem url={`/settings/segments/details/${row.id ?? ''}`}>
                        {t('common.details')}
                    </DropdownItem>
                    {hasVehicleSegmentsWritePolicy && <DropdownItem url={`/settings/segments/edit/${row.id ?? ''}`}>
                        {t('common.edit')}
                    </DropdownItem>}
                    {hasVehicleSegmentsWritePolicy && <DropdownItem onClick={() => showRemoveItemDialog(row)}>
                        {t('common.remove')}
                    </DropdownItem>}
                </>
            }
        >
            <div>
                <FaEllipsisH />
            </div>
        </Dropdown>
    };

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const tableColumns: ListingTableColumn<VehicleSegmentDto>[] = [
        {
            field: 'name',
            name: t('vehicle_segments.list.name'),
            onSearch: onTableFilter,
            searchField: 'vs.name'
        },
        {
            field: 'features',
            name: t('vehicle_segments.features'),
            // onSearch: onTableFilter,
            // searchField: 'vs.name',
            renderCell: renderFeaturesCell
        },
        {
            name: '',
            width: '60px',
            cellAlignment: 'right',
            preventClick: true,
            renderCell: renderTableActionCell,
            cellStyle: { overflow: 'unset' }
        }
    ];

    const getData = async () => {
        try {
            Loading.show();

            const page = await VehicleSegmentsService.getList(criteria);
            setVehicleSegmentsPage(page);

            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't get vehicle segments list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }

        try {
            await VehicleSegmentsService.remove(itemToRemove);
            setCriteria({ ...criteria, page: 1 });
            onCancelRemove();
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete vehicle segments`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
        }
    };

    useEffect(() => {
        getData();
    }, [criteria]);

    const debounced = useDebouncedCallback((value: string) => {
        setCriteria({ ...criteria, page: 1, allIn: value });
    }, 500);

    return (
        <div className='list'>
            <div className={styles.tabHeader}>
                <div>
                    <InputSearch onChangeValue={debounced} placeholder={t('common.search')} ></InputSearch>
                </div>
                <div>
                    {hasVehicleSegmentsWritePolicy && <ScreenHeaderButton icon={AddIcon} onClick={() => history.push(`/settings/segments/new`)} />}
                </div>
            </div>
            <ListingTable
                columns={tableColumns}
                rows={vehicleSegmentsPage?.items || []}
                onRowClick={row => history.push(`/settings/segments/details/${row.id ?? ''}`)}
                onHref={row => `/settings/segments/details/${row.id ?? ''}`}
                allowHover={true}
                initialSearch={{ colField: 'vs.name', isOrderAsc: true }}
            />
            <PaginationWithInfo
                itemName={t('vehicle_segments.list.vehicle_segments')}
                currentPage={vehicleSegmentsPage?.currentPage || 1}
                pageItems={vehicleSegmentsPage?.items.length || 0}
                totalItems={vehicleSegmentsPage?.totalItems || 0}
                onChange={page => setCriteria({ ...criteria, page })}
            />

            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('common.messages.remove_vehicle_segment', { segment: itemToRemove?.name ?? '' })}/>
        </div>
    );
};

export default VehicleSegmentsList;
