import Button from 'common/components/button/Button';
import DateRangePickerController from 'common/components/dateTimePicker/DateRangePickerController';
import FormItem from 'common/components/formItem/FormItem';
import Label from 'common/components/label/Label';
import SelectController from 'common/components/select/SelectController';
import ToggleSwitch from 'common/components/toggleSwitch/ToggleSwitch';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import dayjs from 'dayjs';
import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styles from './FiltersView.module.scss';

export const timePeriodToDates = (timePeriod: TimePeriod) => {
    if (timePeriod === TimePeriod.CURRENT_MONTH) {
        return [dayjs().startOf('month').toDate(), dayjs().endOf('month').toDate()];
    } else if (timePeriod === TimePeriod.PREVIOUS_MONTH) {
        return [
            dayjs().subtract(1, 'months').startOf('month').toDate(),
            dayjs().subtract(1, 'months').endOf('month').toDate(),
        ];
    } else if (timePeriod === TimePeriod.PREVIOUS_3_MONTHS) {
        return [
            dayjs().subtract(3, 'months').startOf('month').toDate(),
            dayjs().subtract(1, 'months').endOf('month').toDate(),
        ];
    }
    return [null, null];
};

export enum TimePeriod {
    CURRENT_MONTH = 'CURRENT_MONTH',
    PREVIOUS_MONTH = 'PREVIOUS_MONTH',
    PREVIOUS_3_MONTHS = 'PREVIOUS_3_MONTHS',
    OTHER = 'OTHER',
}

export type FiltersFormModel = {
    dateFrom: Date | null;
    dateTo: Date | null;
    vehicleId: string | null;
    timePeriod: TimePeriod | null;
    inactives: boolean | null;
};

export type FiltersProps = {
    onFilter: (model: FiltersFormModel) => void;
    filters: FiltersFormModel;
} & (
        { canSelectVehicle: true; vehicles: SelectValueLabel[]; } |
        { canSelectVehicle: false; vehicles?: undefined }
    )

const FiltersView = ({ onFilter, vehicles, filters, canSelectVehicle }: FiltersProps) => {
    const { t } = useTranslation();
    const form = useForm<FiltersFormModel>({
        defaultValues: filters,
    });
    const { handleSubmit, control, reset, setValue } = form;
    const timePeriodWatch = useWatch<TimePeriod>({ name: 'timePeriod', control });

    const onSubmit = (model: FiltersFormModel) => {
       onFilter(model);
    };

    const onTimePeriodChange = (timePeriod: TimePeriod) => {
        const [startDate, endDate] = timePeriodToDates(timePeriod);
        setValue('dateFrom', startDate);
        setValue('dateTo', endDate);
    };

    const onClearFilters = () => {
        const [startDate, endDate] = timePeriodToDates(TimePeriod.CURRENT_MONTH);
        const tempModel: FiltersFormModel = {
            dateFrom: startDate,
            dateTo: endDate,
            timePeriod: TimePeriod.CURRENT_MONTH,
            vehicleId: null,
            inactives: true
        };
        reset(tempModel);
        onFilter(tempModel);
    }

    const timePeriods: SelectValueLabel[] = Object.keys(TimePeriod)
        .filter(key => typeof key === 'string')
        .map(key => {
            return { value: key, label: t(('dashboard.time_periods.' + key) as any) };
        });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.formContent}>
                <FormItem>
                    <Label className={styles.bold}>{t('dashboard.filters.period_of_times')}</Label>
                    <SelectController
                        form={form}
                        isDisabled={false}
                        name="timePeriod"
                        placeholder={t('dashboard.filters.period_of_times')}
                        options={timePeriods}
                        onChange={tp => onTimePeriodChange((TimePeriod as any)[tp!.value])}
                    />
                </FormItem>

                <FormItem>
                    <Label className={styles.bold}>{t('dashboard.filters.dates')}</Label>
                    <DateRangePickerController
                        control={control}
                        nameFrom="dateFrom"
                        nameTo="dateTo"

                        dateFormat="dd/MM/yyyy"
                        placeholderFrom={t('common.range.from')}
                        placeholderTo={t('common.range.to')}
                        disabled={timePeriodWatch && timePeriodWatch !== TimePeriod.OTHER}
                    />
                </FormItem>

                {canSelectVehicle && <FormItem>
                    <Label className={styles.bold}>{t('dashboard.filters.vehicle')}</Label>
                    <SelectController
                        form={form}
                        isDisabled={false}
                        name="vehicleId"
                        placeholder={t('dashboard.filters.vehicle')}
                        options={vehicles || []}
                        isClearable
                    />
                </FormItem>}

                {canSelectVehicle && <FormItem>
                    <Label className={styles.bold}>{t('vehicle.vehicle_inactives')}</Label>
                    <ToggleSwitch
                        name="inactives"
                        form={form}
                        />
                </FormItem>}

                <div className={styles.buttonsFooter}>
                    <FormItem>
                        <Button
                            text={t('common.remove')}
                            size={'normal'}
                            preset={'secondary'}
                            onClick={onClearFilters}
                            type='reset' />
                        <Button
                            type='submit'
                            text={t('common.apply')}
                            size={'normal'}
                        />
                    </FormItem>
                </div>
            </div>
        </form>
    );
}

export default FiltersView;