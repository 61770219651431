 import Http from '../../common/services/Http';
 import { CommunicationDto } from './models/CommunicationDto';

class CommunicationService {

    public getAllForCompany() {
        return Http.get<CommunicationDto[]>(`communications`);
    }

    public update(model: CommunicationDto[]) {
        return Http.put('communications', { communications : model });
    }

}

export default new CommunicationService();