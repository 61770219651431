import { AccidentDto } from 'api/accidents/models/AccidentDto';
import { ChargesSearchCriteria } from 'api/charges/models/ChargesSearchCriteria';
import Button from 'common/components/button/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import ListAccidentsScreen from 'screens/vehicle/components/carAccidentTab/components/AccidentList/ListAccidentsScreen';
import AccidentScreen from 'screens/vehicle/components/carAccidentTab/components/AccidentScreen';
import { Types } from 'screens/vehicle/VehicleScreen';
import styles from './DriverAccidentTab.module.scss';

enum ScreenType {
    LIST = 'LIST',
    ACCIDENT = 'ACCIDENT'
}

type Props = {
    criteriaProp?: ChargesSearchCriteria;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const DriverAccidentTab: React.FC<Props> = ({ criteriaProp }: Props) => {
    const history = useHistory();
    const { driverId, type, tabId, itemId, itemType } = useParams<{ driverId: string, type: Types, tabId: string, itemType: string, itemId: string }>();

    const { t } = useTranslation();

    const onCancel = () => {
        //history.goBack();        
        history.push(`/drivers`);
     };

    return (
        <div className={styles.container}>
            {itemType === ScreenType.LIST.toString().toLowerCase() &&
                <ListAccidentsScreen
                    editable={true}
                    onClickItem={(item: AccidentDto, isDetails: boolean) => history.push(`/drivers/details/${driverId}/${tabId}/${isDetails ? 'details' : 'edit'}/${item.id}`)}
                    isDriver
                    criteriaProp={criteriaProp} />}
            {itemType !== ScreenType.LIST.toString().toLowerCase() &&
                <AccidentScreen
                    editable={true}
                    isDetails={itemType === 'details'}
                    onBackList={() => history.goBack()}
                    onItemDetails={(state: boolean) => history.push(`/drivers/details/${driverId}/${tabId}/${state ? 'details' : 'edit'}/${itemId}`)}
                    isDriver />}

            <div className={styles.buttonContainer}>
                <Button preset={'secondary'}
                    type='button'
                    text={t('common.cancel')}
                    onClick={() => onCancel()} />
            </div>
        </div>
    );
}

export default DriverAccidentTab;