import { SelectValueLabel } from 'common/types/SelectValueLabel';
import Http from 'common/services/Http';
import { Paged } from 'api/common/types/Page';
import { CatalogDriversSearchCriteria } from 'api/catalog/models/CatalogDriversSearchCriteria';
import { DriversListItemDto } from './models/DriversListItemDto';
import { DriversSearchCriteria } from './models/DriversSearchCriteria';
import { DriversTotalsDto } from './models/DriversTotalsDto';

class DriversService {

    public catalog(criteria?: CatalogDriversSearchCriteria) {
        return Http.get<SelectValueLabel[]>('drivers/catalog', criteria);
    }

    public getList(criteria: DriversSearchCriteria) {
        return Http.get<Paged<DriversListItemDto>>('drivers', criteria);
    }

    public getById(id: string) {
        return Http.get<DriversListItemDto>('drivers/' + id);
    }

    public getTotals(criteria?: DriversSearchCriteria) {
        return Http.get<DriversTotalsDto>('drivers/totals', criteria || { page: 1, itemsPerPage: 9999999 });
    }
}

export default new DriversService();
