import DriversService from 'api/drivers/DriversService';
import VehiclesService from 'api/vehicles/VehiclesService';
import Button from 'common/components/button/Button';
import FormItem from 'common/components/formItem/FormItem';
import Input from 'common/components/input/Input';
import Label from 'common/components/label/Label';
import RangeInputPicker from 'common/components/rangeInputPicker/RangeInputPicker';
import Select from 'common/components/select/Select';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import Utils from 'common/services/Utils';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { BLAMING_COLORS, LOGGER_LOG_TYPE } from 'Config';
import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import styles from './AccidentFiltersScreen.module.scss';

export interface Filters {
    vehicleId?: string;
    startDate?: Date | null;
    endDate?: Date | null;
    driverId?: string;
    guilty?: boolean;
    allIn?: string;
    vehicleName?: string;
    page?:number;
}

type Props = {
    filters: Filters;
    isDriver?: boolean;
    onChange: (filters: Filters) => void;
    onFilter: (filters: Filters) => void;
    isVehicle?: boolean;
}

const AccidentFiltersScreen: React.FC<Props> = ({ filters, isDriver, onChange, onFilter, isVehicle }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { driverId } = useParams<{ driverId: string }>();

    const form = useForm<Filters>({ shouldUnregister: false, shouldFocusError: true, defaultValues: filters });
    const { register, reset, getValues, setValue, watch, handleSubmit, control } = form;

    const [driversOptions, setDriversOptions] = useState<SelectValueLabel[]>([]);
    const [vehiclesOptions, setVehicles] = useState<SelectValueLabel[]>([]);

    const getData = async () => {
        try {
            Loading.show();
            const [vehicles, drivers] = await Promise.all([
                VehiclesService.catalog(),
                DriversService.catalog()
            ]);
            setVehicles(vehicles);
            setDriversOptions(drivers);

            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.ACCIDENTS, `Couldn't get information to fill accidents list filters`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const clearFilters = () => {
        reset({
            startDate: undefined,
            endDate: undefined,
            guilty: undefined,
            allIn: undefined,
            driverId: isDriver && !Utils.isStringNullOrEmpty(driverId) ? driverId : undefined,
            vehicleId: undefined
        })
        onInputChange();
        onSubmit(getValues());
    }

    const onSubmit = (filters: Filters) => {
        onFilter({ ...filters, driverId: isDriver && !Utils.isStringNullOrEmpty(driverId) ? driverId : filters.driverId });
    }

    const onInputChange = () => {
        onChange(getValues());
    }

    const renderBlaming = (onChange: (...event: any[]) => void, valueController: boolean, toSetValue: boolean, text: string) => {
        return <div onClick={() => onChange(valueController === toSetValue ? undefined : toSetValue)} style={{ width: '50%' }}>
            <div className={styles.labelSpinner}>
                <div className={`${(valueController === false && !toSetValue) || (valueController === true && toSetValue) ? styles.selected : styles.notSelected}`}
                    style={{ backgroundColor: ((valueController === false && !toSetValue) ? BLAMING_COLORS.NOT_GUILTY : (valueController === true && toSetValue) ? BLAMING_COLORS.GUILTY : '#fff') }}>
                    <span>{text}</span>
                </div>
            </div>
        </div>
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.formContent}>
                <div>
                    <FormItem>
                        <Row>
                            <Col xs={12}>
                                <RangeInputPicker
                                    label={t('common.date')}
                                    onChange={(startValue: any, endValue: any) => {
                                        setValue('startDate', startValue ? moment(startValue).toDate() : undefined);
                                        setValue('endDate', endValue ? moment(endValue).toDate() : undefined);
                                        onInputChange();
                                    }}
                                    defaultStartValue={getValues('startDate')}
                                    defaultEndValue={getValues('endDate')}
                                    isDatePicker
                                />
                            </Col>
                        </Row>
                        <Row>
                            {!isVehicle && <Col xs={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('vehicle.vehicle')}</Label>
                                    <Controller
                                        render={({ onChange, value }) => {
                                            return (
                                                <Select
                                                    options={vehiclesOptions}
                                                    isClearable
                                                    placeholder={t('vehicle.vehicle')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        setValue('vehicleName', vehiclesOptions.find(x => x.value == data?.value)?.label ?? null);
                                                        onChange(data?.value ?? null);
                                                        onInputChange();
                                                    }}
                                                    value={vehiclesOptions.find(x => x.value === value) ? { label: vehiclesOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                                />
                                            );
                                        }}
                                        control={control}
                                        name="vehicleId"
                                        defaultValue={getValues('vehicleId')} />
                                </FormItem>
                            </Col>}
                            {!isDriver && <Col xs={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('accidents.list.driver')}</Label>
                                    <Controller
                                        render={({ onChange, value }) => {
                                            return (
                                                <Select
                                                    options={driversOptions}
                                                    isClearable
                                                    placeholder={t('accidents.list.driver')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        onChange(data?.value ?? null);
                                                        onInputChange();
                                                    }}
                                                    value={driversOptions.find(x => x.value === value) ? { label: driversOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                                />
                                            );
                                        }}
                                        control={control}
                                        name="driverId"
                                        defaultValue={getValues('driverId')} />
                                </FormItem>
                            </Col>}
                            <Col xs={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('accidents.accident.guilt')}</Label>
                                    <Controller
                                        render={({ onChange, value }) => {
                                            return (
                                                <div className={styles.groupSpinner}>
                                                    <div className={styles.spinner}>
                                                        {renderBlaming(onChange, value, false, t('accidents.guilt_types.innocent'))}
                                                        {renderBlaming(onChange, value, true, t('accidents.guilt_types.guilty'))}
                                                    </div>
                                                </div>
                                            );
                                        }}
                                        control={control}
                                        name="guilty"
                                        defaultValue={getValues('guilty')} />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('accidents.list.text_fields_search')}</Label>
                                    <Input
                                        name="allIn"
                                        placeholder={t('accidents.list.text_fields_search')}
                                        ref={register()}
                                        autoComplete='off'
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                    </FormItem>
                </div>
                <div className={styles.buttonsFooter}>
                    <FormItem>
                        <Button
                            text={t('common.remove')}
                            size={'normal'}
                            preset={'secondary'}
                            onClick={clearFilters}
                            type='reset' />
                        <Button
                            type='submit'
                            text={t('common.apply')}
                            size={'normal'}
                        />
                    </FormItem>
                </div>
            </div>
        </form>
    );
}

export default memo(AccidentFiltersScreen);
