import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { VehicleBrandDto } from 'api/vehicleBrands/models/VehicleBrandsDto';
import Loading from 'common/services/Loading';
import VehicleBrandsService from 'api/vehicleBrands/VehicleBrandsService';
import Logger from 'common/services/Logger';
import Button from 'common/components/button/Button';
import styles from './VehicleBrandsDetails.module.scss';
import InputError from 'common/components/inputError/InputError';
import Input from 'common/components/input/Input';
import Label from 'common/components/label/Label';
import FormItem from 'common/components/formItem/FormItem';
import { Controller, useForm } from 'react-hook-form';
import { DEFAULT_INPUT_RULES_WITH_REQUIRED, LOGGER_LOG_TYPE } from 'Config';
import GreyArrow from 'assets/svg/Arrow-grey.svg';
import { useEffect, useState } from 'react';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { Col, Row } from 'react-flexbox-grid';
import Select from 'common/components/select/Select';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import VehicleSegmentsService from 'api/vehicleSegments/VehicleSegmentsService';

type Props = {
};

const VehicleBrandsDetails: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const history = useHistory();
    // eslint-disable-next-line
    let { type, itemId } = useParams<{ type: string, itemId: string }>();
    const isDetails = type === 'details';

    const [vehicleBrand, setVehicleBrand] = useState<VehicleBrandDto | null>(null);
    const [vehicleSegments, setVehicleSegments] = useState<SelectValueLabel[]>([]);

    const [itemToRemove, setItemToRemove] = useState<VehicleBrandDto>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);

    const { register, handleSubmit, errors, reset, control, getValues } = useForm<VehicleBrandDto>();

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasVehicleBrandsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_VEHICLE_BRANDS_WRITE']);

    const onSubmit = async (formData: VehicleBrandDto) => {
        try {
            if (!hasVehicleBrandsWritePolicy) { return; }

            Loading.show();

            if (vehicleBrand != null) {
                vehicleBrand.name = formData.name;
                vehicleBrand.segments = formData.segments;
                await VehicleBrandsService.update(vehicleBrand);
            } else {
                itemId = await VehicleBrandsService.create(formData);
                getData();
            }

            Loading.hide();
            addToast(t('common.messages.record_save_success'), {
                appearance: 'success',
            });

            history.push(`/settings/brands/details/${itemId}`);
        } catch (error) {
            Loading.hide();

            if (error?.response?.status === 409) {
                addToast(t('common.messages.name_already_exists'), { appearance: 'warning' });
                return;
            }

            addToast(t('common.messages.record_save_error'), {
                appearance: 'error',
            });

            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                `Couldn't create or update vehicle brand`,
                error
            );
        }
    };

    const getData = async () => {
        try {
            Loading.show();
            const segments = await VehicleSegmentsService.catalog();
            setVehicleSegments(segments);

            if (itemId) {
                const result = await VehicleBrandsService.getById(itemId);

                setVehicleBrand(result);
                reset(result);
            }
        }
        catch (error) {
            addToast(t('common.messages.error_load_info'), {
                appearance: 'error',
            });

            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                `Couldn't get vehicle brand`,
                error
            );
        }
        finally{
            Loading.hide();
        }
    }

    const showRemoveItemDialog = (item: VehicleBrandDto) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await VehicleBrandsService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                history.push(`/settings/brands/list`);
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    useEffect(() => {
        getData();
    }, []);


    const onGoBack = () => {
        if(vehicleBrand){
            reset(vehicleBrand);
        }
        history.goBack();
    }

    return (
        <div>
            <div className={styles.pageNameContainer}>
                <div className={styles.pageNameContent} onClick={() => history.push(`/settings/brands/list`)} >
                    <img className={styles.pageNameContentImage} src={GreyArrow} />
                    <span className={styles.pageNameContentText}>
                        {t('settings.vehicle_brands')}
                    </span>
                </div>
            </div>

            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col xs={12} md={6}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('vehicle_brands.name')} {type !== 'details' ? '*' : ''}
                            </Label>
                            <Input
                                name='name'
                                placeholder={t('vehicle_brands.name')}
                                defaultValue={vehicleBrand?.name}
                                ref={register({ ...DEFAULT_INPUT_RULES_WITH_REQUIRED })}
                                disabled={type === 'details'}
                            />
                            <InputError error={errors.name} maxLength={250} />
                        </FormItem>
                    </Col>

                    <Col xs={12} md={6}>
                        <FormItem>
                            <Label className={styles.label}>{t('vehicle_segments.title')}</Label>
                            <Controller
                                render={({ onChange, value }) => {
                                    return (
                                        <Select
                                            options={vehicleSegments}
                                            isClearable
                                            isMulti
                                            isDisabled={isDetails}
                                            placeholder={t('vehicle_segments.title')}
                                            onChange={(data: SelectValueLabel[]) => {
                                                onChange(data?.map(x => ( x.value )));
                                            }}
                                            value={value ? vehicleSegments.filter(x => value.find((y: any) => y === x.value)) : null}
                                        />
                                    );
                                }}
                                control={control}
                                name="segments"
                                defaultValue={getValues('segments')} />
                            <InputError error={errors.segments as any} />
                        </FormItem>
                    </Col>
                </Row>

                <div className={styles.buttonContainer}>
                    <Button preset={'secondary'}
                        type='button'
                        text={t('common.cancel')}
                        onClick={() => onGoBack()} />
                    {type === 'details' && hasVehicleBrandsWritePolicy &&
                        <Button
                            type="button"
                            text={t('common.remove')}
                            preset={'danger'}
                            onClick={() => showRemoveItemDialog({ id: vehicleBrand?.id } as VehicleBrandDto)} />
                    }
                    {type === 'details' && hasVehicleBrandsWritePolicy && <Button type='button' text={t('common.edit')} onClick={() => history.push(`/settings/brands/edit/${itemId}`)} />}
                    {type !== 'details' && hasVehicleBrandsWritePolicy && <Button type='submit' text={t('common.save')} />}

                    <QuestionYesNo message={t('common.messages.remove_vehicle_brand', { vehicleBrand: vehicleBrand?.name ?? '' })}
                        isVisible={dialogDeleteItemIsOpen}
                        onYes={() => removeItem()}
                        onNo={() => setDialogDeleteItemIsOpen(false)} />
                </div>
            </form>
        </div>
    );
};

export default VehicleBrandsDetails;
