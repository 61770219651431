import React, { ReactElement } from 'react';
import styles from './FilterBadge.module.scss';
import { FaTimesCircle } from 'react-icons/fa';

type BadgePreset = 'primaryLight' | 'primaryDark' | 'primaryLightInverted' | 'inactive';

type Props = {
    title?: string;
    value?: string | ReactElement;
    preset?: BadgePreset;
    onRemoveFilter: () => void;
    showRemoveFilter?: boolean;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

const FilterBadge: React.FC<Props> = ({ children, title, value, preset = 'primaryDark', onRemoveFilter, showRemoveFilter = true, ...props }: Props) => {

    const presetClassName = preset ? styles[preset] : '';

    return (
        <span
            {...props}
            className={`${styles.badge} ${presetClassName} ${props.className || ''}`} 
        >
            {Boolean(title) && <span className={styles.title}>{title}:&nbsp;</span>}
            {Boolean(value) && <span className={styles.value}>{value}</span>}
            {children}
            { showRemoveFilter && <FaTimesCircle className={styles.removeButton} onClick={onRemoveFilter} /> }
        </span>
    );
}

export default FilterBadge;
