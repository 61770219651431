import React from 'react';
import styles from './FormController.module.scss';

type Props = {
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const FormController: React.FC<Props> = ({ children, ...props }: Props) => {

    return (
        <div
            {...props}
            className={`${styles.formController} ${props.className || ''}`}
        >
            {children}
        </div>
    );
}

export default FormController;
