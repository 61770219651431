import { AccidentDto } from 'api/accidents/models/AccidentDto';
import { VehicleViewModel } from 'api/vehicles/models/VehicleViewModel';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Types } from 'screens/vehicle/VehicleScreen';
import AccidentScreen from '../carAccidentTab/components/AccidentScreen';
import ListAccidentsScreen from './components/AccidentList/ListAccidentsScreen';
import styles from './CarAccidentTab.module.scss';

enum ScreenType {
    LIST = 'LIST',
    ACCIDENT = 'ACCIDENT'
}

type Props = {
    vehicle?: VehicleViewModel;
    tourReRender: () => void;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const CarAccidentTab: React.FC<Props> = ({ vehicle, tourReRender }: Props) => {

    const { vehicleId, tabId, itemId, itemType } = useParams<{ vehicleId: string, type: Types, tabId: string, itemType: string, itemId: string }>();
    const history = useHistory();

    return (
        <div className={styles.container}>
            {(itemType??'list') === ScreenType.LIST.toString().toLowerCase() &&
                <ListAccidentsScreen
                    editable={ !(vehicle && !vehicle.active) }
                    isVehicle
                    createNewAccident={() => {
                        history.push(`/vehicles/details/${vehicleId}/${tabId}/new`);
                    }}
                    onClickItem={(item: AccidentDto, isDetails: boolean) => {
                        history.push(`/vehicles/details/${vehicleId}/${tabId}/${isDetails ? 'details' : 'edit'}/${item.id}`);
                    }}
                    tourReRender={tourReRender}
                >
                </ListAccidentsScreen>}

            {(itemType??'list') !== ScreenType.LIST.toString().toLowerCase() &&
                <AccidentScreen
                    editable={ !(vehicle && !vehicle.active) }
                    isDetails={itemType === 'details'}
                    isVehicle
                    onBackList={() => history.goBack() }
                    onItemDetails={(state: boolean) => history.push(`/vehicles/details/${vehicleId}/${tabId}/${state ? 'details' : 'edit'}/${itemId}`)} />}
        </div>
    );
}

export default CarAccidentTab;