export enum InterventionTypeEnum {
    INTERVENTION_URGENT = 'INTERVENTION_URGENT',
    INTERVENTION = 'INTERVENTION',
    INFORMATION = 'INFORMATION',
}

export enum InterventionTypeColorEnum {
    INTERVENTION_URGENT = '#DC4C4C', //red
    INTERVENTION = '#1781A1', //yellow
    INFORMATION = '#E2B434', //blue
}
