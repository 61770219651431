import CustomFile from 'common/models/CustomFile';
import { blobToBase64 } from 'Config';
import React from 'react';
import { FaRegSave } from 'react-icons/fa';
import ReactPainter from 'react-painter';
import styles from './ImageEditor.module.scss';

type Props = {
    image: CustomFile;
    saveFile?: (image: CustomFile) => void;
    canEdit?: boolean;
    isDetails?: boolean;
    width?: number;
    height?: number;
};

const ImageEditor: React.FC<Props> = ({ image, width, height, canEdit = false, isDetails = false, saveFile }: Props) => {
    const onSave = async (blob: Blob) => {
        if (!saveFile) return;

        const img: CustomFile = new File([blob], image.name) as any;
        img.id = image.id;
        img.key = image.key;
        img.url = image.url;
        img.urlBase64 = await blobToBase64(img);
        img.containerName = image.containerName;
        img.width = image.width;
        img.height = image.height;
        img.toRemove = image.toRemove;

        saveFile(img);
    }

    const url = image.urlBase64 ?? '';

    const renderEdit = (): JSX.Element => {
        return <ReactPainter
            onSave={onSave}
            image={url}
            width={width}
            height={height}
            render={({ triggerSave, canvas, setColor, setLineWidth }) => (
                <div className={styles.editorContent} style={{ width: width }}>
                    {/* <div className={styles.options}>
                        <input type="color" onChange={e => setColor(e.target.value)} />
                        <div className={styles.sizeContainer}>
                            <div className={styles.sizeContentXs} />
                            <div className={styles.sizeContentM} />
                            <div className={styles.sizeContentL} />
                            <div className={styles.sizeContentXl} />
                        </div>
                        <input type="number" onChange={e => setLineWidth(e.target.value as any)} />
                    </div> */}
                    <FaRegSave size={30} className={styles.editorSave} onClick={e => { e.preventDefault(); e.stopPropagation(); triggerSave(); }} />
                    <div>{canvas}</div>
                </div>
            )}
        />
    }

    const renderDetails = (): JSX.Element => {
        return <div className={styles.imagePreview} style={{ backgroundImage: `url('${url}')` }} />
    }

    return (<>
        {canEdit && !isDetails ? renderEdit() : renderDetails()}
    </>);
}

export default ImageEditor;