import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CompanyFiltersScreen.module.scss';
import Button from '../../../common/components/button/Button';
import FormItem from '../../../common/components/formItem/FormItem';
import Label from '../../../common/components/label/Label';
import { Col, Row } from 'react-flexbox-grid';
import { useForm } from 'react-hook-form';
import Input from 'common/components/input/Input';

export interface CompanyFilters {
    name?: string;
    email?: string;
    taxNumber?: string;    
}

type Props = {
    filters: CompanyFilters;
    onChange: (filters: CompanyFilters) => void;
    onFilter: (filters: CompanyFilters) => void;
}

const CompanyFiltersScreen: React.FC<Props> = ({ filters, onChange, onFilter }: Props) => {
    const { t } = useTranslation();

    const form = useForm<CompanyFilters>({ shouldUnregister: false, shouldFocusError: true, defaultValues: filters });
  
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [taxNumber, setTaxNumber] = useState<string>('');
   
    const clearFilters = () => {
        form.reset();
        form.setValue('name', undefined);
        form.setValue('email', undefined);
        form.setValue('taxNumber', undefined);
        onInputChange();
        onSubmit(form.getValues());
    }

    const onSubmit = (filters: CompanyFilters) => {
        onFilter(filters);
    }

    const onInputChange = () => {
        onChange(form.getValues());
    }

    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className={styles.formContent}>
                <div>
                    <FormItem>
                        <Row>
                            <Col xs={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('companies.name')}</Label>
                                    <Input
                                        maxLength={255}
                                        name="name"
                                        onKeyUp={e => {
                                            form.setValue('name', e.currentTarget.value != '' ? e.currentTarget.value : null);
                                            onInputChange();
                                        }}
                                        placeholder={t('companies.name')}
                                        autoComplete='off' 
                                        defaultValue={form.watch('name')}/>
                                </FormItem>
                            </Col>
                            <Col xs={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('companies.email')}</Label>
                                    <Input
                                        maxLength={255}
                                        name="email"
                                        onKeyUp={e => {
                                            form.setValue('email', e.currentTarget.value != '' ? e.currentTarget.value : null);
                                            onInputChange();
                                        }}
                                        placeholder={t('companies.email')}
                                        autoComplete='off' 
                                        defaultValue={form.watch('email')}/>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('companies.tax_number')}</Label>
                                    <Input
                                        maxLength={255}
                                        name="taxNumber"
                                        onKeyUp={e => {
                                            form.setValue('taxNumber', e.currentTarget.value != '' ? e.currentTarget.value : null);
                                            onInputChange();
                                        }}
                                        placeholder={t('companies.tax_number')}
                                        autoComplete='off' 
                                        defaultValue={form.watch('taxNumber')}/>
                                </FormItem>
                            </Col>
                        </Row>
                    </FormItem>
                </div>
                <div className={styles.buttonsFooter}>
                    <FormItem>
                        <Button
                            text={t('common.remove')}
                            size={'normal'}
                            preset={'secondary'}
                            onClick={clearFilters}
                            type='reset' />
                        <Button
                            type='submit'
                            text={t('common.apply')}
                            size={'normal'}
                        />
                    </FormItem>
                </div>
            </div>
        </form>
    );
}

export default memo(CompanyFiltersScreen);