import React from 'react';
import Box from '../box/Box';
import MoneyFormat from 'common/components/moneyFormat/MoneyFormat';
import styles from './CounterBox.module.scss';
import Badge from '../badge/Badge';

export type CounterObj = {
    counter: string;
    color: any;
};

type Props = {
    icon: any;
    label: string;
    subLabel?: string;
    counter?: number | undefined;
    isMoney?: boolean;
    isKm?: boolean;

    counters?: CounterObj[] | undefined;
    col4?: boolean;
    className?: string;
    onClick?: () => void;
    cursor?: string | undefined;

    verticalAlign?: boolean;
    inLine?: boolean;
};

const CounterBox = ({ icon, label, subLabel, counter, isMoney, isKm, counters, col4, className, onClick, cursor, verticalAlign, inLine, ...props }: Props) => {

    return <Box onClick={onClick} style={{ cursor: cursor ? cursor : 'normal', display: verticalAlign ? 'grid' : undefined, alignItems: verticalAlign ? 'center' : undefined }} className={`${styles.boxCounterContainer} ${!!col4 && styles.col4} ${className || ''}`} {...props}>
        <div className={styles.boxCounterContent} >
            {icon && <div className={styles.img} style={{ backgroundImage: 'url(' + icon + ')' }} />}
            <div className={styles.labelCounterContainer} style={{ flexDirection : inLine ? 'row' : undefined }}>
                <span className={`${styles.spanLabel} ${subLabel && styles.spanMoreWidth}`}>
                    {label}
                    {subLabel && <div className={`${styles.spanSubLabel}`}>{subLabel}</div>}
                </span>

                <span className={`${styles.spanCounter}`}>
                    {(isMoney || isKm) && counter !== undefined && counter !== null && <MoneyFormat value={counter} fixedDecimalScale={true} suffix={isKm ? '' : undefined} />}
                    {(!isMoney && !isKm) && counter !== undefined && counter !== null && counter}

                    {!!counters && <div className={styles.countersContainer}>{counters.map((x, i) => <Badge className={styles.counterBadge} key={`roundValue_${i}`} backgroundColor={x.color} outlineColor={x.color} size={'round'}>{x.counter}</Badge>)}</div>}
                </span>
            </div>
        </div>
    </Box>
        ;
};

export default CounterBox;
