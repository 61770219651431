import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ScreenTitle from '../../common/components/screenTitle/ScreenTitle';
import ScreenContainer from '../../common/components/screenContainer/ScreenContainer';
import Logger from 'common/services/Logger';
import { useToasts } from 'react-toast-notifications';
import VehiclesService from 'api/vehicles/VehiclesService';
import Loading from 'common/services/Loading';
import DashboardDto from 'api/dashboard/dtos/DashboardDto';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { FiltersFormModel, TimePeriod, timePeriodToDates } from './filters/FiltersView';
import DashboardService from 'api/dashboard/DashboardService';
import { DATE_FORMAT_DEFAUT, LOGGER_LOG_TYPE } from 'Config';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import FiltersButton from './filters/FiltersButton';
import CounterBox from 'common/components/counterBox/CounterBox';
import CoinsIcon from 'assets/svg/coins.svg';
import MediaIcon from 'assets/svg/media.svg';
import CarIcon from 'assets/svg/car.svg';
import styles from './DashboardScreen.module.scss';
import { Grid, Row } from 'react-flexbox-grid';
import ChartsBoxes from './chartsBoxes/ChartsBoxes';
import ReactTooltip from 'react-tooltip';
import FilterList from 'common/components/filterList/FilterList';
import DateFormat from 'common/components/dateFormat/dateFormat';
import { useSelector } from 'react-redux';
import { UserProfile } from 'api/account/models/UserProfile';
import { Reducers } from 'store/types';
import moment from 'moment';
import dayjs from 'dayjs';
import { CountersDashboardDto } from 'api/dashboard/dtos/CountersDashboardDto';
import { ExpensesDashboardDto } from 'api/dashboard/dtos/ExpensesDashboardDto';
import { ConsumesDashboardDto } from 'api/dashboard/dtos/ConsumesDashboardDto';
import { MonthlyCostDashboardDto } from 'api/dashboard/dtos/MonthlyCostDashboardDto';
import { NextMaintenancesDashboardDto } from 'api/dashboard/dtos/NextMaintenancesDashboardDto';
import { MonthlyRoutesDashboardDto } from 'api/dashboard/dtos/MonthlyRouteDashboardDto';


const defaultTimePeriod = TimePeriod.CURRENT_MONTH;
const [defaultDateFrom, defaultDateTo] = timePeriodToDates(defaultTimePeriod);

const DashboardScreen = () => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const [data, setData] = useState<DashboardDto | null>(null);
    const [vehicles, setVehicles] = useState<SelectValueLabel[]>([]);
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const [counters, setCounters] = useState<CountersDashboardDto | null>(null);
    const [expenses, setExpenses] = useState<ExpensesDashboardDto | null>(null);
    const [consumes, setConsumes] = useState<ConsumesDashboardDto | null>(null);
    const [nextMaintenances, setNextMaintenances] = useState<NextMaintenancesDashboardDto | null>(null);
    const [monthlyCost, setMonthlyCost] = useState<MonthlyCostDashboardDto | null>(null);
    const [monthlyRoute, setMonthlyRoutes] = useState<MonthlyRoutesDashboardDto | null>(null);
    const [refresh, setRefresh] = useState<number>(1);
    const [filters, setFilters] = useState<FiltersFormModel>({
        dateFrom: defaultDateFrom,
        dateTo: defaultDateTo,
        vehicleId: null,
        timePeriod: defaultTimePeriod,
        inactives: true
    });

    const [isLoadingCounters, setIsLoadingCounters] = useState<boolean>(false);
    const [isLoadingExpenses, setIsLoadingExpenses] = useState<boolean>(false);
    const [isLoadingConsumes, setIsLoadingConsumes] = useState<boolean>(false);
    const [isLoadingNextMaintenances, setIsLoadingNextMaintenances] = useState<boolean>(false);
    const [isLoadingMonthlyCost, setIsLoadingMonthlyCost] = useState<boolean>(false);
    const [isLoadingMonthlyRoute, setIsLoadingMonthlyRoute] = useState<boolean>(false);

    const getData = async () => {
        try {
            Loading.show();
            const criteria = {
                ...filters,
                dateTo: filters.dateTo ? dayjs(filters.dateTo).endOf('month').toDate() : filters.dateTo,
            }
            const [vehiclesList] = await Promise.all([
                VehiclesService.catalogWithDeleted()
            ]);
            setVehicles(vehiclesList);

        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.DASHBOARD, 'error getting dashboard data', error);
        } finally {
            Loading.hide();
        }
    };

    const getCounters = async () => {
        try {
            setIsLoadingCounters(true);
            const dashboardData = await DashboardService.getCounters({ ...filters });
            setCounters(dashboardData);
            setRefresh(refresh + 1);
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.DASHBOARD, 'error getting dashboard data', error);
        } finally {
            setIsLoadingCounters(false);
        }
    };

    const getExpenses = async () => {
        try {
            setIsLoadingExpenses(true);
            const dashboardData = await DashboardService.getExpenses({ ...filters });
            setExpenses(dashboardData);
            setRefresh(refresh + 1);
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.DASHBOARD, 'error getting dashboard data', error);
        } finally {
            setIsLoadingExpenses(false);
        }
    };

    const getConsumes = async () => {
        try {
            setIsLoadingConsumes(true);
            const dashboardData = await DashboardService.getConsumes({ ...filters });
            setConsumes(dashboardData);
            setRefresh(refresh + 1);
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.DASHBOARD, 'error getting dashboard data', error);
        } finally {
            setIsLoadingConsumes(false);
        }
    };

    const getNextMaintenances = async () => {
        try {
            setIsLoadingNextMaintenances(true);
            const dashboardData = await DashboardService.getNextMaintenances({ ...filters });
            setNextMaintenances(dashboardData);
            setRefresh(refresh + 1);
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.DASHBOARD, 'error getting dashboard data', error);
        } finally {
            setIsLoadingNextMaintenances(false);
        }
    };

    const getMonthlyCost = async () => {
        try {
            setIsLoadingMonthlyCost(true);
            const dashboardData = await DashboardService.getMonthlyCost({ ...filters });
            setMonthlyCost(dashboardData);
            setRefresh(refresh + 1);
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.DASHBOARD, 'error getting dashboard data', error);
        } finally {
            setIsLoadingMonthlyCost(false);
        }
    };

    const getMonthlyRoutes = async () => {
        try {
            setIsLoadingMonthlyRoute(true);
            const dashboardData = await DashboardService.getMonthlyRoutes({ ...filters });
            setMonthlyRoutes(dashboardData);
            setRefresh(refresh + 1);
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.DASHBOARD, 'error getting dashboard data', error);
        } finally {
            setIsLoadingMonthlyRoute(false);
        }
    };


    useEffect(() => {
        getCounters();
        getExpenses();
        getConsumes();
        getNextMaintenances();
        getMonthlyCost();
        //getMonthlyRoutes();
    }, [filters]);

    const onChangeFilters = (f: FiltersFormModel) => {
        f.dateFrom = f.dateFrom == null ? defaultDateFrom : moment.utc(f.dateFrom).toDate();
        f.dateTo = f.dateTo == null ? defaultDateTo : moment.utc(f.dateTo).endOf('month').toDate();
        f.timePeriod = f.timePeriod == null ? defaultTimePeriod : f.timePeriod;
        setFilters(f);
        filters.dateFrom = f.dateFrom;
        filters.dateTo = f.dateTo;
        filters.vehicleId = f.vehicleId;
        filters.timePeriod = f.timePeriod;
        filters.inactives = f.inactives;
        getData();
    }

    return (
        <ScreenTitle title={t('dashboard.title')}>
            <ScreenContainer>
                <Grid fluid>
                    <ScreenHeader title={t('dashboard.title')}>
                        <FiltersButton
                            onFilter={setFilters}
                            vehicles={vehicles}
                            filters={filters}
                            canSelectVehicle={true}
                        />
                    </ScreenHeader>

                    <FilterList filters={[
                        {
                            value: filters.dateFrom && filters.dateTo ? <>{t('common.range.from')} <DateFormat format={DATE_FORMAT_DEFAUT} value={filters.dateFrom} /> {t('common.range.to').toLowerCase()} <DateFormat format={DATE_FORMAT_DEFAUT} value={filters.dateTo} /></>
                                : filters.dateFrom ? <>{t('common.range.from')} <DateFormat format={DATE_FORMAT_DEFAUT} value={filters.dateFrom} /></>
                                    : filters.dateTo ? <>{t('common.range.to')} <DateFormat format={DATE_FORMAT_DEFAUT} value={filters.dateTo} /></> : undefined,
                            label: t('dashboard.filters.dates'),
                            onRemove: () => { filters.dateFrom = filters.dateTo = null; filters.timePeriod = filters.timePeriod == TimePeriod.OTHER ? filters.timePeriod : null; onChangeFilters(filters) },
                            showRemoveFilter: filters.dateFrom?.getFullYear() != defaultDateFrom?.getFullYear() ||
                                Number(filters.dateFrom ? filters.dateFrom.getMonth() + 1 : 0) != Number(defaultDateFrom ? defaultDateFrom.getMonth() + 1 : 0) ||
                                filters.dateTo?.getFullYear() != defaultDateTo?.getFullYear() ||
                                Number(filters.dateTo ? filters.dateTo.getMonth() + 1 : 0) != Number(defaultDateTo ? defaultDateTo.getMonth() + 1 : 0)
                        },
                        {
                            value: vehicles.find(x => x.value == filters.vehicleId)?.label,
                            label: t('vehicle.registration'),
                            onRemove: () => { filters.vehicleId = null; onChangeFilters(filters) }
                        },
                        {
                            value: t(('dashboard.time_periods.' + filters.timePeriod) as any),
                            label: t('dashboard.filters.period_of_times'),
                            onRemove: () => { filters.timePeriod = null; filters.dateFrom = filters.dateTo = null; onChangeFilters(filters) },
                            showRemoveFilter: filters.timePeriod != defaultTimePeriod
                        },
                        {
                            value: filters.inactives ? t('common.yes') : '',
                            label: t('vehicle.inactives'),
                            onRemove: () => { filters.inactives = false; onChangeFilters(filters) }
                        }
                    ]} />

                </Grid>

                <Grid fluid className={styles.counters}>
                    <Row className={styles.rowCounters}>
                        {data?.counters && <CounterBox
                            icon={CarIcon}
                            label={t('dashboard.number_vehicles')}
                            counter={data?.counters.numberVehicles}
                            data-for="main"
                        />}
                        {data?.counters && <CounterBox
                            icon={MediaIcon}
                            label={t('dashboard.average_cost', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) })}
                            counter={data?.counters.costTotalMoyenne}
                            isMoney={true}
                            data-tip={t('dashboard.average_cost_tooltip')}
                            data-for="main"
                        />}
                        {data?.counters && <CounterBox
                            icon={CoinsIcon}
                            label={t('dashboard.cumulative_cost', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) })}
                            counter={data?.counters.costTotalAccumulated}
                            isMoney={true}
                            data-tip={t('dashboard.cumulative_cost_tooltip', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) })}
                            data-for="main"
                        />}
                    </Row>
                </Grid>
                <ReactTooltip effect="solid" id="main" />
                <ChartsBoxes
                    counters={counters}
                    expenses={expenses}
                    consumes={consumes}
                    nextMaintenances={nextMaintenances}
                    monthlyCost={monthlyCost}
                    monthlyRoute={null}
                    key={refresh}
                    isLoadingCounters={isLoadingCounters}
                    isLoadingExpenses={isLoadingExpenses}
                    isLoadingConsumes={isLoadingConsumes}
                    isLoadingNextMaintenances={isLoadingNextMaintenances}
                    isLoadingMonthlyCost={isLoadingMonthlyCost} />
            </ScreenContainer>
        </ScreenTitle>
    );
}

export default DashboardScreen;