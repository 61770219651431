import React from 'react';
import styles from './Badge.module.scss';

export type BadgePreset = 'primaryLight' | 'primaryDark' | 'success' | 'danger' | 'warning' | 'inactive' | 'primaryLightInverted' | 'default' | 'primaryDark10' | 'waitingStatus' | 'greenAmerica' | 'yellowAmerica';
type BadgeSize = 'normal' | 'round';

type Props = {
    text?: string | React.ReactElement;
    preset?: BadgePreset;
    size?: BadgeSize;
    backgroundColor?: string;
    textColor?: string;
    outlineColor?: string;
    isSmallRound?: boolean;
    width?: string;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

const Badge: React.FC<Props> = ({ children, text, preset = 'primaryLight', size = 'normal', backgroundColor, textColor, outlineColor, isSmallRound, width, ...props }: Props) => {

    const presetClassName = preset ? styles[preset] : '';

    const sizeClassName = size ? (styles as any)['size' + size[0].toUpperCase() + size.substr(1, size.length)] : styles.sizeNormal;

    return (
        <span
            {...props}
            className={`${styles.badge} ${presetClassName} ${sizeClassName} ${isSmallRound && styles.sizeRoundSmall} ${props.className || ''}`}
            style={{
                ...(backgroundColor ? { backgroundColor } : {}),
                ...(textColor ? { color: textColor } : {}),
                ...(outlineColor ? { boxShadow: `0 0 2px 2px ${outlineColor}` } : {}),
                ...(width ? { width : width }: {})
            }}
        >
            {Boolean(text) && text}
            {children}
        </span>
    );
}

export default Badge;
