import ListChargesScreen from '../../../charges/ListChargesScreen';
import { ChargeDto } from 'api/charges/models/ChargeDto';
import ChargeScreen from '../carChargeTab/components/ChargeScreen';
import { useHistory, useParams } from 'react-router-dom';
import { VehicleDto } from 'api/vehicles/models/VehicleDto';

enum ScreenType {
    LIST = 'LIST',
    CHARGE = 'CHARGE'
}

type Props = {
    vehicle?: VehicleDto;
    onDeleteFromList?: () => void;
    tourReRender: () => void;
}


const CarChargeTab = ({ onDeleteFromList, vehicle, tourReRender }: Props) => {
    const { vehicleId, tabId, itemId, itemType } = useParams<{ vehicleId: string, tabId: string, itemType: string, itemId: string }>();
    const history = useHistory();
        
    return (
        <div id="five-step">
            {(itemType??'list') === ScreenType.LIST.toString().toLowerCase() &&
                <ListChargesScreen
                    isVehicle={true}
                    editable={!(vehicle && !vehicle.active)}
                    isDriver={false}                    
                    createNewCharge={() => {
                        history.push(`/vehicles/details/${vehicleId}/${tabId}/new`);
                    }}
                    onClickItem={(item: ChargeDto, isDetails: boolean) => {
                        history.push(`/vehicles/details/${vehicleId}/${tabId}/${isDetails ? 'details' : 'edit'}/${item.id}`);
                    }}
                    onDeleteFromList={onDeleteFromList}
                    tourReRender={tourReRender}
                    >
                </ListChargesScreen>}

            {(itemType??'list') !== ScreenType.LIST.toString().toLowerCase() &&
                <ChargeScreen
                    isVehicle
                    onBackList={() => history.goBack() }
                />}
        </div>
    );
}

export default CarChargeTab;
