import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import styles from './DeletedVehicleCircle.module.scss';

interface Props {
    id: string;
}

const DeletedVehicleCircle = ({ id }: Props) => {
    const { t } = useTranslation();
    return (
        <>
            <span className={styles.deletedCircle} data-id={'vehicleActive-' + id} data-tip={t('common.vehicle_deleted')}></span>
            <ReactTooltip effect="solid" id={'vehicleActive-' + id} />
        </>
    );
}

export default DeletedVehicleCircle