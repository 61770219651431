import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { VehicleModelsSearchCriteria } from './models/VehicleModelsSearchCriteria';
import { VehicleModelsDto } from './models/VehicleModelsDto';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { CatalogModelsSearchCriteria } from 'api/catalog/models/CatalogModelsSearchCriteria';

class VehicleModelsService {
    public getList(criteria: VehicleModelsSearchCriteria) {
        return Http.get<Paged<VehicleModelsDto>>('vehiclemodels', criteria);
    }

    public catalog(criteria?: CatalogModelsSearchCriteria) {
        return Http.get<SelectValueLabel[]>('vehiclemodels/catalog', criteria);
    }

    public getById(id: string) {
        return Http.get<VehicleModelsDto>('vehiclemodels/' + id);
    }

    public create(model: VehicleModelsDto) {
        return Http.post<string>('vehiclemodels', model);
    }

    public update(model: VehicleModelsDto) {
        return Http.put('vehiclemodels', model);
    }

    public remove(model: VehicleModelsDto) {
        return Http.put('vehiclemodels/deactivate', model);
    }
}

export default new VehicleModelsService();