import VehicleSegmentsDetails from './components/vehicleSegmentsDetails/VehicleSegmentsDetails';
import VehicleSegmentsList from './components/vehicleSegmentsList/VehicleSegmentsList';
import { useParams } from 'react-router-dom';
import styles from './VehicleSegmentsTab.module.scss';

const VehicleSegmentsTab = () => {
    const { type } = useParams<{ type: string, itemId: string }>();

    return <div className={styles.container}>
        {
            (type === 'list' ? <VehicleSegmentsList /> : <VehicleSegmentsDetails />)
        }
        </div>;
}

export default VehicleSegmentsTab;
