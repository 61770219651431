import LocalsDetails from './components/localsDetails/LocalsDetails';
import LocalsList from './components/localsList/LocalsList';
import { useParams } from 'react-router-dom';
import styles from './LocalsTab.module.scss';

const LocalsTab = () => {
    const { type } = useParams<{ type: string, itemId: string }>();
    return <div className={styles.container}>
    {
        (type === 'list' ? <LocalsList /> : <LocalsDetails />)
    }
    </div>;
}

export default LocalsTab;
