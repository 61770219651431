import React, { useEffect, useRef, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import styles from './ColorPicker.module.scss';

type Props = {
    value: string;
    disabled?: boolean;
    onChangeColor: (color: string) => void;
};

const ColorPicker: React.FC<Props> = ({ value, disabled, onChangeColor }: Props) => {

    const ref = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const changeColor = (data: ColorResult) => {
        onChangeColor && onChangeColor(data.hex);
    };

    const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return (
        <div>
            <div className={`${styles.inputContainer} ${disabled ? styles.disabled : ''}`}>
                <div className={styles.colorInput} style={{ backgroundColor: value || 'lightgrey' }} onClick={() => !disabled && setIsOpen(true)}></div>
                <input type='text' value={value} disabled={disabled} onChange={e => {
                    if (e.currentTarget.value && !e.currentTarget.value.startsWith('#')) {
                        onChangeColor('#' + e.currentTarget.value);
                    }
                    else {
                        onChangeColor(e.currentTarget.value);
                    }
                }} />
            </div>
            {isOpen &&
                <div ref={ref}>
                    <SketchPicker className={styles.pallete} color={value} onChange={changeColor} onChangeComplete={changeColor}  />
                </div>
            }
        </div>
    );
}

export default ColorPicker;