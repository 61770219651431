import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Label from 'common/components/label/Label';
import FormItem from 'common/components/formItem/FormItem';
import FileSelector from 'common/components/fileSelector/FileSelector';
import { ContractInvoiceDto } from 'api/contracts/invoices/models/InvoiceDto';
import styles from './Invoices.module.scss';
import InputGroup from 'common/components/inputGroup/InputGroup';
import { FaCalendarAlt } from 'react-icons/fa';
import Button from 'common/components/button/Button';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import CloseIcon from 'assets/svg/x-circle.svg';
import Utils from 'common/services/Utils';
import DateTimePicker from 'common/components/dateTimePicker/DateTimePicker';
import { DATE_FORMAT_DEFAUT, DATEPICKER_FORMAT_DEFAULT } from 'Config';
import DateFormat from 'common/components/dateFormat/dateFormat';
import { AiOutlineFilePdf } from 'react-icons/ai';
import InputError from 'common/components/inputError/InputError';
import moment from 'moment';
import { FaTrashAlt } from 'react-icons/fa';
import CustomFile from 'common/models/CustomFile';
interface Props {
    mode:string;
    setMode:(mode:'add' | 'list') => void;
    invoices: ContractInvoiceDto[];
    onAddInvoice: (invoice: ContractInvoiceDto) => void;
    onRemoveInvoice: (invoice: ContractInvoiceDto) => void;
    isDetails: boolean;
}

function ContractInvoices({ invoices, onAddInvoice, onRemoveInvoice, isDetails,mode,setMode }: Props): JSX.Element | null {
    const { t } = useTranslation();

    const [invoice, setInvoice] = useState<ContractInvoiceDto>({ id: Utils.newGuid(), date: new Date(),isNew:true })
    const [hasSaved, setHasSaved] = useState<boolean>(false);

    const cancelInvoice = () => {
        setMode('list');
        setInvoice({ id: Utils.newGuid(), date: new Date(),isNew:true })
        setHasSaved(false);
    }

    const onSaveInvoice = () => {

        if (!invoice.file || !invoice.date) {
            setHasSaved(true);
            return;
        }

        onAddInvoice(invoice);
        setInvoice({ id: Utils.newGuid(), date: new Date(),isNew:true })
        setMode('list')
        setHasSaved(false);
    }

    const openFile = (e: React.MouseEvent<HTMLDivElement, MouseEvent>,item:CustomFile) => {
        if (item?.url) {
            e.preventDefault();
            e.stopPropagation();
            window.open(item.url, '_blank');
        }
    }

    const setFile = (file:CustomFile) => {
        file.containerName = invoice.id;
        setInvoice(x => {
            return {
                ...x,
                file: file
            }
        })
    }

    const add = () => (
        <>
            <FormItem>
                <FileSelector
                    isDetails={false}
                    isMulti={false}
                    smallButtonAdd={true}
                    onFilesChanged={file => setFile(file[0])}
                    onRemoveFile={file => {
                        setInvoice(x => {
                            return {
                                ...x,
                                file: undefined
                            }
                        })
                    }}
                    label={<Label className={styles.label}>{`${t('contracts.invoices.invoice')}*`}</Label>}
                    fileButtonSizes={{ sm: 12, lg: 6 }}
                />
                {hasSaved && !invoice.file && <InputError error={{ type: 'required' }} />}
            </FormItem>
            <FormItem>
                <Label className={styles.label}>{t('contracts.invoices.date')} *</Label>
                <div className={styles.dateRangeCustom}>
                    <DateTimePicker
                        name="date"
                        placeholderText={t('common.date')}
                        maxDate={new Date()}
                        onChange={(date: Date) => {
                            setInvoice(x => {
                                return {
                                    ...x,
                                    date: date
                                }
                            })
                        }}

                        dateFormat={DATEPICKER_FORMAT_DEFAULT}
                        selected={moment(invoice.date).toDate()}
                        customInput={<InputGroup icon={<FaCalendarAlt />} />}
                    />
                </div>
                {hasSaved && !invoice.date && <InputError error={{ type: 'required' }} />}
            </FormItem>
            <div className={styles.buttonsContainer}>
                <Button
                    type="button"
                    onClick={onSaveInvoice}
                    text={t('common.save')}
                />
            </div>
        </>
    )

    const list = () => (
        <>
            {!invoices?.length && <div className={styles.displayCenter}>{t('common.without_elements')}</div>}
            {invoices.length > 0 && invoices.map((item, index) => (
                <div className={styles.timelineItem} key={`invoice-${index}`}>
                    <div className={styles.circle}></div>
                    <div className={styles.displayBetween}>
                        <div>
                            <div className={styles.dateTime}>
                                <DateFormat value={item.date!} format={DATE_FORMAT_DEFAUT} />
                            </div>
                            <div className={styles.file} onClick={e => openFile(e,item.file!)} style={{ cursor: item.file?.url ? 'pointer': 'default' }}>
                                <AiOutlineFilePdf size={25} />  {item.file?.name}
                            </div>
                        </div>
                        {!isDetails && <div className={styles.removeIconDiv}>
                            <FaTrashAlt fill="red" onClick={() => onRemoveInvoice(item)} style={{ cursor: 'pointer' }} />
                        </div>}
                    </div>
                </div>
            ))}
        </>
    )

    useEffect(() => {
        setMode('list');
    }, [isDetails]);

    return (
        <>
            <div className={styles.displayBetween}>
                <Label className={styles.subtitle}>{t('contracts.invoices.title')}</Label>
                {!isDetails && mode === 'list' && <ScreenHeaderButton icon={AddIcon} onClick={() => setMode('add')} />}
                {!isDetails && mode === 'add' && <ScreenHeaderButton icon={CloseIcon} onClick={cancelInvoice} />}
            </div>
            <div className={styles.container}>
                {mode === 'list' && list()}
                {mode === 'add' && add()}
            </div>
        </>
    );
}

export default ContractInvoices;