import { useParams } from 'react-router-dom';
import DamageTypesDetails from './components/damageTypesDetails/DamageTypesDetails';
import DamageTypesList from './components/damageTypesList/DamageTypesList';
import styles from './DamageTypesTab.module.scss';

const DamageTypesTab = () => {
    const { type } = useParams<{ type: string, itemId: string }>();
    
    return <div className={styles.container}>
        {
            (type === 'list' ? <DamageTypesList /> : <DamageTypesDetails />)
        }
        </div>;
}

export default DamageTypesTab;
