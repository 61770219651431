import React, { useEffect, useState } from 'react';
import styles from './ListContractsScreen.module.scss';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import { LOGGER_LOG_TYPE } from 'Config';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import { useTranslation } from 'react-i18next';
import { ContractModel } from '../../../../../../api/contracts/enums/ContractModel';
import { UseContractDto } from '../../../../../../api/contracts/useContract/models/UseContractDto';
import { MaintenanceContractDto } from '../../../../../../api/contracts/maintenanceContract/models/MaintenanceContractDto';
import { InsuranceContractDto } from '../../../../../../api/contracts/insuranceContract/models/InsuranceContractDto';
import { UseContractsSearchCriteria } from 'api/contracts/useContract/models/UseContractsSearchCriteria';
import { MaintenanceContractsSearchCriteria } from 'api/contracts/maintenanceContract/models/MaintenanceContractsSearchCriteria';
import { InsuranceContractsSearchCriteria } from 'api/contracts/insuranceContract/models/InsuranceContractsSearchCriteria';
import UseContractsService from 'api/contracts/useContract/UseContractsService';
import Logger from 'common/services/Logger';
import { useToasts } from 'react-toast-notifications';
import MaintenanceContractsService from 'api/contracts/maintenanceContract/MaintenanceContractsService';
import InsuranceContractsService from 'api/contracts/insuranceContract/InsuranceContractsService';
import { useParams } from 'react-router-dom';
import DateFormat from 'common/components/dateFormat/dateFormat';
import MoneyFormat from 'common/components/moneyFormat/MoneyFormat';
import Utils from 'common/services/Utils';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import { ContractStatusColorEnum } from 'api/contracts/enums/ContractStatusColorEnum';
import Dropdown from 'common/components/dropdown/Dropdown';
import DropdownItem from 'common/components/dropdown/DropdownItem';
import { FaEllipsisH } from 'react-icons/fa';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import Loading from 'common/services/Loading';

export type Props = {
    type: ContractModel;
    onClickItem: (item: (UseContractDto | MaintenanceContractDto | InsuranceContractDto | any), isDetails: boolean) => void;
    createNewContract: () => void;
    onListDidLoad: () => void;
    onDeleteFromList?: () => void;
    editable : boolean;
    tourReRender: () => void;
    listReRender: () => void;
} & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ListContractsScreen = ({ type, editable, onClickItem, createNewContract, onListDidLoad, listReRender, onDeleteFromList, tourReRender, ...props }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const { vehicleId } = useParams<{ vehicleId: string }>();

    const title = type === ContractModel.USE ? t('contracts.use_contract.title') :
        type === ContractModel.MAINTENANCE ? t('contracts.maintenance_contract.title') :
            type === ContractModel.INSURANCE ? t('contracts.insurance_contract.title') : '';

    const [list, setList] = useState<UseContractDto[] | MaintenanceContractDto[] | InsuranceContractDto[]>([]);

    const [criteria, setCriteria] = useState<UseContractsSearchCriteria | MaintenanceContractsSearchCriteria | InsuranceContractsSearchCriteria>({
        page: 1,
        itemsPerPage: 15,
        vehicleId: vehicleId,
        orderColumn: 'start_date',
        orderBy: 'asc'
    });

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasContractsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_CONTRACTS_WRITE']);

    const [itemToRemove, setItemToRemove] = useState<UseContractDto | MaintenanceContractDto | InsuranceContractDto | null>(null);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);

    const getData = async () => {
        try {
            if (type === ContractModel.USE) {
                const useContracts = await UseContractsService.getList(criteria as UseContractsSearchCriteria);
                setList(useContracts.items);
            }

            if (type === ContractModel.MAINTENANCE) {
                const maintenanceContracts = await MaintenanceContractsService.getList(criteria as MaintenanceContractsSearchCriteria);
                setList(maintenanceContracts.items);
            }

            if (type === ContractModel.INSURANCE) {
                const insuranceContracts = await InsuranceContractsService.getList(criteria as InsuranceContractsSearchCriteria);
                setList(insuranceContracts.items);
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.CONTRACTS, `Couldn't get contracts list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
        finally{
            tourReRender();
            onListDidLoad();
        }
    };

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const showRemoveItemDialog = (item: UseContractDto | MaintenanceContractDto | InsuranceContractDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    };
	
	const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }

        try {
            Loading.show();
            if(type === ContractModel.USE) await UseContractsService.remove(itemToRemove as UseContractDto);
            if(type === ContractModel.MAINTENANCE) await MaintenanceContractsService.remove(itemToRemove as MaintenanceContractDto);
            if(type === ContractModel.INSURANCE) await InsuranceContractsService.remove(itemToRemove as InsuranceContractDto);
            setCriteria({ ...criteria, page: 1 });
            onCancelRemove();
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });
            if(onDeleteFromList){
                onDeleteFromList();
            }
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete contract`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
        }
        finally{
            Loading.hide();
            listReRender();
        }
    };

    const getTooltipToOtherContracts = (row: UseContractDto | MaintenanceContractDto | InsuranceContractDto) => {    
        const isRowUseContract = (row as UseContractDto);    
        const labelMaintenances = Boolean(isRowUseContract?.maintenanceContractIncluded) ? t('contracts.use_contract.maintenance_contracts_associated') + isRowUseContract.maintenanceContractsNumbers : undefined;            
        const labelInsurance = (Boolean(isRowUseContract?.insuranceIncluded) ? t('contracts.use_contract.insurance_contracts_associated') + isRowUseContract.insuranceContractsNumbers : '');        
        return (<div>
            {validateRemoveConfirmation(row) && <div>{t('contracts.use_contract.cant_delete_contracts_associated')}</div>}
            {labelMaintenances && <div><small>{labelMaintenances}</small></div>}
            {labelInsurance && <div><small>{labelInsurance}</small></div>}
        </div>);
    }

    const validateRemoveConfirmation = (row: UseContractDto | MaintenanceContractDto | InsuranceContractDto) => { 
        const isRowUseContract = (row as UseContractDto);    
        //return (Boolean(isRowUseContract?.maintenanceContractsNumbers) || Boolean(isRowUseContract?.insuranceContractsNumbers));
        return isRowUseContract.insuranceIncluded || isRowUseContract.maintenanceContractIncluded;
    }

    const renderTableActionCell = (
        row: UseContractDto | MaintenanceContractDto | InsuranceContractDto) => {
	const urlDetails = `/vehicles/details/${vehicleId}/contract/details/${type.toString().toLowerCase()}/details/${row.id}`;
    const urlEdit = `/vehicles/details/${vehicleId}/contract/details/${type.toString().toLowerCase()}/edit/${row.id}`;

        return (
            <Dropdown
                options={
                    <>
                        <DropdownItem url={urlDetails}>
                            {t('common.details')}
                        </DropdownItem>
                        { editable && hasContractsWritePolicy && <DropdownItem url={urlEdit}>
                            {t('common.edit')}
                        </DropdownItem>}
                        { editable && hasContractsWritePolicy && <DropdownItem onClick={() => showRemoveItemDialog(row)}>
                            {t('common.remove')}
                        </DropdownItem>
                        }
                        
                    </>
                }
            >
                <div>
                    <FaEllipsisH />
                </div>
            </Dropdown>
        );
    };
	

    const tableColumns: ListingTableColumn<UseContractDto | MaintenanceContractDto | InsuranceContractDto>[] = [
        {
            name: t('common.start') + ' - ' + t('common.end'),
            renderCell: row => <div><DateFormat format="DD/MM/yyyy" value={row.startDate} /> {row.endDate && <> - <DateFormat format="DD/MM/yyyy" value={row.endDate} /></>} </div>,
            width: '20%',
            onSearch: onTableFilter,
            searchField: 'start_date'
        },
        {
            name: type === ContractModel.MAINTENANCE ? t('contracts.maintenance_contract.supplier') : (type === ContractModel.INSURANCE) ? t('contracts.insurance_contract.supplier') : t('common.supplier'),
            renderCell: row => <div>{row.supplierUrl && <img src={row.supplierUrl} style={{ height: '1.5rem' }}></img>} {row.supplierName || '-'}</div>,
            width: '15%',
            onSearch: onTableFilter,
            searchField: 'supplier.name',
            hideOn: ['sm', 'md', 'lg']
        },
        {
            name: type === ContractModel.USE ? t('common.type_funding') : t('common.type_contract'),
            renderCell: row => <div>
            {!Utils.isStringNullOrEmpty((row as UseContractDto).useContractTypeName) ? t(('common.use_contract_types.' + (row as UseContractDto).useContractTypeName) as any) :
                (row as MaintenanceContractDto).maintenanceContractTypes?.length > 0 ? (row as MaintenanceContractDto).maintenanceContractTypes.map(x => <div key={`idMCT_${x.maintenanceContractTypeId}`}>{t(('common.maintenance_contract_type.' + x.maintenanceContractTypeName) as any)}</div>) :
                    (row as InsuranceContractDto).insuranceContractTypes?.length > 0 ? (row as InsuranceContractDto).insuranceContractTypes.map(x => <div key={`idMCT_${x.insuranceContractTypeId}`}>{t(('common.insurance_contract_types.' + x.insuranceContractTypeName) as any)}</div>) :
                        '-'}
            </div>,
            width: '20%',
            onSearch: type === ContractModel.USE ? onTableFilter : undefined,
            searchField: type === ContractModel.USE ? 'useContractType.name' : undefined,
            hideOn: ['sm', 'md']
        },
        {
            name: t('contracts.state_number'),
            renderCell: row => { 
                const colorText = !row.endDate || new Date() >= new Date(row.startDate) && new Date() <= new Date(row.endDate) ? ContractStatusColorEnum.IN_PROGRESS :
                                    new Date() > new Date(row.startDate) ? ContractStatusColorEnum.EXPIRED :
                                    new Date() < new Date(row.endDate) ? ContractStatusColorEnum.PLANNED : '#000'

                return(
                    <div>
                        <div>{row.number || '-'}</div>
                        <div className={styles.status}
                        style={{ color: colorText }}>
                        {!Utils.isStringNullOrEmpty((row as UseContractDto).useContractTypeName) && (row as UseContractDto).useContractTypeName === 'Achat' ? '-' :
                            !row.endDate || new Date() >= new Date(row.startDate) && new Date() <= new Date(row.endDate) ? t('contracts.states.in_progress') :
                                new Date() > new Date(row.startDate) ? t('contracts.states.expired') :
                                    new Date() < new Date(row.endDate) ? t('contracts.states.planned') : '-'}
                    </div>
                    </div>                    
                )},
            width: '15%'
        },
        {
            name: t('common.cost', { vat : (userProfile?.useValueWithVat? t('common.with_vat') : t('common.without_vat')) }),
            renderCell: row => row.cost ? <div><MoneyFormat fixedDecimalScale={true} value={row.cost ?? '-'} suffix={t('common.euro')} />{row.periodicity ? ' (' + t('common.periodicities.' + (row as UseContractDto).periodicity as any) + ')' : ''}</div> : <div>-</div>,
            width: '15%',
            onSearch: onTableFilter,
            searchField: 'cost',
            cellAlignment: 'right'
        },
        {
            name: t('common.cost_cumulative', { vat : (userProfile?.useValueWithVat? t('common.with_vat') : t('common.without_vat')) }),
            renderCell: row => row.costCumule ? <MoneyFormat fixedDecimalScale={true} value={row.costCumule ?? '-'} suffix={t('common.euro')} /> : <div>-</div>,
            width: '15%',
            hideOn: ['sm', 'md', 'lg'],
            cellAlignment: 'right'
        },
        {
            name: '',
            width: '60px',
            cellAlignment: 'right',
            preventClick: true,
            renderCell: renderTableActionCell,
            cellStyle: { overflow: 'unset' },
            hideOn: ['sm', 'md', 'lg', 'xl']
        }
    ];

    useEffect(() => {
        getData();
    }, [criteria]);

    return (
        <div className={styles.content} {...props}>
            <span className={styles.title}>
                {title}
                { hasContractsWritePolicy && editable && <ScreenHeaderButton icon={AddIcon} onClick={createNewContract} />}
            </span>
            

            <ListingTable
                columns={tableColumns}
                rows={list}
                onRowClick={row => onClickItem(row, true)}
                allowHover={true}
                initialSearch={{ colField: 'start_date', isOrderAsc: true }}
            />
            <QuestionYesNo
                onNo={onCancelRemove}
                onYes={onRemove}
                isInformation={type === ContractModel.USE && itemToRemove && validateRemoveConfirmation(itemToRemove)}
                isVisible={showRemoveModal}
                message={!Boolean(type === ContractModel.USE && itemToRemove && validateRemoveConfirmation(itemToRemove)) ? (t('common.messages.remove_record_with_ident', { name: itemToRemove?.number ?? '' })): undefined}>
                    <div>
                        {type === ContractModel.USE && itemToRemove && getTooltipToOtherContracts(itemToRemove)}
                    </div>
            </QuestionYesNo>
        </div>
    );
}

export default ListContractsScreen;
