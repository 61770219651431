import Popover from 'common/components/popover/Popover';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import React from 'react';
import styles from './FiltersButton.module.scss';
import FiltersView, { FiltersFormModel, FiltersProps } from './FiltersView';
import FiltersIcon from 'assets/svg/desktop_filtres.svg';

const getTotal = (f: FiltersFormModel, canSelectVehicle: boolean) => {
    let total = 0;

    if (f.dateFrom || f.dateTo) {
        total++;
    }

    if (f.vehicleId && canSelectVehicle) {
        total++;
    }

    if (f.timePeriod) {
        total++;
    }

    if (f.inactives) {
        total++;
    }

    return total;
}

const FiltersButton = ({ filters, onFilter, ...props }: FiltersProps) => {
    const total = getTotal(filters, props.canSelectVehicle);
    return (
        <Popover
            contentContainerClassName={styles.popoverContainer}
            containerStyle={{ boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}
            positions={['bottom', 'left']}
            overflowHidden={true}
            align={'end'}
            content={setIsPopoverOpen => (
                <FiltersView
                    {...props}
                    filters={filters}
                    onFilter={model => {
                        setIsPopoverOpen(false);
                        onFilter(model);
                    }}
                />
            )}>
            {(isPopoverOpen, setIsPopoverOpen) => (
                <ScreenHeaderButton icon={FiltersIcon} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                    {total > 0 &&
                        <div className={styles.counterList}> <div className={styles.counterNumber}>{total}</div> </div>
                    }
                </ScreenHeaderButton>
            )}
        </Popover>
    );
};

export default FiltersButton;