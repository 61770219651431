import GenericTypesDetails from './components/genericTypesDetails/GenericTypesDetails';
import GenericTypesList from './components/genericTypesList/GenericTypesList';
import { useParams } from 'react-router-dom';
import styles from './GenericTypesTab.module.scss';

const GenericTypesTab = () => {
    const { type } = useParams<{ type: string, itemId: string }>();
    return <div className={styles.container}>
    {
        (type === 'list' ? <GenericTypesList /> : <GenericTypesDetails />)
    }
    </div>;
}

export default GenericTypesTab;
