import SuppliersDetails from './components/suppliersDetails/SuppliersDetails';
import SuppliersList from './components/suppliersList/SuppliersList';
import { useParams } from 'react-router-dom';
import styles from './SuppliersTab.module.scss';

const SuppliersTab = () => {
    const { type } = useParams<{ type: string, itemId: string }>();
    return <div className={styles.container}>
    {
        (type === 'list' ? <SuppliersList /> : <SuppliersDetails />)
    }
    </div>;
}

export default SuppliersTab;
