import Http from '../../../common/services/Http';
import { Paged } from '../../common/types/Page';
import { UseContractsSearchCriteria } from './models/UseContractsSearchCriteria';
import { UseContractDto } from './models/UseContractDto';
import CustomFile from '../../../common/models/CustomFile';
import { SelectValueLabel } from 'common/types/SelectValueLabel';

class UseContractsService {

    public getList(criteria: UseContractsSearchCriteria) {
        return Http.get<Paged<UseContractDto>>('useContracts', criteria);
    }

    public catalog() {
        return Http.get<SelectValueLabel[]>('useContracts/catalog');
    }

    public getById(id: string) {
        return Http.get<UseContractDto>('useContracts/' + id);
    }

    public create(model: UseContractDto, attachment?: CustomFile[]) {
        return Http.postWithFile<string>('useContracts', model, attachment ? attachment : []);
    }
   
    public update(model: UseContractDto, attachment?: CustomFile[]) {
        return Http.putWithFile('useContracts', model, attachment ? attachment : []);
    }

    public remove(model: UseContractDto) {
        return Http.put('useContracts/deactivate', model);
    }

    public getUseContractTypes() {
        return Http.get<SelectValueLabel[]>('useContracts/get-use-contract-types');
    }

    public getUseContractsByVehicle(id: string, notAchat?: boolean) {
        return Http.get<SelectValueLabel[]>(notAchat ? ('useContracts/get-use-contracts-by-vehicle/' + id + '/' + notAchat) : ('useContracts/get-use-contracts-by-vehicle/' + id));
    }
}

export default new UseContractsService();