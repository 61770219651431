import { FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';

type Props = {
  onCreated?: (area: LocationArea) => void;
  onDrawStart?: () => void;
  onDrawStop?: () => void;
};

export type LocationArea = {
  radius: number;
  mRadius: number;
  latitude: number;
  longitude: number;
};

const DrawTools = ({ onCreated, onDrawStart, onDrawStop }: Props) => {
  const _onCreated = (e: any) => {
    const layer = e.layer;
    if (onCreated) {
      onCreated({ mRadius: layer._mRadius, radius: layer._radius, latitude: layer._latlng.lat, longitude: layer._latlng.lng });
    }
  };

  return (
    <FeatureGroup>
      <EditControl
        position='topleft'
        onCreated={_onCreated}
        onDrawStart={onDrawStart}
        onDrawStop={onDrawStop}
        draw={{
          rectangle: false,
          circlemarker: false,
          polygon: false,
          polyline: false,
          marker: false,
          circle: {
            shapeOptions: {
              color: '#6417FC',
            }
          }
        }}
        edit= { { edit: false, remove: false } }
      />
    </FeatureGroup>
  );
};

export default DrawTools;
