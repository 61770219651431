import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'react-flexbox-grid';
import { FaInfoCircle } from 'react-icons/fa';
import { DATE_TIME_FORMAT_DEFAUT, LOGGER_LOG_TYPE, removeAccents } from 'Config';
import Box from 'common/components/box/Box';
import Label from 'common/components/label/Label';
import Button from 'common/components/button/Button';
import FormItem from 'common/components/formItem/FormItem';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import FileSelector from 'common/components/fileSelector/FileSelector';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import CustomFile from 'common/models/CustomFile';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import styles from './ImportChargeScreen.module.scss';
import SuppliersService from 'api/suppliers/SuppliersService';
import ImportChargeService from 'api/import-charges/ImportChargeService';
import Logger from 'common/services/Logger';
import ExpenseTemplatesService from 'api/expenseTemplates/ExpenseTemplatesService';
import SelectController from 'common/components/select/SelectController';
import InputError from 'common/components/inputError/InputError';
import { ImportChargeViewModel } from 'api/import-charges/models/ImportChargeViewModel';
import { useHistory, useParams } from 'react-router-dom';
import { ImportExpenseFileDto, ImportExpenseFileItemDto } from 'api/import-charges/models/ImportExpenseFileDto';
import IconBack from 'assets/svg/Arrow-grey.svg';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import DateFormat from 'common/components/dateFormat/dateFormat';
import Popover from 'common/components/popover/Popover';
import DropdownItem from 'common/components/popover/PopoverItem';
import Dropdown from 'common/components/dropdown/Dropdown';
import { FaEllipsisV } from 'react-icons/fa';
import ImportChargeFormScreen from './components/form/ImportChargeFormScreen';
import Loading from 'common/services/Loading';
import { Paged } from 'api/common/types/Page';
import { ImportExpenseFileItemsSearchCriteria } from 'api/import-charges/models/ImportExpenseFileItemsSearchCriteria';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import MoneyFormat from 'common/components/moneyFormat/MoneyFormat';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import ReactTooltip from 'react-tooltip';
import Input from 'common/components/input/Input';

type Types = 'edit' | 'details' | 'new' | 'list';

const ImportChargeScreen: React.FC = () => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const history = useHistory();
    const { type, itemId } = useParams<{ type: Types, itemId: string }>();
    const [attachment, setAttachment] = useState<CustomFile>();
    const [expenseFile, setExpenseFile] = useState<ImportExpenseFileDto>();
    const [expenseFileItemsPage, setExpenseFileItemsPage] = useState<Paged<ImportExpenseFileItemDto> | null>(null);
    const [criteria, setCriteria] = useState<ImportExpenseFileItemsSearchCriteria>({
        itemsPerPage: 50,
        page: 1,
        orderColumn: 'line',
        orderBy: 'asc'
    });

    const [supplierOptions, setSupplierOptions] = useState<SelectValueLabel[]>([]);
    const [templateOptions, setTemplateOptions] = useState<SelectValueLabel[]>([]);
    const [openItemEditPopover, setOpenItemEditPopover] = useState<string | null>(null);
    const [refreshList, setRefreshList] = useState<number>(0);
    const [itemToRemove, setItemToRemove] = useState<ImportExpenseFileDto | null>(null);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const formAdd = useForm<ImportChargeViewModel>({ shouldUnregister: false });
    const { handleSubmit, setValue, register, errors } = formAdd;
    const [isDetails] = useState<boolean>(type && type == 'details' ? true : false);
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasChargesWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_IMPORT_EXPENSES_WRITE']);
    const [supplierId, setSupplierId] = useState<string | undefined>(undefined);
    const [supplierName, setSupplierName] = useState<string | undefined>(undefined);


    const getData = async () => {
        try {
            Loading.show();
            
            const templates = await ExpenseTemplatesService.catalog();
            setTemplateOptions((templates || []).map((x: SelectValueLabel) => ({ value: x.value || '', label: x.label || '' })));
            setSupplierOptions([]);

            const suppliers = await SuppliersService.catalog({ isCharges: true });
            setSupplierOptions((suppliers || []).map((x: SelectValueLabel) => ({ value: x.value || '', label: x.label || '' })));

            if (itemId) {

                const file = await ImportChargeService.getById(itemId);
                setExpenseFile(file);
                if (file.status === 'OK' && !isDetails) {
                    navigateTo('details', itemId);
                }
                criteria.expenseFileId = file.id;
                setSupplierId(file.supplierId);
                setSupplierName(file.supplierName);
                setCriteria(criteria);
                await getItems(criteria);

            } else {
                Loading.hide();
            }

        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.CHARGES, `Couldn't get import charge info`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    };

    const getItems = async (criteriap?: ImportExpenseFileItemsSearchCriteria) => {
        try {
            Loading.show();
            const data = await ImportChargeService.getListItems(criteriap ? criteriap : criteria);
            setExpenseFileItemsPage(data);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.CHARGES, `Couldn't get import expense file items`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
        finally {
            Loading.hide();
        }
    };

    const setValuesFiles = (files: CustomFile[]) => {
        setAttachment(files[0]);
    }

    const onPageChange = (page: number) => {
        setCriteria({ ...criteria, page: page });
    }

    const renderActionsCell = (row: ImportExpenseFileItemDto, _: any, index: number) => {
        const elem = document.getElementById('root');
        return (
            <>
                <Popover
                    containerStyle={{ width: '500px', overflow: 'inherit', boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}
                    positions={['left', 'top']}
                    align={'center'}
                    reposition
                    containerParent={elem ? elem : document.getElementsByTagName('body')[0]}
                    isOpen={openItemEditPopover === row.id}
                    onChange={v => setOpenItemEditPopover(v ? row.id! : null)}
                    content={setIsPopoverOpen =>
                        <ImportChargeFormScreen
                            item={row}
                            mode={type}
                            onCancel={() => {
                                setIsPopoverOpen(false);
                            }}
                            onSave={f => {
                                setRefreshList(refreshList + 1);
                                setIsPopoverOpen(false);
                                addToast(t('common.messages.record_save_success'), { appearance: 'success' });
                            }}
                            supplierId={supplierId}
                        />
                    } />
                <div style={{ paddingRight: '1rem', position: 'relative' }}>
                    {type === 'edit' && hasChargesWritePolicy && <Dropdown
                        options={
                            <>
                                {<DropdownItem onClick={v => { setOpenItemEditPopover(v ? row.id! : null); }}>
                                    {t('common.edit')}
                                </DropdownItem>}
                            </>
                        }
                    >
                        <div>
                            <FaEllipsisV />
                        </div>
                    </Dropdown>}
                </div>
            </>
        );
    }

    const renderVehicleCell = (row: ImportExpenseFileItemDto) => {
        return (
            <div className={styles.cellContainer}>
                {!row.vehicleId ?
                    <div>
                        <div>
                            <span data-tip={t('import_charges.vehicle_unlinked')} className={styles.errorField}>{row.registrationNumber}</span>
                            <ReactTooltip delayHide={100} scrollHide={true} />
                        </div>
                    </div>
                    : <span>{row.registrationNumber}</span>}
            </div>
        )
    }

    const renderExpenseTypeCell = (row: ImportExpenseFileItemDto) => {
        return (
            <div className={styles.cellContainer}>
                {!row.typeId ?
                    <div>
                        <div>
                            <span data-tip={t('import_charges.expense_type_unlinked')} className={styles.errorField}>{row.typeCode}</span>
                            <ReactTooltip delayHide={100} scrollHide={true} />
                        </div>
                    </div>
                    : <span>{row.typeCode}</span>}
            </div>
        )
    }

    const tableEditColumns: ListingTableColumn<ImportExpenseFileItemDto>[] = [
        {
            name: t('import_charges.line'),
            renderCell: row => <div>{row.line}</div>
        },
        {
            name: t('common.date'),
            renderCell: row => <div>
                <div><DateFormat value={row.date} format={DATE_TIME_FORMAT_DEFAUT} /></div>
            </div>,
            hideOn: ['sm'],
            searchField: 'date'
        },
        {
            name: t('import_charges.expense'),
            width: 'fill',
            renderCell: renderExpenseTypeCell
        },
        {
            name: t('import_charges.qty'),
            columnCellAlignment: 'right',
            cellAlignment: 'right',
            renderCell: row => <div><MoneyFormat value={row.qty ?? 0} suffix={''} /></div>
        },
        {
            name: t('import_charges.cost'),
            columnCellAlignment: 'right',
            cellAlignment: 'right',
            renderCell: row => <div>{row.cost !== undefined && row.cost !== null ? <MoneyFormat value={row.cost} suffix={t('common.euro')} fixedDecimalScale={true} /> : '-'}</div>
        },
        {
            name: t('import_charges.registration_number'),
            renderCell: renderVehicleCell
        },
        {
            name: t('import_charges.kilometers'),
            columnCellAlignment: 'right',
            cellAlignment: 'right',
            renderCell: row => <div><MoneyFormat value={row.kilometers ?? 0} suffix={t('common.kms')} /></div>
        },
        {
            renderCell: renderActionsCell,

            width: 100,
            preventClick: true,
            cellStyle: { overflow: 'unset' },
            cellAlignment: 'right',
        }
    ];

    const tableDetailsColumns = tableEditColumns.slice(0, tableEditColumns.length - 1);

    const navigateTo = (typeUrl?: string, id?: string) => {
        if (typeUrl) {
            history.push(`/import-charges/${typeUrl}/${id}`);
        } else {
            history.push(`/import-charges`);
        }
    }

    const showRemoveItemDialog = (item: ImportExpenseFileDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }

        try {
            await ImportChargeService.remove(itemToRemove);
            setCriteria({ ...criteria, page: 1 });
            onCancelRemove();
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });
            navigateTo();
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete import charges file`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
        }
    };

    const submitAdd = (data: any) => {
        if (attachment) {
            Loading.show();
            ImportChargeService.import({ expenseTemplateId: data.expenseTemplateId, supplierId: data.supplierId }, attachment)
                .then(() => {
                    addToast(t('common.messages.record_save_success'), { appearance: 'success' });
                    navigateTo();
                })
                .catch(error => {
                    Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't import charges`, error);
                    addToast(t('common.messages.record_save_error'), { appearance: 'error' });
                }).finally(() => {
                    Loading.hide();
                });
        }
    }

    const processFile = () => {
        if (expenseFile) {
            Loading.show();
            ImportChargeService.process({ id: expenseFile.id })
                .then(result => {
                    if (result.validated) {
                        addToast(t('common.messages.record_save_success'), { appearance: 'success' });
                        navigateTo();
                    }
                    else {
                        addToast(result.error, { appearance: 'warning' });
                    }

                })
                .catch(error => {
                    Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't import charges - process file`, error);
                    addToast(t('common.messages.record_save_error'), { appearance: 'error' });
                }).finally(() => {
                    Loading.hide();
                });
        }
    }

    useEffect(() => {
        getData();
    }, [criteria]);

    useEffect(() => {
        getItems();
    }, [refreshList]);

    return (
        <ScreenTitle title={t('import_charges.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('import_charges.title')}></ScreenHeader>

                {/* new  */}
                {type == 'new' && <Box className={styles.box}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={8} xl={9}>
                            <div className={styles.header}>
                                <img src={IconBack} className={styles.icon} onClick={() => navigateTo()} /> {t('import_charges.new')}
                            </div>
                        </Col>
                    </Row>


                    <form onSubmit={handleSubmit(data => submitAdd(data))}>
                        <Row>
                            <Col xs={12} sm={12} lg={6} xl={4}>
                                {<FormItem>
                                    <Label className={styles.label}>{t('import_charges.expense_template')} *</Label>
                                    <SelectController
                                        ref={register('expenseTemplateId')}
                                        form={formAdd}
                                        name="expenseTemplateId"
                                        placeholder={t('import_charges.expense_template_select')}
                                        isDisabled={false}
                                        options={templateOptions}
                                        rules={{ required: true }}
                                        filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                        onChangeSelect={(data: SelectValueLabel) => {
                                            // getSupplierByTemplate(data.value);
                                            setValue('expenseTemplateId', data ? data.value : null);
                                        }}
                                    />
                                    <InputError error={errors.expenseTemplateId} />
                                </FormItem>}
                            </Col>
                            <Col xs={12} sm={12} lg={6} xl={4}>
                                <FormItem>
                                    <Label className={styles.label}>{t('import_charges.supplier')} *  &nbsp;
                                    <span data-tip={t('import_charges.supplier_message')}><FaInfoCircle />
                                            <ReactTooltip />
                                        </span></Label>
                                    <SelectController
                                        ref={register('supplierId')}
                                        form={formAdd}
                                        name="supplierId"
                                        placeholder={t('import_charges.supplier_select')}
                                        isDisabled={false}
                                        options={supplierOptions || []}
                                        rules={{ required: true }}
                                        filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                        onChangeSelect={(data: SelectValueLabel) => {
                                            setValue('supplierId', data ? data.value : null);
                                        }}
                                    />
                                    <InputError error={errors.supplierId} />
                                </FormItem>
                            </Col>
                            <Col xs={12} sm={12} lg={6} xl={4}>
                                <Label className={styles.label}>{t('common.attachment')} (excel) *</Label>
                                <FormItem>
                                    <FileSelector
                                        onFilesChanged={setValuesFiles}
                                        fileButtonSizes={{ sm: 12 }}
                                        label={<Label className={styles.label}>{t('common.add_new_file')}</Label>}
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    />
                                </FormItem>
                            </Col>
                        </Row>

                        <div className={styles.buttonsContainer}>
                            <Button
                                type={'submit'}
                                text={t('common.save')}
                                isDisabled={!!formAdd.errors.expenseTemplateId || !!formAdd.errors.supplierId || !attachment}
                            />
                        </div>
                    </form>
                </Box>}
                {(type == 'edit' || type == 'details') && <Box className={styles.box}>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={8} xl={9}>
                            <div className={styles.header}>
                                {type == 'edit' &&
                                    <>
                                        <img src={IconBack} className={styles.icon} onClick={() => navigateTo()} /> {t('import_charges.edit')}
                                    </>}
                                {type == 'details' &&
                                    <>
                                        <img src={IconBack} className={styles.icon} onClick={() => navigateTo()} /> {t('import_charges.details')}
                                    </>}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} lg={4} xl={3}>
                            <FormItem>
                                <Label className={styles.label}>{t('import_charges.supplier')}</Label>
                                <Input
                                    name='supplierName'
                                    defaultValue={supplierName}
                                    disabled={true}
                                />
                            </FormItem>
                        </Col>
                    </Row>

                    <Row>

                        <ListingTable
                            columns={type === 'edit' && expenseFile?.status !== 'OK' ? tableEditColumns : tableDetailsColumns}
                            rows={expenseFileItemsPage?.items ?? []}
                            allowHover={true}
                            initialSearch={{ colField: 'line', isOrderAsc: true }} />

                        <PaginationWithInfo
                            itemName={t('import_charges.list.files')}
                            currentPage={expenseFileItemsPage?.currentPage ?? 1}
                            pageItems={expenseFileItemsPage?.items.length ?? 0}
                            itemsPerPage={50}
                            totalItems={expenseFileItemsPage?.totalItems ?? 0}
                            onChange={onPageChange} />
                    </Row>

                    <div className={styles.buttonsContainer}>
                        <Button
                            preset={'secondary'}
                            type="button"
                            onClick={() => type == 'details' ? navigateTo() : navigateTo('details', itemId)}
                            text={t('common.cancel')}
                        />

                        {type != 'edit' && expenseFile?.status !== 'OK' && hasChargesWritePolicy &&
                            <Button
                                type="button"
                                text={t('common.remove')}
                                preset={'danger'}
                                onClick={() => showRemoveItemDialog({ id: itemId } as ImportExpenseFileDto)} />
                        }

                        {type != 'edit' && expenseFile?.status !== 'OK' && hasChargesWritePolicy &&
                            <Button
                                type={'button'}
                                text={t('common.edit')}
                                onClick={() => { navigateTo('edit', itemId); }}
                            />}

                        {type === 'edit' && expenseFile?.status !== 'OK' && hasChargesWritePolicy &&
                            <Button
                                preset={'success'}
                                type={'button'}
                                onClick={processFile}
                                text={t('import_charges.process')}
                            />}
                    </div>

                    <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('common.messages.remove_record_with_ident', { name: itemToRemove?.fileName ?? '' })} />
                </Box>}
            </ScreenContainer>
        </ScreenTitle >
    );
};

export default ImportChargeScreen; 
