import { Paged } from 'api/common/types/Page';
import Http from '../../common/services/Http';
import { VehicleReservationDto } from './models/VehicleReservationDto';
import { VehicleReservationsSearchCriteria } from './models/VehicleReservationsSearchCriteria'
import { ReservationsVehicleListItemDto }  from '../vehicles/models/ReservationsVehicleListItemDto'

class VehicleReservationsService {

    public getById(id: string) {
        return Http.get<VehicleReservationDto>('vehicleReservations/' + id);
    }


    public getVehicles(criteria: VehicleReservationsSearchCriteria) {
        return Http.get<Paged<ReservationsVehicleListItemDto>>('vehicleReservations/getVehicles', criteria);
    }

    public getListByVehicleId(criteria: VehicleReservationsSearchCriteria) {
        return Http.get<Paged<VehicleReservationDto>>('vehicleReservations', criteria);
    }

    public validateDates(criteria: VehicleReservationsSearchCriteria) {
        return Http.get<boolean>('vehicleReservations/validateDates', criteria);
    }

    public create(model: VehicleReservationDto) {
        return Http.post<string>('vehicleReservations', model);
    }

    public update(model: VehicleReservationDto) {
        return Http.put('vehicleReservations', model);
    }

    public remove(model: VehicleReservationDto) {
        return Http.put('vehicleReservations/deactivate', model);
    }
}

export default new VehicleReservationsService();