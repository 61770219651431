import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ScreenTitle from '../../common/components/screenTitle/ScreenTitle';
import ScreenContainer from '../../common/components/screenContainer/ScreenContainer';
import styles from './AlertsScreen.module.scss';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import FiltersIcon from 'assets/svg/desktop_filtres.svg';
import EyeIcon from 'assets/svg/eye-solid.svg';
import Logger from 'common/services/Logger';
import { useToasts } from 'react-toast-notifications';
import Popover from 'common/components/popover/Popover';
import Loading from 'common/services/Loading';
import AlertsFilters, { Filters } from './components/alertsFilters/AlertsFilters';
import AlertsService from 'api/alerts/AlertsService';
import { AlertsSearchCriteria } from 'api/alerts/models/AlertsSearchCriteria';
import { AlertsListItemDto } from 'api/alerts/models/AlertsListItemDto';
import { getAlertTypeColor, getSubjectTypeColor } from 'common/utils/AlertsUtils';
import { AlertsTotalsDto } from 'api/alerts/models/AlertsTotalsDto';
import AlertsList from './components/alertsList/AlertsList';
import Box from 'common/components/box/Box';
import AlertsFiltersHeader from './components/alertsFiltersHeader/AlertsFiltersHeader';
import FilterList from 'common/components/filterList/FilterList';
import CounterBox from 'common/components/counterBox/CounterBox';
import DateFormat from 'common/components/dateFormat/dateFormat';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';

const AlertsScreen = () => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);

    const [criteria, setCriteria] = useState<AlertsSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderColumn: 'a.created_date',
        orderBy: 'desc',
        responsibleId: loggedUser?.id
    });
    const [alerts, setAlerts] = useState<AlertsListItemDto[]>([]);
    const [totalItems, setTotalItems] = useState(0);
    const [filters, setFilters] = useState<Filters>({ responsibleId: criteria.responsibleId, responsibleName: loggedUser?.realName });
    const [filtersTotal, setFiltersTotal] = useState(1);
    const [totals, setTotals] = useState<AlertsTotalsDto>({ totalNotRead: 0, totalNotReadOwn: 0, totalRead: 0, totalTreated: 0, totalNonTreated: 0, totalInterventionUrgent: 0, totalInformation: 0, totalIntervention: 0 });
    //const [showReadOnly, setShowReadOnly] = useState(false);

    const getData = async () => {
        try {
            Loading.show();

            const [alertsItems, _totals] = await Promise.all([
                AlertsService.getList({ ...criteria }), //AlertsService.getList({ ...criteria, readOnly: showReadOnly, notReadOnly: !showReadOnly  }),
                AlertsService.getTotals(criteria)
            ]);

            alertsItems.items.forEach(a => {
                a.colorType = getAlertTypeColor(a.type);
                a.color = getSubjectTypeColor(a.subject);
            });

            setTotalItems(alertsItems.totalItems);
            setAlerts(alertsItems.items);
            setTotals(_totals);

        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.ALERTS, `Couldn't get alerts list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        } finally{
            Loading.hide();
        }
    }

    useEffect(() => {
        //getData();
        loadFilters();
    }, [criteria]);//}, [criteria, showReadOnly]);

    const loadFilters = () => {
        const cachedFilters = localStorage.getItem('ALERTSFILTER');
        if(cachedFilters != null){onChangeFilters(JSON.parse(cachedFilters),true)}
        else{getData()}
    }

    const saveFiltersOnCache = (f:Filters) => {
        setFilters(f);
        localStorage.setItem('ALERTSFILTER', JSON.stringify(f));
    }


    const onChangeFilters = (_filters: Filters,isOnLoad?:boolean) => {
        updateTotalFilters(_filters);
        
            criteria.vehicleId = _filters.vehicleId;
            criteria.type = _filters.type;
            criteria.responsibleId = _filters.responsibleId;
            criteria.reading = _filters.reading;
            criteria.startDate = _filters.startDate;
            criteria.endDate = _filters.endDate;

        
        if(isOnLoad && _filters.page){
            criteria.page = _filters.page
        }else{
            criteria.page = 1;
            _filters.page = 1;
        }

        saveFiltersOnCache(_filters);
        getData();
    }

    const onPageChange = (page?: number,saveOnFilter?:boolean) => {
        if(page != undefined ){criteria.page = page}
        if(saveOnFilter) {
            filters.page = criteria.page;
            saveFiltersOnCache(filters)
        }
        getData();
    }

    const updateTotalFilters = (_filters: Filters) => {
        let total = 0;

        if (_filters.vehicleId) {
            total++;
        }
        if (_filters.type) {
            total++;
        }
        if (_filters.responsibleId) {
            total++;
        }
        if (_filters.startDate) {
            total++;
        }
        if (_filters.endDate) {
            total++;
        }
        if (_filters.reading !== undefined) {
            total++;
        }

        setFiltersTotal(total);
    }

    const renderCounterBoxes = () => {
        return <div className={styles.rowCounters}>
            {totals && renderBox(EyeIcon, t('alerts.list.alerts'), totals.totalRead + totals.totalNotRead ?? 0, undefined, undefined, t('common.total'),
                () => { getDataCounter(undefined) }, 'pointer')}
            {totals && renderBox(EyeIcon, t('alerts.list.alerts'), totals.totalRead ?? 0, undefined, undefined, t('alerts.list.reads'),
                () => { getDataCounter(true) }, 'pointer')}
            {totals && renderBox(EyeIcon, t('alerts.list.alerts'), totals.totalNotRead ?? 0, undefined, undefined, t('alerts.list.not_reads'),
                () => { getDataCounter(false) }, 'pointer')}
        </div>
    }

    const getDataCounter = async (mode: boolean | undefined) => {
        try {
            Loading.show();

            filters.reading = filters.readingName = undefined;
            updateTotalFilters(filters);
            setFilters(filters);
            criteria.reading = mode;

            const alertsItems = await AlertsService.getList({ ...criteria });

            alertsItems.items.forEach(a => {
                a.colorType = getAlertTypeColor(a.type);
                a.color = getSubjectTypeColor(a.subject);
            });

            setTotalItems(alertsItems.totalItems);
            setAlerts(alertsItems.items);

            criteria.reading = undefined;
            const _totals = await AlertsService.getTotals(criteria);
            setTotals(_totals);

            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.ALERTS, `Couldn't get alerts list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const renderBox = (icon: any, label: string, counter?: number | undefined, isMoney?: boolean, counters?: any, subLabel?: string, onClick?: () => void, cursor?: string) => {
        return <CounterBox
            icon={icon}
            label={label}
            subLabel={subLabel}
            counter={counter}
            isMoney={isMoney}
            counters={counters}
            onClick={onClick}
            cursor={cursor}
            inLine
        />
    }

    return (
        <ScreenTitle title={t('alerts.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('alerts.title')}>
                    <Popover
                        contentContainerClassName={styles.filtersPopoverContainer}
                        containerStyle={{ boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}
                        positions={['bottom', 'left']}
                        align={'end'}
                        onClickOutside={() => updateTotalFilters(filters)}
                        content={setIsPopoverOpen => <AlertsFilters
                            filters={filters}
                            onFilter={_filters => { setIsPopoverOpen(false); onChangeFilters(_filters) }}
                            onChange={_filters => updateTotalFilters(_filters)}
                            isDriver={!loggedUser?.companyId && !!loggedUser?.id}
                        />}>
                        {(isPopoverOpen, setIsPopoverOpen) => (
                            <ScreenHeaderButton icon={FiltersIcon} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                                {filtersTotal > 0 &&
                                    <div className={styles.counterList}> <div className={styles.counterNumber}>{filtersTotal}</div> </div>
                                }
                            </ScreenHeaderButton>
                        )}
                    </Popover>
                </ScreenHeader>

                <FilterList filters={[
                    {
                        value: filters.startDate && filters.endDate ? <>{t('common.range.from')} <DateFormat value={filters.startDate} /> {t('common.range.to').toLowerCase()} <DateFormat value={filters.endDate} /></>
                            : filters.startDate ? <>{t('common.range.from')} <DateFormat value={filters.startDate} /></>
                                : filters.endDate ? <>{t('common.range.to')} <DateFormat value={filters.endDate} /></> : undefined,
                        label: t('charges.filter_date'),
                        onRemove: () => { filters.startDate = filters.endDate = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.responsibleName,
                        label: t('alerts.list.alert_receiver'),
                        onRemove: () => { filters.responsibleId = filters.responsibleName = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.vehicleName,
                        label: t('vehicle.vehicle'),
                        onRemove: () => { filters.vehicleId = filters.vehicleName = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.typeName,
                        label: t('common.type'),
                        onRemove: () => { filters.type = filters.typeName = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.readingName,
                        label: t('alerts.list.reading'),
                        onRemove: () => { filters.reading = filters.readingName = undefined; onChangeFilters(filters) }
                    },
                ]} />

                {totals && renderCounterBoxes()}

                <Box className={styles.box}>
                    <AlertsFiltersHeader
                        criteria={criteria}
                        onChangeCriteria={setCriteria}
                    />
                    <AlertsList
                        editable={true}
                        alerts={alerts}
                        criteria={criteria}
                        totalItems={totalItems}
                        onReadAlert={getData}
                        onChangeCriteria={setCriteria}
                        onPageChangeCallback={onPageChange}
                        //onSetFilterPage={onSetFitlerPage}
                    />
                </Box>

            </ScreenContainer>
        </ScreenTitle>
    );
}

export default AlertsScreen;
