import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './VehicleGpsFiltersScreen.module.scss';
import Button from 'common/components/button/Button';
import FormItem from 'common/components/formItem/FormItem';
import Label from 'common/components/label/Label';
import { Col, Row } from 'react-flexbox-grid';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import Select from 'common/components/select/Select';
import { Controller, useForm } from 'react-hook-form';
import Input from 'common/components/input/Input';
import DriversService from 'api/drivers/DriversService';

export interface Filters {
    registrationNumber?: string;
    driverId?: string;
    driverName?: string;
    ignition?: boolean;
}

type Props = {
    filters: Filters;
    onChange: (filters: Filters) => void;
    onFilter: (filters: Filters) => void;
}

const VehicleGpsFiltersScreen: React.FC<Props> = ({ filters, onChange, onFilter }: Props) => {
    const { t } = useTranslation();

    const form = useForm<Filters>({ shouldUnregister: false, shouldFocusError: true, defaultValues: filters });
    const { getValues, setValue, reset, watch, control } = form;

    const [driversOptions, setDriversOptions] = useState<SelectValueLabel[]>([]);
    const [selectIgnition, setSelectIgnition] = useState<boolean | undefined>();

    const getData = async () => {
        const [
            drivers
        ] = await Promise.all([
            DriversService.catalog()
        ]);
        setDriversOptions(drivers);
    }

    useEffect(() => {
        if (form.getValues('ignition') != undefined) {
            setSelectIgnition(form.getValues('ignition'));
        }
        getData();
    }, []);

    const clearFilters = () => {
        reset({
            registrationNumber: undefined,
            driverId: undefined,
            ignition: undefined
        });
        onInputChange();
        onSubmit(getValues());
    }

    const onSubmit = (f: Filters) => {
        onFilter(f);
    }

    const onInputChange = () => {
        onChange(getValues());
    }

    const onSelectIgnition = (status: boolean) => {
        setSelectIgnition(selectIgnition == status ? undefined : status);
        setValue('ignition', selectIgnition == status ? undefined : status);
    }

    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className={styles.formContent}>
                <div>
                    <FormItem>
                        <Row>
                            <Col xs={12}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('vehicle.registration')}</Label>
                                    <Input
                                        maxLength={255}
                                        name="registrationNumber"
                                        onKeyUp={e => {
                                            setValue('registrationNumber', e.currentTarget.value != '' ? e.currentTarget.value : null);
                                            onInputChange();
                                        }}
                                        placeholder={t('vehicle.registration')}
                                        autoComplete='off'
                                        defaultValue={watch('registrationNumber')} />
                                </FormItem>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('vehicle.driver')}</Label>
                                    <Controller
                                        render={({ onChange: onSelectChange, value }) => {
                                            return (
                                                <Select
                                                    options={driversOptions}
                                                    isClearable
                                                    placeholder={t('vehicle.driver')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        onSelectChange(data?.value ?? null);
                                                        setValue('driverName', data?.label ?? null);
                                                        onInputChange();
                                                    }}
                                                    value={driversOptions.find(x => x.value === value) ? { label: driversOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                                />
                                            );
                                        }}
                                        control={control}
                                        name="driverId"
                                        defaultValue={getValues('driverId')} />
                                </FormItem>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('common.state')}</Label>
                                    <div className={styles.useContractStatus}>
                                        <div onClick={() => onSelectIgnition(true)} style={{ width: '50%' }}>
                                            <div className={styles.labelContainer}>
                                                <div className={selectIgnition ? styles.selected : styles.notSelected}>
                                                    <span>{t('vehicle.ignition.ON')}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div onClick={() => onSelectIgnition(false)} style={{ width: '50%' }}>
                                            <div className={styles.labelContainer}>
                                                <div className={selectIgnition != undefined && !selectIgnition ? styles.selected : styles.notSelected}>
                                                    <span>{t('vehicle.ignition.OFF')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </FormItem>
                            </Col>
                        </Row>
                    </FormItem>
                </div>

                <div className={styles.buttonsFooter}>
                    <FormItem>
                        <Button
                            text={t('common.remove')}
                            size={'normal'}
                            preset={'secondary'}
                            onClick={clearFilters}
                            type='reset' />
                        <Button
                            type='submit'
                            text={t('common.apply')}
                            size={'normal'}
                        />
                    </FormItem>
                </div>
            </div>
        </form>
    );
}

export default memo(VehicleGpsFiltersScreen);