import React from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import styles from './QRCode.module.scss';
import Modal from 'react-modal';
import CloseIcon from 'assets/svg/mobile_close.svg';


type Props = {
    label?: string;
    value?: string;
    emptyMessage?: string;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const QrCode: React.FC<Props> = ({ label, value , emptyMessage }: Props) => {
    const { t } = useTranslation();

    const [qrCodeModalIsOpen, setQrCodeModalIsOpen] = React.useState(false);

    const showRemoveItemDialog = () => {
            setQrCodeModalIsOpen(true);
    };

    const customStyles = {
        content: {
            top: '15%',
            left: '35%',
            right: '35%',
            bottom: 'auto',
            backgroundClip: 'padding-box',
            backgroundColor: '#FFFFFF',
            border: '1px solid transparent',
            borderRadius: '4px',
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.3)',
            outline: '0 none',

        },
        overlay: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }
    };


    return (
        <> <div className={styles.container} onClick={() => showRemoveItemDialog()}>
            <label className={styles.label}>
                {label ? label : t('common.generate_qrCode')}
            </label>
            <div className={styles.qrCode}>
                {value && <QRCode value={value} size={50} />}
            </div>

        </div>

            <Modal isOpen={qrCodeModalIsOpen} style={customStyles}>
                <div>
                    <div className={styles.closeButtonModal} >
                        <img src={CloseIcon} onClick={() => setQrCodeModalIsOpen(false)} style={{ width: '1rem', height: '1rem' }} />
                    </div>
                    <div className={styles.messageContainer}>
                        {value && <QRCode value={value} size={250} />}
                        {!value && emptyMessage}
                    </div>

                </div>
            </Modal>
        </>
    );
}

export default QrCode;
