import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { DiagnosticTypesDto } from './models/DiagnosticTypesDto';
import { DiagnosticTypesSearchCriteria } from './models/DiagnosticTypesSearchCriteria';
import { CatalogDiagnosticTypesSearchCriteria } from 'api/catalog/models/CatalogDiagnosticTypesSearchCriteria';

class DiagnosticTypesService {
    public getList(criteria: DiagnosticTypesSearchCriteria) {
        return Http.get<Paged<DiagnosticTypesDto>>('diagnostictypes', criteria);
    }

    public catalog(criteria?: CatalogDiagnosticTypesSearchCriteria) {
        return Http.get<SelectValueLabel[]>('diagnostictypes/catalog', criteria);
    }

    public getById(id: string) {
        return Http.get<DiagnosticTypesDto>('diagnostictypes/' + id);
    }

    public create(model: DiagnosticTypesDto) {
        return Http.post<string>('diagnostictypes', model);
    }

    public update(model: DiagnosticTypesDto) {
        return Http.put('diagnostictypes', model);
    }

    public remove(model: DiagnosticTypesDto) {
        return Http.put('diagnostictypes/deactivate', model);
    }
}

export default new DiagnosticTypesService();