import { ChargeDto } from 'api/charges/models/ChargeDto';
import { ChargesSearchCriteria } from 'api/charges/models/ChargesSearchCriteria';
import Button from 'common/components/button/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import ListChargesScreen from 'screens/charges/ListChargesScreen';
import ChargeScreen from 'screens/vehicle/components/carChargeTab/components/ChargeScreen';
import { Types } from 'screens/vehicle/VehicleScreen';
import styles from './DriverChargeTab.module.scss';

enum ScreenType {
    LIST = 'LIST',
    CHARGE = 'CHARGE'
}
type Props = {
    criteriaProp?: ChargesSearchCriteria;
    reloadCounters?: () => void;
}

const DriverChargeTab = ({ criteriaProp, reloadCounters }: Props) => {
    const { driverId, type, tabId, itemId, itemType } = useParams<{ driverId: string, type: Types, tabId: string, itemType: string, itemId: string }>();
    const history = useHistory();
    const { t } = useTranslation();

    const onCancel = () => {
        //history.goBack();        
        history.push(`/drivers`);
     };

    return (
        <div className={styles.container}>
            {itemType === ScreenType.LIST.toString().toLowerCase() &&
                <ListChargesScreen
                    editable={true}
                    isDriver
                    onClickItem={(item: ChargeDto, isDetails: boolean) => {
                        history.push(`/drivers/details/${driverId}/${tabId}/${isDetails ? 'details' : 'edit'}/${item.id}`);
                    }}
                    criteriaProp={criteriaProp}>
                </ListChargesScreen>}

            {itemType !== ScreenType.LIST.toString().toLowerCase() &&
                <ChargeScreen
                    isDriver
                    onBackList={() => { (reloadCounters && reloadCounters()); history.goBack(); }}
                />}

            <div className={styles.buttonContainer}>
                <Button preset={'secondary'}
                    type='button'
                    text={t('common.cancel')}
                    onClick={() => onCancel()} />
            </div>
        </div>
    );
}

export default DriverChargeTab;