import { UserManager, WebStorageStateStore, User } from 'oidc-client';
import { ApplicationPaths, ApplicationName } from '../../Config';

export const AuthenticationResultStatus = {
    Redirect: 'redirect',
    Success: 'success',
    Fail: 'fail'
};


export class AuthorizeService {

    private userManager : UserManager | undefined = undefined;
    private _callbacks : any = [];
    private _nextSubscriptionId = 0;
    private _user : User | undefined | null = null;
    private _isAuthenticated = false;
    private _popUpDisabled = true;
    
    public createArguments = (state : any) => ({ useReplaceToNavigate: true, data: state });
    public error = (message : any) => ({ status: AuthenticationResultStatus.Fail, message });
    public success = (state : any, user : User | undefined ) => ({ status: AuthenticationResultStatus.Success, state, user });
    public redirect = () => ({ status: AuthenticationResultStatus.Redirect });

    
    public async isAuthenticated() {
        const user = await this.getUser();
        return !!user;
    }

    public async getUser() {
        if (this._user && this._user?.profile) {
            return this._user.profile;
        }
        await this.ensureUserManagerInitialized();
        const user = await this.userManager?.getUser();
        return user && user.profile;
    }

    public async getAccessToken() {
        await this.ensureUserManagerInitialized();
        const user = await this.userManager?.getUser();
        return user && user.access_token;
    }

    public async signIn(state : any) {
        await this.ensureUserManagerInitialized();
        try {                    
            await this.userManager?.signinRedirect(this.createArguments(state));
            return this.redirect();
        } catch (redirectError) {
            console.log('Redirect authentication error: ', redirectError);
            return this.error(redirectError);
        }
    }

    public async completeSignIn(url : any) {
        try {
            await this.ensureUserManagerInitialized();
            const user = await this.userManager?.signinCallback(url);
            this.updateState(user);
            return this.success(user && user.state, user);
        } catch (error) {
            console.log('There was an error signing in: ', error);
            return this.error('There was an error signing in.');
        }
    }

    async signOut(state : any) {
        await this.ensureUserManagerInitialized();        
        try {
            await this.userManager?.signoutRedirect(this.createArguments(state));
            return this.redirect();
        } catch (redirectSignOutError) {
            console.log('Redirect signout error: ', redirectSignOutError);
            return this.error(redirectSignOutError);
        }
    }

    async completeSignOut(url: any) {
        await this.ensureUserManagerInitialized();
        try {
            const response = await this.userManager?.signoutCallback(url);
            this.updateState(null);
            return this.success(response && response.state, undefined);
        } catch (error) {
            console.log(`There was an error trying to log out '${error}'.`);
            return this.error(error);
        }
    }

    updateState(user : User | undefined | null) {
        this._user = user;
        this._isAuthenticated = !!this._user;
      //  this.notifySubscribers();
    }

    // public subscribe(callback : any) {
    //     this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
    //     return this._nextSubscriptionId - 1;
    // }

    // public unsubscribe(subscriptionId : any) {
    //     const subscriptionIndex = this._callbacks
    //         .map((element: any, index : number) => element.subscription === subscriptionId ? { found: true, index } : { found: false })
    //         .filter((element : any) => element.found === true);
    //     if (subscriptionIndex.length !== 1) {
    //         throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
    //     }

    //     this._callbacks.splice(subscriptionIndex[0].index, 1);
    // }

    // public notifySubscribers() {
    //     for (let i = 0; i < this._callbacks.length; i++) {
    //         const callback = this._callbacks[i].callback;
    //         callback();
    //     }
    // }

    async ensureUserManagerInitialized() {
        if (this.userManager !== undefined) {
            return;
        }
        const response = await fetch(ApplicationPaths.ApiAuthorizationClientConfigurationUrl);

        if (!response.ok) {
            throw new Error(`Could not load settings for '${ApplicationName}'`);
        }

        const settings = await response.json();
        settings.automaticSilentRenew = true;
        settings.includeIdTokenInSilentRenew = true;
        settings.userStore = new WebStorageStateStore({
            prefix: ApplicationName
        });

        this.userManager = new UserManager(settings);

        this.userManager.events.addUserSignedOut(async () => {
            await this.userManager?.removeUser();
            this.updateState(undefined);
        });
    }

    static get instance() { return authService }
}


const authService = new AuthorizeService();

export default authService;

