import { useTranslation } from 'react-i18next';
import styles from './Pagination.module.scss';
import ReactPaginate from 'react-paginate';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE } from 'Config';

export type PaginationProps = {
    currentPage: number;
    itemsPerPage?: number,
    totalItems: number
    onChange: (page: number) => void;
    containerClassName?: string;
}

const Pagination = ({ currentPage, itemsPerPage = DEFAULT_PAGINATION_ITEMS_PER_PAGE, totalItems, onChange, containerClassName }: PaginationProps) => {
    const { t } = useTranslation();
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    return (
        <ReactPaginate
            previousLabel={t('pagination.previous')}
            nextLabel={t('pagination.next')}
            breakLabel={'...'}
            breakClassName={styles.page}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={page => onChange(page.selected + 1)}
            containerClassName={`${styles.container} ${containerClassName || ''}`}
            pageClassName={styles.page}
            activeClassName={styles.currentPage}
            nextClassName={styles.direction}
            previousClassName={styles.direction}
            disabledClassName={styles.directionDisabled}
            forcePage={currentPage - 1}
        />
    );
}

export default Pagination;