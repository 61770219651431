import React from 'react';
import {
    VerticalTimeline,
    VerticalTimelineElement
} from 'react-vertical-timeline-component';

import styles from './TimeLine.module.scss'

import 'react-vertical-timeline-component/style.min.css';
import DateFormat from '../dateFormat/dateFormat';
import { DATE_TIME_FORMAT_DEFAULT } from 'Config';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import AvatarWithText from '../avatar/AvatarWithText';
import colors from 'styles/export/colors.module.scss';
import { ChargesStatusColorEnum } from 'api/charges/enums/ChargesStatusColorEnum';

export interface TimeLineItem {
    title?: string | React.ReactElement;
    date?: Date;
    dateRefund?: Date;
    userName?: string;
    userPhotoUrl?: string;
    description?: string;
    status?: string;
    situation?: string;
}
export type Props = {
    items?: TimeLineItem[];
} & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const TimeLine = ({ items }: Props) => {
    const { t } = useTranslation();
    return (
        <VerticalTimeline
            animate={false}
            layout={'1-column'}
        >
            {items && items.length > 0 && items.map((item, i) => {

                return (
                    <VerticalTimelineElement
                        key={`item_${i}`}
                        iconStyle={{
                            background: item.situation ? ((ChargesStatusColorEnum as any)[item.situation]) :
                                colors.black,
                            color: '#fff'
                        }}
                    >
                        <div>
                            <span className={styles.title}>
                                {item.title}
                            </span>

                            <span className={styles.infoSmall}>
                                {!Boolean(item.dateRefund) &&
                                    <>
                                        <span data-tip={t('charges.date_refund_tooltip')}>
                                            <DateFormat value={item.date ?? ''} format={DATE_TIME_FORMAT_DEFAULT.toString()} />
                                        </span>
                                        <ReactTooltip />
                                    </>
                                }
                                {Boolean(item.dateRefund) &&
                                    <>
                                        <span data-tip={t('charges.date_tooltip')}>
                                            <DateFormat value={item.dateRefund ?? ''} format={DATE_TIME_FORMAT_DEFAULT.toString()} />
                                        </span>
                                        <ReactTooltip />
                                    </>
                                }
                            </span>
                        </div>

                        <div className={styles.info}>
                            <AvatarWithText src={item.userPhotoUrl} size="x2"><span style={{ wordBreak: 'break-word', maxWidth: '90%' }}>{item.userName}</span></AvatarWithText>
                        </div>
                        {item.description && <div className={styles.info}>
                            {item.description}
                        </div>}

                    </VerticalTimelineElement>
                );
            })}
        </VerticalTimeline >
    );
};

export default TimeLine;