import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { VehicleModelsDto } from 'api/vehicleModels/models/VehicleModelsDto';
import Loading from 'common/services/Loading';
import VehicleModelsService from 'api/vehicleModels/VehicleModelsService';
import Logger from 'common/services/Logger';
import Button from 'common/components/button/Button';
import styles from './VehicleModelDetails.module.scss';
import InputError from 'common/components/inputError/InputError';
import Input from 'common/components/input/Input';
import Label from 'common/components/label/Label';
import FormItem from 'common/components/formItem/FormItem';
import FormController from 'common/components/formController/FormController';
import { useForm } from 'react-hook-form';
import { DEFAULT_INPUT_RULES_WITH_REQUIRED, LOGGER_LOG_TYPE, removeAccents } from 'Config';
import GreyArrow from 'assets/svg/Arrow-grey.svg';
import SelectController from 'common/components/select/SelectController';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { useEffect, useState } from 'react';
import VehicleBrandsService from 'api/vehicleBrands/VehicleBrandsService';
import { Col, Row } from 'react-flexbox-grid';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';

type Props = {
};

const VehicleModelDetails: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const history = useHistory();
    // eslint-disable-next-line
    let { type, itemId } = useParams<{ type: string, itemId: string }>();

    const [brandList, setBrandList] = useState<SelectValueLabel[]>([]);

    const [itemToRemove, setItemToRemove] = useState<VehicleModelsDto>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);

    const [vehicleModel, setVehicleModel] = useState<VehicleModelsDto | null>(null);

    const form = useForm<VehicleModelsDto>({ shouldUnregister: false, defaultValues: { ...vehicleModel } });

    const { register, handleSubmit, errors, reset } = form;

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasVehicleModelsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_VEHICLE_MODELS_WRITE']);

    const getData = async () => {
        Loading.show();

        try {
            if (itemId) {
                const model = await VehicleModelsService.getById(itemId);
                setVehicleModel(model);
                reset(model);
            }
            const brands = await VehicleBrandsService.catalog();
            setBrandList(brands);

            Loading.hide();

        } catch (error) {
            addToast(t('common.messages.error_load_info'), {
                appearance: 'error',
            });
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't get vehicle model`, error);
            Loading.hide();
        }
    }

    const onSubmit = async (formData: VehicleModelsDto) => {
        try {
            Loading.show();

            if (vehicleModel != null) {
                await VehicleModelsService.update({ ...vehicleModel, ...formData });
            } else {
                itemId = await VehicleModelsService.create(formData);
                getData();
            }

            Loading.hide();

            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
            history.push(`/settings/models/details/${itemId}`);
        } catch (error) {
            Loading.hide();
            
            if(error?.response?.status === 409){
                addToast(t('common.messages.name_already_exists'), { appearance: 'warning' });
                return;
            }

            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't create or update vehicle model`, error);
        }
    };

    const showRemoveItemDialog = (item: VehicleModelsDto) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await VehicleModelsService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                history.push(`/settings/brands/list`);
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const onGoBack = () => {
        if(vehicleModel){
            reset(vehicleModel);
        }
        history.goBack();
    }

    return (
        <div>
            <div className={styles.pageNameContainer}>
                <div className={styles.pageNameContent} onClick={() => history.push(`/settings/models/list`)}>
                    <img className={styles.pageNameContentImage} src={GreyArrow} />
                    <span className={styles.pageNameContentText}>
                        {t('settings.vehicle_models')}
                    </span>
                </div>
            </div>

            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <FormController>
                    <Row>
                        <Col xs={12} md={12} lg={12} className={styles.col}>
                            <FormItem>
                                <Label className={styles.label}>{t('vehicle_models.name')} {type !== 'details' ? '*' : ''}</Label>
                                <Input
                                    name='name'
                                    placeholder={t('vehicle_models.name')}
                                    ref={register({ ...DEFAULT_INPUT_RULES_WITH_REQUIRED })}
                                    disabled={type === 'details'}
                                />
                                <InputError error={errors.name} maxLength={250}/>
                            </FormItem>
                        </Col>
                        <Col xs={12} md={6} lg={6} className={styles.col}>
                            <FormItem>
                                <Label className={styles.label}>{t('vehicle_models.brand')} {type !== 'details' ? '*' : ''}</Label>
                                <SelectController
                                    form={form}
                                    name='vehicleBrandId'
                                    menuPortalTarget={document.querySelector('body')}
                                    options={brandList}
                                    placeholder={t('vehicle_models.details.vehicle_brand')}
                                    rules={{ required: true }}
                                    isDisabled={type === 'details'}
                                    filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                />
                                <InputError error={errors.vehicleBrandId} />
                            </FormItem>
                        </Col>

                    </Row>
                </FormController>

                <div className={styles.buttonContainer}>
                    <Button
                        preset={'secondary'}
                        type='button'
                        text={t('common.cancel')}
                        onClick={() => onGoBack()}
                    />
                    {type === 'details' && hasVehicleModelsWritePolicy &&
                        <Button
                            type="button"
                            text={t('common.remove')}
                            preset={'danger'}
                            onClick={() => showRemoveItemDialog({ id: vehicleModel?.id } as VehicleModelsDto)} />
                    }
                    {type === 'details' && hasVehicleModelsWritePolicy && <Button type='button' text={t('common.edit')} onClick={() => history.push(`/settings/models/edit/${itemId}`)} />}
                    {type !== 'details' && hasVehicleModelsWritePolicy && <Button type='submit' text={t('common.save')} />}

                    <QuestionYesNo message={t('common.messages.remove_vehicle_model', { vehicleModel: vehicleModel?.name ?? '' })}
                        isVisible={dialogDeleteItemIsOpen}
                        onYes={() => removeItem()}
                        onNo={() => setDialogDeleteItemIsOpen(false)} />
                </div>
            </form>
        </div>
    );
};

export default VehicleModelDetails;
