import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { VehiclesSearchCriteria } from 'api/vehicles/models/VehiclesSearchCriteria';
import { VehicleViewModel } from 'api/vehicles/models/VehicleViewModel';
import VehiclesService from 'api/vehicles/VehiclesService';
import CounterBox from 'common/components/counterBox/CounterBox';
import DeletedVehicleCircle from 'common/components/deletedVehicleCircle/DeletedVehicleCircle';
import IconWithText from 'common/components/iconWithText/IconWithText';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import MoneyFormat from 'common/components/moneyFormat/MoneyFormat';
import Logger from 'common/services/Logger';
import { DATE_FORMAT_DEFAUT, DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE, USE_CONTRACT_TYPES } from 'Config';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiTargetLock } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import ReactTooltip from 'react-tooltip';
import { Reducers } from 'store/types';
import styles from './VehiclesMileageScreen.module.scss';
import VehiclesMileageFiltersScreen, { Filters } from './components/VehiclesMileageFiltersScreen';
import FiltersIcon from 'assets/svg/desktop_filtres.svg';
import CarIcon from 'assets/svg/car.svg';
import GaugeIcon from 'assets/svg/gauge.svg';
import GaugeDangerIcon from 'assets/svg/gauge-danger.svg';
import NoImage from 'assets/img/asset-default-image.png';
import MediaIcon from 'assets/svg/media.svg';
import AlertIcon from 'assets/svg/i_alertas_white.svg';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import DateFormat from 'common/components/dateFormat/dateFormat';
import FilterList from 'common/components/filterList/FilterList';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import Popover from 'common/components/popover/Popover';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import Box from 'common/components/box/Box';
import { ContractStatusColorEnum } from 'api/contracts/enums/ContractStatusColorEnum';
import { VehicleMileageCounterListDto } from 'api/vehicles/models/VehicleDto';
import Loading from 'common/services/Loading';
import { FaHourglassEnd, FaRegClock } from 'react-icons/fa';
import TrackingService from 'api/tracking/TrackingService';
import LoadingSpinner from 'common/components/loading/LoadingSpinner';
import { TrackingProvider } from 'api/tracking/enums/TrackingProvider';
import CompaniesService from 'api/companies/CompaniesService';
import { CompanyDto } from 'api/companies/models/CompanyDto';

import DropdownItem from 'common/components/popover/PopoverItem';
import Dropdown from 'common/components/dropdown/Dropdown';
import { FaFileExport } from 'react-icons/fa';
import moment from 'moment';
import Colors from 'styles/export/colors.module.scss';
import EcoDriving from 'screens/vehicles-gps/components/ecoDriving/EcoDriving';

const defaultcostsEndDate = dayjs().endOf('month').toDate();

const VehiclesMileageScreen = () => {

    const { t } = useTranslation();
    const { addToast } = useToasts();

    const [criteria, setCriteria] = useState<VehiclesSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderColumn: 'v.registration_number',
        orderBy: 'asc',
        costsEndDate: defaultcostsEndDate,
        inactives: true,
        projectionsExceeded: true,
        hasKmFeature: true
    });

    const [vehicles, setVehicles] = useState<VehicleViewModel[]>([]);
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);

    const [filters, setFilters] = useState<Filters>({ inactives: true, projectionsExceeded: true });
    const [filtersTotal, setFiltersTotal] = useState(2);

    const [counters, setCounters] = useState<VehicleMileageCounterListDto>();

    const imageCacheKey = useRef(new Date().getTime());

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasCountersPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['CHARGES_TOTAL_READ']);
    const hasGpsReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_GPS_READ']);

    const [pageLoaded, setPageLoaded] = useState(false);
    const [company, setCompany] = useState<CompanyDto>();
    const iteration = useRef<number>(0);
    const getData = async () => {

        try {
            Loading.show();

            let countersDB = null;
            if (hasCountersPolicy) {
                countersDB = await VehiclesService.getMileageCountersList(criteria);
            }
            const vehiclesDB = await VehiclesService.getList(criteria);
            setCurrentPage(vehiclesDB.currentPage);
            setTotalItems(vehiclesDB.totalItems);

            setVehicles(vehiclesDB.items);


            const c = await CompaniesService.getCurrent();
            setCompany(c);

            if (countersDB) {
                setCounters(countersDB);
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get vehicles list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
        finally {
            Loading.hide();
        }
    }

    const hydrateGpsData = async () => {
        try {
            iteration.current++;
            const it = iteration.current;
            const vehiclesToHydrate = vehicles.filter(x => x.trackingDeviceId);
            if (!vehiclesToHydrate.length) {
                return;
            }

            criteria.trackingDeviceIds = vehiclesToHydrate.map(x => x.trackingDeviceId);
            const gpsData = await TrackingService.hydrateGpsData(criteria);

            const _vehicles = vehicles.map(vehicle => {
                const gps = gpsData.find(x => x.trackingDeviceId == vehicle.trackingDeviceId);
                if (gps) {
                    return { ...vehicle, totalKms: gps.totalKms, totalPenalty: gps.totalPenalty, totalMinutes: gps.totalMinutes, totalIdling: gps.totalIdling, gpsDataHydrated: true }
                }
                return { ...vehicle, gpsDataHydrated: true };
            });
            if(iteration.current == it){
                setVehicles(_vehicles);
            }


        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get vehicles gps gata`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const renderImageCell = (row: VehicleViewModel) => {
        return (
            <div className={styles.imageDescCell}>
                <div className={styles.contentImage} style={{ width: '5rem', height: '3rem' }}>
                    <div className={styles.img} style={{ width: '5rem', height: '3rem', backgroundImage: row?.photoUrl ? 'url(' + row?.photoUrl + '?_=' + imageCacheKey.current + ')' : 'url(' + NoImage + ')' }} />
                </div>
            </div>
        )
    }

    const renderDescCell = (row: VehicleViewModel) => {
        return (
            <div className={styles.descCell}>
                <div className={styles.columnPlate}>
                    {row.registrationNumber}
                    {!row.active && <DeletedVehicleCircle id={row.id ?? ''} />}
                </div>
                <div className={styles.columnBrand}>{row.vehicleBrandName + (' | ') + row.vehicleModelName}</div>
            </div>
        )
    }

    const renderTypeContractCell = (row: VehicleViewModel) => {
        return (
            <div className={styles.cellContainer}>
                {
                    row.useContractId &&
                    <div>
                        {row.useContractTypeName && <div className={styles.firstRow}>{t(`common.use_contract_types.${row.useContractTypeName}` as any)}</div>}
                        {row.useContractNumber && <div className={styles.smallCustomization}>{t('common.number')}: {row.useContractNumber}</div>}
                        {row.useContractTypeName !== USE_CONTRACT_TYPES.PURCHASE && row.useContractEndDate && <div>
                            <div className={styles.smallCustomization}
                                data-tip={
                                    `${dayjs(row.useContractStartDate).format(DATE_FORMAT_DEFAUT)} - ${dayjs(row.useContractEndDate).format(DATE_FORMAT_DEFAUT)}
                                    (${dayjs(row.useContractEndDate).diff(dayjs(row.useContractStartDate), 'days')} ${t('vehicles_mileage.list.days')})`}>
                                {`${t('vehicles_mileage.list.months')} ${row.useContractPastMonths} ${t('vehicles_mileage.list.of')} ${row.useContractMonthsContract}`}</div>
                            <ReactTooltip />
                        </div>
                        }
                        {!row.useContractEndDate && row.useContractTypeName !== USE_CONTRACT_TYPES.PURCHASE && <div>
                            <div className={styles.smallCustomization}
                                data-tip={
                                    `${dayjs(row.useContractStartDate).format(DATE_FORMAT_DEFAUT)} - ${t('vehicles_mileage.without_end_date')}`}>
                                {`${t('vehicles_mileage.list.months')} ${row.useContractPastMonths} (${t('vehicles_mileage.without_end_date')})`}</div>
                            <ReactTooltip />
                        </div>
                        }
                        {row.useContractTypeName == USE_CONTRACT_TYPES.PURCHASE && <div>
                            <div className={styles.smallCustomization}
                                data-tip={
                                    `${t('common.start_date')}: ${dayjs(row.useContractStartDate).format(DATE_FORMAT_DEFAUT)}`}>
                                {`${t('vehicles_mileage.list.months')} ${row.useContractPastMonths}`}</div>
                            <ReactTooltip />
                        </div>
                        }
                    </div>
                }
                {
                    !row.useContractId &&
                    <div>
                        <div className={styles.firstRow}>{t('vehicles_mileage.without_current_contract')}</div>
                        {row.useContractExpiredEndDate && <div>
                            <span className={styles.smallCustomization} style={{ color: ContractStatusColorEnum.EXPIRED }}>
                                {t('vehicles_mileage.last_contract_in')}: <DateFormat value={row.useContractExpiredEndDate} format={DATE_FORMAT_DEFAUT} />
                            </span>
                        </div>}
                    </div>
                }
            </div>
        )
    }

    const renderContractCell = (row: VehicleViewModel) => {
        return (
            <div className={styles.cellContainer}>
                {
                    row.useContractId &&
                    <div>
                        {row.useContractTypeName !== USE_CONTRACT_TYPES.PURCHASE && row.useContractKmsByMonth && row.useContractPastMonths && row.useContractKmContractual ?
                            <div className={styles.firstRow}>

                                <div data-tip={t('vehicles_mileage.list.avg_kms_contract_values', {
                                    contract_kms: row.useContractKmContractual,
                                    contract_months: row.useContractMonthsContract
                                })}>
                                    <BiTargetLock />&nbsp;
                                    <MoneyFormat value={(row.useContractKmsByMonth)} suffix={t('vehicles_management.list.kilometers_minified')} />
                                </div>
                                <ReactTooltip />

                                <div data-tip={t('vehicles_mileage.list.kms_contract')}>
                                    <MoneyFormat value={(row.useContractKmContractual)} suffix={t('vehicles_management.list.kilometers_minified')} />
                                </div>
                                <ReactTooltip />

                            </div>
                            : <></>}
                        {row.useContractTypeName !== USE_CONTRACT_TYPES.PURCHASE && !row.useContractKmContractual ?
                            <div>
                                <div className={styles.firstRow}>
                                    <div data-tip={t('vehicles_mileage.list.avg_kms_contract_values', {
                                        contract_kms: row.useContractKmContractual ?? 0,
                                        contract_months: row.useContractMonthsContract
                                    })}>
                                        <BiTargetLock />&nbsp;
                                        <MoneyFormat value={row.averageTargetMonth} suffix={t('vehicles_management.list.kilometers_minified')} />
                                    </div>
                                    <ReactTooltip />
                                </div>
                                {row.useContractTypeName !== USE_CONTRACT_TYPES.PURCHASE && <div className={styles.smallCustomization}>{t('vehicles_mileage.without_km_contracted')}</div>}
                            </div>
                            : <></>}
                        {
                            row.useContractTypeName === USE_CONTRACT_TYPES.PURCHASE &&
                            <div>
                                <div className={styles.firstRow}>
                                    <div data-tip={t('vehicles_mileage.list.average_target_month')}>
                                        <BiTargetLock />&nbsp;
                                        {<MoneyFormat value={row.averageTargetMonth ?? 0} suffix={t('vehicles_mileage.list.kilometers_minified')} />}
                                    </div>
                                    <ReactTooltip />
                                </div>
                            </div>
                        }
                    </div>
                }
                {
                    !row.useContractId &&
                    <div className={styles.firstRow}>{'-'}</div>
                }
            </div>)
    }

    const renderKilometersCell = (row: VehicleViewModel) => {
        return (
            <div className={styles.cellContainer}>
                {
                    row.useContractId &&
                    <div>
                        <div className={styles.firstRow} data-tip={t('vehicles_mileage.list.current_kilometers')}>
                            <IconWithText
                                icon={GaugeIcon}
                                value={row.currentKilometers}
                                suffix={t('vehicles_mileage.list.kilometers_minified')}
                                isNumeric={true}
                            />
                        </div>
                        <ReactTooltip />
                        {hasGpsReadPolicy && row && !!row.currentKilometersTracking &&
                            <div className={styles.secondRow}>
                                {t('vehicles_mileage.list.gps')}:&nbsp;<MoneyFormat value={row.currentKilometersTracking} suffix={' '} fixedDecimalScale={false} />
                            </div>}
                    </div>
                }
                {
                    !row.useContractId &&
                    <div className={styles.firstRow}>{'-'}</div>
                }


                <div className={styles.cellContainer}>
                    {
                        row.useContractId &&
                        <div>
                            <div className={styles.firstRow}>
                                <div data-tip={row.useContractKmVehicle ?
                                    t('vehicles_mileage.list.average_real_kms_tooltip_values', {
                                        current_kms: row.currentKilometers,
                                        contract_kms: row.useContractKmVehicle,
                                        past_months: row.useContractPastMonths
                                    }) :
                                    t('vehicles_mileage.list.average_real_kms_without_km_contract_tooltip_values', {
                                        current_kms: row.currentKilometers,
                                        past_months: row.useContractPastMonths
                                    })}>
                                    <div>
                                        <span style={{ fontWeight: 'normal' }}> {t('vehicles_mileage.list.average_real_kms')}</span>&nbsp;
                                        <MoneyFormat value={row.useContractKmsReal || row.useContractKmsReal == 0 ? row.useContractKmsReal : '-'} decimalScale={3} suffix={t('vehicles_mileage.list.kilometers_minified')} />
                                    </div>
                                </div>
                                <ReactTooltip />
                            </div>
                        </div>
                    }
                    {
                        !row.useContractId &&
                        <div className={styles.firstRow}>{'-'}</div>
                    }
                </div>
            </div>
        )
    }

    const renderAverageRealKmsCell = (row: VehicleViewModel) => {
        return (
            <div className={styles.cellContainer}>
                {
                    row.useContractId &&
                    <div>
                        <div className={styles.firstRow}>
                            <div data-tip={row.useContractKmVehicle ?
                                t('vehicles_mileage.list.average_real_kms_tooltip_values', {
                                    current_kms: row.currentKilometers,
                                    contract_kms: row.useContractKmVehicle,
                                    past_months: row.useContractPastMonths
                                }) :
                                t('vehicles_mileage.list.average_real_kms_without_km_contract_tooltip_values', {
                                    current_kms: row.currentKilometers,
                                    past_months: row.useContractPastMonths
                                })}>
                                <IconWithText
                                    icon={GaugeIcon}
                                    value={row.useContractKmsReal ?? 0}
                                    suffix={t('vehicles_mileage.list.kilometers_minified')}
                                    isNumeric={true}
                                />
                            </div>
                            <ReactTooltip />
                        </div>
                    </div>
                }
                {
                    !row.useContractId &&
                    <div className={styles.firstRow}>{'-'}</div>
                }
            </div>)
    }

    const renderAverageKmsPreview = (row: VehicleViewModel) => {
        return (
            <div className={styles.cellContainer}>
                {
                    row.useContractId &&
                    <div>
                        <div className={styles.firstRow}>
                            <div data-tip={t('vehicles_mileage.list.average_target_month')}>
                                <BiTargetLock />&nbsp;
                                {row.averageTargetMonth ? <MoneyFormat value={row.averageTargetMonth} suffix={t('vehicles_mileage.list.kilometers_minified')} /> : '-'}
                            </div>
                            <ReactTooltip />
                        </div>
                    </div>
                }
                {
                    !row.useContractId &&
                    <div className={styles.firstRow}>{'-'}</div>
                }
            </div>
        )
    }

    const renderProjectionCell = (row: VehicleViewModel) => {
        return (
            <div className={styles.cellContainer}>
                {(row.useContractTypeName !== USE_CONTRACT_TYPES.PURCHASE && row.useContractId && (!row.useContractKmContractual || (row.useContractKmContractual && row.useContractKmContractual == 0))) ? <div className={styles.firstRow}>{'-'}</div> : <></>}
                {!row.useContractId ? <div className={styles.firstRow}>{'-'}</div> : <></>}
                {row.useContractId && row.useContractTypeName === USE_CONTRACT_TYPES.PURCHASE ? <div className={styles.firstRow}>{'-'}</div> : <></>}
                {
                    row.useContractId && row.useContractKmContractual && row.useContractTypeName !== USE_CONTRACT_TYPES.PURCHASE && row.projection ?
                        <div className={styles.firstRow}>
                            <div data-tip={t('vehicles_mileage.list.projection_tooltip_values', {
                                current_kms: row.currentKilometers,
                                real_kms: row.useContractKmsReal ?? 0,
                                remaining_months: (row.useContractMonthsContract ?? 0) - (row.useContractPastMonths ?? 0)
                            })}>
                                {/* <MoneyFormat value={(row.projection)} suffix={t('vehicles_management.list.kilometers_minified')} /> */}
                                <IconWithText
                                    icon={GaugeIcon}
                                    value={row.projection}
                                    suffix={t('vehicles_management.list.kilometers_minified')}
                                    isNumeric={true}
                                />
                            </div>
                            <ReactTooltip />
                        </div> : <></>
                }
                {row.useContractId && row.useContractKmContractual && row.useContractTypeName !== USE_CONTRACT_TYPES.PURCHASE
                    && row.projection && (row.projectionExceeded ?? 0) > 0 ?
                    <div className={styles.smallCustomizationDanger} data-tip={t('vehicles_mileage.list.projection_exceeded_tooltip_values', {
                        projection: row.projection,
                        vehicle_kms: row.useContractKmVehicle,
                        contract_kms: row.useContractKmContractual,
                        projectionExceeded: row.projectionExceeded
                    })}>
                        {/* <MoneyFormat value={row.projectionExceeded} suffix={t('vehicles_management.list.kilometers_minified')} /> {t('common.exceeded')} */}
                        <IconWithText
                            icon={GaugeDangerIcon}
                            value={row.projectionExceeded}
                            suffix={t('vehicles_management.list.kilometers_minified') + ' ' + t('common.exceeded')}
                            isNumeric={true}
                        />
                        <ReactTooltip />
                    </div> : <></>
                }
            </div>)
    }


    const formatTotalMinutes = (time: number) => {
        const diffInTime = time * 60;
        const hours = Math.floor(diffInTime / (3600))
        const minutes = Math.floor((diffInTime - (hours * 3600)) /  60);
        const seconds = Math.round((diffInTime - (hours * 3600 ) - (minutes * 60)));
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}  h`;
    }

    const calcPenaltyTotal = (totalKms: number, totalPenalty: number) => {
        return totalKms > 0 ? (totalPenalty / totalKms) : totalPenalty;
    }

    const renderPathCell = (row: VehicleViewModel) => {
        const ecoValue = row.totalKms && row.totalPenalty ? calcPenaltyTotal(row.totalKms, row.totalPenalty) : 0;

        return (
            <div className={styles.cellContainer}>
                {row.trackingDeviceId && !row.gpsDataHydrated
                    ? <LoadingSpinner />
                    : (row.totalKms || row.totalMinutes || ecoValue)
                        ? <><div data-tip={t('location.info_messages.distance')} data-for={`distance_${row.id}`} className={styles.toolTipDivFitContent}>
                            <IconWithText
                                icon={GaugeIcon}
                                value={row.totalKms}
                                suffix={t('vehicles_mileage.list.kilometers_minified')}
                                isNumeric={true}
                                decimalScale={3}
                            />
                        </div>
                            <ReactTooltip id={`distance_${row.id}`} />
                            <div data-tip={t('location.info_messages.time')} data-for={`time_${row.id}`} className={styles.toolTipDivFitContent}>
                                <FaRegClock /> {row.totalMinutes ? formatTotalMinutes(row.totalMinutes) : 0 + ' h'}
                            </div>
                            <ReactTooltip id={`time_${row.id}`} />
                            <div data-tip={t('location.info_messages.total_idling')} data-for={`idling_${row.id}`} className={styles.toolTipDivFitContent}>
                                <FaHourglassEnd /> {row.totalIdling ? formatTotalMinutes(row.totalIdling / 60) : 0 + ' h'}
                            </div>
                            <ReactTooltip id={`idling_${row.id}`} />
                            <div>
                                <EcoDriving value={ecoValue} idx={row.id} />
                            </div>
                        </>
                        : <div>{' - '}</div>
                }
            </div>)
    }

    const tableColumns: ListingTableColumn<VehicleViewModel>[] = [
        {
            renderCell: renderImageCell,
            width: 90,
            hideOn: ['sm', 'md'],
            onlyImageColumn: true
        },
        {
            name: t('vehicles_mileage.list.vehicle'),
            renderCell: renderDescCell,
            onSearch: onTableFilter,
            searchField: 'v.registration_number'
        },
        {
            name: t('common.type_funding'),
            renderCell: renderTypeContractCell,
            onSearch: onTableFilter,
            searchField: 'use_contract_info.name',
            hideOn: ['sm', 'md', 'lg', 'xl'],
            width: 'fill'
        },
        {
            name: t('vehicles_mileage.list.kilometers'),
            renderCell: renderKilometersCell,
            hideOn: ['sm', 'md', 'lg'],
            onSearch: onTableFilter,
            searchField: 'use_contract_info.id is not null desc, case when use_contract_info.id is not null then coalesce(v.current_kilometers,0) else 0 end @orderBy',
        },
        {
            name: t('vehicles_mileage.list.average_kms_preview'),
            renderCell: (row: VehicleViewModel) => renderContractCell(row), //Boolean(row.useContractId) ? renderContractCell(row) : renderAverageKmsPreview(row),
            onSearch: onTableFilter,
            searchField: 'use_contract_info.id is not null desc, case when use_contract_info.id is not null then coalesce(use_contract_info_helper_order.verified_value, -1) else 0 end @orderBy',
            hideOn: ['sm', 'md'],
            width: 'fill'
        },
        {
            name: t('vehicles_mileage.list.projection'),
            renderCell: renderProjectionCell,
            onSearch: onTableFilter,
            searchField: 'projection',
            hideOn: ['sm', 'md'],
            width: 'fill'
        }
    ];

    if (hasGpsReadPolicy && company?.trackingProvider && company?.trackingProvider !== TrackingProvider.NONE) {
        tableColumns.push({
            name: t('vehicles_mileage.list.path') + ' (' + t('vehicles_mileage.list.gps') + ')',
            renderCell: renderPathCell,
            hideOn: ['sm', 'md'],
            width: 'fill'
        })
    }

    const navigateTo = (type?: string, id?: string) => {
        if (id) {
            history.push(`/vehicles/${type}/${id}/informations/list/undefined/undefined/undefined/undefined/${'vehicles-mileage'}`);
        } else {
            history.push(`/vehicles/${type}`);
        }
    }

    const onRowClick = (event: any, id?: string) => {
        if (id) {
            event.preventDefault();
            event.stopPropagation();

            filters.page = currentPage;
            saveFiltersOnCache(filters);

            if (event.ctrlKey) {
                window.open(`vehicles/details/${id}`, '_blank');
                return;
            }
            navigateTo('details', id);
        }
    }

    const saveFiltersOnCache = (f: Filters) => {
        setFilters(f);
        localStorage.setItem('VEHICLESMILEAGEFILTER', JSON.stringify(f));
    }

    const onPageChange = (page: number) => {
        criteria.page = page;
        getData();
    }

    useEffect(() => {
        loadFilters();
        setPageLoaded(true);
    }, []);

    useEffect(() => {
        if (pageLoaded) {
            getData();
        }
    }, [criteria]);

    useEffect(() => {
        if (!vehicles[0]?.gpsDataHydrated) {
            hydrateGpsData();
        }
    }, [vehicles]);

    const loadFilters = () => {
        const cachedFilters = localStorage.getItem('VEHICLESMILEAGEFILTER')
        if (cachedFilters != null) { onChangeFilters(JSON.parse(cachedFilters), true) }
        else { getData() }
    }

    const onChangeFilters = (f: Filters, isOnLoad?: boolean) => {
        updateTotalFilters(f);
        criteria.registrationNumber = f.registrationNumber;
        criteria.registrationDate = f.registrationDate;
        criteria.segmentId = f.segmentId;
        criteria.driverId = f.driverId;
        criteria.useContractTypeId = f.useContractTypeId;
        criteria.useContractActive = f.useContractActive;
        criteria.useContractEndsAfter = f.useContractEndsAfter;
        criteria.useContractEndsBefore = f.useContractEndsBefore;
        criteria.inactives = f.inactives;
        criteria.projectionsExceeded = f.projectionsExceeded;
        criteria.costsEndDate = defaultcostsEndDate;
        criteria.pathStartDate = f.pathStartDate;
        criteria.pathEndDate = f.pathEndDate;

        if (isOnLoad && f.page) {
            setCurrentPage(f.page);
            criteria.page = f.page
        } else {
            setCurrentPage(1);
            criteria.page = 1;
            f.page = 1;
        }

        saveFiltersOnCache(f);
        getData();
    }

    const updateTotalFilters = (f: Filters) => {
        let total = 0;

        if (f.registrationNumber) {
            total++;
        }

        if (f.registrationDate) {
            total++;
        }

        if (f.segmentId && f.segmentId.length > 0) {
            total++;
        }

        if (f.useContractTypeId) {
            total++;
        }

        if (f.useContractActive != undefined) {
            total++;
        }

        if (f.useContractEndsAfter || f.useContractEndsBefore) {
            total++;
        }

        if (f.inactives) {
            total++;
        }

        if (f.projectionsExceeded) {
            total++;
        }

        if (f.pathStartDate || f.pathEndDate) {
            total++;
        }

        setFiltersTotal(total);
    }

    const renderCounterBoxes = () => {
        return <div className={styles.rowCounters}>
            {counters && renderBox(CarIcon, t('vehicles_mileage.list.number_vehicles'), counters.numberVehicles ?? 0)}
            {counters && renderBox(MediaIcon, t('vehicles_mileage.list.number_vehicles_exceeded'), counters.numberVehiclesExceeded ?? 0)}
            {counters && renderBox(AlertIcon, t('vehicles_mileage.list.kms_exceeded'), counters.kmsExceeded ?? 0, false, '', true)}
            <ReactTooltip effect="solid" id="main" />
        </div>
    }

    const renderBox = (icon: any, label: string, counter: number, isMoney?: boolean, tooltip?: string, isKm?: boolean) => {
        return <CounterBox
            icon={icon}
            label={label}
            counter={counter}
            isMoney={isMoney}
            data-tip={tooltip}
            data-for="main"
            isKm={isKm}
        />
    }

    const getVehiclesMileageReport = async () => {
        Loading.show();
        try {
            criteria.hasGpsReadPolicy = hasGpsReadPolicy;
            const report = await VehiclesService.getVehiclesMileageReport(criteria);
            const title = t('menu.vehicles_mileage');
            saveAs(report, `${title}_${moment(new Date()).format('YYYY_MM_DD_HH_mm_ss')}.xlsx`);
            Loading.hide();
        } catch (error) {
            Loading.hide();
            Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get vehicles mileage report`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    return (
        <ScreenTitle title={t('vehicles_mileage.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('vehicles_mileage.title')}>
                    <Dropdown style={{ right: '10px' }}
                        options={
                            <>
                                <DropdownItem onClick={() => getVehiclesMileageReport()}>
                                    {t('vehicles_mileage.list.export_excel')}
                                </DropdownItem>
                            </>
                        }>
                        <div>
                            <FaFileExport size={24} className={styles.exportButton} color={Colors.primaryDark} />
                        </div>
                    </Dropdown>
                    <Popover
                        contentContainerClassName={styles.filtersPopoverContainer}
                        containerStyle={{ boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}
                        positions={['bottom', 'left']}
                        align={'end'}
                        onClickOutside={() => updateTotalFilters(filters)}
                        content={setIsPopoverOpen => <VehiclesMileageFiltersScreen
                            filters={filters}
                            hasCountersPolicy={hasCountersPolicy}
                            onFilter={f => { setIsPopoverOpen(false); onChangeFilters(f) }}
                            onChange={f => updateTotalFilters(f)}
                            showTrajectDatesFilter={Boolean(hasGpsReadPolicy && company?.trackingProvider && company?.trackingProvider !== TrackingProvider.NONE)}
                        />}>
                        {(isPopoverOpen, setIsPopoverOpen) => (
                            <ScreenHeaderButton icon={FiltersIcon} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                                {filtersTotal > 0 &&
                                    <div className={styles.counterList}> <div className={styles.counterNumber}>{filtersTotal}</div> </div>
                                }
                            </ScreenHeaderButton>
                        )}
                    </Popover>
                    <ReactTooltip />
                </ScreenHeader>

                <FilterList filters={[
                    {
                        value: filters.registrationNumber,
                        label: t('vehicle.registration_identification'),
                        onRemove: () => { filters.registrationNumber = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.segmentName,
                        label: t('vehicle.type'),
                        onRemove: () => { filters.segmentId = filters.segmentName = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.driverName,
                        label: t('common.state'),
                        onRemove: () => { filters.driverId = filters.driverName = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.useContractTypeName,
                        label: t('common.type_funding'),
                        onRemove: () => { filters.useContractTypeId = filters.useContractTypeName = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.useContractActive == undefined ? undefined : (filters.useContractActive ? <>{t('contracts.states.in_progress')}</>
                            : <>{t('contracts.states.expired')}</>),
                        label: t('common.state'),
                        onRemove: () => { filters.useContractActive = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.useContractEndsBefore && filters.useContractEndsAfter ? <>{t('common.range.from')} <DateFormat format={DATE_FORMAT_DEFAUT} value={filters.useContractEndsAfter} /> {t('common.range.to').toLowerCase()} <DateFormat format={DATE_FORMAT_DEFAUT} value={filters.useContractEndsBefore} /></>
                            : filters.useContractEndsAfter ? <>{t('common.range.from')} <DateFormat format={DATE_FORMAT_DEFAUT} value={filters.useContractEndsAfter} /></>
                                : filters.useContractEndsBefore ? <>{t('common.range.to')} <DateFormat format={DATE_FORMAT_DEFAUT} value={filters.useContractEndsBefore} /></> : undefined,
                        label: t('contracts.use_contract.expiration_date'),
                        onRemove: () => { filters.useContractEndsBefore = filters.useContractEndsAfter = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.inactives ? t('common.yes') : '',
                        label: t('vehicle.inactives'),
                        onRemove: () => { filters.inactives = false; onChangeFilters(filters) }
                    },
                    {
                        value: filters.projectionsExceeded ? t('common.yes') : '',
                        label: t('vehicles_mileage.projections_exceeded'),
                        onRemove: () => { filters.projectionsExceeded = false; onChangeFilters(filters) }
                    },
                    {
                        value: filters.pathStartDate && filters.pathEndDate ? <>{t('common.range.from')} <DateFormat format={DATE_FORMAT_DEFAUT} value={filters.pathStartDate} /> {t('common.range.to').toLowerCase()} <DateFormat format={DATE_FORMAT_DEFAUT} value={filters.pathEndDate} /></>
                            : filters.pathStartDate ? <>{t('common.range.from')} <DateFormat format={DATE_FORMAT_DEFAUT} value={filters.pathStartDate} /></>
                                : filters.pathEndDate ? <>{t('common.range.to')} <DateFormat format={DATE_FORMAT_DEFAUT} value={filters.pathEndDate} /></> : undefined,
                        label: t('vehicles_mileage.list.path_date'),
                        onRemove: () => { filters.pathStartDate = filters.pathEndDate = undefined; onChangeFilters(filters) }
                    },
                ]} />

                {counters && renderCounterBoxes()}

                <Box>
                    <ListingTable
                        columns={tableColumns}
                        rows={vehicles}
                        onRowClick={(row, _, event) => onRowClick(event, row.id)}
                        onHref={row => `vehicles/details/${row.id}`}
                        allowHover={true}
                        initialSearch={{ colField: 'v.registration_number', isOrderAsc: true }}
                    />
                    <PaginationWithInfo
                        itemName={t('vehicles_mileage.list.vehicles')}
                        currentPage={currentPage}
                        pageItems={vehicles.length}
                        totalItems={totalItems}
                        onChange={onPageChange}
                    />
                </Box>

            </ScreenContainer>
        </ScreenTitle>
    );
}

export default VehiclesMileageScreen;