import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Colors from 'styles/export/colors.module.scss';
import { Rect, VictoryAxis, VictoryBar, VictoryChart, VictoryGroup, VictoryLabel, VictoryLegend, VictoryLine } from 'victory';
import styles from './MonthlyCostChart.module.scss';
import { MonthlyCostDashboardDto, MonthlyCostItemTypeDashboardDto } from 'api/dashboard/dtos/MonthlyCostDashboardDto';
import { DashboardMonthlyCostTypeEnum } from 'api/dashboard/enums/DashboardMonthlyCostTypeEnum';
import Utils from 'common/services/Utils';
import { DASHBOARD_COLORS, DEFAULT_LANGUAGE, STORAGE } from 'Config';
import ChartLegendLabel from 'common/components/charts/ChartLegendLabel';
import MoneyFormat from 'common/components/moneyFormat/MoneyFormat';
import ChartEmptyLabel from 'common/components/charts/ChartEmptyLabel';
import useElementSize from 'common/hooks/useElementSize';
import dayjs from 'dayjs';
import Storage from 'common/services/Storage';

const BarLabel = (props: any) => (
	<VictoryLabel
		{...props}
		y={props.y - 9}
		backgroundPadding={{ top: 4, left: 8, bottom: 6, right: 8 }}
		style={{ fill: Colors.white, userSelect: 'none', fontSize: 16 }}
		backgroundStyle={{ fill: props.fill, opacity: 1 }}
		backgroundComponent={<Rect rx={7} ry={10} />}
		verticalAnchor="middle"
		renderInPortal={false}
	/>
);

type Props = {
	data: MonthlyCostDashboardDto;
	selectedYear: number | null;
};

const MonthlyCostChart = ({ data, selectedYear }: Props) => {
	const { t } = useTranslation();
	const [hiddenData, setHiddenData] = useState<DashboardMonthlyCostTypeEnum[]>([]);
	const [reRenderKey, setReRenderKey] = useState(new Date().getTime());
	const [containerRef, { width, height }] = useElementSize();
	const [fixedCostsTotal, setFixedCostsTotal] = useState(0);
	const [variableCostsTotal, setVariableCostsTotal] = useState(0);
	const [costsTotal, setCostsTotal] = useState(0);
	const locale = Storage.get(STORAGE.CURRENT_LOCALE) || DEFAULT_LANGUAGE;

	useEffect(() => {
		setReRenderKey(new Date().getTime());
		selectedYear && setCosts(selectedYear);
	}, [data, selectedYear]);

	if (!data.items || data.items.length === 0) {
		return <div className={styles.noEnoughInfoText}>{t('dashboard.no_enough_information')}</div>;
	}

	const viewWidth = 650;
	const viewHeight = 170;

	const chartWidth = viewWidth;
	const chartHeight = viewHeight - 30;
	const barWidth = 16;

	const legendData = [
		{
			name: t('dashboard.consume_average'),
			symbol: { fill: Colors.primaryDark, type: 'minus' },
			labels: { fill: Colors.primaryDark },
			clickable: false,
		},
		...data.items.map(x => ({
			name: t(`dashboard.monthly_cost_types.${x.type}` as any),
			type: x.type,
			symbol: {
				fill: !hiddenData.find(a => a === x.type) ? DASHBOARD_COLORS.monthlyCost[x.type] : Colors.gray17,
			},
			labels: { fill: Colors.primaryDark },
			clickable: true,
		})),
	];

	let itemsData = data.items
		.filter(x => !hiddenData.find(h => h === x.type))

	if (selectedYear) {
		itemsData = itemsData.map(x => {
			return {
				...x,
				items: x.items.filter(s => dayjs(s.date).year() === selectedYear)
			};
		});
	}

	const allItems = Utils.flatten<MonthlyCostItemTypeDashboardDto>(itemsData.map(x => x.items));
	const allDates = allItems
		.map(x => x.date)
		.filter((date, i, self) => self.findIndex(d => d.getTime() === date.getTime()) === i);
	const allValues = allItems.map(x => x.value);
	const average = allValues.reduce((a, b) => a + b, 0) / allValues.length;
	const highestValue = Math.max(...allValues);

	const monthsDiff = allDates?.length ? Utils.getMonthsInRange(allDates[0], allDates[allDates.length-1]).length : 0;

	const chartPadding = { top: 40, left: 25, right: 25, bottom: 40 };

	const onClickLegend = (propsData: any) => {
		const type = propsData.datum.type;
		if (!type) {
			return;
		}

		if (hiddenData.find(x => x === type)) {
			setHiddenData(hiddenData.filter(x => x !== type));
		} else {
			setHiddenData([...hiddenData, type]);
		}

		setReRenderKey(new Date().getTime());
	};

	const setCosts = (year: number | null) => {
		if(year){
			const fixedValues = data.items.length > 0 ? data.items.filter(x => x.type == DashboardMonthlyCostTypeEnum.FIXED_COST)[0].items : [];
			const variableValues = data.items.length > 0 ? data.items.filter(x => x.type == DashboardMonthlyCostTypeEnum.VARIABLE_COST)[0].items : [];
			const fixedCost = fixedValues != null ? fixedValues.filter(x => x.date.getFullYear() == selectedYear).map(x => x.value).sum() : 0;
			const variableCost = variableValues != null ? variableValues.filter(x => x.date.getFullYear() == selectedYear).map(x => x.value).sum() : 0;
			setFixedCostsTotal(fixedCost);
			setVariableCostsTotal(variableCost);
			setCostsTotal(fixedCost+variableCost);
		} else {
			setFixedCostsTotal(data.fixedCostsTotal);
			setVariableCostsTotal(data.variableCostsTotal);
			setCostsTotal(data.total);
		}
	};

	return (
		<div className={styles.container}>
			<div className={styles.countersContainer}>
				<div className={`${styles.counter} ${styles.counterRightBorder}`}>
					<p className={styles.counterLabel}>{t('dashboard.monthly_cost_types.FIXED_COST')}</p>
					<div className={styles.counterValue}>
						<MoneyFormat
							value={selectedYear ? fixedCostsTotal : data.fixedCostsTotal}
							style={{ color: DASHBOARD_COLORS.monthlyCost.FIXED_COST }}
							fixedDecimalScale={true}
						/>
					</div>
				</div>
				<div className={`${styles.counter} ${styles.counterRightBorder}`}>
					<p className={styles.counterLabel}>{t('dashboard.monthly_cost_types.VARIABLE_COST')}</p>
					<div className={styles.counterValue}>
						<MoneyFormat
							value={selectedYear ? variableCostsTotal : data.variableCostsTotal}
							style={{ color: DASHBOARD_COLORS.monthlyCost.VARIABLE_COST }}
							fixedDecimalScale={true}
						/>
					</div>
				</div>
				<div className={styles.counter}>
					<p className={styles.counterLabel} style={{ fontWeight: 'bold' }}>
						{t('dashboard.monthly_cost_total_label')}
					</p>
					<div className={styles.counterValue}>
						<MoneyFormat value={selectedYear ? costsTotal : data.total} style={{ color: Colors.success, fontWeight: 'bold' }} fixedDecimalScale={true}/>
					</div>
				</div>
			</div>

			<div className={styles.chartContainer} ref={containerRef}>
				<svg width={width} height={height} viewBox={`0 0 ${viewWidth} ${viewHeight}`}>
					{/* Average Line */}
					{itemsData.length > 0 && (
						<VictoryChart
							standalone={false}
							width={chartWidth}
							height={chartHeight}
							padding={chartPadding}
							maxDomain={{ y: highestValue }}
							minDomain={{ y: 0 }}
						>
							<VictoryLine
								style={{
									data: {
										stroke: Colors.primaryDark,
										strokeWidth: 1,
										strokeDasharray: 4,
									},
								}}
								data={[
									{ x: 0, y: average },
									{ x: 1, y: average },
								]}
							/>
							<VictoryAxis
								dependentAxis
								standalone={false}
								tickValues={allValues}
								style={{
									axis: {
										stroke: 'transparent',
										fill: 'none',
									},
									ticks: {
										stroke: 'transparent',
										fill: 'none',
									},
									axisLabel: {
										stroke: 'transparent',
										fill: 'none',
									},
								}}
								tickLabelComponent={<ChartEmptyLabel />}
							/>
						</VictoryChart>
					)}

					{/* Main bars chart */}
					{itemsData.length > 0 && (
						<VictoryChart
							standalone={false}
							width={chartWidth}
							height={chartHeight}
							padding={chartPadding}
							scale={{ x: 'time' }}
							domainPadding={{ x: itemsData.find(x => x.items.length > 1) ? 50 : 350 }}
							key={'chart-' + reRenderKey}
						>
							<VictoryGroup offset={22}>
								{itemsData.map((item, i) => (
									<VictoryBar
										key={'bar-' + item.type + '-' + i}
										data={item.items}
										x="date"
										y="value"
										alignment="middle"
										style={{
											data: { fill: DASHBOARD_COLORS.monthlyCost[item.type], cursor: 'pointer' },
										}}
										cornerRadius={{ top: barWidth / 2 }}
										barWidth={barWidth}
										labels={() => null}
										labelComponent={<BarLabel fill={DASHBOARD_COLORS.monthlyCost[item.type]} />}
										events={[
											{
												target: 'data',
												eventHandlers: {
													onMouseEnter: () => {
														return [
															{
																target: 'labels',
																mutation: propsData => {
																	const fill = propsData.style && propsData.style.fill;
																	return fill === DASHBOARD_COLORS.monthlyCost[item.type]
																		? null
																		: {
																				text: Utils.formatCurrency(
																					propsData.datum.value,
																				)
																		};
																},
															},
														];
													},
													onMouseLeave: () => {
														return [
															{
																target: 'labels',
																mutation: () => {
																	return null;
																}
															}
														];
													}
												},
											},
										]}
									/>
								))}
							</VictoryGroup>
							<VictoryAxis
								orientation="bottom"
								width={chartWidth}
								height={chartHeight}
								tickValues={allDates}
								tickFormat={x => Utils.capitalize(monthsDiff > 5 ? dayjs(x).locale(locale).format('MMMM')[0] : dayjs(x).locale(locale).format('MMMM'))}
								style={{
									tickLabels: { fill: Colors.black, userSelect: 'none', fontSize: 15 },
									axis: { stroke: Colors.gray3 },
								}}
							/>
						</VictoryChart>
					)}

					{/* Legends */}
					<VictoryLegend
						standalone={false}
						x={0}
						y={chartHeight}
						gutter={40}
						data={legendData}
						orientation={'horizontal'}
						itemsPerRow={3}
						labelComponent={<ChartLegendLabel onClick={onClickLegend} />}
						style={{ labels: { fontSize: 15, userSelect: 'none' } }}
					/>
				</svg>
			</div>

		</div>
	);
};

export default MonthlyCostChart;
