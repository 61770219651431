import Box from '../../../../common/components/box/Box';
import Logger from '../../../../common/services/Logger';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import styles from './AlarmTab.module.scss';
import Loading from '../../../../common/services/Loading';
import AlarmsService from '../../../../api/alarms/AlarmsService';
import { DEFAULT_LANGUAGE, LOGGER_LOG_TYPE, STORAGE } from '../../../../Config';
import Button from 'common/components/button/Button';
import ToggleSwitch from 'common/components/toggleSwitch/ToggleSwitch';
import Label from 'common/components/label/Label';
import FormItem from 'common/components/formItem/FormItem';
import { Col, Row } from 'react-flexbox-grid';
import InputController from 'common/components/input/InputController';
import InputError from 'common/components/inputError/InputError';
import { AlarmSettingDaysOfWeekDto, AlarmSettingDto, AlarmTypeDto, AlarmTypeFieldDto } from 'api/alarms/models/AlarmSettingDto';
import DrawTools, { LocationArea } from 'common/components/maps/DrawTools';
import { Circle, MapContainer, TileLayer } from 'react-leaflet';
import { useHistory, useParams } from 'react-router-dom';
import TimePickerController from 'common/components/timePicker/TimePickerController';
import IconBack from 'assets/svg/Arrow-grey.svg';
import SelectController from 'common/components/select/SelectController';
import Utils from 'common/services/Utils';
import InputGroupController from 'common/components/inputGroup/InputGroupController';
import ZoomLevelControl from 'common/components/maps/ZoomLevelControl';
import moment from 'moment';
import Storage from '../../../../common/services/Storage';
import toggleSwitchStyle from 'common/components/toggleSwitch/ToggleSwitch.module.scss'

type Props = {
    alarm: AlarmSettingDto | null;
    onSelectDayOfWeek: (day: number, value: boolean) => void;
    daysOfWeekSelected: AlarmSettingDaysOfWeekDto[];
    form: any;
} & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLDivElement>, HTMLDivElement>;


const AlarmTab = React.forwardRef<HTMLDivElement, Props>(({ alarm, form, daysOfWeekSelected, onSelectDayOfWeek }, ref) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const history = useHistory();

    const { type } = useParams<{ type: string }>();
    const [isDetails, setIsDetails] = useState<boolean>(type === 'details');

    const [selectedAlarmType, setSelectedAlarmType] = useState<AlarmTypeDto>();
    const [alarmTypes, setAlarmTypes] = useState<AlarmTypeDto[]>([]);

    const [area, setArea] = useState<LocationArea>();
    const [position, setPosition] = useState<GeolocationPosition>();
    const [zoomDefault, setZoomDefault] = useState<number>(12);

    const { handleSubmit, setValue, errors, register } = form;

    const [refresh, setRefresh] = useState<number>(1);

    const [formValues, setFormValues] = useState<any>({});
    const [reloadData, setReloadData] = useState<boolean>(false);

    const [defaultWeekdays, setDefaultWeekdays] = useState<string[]>([]);

    const weekdaysChecked = (i: number) => {
        return daysOfWeekSelected.map(x=>x.dayOfWeek).includes(i);
    };

    const getData = async () => {
        try {
            Loading.show();

            const types = await AlarmsService.getTypes();
            setAlarmTypes(types);
            setReloadData(true);

        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.ALARMS, `Couldn't get information to create alarm`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        } finally {
            Loading.hide();
        }
    }

    const loadData = async () => {
        if (!alarm && !Object.keys(formValues).length) { return; } // Verifica se há valores armazenados localmente

        try {

            const alarmData = isDetails ? alarm : formValues;

            if (alarmData.type) {
                const typeSelected = alarmTypes.find(x => x.name === alarmData.type);
                onChangeType(typeSelected);
            }

            const dataMappings = {
                id: alarm?.id,
                rowVersion: alarm?.rowVersion,
                name: alarmData.name,
                enabled: alarmData.enabled,
                notificationsEnabled: alarmData.notificationsEnabled,
                startHour: alarmData.startHour,
                stopHour: alarmData.stopHour,
                limit: alarmData.limit,
                latitude: alarmData.latitude,
                longitude: alarmData.longitude,
                radius: alarmData.radius,
                duration: alarmData.duration
            };

            Object.entries(dataMappings).forEach(([key, value]) => setValue(key, value));

            if (alarmData.latitude && alarmData.longitude && alarmData.radius) {
                const areaAux = { latitude: alarmData.latitude, longitude: alarmData.longitude, mRadius: alarmData.radius, radius: alarmData.radius };
                onCreateArea(areaAux);
            }
            setZoomDefault(alarmData.zoom ?? zoomDefault);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.ALARMS, `Couldn't load alarm information`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const navigateTo = (typeUrl?: string, id?: string) => {
        if (typeUrl) {
            history.push(`/alarms/${typeUrl}/${id}`);
            // setIsDetails(typeUrl === 'details');
        } else {
            history.push(`/alarms`);
        }
    }

    const onChangeType = (at?: AlarmTypeDto) => {
        clearForm();
        setSelectedAlarmType(at);
        form.setValue('type', at ? at.name : undefined);
    }

    const clearForm = () => {
        setValue('rowVersion', alarm?.rowVersion);
        setValue('type', undefined);
        setValue('name', '');
        setValue('enabled', false);
        setValue('notificationsEnabled', false);
        setValue('startHour', undefined);
        setValue('stopHour', undefined);
        setValue('limit', undefined);
        setValue('latitude', undefined);
        setValue('longitude', undefined);
        setValue('radius', undefined);
    };

    const clearArea = () => {
        setArea(undefined);
        setValue('latitude', '');
        setValue('longitude', '');
        setValue('radius', '');
    }

    const onCreateArea = (a: LocationArea) => {
        setArea(a);
        setValue('latitude', a.latitude);
        setValue('longitude', a.longitude);
        setValue('radius', a.mRadius);
    }

    const setCurrentPosition = (p: GeolocationPosition) => {
        setPosition(p);
        setRefresh(refresh + 1);
    }

    const onChangeCoordinates = () => {
        if (form.getValues('latitude') && form.getValues('longitude')) {
            const p: GeolocationPosition = {
                coords: {
                    latitude: form.getValues('latitude'),
                    longitude: form.getValues('longitude'),
                    accuracy: 0,
                    altitude: null,
                    altitudeAccuracy: null,
                    heading: null,
                    speed: null
                },
                timestamp: Date.now()
            };
            setCurrentPosition(p);
            setZoomDefault(zoomDefault);
            if (form.getValues('radius')) {
                setArea({ latitude: form.getValues('latitude'), longitude: form.getValues('longitude'), mRadius: form.getValues('radius'), radius: form.getValues('radius') });
            }
        }
    }

    const renderMap = () => {
        return <>
            <MapContainer
                key={`map_${refresh}`}
                center={[area ? area.latitude : position?.coords?.latitude ?? 52.516316, area ? area.longitude : position?.coords?.longitude ?? 13.377825]}
                maxZoom={18} minZoom={6} zoom={zoomDefault} scrollWheelZoom={true} zoomSnap={0.1} zoomDelta={0.5} className={styles.map}>
                {zoomDefault && <ZoomLevelControl onZoomChange={ zoom => { setZoomDefault(zoom); setValue('zoom', zoom); } } />}
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {area ? <Circle center={[area.latitude, area.longitude]} radius={area.mRadius}></Circle> :
                    (isDetails ? <></> : <DrawTools onCreated={onCreateArea} />)
                }
            </MapContainer>
        </>
    }

    const renderForm = (f: AlarmTypeFieldDto) => {
        switch (f.name) {
            case 'name': return (
                <Col xs={12} key={`${f.name}_${refresh}`}>
                    <FormItem key={`${f.name}_${refresh}`}>
                        <Label className={styles.label}>{t('alarms.list.name')}{!isDetails && f.required ? '*' : ''}</Label>
                        <InputController
                            name="name"
                            form={form as any}
                            autoComplete='off'
                            disabled={isDetails}
                            placeholder={t('alarms.list.name')}
                            rules={{ required: !isDetails && f.required, validate: value => !Utils.isStringNullOrEmpty(value), maxLength: 250 }}
                        />
                        <InputError error={errors.name} maxLength={250} />
                    </FormItem>

                </Col>

            )
            case 'start_hour': return (
                <>
                <Col xs={12}>
                <Label className={styles.label}>{t('alarms.day_of_week')}{!isDetails && f.required ? '*' : ''}</Label>
                </Col>
                { defaultWeekdays.map((day, index) => (
                <Col xs={12} md={3} lg={1} key={`${refresh}-day--${day}`} className="mb-3">
                     <FormItem key={`${f.name}_${refresh}-day--${day}`}>
                     <Label>{day}</Label>
                    <label
                        style={{ cursor: isDetails ? 'no-drop' : 'pointer' }}
                        className={`${toggleSwitchStyle.toggleSwitch} ${toggleSwitchStyle.enabled}`}>
                        <input
                            type='checkbox'
                            id={`checkbox_day--${day}`}
                            checked={weekdaysChecked(index)}
                            onChange={e => onSelectDayOfWeek(index, e.target.checked)}
                            disabled={isDetails}
                        />
                        <span className={toggleSwitchStyle.switch} />
                    </label>
                    </FormItem>
                </Col>))}
                <Col xs={12} md={6} key={`${f.name}_${refresh}`}>
                    <FormItem key={`${f.name}_${refresh}`}>
                        <Label className={styles.label}>{t('alarms.start_hour')}{!isDetails && f.required ? '*' : ''}</Label>
                        <TimePickerController
                            form={form}
                            name="startHour"
                            isDisabled={isDetails}
                            timePlaceholder={t('alarms.start_hour')}
                            isClearable={true}
                        />

                        <InputError error={errors.startHour} />
                    </FormItem>
                </Col></>
            )
            case 'stop_hour': return (
                <Col xs={12} md={6} key={`${f.name}_${refresh}`}>
                    <FormItem key={`${f.name}_${refresh}`}>
                        <Label className={styles.label}>{t('alarms.stop_hour')}{!isDetails && f.required ? '*' : ''}</Label>

                        <TimePickerController
                            form={form}
                            name="stopHour"
                            isDisabled={isDetails}
                            timePlaceholder={t('alarms.stop_hour')}
                            isClearable={true}
                        />
                        <InputError error={errors.stopHour} />

                    </FormItem>
                </Col>
            )
            case 'limit': return (
                <Col xs={12} md={12} key={`${f.name}_${refresh}`}>
                    <FormItem key={`${f.name}_${refresh}`}>
                        <Label className={styles.label}>{t('alarms.limit')}{!isDetails && f.required ? '*' : ''}</Label>
                        <InputController
                            name={f.name}
                            form={form as any}
                            type="number"
                            min="0"
                            autoComplete='off'
                            disabled={isDetails}
                            placeholder={t('alarms.limit')}
                            rules={{ required: !isDetails && f.required }}
                        />
                        <InputError error={errors.limit} />
                    </FormItem>
                </Col>
            )
            case 'latitude': case 'longitude': return (
                <Col xs={12} md={6} xl={3} key={`${f.name}_${refresh}`}>
                    <Label className={styles.label}>{t('alarms.' + f.name as any)}{!isDetails && f.required ? '*' : ''}</Label>
                    <InputController
                        key={f.name}
                        name={f.name}
                        type="number"
                        min="0"
                        form={form as any}
                        autoComplete='off'
                        disabled={isDetails}
                        placeholder={t('alarms.latitude')}
                        rules={{ required: !isDetails && f.required }}
                        onKeyPress={(e: any) => {
                            if (e.key === 'Enter') {
                                onChangeCoordinates();
                            }
                        }}
                        onBlur={() => {
                            onChangeCoordinates();
                        }}
                    />
                    <InputError error={errors[f.name]} />
                </Col>
            )
            case 'duration': return (
                <Col xs={12} md={isDetails ? 6 : 4} xl={isDetails ? 3 : 2} key={`${f.name}_${refresh}`}>
                    <FormItem key={`${f.name}_${refresh}`}>
                        <Label className={styles.label}>{t('alarms.duration')}{!isDetails && f.required ? '*' : ''}</Label>
                        <InputController
                            name={f.name}
                            form={form as any}
                            type="time"
                            disabled={isDetails}
                            placeholder={t('alarms.duration')}
                            rules={{ required: !isDetails && f.required }}
                        />
                        <InputError error={errors.limit} />
                    </FormItem>
                </Col>
            );
            case 'radius': return (<>
                <Col xs={12} md={isDetails ? 6 : 4} xl={isDetails ? 3 : 2} key={`${f.name}_${refresh}`}>
                    <Label className={styles.label}>{t('alarms.radius')}{!isDetails && f.required ? '*' : ''}</Label>
                    <InputGroupController
                        name={f.name}
                        text={t('common.meters')}
                        type="number"
                        min="0"
                        form={form as any}
                        autoComplete='off'
                        disabled={isDetails}
                        placeholder={t('alarms.radius')}
                        rules={{ required: !isDetails && f.required }}
                        onKeyPress={(e: any) => {
                            if (e.key === 'Enter') {
                                onChangeCoordinates();
                            }
                        }}
                        onBlur={() => {
                            onChangeCoordinates();
                        }}
                    />

                    <InputError error={errors[f.name]} />
                </Col>
                { !isDetails && <Col xs={12} md={4} xl={2} key={'btn_' + f.name}>
                    <div className={styles.smallButtonsContainer}>
                        <Button
                            preset={'danger'}
                            type="button"
                            onClick={clearArea}>
                            {t('common.clear')}
                        </Button>
                    </div>
                </Col>}

                    <Col xs={12}>
                <Row className={styles.map} key={`map_${ref}`} >
                        {renderMap()}
                </Row>
                    </Col>
            </>)
            default:
                break;
        }
    }

    useEffect(() => {
        getData();
        navigator.geolocation.getCurrentPosition((x: GeolocationPosition) => setCurrentPosition(x));
    }, []);

    useEffect(() => {
        setIsDetails(type === 'details');
        setDefaultWeekdays(Array(... new Array(7)).map(function (_, i) {
            moment.locale(Storage.get(STORAGE.CURRENT_LOCALE) ?? DEFAULT_LANGUAGE,{ week: { dow: 0 } });
            return moment().startOf('week').weekday(i).format('ddd');
        }));
        // Armazena os valores do formulário quando a guia mudar
        if (type !== 'details') {
            setFormValues(form.getValues());
        }
    }, [type]);


    useEffect(() => {
        loadData();
    }, [alarm, reloadData]);


    return (
        <form onSubmit={handleSubmit} key={'form_' + refresh}>
            <Box className={styles.box}>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={8} xl={9}>
                        <div className={styles.header}>
                            {type === 'new' && <><img src={IconBack} className={styles.icon} onClick={() => navigateTo()} /> {t('alarms.new')}</>}
                            {type == 'edit' && <><img src={IconBack} className={styles.icon} onClick={() => navigateTo()} /> {t('alarms.edit')}</>}
                            {type == 'details' && <><img src={IconBack} className={styles.icon} onClick={() => navigateTo()} /> {t('alarms.details')}</>}
                        </div>

                    </Col>
                </Row>

                <Row>
                    <Col xl={12} key={'type' + ref}>
                        <FormItem>
                            <Label className={styles.label}>{t('alarms.list.type')}*</Label>

                            <SelectController
                                ref={register({ required: true })}
                                form={form}
                                name="type"
                                isClearable={true}
                                placeholder={t('common.select_option')}
                                options={alarmTypes.map(x => ({
                                    value: x.name,
                                    label: t('alarms.types.' + x.name as any)
                                })).sort((a, b) => a.label.localeCompare(b.label))}
                                isDisabled={isDetails}
                                rules={{ required: !isDetails }}
                                onChange={(x: any) => {
                                    const selected = x ? alarmTypes.find(y => y.name === x.value) : undefined;
                                    onChangeType(selected);
                                }}

                            />
                            {!isDetails && <InputError error={errors.type} />}
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    {selectedAlarmType?.fields.map(renderForm)}
                </Row>

                <Row>
                    <Col xl={12}>
                        <FormItem key={`enabled_${refresh}`}>
                            <Label className={styles.label}>{t('alarms.enabled')}</Label>
                            <ToggleSwitch
                                name="enabled"
                                form={form}
                                isDetails={isDetails}
                            />
                        </FormItem>
                    </Col>

                    <Col xl={12}>
                        <FormItem key={`sendNotification_${refresh}`}>
                            <Label className={styles.label}>{t('alarms.send_notification')}</Label>
                            <ToggleSwitch
                                name="notificationsEnabled"
                                form={form}
                                isDetails={isDetails}
                            />

                        </FormItem>
                    </Col>
                </Row>
            </Box>
        </form >
    );
});

export default AlarmTab;