import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './VehicleFuelFormItem.module.scss';
import { Col, Row } from 'react-flexbox-grid';
import FormItem from 'common/components/formItem/FormItem';
import { FUELS, removeAccents } from 'Config';
import { ReactComponent as TrashIcon } from 'assets/svg/trash_no_color.svg';
import { Controller, UseFormMethods } from 'react-hook-form';
import Label from 'common/components/label/Label';
import InputError from 'common/components/inputError/InputError';
import { VehicleViewModel } from 'api/vehicles/models/VehicleViewModel';
import SelectController from 'common/components/select/SelectController';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import InputGroup from 'common/components/inputGroup/InputGroup';

type Props = {
    form: UseFormMethods<VehicleViewModel>;
    isDetails?: boolean
    onRemoveClick: () => void;
    index: number;
    mode?: string;
    fuelsOptions: SelectValueLabel[];
    setFuelReRender: () => void;
    vehicleFuelsCounter: number,
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const VehicleFuelFormItem: React.FC<Props> = ({
    form,
    isDetails,
    onRemoveClick,
    index,
    mode,
    fuelsOptions,
    setFuelReRender,
    vehicleFuelsCounter,
    ...props
}: Props) => {
    const { t } = useTranslation();
    const errors = (form.errors?.vehicleFuels || [])[index] || null;

    form.setValue(`vehicleFuels[${index}].index`, index);

    return (
        <div
            {...props}
            className={`${styles.container} ${(errors && styles.containerError) || ''} ${props.className || ''}`}
            style={{
                ...(props.style || {}),
            }}
        >
            <Row>

                <Col xs={!isDetails ? 10 : 12} lg={!isDetails ? 4 : 6} xl={!isDetails ? 5 : 6}>
                    <FormItem>
                        <Label className={styles.label}>{t('vehicle.fuel')} *</Label>
                        <SelectController
                            form={form}
                            name={`vehicleFuels[${index}].fuelId`}
                            placeholder={t('vehicle.fuel')}
                            isDisabled={mode === 'details'}
                            options={fuelsOptions}
                            rules={{ required: true }}
                            isClearable={true}
                            filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                            onChangeSelect={(data: SelectValueLabel) => {
                                form.setValue(`vehicleFuels[${index}].fuelId`, data ? data.value : null);
                                setFuelReRender();
                            }}
                        />
                        <InputError error={errors?.fuelId} />
                    </FormItem>
                </Col>

                <Col xs={12} lg={6} xl={6}>
                    <FormItem>
                        <Label className={styles.label}>{t('vehicle.average_reference_consumption')}</Label>
                        <Controller
                            render={({ }) => {
                                return (
                                    <InputGroup
                                        style={{ textAlign: 'end' }} format={'number'}
                                        placeholder={t((fuelsOptions.find(x => x.value == form.getValues(`vehicleFuels[${index}].fuelId`))?.fullLabel == FUELS.ELECTRIC ? 'vehicle.eletric_consumption_unit' : 'vehicle.average_consumption_unit') as any)}
                                        text={t((fuelsOptions.find(x => x.value == form.getValues(`vehicleFuels[${index}].fuelId`))?.fullLabel == FUELS.ELECTRIC ? 'vehicle.eletric_consumption_unit' : 'vehicle.average_consumption_unit') as any)}
                                        value={form.getValues(`vehicleFuels[${index}].consommationMoyenneReference`)}
                                        onValueChange={value => {
                                            form.setValue(`vehicleFuels[${index}].consommationMoyenneReference`, value);
                                        }}
                                        disabled={mode === 'details'} />
                                );
                            }}
                            control={form.control}
                            name={`vehicleFuels[${index}].consommationMoyenneReference`}
                        />
                    </FormItem>
                </Col>
                {!isDetails && vehicleFuelsCounter > 1 &&
                    <Col xs={2} lg={2} xl={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                        <div className={styles.removeButton}>
                            <TrashIcon className={styles.removeIcon} onClick={onRemoveClick} />
                        </div>
                    </Col>}
            </Row>
        </div >
    );
}

export default VehicleFuelFormItem;
