import React, { useRef, useState } from 'react';
import Timekeeper from 'react-timekeeper';
import { FaRegClock } from 'react-icons/fa';
import styles from './TimePicker.module.scss';
import InputGroup from '../inputGroup/InputGroup';
import { Controller } from 'react-hook-form';
import dayjs from 'dayjs';

const resetTime = (value: Date) => {
    return dayjs(value)
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0)
        .toDate(); // Convert back to Date object
}

const formatTimeNumber = (n: number) => {
    return n.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
    });
}

type Props = {
    form: any;
    isDisabled: boolean;
    isClearable?: boolean;
    name: string;
    timePlaceholder?: string;
    className?: string;
    minTime?: Date;
    onChange?: (date: Date) => void;
    onChangeTime?: (date: Date) => void;
};

const TimePickerController = ({ form, timePlaceholder, isDisabled, isClearable, className, name, minTime, onChange, onChangeTime }: Props) => {

    const [showTime, setShowTime] = useState(false);
    const [reset, setReset] = useState(false); // State to control reset
    const ref = useRef(null);

    return (
        <Controller
            render={({ value, onChange: onChangeCtrl }) => {
                const timeInput = {
                    hour: value ? dayjs(value).get('hours') : 0,
                    minute: value ? dayjs(value).get('minutes') : 0
                };

                const handleClear = () => {
                    onChangeCtrl(null); // Clear the value
                };


                return (
                    <div className={`${styles.container} ${className ? className : ''}`}>
                        <div className={styles.flex}>
                            {showTime &&
                                <div style={{ zIndex: 5, position: 'relative', maxHeight: '1rem', display: 'flex' }}>
                                    <Timekeeper

                                        closeOnMinuteSelect={true}
                                        hour24Mode={true}
                                        disabledTimeRange={minTime ? { from: '00:00', to: minTime?.getMinutes().toString() } : undefined}
                                        switchToMinuteOnHourSelect
                                        time={timeInput}
                                        onDoneClick={hour => {
                                            setShowTime(false);

                                            if (!hour || !hour.formatted24 || reset) {
                                                // Reset time if 'reset' is true or no hour is selected
                                                onChangeCtrl(resetTime(value));
                                                setReset(false); // Reset 'reset' state
                                            } else {
                                                const time = hour.formatted24.split(':');

                                                const newDate = dayjs(value || resetTime(new Date()))
                                                    .set('hour', Number(time[0]))
                                                    .set('minute', Number(time[1]))
                                                    .toDate();

                                                onChangeCtrl(newDate);
                                                if (onChange) {
                                                    onChange(newDate)
                                                }
                                                if (onChangeTime) {
                                                    onChangeTime(newDate)
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            }
                            {!showTime &&
                                <InputGroup
                                    value={value ? `${formatTimeNumber(timeInput.hour)}:${formatTimeNumber(timeInput.minute)}` : ''}
                                    placeholder={timePlaceholder}
                                    onClick={() => setShowTime(true)}
                                    isClearable={isClearable}
                                    disabled={isDisabled}
                                    icon={<FaRegClock />}
                                    onClear={handleClear}
                                    ref={ref}
                                />
                            }
                        </div>
                    </div>
                );
            }}
            onFocus={() => (ref.current as any).input.focus()}
            control={form.control}
            name={name}
            defaultValue={form.getValues(name) || undefined}
        />
    );
};

export default TimePickerController;