import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import VehicleMonthlyCostsService from 'api/vehicleMonthlyCosts/VehicleMonthlyCostsService';
import Loading from 'common/services/Loading';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Reducers } from 'store/types';

const CalculateMonthlyCostsScreen: React.FC = () => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { companyId } = useParams<{ companyId: string }>();
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);

    const getData = async () => {
        if(UsersService.isPlatformAdmin(userProfile?.roles)){
            try {
                Loading.show();
                await VehicleMonthlyCostsService.calculateMonthlyCosts(companyId);
                Loading.hide();
                addToast(t('common.messages.record_save_success'), { appearance: 'success' });
            } catch (error) {
                Loading.hide();
                addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            }
        }
    }

    useEffect(() => {
        getData();
    }, []);

    return null;
};

export default CalculateMonthlyCostsScreen;
