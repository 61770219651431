import { LanguageDto } from 'api/languages/models/LanguageDto';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { FaExclamationTriangle, FaLanguage } from 'react-icons/fa';
import styles from './LanguagesTab.module.scss';

type Props = {
    selectedLanguageIndex: number;
    languages: LanguageDto[];
    onChange: (language: LanguageDto, i: number) => void;
    children: ReactNode | ReactNode[] | ((lang: LanguageDto, index: number) => (ReactNode | ReactNode[]));
    functional?: boolean;
}

const LanguagesTab = ({ selectedLanguageIndex, languages, onChange, children, functional }: Props) => {
    const { t } = useTranslation();
    
    return (
        <div>
            <div className={styles.tabs}>
                {languages.map((lang, i) => (
                    <div
                        key={lang.id} 
                        className={`${styles.tab} ${i === selectedLanguageIndex ? styles.active : ''}`}
                        onClick={() => onChange(lang, i)}
                    >
                        {lang.invalid && <FaExclamationTriangle className={styles.invalid}/>}
                        {lang.isDefault && <FaLanguage className={styles.languageDefault} title={t('common.language_default')}/>}
                        {lang.name}
                    </div>
                ))}
            </div>
            <div>
                {functional && children ? languages.map(children as any) : children}
            </div>
        </div>
    );
}

export default LanguagesTab;
