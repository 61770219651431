import React from 'react';
import Modal from 'react-modal';
import styles from './VerificationExistModal.module.scss';
import { VerificationOverlap } from '../CarVerification';
import { useTranslation } from 'react-i18next';
import Button from 'common/components/button/Button';
import { useSelector } from 'react-redux';
import { UserProfile } from 'api/account/models/UserProfile';
import { Reducers } from 'store/types';

type Props = {
    isVisible: boolean;
    item?: VerificationOverlap | null;
    onCancel: () => void;
    onFinish?: () => void;
};

const VerificationExistModal: React.FC<Props> = ({ isVisible, item, onCancel, onFinish }: Props) => {

    const { t } = useTranslation();
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    
    const customStyles = {
        content: {
            top: '30%',
            left: '35%',
            right: '35%',
            bottom: 'auto',
            backgroundClip: 'padding-box',
            backgroundColor: '#FFFFFF',
            border: '1px solid transparent',
            borderRadius: '4px',
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.3)',
            outline: '0 none',
        },
        overlay: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }
    };

    return (
        <Modal isOpen={isVisible} style={customStyles}>

            <div className={styles.textTitle}> {t('common.warning_title')}</div>

            <div className={styles.messageContainer}>
                <div className={styles.textMessage}>
                    {onFinish && (item?.driverId == userProfile?.id || item?.responsableId == userProfile?.id) ?
                        t('car_verification.verification_vehicle_exist_message', { registrationNumber: item?.vehicleRegistrationNumber })
                        :
                        t('car_verification.verification_exist_message')}
                </div>
            </div>

            <div className={styles.buttonsContainer}>
                <Button
                    className={styles.button}
                    preset={'secondary'}
                    type='button'
                    text={t('common.cancel')}
                    onClick={onCancel}
                />
                {onFinish && (item?.driverId == userProfile?.id || item?.responsableId == userProfile?.id) && (
                    <Button
                        preset={'primary'}
                        type='button'
                        text={t('car_verification.verification.finish_button')}
                        onClick={onFinish}
                    />
                )}
            </div>

        </Modal>
    );
}

export default VerificationExistModal;