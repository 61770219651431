import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import { useToasts } from 'react-toast-notifications';
import Loading from 'common/services/Loading';
import VehicleModelsService from 'api/vehicleModels/VehicleModelsService';
import Logger from 'common/services/Logger';
import { Paged } from 'api/common/types/Page';
import InputSearch from 'common/components/inputSearch/InputSearch';
import { useDebouncedCallback } from 'use-debounce';
import { VehicleModelsDto } from 'api/vehicleModels/models/VehicleModelsDto';
import { VehicleModelsSearchCriteria } from 'api/vehicleModels/models/VehicleModelsSearchCriteria';
import Dropdown from 'common/components/dropdown/Dropdown';
import DropdownItem from 'common/components/dropdown/DropdownItem';
import { FaEllipsisH } from 'react-icons/fa';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import Popover from 'common/components/popover/Popover';
import VehicleModelsFiltersScreen, { Filters } from '../vehicleModelsFilters/VehicleModelsFiltersScreen';
import { useHistory } from 'react-router-dom';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import FiltersIcon from 'assets/svg/desktop_filtres.svg';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import styles from './VehicleModelList.module.scss';

type Props = {
};

const VehicleModelList: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const history = useHistory();

    const [vehicleModelsPage, setVehicleModelsPage] = useState<Paged<VehicleModelsDto>>();
    const [criteria, setCriteria] = useState<VehicleModelsSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderColumn: 'name',
        orderBy: 'asc'
    });

    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<VehicleModelsDto | null>(null);

    const [filters, setFilters] = useState<Filters>({});
    const [filtersTotal, setFiltersTotal] = useState(0);

    const showRemoveItemDialog = (item: VehicleModelsDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasVehicleModelsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_VEHICLE_MODELS_WRITE']);

    const renderTableActionCell = (row: VehicleModelsDto) => {
        return <Dropdown
            options={
                <>
                    <DropdownItem url={`/settings/models/details/${row.id ?? ''}`}>
                        {t('common.details')}
                    </DropdownItem>
                    {hasVehicleModelsWritePolicy && <DropdownItem url={`/settings/models/edit/${row.id ?? ''}`}>
                        {t('common.edit')}
                    </DropdownItem>}
                    {hasVehicleModelsWritePolicy && <DropdownItem onClick={() => showRemoveItemDialog(row)}>
                        {t('common.remove')}
                    </DropdownItem>}
                </>
            }
        >
            <div><FaEllipsisH /></div>
        </Dropdown>
    }

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const tableColumns: ListingTableColumn<VehicleModelsDto>[] = [
        {
            field: 'name',
            name: t('vehicle_models.list.name'),
            onSearch: onTableFilter,
            searchField: 'vm.name'
        },
        {
            field: 'vehicleBrandName',
            name: t('vehicle_models.list.vehicle_brands'),
            onSearch: onTableFilter,
            searchField: 'vb.name'
        },
        {
            name: '',
            width: '60px',
            cellAlignment: 'right',
            preventClick: true,
            renderCell: renderTableActionCell,
            cellStyle: { overflow: 'unset' }
        }
    ];

    const getData = async () => {
        try {
            Loading.show();

            const page = await VehicleModelsService.getList(criteria);
            setVehicleModelsPage(page);

            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't get vehicle models list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }

        try {
            await VehicleModelsService.remove(itemToRemove);
            setCriteria({ ...criteria, page: 1 });
            onCancelRemove();
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete vehicle model`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
        }
    };

    useEffect(() => {
        getData();
    }, [criteria]);

    const debounced = useDebouncedCallback((value: string) => {
        setCriteria({ ...criteria, page: 1, allIn: value });
    }, 500);

    const onChangeFilters = (filters: Filters) => {
        updateTotalFilters(filters);
        setFilters(filters);
        criteria.brandId = filters.brandId;
        getData();
    }

    const updateTotalFilters = (filters: Filters) => {
        let total = 0;

        if (filters.brandId && filters.brandId.length > 0) {
            total++;
        }

        setFiltersTotal(total);
    }

    return (
        <div className='list'>
            <div className={styles.tabHeader}>
                <div>
                    <InputSearch onChangeValue={debounced} placeholder={t('common.search')} ></InputSearch>
                </div>
                <div className={styles.rowFlex}>
                    <Popover
                        contentContainerClassName={styles.filtersPopoverContainer}
                        containerStyle={{ boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}
                        positions={['bottom', 'left']}
                        align={'end'}
                        onClickOutside={() => updateTotalFilters(filters)}
                        content={setIsPopoverOpen => <VehicleModelsFiltersScreen
                            filters={filters}
                            onFilter={filters => { setIsPopoverOpen(false); onChangeFilters(filters) }}
                            onChange={filters => updateTotalFilters(filters)}
                        />}>
                        {(isPopoverOpen, setIsPopoverOpen) => (
                            <ScreenHeaderButton icon={FiltersIcon} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                            {filtersTotal > 0 &&
                                <div className={styles.counterList}> <div className={styles.counterNumber}>{filtersTotal}</div> </div>
                            }
                            </ScreenHeaderButton>
                        )}
                    </Popover>
                    {hasVehicleModelsWritePolicy && <ScreenHeaderButton icon={AddIcon} onClick={() => history.push(`/settings/models/new`)} /> }
                </div>
            </div>
            <ListingTable
                columns={tableColumns}
                rows={vehicleModelsPage?.items || []}
                onRowClick={row => history.push(`/settings/models/details/${row.id ?? ''}`)}
                onHref={row => `/settings/models/details/${row.id ?? ''}`}
                allowHover={true}
                initialSearch={{ colField: 'vm.name', isOrderAsc: true }}
            />
            <PaginationWithInfo
                itemName={t('vehicle_models.list.vehicle_models')}
                currentPage={vehicleModelsPage?.currentPage || 1}
                pageItems={vehicleModelsPage?.items.length || 0}
                totalItems={vehicleModelsPage?.totalItems || 0}
                onChange={page => setCriteria({ ...criteria, page })}
            />
            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('common.messages.remove_vehicle_model', { segment: itemToRemove?.name ?? '' })}/>
        </div>
    );
};

export default VehicleModelList;
