import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

const DistanceFormat: React.FC<NumberFormatProps> = ({ value, ...props }: NumberFormatProps) => {
    const data = Number(value);

    return (
        data >= 1 ?
            <NumberFormat
                value={data}
                displayType={props.displayType ?? 'text'}
                thousandSeparator={' '}
                decimalSeparator={','}
                decimalScale={3}
                fixedDecimalScale={false}
                suffix={' km'}
                {...props}
            />
            :
            props.showLessThan1Km ?
                <div>{'<1 km'}</div>
                :
                <NumberFormat
                    value={data * 1000}
                    displayType={props.displayType ?? 'text'}
                    thousandSeparator={' '}
                    decimalSeparator={','}
                    decimalScale={0}
                    fixedDecimalScale={false}
                    suffix={data == 0 ? ' km' : ' m'}
                    {...props}
                />

    );
};

export default DistanceFormat;
