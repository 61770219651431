import React from 'react';
import styles from './InputInfoWarning.module.scss';

type Props = {
    message?: string;
    isWarning?: boolean;
};

const InputInfoWarning: React.FC<Props> = ({ message, isWarning }: Props) => {
    return <div className={`${styles.container} ${isWarning ? styles.messageWarning : ''}`}>
        {/* <img src={isWarning ? AlertIcon : InfoIcon}/> */}
        <div className={styles.message}>{message}</div>
    </div>;
}

export default InputInfoWarning;
