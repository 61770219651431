import React, { ReactElement } from 'react';
import styles from './FilterList.module.scss';
import FilterBadge from '../filterBadge/FilterBadge';

export type Filter = {
    value?: string | ReactElement | null;
    label?: string;
    children?: ReactElement
    onRemove: () => void;
    showRemoveFilter?: boolean;
};

type Props = {
    filters?: Filter[];
};

const FilterList: React.FC<Props> = ({ filters }: Props) => {
    if(!filters){
        return null;
    } 

    return (
        <div className={styles.filtersList}>
            {filters.filter(x => x?.value || x?.children)
                .map(filter => 
                    <FilterBadge 
                        key={filter.label}
                        title={filter.label}
                        value={filter.value ?? undefined}
                        onRemoveFilter={filter.onRemove}
                        showRemoveFilter={filter.showRemoveFilter}
                    >
                        {filter.children}
                    </FilterBadge>
                )
            }
        </div>
    );
}

export default FilterList;
