import Select, { Props as SelectProps } from './Select';
import { Controller, RegisterOptions, UseFormMethods } from 'react-hook-form';
import { SelectValueLabel } from '../../../common/types/SelectValueLabel';
import { SelectValueLabelDto } from 'api/common/models/SelectValueLabelDto';

type Props = {
    form: UseFormMethods<any>;
    name: string;
    options: SelectValueLabel[] | SelectValueLabelDto[];
    rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
    isClearable?: boolean;
    onChangeSelect?: (data: any) => void;
    removeInputBorderRadius?: boolean;
} & SelectProps;

const SelectController = ({ form, name, options, rules, isClearable, onChangeSelect, removeInputBorderRadius, ...props }: Props) => {
    return (        
        <Controller
            render={({ onChange, value, ref }) => {
                return (                    
                    <Select
                        {...props}
                        options={options}
                        value={props.isMulti 
                            ? value ? options.filter(x => value.find((y: any) => y === x.value)) : []
                            : options.find(x => String(x.value || null).toString() === String(value || null).toString()) || null}
                        ref={ref}
                        isClearable={isClearable}
                        onChange={(data: SelectValueLabel | SelectValueLabel[], e: any) => {

                            if (props.isMulti) {
                                onChange((data as any)?.map((x: any) => x.value));
                            } else {
                                onChange((data as any)?.value);
                            }

                            if (props.onChange) {
                                props.onChange((data as any), e);
                            }
                            onChangeSelect && onChangeSelect(data);
                        }}
                        removeInputBorderRadius={removeInputBorderRadius}
                    />
                );
            }}
            control={form.control}
            name={name}
            defaultValue={form.getValues(name) || null}
            rules={rules}
        />
    );
};

export default SelectController;
