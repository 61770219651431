/* eslint-disable @typescript-eslint/indent */
import L from 'leaflet';
import { createControlComponent } from '@react-leaflet/core';
import 'leaflet-routing-machine';
import { render } from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import { FaMapMarkerAlt } from 'react-icons/fa';
import styles from './RoutineMachine.module.scss';

const RoutingMachineLayer = (props: any) => {
    const { waypoints, renderInfo, hide, loadIconColor } = props;

    const getIconColor = (i: number) => {
        if (loadIconColor) {
            return loadIconColor(i)
        }
        return i == 0 ? '#dd1021' : '#1bd44d';
    }

    const plan = new L.Routing.Plan(waypoints, {
        createMarker: (i, wp, nWps) => {
            const icon = new L.DivIcon({
                html: ReactDOMServer.renderToString(
                    <FaMapMarkerAlt style={{
                        fontSize: '30px',
                        color: getIconColor(i),
                    }} />
                ), // Renderiza o ícone do FaMapMarkerAlt como HTML
                className: styles.pin,
                iconAnchor: [15, 30],//para a base do icon ficar exatamente nas coordenadas e não ficar centrado
                popupAnchor: [1, -34]
            })

            if (hide && i > 0 && i < waypoints.length - 1) {
                return false;
            }

            const marker = L.marker(wp.latLng, { icon: icon })
                .bindPopup(() => {
                    const div = document.createElement('div');
                    if (renderInfo) {
                        render(renderInfo(i), div);
                    }
                    return div.innerHTML;
                }, { minWidth: 230, closeButton: false });
            props.setMarkerRef && props.setMarkerRef(i, marker);
            return marker;
        }
    });

    const instance = L.Routing.control({
        waypoints: waypoints,
        show: false,
        autoRoute: false,
        addWaypoints: true,
        routeWhileDragging: false,
        fitSelectedRoutes: true,
        showAlternatives: false,
        plan,
        // lineOptions: { extendToWaypoints: false, missingRouteTolerance: 0, styles: [{ color: 'white', opacity: 0, weight: 9 }] }
    });
    return instance;
};

const RoutingMachine = createControlComponent(RoutingMachineLayer);

export default RoutingMachine;
