import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import React, { useEffect, useState } from 'react';
import Tabs, { TabItem } from 'common/components/tabs/Tabs';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import AlarmTab from './components/alarmTab/AlarmTab';
import VehiclesTab from './components/vehiclesTab/VehiclesTab';
import NotificationsTab from './components/notificationsTab/NotificationsTab';
import Button from 'common/components/button/Button';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import styles from './AlarmScreen.module.scss';
import { Row } from 'react-flexbox-grid';
import { AlarmSettingDaysOfWeekDto, AlarmSettingDto, AlarmSettingVehicleDto } from 'api/alarms/models/AlarmSettingDto';
import AlarmsService from 'api/alarms/AlarmsService';
import { useToasts } from 'react-toast-notifications';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';
import { useForm } from 'react-hook-form';

const AlarmScreen = () => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const history = useHistory();
    const { alarmId, type } = useParams<{ alarmId: string, type: string }>();

    const [isDetails, setIsDetails] = useState<boolean>(type === 'details');
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);
    const [alarm, setAlarm] = useState<AlarmSettingDto | null>(null);
    const [allVehiclesSelected, setAllVehiclesSelected] = useState<boolean>(false);

    const defaultTab = ('alarms');
    const [activeTabId, setActiveTabId] = useState(defaultTab);

    const form = useForm<AlarmSettingDto>({ shouldUnregister: false });
    const { handleSubmit } = useForm<AlarmSettingDto>({ shouldUnregister: false });

    const [selectedVehicles, setSelectedVehicles] = useState<AlarmSettingVehicleDto[]>([]);
    const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState<AlarmSettingDaysOfWeekDto[]>([]);
    const [formHasErrors, setFormHasErros] = useState<boolean>(false);

    const getData = async () => {
        try {
            Loading.show();

            const result = alarmId ? await AlarmsService.getById(alarmId) : null;
            if (result) {
                if (result.duration) {
                    const hours = Math.floor((result.duration as number) / 60);
                    const minutes = (result.duration as number) % 60;
                    result.duration = (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes;
                }

                setAlarm(result);
                setSelectedVehicles(result.vehicles ?? []);
                setSelectedDaysOfWeek(result.daysOfWeek ?? []);
            }

        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.ALARMS, `Couldn't get information to create alarm`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        } finally {
            Loading.hide();
        }
    }

    const onSelectVehicle = (vehicleId: string, isToAdd: boolean) => {
        setSelectedVehicles(prev => isToAdd ? [...prev, { vehicleId: vehicleId }] : prev?.filter(x => x.vehicleId !== vehicleId));
    }

    const onSelectDayOfWeek = (dayOfWeek: number, isToAdd: boolean) => {
        setSelectedDaysOfWeek(prev => isToAdd ? [...prev, { dayOfWeek: dayOfWeek }] : prev?.filter(x => x.dayOfWeek !== dayOfWeek));
    }

    const alarmName = form.watch('name');
    const tabs: TabItem[] = [
        {
            id: 'alarms',
            title: (!!alarmName && alarmName.trim() !== '') ? alarmName : t('alarms.alarm_tab'),
            content: <AlarmTab alarm={alarm} form={form} onSelectDayOfWeek={onSelectDayOfWeek} daysOfWeekSelected={selectedDaysOfWeek}/>
        },
        {
            id: 'vehicles',
            title: t('alarms.vehicles_tab.title'),
            content: <VehiclesTab
                vehiclesSelected={selectedVehicles}
                onSelectAllVehicles={value => setSelectedVehicles(value)}
                onSelectVehicle={onSelectVehicle}
                allVehiclesSelected={allVehiclesSelected}
                setAllVehiclesSelected={value => setAllVehiclesSelected(value)}
            />
        }
    ]

    if (type !== 'new') {
        tabs.push(
            {
                id: 'notifications',
                title: t('alarms.notifications_tab.title'),
                content: <NotificationsTab alarmId={alarmId} />
            })
    }

    const navigateTo = (typeUrl?: string, id?: string) => {
        if (typeUrl) {
            history.push(`/alarms/${typeUrl}/${id}`);
            setIsDetails(typeUrl === 'details');
        } else {
            history.push(`/alarms`);
        }
    }

    const removeItem = async (a?: AlarmSettingDto) => {
        setDialogDeleteItemIsOpen(false);
        if (!a) return;

        try {
            Loading.show();
            await AlarmsService.remove(a);
            addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
            navigateTo();
        } catch (error) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
        } finally {
            Loading.hide();
        }
    };

    const onError = async () => {
        addToast(t('common.messages.validate_form'), { appearance: 'warning' });
    }

    const handleChangeTab = async (tabId: string) => {
        // Dispara a validação do formulário atual
        if (type != 'details') {
            const isValid = await form.trigger();
            setFormHasErros(!isValid);
        }
        setActiveTabId(tabId);
    };

    const onSubmit = async () => {
        try {
            const isValid = await form.trigger();

            if (!!!isValid || (formHasErrors && activeTabId == 'vehicles')) {
                onError();
                setActiveTabId(defaultTab);
                return;
            }

            const formData = form.getValues();

            const model: AlarmSettingDto = {
                id: alarmId ?? null,
                name: formData.name,
                type: formData.type,
                startHour: formData.startHour ?? undefined,
                stopHour: formData.stopHour ?? undefined,
                limit: formData.limit ?? 0,
                latitude: formData.latitude ?? undefined,
                longitude: formData.longitude ?? undefined,
                radius: formData.radius ?? undefined,
                zoom: formData.zoom ?? undefined,
                duration: formData.duration ?? undefined,
                rowVersion: alarm?.rowVersion ?? undefined,
                enabled: formData.enabled,
                notificationsEnabled: formData.notificationsEnabled ?? false,
                vehicles: selectedVehicles,
                daysOfWeek: selectedDaysOfWeek
            };

            if (model.duration) {
                const splittedTime = (model.duration as string).split(':');
                const hours = Number(splittedTime[0]);
                model.duration = hours * 60 + Number(splittedTime[1]);
            }

            Loading.show();
            if (alarmId) {
                await AlarmsService.update(model);
                navigateTo('details', alarmId);
            } else {
                const id = await AlarmsService.create(model);
                navigateTo('details', id);
            }
            getData();
            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
            Loading.hide();
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.ALARMS, `Couldn't save alarm`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    };

    useEffect(() => {
        if (activeTabId == defaultTab) {
            getData();
        }
    }, [type]);


    useEffect(() => {
        // Verifica se a tab está aberta e se há erros no formulário
        if (activeTabId === 'alarms' && formHasErrors) {
            const timeoutId = setTimeout(() => {
                form.trigger(); // Dispara a validação do formulário para mostrar mensagens de erro
            }, 1000);
            return () => clearTimeout(timeoutId);
        }

    }, [activeTabId]);

    return (
        <ScreenTitle title={t('alarms.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('alarms.title')} />
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <Tabs
                        items={tabs}
                        activeTabId={activeTabId}
                        onChange={(tId: string) => { handleChangeTab(tId) }}
                    />
                    <Row>
                        <div className={styles.buttons}>
                            <Button
                                preset={'secondary'}
                                type="button"
                                onClick={() => {
                                    if (!!alarmId) {
                                        isDetails ? navigateTo() : navigateTo('details', alarmId);
                                    } else {
                                        history.goBack();
                                    }
                                }}
                                text={t('common.cancel')}
                            />
                            {!!alarmId && isDetails &&
                                <Button
                                    type="button"
                                    text={t('common.remove')}
                                    preset={'danger'}
                                    onClick={() => setDialogDeleteItemIsOpen(true)} />
                            }
                            {alarmId && isDetails &&
                                <Button
                                    type={'button'}
                                    text={t('common.edit')}
                                    onClick={() => {
                                        setActiveTabId(defaultTab);
                                        navigateTo('edit', alarmId);
                                    }}
                                />}

                            {!isDetails && <Button
                                text={t('common.save')}
                                onClick={handleSubmit(onSubmit)}
                            />}

                            {alarm && <QuestionYesNo message={t('common.messages.remove_record')}
                                isVisible={dialogDeleteItemIsOpen}
                                onYes={() => removeItem(alarm)}
                                onNo={() => setDialogDeleteItemIsOpen(false)} />}
                        </div>
                    </Row>
                </form>
            </ScreenContainer>
        </ScreenTitle>
    );
}

export default AlarmScreen;