import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT_DEFAUT, SEGMENT_FEATURES } from 'Config';
import Box from 'common/components/box/Box';
import AvatarWithText from 'common/components/avatar/AvatarWithText';
import IconWithText from 'common/components/iconWithText/IconWithText';
import MoneyFormat from 'common/components/moneyFormat/MoneyFormat';
import { VehicleViewModel } from 'api/vehicles/models/VehicleViewModel';
import GaugeIcon from 'assets/svg/gauge.svg';
import NoImage from 'assets/img/asset-default-image.png';
import { FaCalendarAlt } from 'react-icons/fa';
import Badge from 'common/components/badge/Badge';
import DateFormat from 'common/components/dateFormat/dateFormat';
import styles from './VehicleCard.module.scss';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import moment from 'moment';
import DeletedVehicleCircle from 'common/components/deletedVehicleCircle/DeletedVehicleCircle';
import AlertsCounter from 'common/components/alertsCounter/AlertsCounter';
import CurrentLocation from 'screens/vehicles-gps/components/currentLocation/CurrentLocation';
import LoadingSpinner from 'common/components/loading/LoadingSpinner';

type Props = {
    vehicle: VehicleViewModel,
    hasGpsPolicy?: boolean,
    hasCountersPolicy?: boolean,
    onCardPress: (event: any, id?: string, gps?: boolean) => void
    onHref: (row: any) => string;
};

const VehicleCard: React.FC<Props> = ({ vehicle, hasGpsPolicy, hasCountersPolicy, onCardPress, onHref }: Props) => {
    const { t } = useTranslation();
    const imageCacheKey = useRef(new Date().getTime());
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasAlertsReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_ALERTS_READ']);
    const vehicleImmobilizedTooltip = vehicle.predictedDateEndImmobilization
        ? `${t('vehicles.stopped_until')} ${moment(vehicle.predictedDateEndImmobilization).format(DATE_FORMAT_DEFAUT)}`
        : t('vehicles.no_date_end_immobilization');
    const vehicleLocals = vehicle.vehicleLocals?.map(x => x.localName);
    
    const showField = (feature: string) => {
        return (vehicle?.segmentFeatures?.findIndex(x => x === feature) ?? -1) >= 0;
    }

    const renderGpsInfo = () => {
        if (!hasGpsPolicy) {
            return null
        }

        if (vehicle.trackingDeviceId && !vehicle.gpsDataHydrated) {
            return <LoadingSpinner />
        }

        if (vehicle.trackingDeviceId && vehicle.gpsDataHydrated && !vehicle.latitude) {
            return <div className={styles.noGpsData}>
                {t('location.info_messages.no_data')}
            </div>
        }

        if (vehicle.trackingDeviceId && vehicle.latitude && vehicle.longitude) {
            return <div onClick={(e: any) => onCardPress(e, vehicle.id, true)} style={{ margin: '0.3rem 0' }}>
                <CurrentLocation position={{
                    ignition: vehicle.ignition,
                    latitude: vehicle.latitude,
                    longitude: vehicle.longitude,
                    date: vehicle.date!
                }} />
            </div>
        }

        return null
    }



    return <Box className={`${styles.cardContainer} ${vehicle.immobilized ? styles.opacityBackground : ''}`} onClick={(e: any) => onCardPress(e, vehicle.id)} bodyClassName={styles.cardContainerHeight}>
        <div className={styles.cardContainerHeight}>
            {
                hasAlertsReadPolicy && !!vehicle.totalAlerts && vehicle.totalAlerts > 0 &&
                <AlertsCounter totalAlerts={vehicle.totalAlerts} className={styles.iconNumber} />
            }
            {/* href={onHref(vehicle)} */}
            <div className={`${vehicle.immobilized || !vehicle.active ? styles.opacity : ''}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <div className={styles.cardContent} >
                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
                        <div className={styles.contentImage}>
                            <div className={styles.img} style={{ backgroundImage: vehicle?.photoUrl ? 'url(' + vehicle?.photoUrl + '?_=' + imageCacheKey.current + ')' : 'url(' + NoImage + ')' }} />
                            {
                                //ToDo - REMOVE FALSE TO SHOW DATES BADGE
                                false && vehicle.useContractStartDate
                                    ? <div className={styles.containerBadge}>
                                        <Badge className={styles.infoBadge} preset={'primaryDark10'}>
                                            <FaCalendarAlt className={styles.iconCalendar} /> <DateFormat value={vehicle.useContractStartDate ?? '-'} format={DATE_FORMAT_DEFAUT.toString()} /> - <DateFormat value={vehicle.useContractEndDate ?? '-'} format={DATE_FORMAT_DEFAUT.toString()} />
                                        </Badge>
                                    </div>
                                    : <div>&nbsp;</div>
                            }

                            <div className={styles.columnPlate}>{vehicle.registrationNumber} {!vehicle.active && <DeletedVehicleCircle id={vehicle.id ?? ''} />}</div>
                            <div className={styles.columnBrand}>{(vehicle.vehicleBrandName ?? '') + (' ') + (vehicle.vehicleModelName ?? '')}</div>
                            <div className={styles.columnSpec}>{vehicle.vehicleSegmentName + ( vehicle.vehicleFuels?.length || vehicle.colorName ? ' | ' : '')}
                                {(vehicle.vehicleFuels?.map((x, i) => (
                                    <span key={'fuel' + i}> {t(('vehicle.fuels.' + x.fuelName) as any).toString()} </span>
                                )))}
                                {vehicle.colorName ? ( t(('vehicle.colors.' + vehicle.colorName) as any)) : ''}
                            </div>

                            {renderGpsInfo()}

                            <div className={styles.columnLocals}>{vehicleLocals?.join(' | ')}</div>
                            {vehicle.driverName && <div className={styles.columnDriver}>
                                <AvatarWithText src={vehicle.driverPhotoUrl} size="x2">{vehicle.driverName}</AvatarWithText>
                            </div>}

                        </div>
                        {showField(SEGMENT_FEATURES.KM) && <div>
                            <div className={styles.bottomLabels}>
                                <IconWithText
                                    icon={GaugeIcon}
                                    value={vehicle.currentKilometers}
                                    suffix={t('vehicles.list.kilometers_minified')}
                                    isNumeric={true}
                                    tooltip={t('vehicles.list.kilometers')}
                                />
                                {
                                    hasCountersPolicy &&
                                    <MoneyFormat
                                        className={styles.fontBold}
                                        value={vehicle.monthlyCostAverage}
                                        suffix={t('vehicles.list.euro_months')}
                                        fixedDecimalScale={false}
                                        tooltip={t('vehicles.list.average_monthly_cost', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) })}
                                    />
                                }
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            {vehicle.immobilized && <div className={styles.overlay} data-tip={vehicleImmobilizedTooltip}>
                <div className={styles.overlayText} >{t('vehicle.immobilized')}</div>
            </div>}
        </div>
    </Box>
}

export default VehicleCard;
