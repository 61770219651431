import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './EcoDriving.module.scss';
import { ReactComponent as EcoIcon } from 'assets/svg/eco-conducao.svg';
import { Col, Row } from 'react-flexbox-grid';
import ReactTooltip from 'react-tooltip';
import NumberFormat from 'react-number-format';

type Props = {
    value: number;
    idx?: any;
};

const EcoDriving: React.FC<Props> = ({ value, idx }: Props) => {
    const { t } = useTranslation();

    const calcEcoColor = (val: number) => {
        if (val <= 5) {
            return '#73C25C';
        } else if (val > 5 && val < 15) {
            return '#F3A70D';
        } else {
            return '#D22222';
        }
    }

    const calcEcoValue = (val: number) => {
        if (val <= 5) {
            return styles.eco_success;
        } else if (val > 5 && val < 15) {
            return styles.eco_warning;
        } else {
            return styles.eco_danger;
        }
    }

    const calcEcoRowColor = (val: number) => {
        if (val <= 5) {
            return styles.row_eco_success;
        } else if (val > 5 && val < 15) {
            return styles.row_eco_warning;
        } else {
            return styles.row_eco_danger;
        }
    }

    const ecoValueTooltip = (ecoValue: number) => (
        <Row>
            <Col xs={12}>
                <div className={styles.p08}><strong>{t('location.eco')}</strong> : <NumberFormat value={ecoValue} displayType='text' thousandSeparator={' '} decimalSeparator={','} decimalScale={2} fixedDecimalScale={false} suffix={' '} /> {t('location.tooltip_eco_calc')}</div>
                <table className={styles.table}>
                    <tr>
                        <th>{t('location.tooltip_eco_penalty_header')}</th>
                        <th>{t('location.tooltip_eco_penalty_color')}</th>
                    </tr>
                    <tr className={ecoValue <= 5 ? calcEcoRowColor(ecoValue): undefined}>
                        <td>{t('location.tooltip_eco_success', { until: 5 })}</td>
                        <td><EcoIcon style={{ margin: '0 0.3rem' }} fill={calcEcoColor(0)} /></td>
                    </tr>
                    <tr className={ecoValue >= 5.01 && ecoValue <= 14.99 ? calcEcoRowColor(ecoValue) : undefined}>
                        <td>{t('location.tooltip_eco_warning', { from: 5.01, until: 14.99 })}</td>
                        <td><EcoIcon style={{ margin: '0 0.3rem' }} fill={calcEcoColor(5.01)} /></td>
                    </tr>
                    <tr className={ecoValue >= 15 ? calcEcoRowColor(ecoValue) : undefined}>
                        <td>{t('location.tooltip_eco_danger', { from: 15 })}</td>
                        <td><EcoIcon style={{ margin: '0 0.3rem' }} fill={calcEcoColor(15)} /></td>
                    </tr>
                </table>
            </Col>
        </Row>);


    return (
        <div className={`${styles.eco} ${calcEcoValue(value)}`} data-tip='' data-for={`tooltip_${idx}`}>
            <EcoIcon style={{ margin: '0 0.3rem' }} fill={calcEcoColor(value)} />
            {/* <div>{t('location.eco')}</div> */}
            <ReactTooltip class='shadow_tooltip' id={`tooltip_${idx}`} className={styles.tooltip} backgroundColor={'#fff'} textColor='#000' getContent={e => ecoValueTooltip(value)} />
        </div>
    );
}

export default EcoDriving;