export enum AlertSubjectEnum {
    INFORMATION = 'INFORMATION',
    INTERVENTION = 'INTERVENTION',
    INTERVENTION_URGENT = 'INTERVENTION_URGENT',
}

export enum AlertSubjectColorEnum {
    INTERVENTION = '#EAA32C',
    INFORMATION= '#56CF76',
    INTERVENTION_URGENT = '#DC4C4C'
}