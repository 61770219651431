import React, { useState } from 'react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import styles from './ContentManagement.module.scss';
import SelectController from '../select/SelectController';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import Button from '../button/Button';


type Props = {
    options: SelectValueLabel[];
    onCopy: (value: string) => void;
    isInnerContainer?: boolean;
};

const ContentManagement = ({ options, isInnerContainer, onCopy }: Props) => {

    const { t } = useTranslation();
    const form = useForm<any>();
    
    const [openContent, setOpenContent] = useState<boolean>(false);
    const [optionSelected, setOptionSelected] = useState<SelectValueLabel>();

    return (
        <div className={`${styles.container} ${openContent && styles.isOpen} ${isInnerContainer && styles.innerContainer}`}>
            <div onClick={() => setOpenContent(!openContent)}>            
                <span className={styles.description}>{t('content_management.description')}</span>
                { !openContent && <FaChevronRight size={14} /> }
                { openContent && <FaChevronDown size={14} /> }
            </div>

            {openContent && 
                <div className={styles.selectContainer}>
                    <SelectController
                        form={form}
                        name="contentManagementLanguageId"
                        placeholder={t('common.select_option')}
                        options={options}
                        isDisabled={false}
                        rules={{ required: false }}
                        isClearable={true}
                        filterOption={(candidate: any, input: any) => input ? candidate.label.toUpperCase().includes(input.toUpperCase()) : true}
                        onChangeSelect={opt => setOptionSelected(opt)}
                        minWidth="15rem"
                    />

                    <Button
                        type='button'
                        text={t('common.copy')}
                        size={'small'}
                        onClick={optionSelected ? () => onCopy(optionSelected.value) : undefined} />
                </div>    
            }
        </div>
    );
}

export default ContentManagement;