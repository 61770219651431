import React from 'react';
import InputGroupController from 'common/components/inputGroup/InputGroupController';
import DateTimePickerController from 'common/components/dateTimePicker/DateTimePickerController';
import styles from './InputGroupDate.module.scss';

type Props = {
    form: any;
    //isDetails: boolean;

    inputType?: string;
    inputText?: string;
    inputPlaceholder?: string;
    inputName: string;
    inputFormat?: 'number' | 'default' | 'money' | 'percentage' | 'integer' | undefined;
    inputRequired?: boolean;
    inputMaxLength?: number;
    inputDisabled: boolean;

    datePlaceholder?: string;
    dateSelected: any;
    dateFormat: string;
    dateCustomInput: JSX.Element;
    dateName: string;
    dateRequired?: boolean;
    dateDisabled: boolean;
    onChangeDate: (date: Date) => void;
    showMonthYearPicker?: boolean;
};

const InputGroupDate = ({ form, datePlaceholder, dateSelected, dateFormat, dateCustomInput, dateName, dateRequired, dateDisabled, onChangeDate,
    inputType, inputText, inputPlaceholder, inputName, inputFormat, inputRequired, inputMaxLength, inputDisabled, showMonthYearPicker, ...props }: Props) => {
    return (
        <div className={styles.container}>
            <div className={styles.leftChild}>
                <InputGroupController
                    type={inputType}
                    text={inputText}
                    placeholder={inputPlaceholder}
                    name={inputName}
                    form={form}
                    disabled={inputDisabled}
                    format={inputFormat ?? 'default'}
                    rules={{ required: inputRequired, maxLength: inputMaxLength }} 
                    removeIconOrTextBorderRadius={true} />
            </div>
            <div className={styles.rightChild}>
                <DateTimePickerController
                    form={form}
                    placeholderText={datePlaceholder}
                    onChange={onChangeDate}
                    selected={dateSelected}
                    dateFormat={dateFormat}
                    customInput={dateCustomInput}
                    name={dateName}
                    autoComplete='off'
                    rules={{ required: dateRequired }}
                    disabled={dateDisabled} 
                    showMonthYearPicker={showMonthYearPicker} />
            </div>
        </div>
    );
};

export default InputGroupDate;
