import React, { useState } from 'react';
import CarVerificationList from './components/carVerificationList/CarVerificationList';
import CarVerification from './components/carVerification/CarVerification';
import { useHistory, useParams } from 'react-router-dom';
import { CarVerificationSearchCriteria } from 'api/carVerification/models/CarVerificationSearchCriteria';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE } from 'Config';
import { VehicleDto } from 'api/vehicles/models/VehicleDto';

type RouteParams = {
    vehicleId: string;
    itemType: 'list' | 'details' | 'edit' | 'new';
    itemId: string;
    tabId: string;
    type: 'details' | 'edit' | 'new';
}

type Props = {
    vehicle?: VehicleDto;
    tourReRender: () => void;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const CarVerificationTab: React.FC<Props> = ({ vehicle, tourReRender }: Props) => {
    const { vehicleId, itemType, itemId, tabId } = useParams<RouteParams>();
    const history = useHistory();
    const [criteria, setCriteria] = useState<CarVerificationSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderColumn: 'v.created_date',
        orderBy: 'desc',
        vehicleId: vehicleId     
    });

    return (
        <div>
        {
        (itemType??'list') === 'list' 
            ? <CarVerificationList isVehicle editable={vehicle!= undefined && vehicle.active} criteria={criteria} updateCriteria={setCriteria} tourReRender={tourReRender} />
            : <CarVerification
                goToEdit={(id: string) => history.push(`/vehicles/details/${vehicleId}/${tabId}/edit/${id}`)}
                type={itemType}
                editable={vehicle!= undefined && vehicle.active}
                vehicleId={vehicleId}
                verificationId={itemId}
            />
        }
        </div>
    );
}

export default CarVerificationTab;
