import React from 'react';
import Checkbox, { Props as CheckboxProps } from './CheckBox';
import { Controller, RegisterOptions, UseFormMethods } from 'react-hook-form';

type Props = {
    form: UseFormMethods<any>;    
    name: string;
    rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
} & CheckboxProps;

const CheckBoxController = ({ form, name, label, rules, ...props }: Props) => {
    
    return (
        <Controller
            render={({ value, onChange, ref }) => {
                return (
                    <Checkbox
                        {...props}                        
                        label={label}
                        ref={ref}
                        form={undefined}
                        onChange={e => {
                            onChange(e.target.checked);                          
                        }}
                        checked={value ? true : false}
                    />
                );
            }}
            key={name}
            control={form.control}            
            name={name}
            defaultValue={form.getValues(name) || null}
            rules={rules}
        />
    );
};

export default CheckBoxController;
