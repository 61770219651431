import React, { memo, useState } from 'react';
import styles from './SummernoteController.module.scss';
import { Controller, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ImageDrop } from 'quill-image-drop-module';
import ImageResize from 'quill-image-resize-module-react';
import { Item, Menu, useContextMenu, theme } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';

const fontSizeArr = ['8px', '9px', '10px', '12px', '14px', '16px', '20px', '24px', '32px', '42px', '54px', '68px', '84px', '98px'];

const Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageDrop', ImageDrop);

const BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = [
    'alt',
    'height',
    'width',
    'style',
    'size'
];

class ImageFormat extends BaseImageFormat {
    static formats(domNode: any) {
        return ImageFormatAttributesList.reduce(function (formats, attribute) {
            if (domNode.hasAttribute(attribute)) {
                (formats as any)[attribute] = domNode.getAttribute(attribute);
            }
            return formats;
        }, {});
    }
    format(name: any, value: any) {
        if (ImageFormatAttributesList.indexOf(name) > -1) {
            if (value) {
                this.domNode.setAttribute(name, value);
            } else {
                this.domNode.removeAttribute(name);
            }
        } else {
            super.format(name, value);
        }
    }
}

Quill.register(ImageFormat, true);

const colors: Array<string> = [
    '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc',
    '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66',
    '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2',
    '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'
];

const formats = [
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'ordered', 'indent',
    'link', 'image',
    'align', 'color', 'background', 'size',
    'clean', 'style', 'height', 'width',
    'header',
];

export type Props = {
    form: UseFormMethods<any>;    
    id?: string;
    name: string;
    placeholder?: string;
    required?: boolean;
    isDisabled?: boolean;
    contextMenuOptions?: ContextMenuOption[];
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export type ContextMenuOption = {
    onClick: (element: HTMLInputElement | ReactQuill | undefined) => string;
    name: string;
}

const SummernoteController = ({ form, name, id, placeholder, contextMenuOptions, required, isDisabled, ...props }: Props, ref: any) => {
    const { t } = useTranslation();
    const [element, setElement] = useState<HTMLInputElement | ReactQuill>();    
    const { show } = useContextMenu({ id: id });

    const modules = {
        imageResize: !isDisabled ? {
            parchment: Quill.import('parchment'),
            displaySize: true,
            modules: ['Resize', 'DisplaySize', 'Toolbar'],
        } : undefined,
        imageDrop: !isDisabled,
        toolbar: !isDisabled ? [
             [{ header: [false, 1, 2, 3, 4, 5, 6] }],
            // [ { size: [ 'small', false, 'large', 'huge'] }],
             ['bold', 'italic', 'underline', 'strike', 'blockquote'],
             [{ list: 'ordered' }, { list: 'bullet' }, 'link'],
             [{ color: colors }, { background: [] }],
             [{ align: [] }, 'image'],
            ['clean']
        ] : false,
        clipboard: {
            matchVisual: false
        }
    };

    const onRegisterRef = (instance: HTMLInputElement | ReactQuill | null) => {
        if (instance) setElement(instance);
    }

    const renderContextMenu = () => {
        return <Menu id={id ?? 'context-menu'} theme={theme.light}>
            {contextMenuOptions?.map((option, index) => {
                return <Item key={`item-option-${index}`} onClick={() => option.onClick(element)}>
                    <div className={styles.menuItem}>
                        <span>{t(option.name as any)}</span>
                    </div>
                </Item>;
            })}
        </Menu>;
    }

    return (
        <div className={isDisabled ? styles.disabled : styles.summernote} {...props} ref={ref}>
            <Controller
                render={({ value, onChange }) => (
                    <>
                      { contextMenuOptions && !isDisabled && <div onContextMenu={show}>
                            <ReactQuill
                                ref={onRegisterRef}
                                theme='snow'
                                placeholder={placeholder ? placeholder : t('common.add_text_here')}
                                readOnly={isDisabled}
                                value={value}
                                onChange={onChange}
                                modules={modules}
                                formats={formats}
                            />
                            {renderContextMenu()}
                        </div>
                    }
                    { (!contextMenuOptions || isDisabled) &&
                            <ReactQuill
                                ref={onRegisterRef}
                                theme='snow'
                                placeholder={placeholder ? placeholder : t('common.add_text_here')}
                                readOnly={isDisabled}
                                value={value}
                                onChange={onChange}
                                modules={modules}
                                formats={formats}
                                style={{ backgroundColor: '#EEEEEE', minHeight: '5rem' }}
                            />
                    }
                    </>
                )}
                name={name}
                control={form.control}
                defaultValue={form.getValues(name) || ''}
                rules={{ required }}
            />
        </div>
    );
};

export default memo(React.forwardRef<HTMLTextAreaElement, Props>(SummernoteController));
