import Http from '../../common/services/Http';
import CustomFile from 'common/models/CustomFile';
import { ImportChargeProcessFileViewModel, ImportChargeViewModel } from './models/ImportChargeViewModel';
import { Paged } from 'api/common/types/Page';
import { ImportExpenseFileDto, ImportExpenseFileIdDto, ImportExpenseFileItemDto } from './models/ImportExpenseFileDto';
import { ImportExpenseFilesSearchCriteria } from './models/ImportExpenseFilesSearchCriteria';
import { ImportExpenseFileItemsSearchCriteria } from './models/ImportExpenseFileItemsSearchCriteria';

class ImportChargeService {
    
    public import(model: ImportChargeViewModel, attachment: CustomFile) {
        return Http.postWithFile<string>('importCharge', model, [attachment]);
    }

    public getList(criteria: ImportExpenseFilesSearchCriteria) {
        return Http.get<Paged<ImportExpenseFileDto>>('importCharge', criteria);
    }

     public getListItems(criteria: ImportExpenseFileItemsSearchCriteria) {
         return Http.get<Paged<ImportExpenseFileItemDto>>('importCharge/list-items', criteria);
     }

    public getById(id: string) {
        return Http.get<ImportExpenseFileDto>('importCharge/' + id);
    }

    public updateFileItem(model: ImportExpenseFileItemDto) {
        return Http.post('importCharge/change-file-item', model);
    }

    public process(model: ImportExpenseFileIdDto) {
        return Http.post<ImportChargeProcessFileViewModel>('importCharge/process-file', model);
    }

    public remove(model: ImportExpenseFileIdDto) {
        return Http.put('importCharge/deactivate', model);
    }  
}

export default new ImportChargeService();