import React, { useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import Colors from 'styles/export/colors.module.scss';
import { Rect, VictoryAxis, VictoryBar, VictoryChart, VictoryGroup, VictoryLabel, VictoryLegend, VictoryLine } from 'victory';
import styles from './MonthlyRouteChart.module.scss';
import Utils from 'common/services/Utils';
import { DASHBOARD_COLORS, DEFAULT_LANGUAGE, STORAGE } from 'Config';
import ChartEmptyLabel from 'common/components/charts/ChartEmptyLabel';
import useElementSize from 'common/hooks/useElementSize';
import dayjs from 'dayjs';
import Storage from 'common/services/Storage';
import { MonthlyRoutesDashboardDto } from 'api/dashboard/dtos/MonthlyRouteDashboardDto';
import DistanceFormat from 'common/components/distanceFormat/DistanceFormat';

const BarLabel = (props: any) => (
	<VictoryLabel
		{...props}
		y={props.y - 9}
		backgroundPadding={{ top: 4, left: 8, bottom: 6, right: 8 }}
		style={{ fill: Colors.white, userSelect: 'none', fontSize: 16 }}
		backgroundStyle={{ fill: props.fill, opacity: 1 }}
		backgroundComponent={<Rect rx={7} ry={10} />}
		verticalAnchor="middle"
		renderInPortal={false}
	/>
);

type Props = {
	data: MonthlyRoutesDashboardDto | null;
	selectedYear: number | null;
};

const MonthlyRouteChart = ({ data, selectedYear }: Props) => {
	const { t } = useTranslation();
	const [reRenderKey, setReRenderKey] = useState(new Date().getTime());
	const [containerRef, { width, height }] = useElementSize();
	const locale = Storage.get(STORAGE.CURRENT_LOCALE) || DEFAULT_LANGUAGE;

	useEffect(() => {
		setReRenderKey(new Date().getTime());
		// selectedYear && setCosts(selectedYear);
	}, [data, selectedYear]);

	if (data && !Boolean(data.hasTrackingDeviceId)) {
		return <div className={styles.noEnoughInfoText}>{t('vehicles_gps.without_tracking_device_information')}</div>;
	}

	if (!data?.items || data?.items.length === 0) {
		return <div className={styles.noEnoughInfoText}>{t('dashboard.no_enough_information')}</div>;
	}

	const viewWidth = 650;
	const viewHeight = 170;

	const chartWidth = viewWidth;
	const chartHeight = viewHeight - 30;
	const barWidth = 16;

	const legendData = [
		{
			name: t('dashboard.routes.AVERAGE'),
			symbol: { fill: Colors.black, type: 'minus' },
			labels: { fill: Colors.black },
			clickable: false,
		},
		{
			name: t(`dashboard.routes.KM_TIME` as any),
			symbol: {
				fill: DASHBOARD_COLORS.monthlyRoute.BAR,
			},
			labels: { fill: Colors.black },
			clickable: true,
		}
	];

	let itemsData = data.items;

	if (selectedYear) {
		itemsData = itemsData.filter(s => dayjs(s.date).year() === selectedYear);
	}

	const allItems = itemsData;
	const allDates = allItems
		.map(x => x.date)
		.filter((date, i, self) => self.findIndex(d => d.getTime() === date.getTime()) === i);
	const allValues = allItems.map(x => x.totalKms);
	const average = allValues.reduce((a, b) => a + b, 0) / allValues.length;
	const highestValue = Math.max(...allValues);

	const monthsDiff = allDates?.length ? Utils.getMonthsInRange(allDates[0], allDates[allDates.length-1]).length : 0;

	const chartPadding = { top: 40, left: 25, right: 25, bottom: 40 };

	const formatTime = (totalMinutes: number) => {
		const hours = Math.floor(totalMinutes / 60);
		const minutes = Math.round(totalMinutes - (hours * 60));
		return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} h`;
	}
	return (
		<div className={styles.container}>
			<div className={styles.countersContainer}>
				<div className={`${styles.counter} ${styles.counterRightBorder}`}>
					<p className={styles.counterLabel}>{t('dashboard.routes.KM')}</p>
					<div className={styles.counterValue}>
						<DistanceFormat
							value={data.totalKms}
							style={{ color: DASHBOARD_COLORS.monthlyRoute.KM }}
							fixedDecimalScale={false}
						/>
					</div>
				</div>
				<div className={`${styles.counter} ${styles.counterRightBorder}`}>
					<p className={styles.counterLabel}>{t('dashboard.routes.TIME')}</p>
					<div className={styles.counterValue}>

						<span style={{ color: DASHBOARD_COLORS.monthlyRoute.TIME }}>
							{formatTime(data.totalMinutes)}
						</span>

					</div>
				</div>
			</div>

			<div className={styles.chartContainer} ref={containerRef}>
				<svg width={width} height={height} viewBox={`0 0 ${viewWidth} ${viewHeight}`}>
					{/* Average Line */}
					{itemsData.length > 0 && (
						<VictoryChart
							standalone={false}
							width={chartWidth}
							height={chartHeight}
							padding={chartPadding}
							maxDomain={{ y: highestValue }}
							minDomain={{ y: 0 }}
						>
							<VictoryLine
								style={{
									data: {
										stroke: Colors.primaryDark,
										strokeWidth: 1,
										strokeDasharray: 4,
									},
								}}
								data={[
									{ x: 0, y: average },
									{ x: 1, y: average },
								]}
							/>
							<VictoryAxis
								dependentAxis
								standalone={false}
								tickValues={allValues}
								style={{
									axis: {
										stroke: 'transparent',
										fill: 'none',
									},
									ticks: {
										stroke: 'transparent',
										fill: 'none',
									},
									axisLabel: {
										stroke: 'transparent',
										fill: 'none',
									},
								}}
								tickLabelComponent={<ChartEmptyLabel />}
							/>
						</VictoryChart>
					)}

					{/* Main bars chart */}
					{itemsData.length > 0 && (
						<VictoryChart
							standalone={false}
							width={chartWidth}
							height={chartHeight}
							padding={chartPadding}
							scale={{ x: 'time' }}
							domainPadding={50}
							key={'chart-' + reRenderKey}
						>
							<VictoryGroup offset={22}>
									<VictoryBar
										key={'bar-route'}
										data={itemsData}
										x="date"
										y="totalKms"
										alignment="middle"
										style={{
											data: { fill: DASHBOARD_COLORS.monthlyRoute.BAR, cursor: 'pointer' },
										}}
										cornerRadius={{ top: barWidth / 2 }}
										barWidth={barWidth}
										labels={() => null}
										labelComponent={<BarLabel fill={DASHBOARD_COLORS.monthlyRoute.BAR} />}
										events={[
											{
												target: 'data',
												eventHandlers: {
													onMouseEnter: (props: any) => {
														return [
															{
																target: 'labels',
																mutation: propsData => {
																	const style = propsData.style;
																	const hours = Math.floor(propsData.datum.totalMinutes / 60);
																	const minutes = Math.round(propsData.datum.totalMinutes - (hours * 60));
																	const kms = propsData.datum.totalKms;
																	return propsData.datum ? {
																		...propsData, style,
																		text: t('dashboard.routes.KM_TIME_OVER',{
																			 km: renderToString(<DistanceFormat value={kms} fixedDecimalScale={false}/>).replace(/<\/?span[^>]*>/g,''),
																			 time: `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} h` }) } : null;},
																		},
														];
													},
													onMouseLeave: () => {
														return [
															{
																target: 'labels',
																mutation: () => {
																	return null;
																}
															}
														];
													}
												},
											},
										]}
									/>
							</VictoryGroup>
							<VictoryAxis
								orientation="bottom"
								width={chartWidth}
								height={chartHeight}
								tickValues={allDates}
								tickFormat={x => Utils.capitalize(monthsDiff > 5 ? dayjs(x).locale(locale).format('MMMM')[0] : dayjs(x).locale(locale).format('MMMM'))}
								style={{
									tickLabels: { fill: Colors.black, userSelect: 'none', fontSize: 15 },
									axis: { stroke: Colors.gray3 },
								}}
							/>
						</VictoryChart>
					)}

					{/* Legends */}
					<VictoryLegend
						standalone={false}
						x={0}
						y={chartHeight}
						gutter={40}
						data={legendData}
						orientation={'horizontal'}
						itemsPerRow={3}
						// labelComponent={<ChartLegendLabel onClick={onClickLegend} />}
						style={{ labels: { fontSize: 15, userSelect: 'none' } }}
					/>
				</svg>
			</div>

		</div>
	);
};

export default MonthlyRouteChart;
