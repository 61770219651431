import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ScreenTitle from '../../common/components/screenTitle/ScreenTitle';
import ScreenContainer from '../../common/components/screenContainer/ScreenContainer';
import Box from 'common/components/box/Box';
import styles from './VehiclesScreen.module.scss';
import MoneyFormat from 'common/components/moneyFormat/MoneyFormat';
import { DATE_FORMAT_MONTH_YEAR, DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE, POLICIES } from 'Config';
import FiltersIcon from 'assets/svg/desktop_filtres.svg';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import { useHistory } from 'react-router-dom';
import Logger from 'common/services/Logger';
import { useToasts } from 'react-toast-notifications';
import VehiclesService from 'api/vehicles/VehiclesService';
import { VehicleViewModel } from 'api/vehicles/models/VehicleViewModel';
import Popover from 'common/components/popover/Popover';
import VehicleFiltersScreen, { Filters } from './components/VehicleFiltersScreen';
import { VehiclesSearchCriteria } from 'api/vehicles/models/VehiclesSearchCriteria';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import DateFormat from 'common/components/dateFormat/dateFormat';
import FilterList from 'common/components/filterList/FilterList';
import VehicleCard from './components/vehicleCard/VehicleCard';
import { useDebouncedCallback } from 'use-debounce';
import InputSearch from 'common/components/inputSearch/InputSearch';
import { Row, Col } from 'react-flexbox-grid';
import Loading from 'common/services/Loading';
import HelperModal from 'common/components/helperModal/HelperModal';
import InfoIcon from 'assets/svg/i_info_primaryDark.svg';
import ImportIcon from 'assets/svg/file-arrow-up-solid.svg';
import InfoTour from 'common/components/info-tour/InfoTour';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import TrackingService from 'api/tracking/TrackingService';
import CompaniesService from 'api/companies/CompaniesService';
import { TrackingProvider } from 'api/tracking/enums/TrackingProvider';
import TrackingVehicle from 'api/tracking/models/TrackingVehicle';


type VehiclesListTourItem = {
    selector: string;
    title: string;
    text: string;
    position?: string;
    policies: (keyof typeof POLICIES)[];
    type?: 'OR' | 'AND';
};


const VehiclesScreen = () => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const history = useHistory();
    const [trackingVehicles, setTrackingVehicles] = useState<TrackingVehicle[] | null>();
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasCountersPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['CHARGES_TOTAL_READ']);
    const hasVehiclesWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_VEHICLES_WRITE']);
    const hasImportsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_IMPORT_DATA_WRITE']);
    const hasGpsPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_GPS_READ']);


    const [criteria, setCriteria] = useState<VehiclesSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderColumn: 'v.registration_number',
        orderBy: 'asc'
    });
    const [vehicles, setVehicles] = useState<VehicleViewModel[]>([]);

    const [pageLoaded, setPageLoaded] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const [filters, setFilters] = useState<Filters>({});
    const [filtersTotal, setFiltersTotal] = useState(0);
    const [tourOpen, setTourOpen] = useState<boolean>(false);
    const [companyHaveVehicles, setCompanyHaveVehicles] = useState<boolean | null>(null);
    const validateItem = useCallback((item: VehiclesListTourItem) => {
        const type = item.type || 'AND';
        const policies = item.policies || [];
        const userPolicies = userProfile?.policies || [];
        return !policies.length || UsersService.hasPolicies(userPolicies, policies, type);
    }, [userProfile]);

    const steps: VehiclesListTourItem[] = useMemo(() => {
        const items: VehiclesListTourItem[] = [
            {
                selector: '#first-step',
                title: t('vehicles.tour.car_list'),
                text: t('vehicles.tour.car_list_message'),
                policies: [],
            },
            {
                selector: '#second-step',
                title: t('vehicles.tour.search_by_text'),
                text: t('vehicles.tour.search_by_text_message'),
                policies: [],
            },
            {
                selector: '#third-step',
                title: t('vehicles.tour.filters'),
                text: t('vehicles.tour.filters_message'),
                policies: [],
                position: 'top'
            },
            {
                selector: '#four-step',
                title: t('vehicles.tour.import'),
                text: t('vehicles.tour.import_message'),
                policies: ['SETTINGUP_IMPORT_DATA_WRITE'],
                type: 'OR',
            },
            {
                selector: '#five-step',
                title: t('vehicles.tour.new_car'),
                text: t('vehicles.tour.new_car_message'),
                policies: ['SETTINGUP_VEHICLES_WRITE'],
                type: 'OR',
            },
        ];

        return items.filter(item => validateItem(item));
    }, [userProfile, validateItem]);

    const getData = async () => {
        try {
            Loading.show();

            const result = await VehiclesService.companyHaveVehicles();
            setCompanyHaveVehicles(result);

            const vehiclesDB = await VehiclesService.getList(criteria);
            setTotalItems(vehiclesDB.totalItems);

            setVehicles(vehiclesDB.items);

            Loading.hide();
            if (hasGpsPolicy && vehiclesDB.items?.length) {
                const company = await CompaniesService.getCurrent();
                if (company && company.trackingProvider !== TrackingProvider.NONE) {
                    try {
                        const ids = vehiclesDB.items.filter(x => x.trackingDeviceId != null && x.id!= undefined).map(x => x.id as string) ?? [];
                        if (ids.length) {
                            const res = await TrackingService.getVehiclesList(ids);
                            setTrackingVehicles(res);
                        }
                    } catch (err) {
                        Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get vehicles tracking list`, err);
                        addToast(t('common.messages.error_load_info'), { appearance: 'error' });
                    }
                }
            }

        } catch (error) {
            Loading.hide();
            Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get vehicles list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const navigateTo = (type?: string, id?: string, gps?: boolean) => {
        if (id) {
            history.push(`/vehicles/${type}/${id}` + (gps ? '/gps' : ''));

        } else {
            history.push(`/vehicles/${type}`);
        }
    }

    const onCardClick = (event: any, id?: string, gps?: boolean, locationUrl?: string) => {
        if (id) {
            event.preventDefault();
            event.stopPropagation();
            filters.page = criteria.page;
            saveFiltersOnCache(filters);            
            if (event.ctrlKey) {
                window.open(history.location.pathname + '/details/' + id + (gps ? '/gps' : ''), '_blank');
                return;
            }
            navigateTo('details', id, gps);            
        }
    }

    const saveFiltersOnCache = (f: Filters) => {
        setFilters(f);
        localStorage.setItem('VEHICLESFILTER', JSON.stringify(f));
    }

    const onPageChange = (page: number) => {
        setCriteria({ ...criteria, page });
    }

    useEffect(() => {
        setTrackingVehicles(null);
        if (pageLoaded) {
            getData();
        }
        else {
            setPageLoaded(true);
            loadFilters();
        }
    }, [criteria]);

    const loadFilters = () => {
        const cachedFilters = localStorage.getItem('VEHICLESFILTER');
        if (cachedFilters != null && !pageLoaded) {
            onChangeFilters(JSON.parse(cachedFilters), true)
        } else {
            getData()
        }
    }

    const onChangeFilters = (f: Filters, isOnLoad?: boolean) => {
        updateTotalFilters(f);
        criteria.registrationNumber = f.registrationNumber;
        criteria.registrationDate = f.registrationDate;
        criteria.segmentId = f.segmentId;
        criteria.brandId = f.brandId;
        criteria.modelId = f.modelId;
        criteria.defaultDriverId = f.defaultDriverId;
        criteria.reservationAvailable = f.reservationAvailable;
        criteria.hasGps = f.hasGps;
        criteria.minCost = f.costAverage && f.costAverage[0];
        criteria.maxCost = f.costAverage && f.costAverage[1];
        criteria.minCostTotal = f.costAccumulated && f.costAccumulated[0];
        criteria.maxCostTotal = f.costAccumulated && f.costAccumulated[1];
        criteria.driverId = f.driverId;
        criteria.costsStartDate = f.costsStartDate;
        criteria.costsEndDate = f.costsEndDate;
        criteria.immobilized = f.immobilized;
        criteria.localId = f.localId;
        criteria.inactives = f.inactives;
        criteria.allIn = f.allIn;
        criteria.tags = f.tags;
        criteria.page = !isOnLoad ? 1 : (f.page ?? 1);

        saveFiltersOnCache(f);
        setCriteria({ ...criteria });
    }

    const updateTotalFilters = (f: Filters) => {
        let total = 0;

        if (f.registrationNumber) {
            total++;
        }
        if (f.registrationDate) {
            total++;
        }
        if (f.segmentId && f.segmentId.length > 0) {
            total++;
        }
        if (f.brandId && f.brandId.length > 0) {
            total++;
        }
        if (f.modelId && f.modelId.length > 0) {
            total++;
        }
        if (f.defaultDriverId && f.defaultDriverId.length > 0) {
            total++;
        }
        if (f.reservationAvailable || f.hasGps) {
            total++;
        }
        if (f.costAverage) {
            total++;
        }
        if (f.costAccumulated) {
            total++;
        }
        if (f.costsStartDate || f.costsEndDate) {
            total++;
        }
        if (f.immobilized || f.inactives) {
            total++;
        }
        if (f.localId && f.localId.length > 0) {
            total++;
        }

        setFiltersTotal(total);
    }

    const hydrateTracking = (v: VehicleViewModel) => {
        const trackingVehicle = trackingVehicles?.find(tv => tv.name === v.registrationNumber || tv.id === v.trackingDeviceId);
        if (trackingVehicle) {
            v.ignition = trackingVehicle.ignition;
            v.latitude = trackingVehicle.latitude;
            v.longitude = trackingVehicle.longitude;
            v.movement = trackingVehicle.movement;
            v.date = trackingVehicle.date;
            v.gpsDataHydrated = true;
        }
        else if (trackingVehicles || !hasGpsPolicy) {
            v.gpsDataHydrated = true;
        }
        return v;
    }

    const renderVehiclesCard = () => {
        return <div className={styles.containerCards}>
            {vehicles.map((v, i) => {
                const vehicle = hydrateTracking(v);
                return <VehicleCard
                    vehicle={vehicle}
                    hasGpsPolicy={hasGpsPolicy}
                    hasCountersPolicy={hasCountersPolicy}
                    key={`vehicle_${v.id}_${i}`}
                    onCardPress={onCardClick}
                    onHref={row => `/vehicles/details/${row.id}`}
                />
            })}
        </div>
    }

    const renderWithoutVehicles = () => {
        return companyHaveVehicles != false ? <div className={styles.containerCards} style={{ justifyContent: 'center' }}>
            {t('common.no_results')}
        </div> : renderStartUsingFleetMax();
    }

    const renderStartUsingFleetMax = () => {
        return <div className={styles.containerCards} style={{ justifyContent: 'center' }}>
            {t('common.start_using_fleetmax')} <ScreenHeaderButton id="five-step" icon={AddIcon} onClick={() => navigateTo('new')} className={styles.buttonAdd} />
        </div>
    }

    const debounced = useDebouncedCallback((value: string) => {        
        filters.allIn = value;
        saveFiltersOnCache(filters)
        setCriteria({ ...criteria, page: 1, allIn: value })
    }, 500);

    const debounced2 = useDebouncedCallback((value: string) => {
        filters.tags = value;
        saveFiltersOnCache(filters)
        setCriteria({ ...criteria, page: 1, tags: value })
    }, 500);

    const renderSearchInput = () => {
        return <InputSearch
            inputContainerClassName={styles.inputSearchContainerCustomization}
            onChangeValue={debounced}
            placeholder={t('vehicles.list.search_by_registration')}
            defaultValue={criteria.allIn}
        ></InputSearch>
    }

    const renderSearchTechnicalInfoInput = () => {
        return <InputSearch
            inputContainerClassName={styles.inputSearchContainerCustomization}
            onChangeValue={debounced2}
            placeholder={t('vehicles.list.search_by_technical_info')}
            defaultValue={criteria.tags}
        ></InputSearch>
    }

    const JumpToImport = () => {
        history.push(`/import-data`);
    }

    return (
        <ScreenTitle title={t('vehicles.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('vehicles.title')}>
                    <Row id="second-step">
                        <Col xs={6}>{renderSearchInput()}</Col>
                        <Col xs={6}>{renderSearchTechnicalInfoInput()}</Col>
                    </Row>

                    <Popover
                        contentContainerClassName={styles.filtersPopoverContainer}
                        containerStyle={{ boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}
                        // positions={['bottom', 'left']}
                        // align={'end'}
                        onClickOutside={() => updateTotalFilters(filters)}
                        content={setIsPopoverOpen => <VehicleFiltersScreen
                            filters={filters}
                            hasCountersPolicy={hasCountersPolicy}
                            onFilter={f => { setIsPopoverOpen(false); onChangeFilters(f) }}
                            onChange={f => updateTotalFilters(f)}
                        />}>
                        {(isPopoverOpen, setIsPopoverOpen) => (
                            <ScreenHeaderButton icon={FiltersIcon} onClick={() => setIsPopoverOpen(!isPopoverOpen)} id="third-step">
                                {filtersTotal > 0 &&
                                    <div className={styles.counterList}> <div className={styles.counterNumber}>{filtersTotal}</div> </div>
                                }
                            </ScreenHeaderButton>
                        )}
                    </Popover>

                    {hasImportsWritePolicy && <div data-tip={t('import_data.title')}><ScreenHeaderButton id="four-step" className={styles.emptyBorder} classNameIcon={styles.importIcon} icon={ImportIcon} onClick={() => JumpToImport()} /></div>}
                    {hasVehiclesWritePolicy && <ScreenHeaderButton id="five-step" icon={AddIcon} onClick={() => navigateTo('new')} />}
                    <ScreenHeaderButton icon={InfoIcon} onClick={() => setTourOpen(true)} />
                </ScreenHeader>

                <FilterList filters={[
                    {
                        value: filters.costsStartDate && filters.costsEndDate ? <>{t('common.range.from')} <DateFormat format={DATE_FORMAT_MONTH_YEAR} value={filters.costsStartDate} /> {t('common.range.to').toLowerCase()} <DateFormat format={DATE_FORMAT_MONTH_YEAR} value={filters.costsEndDate} /></>
                            : filters.costsStartDate ? <>{t('common.range.from')} <DateFormat format={DATE_FORMAT_MONTH_YEAR} value={filters.costsStartDate} /></>
                                : filters.costsEndDate ? <>{t('common.range.to')} <DateFormat format={DATE_FORMAT_MONTH_YEAR} value={filters.costsEndDate} /></> : undefined,
                        label: t('vehicles.list.total_monthly_cost_date', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) }),
                        onRemove: () => { filters.costsStartDate = filters.costsEndDate = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.registrationNumber,
                        label: t('vehicle.registration_identification'),
                        onRemove: () => { filters.registrationNumber = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.segmentName,
                        label: t('vehicle.type'),
                        onRemove: () => { filters.segmentId = filters.segmentName = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.brandName,
                        label: t('vehicle.brand'),
                        onRemove: () => { filters.brandId = filters.brandName = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.modelName,
                        label: t('vehicle.model'),
                        onRemove: () => { filters.modelId = filters.modelName = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.reservationAvailable ? t('common.yes') : '',
                        label: t('vehicle.available_for_reservation'),
                        onRemove: () => { filters.reservationAvailable = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.hasGps ? t('common.yes') : '',
                        label: t('vehicle.has_gps'),
                        onRemove: () => { filters.hasGps = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.defaultDriverName,
                        label: t('vehicle.default_driver'),
                        onRemove: () => { filters.defaultDriverId = filters.defaultDriverName = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.driverName,
                        label: t('common.state'),
                        onRemove: () => { filters.driverId = filters.driverName = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: !filters.costAverage || !filters.costAverage.length || !filters.costAverage.find(x => x !== undefined) ? undefined :
                            filters.costAverage.filter(i => i !== undefined).length === 2 ? <>{t('common.between')} <MoneyFormat value={filters.costAverage[0]} /> {t('common.and')} <MoneyFormat value={filters.costAverage[1]} /></>
                                : filters.costAverage[0] !== undefined ? <>{t('common.above')} <MoneyFormat value={filters.costAverage[0]} /></>
                                    : <>{t('common.below')} <MoneyFormat value={filters.costAverage[1]} /></>,
                        label: t('vehicles.list.average_monthly_cost', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) }),
                        onRemove: () => { filters.costAverage = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: !filters.costAccumulated || !filters.costAccumulated.length || !filters.costAccumulated.find(x => x !== undefined) ? undefined :
                            filters.costAccumulated.filter(i => i !== undefined).length === 2 ? <>{t('common.between')} <MoneyFormat value={filters.costAccumulated[0]} /> {t('common.and')} <MoneyFormat value={filters.costAccumulated[1]} /></>
                                : filters.costAccumulated[0] !== undefined ? <>{t('common.above')} <MoneyFormat value={filters.costAccumulated[0]} /></>
                                    : <>{t('common.below')} <MoneyFormat value={filters.costAccumulated[1]} /></>,
                        label: t('vehicles.list.total_monthly_cost', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) }),
                        onRemove: () => { filters.costAccumulated = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.immobilized ? t('common.yes') : '',
                        label: t('vehicle.immobilized'),
                        onRemove: () => { filters.immobilized = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.localName,
                        label: t('vehicle.local'),
                        onRemove: () => { filters.localId = filters.localName = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.inactives ? t('common.yes') : '',
                        label: t('vehicle.inactives'),
                        onRemove: () => { filters.inactives = false; onChangeFilters(filters) }
                    },
                ]} />

                <div id="first-step">
                    <Box>
                        {!!vehicles.length ? renderVehiclesCard() : renderWithoutVehicles()}
                        <PaginationWithInfo
                            itemName={t('vehicles.list.vehicles')}
                            currentPage={criteria.page}
                            pageItems={vehicles.length}
                            totalItems={totalItems}
                            onChange={onPageChange}
                        />
                    </Box>
                </div>

            </ScreenContainer>
            <InfoTour
                steps={steps.map((x, i) => {
                    return {
                        selector: x.selector,
                        content: <HelperModal title={x.title} text={x.text} />,
                        position: x.position
                    }
                })}
                isOpen={tourOpen}
                onRequestClose={() => setTourOpen(false)}
            />
        </ScreenTitle>
    );
}

export default VehiclesScreen;
