import AlertsService from 'api/alerts/AlertsService';
import { AlertsListItemDto } from 'api/alerts/models/AlertsListItemDto';
import { AlertsSearchCriteria } from 'api/alerts/models/AlertsSearchCriteria';
import Popover from 'common/components/popover/Popover';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { getAlertTypeColor, getSubjectTypeColor } from 'common/utils/AlertsUtils';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import AlertsFilters, { Filters } from 'screens/alerts/components/alertsFilters/AlertsFilters';
import AlertsFiltersHeader from 'screens/alerts/components/alertsFiltersHeader/AlertsFiltersHeader';
import AlertsList from 'screens/alerts/components/alertsList/AlertsList';
import FiltersIcon from 'assets/svg/desktop_filtres.svg';
import styles from './CarAlertsTab.module.scss';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import { VehicleDto } from 'api/vehicles/models/VehicleDto';

type Props = {
    vehicle?: VehicleDto;
    tourReRender: () => void;
}

const CarAlertsTab =  ({ vehicle, tourReRender }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { vehicleId } = useParams<{ vehicleId: string, itemType: string, itemId: string, tabId: string, type: string }>();
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);

    const [criteria, setCriteria] = useState<AlertsSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderColumn: 'a.created_date',
        orderBy: 'desc',
        vehicleId,        
        responsibleId: loggedUser?.id
    });
    const [alerts, setAlerts] = useState<AlertsListItemDto[]>([]);
    const [totalItems, setTotalItems] = useState(0);
    const [filters, setFilters] = useState<Filters>({ responsibleId: criteria.responsibleId, responsibleName: loggedUser?.realName });
    const [filtersTotal, setFiltersTotal] = useState(!!loggedUser ? 1 : 0);
    //const [totals, setTotals] = useState<AlertsTotalsDto>({ totalNotRead: 0, totalNotReadOwn: 0, totalRead: 0, totalTreated: 0, totalNonTreated: 0, totalInterventionUrgent: 0, totalInformation: 0, totalIntervention: 0 });
    //const [showReadOnly, setShowReadOnly] = useState(false);

    const getData = async () => {
        try {
            Loading.show();

            const [alertsItems] = await Promise.all([
                AlertsService.getList({ ...criteria }), //AlertsService.getList({ ...criteria, readOnly: showReadOnly, notReadOnly: !showReadOnly }),
                //AlertsService.getTotals(criteria)
            ]);

            alertsItems.items.forEach(a => {
                a.colorType = getAlertTypeColor(a.type);
                a.color = getSubjectTypeColor(a.subject);
            });

            setTotalItems(alertsItems.totalItems);
            setAlerts(alertsItems.items);
            //setTotals(totalsResult);

            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.ALERTS, `Couldn't get alerts list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
        finally{
            tourReRender();
        }
    }

    useEffect(() => {
        getData();
    }, [criteria]); //}, [criteria, showReadOnly]);

    const onChangeFilters = (_filters: Filters) => {
        updateTotalFilters(_filters);
        setFilters(_filters);

        setCriteria({
            ...criteria,
            vehicleId: vehicleId,
            type: _filters.type,
            responsibleId: _filters.responsibleId,
            reading: _filters.reading,
            startDate: _filters.startDate,
            endDate: _filters.endDate,
            page: 1,
        });
    }

    const updateTotalFilters = (_filters: Filters) => {
        let total = 0;

        if (_filters.vehicleId) {
            total++;
        }
        if (_filters.type) {
            total++;
        }
        if (_filters.responsibleId) {
            total++;
        }
        if (_filters.startDate) {
            total++;
        }
        if (_filters.endDate) {
            total++;
        }      
        if (_filters.reading !== undefined) {
            total++;
        }

        setFiltersTotal(total);
    }

    return (
        <div className={styles.container}>
            <div className={styles.filtersHeaderContainer}>
                <AlertsFiltersHeader
                    //totals={totals}
                    //showReadOnly={showReadOnly}
                    //onShowReadOnlyChange={setShowReadOnly}
                    className={styles.filtersHeader}
                    criteria={criteria}
                    onChangeCriteria={setCriteria}
                />
                <Popover
                    contentContainerClassName={styles.filtersPopoverContainer}
                    containerStyle={{ boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}
                    positions={['bottom', 'left']}
                    align={'end'}
                    onClickOutside={() => updateTotalFilters(filters)}
                    content={setIsPopoverOpen => <AlertsFilters
                        filters={filters}
                        onFilter={_filters => { setIsPopoverOpen(false); onChangeFilters(_filters) }}
                        onChange={_filters => updateTotalFilters(_filters)}
                        hideVehicleFilter={true}
                    />}>
                    {(isPopoverOpen, setIsPopoverOpen) => (
                        <ScreenHeaderButton icon={FiltersIcon} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                        {filtersTotal > 0 &&
                            <div className={styles.counterList}> <div className={styles.counterNumber}>{filtersTotal}</div> </div>
                        }
                        </ScreenHeaderButton>
                    )}
                </Popover>
            </div>
            <AlertsList
                editable={vehicle!= undefined && vehicle.active}
                alerts={alerts}
                criteria={criteria}
                totalItems={totalItems}
                onReadAlert={getData}
                onChangeCriteria={setCriteria}
                vehicleId={vehicleId}
            />
        </div>
    );
}

export default CarAlertsTab;