import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { NotificationSearchCriteria } from './models/NotificationSearchCriteria';
import { NotificationDto, NotificationListDto } from './models/NotificationDto';
import { SelectValueLabel } from 'common/types/SelectValueLabel';

class NotificationsService {

    public getList(criteria: NotificationSearchCriteria) {
        return Http.get<Paged<NotificationDto>>('notifications', criteria);
    }
    
    public catalog() {
        return Http.get<SelectValueLabel[]>('notifications/catalog');
    }

    public getListByType(type : string) {
        return Http.get<NotificationDto[]>('notifications/' + type);
    }

    public create(model: NotificationDto) {
        return Http.post<string>('notifications', model);
    }

    public update(list: NotificationListDto) {
        return Http.put('notifications', list);
    }

    public remove(model: NotificationDto) {
        return Http.put('notifications/deactivate', model);
    }

}

export default new NotificationsService();