import styles from './HelperModal.module.scss'

type Props = {
	title?: string;
	text?: string;
	children?: React.ReactElement;
}

const HelperModal: React.FC<Props> = ({ title, text, children }: Props) => {
	return (
		<div className={styles.div}>
			{/* <img src={MainLogo} /> */}
			<div className={styles.title}>{title}</div>
			<div className={styles.text}>{text}</div>
			{children}
			<br />
		</div>
	)
}

export default HelperModal
