import React from 'react';
import ColorPicker from './ColorPicker';
import { Controller, UseFormMethods } from 'react-hook-form';

type Props = {
    form: UseFormMethods<any>;
    name: string;
    disabled?: boolean;
};

const ColorPickerController = ({ form, name, disabled, ...props }: Props) => {
    return (
        <Controller
            render={({ onChange, value }) => {
                return (
                    <ColorPicker
                        {...props}
                        value={value}
                        disabled={disabled}
                        onChangeColor={onChange}
                    />
                );
            }}
            control={form.control}
            name={name}
            defaultValue={form.getValues(name) || ''}
        />
    );
};

export default ColorPickerController;
