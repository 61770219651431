import Http from '../../common/services/Http';
import { VehicleMonthlyCostsDto } from './models/VehicleMonthlyCostsDto';
import { VehicleMonthlyCostsSearchCriteria } from './models/VehicleMonthlyCostsSearchCriteria';

class VehicleMonthlyCosts {

    public getMonthlyCostCounters(criteria: VehicleMonthlyCostsSearchCriteria) {
        return Http.get<VehicleMonthlyCostsDto[]>('vehicles/monthly-cost-counters', criteria);
    }
    
    public calculateMonthlyCosts(companyId?: string) {
        return Http.put('vehicles/calculate-monthly-costs', { companyId: companyId });
    }
}

export default new VehicleMonthlyCosts();