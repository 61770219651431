import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ImportChargeFilters.module.scss';
import { useToasts } from 'react-toast-notifications';
import { DATE_FORMAT_DEFAUT, LOGGER_LOG_TYPE } from 'Config';
import Logger from 'common/services/Logger';
import { FaCalendarAlt } from 'react-icons/fa';
import Button from 'common/components/button/Button';
import { Col, Row } from 'react-flexbox-grid';
import FormItem from 'common/components/formItem/FormItem';
import Label from 'common/components/label/Label';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { Controller, useForm } from 'react-hook-form';
import InputGroup from 'common/components/inputGroup/InputGroup';
import DateWithHourController from 'common/components/dateWithHour/DateWithHourController';
import SuppliersService from 'api/suppliers/SuppliersService';
import Select from 'common/components/select/Select';



export interface Filters {
    startDate?: Date | null,
    endDate?: Date | null,
    supplierId?: string;
    supplierName?: string;
}

type Props = {
    filters: Filters;
    onFilter: (filters: Filters) => void;
    load?: boolean;
}


const ImportChargeFilters: React.FC<Props> = ({ filters,  onFilter, load }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const form = useForm<Filters>({ shouldUnregister: false, shouldFocusError: true, defaultValues: filters });
    const { getValues, setValue, reset, watch, control } = form;
    const [suppliersOptions, setSuppliers] = useState<SelectValueLabel[]>([]);


    useEffect(() => {
        if (load) {
            form.reset(filters);
            onSubmit(filters);
        } else {
            form.reset(filters);
        }
    }, [filters]);

    const getData = async () => {
        try {
            const [suppliersOptions] = await Promise.all([
                SuppliersService.catalog({ isCharges: true })
            ]);

            setSuppliers(suppliersOptions);

        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get data`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const clearFilters = () => {
        const model = {
            startDate: null,
            endDate: null,
            supplierId: undefined,
            supplierName: undefined
        };
        reset(model);
        onSubmit(model);
        getData();
    }

    const onSubmit = (f: Filters) => {
        onFilter(f);
    }

    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <Row >


                <Col sm={12} md={6} xl={4}>
                    <FormItem>
                        <Label className={styles.filtersLabel}>{t('reservations.list.date_start')}</Label>

                        <DateWithHourController
                            form={form}
                            datePlaceholder={t('common.date')}
                            dateFormat={DATE_FORMAT_DEFAUT}
                            dateCustomInput={<InputGroup icon={<FaCalendarAlt />} removeIconOrTextBorderRadius={true} />}
                            name="startDate"
                            isDisabled={false}
                            timePlaceholder={t('common.hour')}
                        />
                    </FormItem>
                </Col>

                <Col sm={12} md={6} xl={4}>
                    <FormItem>
                        <Label className={styles.filtersLabel}>{t('reservations.list.date_end')}</Label>
                        <DateWithHourController
                            form={form}
                            datePlaceholder={t('common.date')}
                            dateFormat={DATE_FORMAT_DEFAUT}
                            dateCustomInput={<InputGroup icon={<FaCalendarAlt />} removeIconOrTextBorderRadius={true} />}
                            name="endDate"
                            isDisabled={false}
                            timePlaceholder={t('common.hour')}
                            minDate={form.watch('startDate') || undefined}
                        />
                    </FormItem>
                </Col>
                <Col sm={12} md={6} xl={4}>
                    <FormItem>
                        <Label className={styles.filtersLabel}>{t('common.supplier')}</Label>
                        <Controller
                            render={({ onChange, value }) => {
                                return (
                                    <Select
                                        options={suppliersOptions}
                                        isClearable
                                        placeholder={t('common.supplier')}
                                        onChange={(data: SelectValueLabel) => {
                                            setValue('supplierName', suppliersOptions.find(x => x.value == data?.value)?.label ?? null);
                                            onChange(data?.value ?? null);
                                        }}
                                        value={suppliersOptions.find(x => x.value === value) ? { label: suppliersOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                    />
                                );
                            }}
                            control={control}
                            name="supplierId"
                            defaultValue={getValues('supplierId')} />
                    </FormItem>
                </Col>

                <Col xl={12} className={styles.searchButton}>
                    <Button
                        text={t('common.remove')}
                        size={'small'}
                        preset={'secondary'}
                        onClick={clearFilters}
                        type='reset'
                    />
                    <Button
                        type='submit'
                        text={t('common.apply')}
                        size={'small'}
                    />
                </Col>

            </Row>
        </form>
    );
}

export default memo(ImportChargeFilters);