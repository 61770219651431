import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import styles from './HotSpotModal.module.scss';
import Label from 'common/components/label/Label';
import Button from 'common/components/button/Button';
import FormItem from 'common/components/formItem/FormItem';
import { Col, Row } from 'react-flexbox-grid';
import { HotSpotDto } from 'api/hotSpots/models/HotSpotDto';
import { useForm } from 'react-hook-form';
import Loading from 'common/services/Loading';
import HotSpotsService from 'api/hotSpots/HotSpotsService';
import { useToasts } from 'react-toast-notifications';
import Logger from 'common/services/Logger';
import { DEFAULT_INPUT_RULES_WITH_REQUIRED, LOGGER_LOG_TYPE } from 'Config';
import Input from 'common/components/input/Input';
import InputError from 'common/components/inputError/InputError';

type Props = {
    show: boolean;
    onYes: (hotSpot: HotSpotDto) => void;
    onNo: () => void;
    latitude: number,
    longitude: number,
    radius: number,
};

const HotSpotModal: React.FC<Props> = ({ latitude, longitude, radius, show, onYes, onNo }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hotSpot] = useState<HotSpotDto>({
        id: '',
        name: '',
        latitude: latitude,
        longitude: longitude,
        radius: radius
    });
    const form = useForm<HotSpotDto>({ shouldUnregister: false, defaultValues: { ...hotSpot } });
    const { register, handleSubmit, errors } = form;

    const customStyles = {
        content: {
            top: '15%',
            left: '35%',
            right: '35%',
            bottom: 'auto',
            backgroundClip: 'padding-box',
            backgroundColor: '#FFFFFF',
            border: '1px solid transparent',
            borderRadius: '4px',
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.3)',
            outline: '0 none',
            padding: '1.5em'
        },
        overlay: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            zIndex:1000,
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }
    };

    const onSubmit = async (formData: HotSpotDto) => {
        setIsLoading(true);

        try {
            Loading.show();
            if (hotSpot) {
                hotSpot.latitude = formData.latitude;
                hotSpot.longitude = formData.longitude;
                hotSpot.name = formData.name;
                hotSpot.radius = formData.radius;
                hotSpot.id = await HotSpotsService.create(hotSpot);
            }
            Loading.hide();
            addToast(t('common.messages.record_save_success'), {
                appearance: 'success',
            });
            onYes(hotSpot);
        } catch (error) {
            if (error?.response?.status === 402) {
                addToast(t('common.errors.pack_limit_exceeded'), { appearance: 'warning' });
            }
            else {
                addToast(t('common.messages.record_save_error'), {
                    appearance: 'error',
                });
            }
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't create location hotspot`, error);
            Loading.hide();
        } finally {
            setIsLoading(false);
        }
    };

    const onError = async () => {
        addToast(t('common.errors.required_fields_empty'), { appearance: 'warning' });
    }

    return (
        <Modal
            isOpen={show}
            style={customStyles}
        >
            <form className={styles.form} onSubmit={handleSubmit(onSubmit, onError)}>
                <div className={styles.titleContainer}>
                    <Label className={styles.textTitle}>{t('vehicles_gps.save_hot_spot')}</Label>
                </div>

                    <Row>
                        <Col xs={12}>
                            <FormItem>
                                <Label className={styles.bold}>
                                    {t('vehicles_gps.hot_spot_name')}
                                </Label>
                                <Input
                                name='name'
                                placeholder={t('vehicles_gps.hot_spot_name')}
                                defaultValue={hotSpot?.name}
                                disabled={false}
                                ref={register({ ...DEFAULT_INPUT_RULES_WITH_REQUIRED })}
                            />
                            <InputError error={errors.name} />
                                </FormItem>
                        </Col>
                    </Row>
                <div className={styles.buttons_container}>
                    <Button text={t('common.cancel')} preset={'secondary'} size={'normal'} onClick={onNo} disabled={isLoading} />
                    <Button type='submit' text={t('common.save')} size={'normal'} isLoading={isLoading} />
                </div>
            </form>
        </Modal>
    );
}

export default HotSpotModal;
