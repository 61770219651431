import { VictoryLabel } from 'victory';

const ChartLegendLabel = (props: any) => {
    const textSize = props.datum.textSize;
    const height = textSize.height + 5;
    return (
        <g style={{ cursor: props.datum.clickable ? 'pointer' : 'auto', userSelect: 'none' }}>
            <rect
                x={props.x}
                y={props.y - height / 2}
                width={textSize.width + 10}
                height={height}
                fill="transparent"
                onClick={() => {
                    if (props.onClick && props.datum.clickable) {
                        props.onClick(props);
                    }
                }}
            />
            <VictoryLabel 
                {...props} 
                events={{ 
                    onClick: () => {
                        if (props.onClick && props.datum.clickable) {
                            props.onClick(props);
                        }
                    }
                }}
            />
        </g>
    );
};

export default ChartLegendLabel;
