import { UserProfile } from 'api/account/models/UserProfile';
import { Paged } from 'api/common/types/Page';
import CompaniesService from 'api/companies/CompaniesService';
import { CompanyDto } from 'api/companies/models/CompanyDto';
import { CompanySearchCriteria } from 'api/companies/models/CompanySearchCriteria';
import UsersService from 'api/users/UsersService';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import FiltersIcon from 'assets/svg/desktop_filtres.svg';
import Box from 'common/components/box/Box';
import Dropdown from 'common/components/dropdown/Dropdown';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import Popover from 'common/components/popover/Popover';
import DropdownItem from 'common/components/popover/PopoverItem';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import Logger from 'common/services/Logger';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEllipsisH } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Reducers } from 'store/types';
import styles from './CompaniesScreen.module.scss';
import CompanyFiltersScreen, { CompanyFilters } from './components/CompanyFiltersScreen';

type Props = {
};

const CompaniesScreen: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const [criteria, setCriteria] = useState<CompanySearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderBy: 'asc',
        orderColumn: 'c.name'
    });
    const [companiesPage, setCompaniesPage] = useState<Paged<CompanyDto>>();

    const history = useHistory();

    const [filters, setFilters] = useState<CompanyFilters>({});
    const [filtersTotal, setFiltersTotal] = useState(0);

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasCompaniesWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_COMPANIES_WRITE']);

    const getData = async () => {
        try {
            const page = await CompaniesService.getList(criteria);
            setCompaniesPage(page);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't get companies list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const renderTableActionCell = (row: CompanyDto) => {
        return <Dropdown
            options={
                <>
                    <DropdownItem url={`companies/details/${row.id}`}>
                        {t('common.details')}
                    </DropdownItem>
                    {hasCompaniesWritePolicy && <DropdownItem url={`companies/edit/${row.id}`}>
                        {t('common.edit')}
                    </DropdownItem>}
                    {hasCompaniesWritePolicy && <DropdownItem onClick={() => showRemoveItemDialog(row)}>
                        {t('common.remove')}
                    </DropdownItem>}
                </>
            }
        >
            <div>
                <FaEllipsisH />
            </div>
        </Dropdown>
    };

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const tableColumns: ListingTableColumn<CompanyDto>[] = [
        {
            field: 'name',
            name: t('companies.list.name'),
            onSearch: onTableFilter,
            searchField: 'c.name'
        },
        {
            field: 'email',
            name: t('companies.list.email'),
            onSearch: onTableFilter,
            searchField: 'c.email'
        },
        {
            field: 'taxNumber',
            name: t('companies.list.tax_number'),
            onSearch: onTableFilter,
            searchField: 'c.tax_number'
        },
        // {
        //     field: 'addressLine',
        //     name: t('companies.list.address'),
        //     onSearch: onTableFilter,
        //     searchField: 'c.address_line'
        // },
        // {
        //     field: 'zipCode',
        //     name: t('companies.list.zip_code'),
        //     onSearch: onTableFilter,
        //     searchField: 'c.zip_code'
        // },
        {
            field: 'city',
            name: t('companies.list.city'),
            onSearch: onTableFilter,
            searchField: 'c.city'
        },
        {
            name: '',
            width: '60px',
            cellAlignment: 'right',
            preventClick: true,
            renderCell: renderTableActionCell,
            cellStyle: { overflow: 'unset' }
        },
    ];

    const navigateTo = (type?: string, id?: string) => {
        if (id) {
            history.push(`/companies/${type}/${id}`);
        } else {
            history.push(`/companies/${type}`);
        }
    }

    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<CompanyDto | null>(null);

    const showRemoveItemDialog = async (item: CompanyDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const onRowClick = (event: any, id?: string) => {
        if (id) {
            if (event.ctrlKey) {
                window.open(history.location.pathname + '/details/' + id, '_blank');
                return;
            }
            navigateTo('details', id);
        }
    }

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }

        try {
            await CompaniesService.remove(itemToRemove);
            onCancelRemove();
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });

            setCriteria({ ...criteria, page: 1 })
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete companies`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
        }
    };

    useEffect(() => {
        getData();
    }, [criteria]);

    const onChangeFilters = (filters: CompanyFilters) => {
        updateTotalFilters(filters);
        setFilters(filters);
        criteria.name = filters.name;
        criteria.email = filters.email;
        criteria.taxNumber = filters.taxNumber;
        getData();
    }

    const updateTotalFilters = (filters: CompanyFilters) => {
        let total = 0;
        if (filters.name != null || filters.email != null || filters.taxNumber != null) {
            total++;
        }
        
        setFiltersTotal(total);
    }

    return (
        <ScreenTitle title={t('companies.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('companies.title')}>
                    <Popover
                        contentContainerClassName={styles.filtersPopoverContainer}
                        containerStyle={{ boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}
                        positions={['bottom', 'left']}
                        align={'end'}
                        onClickOutside={() => updateTotalFilters(filters)}
                        content={setIsPopoverOpen => <CompanyFiltersScreen
                            filters={filters}
                            onFilter={filters => { setIsPopoverOpen(false); onChangeFilters(filters) }}
                            onChange={filters => updateTotalFilters(filters)}
                        />}>
                        {(isPopoverOpen, setIsPopoverOpen) => (
                            <ScreenHeaderButton icon={FiltersIcon} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                                {filtersTotal > 0 &&
                                    <div className={styles.counterList}> <div className={styles.counterNumber}>{filtersTotal}</div> </div>
                                }
                            </ScreenHeaderButton>
                        )}
                    </Popover>
                    {hasCompaniesWritePolicy && <ScreenHeaderButton icon={AddIcon} onClick={() => navigateTo('new')} />}
                </ScreenHeader>

                <Box>
                    <ListingTable
                        columns={tableColumns}
                        rows={companiesPage?.items || []}
                        onRowClick={(row, _, event) => onRowClick(event, row.id)}
                       // onHref={row => `companies/edit/${row.id}`}
                        allowHover={true}
                        initialSearch={{ colField: 'c.name', isOrderAsc: true }}
                    />
                    <PaginationWithInfo
                        itemName={t('companies.title')}
                        currentPage={companiesPage?.currentPage ?? 1}
                        pageItems={companiesPage?.items.length || 0}
                        totalItems={companiesPage?.totalItems || 0}
                        onChange={page => setCriteria({ ...criteria, page: page })}
                    />
                </Box>
                <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('common.messages.remove_record_with_ident', { name: itemToRemove?.name ?? '' })}/>
            </ScreenContainer>
        </ScreenTitle>
    );
};

export default CompaniesScreen;
