import Sidebar from 'common/layouts/sidebar/Sidebar';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ScreenTitle from '../../common/components/screenTitle/ScreenTitle';
import styles from './NotFoundScreen.module.scss';

const NotFoundScreen: React.FC = () => {

    const { t } = useTranslation();

    return (
         <ScreenTitle title={t('common.errors.page_not_found')}>
         <div className={styles.container} >
             <div className={styles.sidebarContainer}>
                 <Sidebar items={[]} />
             </div>
             <div className={styles.contentContainer} id="scrollHere">
                 <h1>404 - {t('common.errors.page_not_found')}</h1>
             </div>
         </div>
     </ScreenTitle>
    );
}

export default NotFoundScreen;
