import React from 'react';
import styles from './Button.module.scss';

type ButtonPreset = 'primary' | 'secondary' | 'success' | 'danger' | 'popover' | 'secondaryDanger' | 'primaryAlt' | 'white' | 'secondary-success' | 'disabled';

type ButtonSize = 'normal' | 'small' | 'extraSmall';

type Props = {
    text?: string;
    preset?: ButtonPreset;
    onlyIcon?: boolean;
    size?: ButtonSize;
    isDisabled?: boolean;
    isLoading?: boolean;
    bw?: boolean;
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const Button: React.FC<Props> = ({ children, type, text, preset = 'primary', size, onlyIcon, isDisabled, isLoading, bw = true, ...props }: Props) => {

    const presetClassName = preset ? styles[preset] : styles.primary;
    const sizeClassName = size ? (styles as any)['size'+size[0].toUpperCase()+size.substr(1, size.length)] : styles.sizeNormal;

    return (
        <button
            type={type || 'button'}
            {...props}
            onClick={e => {
                if (isLoading) {
                    e.preventDefault();
                    e.stopPropagation();
                }
                else {
                    props.onClick && props.onClick(e);
                }
            }}
            className={`${onlyIcon ? styles.buttonIcon : styles.button} ${presetClassName} ${sizeClassName} ${props.className || ''} ${isDisabled || props.disabled ? styles.disabled : ''} ${bw ? styles.bw : ''}`}
        >
            {isLoading && <div className={styles.buttonLoadingOverlay}>
                <span className={styles.spinner}></span>
            </div>}
            {Boolean(text) && text}
            {children}
        </button>
    );
}

export default Button;
