import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './TechnicalInformationFormItem.module.scss';
import { Col, Row } from 'react-flexbox-grid';
import FormItem from 'common/components/formItem/FormItem';
import InputController from 'common/components/input/InputController';
import { DEFAULT_INPUT_RULES_WITH_REQUIRED } from 'Config';
import { ReactComponent as TrashIcon } from 'assets/svg/trash_no_color.svg';
import { UseFormMethods } from 'react-hook-form';
import { VehicleViewModel } from 'api/vehicles/models/VehicleViewModel';
import Label from 'common/components/label/Label';
import InputError from 'common/components/inputError/InputError';
import CustomFile from 'common/models/CustomFile';
import FileSelector from 'common/components/fileSelector/FileSelector';


type Props = {
    form: UseFormMethods<VehicleViewModel>;
    isDetails?: boolean
    onRemoveClick: () => void;
    index: number;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const TechnicalInformationFormItem: React.FC<Props> = ({
    form,
    isDetails,
    onRemoveClick,
    index,
    ...props
}: Props) => {
    const { t } = useTranslation();
    const errors = (form.errors?.technicalInformations || [])[index] || null;
    
    form.setValue(`technicalInformations[${index}].index`, index);

    const setValuesFiles = (files: CustomFile[]) => {
        form.setValue(`technicalInformations[${index}].attachments`, files);
    }

    const onRemoveFile = (file: CustomFile) => {
        if (file.id) {
            const arr = [...(form.getValues(`technicalInformations[${index}].attachmentsToRemove` as any) || []), file.id];
            form.setValue(`technicalInformations[${index}].attachmentsToRemove`, arr);
        }
    }

    return (
        <div
            {...props}
            className={`${styles.container} ${(errors && styles.containerError) || ''} ${props.className || ''}`}
            style={{
                ...(props.style || {}),
            }}
        >
            <Row>
                <Col xs={12} lg={6} xl={3}>
                    <FormItem>
                        <Label className={styles.label}>{t('vehicle.entitled')} *</Label>
                        <InputController
                            name={`technicalInformations[${index}].entitled`}
                            form={form as any}
                            autoComplete='off'
                            placeholder={t('vehicle.entitled')}
                            rules={{ ...DEFAULT_INPUT_RULES_WITH_REQUIRED }}
                            disabled={isDetails}
                        />
                        <InputError error={errors?.entitled} />

                    </FormItem>
                </Col>
                <Col xs={12} lg={6} xl={4}>
                    <FormItem>
                        <Label className={styles.label}>{t('vehicle.description')}</Label>
                        <InputController
                            name={`technicalInformations[${index}].description`}
                            form={form as any}
                            autoComplete='off'
                            placeholder={t('vehicle.description')}
                            disabled={isDetails}
                        />
                    </FormItem>
                </Col>
                <Col xs={10} lg={10} xl={isDetails ? 5 : 4}>
                    <FormItem>
                        <Label className={styles.label}>{t('vehicle.document')}</Label>
                        <FileSelector
                            isDetails={isDetails}
                            isMulti={true}
                            initialFiles={form.getValues(`technicalInformations[${index}].attachments`)}
                            onFilesChanged={setValuesFiles}
                            onRemoveFile={onRemoveFile}
                            container={`technicalInformation_${index}`}
                            fileButtonSizes={{ sm: 6, lg: 4 , xl: 6 }}
                            inLine={true}
                        />
                    </FormItem>
                </Col>
                {!isDetails &&
                    <Col xs={2} lg={2} xl={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                        <div className={styles.removeButton}>
                            <TrashIcon className={styles.removeIcon} onClick={onRemoveClick} />
                        </div>
                    </Col>}

            </Row>

        </div >
    );
}

export default TechnicalInformationFormItem;
