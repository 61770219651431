import Http from 'common/services/Http';
import { Paged } from 'api/common/types/Page';
import { AlertsSearchCriteria } from './models/AlertsSearchCriteria';
import { AlertsListItemDto } from './models/AlertsListItemDto';
import { AlertsTotalsDto } from './models/AlertsTotalsDto';
import { CreateAlertViewModel } from './models/CreateAlertViewModel';

class AlertsService {

    public getList(criteria: AlertsSearchCriteria) {
        return Http.get<Paged<AlertsListItemDto>>('alerts', criteria);
    }
    public getAlerts(criteria: AlertsSearchCriteria) {
        return Http.get<AlertsListItemDto[]>('alerts/get-list', criteria);
    }

    public getTotals(criteria?: AlertsSearchCriteria) {
        return Http.get<AlertsTotalsDto>('alerts/totals', criteria || { page: 1, itemsPerPage: 9999999 });
    }

    public createAlert(model: CreateAlertViewModel) {
        return Http.post('alerts', model);
    }

    public read(alertId: string) {
        return Http.put('alerts/read', { alertId });
    }
   
}

export default new AlertsService();
