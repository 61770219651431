import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AnnotationsForm.module.scss';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { useFieldArray, UseFormMethods } from 'react-hook-form';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import AnnotationsFormItem from '../annotationsFormItem/AnnotationsFormItem';
import { MediaItemDto } from 'api/common/models/MediaItemDto';
import { VerificationAnnotationViewModel, VerificationViewModel } from 'api/carVerification/models/VerificationViewModel';
import { InterventionTypeEnum } from 'api/carVerification/enums/InterventionTypeEnum';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';

type Props = {
    form: UseFormMethods<VerificationViewModel>;
    isDetails: boolean;
    annotationTypes: SelectValueLabel[];
    maintenances: SelectValueLabel[];
    onRemoveMedia: (media: MediaItemDto) => void;
    onRemoveAnnotation: (id: string) => void;
    onClickAddMaintenance: (annotationIndex: number) => void;
    onTouch: (val: boolean) => void;
};

const AnnotationsForm: React.FC<Props> = ({ form, isDetails, annotationTypes, maintenances, onRemoveMedia, onRemoveAnnotation, onClickAddMaintenance, onTouch }: Props) => {
    const { t } = useTranslation();

    const annotations = useFieldArray<VerificationAnnotationViewModel>({ control: form.control, name: 'annotations', keyName: 'formId' as any });
    const [showRemoveAnnotation, setShowRemoveAnnotation] = useState<boolean>(false);
    const [annotationToRemoveIndex, setAnnotationToRemoveIndex] = useState<number | null>(null);

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasAnnotationsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_ANNOTATIONS_WRITE']);

    const addAnnotation = () => {
        annotations.append({
            id: null,
            description: '',
            intervention: InterventionTypeEnum.INFORMATION,
            status: null,
            medias: [],
            index: annotations.fields.length
        });
    }

    const removeItem = () => {
        if (annotationToRemoveIndex === null) return;

        if (annotations.fields[annotationToRemoveIndex]?.id) {
            onRemoveAnnotation(annotations.fields[annotationToRemoveIndex].id!);
        }

        annotations.remove(annotationToRemoveIndex);

        setShowRemoveAnnotation(false);
    }

    const setRemoveItem = (index: number) => {
        setShowRemoveAnnotation(true);
        setAnnotationToRemoveIndex(index);
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}
                style={{ borderRadius: annotations.fields.length === 0 ? '10px' : '' }}>
                <div className={styles.headerText}>{annotations.fields.length} {t('car_verification.annotations')} </div>
                {!isDetails && hasAnnotationsWritePolicy && <div>
                    <span className={styles.headerButton} onClick={addAnnotation}>
                        <span className={styles.headerButtonText}>{t('car_verification.verification.add_annotation')}</span>
                        <img src={AddIcon} className={styles.headerButtonIcon} />
                    </span>
                </div>}
            </div>

            {annotations.fields.length === 0 && <div className={styles.noAnnotationsText}>
                {t('car_verification.verification.without_annotations')}
            </div>}

            {annotations.fields.length > 0 && <div>
                {annotations.fields.map((annotation, index) => {
                    return (
                        <AnnotationsFormItem
                            key={annotation.formId}
                            form={form}
                            isDetails={isDetails}
                            annotationTypes={annotationTypes}
                            maintenances={maintenances}
                            onRemoveMedia={onRemoveMedia}
                            onRemoveClick={() => {
                                setRemoveItem(index);
                            }}
                            index={index}
                            annotation={annotation}
                            onClickAddMaintenance={() => onClickAddMaintenance(index)}
                            onTouch={onTouch}
                        />
                    );
                })}
            </div>}

            <QuestionYesNo
                message={t('common.messages.remove_record')}
                isVisible={showRemoveAnnotation}
                onYes={() => removeItem()}
                onNo={() => { setShowRemoveAnnotation(false); setAnnotationToRemoveIndex(null) }}
            />
        </div>
    );
}

export default AnnotationsForm;
