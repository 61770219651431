import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { VehicleSegmentSearchCriteria } from './models/VehicleSegmentSearchCriteria';
import { VehicleSegmentDto } from './models/VehicleSegmentDto';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { CatalogSegmentsSearchCriteria } from 'api/catalog/models/CatalogSegmentsSearchCriteria';

class VehicleSegmentsService {

    public getList(criteria: VehicleSegmentSearchCriteria) {
        return Http.get<Paged<VehicleSegmentDto>>('vehiclesegments', criteria);
    }

    public catalog(criteria?: CatalogSegmentsSearchCriteria) {
        return Http.get<SelectValueLabel[]>('vehiclesegments/catalog', criteria);
    }

    public getFeatures(id: string) {
        return Http.get<string[]>('vehiclesegments/' + id + '/features');
    }

    public getById(id: string) {
        return Http.get<VehicleSegmentDto>('vehiclesegments/' + id);
    }

    public create(model: VehicleSegmentDto) {
        return Http.post<string>('vehiclesegments', model);
    }

    public update(model: VehicleSegmentDto) {
        return Http.put('vehiclesegments', model);
    }

    public remove(model: VehicleSegmentDto) {
        return Http.put('vehiclesegments/deactivate', model);
    }

}

export default new VehicleSegmentsService();