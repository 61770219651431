import Box from 'common/components/box/Box';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styles from './MaintenancesScreen.module.scss';
import Popover from 'common/components/popover/Popover';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import FiltersIcon from 'assets/svg/desktop_filtres.svg';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import { CarVerificationSearchCriteria } from 'api/carVerification/models/CarVerificationSearchCriteria';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import MaintenanceFiltersScreen, { Filters } from './components/maintenanceFilters/MaintenancesFiltersScreen';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import ListMaintenancesScreen from 'screens/vehicle/components/carMaintenanceTab/components/MaintenanceList/ListMaintenancesScreen';
import { VehicleMaintenanceCounterListDto, VehicleMaintenanceDto } from 'api/vehicleMaintenances/models/VehicleMaintenanceDto';
import MaintenanceScreen from 'screens/vehicle/components/carMaintenanceTab/components/MaintenanceScreen';
import Logger from 'common/services/Logger';
import { useToasts } from 'react-toast-notifications';
import CarIcon from 'assets/svg/car.svg';
import CoinsIcon from 'assets/svg/coins.svg';
import MaintenancesIcon from 'assets/svg/maintenance.svg';
import VehicleMaintenancesService from 'api/vehicleMaintenances/VehicleMaintenancesService';
import FilterList from 'common/components/filterList/FilterList';
import DateFormat from 'common/components/dateFormat/dateFormat';
import CounterBox from 'common/components/counterBox/CounterBox';

type RouteProps = {
    itemId: string;
    type: 'new' | 'edit' | 'details';
}

const MaintenancesScreen = () => {
    const { itemId, type } = useParams<RouteProps>();

    const history = useHistory();
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_VEHICLE_MAINTENANCES_WRITE']);

    const [filters, setFilters] = useState<Filters>({ vehiclesInactive: true });
    const [filtersTotal, setFiltersTotal] = useState(1);
    const [pageLoad, setPageLoad] = useState(false);

    const [counters, setCounters] = useState<VehicleMaintenanceCounterListDto>();

    const [criteria, setCriteria] = useState<CarVerificationSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderColumn: 'vm.expected_date',
        orderBy: 'desc',
        vehiclesInactive: true,
    });

    const getData = async () => {
        try {
            const countersDB = await VehicleMaintenancesService.getCountersList(criteria);
            setCounters(countersDB);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.VEHICLES, `Couldn't get vehicles counters`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const updateTotalFilters = (f: Filters) => {
        let total = 0;

        if (f.startDate || f.endDate) {
            total++;
        }
        if (f.currentState) {
            total++;
        }
        if (f.typeId) {
            total++;
        }
        if (f.supplierId) {
            total++;
        }
        if (f.vehicleId) {
            total++;
        }
        if (f.responsibleId) {
            total++;
        }
        if (f.vehiclesInactive) {
            total++;
        }

        setFiltersTotal(total);
    }


    const savePageChange = (page: number) => {
        filters.page = page;
        localStorage.setItem('MAINTENANCEFILTER', JSON.stringify(filters));
    }

    const loadFilters = () => {
        const cachedFilters = localStorage.getItem('MAINTENANCEFILTER');
        if (cachedFilters != null) {
            const filters = JSON.parse(cachedFilters);
            setCriteria({ ...criteria, ...filters });
            setFilters({ ...filters });
            updateTotalFilters(filters);
        }
        setPageLoad(true)
    }

    const onChangeFilters = (_filters: Filters) => {
        setCriteria({ ...criteria, ..._filters });
        setFilters({ ..._filters });
        updateTotalFilters(_filters);
        localStorage.setItem('MAINTENANCEFILTER', JSON.stringify(_filters));
    }

    const goToDetails = (id: string) => {
        navigateTo('details', id);
    }

    const goToEdit = (id: string) => {
        if (hasWritePolicy) {
            navigateTo('edit', id);
        } else {
            goToDetails(id);
        }
    }

    const navigateTo = (typeUrl?: string, id?: string) => {
        if (typeUrl) {
            history.push(typeUrl === 'new' ? '/maintenances/new' : `/maintenances/${typeUrl}/${id}`);
        } else {
            history.push('/maintenances');
        }
    }

    useEffect(() => {
        loadFilters();
    }, []);

    useEffect(() => {
        if (pageLoad) { getData() }
    }, [criteria]);

    const renderBox = (icon: any, label: string, counter: number, isMoney?: boolean) => {
        return <CounterBox
            icon={icon}
            label={label}
            counter={counter}
            isMoney={isMoney}
        />
    }

    const renderCounterBoxes = () => {
        return <div className={styles.rowCounters}>
            {counters && renderBox(CarIcon, t('maintenances.list.number_vehicles'), counters.numberVehicles ?? 0)}
            {counters && renderBox(MaintenancesIcon, t('maintenances.list.number_maintenances'), counters.numberMaintenances ?? 0)}
            {counters && renderBox(CoinsIcon, t('maintenances.list.total_cost', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) }), counters.costTotalAccumulated ?? 0, true)}
        </div>
    }

    const onRowClick = (event: any, item: VehicleMaintenanceDto, isDetails: boolean) => {
        event.preventDefault();
        event.stopPropagation();

        navigateTo(isDetails ? 'details' : 'edit', item.id);

    }

    return (
        <ScreenTitle title={t('maintenances.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('maintenances.title')}>
                    {!type && <>
                        <Popover
                            contentContainerClassName={styles.filtersPopoverContainer}
                            containerStyle={{ boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}
                            // positions={['bottom', 'left']}
                            // align={'end'}
                            onClickOutside={() => updateTotalFilters(filters)}
                            content={setIsPopoverOpen => <MaintenanceFiltersScreen
                                genericFilter={true}
                                filters={filters}
                                onFilter={_filters => { setIsPopoverOpen(false); onChangeFilters(_filters) }}
                                onChange={_filters => updateTotalFilters(_filters)}
                            />}
                        >
                            {(isPopoverOpen, setIsPopoverOpen) => (
                                <ScreenHeaderButton icon={FiltersIcon} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                                    {filtersTotal > 0 &&
                                        <div className={styles.counterList}> <div className={styles.counterNumber}>{filtersTotal}</div> </div>
                                    }
                                </ScreenHeaderButton>
                            )}
                        </Popover>
                        {hasWritePolicy && <ScreenHeaderButton icon={AddIcon} onClick={() => navigateTo('new')} />}
                    </>}
                </ScreenHeader>

                <FilterList filters={[
                    {
                        value: filters.executedStartDate && filters.executedEndDate ? <>{t('common.range.from')} <DateFormat value={filters.executedStartDate} /> {t('common.range.to').toLowerCase()} <DateFormat value={filters.executedEndDate} /></>
                            : filters.executedStartDate ? <>{t('common.range.from')} <DateFormat value={filters.executedStartDate} /></>
                                : filters.executedEndDate ? <>{t('common.range.to')} <DateFormat value={filters.executedEndDate} /></> : undefined,
                        label: t('maintenances.date_maintenance'),
                        onRemove: () => { filters.executedStartDate = filters.executedEndDate = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.startDate && filters.endDate ? <>{t('common.range.from')} <DateFormat value={filters.startDate} /> {t('common.range.to').toLowerCase()} <DateFormat value={filters.endDate} /></>
                            : filters.startDate ? <>{t('common.range.from')} <DateFormat value={filters.startDate} /></>
                                : filters.endDate ? <>{t('common.range.to')} <DateFormat value={filters.endDate} /></> : undefined,
                        label: t('maintenances.expected_date'),
                        onRemove: () => { filters.startDate = filters.endDate = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.vehicleName,
                        label: t('vehicle.vehicle'),
                        onRemove: () => { filters.vehicleId = filters.vehicleName = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.typeName ? t(`common.maintenance_contract_type.${filters.typeName}` as any) : filters.typeName,
                        label: t('common.type'),
                        onRemove: () => { filters.typeId = filters.typeName = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.currentStateName,
                        label: t('common.state'),
                        onRemove: () => { filters.currentState = filters.currentStateName = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.supplierName,
                        label: t('common.supplier'),
                        onRemove: () => { filters.supplierId = filters.supplierName = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.responsibleName,
                        label: t('common.responsible'),
                        onRemove: () => { filters.responsibleId = filters.responsibleName = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.vehiclesInactive ? t('common.yes') : '',
                        label: t('maintenances.vehicle_inactives'),
                        onRemove: () => { filters.vehiclesInactive = false; onChangeFilters(filters) }
                    },
                ]} />

                {!type && counters && renderCounterBoxes()}

                <Box className={`${styles.box}`}>
                    {/* LIST */}
                    {!type && pageLoad && <>
                        <ListMaintenancesScreen
                            editable={true}
                            criteria={criteria}
                            setPage={savePageChange}
                            createNewMaintenance={() => null}
                            onClickItem={(item: VehicleMaintenanceDto, isDetails: boolean, event: any) => onRowClick(event, item, isDetails)}>
                        </ListMaintenancesScreen>
                    </>}

                    {/* DETAILS */}
                    {type && <MaintenanceScreen
                        editable={true}
                        item={{ id: itemId } as VehicleMaintenanceDto}
                        isDetails={type === 'details'}
                        onItemDetails={isDetails => isDetails ? goToDetails(itemId) : goToEdit(itemId)}
                        onSubmitDone={() => history.goBack()}
                        onBackList={() => history.goBack()}
                        titleHeader={type === 'new' ? t('maintenances.new') : type === 'details' ? t('maintenances.details') : type === 'edit' ? t('maintenances.edit') : t('maintenances.title')}
                    />}
                </Box>
            </ScreenContainer>
        </ScreenTitle>
    );
}

export default MaintenancesScreen;
