import CustomFile from 'common/models/CustomFile';
import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { AccidentDto } from './models/AccidentDto';
import { AccidentsSearchCriteria } from './models/AccidentsSearchCriteria';

class AccidentsService {

    public getList(criteria: AccidentsSearchCriteria) {
        return Http.get<Paged<AccidentDto>>('accidents', criteria);
    }

    public getById(id: string) {
        return Http.get<AccidentDto>('accidents/' + id);
    }

    public create(model: AccidentDto, attachment?: CustomFile[]) {
        return Http.postWithFile<string>('accidents', model, attachment ?? []);
    }

    public update(model: AccidentDto, attachment?: CustomFile[]) {
        return Http.putWithFile('accidents', model, attachment ?? []);
    }

    public remove(model: AccidentDto) {
        return Http.put('accidents/deactivate', model);
    }
}

export default new AccidentsService();