 import { SelectValueLabel } from 'common/types/SelectValueLabel';
import Http from '../../common/services/Http';
 import { LanguageDto } from './models/LanguageDto';

class LanguagesService {

    public getById(id: string) {
        return Http.get<LanguageDto>('languages/' + id);
    }
    
    public getAll() {
        return Http.get<LanguageDto[]>('languages/all');
    }


    public GetAllForSelectItem() {
        return Http.get<SelectValueLabel[]>('languages/get-All-for-select-item');
    }
    

}

export default new LanguagesService();