import React, { useEffect, useState } from 'react';
import { MapContainer, Polyline, Popup, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import styles from './CarGpsTab.module.scss';
import Loading from 'common/services/Loading';
import { useTranslation } from 'react-i18next';
import TrackingService from 'api/tracking/TrackingService';
import { FaCalendarAlt, FaRegClock, FaMapMarkerAlt, FaGasPump, FaHourglassEnd } from 'react-icons/fa';
import { Col, Row } from 'react-flexbox-grid';
import FormItem from 'common/components/formItem/FormItem';
import DateTimePicker from 'common/components/dateTimePicker/DateTimePicker';
import { DATEPICKER_FORMAT_DEFAULT, DATE_TIME_FORMAT_DEFAULT, DEFAULT_LANGUAGE, STORAGE } from 'Config';
import InputGroup from 'common/components/inputGroup/InputGroup';
import Storage from 'common/services/Storage';
import Utils from 'common/services/Utils';
import moment from 'moment';
import TrackingRoutes from 'api/tracking/models/TrackingRoutes';
import DateFormat from 'common/components/dateFormat/dateFormat';
import GaugeIcon from 'assets/svg/gauge.svg';
import DistanceFormat from 'common/components/distanceFormat/DistanceFormat';
import { addressFormat, useGeoLocator } from 'common/services/GeoLocator';
import LoadingSpinner from 'common/components/loading/LoadingSpinner';
import { TrackingItemDto } from 'api/tracking/models/TrackingItemDto';
import RoutingMachine from 'common/components/maps/RoutineMachine';
import CurrentLocation from 'screens/vehicles-gps/components/currentLocation/CurrentLocation';
import SortingDropdown from 'common/components/sortingDropdown/SortingDropdown';
import ReactDOMServer from 'react-dom/server';
import EcoDriving from 'screens/vehicles-gps/components/ecoDriving/EcoDriving';
import ReactTooltip from 'react-tooltip';
import MarkerPopup from 'screens/vehicles-gps/components/markerPopup/MarkerPopup';
import RecenterMap from 'screens/vehicles-gps/components/recenterMap/RecenterMap';
import NumberFormat from 'react-number-format';

type Props = {
    vehicleId: string;
    tourReRender: () => void;
}

const CarGpsTab = ({ vehicleId, tourReRender }: Props) => {
    const { t } = useTranslation();
    const maxZoom = 18;

    const [routes, setRoutes] = useState<TrackingRoutes[]>([]);
    const [date, setDate] = useState<Date>(new Date());
    const locale = Storage.get(STORAGE.CURRENT_LOCALE) || DEFAULT_LANGUAGE;
    const [position, setPosition] = useState<TrackingItemDto>();
    const [waypoints, setWaypoints] = useState<L.LatLng[]>([]);
    const [zoom, setZoom] = useState<number>(12);
    const [selectedRouteKey, setSelectedRouteKey] = useState<string>('');
    const [waypointsInfo, setWaypointsInfo] = useState<TrackingItemDto[]>([]);
    const [refresh, setRefresh] = useState<number>(1);
    const redOptions = { color: 'red' }
    const [orderBy, setOrderBy] = useState<string>(localStorage.getItem('ROUTESFILTERORDERBY') ?? 'DESC');
    const [showPoints, setShowPoints] = useState<boolean>(Storage.get('SHOWROUTEPOINTS') == 'true');
    const [loaded, setLoaded] = useState(false);
    const [positionLoaded, setPositionLoaded] = useState(false);

    useEffect(() => {
        getData();
    }, [date, orderBy])

    useEffect(() => {
        getPosition();
    }, [vehicleId])

    const getData = async () => {
        try {
            if (loaded) {
                Loading.show(); //#49402
            }
            const orderColumn = 'start_date';
            const _routes = await TrackingService.getRoutes(vehicleId, date, orderColumn, orderBy);
            setRoutes(_routes);
            getPosition();
            setWaypoints([]);
            setWaypointsInfo([]);
            setSelectedRouteKey('');
            setRefresh(refresh + 1);
            setLoaded(true);
        }
        finally {
            if (loaded) {
                Loading.hide(); //#49402
            }
            tourReRender();
        }
    }

    const getPosition = async () => {
        try {
            if (loaded) {
                Loading.show(); //#49402
            }
            const positionResult = await TrackingService.getPosition(vehicleId);
            setPosition(positionResult);
            setPositionLoaded(true);
        }
        catch (err) {
            if (loaded) {
                Loading.hide(); //#49402
            }
        }
    }

    const loadWaypoints = async (start: number, stop: number) => {
        try {
            Loading.show();
            const _waypoints = await TrackingService.getWaypoints(vehicleId, start, stop);
            const waypoints: L.LatLng[] = [];
            if (_waypoints) {
                _waypoints.forEach(i => {
                    waypoints.push(L.latLng(i.latitude ?? 0, i.longitude ?? 0));
                });
            }
            setSelectedRouteKey(`georoute_${start}_${stop}`)
            setWaypointsInfo(_waypoints);
            setWaypoints(waypoints);
            calculateZoom(Utils.getDistanceFromLatLonInKm(_waypoints[_waypoints.length - 1].latitude ?? 0, _waypoints[_waypoints.length - 1].longitude ?? 0, _waypoints[0].latitude ?? 0, _waypoints[0].longitude ?? 0))
            setRefresh(refresh + 1);
            Loading.hide();
        } catch (err) {
            Loading.hide();
        }

    }

    const GeoLocatorItem = ({ id, latitude, longitude, fuelLevel, icon, date, isLast, tooltipMessage, index, speed, address }: { id: number, latitude: number, longitude: number, fuelLevel: number, icon: string, date?: Date, isLast: boolean, tooltipMessage?: string, index?: number, speed: number, address: string }) => {
        let formatedAddress = '';
        if (address) {
            formatedAddress = address;
        } else {
            const { isLoading, error, data } = useGeoLocator(latitude, longitude);
            if (isLoading) return <LoadingSpinner />;
            if (error) console.log('An error occurred while fetching the user data ', error);
            formatedAddress = addressFormat(data,latitude, longitude);
            try {
                TrackingService.updateAddress(id, formatedAddress).catch(console.error);
            } catch (error) {
                console.log('Couldn\'t update route address', error);
            }
        }
        return (
            <div className={styles.inputGroup} data-tip={tooltipMessage ? tooltipMessage : undefined} data-for={`${tooltipMessage + '_' + index}`}>
                <div className={icon}><FaMapMarkerAlt /></div>
                {!isLast && <div className={styles.iconLine}></div>}
                <div className={styles.input}>
                    <span className={styles.labelAddress}>{formatedAddress}</span>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        {date && <div className={styles.labelDate}><DateFormat format={DATE_TIME_FORMAT_DEFAULT} value={date} /></div>}
                        {isLast && (speed > 0) && <div className={styles.moving}>
                            <div>{t('location.in_transit')}</div>
                        </div>}
                        <div className={styles.infoFuel}>
                            {fuelLevel > 0 && <><FaGasPump /><NumberFormat className={styles.labelFuel} displayType='text' decimalScale={0} suffix='%' value={fuelLevel} /></>}
                        </div>
                    </div>
                </div>
                <ReactTooltip id={`${tooltipMessage + '_' + index}`} />
            </div>
        );
    };

    const GeoRoutes = ({ idx, start, stop, startLatitude, startLongitude, stopLatitude, stopLongitude, startDate, stopDate, startKm, stopKm, startFuelLevel, stopFuelLevel, ecoValue, idling, speed, startAddress, stopAddress }:
        { idx: number, start: number, stop: number, startLatitude: number, startLongitude: number, stopLatitude: number, stopLongitude: number, startKm: number, stopKm: number, startDate: Date, stopDate: Date, startFuelLevel: number, stopFuelLevel: number, ecoValue: number, idling: number, speed: number, startAddress: string, stopAddress: string }) => {
        const kms = stopKm - startKm;

        return (
            <div className={`${styles.card} ${selectedRouteKey === `georoute_${start}_${stop}` ? styles.selected : ''}`} onClick={() => loadWaypoints(start, stop)} key={`georoute_${start}_${stop}`}>
                <div className={styles.cardBody}>
                    <GeoLocatorItem id={start} latitude={startLatitude} longitude={startLongitude} fuelLevel={startFuelLevel} icon={styles.sourcePin} date={startDate} isLast={false} tooltipMessage={t('location.info_messages.origin')} index={idx} speed={speed} address={startAddress} />
                    <GeoLocatorItem id={stop} latitude={stopLatitude} longitude={stopLongitude} fuelLevel={stopFuelLevel} icon={styles.destinationPin} date={stopDate} isLast={true} tooltipMessage={t('location.info_messages.destination')} index={idx} speed={speed} address={stopAddress} />
                </div>
                <div className={styles.cardFooter}>
                    <div className={`${styles.spaceBetween} ${styles.flexWrap}`}>
                        <div className={styles.badge} data-tip={t('location.info_messages.distance')} data-for={`distance_${idx}`}>
                            <div className={`${styles.icon}`} style={{ backgroundImage: 'url(' + GaugeIcon + ')' }} />
                            <div><DistanceFormat value={kms} showLessThan1Km={false} /></div>
                        </div>
                        <ReactTooltip id={`distance_${idx}`} />
                        <div className={styles.badge} data-tip={t('location.info_messages.time')} data-for={`time_${idx}`}>
                            <div className={styles.mr}><FaRegClock /></div>
                            <div>{calcTimeTotalRoute(startDate, stopDate)}</div>
                        </div>
                        <ReactTooltip id={`time_${idx}`} />
                        <div className={styles.badge} data-tip={t('location.info_messages.idling')} data-for={`idling_${idx}`}>
                            <div className={styles.mr}><FaHourglassEnd /></div>
                            <div>{calcIdlingTotalRoute(idling)}</div>
                        </div>
                        <ReactTooltip id={`idling_${idx}`} />
                        <>
                            <EcoDriving value={ecoValue} idx={idx} />
                        </>
                    </div>
                </div>
            </div >
        );
    };

    const calcTimeTotalRoute = (startDate: Date, stopDate: Date) => {
        const diffInTime = new Date(stopDate).getTime() - new Date(startDate).getTime();
        const hours = Math.floor(diffInTime / (1000 * 60 * 60))
        const minutes = Math.floor((diffInTime - (hours * 1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.round((diffInTime - (hours * 3600 * 1000) - (minutes * 1000 * 60)) / 1000);
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}  h`;
    }

    const calcTimeTotalRoutes = (routes: TrackingRoutes[]) => {
        const diffInTime = routes.map(r => new Date(r.stopDate).getTime() - new Date(r.startDate).getTime()).reduce((p, c) => p + c);
        const hours = Math.floor(diffInTime / (1000 * 60 * 60))
        const minutes = Math.floor((diffInTime - (hours * 1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.round((diffInTime - (hours * 3600 * 1000) - (minutes * 1000 * 60)) / 1000);
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}  h`;
    }

    const calcPenaltyTotalRoutes = (routes: TrackingRoutes[]) => {
        const kms = routes.map(r => r.stopKm - r.startKm).reduce((p, c) => p + c);
        const penalty = routes.map(r => r.penalty).reduce((p, c) => p + c);
        return kms > 0 ? (penalty / kms) : penalty;
    }

    const calcIdlingTotalRoute = (idling: number) => {
        const hours = Math.floor(idling / 3600);
        const minutes = Math.floor((idling - (hours * 3600)) / 60);
        const seconds = Math.round(idling - (hours * 3600) - (minutes * 60));
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} h`;
    }

    const calcIdlingTotalRoutes = (routes: TrackingRoutes[]) => {
        const all = routes.map(r => r.idling).reduce((p, c) => p + c);
        const hours = Math.floor(all / 3600);
        const minutes = Math.floor((all - (hours * 3600)) / 60);
        const seconds = Math.round(all - (hours * 3600) - (minutes * 60));
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} h`;
    }

    const moveToCurrent = () => {
        setSelectedRouteKey('');
        setWaypointsInfo([]);
        setWaypoints([]);
        setRefresh(refresh + 1);
    }

    const customIcon = new L.DivIcon({
        html: ReactDOMServer.renderToString(<FaMapMarkerAlt className={`${position?.ignition ? styles.pinIgnitionOn : styles.pinIgnitionOff}`} style={{ fontSize: '30px' }} />), // Renderiza o ícone do FaMapMarkerAlt como HTML
        iconAnchor: [15, 30],//para a base do icon ficar exatamente nas coordenadas e não ficar centrado
        popupAnchor: [1, -34],
        className: styles.pin
    });

    const calculateZoom = (distance?: number) => {
        if (!distance) {
            return;
        }

        let z = 15 + Math.round(Math.log2(1 / distance));

        const minZoom = 5;
        if (z > maxZoom) {
            z = maxZoom;
        } else if (z < minZoom) {
            z = minZoom;
        }

        setZoom(z);
    }

    const renderInfo = (i: number) => {
        if (!waypointsInfo[i]) return <></>;
        return <MarkerPopup position={waypointsInfo[i]} isRoute={i == 0 ? 'END' : (i == waypointsInfo.length-1 ? 'START' : 'NO')} />
    }

    const loadIconColor = (i: number) => {
        return i == 0 ? '#dd1021' : (i == waypointsInfo.length-1 ? '#1bd44d' : '#1781A1');
    }

    const renderMap = () => {
        const zoomPosition = waypointsInfo && waypointsInfo.length > 0 ? waypointsInfo[Math.round(waypointsInfo.length / 2)] : position;
        if (position && zoomPosition) {
            return <MapContainer style={{ width: '100%', height: '100%' }} center={[zoomPosition.latitude ?? 0, zoomPosition.longitude ?? 0]} maxZoom={maxZoom} minZoom={5} zoom={zoom} scrollWheelZoom={true} tap={false}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {!(waypointsInfo && waypointsInfo.length > 0) &&
                    <Marker position={[position.latitude ?? 0, position.longitude ?? 0]} icon={customIcon}>
                        <Popup minWidth={180} closeButton={false}>
                            <MarkerPopup position={position} isRoute='NO' />
                        </Popup>
                    </Marker>
                }
                <RoutingMachine
                    waypoints={waypoints}
                    icon={customIcon}
                    hide={!showPoints}
                    renderInfo={renderInfo}
                    loadIconColor={loadIconColor}
                />
                <Polyline pathOptions={redOptions} positions={waypoints} />
                <RecenterMap lat={zoomPosition.latitude} lng={zoomPosition.longitude} zoom={zoom} />
            </MapContainer>
        } else {
            return <></>
        }
    }

    const handleSortChange = (option: string) => {
        setOrderBy(option);
        localStorage.setItem('ROUTESFILTERORDERBY', option);
    };

    const handleShowPoints = (option: boolean) => {
        setShowPoints(option);
        setRefresh(refresh + 1);
        Storage.set('SHOWROUTEPOINTS', `${option}`);
    };


    if (!loaded) {
        return <div className={styles.noDataContainer}>
            <LoadingSpinner />
        </div>
    }

    if (!position && loaded && positionLoaded) {
        return <div className={styles.noDataContainer}>
            {t('location.info_messages.no_data')}
        </div>
    }

    return (
        <div className={styles.container}>
            <Row>
                <Col xs={12} md={4}>
                    <FormItem>
                        <DateTimePicker
                            popperClassName={styles.onFg}
                            name="date"
                            dateFormat={DATEPICKER_FORMAT_DEFAULT}
                            locale={locale}
                            autoComplete='off'
                            selected={moment(date).toDate()}
                            placeholderText={t('common.date')}
                            onChange={(date: Date) => { setDate(date); }}
                            maxDate={moment(new Date()).toDate()}
                            customInput={<InputGroup icon={<FaCalendarAlt />} />}
                        />
                    </FormItem>
                    {routes && routes.length > 0 ?
                        <Col>
                            <div className={`${styles.card}`}>
                                <div className={styles.cardBody}>
                                    <div className={`${styles.spaceBetween} ${styles.flexWrap}`}>
                                        <div className={styles.badge} data-tip={t('location.info_messages.total_distance')} data-for="total_distance">
                                            <div className={`${styles.icon}`} style={{ backgroundImage: 'url(' + GaugeIcon + ')' }} />
                                            <div><DistanceFormat value={routes.map(r => r.stopKm - r.startKm).reduce((p, c) => p + c)} showLessThan1Km={false} /></div>
                                        </div>
                                        <ReactTooltip id="total_distance" />
                                        <div className={styles.badge} data-tip={t('location.info_messages.total_time')} data-for="total_time">
                                            <div className={styles.mr}><FaRegClock /></div>
                                            <div>{calcTimeTotalRoutes(routes)}</div>
                                        </div>
                                        <ReactTooltip id="total_time" />
                                        <div className={styles.badge} data-tip={t('location.info_messages.total_idling')} data-for="total_idling">
                                            <div className={styles.mr}><FaHourglassEnd /></div>
                                            <div>{calcIdlingTotalRoutes(routes)}</div>
                                        </div>
                                        <ReactTooltip id="total_idling" />
                                        <>
                                            <EcoDriving value={calcPenaltyTotalRoutes(routes)} idx={'all'} />
                                        </>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.totalContainer}>
                                <small className={styles.totalText}>
                                    {routes.map(_ => 1).reduce((p, c) => p + c)} {t('location.path')}
                                </small>
                                <div className={styles.toggleContainer} data-tip={t('location.info_messages.show_points')} data-for="showPoints">
                                    <small className={styles.toggleLabel}>{t('location.show_points')}</small>
                                    <label className={`${styles.toggleSwitch}`}>
                                        <input
                                            type='checkbox'
                                            checked={showPoints ? true : false}
                                            onChange={e => handleShowPoints(e.target.checked)}
                                        />
                                        <span className={styles.switch} />
                                    </label>
                                    <ReactTooltip id="showPoints" />
                                </div>
                                <SortingDropdown
                                    onSortChange={handleSortChange}
                                    defaultOption={orderBy}
                                    closeDropOnSelect={true}
                                />
                            </div>
                            <div className={styles.geoLocation} >
                                {routes.map((route, i) => <GeoRoutes idx={i} key={i}
                                    start={route.start} stop={route.stop}
                                    startLatitude={route.startLatitude} startLongitude={route.startLongitude}
                                    stopLatitude={route.stopLatitude} stopLongitude={route.stopLongitude}
                                    startDate={route.startDate} stopDate={route.stopDate}
                                    startKm={route.startKm} stopKm={route.stopKm}
                                    startFuelLevel={route.startFuelLevel} stopFuelLevel={route.stopFuelLevel}
                                    ecoValue={route.ecoValue} idling={route.idling} speed={route.stopSpeed}
                                    startAddress={route.startAddress} stopAddress={route.stopAddress}/>)}
                            </div>


                        </Col>
                        :
                        <Col>
                            <div className={`${styles.emptyContainer}`}>
                                {t('location.info_messages.without_routes')}
                            </div>
                        </Col>}
                </Col>
                <Col xs={12} md={8}>
                    <Row>
                        <Col xs={12} onClick={() => moveToCurrent()} className={styles.currentLocation}>
                            <CurrentLocation position={position} />
                        </Col>
                        <Col xs={12}>
                            <div className={styles.map} key={`map_${refresh}`}>
                                {renderMap()}
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default CarGpsTab;