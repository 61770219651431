import React from 'react';
import { useTranslation } from 'react-i18next';
import Tour from 'reactour';
import HelperModal from '../helperModal/HelperModal';
import Storage from '../../../common/services/Storage';
import { STORAGE } from 'Config';

export type Props = {
    steps: any[];
    isOpen: boolean;
    onRequestClose: () => void;
    changeTab?: (tab: string) => void;
    startAt?: number;
    reRender?: string;
    className?: string
};

const InfoTour = ({ steps, isOpen, onRequestClose, changeTab, startAt, reRender, className }: Props) => {
    const { t } = useTranslation();

    const profile = Storage.getObject(STORAGE.AUTH_USER_PROFILE);
    const urlFaq = profile && profile.languageCode === 'fr' ? 'fr/questions-frequentes' :
        profile && profile.languageCode === 'pt' ? 'pt/faq' :
            'eu/faq';
    const urlContacts = profile && profile.languageCode === 'fr' ? 'fr/contacts' :
        profile && profile.languageCode === 'pt' ? 'pt/contactos' :
            'eu/contacts/';

    return (
        <Tour
            className={className}
            steps={[...steps, {
                selector: '',
                content: <HelperModal title={t('welcome.help')} text={t('welcome.help_message')} />
            }]}
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            //lastStepNextButton={<Button text={'Done!'}></Button>}
            maskSpace={10}
            rounded={20}
            showNumber={false}
            accentColor={'#16E0CC'}
            startAt={startAt ?? 0}
            getCurrentStep={(curr: any) => {
                const step = steps[curr];
                if (step && step.tab && step.tab != '' && changeTab) {
                    changeTab(step.tab);
                }
            }}
            update={reRender ?? ''}
            //showNavigation={false}
        />
    )
}

export default InfoTour;
