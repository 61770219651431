import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import { useToasts } from 'react-toast-notifications';
import { UserDto } from 'api/users/models/UserDto';
import { UserSearchCriteria } from 'api/users/models/UserSearchCriteria';
import Loading from 'common/services/Loading';
import UsersService from 'api/users/UsersService';
import Logger from 'common/services/Logger';
import { Paged } from 'api/common/types/Page';
import styles from './UsersList.module.scss';
import InputSearch from 'common/components/inputSearch/InputSearch';
import { useDebouncedCallback } from 'use-debounce';
import DropdownItem from 'common/components/popover/PopoverItem';
import { FaEllipsisH } from 'react-icons/fa';
import Dropdown from 'common/components/dropdown/Dropdown';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import Popover from 'common/components/popover/Popover';
import UsersFiltersScreen, { UserFilters } from 'screens/security/components/usersTab/components/usersFilters/UsersFiltersScreen';
import AvatarWithText from 'common/components/avatar/AvatarWithText';
import { useHistory } from 'react-router-dom';
import { UserProfile } from 'api/account/models/UserProfile';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import FiltersIcon from 'assets/svg/desktop_filtres.svg';
import AddIcon from 'assets/svg/desktop_add_new.svg';

type Props = {
};

const UsersList: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const [usersPage, setUsersPage] = useState<Paged<UserDto>>();
    const [criteria, setCriteria] = useState<UserSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderBy: 'asc',
        orderColumn: 'u.real_name'
    });
    const [filters, setFilters] = useState<UserFilters>({});
    const [filtersTotal, setFiltersTotal] = useState(0);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<UserDto | null>(null);
    const [pageLoad, setPageLoad] = useState(false);
    const history = useHistory();

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasUsersWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_USERS_WRITE']);

    const showRemoveItemDialog = async (item: UserDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }

        try {
            await UsersService.remove(itemToRemove);
            setCriteria({ ...criteria, page: 1 });
            onCancelRemove();
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete user`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
        }
    };

    const renderTableActionCell = (row: UserDto) => {
        return (
            <Dropdown
                options={
                    <>
                        <DropdownItem url={`/security/users/details/${row.id}`}>
                            {t('common.details')}
                        </DropdownItem>
                        { hasUsersWritePolicy && <DropdownItem url={`/security/users/edit/${row.id}`}>
                            {t('common.edit')}
                        </DropdownItem>}
                        { hasUsersWritePolicy && <DropdownItem onClick={() => showRemoveItemDialog(row)}>
                            {t('common.remove')}
                        </DropdownItem>}
                    </>
                }
            >
                <div>
                    <FaEllipsisH />
                </div>
            </Dropdown>
        );
    };

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const tableColumns: ListingTableColumn<UserDto>[] = [
        {
            name: t('users.list.name'),
            renderCell: row => <AvatarWithText src={row.mediaUrl} size="x3"><span style={{ wordBreak: 'break-word', maxWidth: '90%' }}>{row.realName}</span></AvatarWithText>,
            onSearch: onTableFilter,
            searchField: 'u.real_name'
        },
        {
            field: 'email',
            name: t('users.list.email'),
            onSearch: onTableFilter,
            searchField: 'u.email',
            cellStyle: { wordBreak: 'break-all' }
        },
        {
            name: '',
            width: '60px',
            cellAlignment: 'right',
            preventClick: true,
            renderCell: renderTableActionCell,
            cellStyle: { overflow: 'unset' }
        },
    ];

    const getData = async () => {
        try {
            Loading.show();

            const page = await UsersService.getList(criteria);
            setUsersPage(page);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't get users list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    };


    const saveFiltersOnCache = (f: UserFilters) => {
        setFilters(f);
        localStorage.setItem('USERSFILTER', JSON.stringify(f));
    }

    const loadFilters = () => {
        const cachedFilters = localStorage.getItem('USERSFILTER');
        if (cachedFilters != null) {
            const filters: UserFilters = JSON.parse(cachedFilters);
            setCriteria({ ...criteria, roles: filters.roles?.map(r => r.value) });
            setFilters(filters);
            updateTotalFilters(filters);
        }
        setPageLoad(true)
    }

    const onChangeFilters = (filters: UserFilters) => {
        updateTotalFilters(filters);
        setCriteria({ ...criteria, roles: filters.roles?.map(r => r.value), page: 1 });
        saveFiltersOnCache(filters)
    }

    const updateTotalFilters = (filters: UserFilters) => {
        let total = 0;
        if (filters.roles && filters.roles.length > 0) {
            total++;
        }

        setFiltersTotal(total);
    }

    const debounced = useDebouncedCallback((value: string) => {
        setCriteria({ ...criteria, page: 1, allIn: value });
    }, 500);

    const navigateTo = (typeUrl?: string, id?: string) => {

        if (typeUrl === 'new' ){
            canAddUsers();
        }
        history.push(`/security${typeUrl ? `/users/${typeUrl}${id ? `/${id}` : ''}` : ''}`);
    }

    const onRowClick = (event: any, id?: string) => {
        if (id) {
            event.preventDefault();
            event.stopPropagation();

            filters.page = criteria.page;
            saveFiltersOnCache(filters);

            if (event.ctrlKey) {
                window.open('/security/users/details/' + id, '_blank');
                return;
            }
            navigateTo('details', id);
        }
    }

    const canAddUsers = async () => {
        try {
            Loading.show();
            await UsersService.candAddUsers();
            Loading.hide();
        } catch (error) {
            if (error?.response?.status === 402) {
                addToast(t('common.errors.pack_limit_exceeded'), { appearance: 'warning' });
                history.push(`/account/details/subscription`);
            }
        }        
    }

    useEffect(() => {

        loadFilters();
        getData();
    }, []);

    useEffect(() => {
        
        if (pageLoad) { getData() }
    }, [criteria]);


    return (
        <div className={`list ${styles.container}`}>
            <div className={styles.tabHeader}>
                <div>
                    <InputSearch onChangeValue={debounced} placeholder={t('common.search')} ></InputSearch>
                </div>

                <div className={styles.rowFlex}>
                    <Popover
                        contentContainerClassName={styles.filtersPopoverContainer}
                        containerStyle={{ boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}
                        positions={['bottom', 'left']}
                        align={'end'}
                        onClickOutside={() => updateTotalFilters(filters)}
                        content={setIsPopoverOpen => <UsersFiltersScreen
                            filters={filters}
                            onFilter={filters => { setIsPopoverOpen(false); onChangeFilters(filters) }}
                            onChange={filters => updateTotalFilters(filters)}
                        />}>
                        {(isPopoverOpen, setIsPopoverOpen) => (
                            <ScreenHeaderButton icon={FiltersIcon} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                                {filtersTotal > 0 &&
                                    <div className={styles.counterList}> <div className={styles.counterNumber}>{filtersTotal}</div> </div>
                                }
                            </ScreenHeaderButton>
                        )}
                    </Popover>
                    {hasUsersWritePolicy && <ScreenHeaderButton icon={AddIcon} onClick={() => navigateTo('new')} />}
                </div>
            </div>
            <ListingTable
                columns={tableColumns}
                rows={usersPage?.items || []}
                onRowClick={(row, _, event) => onRowClick(event, row.id)}
                onHref={row => `/security/users/details/${row.id}`}
                allowHover={true}
                initialSearch={{ colField: 'u.real_name', isOrderAsc: true }}
            />
            <PaginationWithInfo
                itemName={t('users.list.users')}
                currentPage={usersPage?.currentPage || 1}
                pageItems={usersPage?.items.length || 0}
                totalItems={usersPage?.totalItems || 0}
                onChange={page => setCriteria({ ...criteria, page })}
            />
            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('common.messages.remove_record_with_ident', { name: itemToRemove?.realName ?? '' })} />
        </div>
    );
};

export default UsersList;
