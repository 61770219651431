import { useTranslation } from 'react-i18next';
import styles from './AlertsFiltersHeader.module.scss';
import { AlertsSearchCriteria } from 'api/alerts/models/AlertsSearchCriteria';
import React from 'react';
import InputSearch from 'common/components/inputSearch/InputSearch';
import { useDebouncedCallback } from 'use-debounce/lib';

type Props = {
    //showReadOnly: boolean;
    //totals: AlertsTotalsDto;
    criteria: AlertsSearchCriteria;
    onChangeCriteria: (criteria: AlertsSearchCriteria) => void;
    //onShowReadOnlyChange: (readOnly: boolean) => void;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const AlertsFiltersHeader = ({ criteria, onChangeCriteria, ...props }: Props) => {
    const { t } = useTranslation();


    const debounced = useDebouncedCallback((value: string) => {
        onChangeCriteria({ ...criteria, page: 1, allIn: value });
    }, 500);


    return (
        <div {...props} className={`${styles.informationHeader} ${props.className || ''}`}>
            {/*<Badge
                className={styles.infoBadge}
                preset={!showReadOnly ? 'primaryLight' : 'primaryLightInverted'}
                onClick={() => onShowReadOnlyChange(false)}
            >
                {t('alerts.list.not_all_read')}: {totals.totalNotRead}
            </Badge>
            <Badge
                className={styles.infoBadge}
                preset={showReadOnly ? 'primaryLight' : 'primaryLightInverted'}
                onClick={() => onShowReadOnlyChange(true)}
            >
                {t('alerts.list.all_read')}: {totals.totalRead}
            </Badge>*/}

            <InputSearch
                placeholder={t('common.search')}
                onChangeValue={debounced}
                inputContainerClassName={styles.inputContainer} />

        </div>
    );
}

export default AlertsFiltersHeader;
