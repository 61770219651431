import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import { useToasts } from 'react-toast-notifications';
import { VehicleBrandsSearchCriteria } from 'api/vehicleBrands/models/VehicleBrandsSearchCriteria';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { Paged } from 'api/common/types/Page';
import styles from './VehicleBrandsList.module.scss';
import InputSearch from 'common/components/inputSearch/InputSearch';
import { useDebouncedCallback } from 'use-debounce';
import { VehicleBrandDto } from 'api/vehicleBrands/models/VehicleBrandsDto';
import VehicleBrandsService from 'api/vehicleBrands/VehicleBrandsService';
import Dropdown from 'common/components/dropdown/Dropdown';
import DropdownItem from 'common/components/dropdown/DropdownItem';
import { FaEllipsisH } from 'react-icons/fa';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { useHistory } from 'react-router-dom';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import AddIcon from 'assets/svg/desktop_add_new.svg';

type Props = {
};

const VehicleBrandsList: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const history = useHistory();

    const [vehicleBrandsPage, setVehicleBrandsPage] = useState<Paged<VehicleBrandDto>>();
    const [criteria, setCriteria] = useState<VehicleBrandsSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderColumn: 'name',
        orderBy: 'asc'
    });

    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<VehicleBrandDto | null>(null);

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasVehicleBrandsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_VEHICLE_BRANDS_WRITE']);

    const showRemoveItemDialog = (item: VehicleBrandDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    };

    const renderSegmentsCell = (row: VehicleBrandDto) => <>
        {row.segmentNames?.join(', ')}
    </>;

    const renderTableActionCell = (
        row: VehicleBrandDto,
    ) => {
        return (
            <Dropdown
                options={
                    <>
                        <DropdownItem url={`/settings/brands/details/${row.id ?? ''}`}>
                            {t('common.details')}
                        </DropdownItem>
                        {hasVehicleBrandsWritePolicy && <DropdownItem url={`/settings/brands/edit/${row.id ?? ''}`}>
                            {t('common.edit')}
                        </DropdownItem>}
                        {hasVehicleBrandsWritePolicy && <DropdownItem onClick={() => showRemoveItemDialog(row)}>
                            {t('common.remove')}
                        </DropdownItem>}
                    </>
                }
            >
                <div>
                    <FaEllipsisH />
                </div>
            </Dropdown>
        );
    };

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const tableColumns: ListingTableColumn<VehicleBrandDto>[] = [
        {
            field: 'name',
            name: t('vehicle_brands.list.name'),
            onSearch: onTableFilter,
            searchField: 'vb.name'
        },
        {
            field: 'segments',
            name: t('vehicle_brands.list.vehicle_segments'),
            renderCell: renderSegmentsCell
        },
        {
            name: '',
            width: '60px',
            cellAlignment: 'right',
            preventClick: true,
            renderCell: renderTableActionCell,
            cellStyle: { overflow: 'unset' },
        },
    ];

    const getData = async () => {
        try {
            Loading.show();

            const page = await VehicleBrandsService.getList(criteria);
            setVehicleBrandsPage(page);

            Loading.hide();
        } catch (error) {
            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                `Couldn't get vehicle brands list`,
                error
            );
            addToast(t('common.messages.error_load_info'), {
                appearance: 'error',
            });
            Loading.hide();
        }
    };

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }

        try {
            await VehicleBrandsService.remove(itemToRemove);
            setCriteria({ ...criteria, page: 1 });
            onCancelRemove();
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete vehicle brands`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
        }
    };

    useEffect(() => {
        getData();
    }, [criteria]);

    const debounced = useDebouncedCallback((value: string) => {
        setCriteria({ ...criteria, page: 1, allIn: value });
    }, 500);

    return (
        <div className='list'>
            <div className={styles.tabHeader}>
                <div>
                    <InputSearch
                        onChangeValue={debounced}
                        placeholder={t('common.search')}
                        defaultValue={criteria.allIn}
                    ></InputSearch>
                </div>
                <div>
                    {hasVehicleBrandsWritePolicy && <ScreenHeaderButton icon={AddIcon} onClick={() => history.push(`/settings/brands/new`)} /> }
                </div>
            </div>
            <ListingTable
                columns={tableColumns}
                rows={vehicleBrandsPage?.items || []}
                onRowClick={row => history.push(`/settings/brands/details/${row.id ?? ''}`)}
                onHref={row => `/settings/brands/details/${row.id ?? ''}`}
                allowHover={true}
                initialSearch={{ colField: 'vb.name', isOrderAsc: true }}
            />
            <PaginationWithInfo
                itemName={t('vehicle_brands.list.vehicle_brands')}
                currentPage={vehicleBrandsPage?.currentPage || 1}
                pageItems={vehicleBrandsPage?.items.length || 0}
                totalItems={vehicleBrandsPage?.totalItems || 0}
                onChange={page => setCriteria({ ...criteria, page })}
            />
            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('common.messages.remove_vehicle_brand', { segment: itemToRemove?.name ?? '' })}/>

        </div>
    );
};

export default VehicleBrandsList;
