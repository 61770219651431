import React from 'react';
import styles from './Tabs.module.scss';

export interface TabItem {
    id: string;
    title: string | React.ReactElement;
    content?: React.ReactElement;
}

type Props = {
    items: TabItem[];
    activeTabId: string;
    onChange: (tabId: string) => void;
    emptyTabMessage?: string;
}

const Tabs = ({ items, activeTabId, onChange, emptyTabMessage }: Props) => {
    const activeTab = items.find(x => x.id === activeTabId)!;
    return (
        <div className={styles.container}>
            <div className={styles.list}>
                {items.map(item => (
                    <div
                        key={`tab-${item.id}`}
                        onClick={() => { onChange(item.id); }}
                        className={`${styles.listItem} ${activeTabId == item.id && styles.listItemActive}`}
                    >
                        {item.title}
                    </div>
                ))}
            </div>
            {Boolean(activeTab) ? <div key={`tab-content-${activeTab.id}`} className={styles.content}>
                {activeTab!.content}
            </div>
                :
                emptyTabMessage && <div key={`tab-content-empty`} className={styles.content}>
                    <div className={styles.message}>{emptyTabMessage}</div>
                </div>
            }

        </div>
    );
};

export default Tabs;