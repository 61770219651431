export interface CommunicationDto {    
    id: string;    
    name: string;
    expression: string;
    concatType?: CommunicationConcatType;
}

export enum CommunicationConcatType {
    OR = 'OR',
    AND = 'AND',
}
