import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './TechnicalInformationForm.module.scss';
import { useFieldArray, UseFormMethods } from 'react-hook-form';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { VehicleTechnicalInformationViewModel, VehicleViewModel } from 'api/vehicles/models/VehicleViewModel';
import TechnicalInformationFormItem from '../technicalInformationFormItem/TechnicalInformationFormItem';

type Props = {
    form: UseFormMethods<VehicleViewModel>;
    isDetails: boolean;
    onRemoveTechnicalInformation: (id: string) => void;
};

const TechnicalInformationForm: React.FC<Props> = ({ form, isDetails, onRemoveTechnicalInformation }: Props) => {
    const { t } = useTranslation();

    const technicalInformations = useFieldArray<VehicleTechnicalInformationViewModel>({ control: form.control, name: 'technicalInformations', keyName: 'formId' as any });
    const [showRemoveTechnicalInformation, setShowRemoveTechnicalInformation] = useState<boolean>(false);
    const [technicalInformationToRemoveIndex, setTechnicalInformationToRemoveIndex] = useState<number | null>(null);


    const addTechnicalInformation = () => {
        technicalInformations.append({
            id: null,
            description: '',
            attachments: [],
            index: technicalInformations.fields.length
        });
    }

    const removeItem = () => {
        if (technicalInformationToRemoveIndex === null) return;

        if (technicalInformations.fields[technicalInformationToRemoveIndex]?.id) {
            onRemoveTechnicalInformation(technicalInformations.fields[technicalInformationToRemoveIndex].id!);
        }

        technicalInformations.remove(technicalInformationToRemoveIndex);

        setShowRemoveTechnicalInformation(false);
    }

    const setRemoveItem = (index: number) => {
        setShowRemoveTechnicalInformation(true);
        setTechnicalInformationToRemoveIndex(index);
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.headerText}>{t('vehicle.technical_information')} </div>
                {!isDetails && <div>
                    <span className={styles.headerButton} onClick={addTechnicalInformation}>
                        <span className={styles.headerButtonText}></span>
                        <img src={AddIcon} className={styles.headerButtonIcon} />
                    </span>
                </div>}
            </div>

            {technicalInformations.fields.length === 0 && <div className={styles.noInfoText}>
                {t('vehicle.without_technical_information')}
            </div>}

            {technicalInformations.fields.length > 0 && <div className={styles.content}>
                {technicalInformations.fields.map((ti, index) => {
                    return (
                        <TechnicalInformationFormItem
                            key={ti.formId}
                            form={form}
                            isDetails={isDetails}
                            onRemoveClick={() => {
                                setRemoveItem(index);
                            }}
                            index={index}

                        />
                    );
                })}
            </div>}

            <QuestionYesNo
                message={t('common.messages.remove_record')}
                isVisible={showRemoveTechnicalInformation}
                onYes={() => removeItem()}
                onNo={() => { setShowRemoveTechnicalInformation(false); setTechnicalInformationToRemoveIndex(null) }}
            />
        </div>
    );
}

export default TechnicalInformationForm;
