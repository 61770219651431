import React, { useState, useRef  } from 'react';
import { Controller, RegisterOptions, UseFormMethods } from 'react-hook-form';
import InputResizable, { Props as InputProps } from './InputResizable';
import { Item, Menu, useContextMenu, theme } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import { useTranslation } from 'react-i18next';

type Props = {
    form: UseFormMethods<any>;
    id?: string;
    name: string;
    rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
    contextMenuOptions?: ContextMenuAreaOption[];
} & InputProps;

export type ContextMenuAreaOption = {
    onClick: (element: HTMLTextAreaElement | undefined) => string;
    name: string;
}
const InputResizableController = ({ form, name, rules,id, contextMenuOptions, ...props }: Props) => {
    const [element, setElement] = useState<HTMLTextAreaElement | undefined>();    
    const { show } = useContextMenu({ id: id ?? 'context-area-menu' });
    const { t } = useTranslation();
    const reference = useRef(null);

    const onRegisterRef = (instance: HTMLTextAreaElement) => {
        
        if (instance) {
            setElement(instance);
        }
        return instance;
    }
   
    const renderContextMenu = (onChange : any) => {
        return <Menu id={id ?? 'context-menu'} theme={theme.light}>
            {contextMenuOptions?.map((option, index) => {
                return <Item key={`item-option-${index}`} onClick={() => {  onChange(option.onClick((reference!= null? reference?.current: undefined) as (HTMLTextAreaElement | undefined)));}}>
                    <div>
                        <span>{t(option.name as any)}</span>
                    </div>
                </Item>;
            })}
        </Menu>;
    }

    return (
        <Controller
            render={({ onChange, value }) => {
                return (    
                    <>
                        { contextMenuOptions  && !props.disabled &&
                             <div onContextMenu={show}>
                                <InputResizable
                                    {...props}
                                    ref={ reference }
                                    onChange={e => {                                       
                                        onChange(e.target.value);
                                    }}
                                    value={value}
                                />
                                {renderContextMenu(onChange)}
                            </div>}   

                        { (!contextMenuOptions || props.disabled) && 
                                   <InputResizable
                                   {...props}
                                   ref={reference}
                                   onChange={e => {
                                       onChange(e.target.value);
                                   }}
                                   value={value}
                               />
                        }
                    </>
                );
            }}
            control={form.control}
            name={name}
            defaultValue={form.getValues(name) || ''}
            rules={rules}
        />
    );
};

export default InputResizableController;
