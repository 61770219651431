import CompaniesService from 'api/companies/CompaniesService';
import { CompanyPacksDto } from 'api/companies/models/CompanyPacksDto';
import { PackTypesEnum } from 'api/packs/enums/PackTypesEnum';
import Box from 'common/components/box/Box';
import FormItem from 'common/components/formItem/FormItem';
import Input from 'common/components/input/Input';
import Label from 'common/components/label/Label';
import CustomFile from 'common/models/CustomFile';
import React, { memo, useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import styles from './PlansScreen.module.scss';
import { PackDto } from 'api/packs/models/PackDto';
import DateFormat from 'common/components/dateFormat/dateFormat';
import PlanIcon from 'assets/svg/FleetMax_logo_verde.svg';
import Badge from 'common/components/badge/Badge';
import Button from 'common/components/button/Button';
import Storage from '../../../common/services/Storage';
import { DATE_TIME_FORMAT_DEFAUT, STORAGE } from 'Config';
import ReactTooltip from 'react-tooltip';

enum ValidMonthsTypeColorEnum {
    VALID = '#56CF76',//green
    WARNING = '#E2B434', //yellow 
    DANGER = '#DC4C4C',//red    
}

type Props = {
    companyId?: string;
    companyName?: string;
    isDetails: boolean;
    companyMedia?: CustomFile | null;
    issues: string[];
}

const PlansScreen: React.FC<Props> = ({ companyId, companyName, isDetails, companyMedia, issues }: Props) => {
    const { t } = useTranslation();
    const [companyPacks, setCompanyPacks] = useState<CompanyPacksDto>({ id: '', vehiclesCreated: 0, driversCreated: 0, adminsCreated: 0, packs: [] });
    const [limit, setLimit] = useState<number>(0);

    const getData = async () => {
        const [packsInfo] = await Promise.all([
            CompaniesService.getCompanyPacksInfo(companyId ?? '')
        ]);

        //TEMPORARY
        setCompanyPacks(packsInfo);


        if (packsInfo.packs && packsInfo.packs.filter(x => x.active).length > 0) {


            const groups = groupByPacks(packsInfo.packs.filter(x => x.active), (item: PackDto) => item.group); //agrupar packs por group
            const keys = Array.from(groups.keys());
            //const key = keys.sort((a, b) => a - b)[0]; //ordernar pelo group mais baixo ou seja prioritário
            setLimit(packsInfo.packs.filter(x => (x.validDays > 0|| x.validMonths > 0 || x.validYears > 0)  && x.active === true).map(x => x.limit).sum());
        }


    }

    useEffect(() => {
        getData();
    }, []);


    const groupByPacks = (list: Array<PackDto>, keyGetter: (item: PackDto) => number) => {
        const map = new Map();
        list.forEach(item => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }




    const renderIssues = (issues: string[]) => {
        return issues.length ? (

            <Row className={styles.headerBoxWarning}>
                <Col xs={12}>
                    {issues.map((x, i) => { return <Label key={`issues_${i}`} className={styles.label}>{t(`issues.${x}` as any)}</Label> })}
                </Col>
            </Row>

        ) : null
    }
    const badgeText = (plan: PackDto) => {
        if (plan.validYears > 0) {
            return t('plans.valid_by_x_years_months').replace('@years', plan.validYears.toString()).replace('@months', plan.validMonths.toString())
        }
        if (plan.validYears === 0 && plan.validMonths > 2) {
            return t('plans.valid_by_x_months').replace('@months', plan.validMonths.toString())
        }
        if (plan.validMonths > 0 && plan.validMonths < 3) {
            return t('plans.expire_in_x_days').replace('@days', plan.validDays.toString())
        }
        if (plan.validMonths === 0 && plan.validDays > 0) {
            return t('plans.expire_in_x_days').replace('@days', plan.validDays.toString())
        }
        if (plan.validDays <= 0) {
            return t('plans.expired')
        }

        return '';
    }
    return (
        <Box className={styles.mainBox}>
            {issues && issues.length > 0 && renderIssues(issues)}
            {(companyName) && <Row className={`${styles.companyRow}`}>
                <Col xs={8}>
                    <FormItem>
                        <Label className={styles.bold}>
                            {t('plans.company')}
                        </Label>
                        <Input
                            name='companyName'
                            defaultValue={companyName}
                            disabled={true}
                        />
                    </FormItem>
                </Col>
                {companyMedia && <Col xs={3} className={styles.companyLogoCol}>
                    <img src={companyMedia?.url ?? ''} className={styles.companyLogo} />
                </Col>}
            </Row>}
            <Row>
                <Col xs={8} className={styles.packsCol}>

                    {companyPacks.packs?.map((plan: PackDto, key: number) => {
                        return (
                            <Row key={key} className={`${styles.box} ${!plan.active ? styles.boxExpired : undefined}`}>
                                <Col xs={1}>
                                    <img src={PlanIcon} className={styles.addIcon}></img>
                                </Col>
                                <Col xs={8} className={styles.planContainer}>
                                    <span className={styles.title}>{t('plans.plans_types.' + plan.type as any)}</span>
                                    <span>
                                        <DateFormat value={plan.dateStart} format={DATE_TIME_FORMAT_DEFAUT} />
                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                        <span data-tip={t('plans.number_vehicles_plan')}>{plan.limit} {t('plans.vehicles')}</span>
                                        <ReactTooltip />
                                    </span>
                                </Col>
                                <Col xs={3}>
                                    <div className={`${styles.planContainer} ${styles.textContainer}`}>
                                        {plan.type !== PackTypesEnum.FREE && plan.active && <Badge
                                            width={'fit-content'}
                                            className={styles.validMonthsMessage}
                                            backgroundColor={plan.validMonths > 2 ? ValidMonthsTypeColorEnum.VALID : (plan.validDays > 0) ? ValidMonthsTypeColorEnum.WARNING : ValidMonthsTypeColorEnum.DANGER}
                                        >
                                            {badgeText(plan)}
                                        </Badge>
                                        }

                                        {!plan.active && <Badge
                                            width={'fit-content'}
                                            className={styles.validMonthsMessage}
                                            backgroundColor={ValidMonthsTypeColorEnum.DANGER}
                                        >
                                            {t('plans.disabled')}
                                        </Badge>
                                        }
                                        {plan.type !== PackTypesEnum.FREE && (plan.active || (plan.validDays > 0)) && <span>{t('plans.valid_until')}: {<DateFormat value={plan.expire} />}</span>}
                                        {plan.type !== PackTypesEnum.FREE && (!plan.active && (plan.validDays <= 0)) && <span>{t('plans.expired_on_day')}: {<DateFormat value={plan.expire} />}</span>}
                                    </div>

                                </Col>
                            </Row>
                        )
                    })}

                    {
                        (!companyPacks || !companyPacks.packs.length) && <div className={styles.containerCards} style={{ justifyContent: 'center' }}>
                            {t('plans.without_plans')}
                        </div>
                    }

                </Col>
                <Col xs={4} className={styles.resumeCol}>
                    <div className={styles.resumeBox}>
                        <Row className={styles.resumeTitle}>
                            <Col xs={12} className={styles.colTitle}>
                                <span className={styles.labelTitle}>{t('plans.active_plans')}</span>
                            </Col>
                        </Row>
                        <Row className={styles.resumeRow}>
                            <Col xs={6}>
                                <span className={styles.label}> {t('plans.admins')}</span>
                            </Col>
                            <Col xs={6} className={styles.alignRight}>
                                <span className={styles.label} data-tip={!!companyPacks.packs.every(x => x.type == PackTypesEnum.FREE) ? t('plans.number_admins_available') : t('plans.number_admins_created')}>
                                    {companyPacks.adminsCreated}
                                    {!!companyPacks.packs.every(x => x.type == PackTypesEnum.FREE) && <span> / 1</span>}
                                </span>
                                <ReactTooltip />
                            </Col>
                        </Row>
                        <Row className={styles.resumeRow}>
                            <Col xs={6}>
                                <span className={styles.label}> {t('plans.vehicles')}</span>
                            </Col>
                            <Col xs={6} className={styles.alignRight}>
                                {
                                    (!companyPacks || !companyPacks.packs.length) &&
                                    <span className={styles.label} data-tip={t('plans.number_vehicles_created')}>{companyPacks.vehiclesCreated}</span>
                                }
                                {
                                    (companyPacks && companyPacks.packs.length > 0) &&
                                    <span className={styles.label} data-tip={t('plans.number_vehicles_created_and_actives')}>{companyPacks.vehiclesCreated} / {limit}</span>
                                }
                                <ReactTooltip />
                            </Col>
                        </Row>
                        <Row className={styles.resumeRow}>
                            <Col xs={6}>
                                <span className={styles.label}> {t('plans.conductors')}</span>
                            </Col>
                            <Col xs={6} className={styles.alignRight}>
                                <span className={styles.label} data-tip={!!companyPacks.packs.every(x => x.type == PackTypesEnum.FREE) ? t('plans.number_drivers_available') : t('plans.number_drivers_created')}>
                                    {companyPacks.driversCreated}
                                    {!!companyPacks.packs.every(x => x.type == PackTypesEnum.FREE) && <span> / 1</span>}
                                </span>
                                <ReactTooltip />
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col xs={12} className={styles.alignCenter}>
                            <Button
                                preset={'secondary-success'}
                                type="button"
                                text={t('plans.plans_and_prices')}
                                onClick={() => {
                                    const profile = Storage.getObject(STORAGE.AUTH_USER_PROFILE);

                                    const url = profile && profile.languageCode === 'fr' ? 'fr/tarifs' :
                                        profile && profile.languageCode === 'pt' ? 'pt/planos' :
                                            'eu/plans'
                                    window.open(
                                        `https://fleetmax.${url}`
                                    )
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

        </Box>

    );
}

export default memo(PlansScreen);