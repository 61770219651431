import { UserProfile } from 'api/account/models/UserProfile';
import Box from 'common/components/box/Box';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import ConsumesChartBox from '../consumesChart/ConsumesChartBox';
import ExpensesChart from '../expensesChart/ExpensesChart';
import MonthlyCostChartBox from '../monthlyCostChart/MonthlyCostChartBox';
import NextMaintenances from '../nextMaintenances/NextMaintenances';
import styles from './ChartsBoxes.module.scss';
import MonthlyRouteChartBox from '../monthlyRouteChart/MonthlyRouteChartBox';
import { CountersDashboardDto } from 'api/dashboard/dtos/CountersDashboardDto';
import { NextMaintenancesDashboardDto } from 'api/dashboard/dtos/NextMaintenancesDashboardDto';
import { MonthlyCostDashboardDto } from 'api/dashboard/dtos/MonthlyCostDashboardDto';
import { ConsumesDashboardDto } from 'api/dashboard/dtos/ConsumesDashboardDto';
import { MonthlyRoutesDashboardDto } from 'api/dashboard/dtos/MonthlyRouteDashboardDto';
import { ExpensesDashboardDto } from 'api/dashboard/dtos/ExpensesDashboardDto';
import UsersService from 'api/users/UsersService';

type Props = {
    counters: CountersDashboardDto | null;
    expenses: ExpensesDashboardDto | null;
    consumes: ConsumesDashboardDto | null;
    nextMaintenances: NextMaintenancesDashboardDto | null;
    monthlyCost: MonthlyCostDashboardDto | null;
    monthlyRoute: MonthlyRoutesDashboardDto | null;
    isLoadingCounters?: boolean;
    isLoadingExpenses?: boolean;
    isLoadingConsumes?: boolean;
    isLoadingNextMaintenances?: boolean;
    isLoadingMonthlyCost?: boolean;
    isLoadingMonthlyRoute?: boolean;
    showMonthlyRoute?: boolean;
}

const ChartsBoxes = ({ counters, expenses, consumes, nextMaintenances, monthlyCost, monthlyRoute, isLoadingExpenses, isLoadingConsumes, isLoadingNextMaintenances, isLoadingMonthlyCost, isLoadingMonthlyRoute, showMonthlyRoute }: Props) => {
    const { t } = useTranslation();
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasGpsReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_GPS_READ']);
    return (
        <Grid fluid>
            <Row>
                <Col xl={6} md={12} className={styles.chartCol}>
                    <Box className={styles.chartContainer} bodyClassName={styles.chartBody} title={t('dashboard.expenses')}>
                        <ExpensesChart data={expenses} isLoading={isLoadingExpenses} />
                    </Box>
                </Col>
                {consumes && <Col xl={6} md={12} className={styles.chartCol}>
                    <ConsumesChartBox
                        className={styles.chartContainer}
                        bodyClassName={styles.chartBody}
                        title={t('dashboard.consume')}
                        data={consumes}
                        isLoading={isLoadingConsumes}
                    />
                </Col>}
                <Col xl={6} md={12} className={styles.chartCol}>
                    <Box className={styles.chartContainer} bodyClassName={styles.chartBody} title={t('dashboard.next_maintenances')}>
                        <NextMaintenances data={nextMaintenances} isLoading={isLoadingNextMaintenances} />
                    </Box>
                </Col>
                <Col xl={6} md={12} className={styles.chartCol}>
                    <MonthlyCostChartBox
                        className={styles.chartContainer}
                        bodyClassName={styles.chartBody}
                        title={t('dashboard.monthly_cost', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) })}
                        data={monthlyCost}
                        isLoading={isLoadingMonthlyCost}
                    />
                </Col>
                <Col xl={6} md={12} className={styles.chartCol}>
                    {showMonthlyRoute  && hasGpsReadPolicy && 
                        <MonthlyRouteChartBox
                            className={styles.chartContainer}
                            bodyClassName={styles.chartBody}
                            title={t('dashboard.monthly_routes')}
                            subTitle={t('dashboard.routes.FROM_TRACKING')}
                            data={monthlyRoute}
                            isLoading={isLoadingMonthlyRoute}
                        />
                    }
                </Col>
            </Row>
        </Grid>
    );
}

export default ChartsBoxes;