import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Colors from 'styles/export/colors.module.scss';
import styles from './ExpensesChart.module.scss';
import { VictoryLabel, VictoryLegend, VictoryPie } from 'victory';
import Utils from 'common/services/Utils';
import { ExpensesDashboardDto } from 'api/dashboard/dtos/ExpensesDashboardDto';
import { DashboardExpenseTypeEnum } from 'api/dashboard/enums/DashboardExpenseTypeEnum';
import useElementSize from 'common/hooks/useElementSize';
import ChartEmptyLabel from 'common/components/charts/ChartEmptyLabel';
import LoadingSpinner from 'common/components/loading/LoadingSpinner';
import { DASHBOARD_COLORS } from 'Config';

const LegendLabel = (props: any) => {
	const textSize = props.datum.textSize;
	const height = textSize.height * 2 + 5;

	return (
		<g style={{ cursor: 'pointer', userSelect: 'none' }}>
			<rect
				x={props.x}
				y={props.y - height / 2 - 5}
				width={textSize.width + 10}
				height={height}
				fill="transparent"
				onClick={() => {
					if (props.onClick) {
						props.onClick(props);
					}
				}}
			/>
			<VictoryLabel
				{...props}
				events={{
					onClick: () => {
						if (props.onClick) {
							props.onClick(props);
						}
					}
				}}
			/>
			<VictoryLabel
				{...props}
				y={props.y + 18}
				text={Utils.formatCurrency(props.datum.value || 0)}
				style={{ fontWeight: 'bold', fontSize: 14, fontFamily: 'Roboto' }}
				events={{
					onClick: () => {
						if (props.onClick) {
							props.onClick(props);
						}
					}
				}}
			/>
		</g>
	);
};

type Props = {
	data: ExpensesDashboardDto | null;
	isLoading?: boolean;
};

const ExpensesChart = ({ data, isLoading }: Props) => {
	const { t } = useTranslation();
	const [hiddenData, setHiddenData] = useState<DashboardExpenseTypeEnum[]>([]);
	const [containerRef, { width, height }] = useElementSize();

	const viewWidth = width * 1.6;
	const viewHeight = height;

	const chartWidth = viewWidth;
	const chartHeight = viewHeight;


	const viewBoxWidth = width * 1.1;
	const viewBoxHeight = height * 1.1;

	if (!data && !isLoading) {
		return null;
	}

	if (!data?.items || data?.items.length === 0) {
		return <div className={styles.noEnoughInfoText}>{t('dashboard.no_enough_information')}</div>;
	}

	const itemsData = data?.items.filter(x => !hiddenData.find(h => h === x.type));
	const categories = itemsData.map(x => x.type);
	const colorScale = itemsData.map(x => x.isSystem ? DASHBOARD_COLORS.expenses[(x.type as DashboardExpenseTypeEnum)] : x.color!);
	const legendData = Utils.sortAlphabeticallyByKey(data?.items.map(x => ({
		name: x.isSystem ? t(`dashboard.expenses_types.${x.type}` as any) : x.type,
		type: x.type,
		value: x.value,
		symbol: {
			fill: !hiddenData.find(a => a === x.type) ? (x.isSystem ? DASHBOARD_COLORS.expenses[(x.type as DashboardExpenseTypeEnum)] : x.color) : Colors.gray17,
		},
		clickable: true,
	})), 'name');

	const total = itemsData.map(x => x.value).reduce((a, b) => a + b, 0);

	const onClickLegend = (propsData: any) => {
		const type = propsData.datum.type;

		if (hiddenData.find(x => x === type)) {
			setHiddenData(hiddenData.filter(x => x !== type));
		} else {
			setHiddenData([...hiddenData, type]);
		}
	};

	return (
		<div className={styles.chartContainer} ref={containerRef}>
			{isLoading ? <div className={styles.centerAlign}> <LoadingSpinner /></div> : <svg width={width} height={height} viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`} >
				<VictoryPie
					standalone={false}
					colorScale={colorScale}
					categories={categories}
					innerRadius={95}
					radius={115}
					// labelRadius={50}
					width={chartWidth}
					height={chartHeight}
					data={itemsData}
					x="type"
					y="value"
					labelComponent={<ChartEmptyLabel />}

				/>
				<VictoryLegend
					standalone={false}
					// y={20}
					gutter={40}
					data={legendData}
					orientation={'vertical'}
					itemsPerRow={5}
					labelComponent={<LegendLabel onClick={onClickLegend} />}
					style={{ labels: { fontSize: 15, fontFamily: 'Roboto' }, data: { width: '100%', display: 'flex' } }}
					rowGutter={15}

				/>
				<VictoryLabel
					textAnchor="middle"
					verticalAnchor="middle"
					x={chartWidth / 2}
					y={chartHeight / 2 + 12}
					text={[Utils.formatCurrency(total || 0), t('dashboard.expense_total_label')]}
					style={[{ fontSize: 20, fontWeight: 'bold', fontFamily: 'Roboto' }, { fontSize: 15 }]}

				/>
			</svg>}
		</div>
	);
};

export default ExpensesChart;
