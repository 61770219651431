import VehicleModelList from './components/vehicleModelList/VehicleModelList';
import VehicleModelDetails from './components/vehicleModelDetails/VehicleModelDetails';
import { useParams } from 'react-router-dom';
import styles from './VehicleModelTab.module.scss';

const VehicleModelTab = () => {
    const { type } = useParams<{ type: string, itemId: string }>();

    return <div className={styles.container}>
    {
        (type === 'list' ? <VehicleModelList /> : <VehicleModelDetails />)
    }
    </div>;
}

export default VehicleModelTab;