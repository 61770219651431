import Box from '../../../../common/components/box/Box';
import ScreenTitle from '../../../../common/components/screenTitle/ScreenTitle';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './NotificationsTab.module.scss';
import { AlarmTriggerDto } from 'api/alarms/models/AlarmTriggersDto';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import DateFormat from 'common/components/dateFormat/dateFormat';
import { DATE_TIME_FORMAT_DEFAULT, DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import { AlarmsSearchCriteria } from 'api/alarms/models/AlarmsSearchCriteria';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import Loading from 'common/services/Loading';
import AlarmsService from 'api/alarms/AlarmsService';
import Logger from 'common/services/Logger';
import { useToasts } from 'react-toast-notifications';
import NoImage from 'assets/img/asset-default-image.png';
import { FaExternalLinkAlt, FaMapMarkerAlt } from 'react-icons/fa';

interface Filters {
    page?: number;
}

export type Props = {
    alarmId: string | undefined
} & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const NotificationsTab = ({ alarmId }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const [filters, setFilters] = useState<Filters>({});
    const [triggers, setTriggers] = useState<AlarmTriggerDto[]>([])
    const [totalItems, setTotalItems] = useState<number>(0);
    const [criteria, setCriteria] = useState<AlarmsSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderColumn: 'ti.date',
        orderBy: 'desc',
        alarmId: alarmId
    });

    const imageCacheKey = useRef(new Date().getTime());

    useEffect(() => {
        loadFilters()
    }, [criteria])

    const getData = async () => {
        try {
            Loading.show();

            const [alarmesTriggers] = await Promise.all([
                AlarmsService.getAlarmTriggersList(criteria),
            ]);

            setTriggers(alarmesTriggers.items ?? []);
            setTotalItems(alarmesTriggers.totalItems)
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.ALARMS, `Couldn't get alarms triggers list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }
    const loadFilters = () => {
        const cachedFilters = localStorage.getItem('ALARMSTRIGGERSFILTER');
        if (cachedFilters != null) { onChangeFilters(JSON.parse(cachedFilters), true) }
        else { getData() }
    }

    const onChangeFilters = (f: Filters, isOnLoad?: boolean) => {
        if (isOnLoad && f.page) {
            criteria.page = f.page
        } else {
            criteria.page = 1;
            f.page = 1;
        }
        saveFiltersOnCache(f);
        getData();
    }

    const saveFiltersOnCache = (f: Filters) => {
        setFilters(f);
        localStorage.setItem('ALARMSTRIGGERSFILTER', JSON.stringify(f));
    }

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const tableColumns: ListingTableColumn<AlarmTriggerDto>[] = useMemo(() => {
        const cols: ListingTableColumn<AlarmTriggerDto>[] = [
            {
                name: t('alarms.notifications_tab.table.vehicle'),
                onSearch: onTableFilter,
                searchField: 'v.license_plate',
                renderCell: row => renderVehicleCell(row),
                width: 'fill'
            },
            {
                name: t('alarms.notifications_tab.table.date'),
                renderCell: row => <DateFormat value={row.triggerDate} format={DATE_TIME_FORMAT_DEFAULT} />,
                onSearch: onTableFilter,
                searchField: 'ti.date',
                width: 'fill'
            },
            {
                name: t('alarms.notifications_tab.table.address'),
                renderCell: row => renderAddressCell(row),
                hideOn: ['sm', 'md', 'lg'],
                width: 'fill'
            }
        ];
        return cols;
    }, [onTableFilter]);

    const renderVehicleCell = (item: AlarmTriggerDto) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div>
                    <div className={styles.contentImage} style={{ width: '5rem', height: '3rem' }}>
                        <div className={styles.img} style={{ width: '5rem', height: '3rem', backgroundImage: item?.vehiclePhotoUrl ? 'url(' + item?.vehiclePhotoUrl + '?_=' + imageCacheKey.current + ')' : 'url(' + NoImage + ')' }} />
                    </div>
                </div>
                <div>
                    <div className={styles.licensePlate}>{item.vehicleRegistrationNumber}</div>
                    <div>
                        {item.vehicleBrand} | {item.vehicleModel}
                    </div>
                </div>
            </div>
        )
    }

    const renderAddressCell = (row: AlarmTriggerDto) => {
        return (
            <span className={styles.labelAddress}>
                <FaMapMarkerAlt className={styles.pin} />
                <a href={`https://www.google.com/maps/search/?api=1&query=${row.latitude},${row.longitude}`}>{t('alarms.notifications_tab.table.open_location')}
                    <span className={styles.linkIcon}>
                        <FaExternalLinkAlt/>
                    </span>
                    <span className={styles.small}>({`${row.latitude},${row.longitude}`})</span>
                </a>
            </span>
        )
    }


    const onPageChange = (page: number) => {
        criteria.page = page;
        getData();
    }

    return (
        <ScreenTitle title={t('alarms.notifications_tab.title')}>

            <Box className={styles.box}>
                <ListingTable
                    columns={tableColumns}
                    rows={triggers}
                    allowHover={true}
                    initialSearch={{ colField: 'ti.date', isOrderAsc: false }}
                />
                <PaginationWithInfo
                    itemName={t('alarms.list.alarms')}
                    currentPage={criteria.page}
                    pageItems={triggers.length}
                    totalItems={totalItems}
                    onChange={onPageChange}
                />

            </Box>
        </ScreenTitle >

    );
}

export default NotificationsTab;
