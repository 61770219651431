import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import React, { useEffect, useState } from 'react';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';
import LanguagesTab from 'common/components/languagesTabs/LanguagesTab';
import { LanguageDto } from 'api/languages/models/LanguageDto';
import { NotificationDto, NotificationListDto } from 'api/notifications/models/NotificationDto';
import LanguagesService from 'api/languages/LanguagesService';
import { useForm } from 'react-hook-form';
import ContentManagement from 'common/components/contentManagement/ContentManagement';
import FormItem from 'common/components/formItem/FormItem';
import InputController from 'common/components/input/InputController';
import SummernoteController, { ContextMenuOption } from 'common/components/summernoteController/SummernoteController';
import Label from 'common/components/label/Label';
import InputError from 'common/components/inputError/InputError';
import NotificationsService from 'api/notifications/NotificationsService';
import { useHistory, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import Button from 'common/components/button/Button';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { UserProfile } from 'api/account/models/UserProfile';
import { Reducers } from 'store/types';
import styles from './NotificationsDetails.module.scss'
import InputResizableController, { ContextMenuAreaOption } from 'common/components/input/InputResizableController';
import { MSG_TYPE_CONTRACTS, MSG_TYPE_ANNOTATIONS, MSG_TYPE_CHARGES, MSG_TYPE_CHARGES_SITUATION, MSG_TYPE_CHARGES_STATUS, MSG_TYPE_MAINTENANCES, MSG_TYPE_RESERVATIONS, MSG_TYPE_SCHEDULED_MAINTENANCES, MSG_TYPE_VERIFICATIONS } from 'api/notifications/enums/NotificationMessageType';

function isType(itemId: string, enumType: any) {
    return Boolean(Object.keys(enumType).find(x => enumType[x] === itemId));
}

type Props = {
};

const NotificationsDetails: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const form = useForm<NotificationListDto>({ shouldUnregister: false });
    const [model, setModel] = useState<NotificationListDto>({ list: [] });
    const [selectedLanguageIndex, setSelectedLanguageIndex] = useState(0);
    const [languages, setLanguages] = useState<LanguageDto[]>([]);
    const { type, itemId } = useParams<{ type: string, itemId: string }>();
    const isDetails = type === 'details';
    const [isEmail, setIsEmail] = useState<boolean>(true);
    const history = useHistory();
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasNotificationsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_NOTIFICATIONS_WRITE']);
    const [contextOptions, setContextOptions] = useState<ContextMenuOption[]>([]);
    const [contextAreaOptions, setContextAreaOptions] = useState<ContextMenuAreaOption[]>([]);

    const addElement = (element: HTMLInputElement | HTMLTextAreaElement | ReactQuill | undefined, pattern: string) => {
        let value = '';
        if (!element) return value;

        if (!(element as ReactQuill).getEditorSelection) {
            const elem = element as HTMLInputElement;
            const start = elem.selectionStart ?? 0;
            const end = elem.selectionEnd ?? 0;
            elem.value = `${elem.value.substring(0, start)} ${pattern} ${elem.value.substring(end)}`;
            value = elem.value;
        } else {
            const elemQuill = element as ReactQuill;
            const elemQuillEditor = elemQuill.getEditor();
            const range = elemQuill.getEditorSelection();
            const position = range ? range.index : 0;
            elemQuillEditor.insertText(position, ` ${pattern} `);
        }
        return value;
    }

    //#region declare @
    //*common*
    const addLinkQuill = (element: HTMLInputElement | ReactQuill | undefined) => addElement(element, '@url');
    const addVehicleRegistrationNumberQuill = (element: HTMLInputElement | ReactQuill | undefined) => addElement(element, '@registrationnumber');
    const addVehicleRegistrationNumberArea = (element: HTMLTextAreaElement | undefined) => addElement(element, '@registrationnumber');
    const addVehicleBrandQuill = (element: HTMLInputElement | ReactQuill | undefined) => addElement(element, '@brand');
    const addVehicleBrandArea = (element: HTMLTextAreaElement | undefined) => addElement(element, '@brand');
    const addVehicleModelQuill = (element: HTMLInputElement | ReactQuill | undefined) => addElement(element, '@model');
    const addVehicleModelArea = (element: HTMLTextAreaElement | undefined) => addElement(element, '@model');
    //*contracts*
    const addContractNumberQuill = (element: HTMLInputElement | ReactQuill | undefined) => addElement(element, '@contractnumber');
    const addContractNumberArea = (element: HTMLTextAreaElement | undefined) => addElement(element, '@contractnumber');
    const addContractTypeQuill = (element: HTMLInputElement | ReactQuill | undefined) => addElement(element, '@contrattype');
    const addContractTypeArea = (element: HTMLTextAreaElement | undefined) => addElement(element, '@contrattype');
    const addContractStartDateQuill = (element: HTMLInputElement | ReactQuill | undefined) => addElement(element, '@contractstartdate');
    const addContractStartDateArea = (element: HTMLTextAreaElement | undefined) => addElement(element, '@contractstartdate');
    const addContractEndDateQuill = (element: HTMLInputElement | ReactQuill | undefined) => addElement(element, '@contractenddate');
    const addContractEndDateArea = (element: HTMLTextAreaElement | undefined) => addElement(element, '@contractenddate');
    const addContractContractualKmsQuill = (element: HTMLInputElement | ReactQuill | undefined) => addElement(element, '@contractualkms');
    const addContractContractualKmsArea = (element: HTMLTextAreaElement | undefined) => addElement(element, '@contractualkms');
    //*charges*
    const addChargeValueQuill = (element: HTMLInputElement | ReactQuill | undefined) => addElement(element, '@chargevalue');
    const addChargeValueArea = (element: HTMLTextAreaElement | undefined) => addElement(element, '@chargevalue');
    const addChargeTypeQuill = (element: HTMLInputElement | ReactQuill | undefined) => addElement(element, '@chargetype');
    const addChargeTypeArea = (element: HTMLTextAreaElement | undefined) => addElement(element, '@chargetype');
    const addHaveRefundQuill = (element: HTMLInputElement | ReactQuill | undefined) => addElement(element, '@hasrefund');
    const addHaveRefundArea = (element: HTMLTextAreaElement | undefined) => addElement(element, '@hasrefund');
    //*charges situation*
    const addChargeSituationQuill = (element: HTMLInputElement | ReactQuill | undefined) => addElement(element, '@chargesituation');
    const addChargeSituationArea = (element: HTMLTextAreaElement | undefined) => addElement(element, '@chargesituation');
    //*charges status*
    const addChargeStatusQuill = (element: HTMLInputElement | ReactQuill | undefined) => addElement(element, '@chargestatus');
    const addChargeStatusArea = (element: HTMLTextAreaElement | undefined) => addElement(element, '@chargestatus');
    //*verifications*
    const addVerificationDateQuill = (element: HTMLInputElement | ReactQuill | undefined) => addElement(element, '@verificationdate');
    const addVerificationDateArea = (element: HTMLTextAreaElement | undefined) => addElement(element, '@verificationdate');
    const addVerificationHourQuill = (element: HTMLInputElement | ReactQuill | undefined) => addElement(element, '@verificationhour');
    const addVerificationHourArea = (element: HTMLTextAreaElement | undefined) => addElement(element, '@verificationhour');
    const addVerificationResponsableQuill = (element: HTMLInputElement | ReactQuill | undefined) => addElement(element, '@verificationresponsable');
    const addVerificationResponsableArea = (element: HTMLTextAreaElement | undefined) => addElement(element, '@verificationresponsable');
    const addVerificationTypeQuill = (element: HTMLInputElement | ReactQuill | undefined) => addElement(element, '@verificationtype');
    const addVerificationTypeArea = (element: HTMLTextAreaElement | undefined) => addElement(element, '@verificationtype');
    //*annotations*
    const addAnnotationInterventionTypeQuill = (element: HTMLInputElement | ReactQuill | undefined) => addElement(element, '@annotationtypeintervention');
    const addAnnotationInterventionTypeArea = (element: HTMLTextAreaElement | undefined) => addElement(element, '@annotationtypeintervention');
    const addAnnotationQuill = (element: HTMLInputElement | ReactQuill | undefined) => addElement(element, '@annotation');
    const addAnnotationArea = (element: HTMLTextAreaElement | undefined) => addElement(element, '@annotation');
    //*reservations*
    const addReservationDriverQuill = (element: HTMLInputElement | ReactQuill | undefined) => addElement(element, '@reservationdriver');
    const addReservationDriverArea = (element: HTMLTextAreaElement | undefined) => addElement(element, '@reservationdriver');
    const addReservationStartEndDateQuill = (element: HTMLInputElement | ReactQuill | undefined) => addElement(element, '@reservationstartenddate');
    const addReservationStartEndDateArea = (element: HTMLTextAreaElement | undefined) => addElement(element, '@reservationstartenddate');
    //*maintenance*
    const addMaintenancePredictedKmQuill = (element: HTMLInputElement | ReactQuill | undefined) => addElement(element, '@maintenancepredictedkm');
    const addMaintenancePredictedKmArea = (element: HTMLTextAreaElement | undefined) => addElement(element, '@maintenancepredictedkm');
    const addMaintenancePredictedDateQuill = (element: HTMLInputElement | ReactQuill | undefined) => addElement(element, '@maintenancepredicteddate');
    const addMaintenancePredictedDateArea = (element: HTMLTextAreaElement | undefined) => addElement(element, '@maintenancepredicteddate');
    //*scheduled maintenance*
    const addScheduledMaintenanceDateHourQuill = (element: HTMLInputElement | ReactQuill | undefined) => addElement(element, '@scheduledmaintenancedatehour');
    const addScheduledMaintenanceDateHourArea = (element: HTMLTextAreaElement | undefined) => addElement(element, '@scheduledmaintenancedatehour');
    //#endregion

    //#region contextOption
    //*common*
    const contextOptionAddLink: ContextMenuOption = { onClick: addLinkQuill, name: 'notifications.contextHelper.add_link' };
    const contextOptionAddVehicleRegistrationNumber: ContextMenuOption = { onClick: addVehicleRegistrationNumberQuill, name: 'notifications.contextHelper.add_registration_number' };
    const contextOptionAddVehicleBrand: ContextMenuOption = { onClick: addVehicleBrandQuill, name: 'notifications.contextHelper.add_vehicle_brand' };
    const contextOptionAddVehicleModel: ContextMenuOption = { onClick: addVehicleModelQuill, name: 'notifications.contextHelper.add_vehicle_model' };
    //*contracts*
    const contextOptionAddContractNumber: ContextMenuOption = { onClick: addContractNumberQuill, name: 'notifications.contextHelper.add_contract_number' };
    const contextOptionAddContracType: ContextMenuOption = { onClick: addContractTypeQuill, name: 'notifications.contextHelper.add_contract_type' };
    const contextOptionAddContracStartDate: ContextMenuOption = { onClick: addContractStartDateQuill, name: 'notifications.contextHelper.add_contract_end_date' };
    const contextOptionAddContracEndDate: ContextMenuOption = { onClick: addContractEndDateQuill, name: 'notifications.contextHelper.add_contract_start_date' };
    const contextOptionAddContractContractualKms: ContextMenuOption = { onClick: addContractContractualKmsQuill, name: 'notifications.contextHelper.add_contract_contractual_kms' };
    //*charges*
    const contextOptionAddChargeValue: ContextMenuOption = { onClick: addChargeValueQuill, name: 'notifications.contextHelper.add_charge_value' };
    const contextOptionAddChargeType: ContextMenuOption = { onClick: addChargeTypeQuill, name: 'notifications.contextHelper.add_charge_type' };
    const contextOptionAddHaveRefund: ContextMenuOption = { onClick: addHaveRefundQuill, name: 'notifications.contextHelper.add_charge_have_refund' };
    //*charges situation*
    const contextOptionAddChargeSituation: ContextMenuOption = { onClick: addChargeSituationQuill, name: 'notifications.contextHelper.add_charge_situation' };
    //*charges status*
    const contextOptionAddChargeStatus: ContextMenuOption = { onClick: addChargeStatusQuill, name: 'notifications.contextHelper.add_charge_status' };
    //*verifications*
    const contextOptionAddVerificationDate: ContextMenuOption = { onClick: addVerificationDateQuill, name: 'notifications.contextHelper.add_verification_date' };
    const contextOptionAddVerificationHour: ContextMenuOption = { onClick: addVerificationHourQuill, name: 'notifications.contextHelper.add_verification_hour' };
    const contextOptionAddVerificationResponsable: ContextMenuOption = { onClick: addVerificationResponsableQuill, name: 'notifications.contextHelper.add_verification_responsable' };
    const contextOptionAddVerificationType: ContextMenuOption = { onClick: addVerificationTypeQuill, name: 'notifications.contextHelper.add_verification_type' };
    //*annotations*
    const contextOptionAddAnnotationInterventionType: ContextMenuOption = { onClick: addAnnotationInterventionTypeQuill, name: 'notifications.contextHelper.add_annotation_intervention_type' };
    const contextOptionAddAnnotation: ContextMenuOption = { onClick: addAnnotationQuill, name: 'notifications.contextHelper.add_annotation_description' };
    //*reservations*
    const contextOptionAddReservationDriver: ContextMenuOption = { onClick: addReservationDriverQuill, name: 'notifications.contextHelper.add_reservation_driver' };
    const contextOptionAddReservationStartEndDate: ContextMenuOption = { onClick: addReservationStartEndDateQuill, name: 'notifications.contextHelper.add_reservation_start_end_date' };
    //*maintenance*
    const contextOptionAddMaintenancePredictedKm: ContextMenuOption = { onClick: addMaintenancePredictedKmQuill, name: 'notifications.contextHelper.add_maintenance_predicted_km' };
    const contextOptionAddMaintenancePredictedDate: ContextMenuOption = { onClick: addMaintenancePredictedDateQuill, name: 'notifications.contextHelper.add_maintenance_predicted_date' };
    //*scheduled maintenance*
    const contextOptionAddScheduledMaintenanceDateHour: ContextMenuOption = { onClick: addScheduledMaintenanceDateHourQuill, name: 'notifications.contextHelper.add_scheduled_maintenance_date_hour' };
    //#endregion

    //#region contextOptionArea
    //*common*
    const contextOptionAreaAddVehicleRegistrationNumber: ContextMenuAreaOption = { onClick: addVehicleRegistrationNumberArea, name: 'notifications.contextHelper.add_registration_number' };
    const contextOptionAreaAddVehicleBrand: ContextMenuAreaOption = { onClick: addVehicleBrandArea, name: 'notifications.contextHelper.add_vehicle_brand' };
    const contextOptionAreaAddVehicleModel: ContextMenuAreaOption = { onClick: addVehicleModelArea, name: 'notifications.contextHelper.add_vehicle_model' };
    //*contracts*
    const contextOptionAreaAddContractNumber: ContextMenuAreaOption = { onClick: addContractNumberArea, name: 'notifications.contextHelper.add_contract_number' };
    const contextOptionAreaAddContracType: ContextMenuAreaOption = { onClick: addContractTypeArea, name: 'notifications.contextHelper.add_contract_type' };
    const contextOptionAreaAddContracStartDate: ContextMenuAreaOption = { onClick: addContractStartDateArea, name: 'notifications.contextHelper.add_contract_start_date' };
    const contextOptionAreaAddContracEndDate: ContextMenuAreaOption = { onClick: addContractEndDateArea, name: 'notifications.contextHelper.add_contract_end_date' };
    const contextOptionAreaAddContractContractualKms: ContextMenuAreaOption = { onClick: addContractContractualKmsArea, name: 'notifications.contextHelper.add_contract_contractual_kms' };
    //*charges*
    const contextOptionAreaAddChargeValue: ContextMenuAreaOption = { onClick: addChargeValueArea, name: 'notifications.contextHelper.add_charge_value' };
    const contextOptionAreaAddChargeType: ContextMenuAreaOption = { onClick: addChargeTypeArea, name: 'notifications.contextHelper.add_charge_type' };
    const contextOptionAreaAddHaveRefund: ContextMenuAreaOption = { onClick: addHaveRefundArea, name: 'notifications.contextHelper.add_charge_have_refund' };
    //*charges situation*
    const contextOptionAreaAddChargeSituation: ContextMenuAreaOption = { onClick: addChargeSituationArea, name: 'notifications.contextHelper.add_charge_situation' };
    //*charges status*
    const contextOptionAreaAddChargeStatus: ContextMenuAreaOption = { onClick: addChargeStatusArea, name: 'notifications.contextHelper.add_charge_status' };
    //*verifications*
    const contextOptionAreaAddVerificationDate: ContextMenuAreaOption = { onClick: addVerificationDateArea, name: 'notifications.contextHelper.add_verification_date' };
    const contextOptionAreaAddVerificationHour: ContextMenuAreaOption = { onClick: addVerificationHourArea, name: 'notifications.contextHelper.add_verification_hour' };
    const contextOptionAreaAddVerificationResponsable: ContextMenuAreaOption = { onClick: addVerificationResponsableArea, name: 'notifications.contextHelper.add_verification_responsable' };
    const contextOptionAreaAddVerificationType: ContextMenuAreaOption = { onClick: addVerificationTypeArea, name: 'notifications.contextHelper.add_verification_type' };
    //*annotations*
    const contextOptionAreaAddAnnotationInterventionType: ContextMenuAreaOption = { onClick: addAnnotationInterventionTypeArea, name: 'notifications.contextHelper.add_annotation_intervention_type' };
    const contextOptionAreaAddAnnotation: ContextMenuAreaOption = { onClick: addAnnotationArea, name: 'notifications.contextHelper.add_annotation_description' };
    //*reservations*
    const contextOptionAreaAddReservationDriver: ContextMenuAreaOption = { onClick: addReservationDriverArea, name: 'notifications.contextHelper.add_reservation_driver' };
    const contextOptionAreaAddReservationStartEndDate: ContextMenuAreaOption = { onClick: addReservationStartEndDateArea, name: 'notifications.contextHelper.add_reservation_start_end_date' };
    //*maintenance*
    const contextOptionAreaAddMaintenancePredictedKm: ContextMenuAreaOption = { onClick: addMaintenancePredictedKmArea, name: 'notifications.contextHelper.add_maintenance_predicted_km' };
    const contextOptionAreaAddMaintenancePredictedDate: ContextMenuAreaOption = { onClick: addMaintenancePredictedDateArea, name: 'notifications.contextHelper.add_maintenance_predicted_date' };
    //*scheduled maintenance*
    const contextOptionAreaAddScheduledMaintenanceDateHour: ContextMenuAreaOption = { onClick: addScheduledMaintenanceDateHourArea, name: 'notifications.contextHelper.add_scheduled_maintenance_date_hour' };
    //#endregion

    const contextOptionsDefault: ContextMenuOption[] = [contextOptionAddLink,
        contextOptionAddVehicleRegistrationNumber,
        contextOptionAddVehicleBrand,
        contextOptionAddVehicleModel
    ];

    const generateContextOptions = () => {
        if (isType(itemId, MSG_TYPE_CONTRACTS)) {
            setContextOptions(contextOptionsDefault.concat(contextOptionAddContractNumber,
                contextOptionAddContracType,
                contextOptionAddContracStartDate,
                contextOptionAddContracEndDate,
                contextOptionAddContractContractualKms))
        }

        if (isType(itemId, MSG_TYPE_CHARGES)) {
            setContextOptions(contextOptionsDefault.concat(contextOptionAddChargeValue,
                contextOptionAddHaveRefund,
                contextOptionAddChargeType))
        }
        if (isType(itemId, MSG_TYPE_CHARGES_SITUATION)) {
            setContextOptions(contextOptionsDefault.concat(contextOptionAddChargeValue,
                contextOptionAddChargeType,
                contextOptionAddChargeSituation))
        }
        if (isType(itemId, MSG_TYPE_CHARGES_STATUS)) {
            setContextOptions(contextOptionsDefault.concat(contextOptionAddChargeValue,
                contextOptionAddChargeType,
                contextOptionAddChargeStatus))
        }

        if (isType(itemId, MSG_TYPE_VERIFICATIONS)) {
            setContextOptions(contextOptionsDefault.concat(
                contextOptionAddVerificationDate,
                contextOptionAddVerificationHour,
                contextOptionAddVerificationResponsable,
                contextOptionAddVerificationType))
        }

        if (isType(itemId, MSG_TYPE_ANNOTATIONS)) {
            setContextOptions(contextOptionsDefault.concat(contextOptionAddAnnotationInterventionType, contextOptionAddAnnotation))
        }

        if (isType(itemId, MSG_TYPE_RESERVATIONS)) {
            setContextOptions(contextOptionsDefault.concat(contextOptionAddReservationDriver, contextOptionAddReservationStartEndDate))
        }

        if (isType(itemId, MSG_TYPE_MAINTENANCES)) {
            setContextOptions(contextOptionsDefault.concat(contextOptionAddMaintenancePredictedKm))
        }
        if (isType(itemId, MSG_TYPE_SCHEDULED_MAINTENANCES)) {
            setContextOptions(contextOptionsDefault.concat(contextOptionAddMaintenancePredictedKm,
                contextOptionAddMaintenancePredictedDate))
        }
    }

    const contextAreaOptionsDefault: ContextMenuAreaOption[] = [
        contextOptionAreaAddVehicleRegistrationNumber,
        contextOptionAreaAddVehicleBrand,
        contextOptionAreaAddVehicleModel];

    const generateContextAreaOptions = () => {
        if (isType(itemId, MSG_TYPE_CONTRACTS)) {
            setContextAreaOptions(contextAreaOptionsDefault.concat(contextOptionAreaAddContractNumber,
                contextOptionAreaAddContracType,
                contextOptionAreaAddContracStartDate,
                contextOptionAreaAddContracEndDate,
                contextOptionAreaAddContractContractualKms))
        }

        if (isType(itemId, MSG_TYPE_CHARGES)) {
            setContextAreaOptions(contextAreaOptionsDefault.concat(contextOptionAreaAddChargeValue,
                contextOptionAreaAddHaveRefund,
                contextOptionAreaAddChargeType))
        }
        if (isType(itemId, MSG_TYPE_CHARGES_SITUATION)) {
            setContextAreaOptions(contextAreaOptionsDefault.concat(contextOptionAreaAddChargeValue,
                contextOptionAreaAddChargeType,
                contextOptionAreaAddChargeSituation))
        }
        if (isType(itemId, MSG_TYPE_CHARGES_STATUS)) {
            setContextAreaOptions(contextAreaOptionsDefault.concat(contextOptionAreaAddChargeValue,
                contextOptionAreaAddChargeType,
                contextOptionAreaAddChargeStatus))
        }

        if (isType(itemId, MSG_TYPE_VERIFICATIONS)) {
            setContextAreaOptions(contextAreaOptionsDefault.concat(contextOptionAreaAddVerificationDate,
                contextOptionAreaAddVerificationHour,
                contextOptionAreaAddVerificationResponsable,
                contextOptionAreaAddVerificationType))
        }

        if (isType(itemId, MSG_TYPE_ANNOTATIONS)) {
            setContextAreaOptions(contextAreaOptionsDefault.concat(contextOptionAreaAddAnnotationInterventionType,
                contextOptionAreaAddAnnotation))
        }

        if (isType(itemId, MSG_TYPE_RESERVATIONS)) {
            setContextAreaOptions(contextAreaOptionsDefault.concat(contextOptionAreaAddReservationDriver,
                contextOptionAreaAddReservationStartEndDate))
        }

        if (isType(itemId, MSG_TYPE_MAINTENANCES)) {
            setContextAreaOptions(contextAreaOptionsDefault.concat(contextOptionAreaAddMaintenancePredictedKm))
        }
        if (isType(itemId, MSG_TYPE_SCHEDULED_MAINTENANCES)) {
            setContextAreaOptions(contextAreaOptionsDefault.concat(
                contextOptionAreaAddMaintenancePredictedDate,
                contextOptionAreaAddScheduledMaintenanceDateHour))
        }
    }

    const getData = async () => {
        try {
            Loading.show();
            let list: NotificationDto[] = [];
            const listLanguages = await LanguagesService.getAll();
            listLanguages[0].languageTabVisited = true;
            setLanguages(listLanguages);

            if (itemId) {
                list = await NotificationsService.getListByType(itemId);
            }

            model.list = listLanguages.map(lang => {

                const item = list?.find(x => x.languageId === lang.id);
                if (item) {
                    setIsEmail(item.channel === 'EMAIL');
                    return item;
                }
                return {
                    id: undefined,
                    type: itemId,
                    name: '',
                    title: '',
                    languageId: lang.id,
                };
            });

            form.reset(model)


            Loading.hide();

        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't get notifications list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    };

    useEffect(() => {
        getData();
    }, [isDetails, type]);


    const getType = () => {
        const language = languages[0];
        if (form.getValues('list')) {
            const sourceItem = form.getValues('list').find(x => x.languageId === language.id);
            return t(`notifications.list.${sourceItem?.type}` as any);
        }
        return '';
    }

    const onGoBack = () => {
        if (model) {
            form.reset(model);
        }
        history.goBack();
    }

    const onSubmit = async (model: NotificationListDto) => {
        try {

            Loading.show();
            await NotificationsService.update(model);
            Loading.hide();

            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
            onGoBack();
        } catch (error) {
            Loading.hide();
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't save notification`, error);
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
        }
    };

    const notSubmitted = async (errorsInForm?: any) => {
        for (let i = 0; i < languages.length; i++) {
            const language = languages[i];
            language.invalid = Boolean((errorsInForm?.list || [])[i]) || (!language.languageTabVisited);
        }
    };

    const copyLanguage = (destinationIndex: number, langToCopyId: string) => {
        const sourceItem = form.getValues('list').find(x => x.languageId === langToCopyId);
        if (sourceItem) {
            form.setValue(`list.${destinationIndex}.subject` as 'list.0.subject', sourceItem.subject);
            form.setValue(`list.${destinationIndex}.message` as 'list.0.message', sourceItem.message);
        }
    }

    const onLanguageChange = (lang: LanguageDto, i: number) => {
        setLanguages([
            ...languages.map(l => {
                if (l.id === lang.id) {
                    return {
                        ...l,
                        languageTabVisited: true
                    }
                }
                return l;
            }),
        ]);

        setSelectedLanguageIndex(i);
    }

    useEffect(() => {
        generateContextAreaOptions();
        generateContextOptions();
    }, []);


    return (
        <form onSubmit={form.handleSubmit(onSubmit, notSubmitted)} className={styles.form}>
            <h4 className={styles.title}>{getType()}</h4>

            <LanguagesTab
                selectedLanguageIndex={selectedLanguageIndex}
                languages={languages}
                onChange={onLanguageChange}
            >
                <div className={styles.containerWrapper}>
                    {languages.map((lang, index) => {
                        const keyName = `list.${index}`;
                        const itemErrors = (form.formState.errors.list || [])[index];

                        return <div key={lang.id + '_' + index}
                            className={`${index != selectedLanguageIndex && styles.hideTab}`}>
                            {!isDetails && <div className={styles.contentManagement}><ContentManagement
                                options={languages.filter(x => x.id != lang.id).map(x => ({ label: x.name, value: x.id }))}
                                onCopy={lang => copyLanguage(selectedLanguageIndex, lang)}
                                isInnerContainer={true}
                            />
                            </div>}

                            <div key={`form_${index}`} className={styles.formContent}>
                                <FormItem>
                                    <Label>{t('notifications.subject')}</Label>
                                    <InputController
                                        form={form as any}
                                        name={`${keyName}.subject`}
                                        placeholder={t('notifications.subject')}
                                        disabled={isDetails}
                                        rules={{ required: true, maxLength: 200 }}
                                    />
                                    <InputError error={itemErrors?.subject} />
                                </FormItem>

                                {/* {isEmail && <FormItem>
                                        <Label>{t('notifications.message')}</Label>
                                        <Controller
                                            render={({ value, onChange }) => {
                                                return (<TextAreaWithHelper style={{ marginBottom: '1rem' }} id={`${keyName}.message`} value={value} setValue={onChange} contextMenuOptions={contextOptions} readOnly={isDetails}></TextAreaWithHelper>);
                                            }}
                                            control={form.control}
                                            name={`${keyName}.message`}
                                            defaultValue={form.getValues(`${keyName}.message`) || null} />
                                        <InputError error={itemErrors?.message} />
                                    </FormItem>
                                    } */}

                                {isEmail && <FormItem>
                                    <Label>{t('notifications.message')}</Label>
                                    <SummernoteController
                                        form={form as any}
                                        name={`${keyName}.message`}
                                        placeholder={t('notifications.message')}
                                        contextMenuOptions={contextOptions}
                                        id={`${keyName}.message`}
                                        isDisabled={isDetails}
                                    />

                                    <InputError error={itemErrors?.message} />
                                </FormItem>
                                }

                                {!isEmail &&
                                    <FormItem>
                                        <Label>{t('notifications.message')}</Label>
                                        <InputResizableController
                                            form={form as any}
                                            name={`${keyName}.message`}
                                            placeholder={t('notifications.message')}
                                            disabled={isDetails}
                                            id={`${keyName}.message`}
                                            rules={{ maxLength: 500 }}
                                            contextMenuOptions={contextAreaOptions}
                                            rows={2}
                                        />
                                        <InputError error={itemErrors?.message} />
                                    </FormItem>
                                }
                            </div>
                        </div>
                    })
                    }
                </div>
            </LanguagesTab>


            <div className={styles.buttonContainer}>
                <Button preset={'secondary'}
                    type='button'
                    text={t('common.cancel')}
                    onClick={() => onGoBack()} />
                {type === 'details' && hasNotificationsWritePolicy && <Button type='button' text={t('common.edit')} onClick={() => { history.push(`/settings/notifications/edit/${itemId}`) }} />}
                {type !== 'details' && hasNotificationsWritePolicy && <Button type='submit' text={t('common.save')} />}
            </div>

        </form>
    );
};

export default NotificationsDetails;