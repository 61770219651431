import { CompanyDto } from 'api/companies/models/CompanyDto';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import Loading from 'common/services/Loading';
import { useHistory, useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import CompaniesService from 'api/companies/CompaniesService';
import { DEFAULT_EMAIL_RULES_WITH_REQUIRED, DEFAULT_INPUT_RULES, DEFAULT_INPUT_RULES_WITH_REQUIRED, LOGGER_LOG_TYPE, removeAccents } from 'Config';
import Logger from 'common/services/Logger';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import { Col, Row } from 'react-flexbox-grid';
import FormItem from 'common/components/formItem/FormItem';
import Label from 'common/components/label/Label';
import Input from 'common/components/input/Input';
import InputError from 'common/components/inputError/InputError';
import styles from './CompanyScreen.module.scss';
import { useFieldArray, useForm } from 'react-hook-form';
import Box from 'common/components/box/Box';
import Button from 'common/components/button/Button';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import CustomFile from 'common/models/CustomFile';
import SelectController from 'common/components/select/SelectController';
import { TrackingProvider } from 'api/tracking/enums/TrackingProvider';
import Separator from 'common/components/separator/Separator';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import CheckBoxController from 'common/components/checkBox/CheckBoxController';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import Tabs, { TabItem } from 'common/components/tabs/Tabs';
import PlansScreen from 'screens/account/components/PlansScreen';
import CountriesService from 'api/countries/CountriesService';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import { PackCompanyDto } from 'api/packs/models/PackDto';
import PlanFormItem from './components/PlanFormItem';
import LanguagesService from 'api/languages/LanguagesService';
import Utils from 'common/services/Utils';

export type CloseType = 'edit' | 'details';


export interface PlanItem {
    label: string;
    value: string;
    id: string;
}

const CompanyScreen = () => {
    const { id, type } = useParams<{ id: string, type: string }>();
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const history = useHistory();
    const [item, setItem] = useState<CompanyDto>({ id: '', name: '', trackingProvider: TrackingProvider.NONE, useValueWithVat: true, packs: [] });
    const form = useForm<CompanyDto>({ shouldUnregister: false, defaultValues: id == undefined ? { id: '', name: '', trackingProvider: TrackingProvider.NONE, useValueWithVat: true, packs: [{}] } : undefined });
    const { register, handleSubmit, errors, watch, clearErrors } = form;
    const [medias, setMedias] = useState<CustomFile[]>([]);
    const isDetails = type === 'details';
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<CompanyDto | null>(null);
    const [countries, setCountries] = useState<SelectValueLabel[]>([]);
    const [languages, setLanguages] = useState<SelectValueLabel[] | null>([]);

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasCompaniesWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_COMPANIES_WRITE']);
    const [tabId, setTabId] = useState<string>('account')

    const [plansList, setPlansList] = useState<PlanItem[]>([])
    const [reRender, setReRender] = useState<number>(0);
    const [hasLoaded, setHasLoaded] = useState<boolean>(false);
    const packs = useFieldArray<PackCompanyDto>({ control: form.control, name: 'packs', keyName: 'formId' as any });

    const trackingProvidersList = useMemo(() =>
        Object.keys(TrackingProvider).map(key => {
            const e = (TrackingProvider as any)[key];
            return ({ label: t('common.tracking_providers.' + e as any), value: e });
        })
        , []);

    const getData = async () => {
        try {
            const isCreate = type === 'new'

            const [allPacksInfo, coutriesData, langs] = await Promise.all([
                CompaniesService.getPacksInfo(),
                CountriesService.catalog(),
                isCreate ? LanguagesService.GetAllForSelectItem() : null,
            ]);

            setPlansList(allPacksInfo.map(x => {
                return { id: x.id, value: x.type, label: (t('plans.plans_types.' + x.type as any)) }
            }));


            setCountries(coutriesData.map(x => {
                return { value: x.name, label: (t('common.countries.' + x.name as any)) }
            }));
            setLanguages(langs)


            if (id) {
                Loading.show();

                const result = await CompaniesService.getById(id);

                result.packs?.forEach((item: PackCompanyDto) => {
                    item.date_start = new Date(item.date_start)
                    item.expire = new Date(item.expire)
                })
                setItem(result);
                form.reset(result);
                Loading.hide();
            }

            setHasLoaded(true)

        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't get company information `, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    useEffect(() => {
        getData();
    }, [id, type]);

    const navigateTo = (typeUrl?: string, id?: string) => {
        if (typeUrl) {
            history.push(`/companies/${typeUrl}/${id}`);
        } else {
            history.push(`/companies`);
        }
    }

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }

        try {
            await CompaniesService.remove(itemToRemove);
            onCancelRemove();
            navigateTo();
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete company`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
        }
    };

    const showRemoveItemDialog = async (item: CompanyDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const onSubmit = async (form: CompanyDto) => {
        try {
            Loading.show();
            const tempItem = { ...item };


            tempItem.name = form.name;
            tempItem.taxNumber = form.taxNumber;
            tempItem.email = form.email;
            tempItem.phone = form.phone;
            tempItem.website = form.website;
            tempItem.addressLine = form.addressLine;
            tempItem.zipCode = form.zipCode;
            tempItem.city = form.city;
            tempItem.country = form.country;
            tempItem.removeAttachment = tempItem.logoId && medias.length === 0 ? true : false;
            tempItem.trackingProvider = form.trackingProvider;
            tempItem.trackingProviderUsername = form.trackingProviderUsername;
            tempItem.trackingProviderPassword = form.trackingProviderPassword;
            tempItem.trackingProviderKey = form.trackingProviderKey;
            tempItem.useValueWithVat = form.useValueWithVat;
            tempItem.languageId = form.languageId;

            tempItem.packs = form.packs;


            // try {
            //     await CompaniesService.validateVatNumber(form);

            // } catch (error) {                
            //     addToast(t('common.errors.invalid_tax_number'), { appearance: 'warning' });                
            //     Loading.hide();
            //     return;
            // }

            if (tempItem && tempItem.id != '') {
                await CompaniesService.update(tempItem, medias)
                navigateTo('details', tempItem.id);
            } else if (tempItem) {
                const id = await CompaniesService.create(tempItem, medias)
                navigateTo('details', id);
            }
            setItem(tempItem)
            setHasLoaded(false)
            Loading.hide();
            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
        } catch (error) {
            if (error?.response?.status === 409) {
                addToast(t('common.messages.company_already_exists'), { appearance: 'warning' });
            }
            else if (error.message.includes('403')) {
                addToast(t('common.errors.tracking_auth'), { appearance: 'warning' });
                Logger.error(LOGGER_LOG_TYPE.CHARGES, `Provider Authentication Error - wrong credencials`, error);
            } else {
                addToast(t('common.messages.record_save_error'), { appearance: 'error' });
                Logger.error(LOGGER_LOG_TYPE.CHARGES, `Couldn't update the charge with id: ${form.id}`, error);
            }

            Loading.hide();
        }
    }

    const onAddMedias = (medias: CustomFile[]) => {
        setMedias(medias);
    }

    const onRemoveMedia = (mediaId: string) => {
        const filteredMedia = [...medias.filter(x => x.id != mediaId)];
        setMedias(filteredMedia);
    }

    const trackingProvider = form.watch('trackingProvider');

    const onError = async () => {
        addToast(t('common.errors.required_fields_empty'), { appearance: 'warning' });
    };

    useEffect(() => {
        clearErrors(['trackingProviderUsername', 'trackingProviderPassword', 'trackingProviderKey']);
    }, [watch('trackingProvider')]);



    const getPageTitle = () => {
        return type === 'new' ? t('companies.new') : type === 'edit' ? t('companies.edit') : t('companies.details');
    }

    const removePlan = (index: number | null) => {
        if (index === null) return;
        packs.remove(index);
    }

    const accountTab = () => (
        <Box className={styles.box}>
            {hasLoaded && <div className={styles.form}>
                <Row>
                    <Col xs={12} xl={10}>
                        <Row>
                            <Col xs={12} md={8} lg={8}>
                                <FormItem>
                                    <Label className={styles.label}>
                                        {t('companies.name')}
                                        {!isDetails ? '*' : ''}
                                    </Label>
                                    <Input
                                        name='name'
                                        placeholder={t('companies.name')}
                                        defaultValue={item?.name}
                                        ref={register({ ...DEFAULT_INPUT_RULES_WITH_REQUIRED })}
                                        disabled={isDetails}
                                    />
                                    <InputError error={errors.name} />
                                </FormItem>
                            </Col>
                            <Col xs={12} md={4} lg={4}>
                                <FormItem>
                                    <Label className={styles.label}>
                                        {t('companies.tax_number')}
                                    </Label>
                                    <Input
                                        name='taxNumber'
                                        placeholder={t('companies.tax_number')}
                                        defaultValue={item?.taxNumber}
                                        ref={register({ ...DEFAULT_INPUT_RULES, maxLength: 100 })}
                                        disabled={isDetails}
                                    />
                                    <InputError error={errors.taxNumber} />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={4} lg={4}>
                                <FormItem>
                                    <Label className={styles.label}>
                                        {t('companies.email')}
                                        {!isDetails ? '*' : ''}
                                    </Label>
                                    <Input
                                        name='email'
                                        placeholder={t('companies.email')}
                                        defaultValue={item?.email}
                                        type={'email'}
                                        ref={register({ ...DEFAULT_EMAIL_RULES_WITH_REQUIRED, maxLength: 100 })}
                                        disabled={isDetails}
                                    />
                                    <InputError error={errors.email} />
                                </FormItem>
                            </Col>
                            <Col xs={12} md={4} lg={4}>
                                <FormItem>
                                    <Label className={styles.label}>
                                        {t('companies.phone')}
                                    </Label>
                                    <Input
                                        name='phone'
                                        placeholder={t('companies.phone')}
                                        defaultValue={item?.phone}
                                        ref={register({ ...DEFAULT_INPUT_RULES, maxLength: 50 })}
                                        disabled={isDetails}
                                    />
                                    <InputError error={errors.phone} />
                                </FormItem>
                            </Col>
                            <Col xs={12} md={4} lg={4}>
                                <FormItem>
                                    <Label className={styles.label}>
                                        {t('companies.website')}
                                    </Label>
                                    <Input
                                        name='website'
                                        placeholder={t('companies.website')}
                                        defaultValue={item?.website}
                                        ref={register({ ...DEFAULT_INPUT_RULES })}
                                        disabled={isDetails}
                                    />
                                    <InputError error={errors.website} />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={4} lg={4}>
                                <FormItem>
                                    <Label className={styles.label}>
                                        {t('companies.address')}
                                        {!isDetails ? '*' : ''}
                                    </Label>
                                    <Input
                                        name='addressLine'
                                        placeholder={t('companies.address')}
                                        defaultValue={item?.addressLine}
                                        ref={register({ ...DEFAULT_INPUT_RULES_WITH_REQUIRED, maxLength: 200 })}
                                        disabled={isDetails}
                                    />
                                    <InputError error={errors.addressLine} />
                                </FormItem>
                            </Col>
                            <Col xs={12} md={4} lg={4}>
                                <FormItem>
                                    <Label className={styles.label}>
                                        {t('companies.zip_code')}
                                        {!isDetails ? '*' : ''}
                                    </Label>
                                    <Input
                                        name='zipCode'
                                        placeholder={t('companies.zip_code')}
                                        defaultValue={item?.zipCode}
                                        ref={register({ ...DEFAULT_INPUT_RULES_WITH_REQUIRED, maxLength: 15 })}
                                        disabled={isDetails}
                                    />
                                    <InputError error={errors.zipCode} />
                                </FormItem>
                            </Col>
                            <Col xs={12} md={4} lg={4}>
                                <FormItem>
                                    <Label className={styles.label}>
                                        {t('companies.city')}
                                    </Label>
                                    <Input
                                        name='city'
                                        placeholder={t('companies.city')}
                                        defaultValue={item?.city}
                                        ref={register({ ...DEFAULT_INPUT_RULES })}
                                        disabled={isDetails}
                                    />
                                    <InputError error={errors.city} />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} xl={4}>
                                <FormItem>
                                    <Label className={styles.label}>
                                        {t('common.country')}
                                    </Label>
                                    <SelectController
                                        form={form}
                                        isClearable={true}
                                        name='country'
                                        options={countries}
                                        placeholder={t('common.country')}
                                        rules={{ required: false }}
                                        isDisabled={isDetails}
                                        filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                    />
                                    <InputError error={errors.country} />
                                </FormItem>
                            </Col>
                            {type === 'new' && <Col xs={12} xl={4}>
                                <FormItem>
                                    <Label className={styles.label}>
                                        {t('users.language')}
                                    </Label>
                                    <SelectController
                                        form={form}
                                        name="languageId"
                                        options={languages ? languages.map(x => { return { ...x, label: x.fullLabel ?? '' } }) : []}
                                        placeholder={t('users.language')}
                                        rules={{ required: false }}
                                        isDisabled={isDetails}
                                    />
                                    <InputError error={errors.languageId} />
                                </FormItem>
                            </Col>}
                        </Row>
                        <Separator />
                        <Row>
                            <Col xs={12} md={4} lg={4}>
                                <FormItem>
                                    <Label className={styles.label}>
                                        {t('companies.tracking_provider')}
                                    </Label>
                                    <SelectController
                                        form={form}
                                        name='trackingProvider'
                                        menuPortalTarget={document.querySelector('body')}
                                        options={trackingProvidersList}
                                        watch={watch('trackingProvider')}
                                        placeholder={t('companies.tracking_provider')}
                                        isDisabled={isDetails}
                                        filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                        onChangeSelect={e => {
                                            if (e.value === TrackingProvider.NONE) {
                                                form.setValue('trackingProviderKey', undefined);
                                                form.setValue('trackingProviderUsername', undefined);
                                                form.setValue('trackingProviderPassword', undefined);
                                            } else if (e.value === TrackingProvider.EFCOLIA) {
                                                form.setValue('trackingProviderKey', undefined);
                                            } else {
                                                form.setValue('trackingProviderUsername', undefined);
                                                form.setValue('trackingProviderPassword', undefined);
                                            }
                                        }}
                                    />
                                    <InputError error={errors.trackingProvider} />
                                </FormItem>
                            </Col>
                            {trackingProvider && trackingProvider === TrackingProvider.EFCOLIA && <>
                                <Col xs={12} md={4} lg={4}>
                                    <FormItem>
                                        <Label className={styles.label}>
                                            {t('companies.tracking_provider_username')}*
                                        </Label>
                                        <Input
                                            name='trackingProviderUsername'
                                            placeholder={t('companies.tracking_provider_username')}
                                            defaultValue={item?.trackingProviderUsername}
                                            ref={register({ ...DEFAULT_INPUT_RULES_WITH_REQUIRED })}
                                            disabled={isDetails}
                                        />
                                        <InputError error={errors.trackingProviderUsername} />
                                    </FormItem>
                                </Col>
                                <Col xs={12} md={4} lg={4}>
                                    <FormItem>
                                        <Label className={styles.label}>
                                            {t('companies.tracking_provider_password')}*
                                        </Label>
                                        <Input
                                            type="password"
                                            name='trackingProviderPassword'
                                            placeholder={t('companies.tracking_provider_password')}
                                            defaultValue={item?.trackingProviderPassword}
                                            ref={register({ ...DEFAULT_INPUT_RULES_WITH_REQUIRED })}
                                            disabled={isDetails}
                                        />
                                        <InputError error={errors.trackingProviderPassword} />
                                    </FormItem>
                                </Col>
                            </>}
                            {trackingProvider === TrackingProvider.GPSTRACKING && <>
                                <Col xs={12} md={8} lg={8}>
                                    <FormItem>
                                        <Label className={styles.label}>{t('companies.tracking_provider_key')}*</Label>
                                        <Input
                                            name='trackingProviderKey'
                                            placeholder={t('companies.tracking_provider_key')}
                                            defaultValue={item?.trackingProviderKey}
                                            ref={register({ ...DEFAULT_INPUT_RULES_WITH_REQUIRED, validate: value => !Utils.isStringNullOrEmpty(value) })}
                                            disabled={isDetails}
                                        />
                                        <InputError error={errors.trackingProviderKey} />
                                    </FormItem>
                                </Col>
                            </>}
                        </Row>
                        <Separator />
                        <Row>
                            <Col xs={12}>
                                <FormItem>
                                    <CheckBoxController form={form as any}
                                        name={`useValueWithVat`}
                                        label={t('companies.use_value_with_vat')}
                                        disabled={isDetails} />
                                </FormItem>
                            </Col>
                        </Row>
                        <Separator />
                        <Row>
                            <Col xs={12}>
                                <div className={styles.headerPlans}>
                                    <div className={styles.headerText}>
                                        {t('plans.plans')}
                                    </div>
                                    {!isDetails && <div>
                                        <span className={styles.headerButton} onClick={() => packs.append({})}>
                                            <span className={styles.headerButtonText}></span>
                                            <img src={AddIcon} className={styles.headerButtonIcon} />
                                        </span>
                                    </div>}
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '15px' }}>
                            <Col xs={12}>
                                {packs.fields.map((ti, index) => {
                                    return (
                                        <PlanFormItem
                                            key={ti.formId}
                                            form={form}
                                            isDetails={isDetails}
                                            onRemoveClick={() => {
                                                removePlan(index);
                                            }}
                                            index={index}
                                            plansList={plansList}
                                            setRender={() => { setReRender(reRender + 1); }}
                                        />
                                    );
                                })}
                            </Col>
                        </Row>
                    </Col>

                </Row>
                <div className={styles.buttonsContainer}>
                    <Button
                        preset={'secondary'}
                        type="button"
                        onClick={() => navigateTo()}
                        text={t('common.cancel')}
                    />
                    {isDetails && hasCompaniesWritePolicy &&
                        <Button
                            type="button"
                            text={t('common.remove')}
                            preset={'danger'}
                            onClick={() => showRemoveItemDialog({ id: item?.id } as CompanyDto)} />
                    }
                    {isDetails && hasCompaniesWritePolicy &&
                        <Button
                            type={'button'}
                            text={t('common.edit')}
                            onClick={() => { navigateTo('edit', item?.id); }}
                        />}
                    {!isDetails && hasCompaniesWritePolicy &&
                        <Button
                            type={'submit'}
                            text={t('common.save')}
                        />}

                    <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('common.messages.remove_record_with_ident', { name: item?.name ?? '' })} />
                </div>
            </div>}
        </Box>
    );

    const packsTab = () => (
        <PlansScreen
            isDetails={isDetails}
            companyId={id}
            issues={[]}
        ></PlansScreen>)


    const items: TabItem[] = [
        {
            id: 'account',
            title: t('companies.account'),
            content: accountTab()
        },
        {
            id: 'subscription',
            title: t('companies.plans'),
            content: packsTab()
        }
    ];


    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <ScreenTitle title={getPageTitle()}>
                <ScreenContainer>
                    <ScreenHeader title={getPageTitle()} />
                    {type !== 'new' && <Tabs
                        items={items}
                        activeTabId={tabId}
                        onChange={(tId: string) => { setTabId(tId) }} />
                    }
                    {type === 'new' && accountTab()
                    }
                </ScreenContainer>
            </ScreenTitle>
        </form>
    );
};

export default CompanyScreen;
