import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ChargeFiltersScreen.module.scss';
import Button from '../../../common/components/button/Button';
import FormItem from '../../../common/components/formItem/FormItem';
import Label from '../../../common/components/label/Label';
import { Col, Row } from 'react-flexbox-grid';
import { SelectValueLabel } from '../../../common/types/SelectValueLabel';
import VehiclesService from '../../../api/vehicles/VehiclesService';
import GenericTypesService from '../../../api/genericTypes/GenericTypesService';
import SuppliersService from '../../../api/suppliers/SuppliersService';
import Select from 'common/components/select/Select';
import { Controller, useForm } from 'react-hook-form';
import UsersService from 'api/users/UsersService';
import { UsersSelectItemDto } from 'api/users/models/UserDto';
import { GenericType } from 'api/genericTypes/enums/GenericType';
import Utils from 'common/services/Utils';
import { useParams } from 'react-router-dom';
import RangeInputPicker from 'common/components/rangeInputPicker/RangeInputPicker';
import moment from 'moment';
import { SituationType } from 'screens/vehicle/components/carChargeTab/components/ChargeScreen';
import { ChargesStatusColorEnum } from 'api/charges/enums/ChargesStatusColorEnum';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import Loading from 'common/services/Loading';
import ToggleSwitch from 'common/components/toggleSwitch/ToggleSwitch';

export interface Filters {
    startDate?: Date | null;
    endDate?: Date | null;
    vehicleId?: string;
    vehicleName?: string;
    typeId?: string;
    typeName?: string;
    type?: string;
    supplierId?: string;
    supplierName?: string;
    responsibleId?: string;
    responsibleName?: string;
    cost?: number[];
    refundSituation?: string;
    vehiclesInactive?: boolean;
    page?: number;
    fuelId?: string;
    fuelName?: string;
}

type Props = {
    isVehicle?: boolean;
    isDriver?: boolean;
    filters: Filters;
    onChange: (filters: Filters) => void;
    onFilter: (filters: Filters) => void;
}

const ChargeFiltersScreen: React.FC<Props> = ({ isVehicle, isDriver, filters, onChange, onFilter }: Props) => {
    const { t } = useTranslation();

    const { driverId } = useParams<{ driverId: string }>();

    const form = useForm<Filters>({ shouldUnregister: false, shouldFocusError: true, defaultValues: filters });
    const { setValue, watch, getValues, control } = form;
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const [vehiclesOptions, setVehicles] = useState<SelectValueLabel[]>([]);
    const [typesOptions, setTypes] = useState<SelectValueLabel[]>([]);
    const [suppliersOptions, setSuppliers] = useState<SelectValueLabel[]>([]);
    const [responsiblesOptions, setResponsibles] = useState<SelectValueLabel[]>([]);
    const [selectedRefundTreated, setSelectedRefundTreated] = useState<boolean | undefined>(undefined);
    const [reRender, setReRender] = useState<number>(0);
    const [fuelsOptions, setFuels] = useState<SelectValueLabel[]>([]);

    const getData = async () => {
        try {
            Loading.show();
            const [vehiclesOptions, typesOptions, suppliersOptions, responsiblesOptions, fuels] = await Promise.all([
                VehiclesService.catalogByCharges(),
                GenericTypesService.catalogCharge(),
                SuppliersService.catalog({ referencedAtCharges: true }),
                UsersService.getAllForCompany(),
                VehiclesService.getFuelList(),
            ]);
            setVehicles(vehiclesOptions);

            const types2 = typesOptions.map(x => ({ ...x, name: x.label, label: x.isSystem ? t(`common.generic_types.${x.label}` as any) : x.label }));
            const types = Utils.sortAlphabetically(types2);
            setTypes(types);

            setSuppliers(suppliersOptions);
            setResponsibles((responsiblesOptions || []).map((x: UsersSelectItemDto) => ({ value: x.id || '', label: x.realName || '' })));

            setFuels(Utils.sortAlphabetically(fuels.map(x => ({ value: x.value, label: (t(('vehicle.fuels.' + x.label) as any)), fullLabel: x.label }))));

            Loading.hide();
        } catch (error) {
            Loading.hide();
        }
    }

    useEffect(() => {
        if (form.getValues('refundSituation')) {
            setSelectedRefundTreated(form.getValues('refundSituation') == SituationType.TREATED);
        }
        getData();
    }, []);

    const clearFilters = () => {
        form.reset({
            startDate: undefined,
            endDate: undefined,
            vehicleId: undefined,
            typeId: undefined,
            supplierId: undefined,
            responsibleId: isDriver && !Utils.isStringNullOrEmpty(driverId) ? driverId : undefined,
            cost: undefined,
            vehicleName: undefined,
            typeName: undefined,
            type: undefined,
            supplierName: undefined,
            responsibleName: undefined,
            refundSituation: undefined,
            vehiclesInactive: false,
            fuelId: undefined
        });
        onInputChange();
        onSubmit(getValues());
    }

    const onSubmit = (filters: Filters) => {
        onFilter({ ...filters, responsibleId: isDriver && !Utils.isStringNullOrEmpty(driverId) ? driverId : filters.responsibleId });
    }

    const onInputChange = () => {
        if (getValues('type') != GenericType.FUEL) {
            setValue('fuelId', undefined);
        }
        onChange(getValues());

        setReRender(reRender + 1);
    }

    const getVal = (arr: any[] | undefined, index: number): any | undefined => {
        return arr && arr.length > index ? arr[index] : undefined;
    }


    const onSelectRefundTreated = (mode: boolean, type?: SituationType) => {
        const aux = selectedRefundTreated === mode ? undefined : mode;
        setSelectedRefundTreated(aux);
        setValue('refundSituation', aux === undefined ? undefined : type);
        onInputChange();
    }

    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className={styles.formContent}>
                <div>
                    <FormItem>
                        <Row>
                            <Col xs={12}>
                                <RangeInputPicker
                                    label={t('common.date')}
                                    onChange={(startValue: any, endValue: any) => {
                                        setValue('startDate', startValue ? moment(startValue).toDate() : undefined);
                                        setValue('endDate', endValue ? moment(endValue).toDate() : undefined);
                                        onInputChange();
                                    }}
                                    defaultStartValue={getValues('startDate')}
                                    defaultEndValue={getValues('endDate')}
                                    isDatePicker
                                />
                            </Col>
                        </Row>
                        <Row key={'chargesFiltersRow' + reRender}>
                            {!isVehicle && <Col xs={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('vehicle.vehicle')}</Label>
                                    <Controller
                                        render={({ onChange, value }) => {
                                            return (
                                                <Select
                                                    options={vehiclesOptions}
                                                    isClearable
                                                    placeholder={t('vehicle.vehicle')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        setValue('vehicleName', vehiclesOptions.find(x => x.value == data?.value)?.label ?? null);
                                                        onChange(data?.value ?? null);
                                                        onInputChange();
                                                    }}
                                                    value={vehiclesOptions.find(x => x.value === value) ? { label: vehiclesOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                                />
                                            );
                                        }}
                                        control={control}
                                        name="vehicleId"
                                        defaultValue={getValues('vehicleId')} />
                                </FormItem>
                            </Col>}
                            <Col xs={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('common.type')}</Label>
                                    <Controller
                                        render={({ onChange, value }) => {
                                            return (
                                                <Select
                                                    options={typesOptions}
                                                    isClearable
                                                    placeholder={t('common.type')}
                                                    onChange={(data: SelectValueLabel) => {
                                                            const _type = typesOptions.find(x => x.value == data?.value);
                                                            setValue('type', _type?.fullLabel ?? null);
                                                            setValue('typeName', _type?.label ?? null);
                                                            onChange(data?.value ?? null);
                                                        onInputChange();
                                                    }}
                                                    value={typesOptions.find(x => x.value === value) ? { label: typesOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                                />
                                            );
                                        }}
                                        control={control}
                                        name="typeId"
                                        defaultValue={getValues('typeId')} />
                                </FormItem>
                            </Col>
                            {getValues('type') == GenericType.FUEL && <Col xs={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('vehicle.fuel')}</Label>
                                    <Controller
                                        render={({ onChange, value }) => {
                                            return (
                                                <Select
                                                    options={fuelsOptions}
                                                    isClearable
                                                    placeholder={t('vehicle.fuel')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        setValue('fuelName', fuelsOptions.find(x => x.value == data?.value)?.label ?? null);
                                                        onChange(data?.value ?? null);
                                                        onInputChange();
                                                    }}
                                                    value={fuelsOptions.find(x => x.value === value) ? { label: fuelsOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                                />
                                            );
                                        }}
                                        control={control}
                                        name="fuelId"
                                        defaultValue={getValues('fuelId')} />
                                </FormItem>
                            </Col>}
                            <Col xs={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('common.supplier')}</Label>
                                    <Controller
                                        render={({ onChange, value }) => {
                                            return (
                                                <Select
                                                    options={suppliersOptions}
                                                    isClearable
                                                    placeholder={t('common.supplier')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        setValue('supplierName', suppliersOptions.find(x => x.value == data?.value)?.label ?? null);
                                                        onChange(data?.value ?? null);
                                                        onInputChange();
                                                    }}
                                                    value={suppliersOptions.find(x => x.value === value) ? { label: suppliersOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                                />
                                            );
                                        }}
                                        control={control}
                                        name="supplierId"
                                        defaultValue={getValues('supplierId')} />
                                </FormItem>
                            </Col>
                            {!isDriver && <Col xs={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('common.responsible')}</Label>
                                    <Controller
                                        render={({ onChange, value }) => {
                                            return (
                                                <Select
                                                    options={responsiblesOptions}
                                                    isClearable
                                                    placeholder={t('common.responsible')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        setValue('responsibleName', responsiblesOptions.find(x => x.value == data?.value)?.label ?? null);
                                                        onChange(data?.value ?? null);
                                                        onInputChange();
                                                    }}
                                                    value={responsiblesOptions.find(x => x.value === value) ? { label: responsiblesOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                                />
                                            );
                                        }}
                                        control={control}
                                        name="responsibleId"
                                        defaultValue={getValues('responsibleId')} />
                                </FormItem>
                            </Col>}
                            <Col xs={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('charges.refund')}</Label>
                                    <div className={styles.situationDetails}>
                                        <div onClick={() => onSelectRefundTreated(true, SituationType.TREATED)} style={{ width: '50%' }}>
                                            <div className={styles.labelContainer}>
                                                <div className={selectedRefundTreated ? styles.selected : styles.notSelected} style={{ background: selectedRefundTreated ? (ChargesStatusColorEnum as any)[SituationType.TREATED] : null }}>
                                                    <span>{t(('charges.situations.' + SituationType.TREATED) as any)}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div onClick={() => onSelectRefundTreated(false, SituationType.NOT_TREATED)} style={{ width: '50%' }}>
                                            <div className={styles.labelContainer}>
                                                <div className={selectedRefundTreated != undefined && !selectedRefundTreated ? styles.selected : styles.notSelected} style={{ background: selectedRefundTreated != undefined && !selectedRefundTreated ? (ChargesStatusColorEnum as any)[SituationType.NOT_TREATED] : null }}>
                                                    <span>{t(('charges.situations.' + SituationType.NOT_TREATED) as any)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </FormItem>
                            </Col>
                            {!isVehicle && <Col xs={6}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('charges.vehicle_inactives')}</Label>
                                    <ToggleSwitch
                                        name="vehiclesInactive"
                                        form={form}
                                    />
                                </FormItem>
                            </Col>}
                            <Col xs={12}>
                                <RangeInputPicker
                                    label={t('common.cost', { vat: (userProfile?.useValueWithVat ? t('common.with_vat') : t('common.without_vat')) })}
                                    onChange={(startValue: any, endValue: any) => {
                                        const range = [];
                                        range.push(startValue ? Number(startValue) : undefined);
                                        range.push(endValue ? Number(endValue) : undefined);
                                        setValue('cost', range);
                                        onInputChange();
                                    }}
                                    defaultStartValue={getVal(getValues('cost'), 0)}
                                    defaultEndValue={getVal(getValues('cost'), 1)}
                                    groupSymbol={t('common.euro')}
                                />
                            </Col>
                        </Row>
                    </FormItem>
                </div>
                <div className={styles.buttonsFooter}>
                    <FormItem>
                        <Button
                            text={t('common.remove')}
                            size={'normal'}
                            preset={'secondary'}
                            onClick={clearFilters}
                            type='reset' />
                        <Button
                            type='submit'
                            text={t('common.apply')}
                            size={'normal'}
                        />
                    </FormItem>
                </div>
            </div>
        </form>
    );
}

export default memo(ChargeFiltersScreen);