export enum Periodicity {
    ANNUAL = 'ANNUAL',
    SIX_MONTHLY = 'SIX_MONTHLY',
    QUARTERLY = 'QUARTERLY',
    MONTHLY = 'MONTHLY',
    WEEKLY = 'WEEKLY',
    DAILY = 'DAILY',
    UNIQUE = 'UNIQUE'
}

export enum PeriodicityInMonths {
    ANNUAL = 12,
    SIX_MONTHLY = 6,
    QUARTERLY = 3,
    MONTHLY = 1
}