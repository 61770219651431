import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { VehicleBrandsSearchCriteria } from './models/VehicleBrandsSearchCriteria';
import { VehicleBrandDto } from './models/VehicleBrandsDto';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { CatalogBrandsSearchCriteria } from 'api/catalog/models/CatalogBrandsSearchCriteria';

class VehicleBrandsService {
    public getList(criteria: VehicleBrandsSearchCriteria) {
        return Http.get<Paged<VehicleBrandDto>>('vehiclebrands', criteria);
    }

    public catalog(criteria?: CatalogBrandsSearchCriteria) {
        return Http.get<SelectValueLabel[]>('vehiclebrands/catalog', criteria);
    }

    public getById(id: string) {
        return Http.get<VehicleBrandDto>('vehiclebrands/' + id);
    }

    public create(model: VehicleBrandDto) {
        return Http.post<string>('vehiclebrands', model);
    }

    public update(model: VehicleBrandDto) {
        return Http.put('vehiclebrands', model);
    }

    public remove(model: VehicleBrandDto) {
        return Http.put('vehiclebrands/deactivate', model);
    }
}

export default new VehicleBrandsService();