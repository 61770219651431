import Http from '../../common/services/Http';
import CustomFile from 'common/models/CustomFile';
import { ImportDataViewModel } from './models/ImportDataViewModel';
import { DownloadImportModelViewModel } from './models/DownloadImportModelViewModel';

class ImportDataService {
    public import(model: ImportDataViewModel, attachment: CustomFile) {
        return Http.postWithFile<string>('importData', model, [attachment]);
    }

    public download(model: DownloadImportModelViewModel) {        
        return Http.getWithConfig<Blob>(`importdata/download-template`, model, { responseType: 'blob' });
    }

}

export default new ImportDataService();