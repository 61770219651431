import { useState } from 'react';
import { QueryParameterNames, ApplicationPaths, LoginActions } from '../../Config';
import authService, { AuthenticationResultStatus } from '../../common/services/AuthorizeService';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authenticateUser } from '../../store/authentication/action';
import AccountService from '../../api/account/AccountService';
import { sleep } from 'common/utils/GlobalFunctions';

type Props = {
    action: string;
}

type LoginState = {
    message?: string;
}

const IdentityLoginScreen = ({ action }: Props) => {
    const dispatch = useDispatch();
    const [loginMessage, setLoginMessage] = useState<LoginState>({});

    useEffect(() => {
        switch (action) {
            case LoginActions.Login:
                login(getReturnUrl(null));
                break;
            case LoginActions.LoginCallback:
                processLoginCallback();
                break;
            case LoginActions.LoginFailed:
                const params = new URLSearchParams(window.location.search);
                const error = params.get(QueryParameterNames.Message);
                if (error) {
                    setLoginMessage({ message: error });
                }
                break;
            case LoginActions.Profile:
                redirectToProfile();
                break;
            case LoginActions.Register:
                redirectToRegister();
                break;
            case LoginActions.Reset:                
                break;
            default:
                throw new Error(`Invalid action '${action}'`);
        }
    }, []);

    const login = async (returnUrl: any) => {
        const state = { returnUrl };
        const result: any = await authService.signIn(state);
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                break;
            case AuthenticationResultStatus.Success:
                await navigateToReturnUrl(returnUrl);
                break;
            case AuthenticationResultStatus.Fail:
                setLoginMessage(result.message);
                break;
            default:
                throw new Error(`Invalid status result ${result.status}.`);
        }
    }

    const processLoginCallback = async () => {
        const url = window.location.href;
        await sleep(1000);
        const result: any = await authService.completeSignIn(url);
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                throw new Error('Should not redirect.');
            case AuthenticationResultStatus.Success: {
                const token = { token: result?.user.access_token, tokenType: result?.user.token_type, expires: '' }
                const emptyProfile = { id: '', userName: '', realName: '', email: '', policies: [], roles: [], useValueWithVat: true, issues: [] };
                dispatch(authenticateUser(emptyProfile, token));
                const profile = await AccountService.profile();
                dispatch(authenticateUser(profile, token));
                navigateToReturnUrl(getReturnUrl(null));
            }
                break;
            case AuthenticationResultStatus.Fail:
                setLoginMessage(result.message);
                break;
            default:
                throw new Error(`Invalid authentication result status '${result.status}'.`);
        }
    }

    const getReturnUrl = (state: any) => {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            throw new Error('Invalid return url. The return url needs to have the same origin as the current page.' + fromQuery)
        }
        return (state && state.returnUrl) || fromQuery || `${window.location.origin}/`;
    }

    const redirectToRegister = () => { redirectToApiAuthorizationPath(`${ApplicationPaths.IdentityRegisterPath}?${QueryParameterNames.ReturnUrl}=${encodeURI(ApplicationPaths.Login)}`); }

    const redirectToProfile = () => { redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath); }

    const redirectToApiAuthorizationPath = (apiAuthorizationPath: any) => { window.location.replace(`${window.location.origin}/${apiAuthorizationPath}`); };

    const navigateToReturnUrl = (returnUrl: any) => window.location.replace(returnUrl);

    return (
        <div></div>
    );
}
export default IdentityLoginScreen;