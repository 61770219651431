import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import Tabs, { TabItem } from 'common/components/tabs/Tabs';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DriverUserTab from 'screens/driver/components/driverUserTab/DriverUserTab';
import { Reducers } from 'store/types';
import RolesDetails from './components/rolesTab/components/rolesDetails/RolesDetails';
import RolesList from './components/rolesTab/components/rolesList/RolesList';
import RulesTab from './components/rulesTab/RulesTab';
import UsersList from './components/usersTab/components/usersList/UsersList';

const SecurityScreen = () => {
    const { t } = useTranslation();
    const { tabId, type, id } = useParams<{ tabId: string, type: string, id: string }>();

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasUsersReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_USERS_READ']);
    const hasRolesReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_ROLES_READ']);
    const hasRulesReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_RULES_READ']);

    const defaultTab = (hasUsersReadPolicy ? 'users' : (hasRolesReadPolicy ? 'roles' : 'rules'));
    const [activeTabId, setActiveTabId] = useState(defaultTab);

    const tabs: TabItem[] = useMemo(() => {
        const items: TabItem[] = [];
        if (hasUsersReadPolicy) {
            items.push({
                id: 'users',
                title: t('users.title'),
                content: tabId === 'users' && type ? <DriverUserTab /> : <UsersList />
            });
        }
        if (hasRolesReadPolicy) {
            items.push({
                id: 'roles',
                title: t('roles.title'),
                content: tabId === 'roles' && type ? <RolesDetails /> : <RolesList />
            });
        }
        if (hasRulesReadPolicy) {
            items.push({
                id: 'rules',
                title: t('rules.title'),
                content: <RulesTab />
            });
        }
        return items;
    }, [tabId, type, id]);

    return (
        <ScreenTitle title={t('users.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('users.title')} />

                <Tabs
                    items={tabs}
                    activeTabId={activeTabId}
                    onChange={(tId: string) => { !type && setActiveTabId(tId) }}
                />
            </ScreenContainer>
        </ScreenTitle>
    );
}

export default SecurityScreen;
