import Http from '../../common/services/Http';
import { HotSpotDto } from './models/HotSpotDto';
import { SelectValueLabel } from 'common/types/SelectValueLabel';

class HotSpotsService {
    public getById(id: string) {
        return Http.get<HotSpotDto>('hotspots/' + id);
    }

    public getMyHotSpots() {
        return Http.get<SelectValueLabel[]>('hotspots/getMyHotSpots', {});
    }

    public create(model: HotSpotDto) {
        return Http.post<string>('hotspots', model);
    }

    public remove(model: HotSpotDto) {
        return Http.put(`hotspots/${model.id}/deactivate`, model);
    }

    public select(model: HotSpotDto) {
        return Http.put(`hotspots/select`, model);
    }

    public deselect() {
        return Http.put(`hotspots/deselect`, {});
    }
}

export default new HotSpotsService();
