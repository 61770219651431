import styles from './WelcomeModal.module.scss'
import MainLogo from 'assets/svg/FleetMax_logo_verde.svg'
import ImgInicio from 'assets/img/welcome_inicio.png'
import ImgDrivers from 'assets/img/welcome_condutores.png'
import ImgVehicles from 'assets/img/welcome_veiculos.png'
import ImgContractsChargesMaintenanceTrips from 'assets/img/welcome_contratosdespesasmanutenções.png'
import ImgDashboards from 'assets/img/welcome_dashboards.png'
import ImgClientSupport from 'assets/img/welcome_apoioaocliente.png'
import CloseButton from 'assets/svg/x-circle.svg'
import ScreenHeaderButton from '../screenHeader/ScreenHeaderButton'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { UserProfile } from 'api/account/models/UserProfile';
import { useDispatch, useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import UsersService from 'api/users/UsersService';
import { setProfile } from 'store/authentication/action';
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.scss'
import { Col, Row } from 'react-flexbox-grid'
import { useTranslation } from 'react-i18next'
import { POLICIES } from 'Config'

type Props = {
}

type WelcomeModalItemWithValidation = {
	image: string;
	title: string;
	description: string;
	policies: (keyof typeof POLICIES)[];
	type?: 'OR' | 'AND';
};

const WelcomeModal: React.FC<Props> = ({ }: Props) => {
	const { t } = useTranslation();
	const paginationRef = useRef<HTMLDivElement | null>(null)
	const dispatch = useDispatch();
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [checkBox, setCheckBox] = useState<boolean>(false)
	const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
	const cachedFilters = localStorage.getItem(userProfile?.id ?? '');

	useEffect(() => {
		(userProfile?.hasSeenWelcomeModal || cachedFilters === 'true') ? setIsOpen(false) : setIsOpen(true)
		userProfile?.hasSeenWelcomeModal ? localStorage.removeItem(userProfile.id) : null
	}, [])

	const handleCheck = (e: any) => {
		setCheckBox(e.target.checked);
	}

	const closeModal = () => {
		checkBox ? updateUserProfile() : null;
		setIsOpen(false)
	}

	const updateUserProfile = () => {
		UsersService.updateWelcomeModal(userProfile?.id ?? '')
		userProfile ? userProfile.hasSeenWelcomeModal = true : null;
		localStorage.setItem(userProfile?.id ?? '', 'true');
		dispatch(setProfile(userProfile))
	}

	const validateItem = useCallback((item: WelcomeModalItemWithValidation) => {
		const type = item.type || 'AND';
		const policies = item.policies || [];
		const userPolicies = userProfile?.policies || [];

		return !policies.length || UsersService.hasPolicies(userPolicies, policies, type);
	}, [userProfile]);


	const sidebarItems: WelcomeModalItemWithValidation[] = useMemo(() => {
		const items: WelcomeModalItemWithValidation[] = [
			{
				image: ImgInicio,
				title: t('welcome.start'),
				description: t('welcome.start_message'),
				policies: [],
			},
			{
				image: ImgInicio,
				title: t('welcome.config'),
				description: t('welcome.config_message'),
				policies: [
					'SETTINGUP_GENERIC_TYPES_READ', 'SETTINGUP_GENERIC_TYPES_WRITE',
					'SETTINGUP_VEHICLE_SEGMENTS_READ', 'SETTINGUP_VEHICLE_SEGMENTS_WRITE',
                    'SETTINGUP_VEHICLE_BRANDS_READ', 'SETTINGUP_VEHICLE_BRANDS_WRITE',
                    'SETTINGUP_VEHICLE_MODELS_READ', 'SETTINGUP_VEHICLE_MODELS_WRITE',
                    'SETTINGUP_SUPPLIERS_READ', 'SETTINGUP_SUPPLIERS_WRITE',
                    'SETTINGUP_LOCALS_READ', 'SETTINGUP_LOCALS_WRITE',
                    'SETTINGUP_DIAGNOSTIC_TYPES_READ', 'SETTINGUP_DIAGNOSTIC_TYPES_WRITE',
                    'SETTINGUP_COMMUNICATIONS_READ', 'SETTINGUP_COMMUNICATIONS_WRITE',
                    'SETTINGUP_NOTIFICATIONS_READ', 'SETTINGUP_NOTIFICATIONS_WRITE'
				],
				type: 'OR',
			},
			{
				image: ImgDrivers,
				title: t('welcome.drivers'),
				description: t('welcome.drivers_message'),
				policies: ['SETTINGUP_DRIVERS_READ', 'SETTINGUP_DRIVERS_WRITE'],
				type: 'OR',
			},
			{
				image: ImgVehicles,
				title: t('welcome.vehicles'),
				description: t('welcome.vehicles_message'),
				policies: ['SETTINGUP_VEHICLES_READ', 'SETTINGUP_VEHICLES_WRITE'],
				type: 'OR',
			},
			{
				image: ImgContractsChargesMaintenanceTrips,
				title: t('welcome.contracts_charges_maitenances_trips'),
				description: t('welcome.contracts_charges_maitenances_message'),
				policies: ['SETTINGUP_CHARGES_READ', 'SETTINGUP_CHARGES_WRITE',
						   'SETTINGUP_VEHICLE_MAINTENANCES_READ', 'SETTINGUP_VEHICLE_MAINTENANCES_WRITE',
  						   'SETTINGUP_CONTRACTS_READ', 'SETTINGUP_CONTRACTS_WRITE',
						   'SETTINGUP_VERIFICATIONS_READ',
						   'SETTINGUP_VERIFICATIONS_WRITE'
				],
				type: 'OR',
			},
			{
				image: ImgDashboards,
				title: t('welcome.dashboards'),
				description: t('welcome.dashboards_message'),
				policies: ['SETTINGUP_DASHBOARDS_READ'],
				type: 'OR',
			},
			{
				image: ImgClientSupport,
				title: t('welcome.client_support'),
				description: t('welcome.client_support_message'),
				policies: [],
				type: 'OR',
			}
		];

		return items.filter(item => validateItem(item));
	}, [userProfile, validateItem]);


	return (
		<>
			{isOpen && (
				<>
					<div className={styles.overlay} />
					<div className={styles.modalContainer}>
						<ScreenHeaderButton
							icon={CloseButton}
							onClick={() => closeModal()}
							className={styles.closeButton}
						></ScreenHeaderButton>
						<div>
							<Row>
								<Col xs={12}>
									<img src={MainLogo} className={styles.logo} />
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<span className={styles.title}>{t('welcome.title')}</span>
								</Col>
							</Row>
							<Swiper
								loop={false}
								pagination={{
									el: '.customPagination',
									clickable: true,
								}}
								modules={[Pagination, Navigation]}
								initialSlide={0}
								resistanceRatio={0}
								navigation
								className={styles.swiper}
							>
								{sidebarItems && sidebarItems.map((x, i) => {
									return (
										<SwiperSlide className={styles.slider} key={'swipe' + i}>
											<img src={x.image} className={styles.image} />
											<span className={styles.subtitle}>{x.title}</span>
											<span className={styles.description}>
												{x.description}
											</span>
										</SwiperSlide>
									)
								})}
							</Swiper>
							<div
								ref={paginationRef}
								className={`${styles.pagination} customPagination`}
							></div>
							<div className={styles.checkBoxContainer}>
								<label className={styles.container}>
									<input type="checkbox" onChange={handleCheck} />
									<span className={styles.checkmark}></span>
									<span className={styles.checkBoxText}>{t('welcome.dont_show_this_again')}</span>
								</label>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	)
}

export default WelcomeModal
