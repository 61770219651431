import React from 'react';
import Modal from 'react-modal';
import styles from './AddMaintenanceModal.module.scss';
import MaintenanceScreen from 'screens/vehicle/components/carMaintenanceTab/components/MaintenanceScreen';

type Props = {
    isVisible: boolean;
    onSave: (id: string) => void;
    onCancel: () => void;
    vehicleId: string | null;
};

const AddMaintenanceModal: React.FC<Props> = ({ isVisible, onSave, onCancel, vehicleId }: Props) => {
    const customStyles = {
        content: {
            top: '15%',
            left: '15%',
            right: '15%',
            bottom: '15%',
            backgroundClip: 'padding-box',
            backgroundColor: '#FFFFFF',
            border: '1px solid transparent',
            borderRadius: '4px',
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.3)',
            outline: '0 none',
            overflow: 'hidden',
            padding: '0'
        },
        overlay: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }
    };

    return (
        <Modal
            isOpen={isVisible}
            style={customStyles}
        >
            <div className={styles.container}>
                <MaintenanceScreen
                    item={null}
                    editable={true}
                    isDetails={false}
                    onBackList={onCancel}
                    onItemDetails={() => {
                        //do nothing
                    }}
                    onSubmitDone={onSave}
                    vehicleId={vehicleId}
                    isModal
                    titleHeader={undefined}
                />
            </div>
        </Modal>
    );
}

export default AddMaintenanceModal;
