import Http from 'common/services/Http';
import { AlarmsSearchCriteria, AlarmVehiclesSearchCriteria } from './models/AlarmsSearchCriteria';
import { AlarmsListItemDto } from './models/AlarmsListItemDto';
import { AlarmSettingDto, AlarmTypeDto } from './models/AlarmSettingDto';
import { Paged } from 'api/common/types/Page';
import { VehicleAlarmListItemDto } from 'api/vehicles/models/VehicleDto';
import { AlarmTriggerDto } from './models/AlarmTriggersDto';

class AlarmsService {

    public getList(criteria: AlarmsSearchCriteria) {
        return Http.get<Paged<AlarmsListItemDto>>('alarms', criteria);
    }

    public getTypes() {
        return Http.get<AlarmTypeDto[]>('alarms/get-types');
    }

    public getById(id: string) {
        return Http.get<AlarmSettingDto>('alarms/' + id);
    }

    public create(model: AlarmSettingDto) {
        return Http.post<string>('alarms', model);
    }
    
 
    public update(model: AlarmSettingDto) {
        return Http.put('alarms', model);
    }

    public remove(model: AlarmSettingDto) {
        return Http.put('alarms/deactivate', model);
    }

    
    public getVehiclesList(criteria: AlarmVehiclesSearchCriteria) {
        return Http.get<Paged<VehicleAlarmListItemDto>>('alarms/get-vehicles', criteria);
    }

    public getAlarmTriggersList(criteria:AlarmsSearchCriteria){
        return Http.get<Paged<AlarmTriggerDto>>('alarms/alarm-triggers', criteria);
    }

    public getCompanyVehiclesList(){
        return Http.get<string[]>('alarms/get-vehicles-id-by-company');
    }

}

export default new AlarmsService();
