import ListMaintenancesScreen from './components/MaintenanceList/ListMaintenancesScreen';
import { VehicleMaintenanceDto } from 'api/vehicleMaintenances/models/VehicleMaintenanceDto';
import MaintenanceScreen from '../carMaintenanceTab/components/MaintenanceScreen';
import { useHistory, useParams } from 'react-router-dom';
import { Types } from 'screens/vehicle/VehicleScreen';
import styles from './CarMaintenanceTab.module.scss';
import { useTranslation } from 'react-i18next';
import { VehicleDto } from 'api/vehicles/models/VehicleDto';

enum ScreenType {
    LIST = 'LIST',
    MAINTENANCE = 'MAINTENANCE'
}

type Props = {
    vehicle?: VehicleDto;
    onDeleteFromList: () => void;
    tourReRender: () => void;
}

const CarMaintenanceTab = ({ vehicle, onDeleteFromList, tourReRender }: Props) => {
    const { vehicleId, tabId, itemId, itemType } = useParams<{ vehicleId: string, type: Types, tabId: string, itemType: string, itemId: string }>();
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <div className={styles.container} id="six-step">
            <div>
                {(itemType??'list') === ScreenType.LIST.toString().toLowerCase() && <>
                    <ListMaintenancesScreen
                        editable={!(vehicle && !vehicle.active)}
                        createNewMaintenance={() => {
                            history.push(`/vehicles/details/${vehicleId}/${tabId}/new`);
                        }}
                        onClickItem={(item: VehicleMaintenanceDto, isDetails: boolean) => {
                            history.push(`/vehicles/details/${vehicleId}/${tabId}/${isDetails ? 'details' : 'edit'}/${item.id}`);
                        }}
                        onDeleteFromList={onDeleteFromList}
                        tourReRender={tourReRender}>
                    </ListMaintenancesScreen>
                </>}

                {(itemType??'list') !== ScreenType.LIST.toString().toLowerCase() &&
                    <MaintenanceScreen
                        item={{ id: itemId } as VehicleMaintenanceDto}
                        editable={!(vehicle && !vehicle.active)}
                        isDetails={itemType === 'details'}
                        onItemDetails={(state: boolean) => history.push(`/vehicles/details/${vehicleId}/${tabId}/${state ? 'details' : 'edit'}/${itemId}`)}
                        onSubmitDone={(id: string) => history.goBack()} //history.push(`/vehicles/details/${vehicleId}/${tabId}/details/${id}`)}
                        onBackList={() => history.goBack()}
                        titleHeader={t('maintenances.title')}
                    />
                }
            </div>
        </div>
    );
}

export default CarMaintenanceTab;