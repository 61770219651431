import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { AnnotationDto, CarVerificationListItemDto, RemoveItemViewModel } from './models/CarVerificationDto';
import { CarVerificationSearchCriteria, VerificationOverlapCriteria } from './models/CarVerificationSearchCriteria';
import { UpdateAnnotationsViewModel } from './models/AnnotationsViewModel';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import CustomFile from 'common/models/CustomFile';
import { VerificationViewModel } from './models/VerificationViewModel';
import { VerificationCounterListDto, VerificationDto } from './models/VerificationDto';

class CarVerificationService {
    public getList(criteria: CarVerificationSearchCriteria) {
        return Http.get<Paged<CarVerificationListItemDto>>('verification', criteria);
    }
    public getTop5verifications(criteria: CarVerificationSearchCriteria) {
        return Http.get<CarVerificationListItemDto[]>('verification/get-top5-verifications', criteria);
    }
    
    public getById(id: string) {
        return Http.get<VerificationDto>('verification/' + id);
    }

    public getAnnotationsByVerificationId(criteria: CarVerificationSearchCriteria) {
        return Http.get<AnnotationDto[]>('verification/get-annotations', criteria);
    }

    public create(model: VerificationViewModel, files: CustomFile[]) {
        return Http.postWithFile<string>('verification', model, files);
    }

    public update(model: VerificationViewModel, files: CustomFile[]) {
        return Http.putWithFile<string>('verification', model, files);
    }

    public remove(model: RemoveItemViewModel) {
        return Http.put('verification/deactivate', model);
    }

    public updateAnnotations(model: UpdateAnnotationsViewModel) {
        return Http.put<string>('verification/annotations', model);
    }

    public removeAnnotation(model: RemoveItemViewModel) {
        return Http.put('verification/deactivateannotation', model);
    }

    public catalogZones() {
        return Http.get<SelectValueLabel[]>('verification/zonecatalog');
    }
    
    public getCountersList(criteria: CarVerificationSearchCriteria) {
        return Http.get<VerificationCounterListDto>('verification/counters-list', criteria);
    }

    public getDefaultKm(vehicleId: string) {
        return Http.get<VerificationCounterListDto>('verification/get-default-km/' + vehicleId);
    }
    
    public findOverlaps(criteria: VerificationOverlapCriteria) {
		return Http.get<VerificationDto>('verification/find-overlaps', criteria);
	}

}

export default new CarVerificationService();