import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'react-flexbox-grid';

import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE, removeAccents } from 'Config';

import Box from 'common/components/box/Box';
import Label from 'common/components/label/Label';
import Button from 'common/components/button/Button';
import FormItem from 'common/components/formItem/FormItem';
import InputError from 'common/components/inputError/InputError';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import FileSelector from 'common/components/fileSelector/FileSelector';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import SelectController from 'common/components/select/SelectController';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import Logger from 'common/services/Logger';
import Loading from 'common/services/Loading';
import CustomFile from 'common/models/CustomFile';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import CompaniesService from 'api/companies/CompaniesService';
import { CompanySearchCriteria } from 'api/companies/models/CompanySearchCriteria';
import { SelectValueLabelDto } from 'api/common/models/SelectValueLabelDto';
import { ImportDataViewModel } from 'api/import-data/models/ImportDataViewModel';
import ImportDataService from 'api/import-data/ImportDataService';
import styles from './ImportDataScreen.module.scss';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import moment from 'moment';

const ImportDataScreen: React.FC = () => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const [criteria] = useState<CompanySearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderBy: 'asc',
        orderColumn: 'c.name'
    });
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasUserImportDataWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_IMPORT_DATA_WRITE']);
    const hasCompanyWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_COMPANIES_WRITE']);



    const [attachment, setAttachment] = useState<CustomFile>();
    const [companies, setCompanies] = useState<SelectValueLabelDto[]>();
    const [type, setType] = useState<string | null>(null);
    const types = [
        { value: 'VEHICLES', label: t('import_data.types.VEHICLES') },
        { value: 'CONTRACTS', label: t('import_data.types.CONTRACTS') }
    ]

    const form = useForm<ImportDataViewModel>({ shouldUnregister: false });
    const { handleSubmit, setValue, register, errors } = form;

    const getCompanies = async () => {
        try {

            if (hasCompanyWritePolicy) {
                const companies = await CompaniesService.catalog(criteria);
                setCompanies(companies);
            }
            else {
                const current = await CompaniesService.getCurrent();
                const company: SelectValueLabelDto = { value: current.id ?? '', label: current.name };
                setCompanies([company]);
                setValue('companyId', company.value);
            }


        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't get companies list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const setValuesFiles = (files: CustomFile[]) => {
        setAttachment(files[0]);
    }

    const download = async () => {
        try {
            if (type) {
                Loading.show();
                const template = await ImportDataService.download({ type: type ?? '' });

                const title = t(`import_data.types.${type}` as any);
                saveAs(template, `${title}_${moment(new Date()).format('YYYY_MM_DD_HH_mm_ss')}.xlsx`);
                Loading.hide();
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't download template`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }


    const submitForm = (data: any) => {
        if (attachment) {
            Loading.show();
            ImportDataService.import({ type: data.type, companyId: data.companyId }, attachment)
                .then((result: any) => {
                    Loading.hide();
                    if (result?.message == 'Ok with errors') {
                        addToast(t('import_data.saved_with_errors'), { appearance: 'warning' });
                    }
                    else {
                        addToast(t('common.messages.record_save_success'), { appearance: 'success' });
                    }
                })
                .catch(error => {
                    Loading.hide();
                    if (error?.response?.status === 422) {
                        addToast(t('common.errors.empty_file'), { appearance: 'warning' });
                    }
                    else if (error?.response?.status === 402) {
                        addToast(t('common.errors.pack_limit_exceeded'), { appearance: 'warning' });
                    }
                    else {
                        Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't import data`, error);
                        addToast(t('common.messages.record_save_error'), { appearance: 'error' });
                    }
                });
        }
    }

    useEffect(() => {
        getCompanies();
    }, [criteria]);

    return (
        <ScreenTitle title={t('import_data.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('import_data.title')}></ScreenHeader>
                <Box className={styles.box}>
                    <form onSubmit={handleSubmit(data => submitForm(data))}>
                        <Row>
                            <Col xs={12} sm={12} lg={6} xl={4}>
                                <FormItem>
                                    <Label className={styles.label}>{t('import_data.type')} : *</Label>
                                    <SelectController
                                        ref={register('type', { required: true })}
                                        form={form}
                                        name="type"
                                        placeholder={t('import_data.type')}
                                        isDisabled={false}
                                        options={types}
                                        rules={{ required: true }}
                                        filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                        onChangeSelect={(data: SelectValueLabel) => {
                                            setValue('type', data ? data.value : null);
                                            setType(data ? data.value : null);
                                        }}
                                    />
                                    {/* <InputError error={errors.type} /> */}
                                </FormItem>
                            </Col>
                            {hasCompanyWritePolicy && <Col xs={12} sm={12} lg={6} xl={4}>
                                <FormItem>
                                    <Label className={styles.label}>{t('import_data.company')} : *</Label>
                                    <SelectController
                                        ref={register('companyId')}
                                        form={form}
                                        name="companyId"
                                        placeholder={t('import_data.company_select')}
                                        isDisabled={false}
                                        options={companies || []}
                                        rules={{ required: true }}
                                        filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                                        onChangeSelect={(data: SelectValueLabel) => {
                                            setValue('companyId', data ? data.value : null);
                                        }}
                                    />
                                    <InputError error={errors.companyId} />
                                </FormItem>
                            </Col>}
                            {type != null && <Col xs={12} sm={12} lg={6} xl={4}>
                                <Label className={styles.label}>{t('common.attachment')} (excel) : *</Label>
                                <FormItem>
                                    <FileSelector
                                        onFilesChanged={setValuesFiles}
                                        fileButtonSizes={{ sm: 12 }}
                                        label={<Label className={styles.label}>{t('common.add_new_file')}</Label>}
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    />
                                </FormItem>
                            </Col>}
                        </Row>

                        <div className={styles.buttonsContainer}>
                            <Button
                                type={'submit'}
                                text={t('common.save')}
                                isDisabled={!!form.errors.type || !!form.errors.companyId || !attachment}
                            />
                            <Button
                                text={t('common.download_template')}
                                isDisabled={type === null}
                                onClick={() => { download(); }}
                            />
                        </div>
                    </form>
                </Box>
            </ScreenContainer>
        </ScreenTitle>
    );
};

export default ImportDataScreen;
