import Box from 'common/components/box/Box';
import ScreenContainer from 'common/components/screenContainer/ScreenContainer';
import ScreenHeader from 'common/components/screenHeader/ScreenHeader';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CarVerificationList from 'screens/vehicle/components/carVerificationTab/components/carVerificationList/CarVerificationList';
import { useHistory, useParams } from 'react-router-dom';
import styles from './VerificationScreen.module.scss';
import CarVerification from 'screens/vehicle/components/carVerificationTab/components/carVerification/CarVerification';
import Popover from 'common/components/popover/Popover';
import ScreenHeaderButton from 'common/components/screenHeader/ScreenHeaderButton';
import AddIcon from 'assets/svg/desktop_add_new.svg';
import { CarVerificationSearchCriteria } from 'api/carVerification/models/CarVerificationSearchCriteria';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import VerificationFiltersScreen, { Filters } from './components/list-filters/VerificationFiltersScreen';
import FiltersIcon from 'assets/svg/desktop_filtres.svg';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import FilterList from 'common/components/filterList/FilterList';
import DateFormat from 'common/components/dateFormat/dateFormat';
import CarIcon from 'assets/svg/car.svg';
import AlertIcon from 'assets/svg/i_alertas_white.svg';
import CalendarIcon from 'assets/svg/desktop_i_calendar.svg';
import CarVerificationService from 'api/carVerification/CarVerificationService';
import Logger from 'common/services/Logger';
import { useToasts } from 'react-toast-notifications';
import { VerificationCounterListDto } from 'api/carVerification/models/VerificationDto';
import CounterBox from 'common/components/counterBox/CounterBox';
import { InterventionTypeEnum } from 'api/carVerification/enums/InterventionTypeEnum';
import { getInterventionTypeStatusColor } from 'common/utils/VerificationsUtils';


type RouteProps = {
    itemId: string;
    type: 'new' | 'edit' | 'details';
}

const VerificationScreen = () => {
    const { itemId, type } = useParams<RouteProps>();

    const history = useHistory();
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const [filters, setFilters] = useState<Filters>({});
    const [filtersTotal, setFiltersTotal] = useState(0);

    const [criteria, setCriteria] = useState<CarVerificationSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderColumn: 'v.verification_date',
        orderBy: 'desc'
    });

    const [updateRender, setUpdateRender] = useState(0);
    const [pageLoad, setPageLoad] = useState(false);

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasVerificationsWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_VERIFICATIONS_WRITE']);
    const hasCountersPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['CHARGES_TOTAL_READ']);

    const [counters, setCounters] = useState<VerificationCounterListDto>();

    const getData = async () => {
        try {
            let countersDB = null;
            if (hasCountersPolicy) {
                countersDB = await CarVerificationService.getCountersList(criteria);
            }
            if (countersDB) {
                setCounters(countersDB);
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.VERIFICATIONS, `Couldn't get counters verifications list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const updateTotalFilters = (f: Filters) => {
        let total = 0;

        if (f.vehicleId) {
            total++;
        }
        if (f.type) {
            total++;
        }
        if (f.responsibleId) {
            total++;
        }
        if (f.driverId) {
            total++;
        }
        if (f.startDate || f.endDate) {
            total++;
        }
        if (f.interventionType) {
            total++;
        }

        setFiltersTotal(total);
    }

    const loadFilters = () => {
        const cachedFilters = localStorage.getItem('VERIFICATIONSFILTER');
        setPageLoad(true)
        if(cachedFilters != null){
            const filters = JSON.parse(cachedFilters);
            setCriteria({ ...criteria, ...filters });
            setFilters({ ...filters });
            updateTotalFilters(filters);
        } 
    }

    const onChangeFilters = (_filters: Filters) => {
        setCriteria({ ...criteria, page: 1, ..._filters });
        setFilters({ ..._filters });
        updateTotalFilters(_filters);
        localStorage.setItem('VERIFICATIONSFILTER', JSON.stringify(_filters));
    }

    const goToList = () => {
        navigateTo();
    }

    const goToDetails = (id: string) => {
        navigateTo('details', id);
    }

    const goToEdit = (id: string) => {
        if (hasVerificationsWritePolicy) {
            navigateTo('edit', id);
        } else {
            goToDetails(id);
        }
    }

    const navigateTo = (typeUrl?: string, id?: string) => {
        if (typeUrl) {
            history.push(typeUrl === 'new' ? '/verifications/new' : `/verifications/${typeUrl}/${id}`);
        } else {
            history.push('/verifications');
        }
    }

    useEffect(() => {
        setUpdateRender(updateRender + 1);
    }, [type]); //criteria, type

    useEffect(() => {
        loadFilters();
    }, []); 

    useEffect(() => {  
        if(pageLoad){getData()}
    }, [criteria]);


    const renderCounterBoxes = () => {
        return <div className={styles.rowCounters}>
            {counters && renderBox(
                CarIcon,
                t('car_verification.list.number_vehicles'),
                counters.numberVehicles ?? 0
            )}
            {counters && renderBox(
                CalendarIcon,
                t('car_verification.list.number_verifications'),
                counters.numberVerifications ?? 0
            )}
            {counters && renderBox(
                AlertIcon,
                t('car_verification.list.annotations'),
                undefined, undefined,
                [
                    { counter: counters.numberAnnotationsUrgent ?? 0, color: getInterventionTypeStatusColor(InterventionTypeEnum.INTERVENTION_URGENT).color },
                    { counter: counters.numberAnnotationsInformative ?? 0, color: getInterventionTypeStatusColor(InterventionTypeEnum.INFORMATION).color },
                    { counter: counters.numberAnnotationsIntervention ?? 0, color: getInterventionTypeStatusColor(InterventionTypeEnum.INTERVENTION).color }
                ],
                t('car_verification.list.annotation_type.urgent') + ' | ' + t('car_verification.list.annotation_type.informative') + ' | ' + t('car_verification.list.annotation_type.intervention')
            )}

        </div>
    }

    const renderBox = (icon: any, label: string, counter?: number | undefined, isMoney?: boolean, counters?: any, subLabel?: string) => {
        return <CounterBox
            icon={icon}
            label={label}
            subLabel={subLabel}
            counter={counter}
            isMoney={isMoney}
            counters={counters}
        />
    }

    return (
        <ScreenTitle title={t('car_verification.title')}>
            <ScreenContainer>
                <ScreenHeader title={t('car_verification.title')}>
                    {!type && <>
                        <Popover
                            contentContainerClassName={styles.filtersPopoverContainer}
                            containerStyle={{ boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}
                            positions={['bottom', 'left']}
                            align={'end'}
                            onClickOutside={() => updateTotalFilters(filters)}
                            content={setIsPopoverOpen => <VerificationFiltersScreen
                                genericFilter={true}
                                filters={filters}
                                onFilter={_filters => { setIsPopoverOpen(false); onChangeFilters(_filters) }}
                                onChange={_filters => updateTotalFilters(_filters)}
                            />}
                        >
                            {(isPopoverOpen, setIsPopoverOpen) => (
                                <ScreenHeaderButton icon={FiltersIcon} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                                    {filtersTotal > 0 &&
                                        <div className={styles.counterList}> <div className={styles.counterNumber}>{filtersTotal}</div> </div>
                                    }
                                </ScreenHeaderButton>
                            )}
                        </Popover>
                        {hasVerificationsWritePolicy && <ScreenHeaderButton icon={AddIcon} onClick={() => navigateTo('new')} />}
                    </>}
                </ScreenHeader>

                <FilterList filters={[
                    {
                        value: filters.vehicleName,
                        label: t('vehicle.vehicle'),
                        onRemove: () => { filters.vehicleId = filters.vehicleName = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.type,
                        label: t('vehicle.type'),
                        onRemove: () => { filters.type = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.responsibleName,
                        label: t('common.responsible'),
                        onRemove: () => { filters.responsibleId = filters.responsibleName = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.driverName,
                        label: t('common.driver'),
                        onRemove: () => { filters.driverId = filters.driverName = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.startDate && filters.endDate ? <>{t('common.range.from')} <DateFormat value={filters.startDate} /> {t('common.range.to').toLowerCase()} <DateFormat value={filters.endDate} /></>
                            : filters.startDate ? <>{t('common.range.from')} <DateFormat value={filters.startDate} /></>
                                : filters.endDate ? <>{t('common.range.to')} <DateFormat value={filters.endDate} /></> : undefined,
                        label: t('car_verification.filter_date'),
                        onRemove: () => { filters.startDate = filters.endDate = undefined; onChangeFilters(filters) }
                    },
                    {
                        value: filters.interventionType ? t(`car_verification.intervention_type.${filters.interventionType}`.toLowerCase() as any) : '',
                        label: t('common.intervention_type'),
                        onRemove: () => { filters.interventionType = undefined; onChangeFilters(filters) }
                    }
                ]} />

                {!type && counters && renderCounterBoxes()}

                <Box className={styles.box}>
                    {!type && pageLoad && <>
                        <CarVerificationList genericPage editable={true} criteria={criteria} updateCriteria={setCriteria} />
                    </>}
                    {type &&
                        <CarVerification
                            editable={true}
                            goToEdit={goToEdit}
                            genericPage
                            type={type}
                            vehicleId={null}
                            verificationId={itemId}
                        />}
                </Box>
            </ScreenContainer>
        </ScreenTitle>
    );
}

export default VerificationScreen;
