import { Action, AlertsState, SET_TOTALS } from './type';

const initialState: AlertsState = {
    totals: { totalNotRead: 0, totalRead: 0, totalNotReadOwn: 0, totalTreated: 0, totalNonTreated: 0, totalInterventionUrgent: 0, totalInformation: 0, totalIntervention: 0 },
};

function rootReducer(state: AlertsState = initialState, action: Action): AlertsState {
    switch (action.type) {

        case SET_TOTALS:
            return {
                ...state,
                totals: { ...action.totals },
            };

        default:
            return state;
    }
}

export default rootReducer;
