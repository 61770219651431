import ListContractsScreen from './components/listContractsScreen/ListContractsScreen';
import { useEffect, useState } from 'react';
import { ContractModel } from '../../../../api/contracts/enums/ContractModel';
import { UseContractDto } from 'api/contracts/useContract/models/UseContractDto';
import { MaintenanceContractDto } from 'api/contracts/maintenanceContract/models/MaintenanceContractDto';
import { InsuranceContractDto } from 'api/contracts/insuranceContract/models/InsuranceContractDto';
import UseContractScreen from '../carContractTab/components/useContractScreen/UseContractScreen';
import MaintenanceContractsScreen from '../carContractTab/components/maintenanceContractsScreen/MaintenanceContractsScreen';
import InsuranceContractsScreen from '../carContractTab/components/insuranceContractsScreen/InsuranceContractsScreen';
import Loading from 'common/services/Loading';
import { useHistory, useParams } from 'react-router-dom';
import { Types } from 'screens/vehicle/VehicleScreen';
import styles from './CarContractTab.module.scss';
import { VehicleDto } from 'api/vehicles/models/VehicleDto';

enum ScreenType {
    LIST = 'LIST',
    CONTRACT = 'CONTRACT'
}

type Props = {
    vehicle?: VehicleDto;
    onDeleteFromList: () => void;
    tourReRender: () => void;
}

const CarContractTab = ({ vehicle, onDeleteFromList, tourReRender }: Props) => {
    const { vehicleId, type, tabId, itemId, contractType, contractId, itemType } = useParams<{ vehicleId: string, type: Types, tabId: string, itemType: string, itemId: string, contractType: string, contractId: string }>();
    const history = useHistory();

    const [useContractsLoaded, setUseContractsLoaded] = useState<boolean | null>(null);
    const [maintenanceContractsLoaded, setMaintenanceContractsLoaded] = useState<boolean | null>(null);
    const [insuranceContractsLoaded, setInsuranceContractsLoaded] = useState<boolean | null>(null);
    const [render, setRender] = useState<number>(0);

    const listDidLoad = () => {
        if (useContractsLoaded && maintenanceContractsLoaded && insuranceContractsLoaded) {
            Loading.hide();
        }
    }

    useEffect(() => {
        Loading.show();
    }, []);

    useEffect(() => {
        listDidLoad();
    }, [useContractsLoaded, maintenanceContractsLoaded, insuranceContractsLoaded]);

    return (
        <div >
            {(itemType??'list') === ScreenType.LIST.toString().toLowerCase() && <div className={styles.container}>
                <ListContractsScreen
                    id="contracts-1"
                    key={'contracts-1-'+render}
                    editable={!(vehicle != null && vehicle != undefined && !vehicle.active)}
                    type={ContractModel.USE}
                    createNewContract={() => {
                        history.push(`/vehicles/details/${vehicleId}/${tabId}/details/${ContractModel.USE.toString().toLowerCase()}/new`);
                    }}
                    onClickItem={(item: UseContractDto, isDetails: boolean) => {
                        history.push(`/vehicles/details/${vehicleId}/${tabId}/details/${ContractModel.USE.toString().toLowerCase()}/${isDetails ? 'details' : 'edit'}/${item.id}`);
                    }}
                    onListDidLoad={() => setUseContractsLoaded(true)}
                    onDeleteFromList={onDeleteFromList}
                    tourReRender={tourReRender}
                    listReRender={()=> setRender(render+1)}
                >
                </ListContractsScreen>
                <ListContractsScreen
                    id="contracts-2"
                    editable={!(vehicle != null && vehicle != undefined && !vehicle.active)}
                    type={ContractModel.MAINTENANCE}
                    createNewContract={() => {
                        history.push(`/vehicles/details/${vehicleId}/${tabId}/details/${ContractModel.MAINTENANCE.toString().toLowerCase()}/new`);
                    }}
                    onClickItem={(item: MaintenanceContractDto, isDetails: boolean) => {
                        history.push(`/vehicles/details/${vehicleId}/${tabId}/details/${ContractModel.MAINTENANCE.toString().toLowerCase()}/${isDetails ? 'details' : 'edit'}/${item.id}`);
                    }}
                    onListDidLoad={() => setMaintenanceContractsLoaded(true)}
                    onDeleteFromList={onDeleteFromList}
                    tourReRender={tourReRender}
                    listReRender={()=> setRender(render+1)}>
                </ListContractsScreen>
                <ListContractsScreen
                    id="contracts-3"
                    editable={!(vehicle != null && vehicle != undefined && !vehicle.active)}
                    type={ContractModel.INSURANCE}
                    createNewContract={() => {
                        history.push(`/vehicles/details/${vehicleId}/${tabId}/details/${ContractModel.INSURANCE.toString().toLowerCase()}/new`);
                    }}
                    onClickItem={(item: InsuranceContractDto, isDetails: boolean) => {
                        history.push(`/vehicles/details/${vehicleId}/${tabId}/details/${ContractModel.INSURANCE.toString().toLowerCase()}/${isDetails ? 'details' : 'edit'}/${item.id}`);
                    }}
                    onListDidLoad={() => setInsuranceContractsLoaded(true)}
                    onDeleteFromList={onDeleteFromList}
                    tourReRender={tourReRender}
                    listReRender={()=> setRender(render+1)}>
                </ListContractsScreen>
            </div>}
            {(itemType??'list') !== ScreenType.LIST.toString().toLowerCase() && itemId === ContractModel.USE.toString().toLowerCase() &&
                <UseContractScreen
                    vehicle={vehicle}
                    editable={!(vehicle != null && vehicle != undefined && !vehicle.active)}
                    itemId={contractId}
                    isDetails={contractType === 'details'}
                    onBackList={() => history.goBack()}
                    onItemDetails={(state: boolean) => history.push(`/vehicles/details/${vehicleId}/${tabId}/details/${ContractModel.USE.toString().toLowerCase()}/${state ? 'details' : 'edit'}/${contractId}`)}
                    onSubmitted={() => history.goBack()}>
                </UseContractScreen>}
            {(itemType??'list') !== ScreenType.LIST.toString().toLowerCase() && itemId === ContractModel.MAINTENANCE.toString().toLowerCase() &&
                <MaintenanceContractsScreen
                    itemId={contractId}
                    editable={!(vehicle != null && vehicle != undefined && !vehicle.active)}
                    isDetails={contractType === 'details'}
                    onBackList={() => history.goBack()}
                    onItemDetails={(state: boolean) => history.push(`/vehicles/details/${vehicleId}/${tabId}/details/${ContractModel.MAINTENANCE.toString().toLowerCase()}/${state ? 'details' : 'edit'}/${contractId}`)}
                    onSubmitted={() => history.goBack()}>
                </MaintenanceContractsScreen>}
            {(itemType??'list') !== ScreenType.LIST.toString().toLowerCase() && itemId === ContractModel.INSURANCE.toString().toLowerCase() &&
                <InsuranceContractsScreen
                    itemId={contractId}
                    editable={!(vehicle != null && vehicle != undefined && !vehicle.active)}
                    isDetails={contractType === 'details'}
                    onBackList={() => history.goBack()}
                    onItemDetails={(state: boolean) => history.push(`/vehicles/details/${vehicleId}/${tabId}/details/${ContractModel.INSURANCE.toString().toLowerCase()}/${state ? 'details' : 'edit'}/${contractId}`)}
                    onSubmitted={() => history.goBack()}>
                </InsuranceContractsScreen>}
        </div>
    );
}

export default CarContractTab;