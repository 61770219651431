/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react';
import { useTranslation } from 'react-i18next';
import LibSelect, { Props as SelectProps } from 'react-select';
import colors from './../../../styles/export/colors.module.scss';

export type Props = {
    isDisabled: boolean;
    isSimple?: boolean;
    removeInputBorderRadius?: boolean;
    minWidth?: string;
} & SelectProps;

const Select = React.forwardRef<LibSelect, Props>(({ minWidth, ...props }, ref) => {
    const { t } = useTranslation();

    return (
        <LibSelect
            {...props}
            ref={ref}
            noOptionsMessage={() => t('common.no_options')}
            menuPortalTarget={document.body}
            styles={{
                control: (base, state) => ({
                    ...base,
                    backgroundColor: props.isDisabled ? colors.whisper : colors.white,
                    borderRadius: props.removeInputBorderRadius ? '20px 0px 0 20px !important' : '20px !important',
                    border: props.isSimple ? '0px' : state.isFocused ? `1px solid ${colors.success}` : `1px solid ${colors.mercury}`,
                    boxShadow: 'none',
                    ':hover': {
                        borderColor: state.isFocused ? colors.success : colors.mercury,
                    },
                    minWidth: minWidth,
                    zIndex: 0
                }),
                menuPortal:base => ({
                    ...base,
                    zIndex: 999
                }),
                placeholder: base => ({
                    ...base,
                    color: colors.frenchGray,
                    opacity: 0.54,
                }),
                valueContainer: base => ({
                    ...base,
                    padding: props.isSimple ? '5.75px 0.5rem' : '5.75px 1rem',
                    justifyContent: props.isSimple ? 'flex-end' : 'initial',
                    zIndex: 0
                }),
                option: (base, state) => ({
                    ...base,
                    color: state.isSelected ? colors.white : colors.black,
                    backgroundColor: state.isSelected ? colors.primaryDark : colors.white,
                    ':hover': {
                        backgroundColor: colors.lightGrayishBlue,
                        color: colors.black
                    },
                }),
                singleValue: base => ({
                    ...base,
                    color: colors.lightblack,
                }),
                multiValueLabel: base => ({
                    ...base,
                    backgroundColor: colors.solitude,
                    color: colors.black,
                }),
                multiValueRemove: base => ({
                    ...base,
                    backgroundColor: colors.solitude,
                    color: colors.black,
                }),
                indicatorSeparator: base => ({
                    ...base,
                    width: props.isSimple? '0px' : '1px',
                    backgroundColor: colors.mercury
                }),
            }}
        />
    );
});

export default Select;
