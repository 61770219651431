import Sidebar from 'common/layouts/sidebar/Sidebar';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ScreenTitle from '../../common/components/screenTitle/ScreenTitle';
import styles from './ForbiddenScreen.module.scss';

const ForbiddenScreen: React.FC = () => {

    const { t } = useTranslation();

    return (
        <ScreenTitle title={t('common.errors.page_forbidden_title')}>
            <div className={styles.container} >
                <div className={styles.sidebarContainer}>
                    <Sidebar items={[]} />
                </div>
                <div className={styles.contentContainer} id="scrollHere">
                    <h1>403 - {t('common.errors.page_forbidden')}</h1>
                </div>
            </div>
        </ScreenTitle>
    );
}

export default ForbiddenScreen;
