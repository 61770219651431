export enum MSG_TYPE_CONTRACTS {
    CONTRACT_INSURANCE_EMAIL = 'CONTRACT_INSURANCE_EMAIL',
    CONTRACT_INSURANCE_ALERT = 'CONTRACT_INSURANCE_ALERT',
    CONTRACT_USE_ALERT = 'CONTRACT_USE_ALERT',
    CONTRACT_USE_EMAIL = 'CONTRACT_USE_EMAIL',
    CONTRACT_MAINTENANCE_ALERT = 'CONTRACT_MAINTENANCE_ALERT',
    CONTRACT_MAINTENANCE_EMAIL = 'CONTRACT_MAINTENANCE_EMAIL'
}

export enum MSG_TYPE_CHARGES {
    EXPENSE_NEW_ALERT = 'EXPENSE_NEW_ALERT',
    EXPENSE_NEW_EMAIL = 'EXPENSE_NEW_EMAIL',
}


export enum MSG_TYPE_CHARGES_SITUATION {
    EXPENSE_TREATED_ALERT = 'EXPENSE_TREATED_ALERT',
    EXPENSE_TREATED_EMAIL = 'EXPENSE_TREATED_EMAIL',
    EXPENSE_NOT_TREATED_ALERT = 'EXPENSE_NOT_TREATED_ALERT',
    EXPENSE_NOT_TREATED_EMAIL = 'EXPENSE_NOT_TREATED_EMAIL'
}

export enum MSG_TYPE_CHARGES_STATUS {
    EXPENSE_REFUND_APPROVED_ALERT = 'EXPENSE_REFUND_APPROVED_ALERT',
    EXPENSE_REFUND_APPROVED_EMAIL = 'EXPENSE_REFUND_APPROVED_EMAIL',
    EXPENSE_REFUND_REFUSED_ALERT = 'EXPENSE_REFUND_REFUSED_ALERT',
    EXPENSE_REFUND_REFUSED_EMAIL = 'EXPENSE_REFUND_REFUSED_EMAIL'
}

export enum MSG_TYPE_VERIFICATIONS { VERIFICATION_NEW_ALERT = 'VERIFICATION_NEW_ALERT', VERIFICATION_NEW_EMAIL = 'VERIFICATION_NEW_EMAIL' }

export enum MSG_TYPE_ANNOTATIONS {
    ANNOTATION_INTERVENTION_TYPE_INFORMATION_ALERT = 'ANNOTATION_INTERVENTION_TYPE_INFORMATION_ALERT',
    ANNOTATION_INTERVENTION_TYPE_INFORMATION_EMAIL = 'ANNOTATION_INTERVENTION_TYPE_INFORMATION_EMAIL',
    ANNOTATION_INTERVENTION_TYPE_INTERVENTION_ALERT = 'ANNOTATION_INTERVENTION_TYPE_INTERVENTION_ALERT',
    ANNOTATION_INTERVENTION_TYPE_INTERVENTION_EMAIL = 'ANNOTATION_INTERVENTION_TYPE_INTERVENTION_EMAIL',
    ANNOTATION_INTERVENTION_TYPE_URGENT_ALERT = 'ANNOTATION_INTERVENTION_TYPE_URGENT_ALERT',
    ANNOTATION_INTERVENTION_TYPE_URGENT_EMAIL = 'ANNOTATION_INTERVENTION_TYPE_URGENT_EMAIL'
}

export enum MSG_TYPE_RESERVATIONS {
    RESERVATION_NEW_ALERT = 'RESERVATION_NEW_ALERT',
    RESERVATION_NEW_EMAIL = 'RESERVATION_NEW_EMAIL'
}

export enum MSG_TYPE_MAINTENANCES {
    MAINTENANCE_NEW_ALERT = 'MAINTENANCE_NEW_ALERT',
    MAINTENANCE_NEW_EMAIL = 'MAINTENANCE_NEW_EMAIL'
}

export enum MSG_TYPE_SCHEDULED_MAINTENANCES {
    MAINTENANCE_PLANNED_ALERT = 'MAINTENANCE_PLANNED_ALERT',
    MAINTENANCE_PLANNED_EMAIL = 'MAINTENANCE_PLANNED_EMAIL'
}


export enum MSG_TYPE_REQUEST_VERIFICATIONS {
    REQUEST_VERIFICATION_NEW_ALERT = 'REQUEST_VERIFICATION_NEW_ALERT',
    REQUEST_VERIFICATION_NEW_EMAIL = 'REQUEST_VERIFICATION_NEW_EMAIL'
}