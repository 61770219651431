import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './InformationForm.module.scss';
import { Col, Row } from 'react-flexbox-grid';
import Label from 'common/components/label/Label';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { UseFormMethods, useWatch } from 'react-hook-form';
import FormItem from 'common/components/formItem/FormItem';
import InputError from 'common/components/inputError/InputError';
import DateTimePickerControllerNew from 'common/components/dateTimePicker/DateTimePickerControllerNew';
import SelectController from 'common/components/select/SelectController';
import InputGroupController from 'common/components/inputGroup/InputGroupController';
import { DEFAULT_INPUT_RULES_WITH_REQUIRED, LOGGER_LOG_TYPE, removeAccents, SEGMENT_FEATURES } from 'Config';
import { VerificationType } from 'api/carVerification/enums/VerificationType';
import { VerificationViewModel } from 'api/carVerification/models/VerificationViewModel';
import Utils from 'common/services/Utils';
import { FaRegClock } from 'react-icons/fa';
import Timekeeper from 'react-timekeeper';
import dayjs from 'dayjs';
import { VehicleDto } from 'api/vehicles/models/VehicleDto';
import VehiclesService from 'api/vehicles/VehiclesService';
import Logger from 'common/services/Logger';
import { useToasts } from 'react-toast-notifications';
import InputInfoWarning from 'common/components/inputInfoWarning/InputInfoWarning';
import TrackingService from 'api/tracking/TrackingService';
import { TrackingProvider } from 'api/tracking/enums/TrackingProvider';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';

type Props = {
    form: UseFormMethods<VerificationViewModel>;
    vehicles: SelectValueLabel[];
    vehicle?: VehicleDto;
    responsables: SelectValueLabel[];
    drivers: SelectValueLabel[];
    genericPage: boolean;
    isDetails: boolean;
    type: string;
    findOverlaps?: () => void;
};

const InformationForm: React.FC<Props> = ({ form, vehicles, vehicle, responsables, drivers, genericPage, isDetails, type, findOverlaps }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { errors } = form;
    const [showTime, setShowTime] = useState(false);
    const [showTimeFinish, setShowTimeFinish] = useState(false);
    const [currentKmsGps, setCurrentKmsGps] = useState<number | null>(null);

    const typeWatch = useWatch({ control: form.control, name: 'type' });
    const verificationFinishDateWatch = useWatch<Date | null>({ control: form.control, name: 'verificationFinishDate' });
    const [showKilometersFinish, setShowKilometersFinish] = useState(!!verificationFinishDateWatch);
    const [vehicleParam, setVehicleParam] = useState<VehicleDto | undefined>(vehicle);
    const [inputKmsBelowCurrentVehicleKms, setInputKmsBelowCurrentVehicleKms] = useState<boolean>(false);
    const [typesOptions, setTypesOptions] = useState<SelectValueLabel[]>([]);

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasReadGPSPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_GPS_READ']);

    const changeVehicle = (data: SelectValueLabel) => {
        const vId = data ? data.value : null;
        getVehicle(vId);
    }

    const getVehicle = async (id?: string | null) => {
        try {
            if (id) {
                const result = await VehiclesService.getById(id);
                if (result) {
                    setVehicleParam(result);
                }
            }

        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.VERIFICATIONS, `Couldn't get vehicle data`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const getVehicleGpsCurrentKms = async () => {
        const vehicleAux = vehicle ? vehicle : vehicleParam ? vehicleParam : undefined;
        if (vehicleAux && vehicleAux.id && vehicleAux.companyTrackingProvider && vehicleAux?.companyTrackingProvider !== TrackingProvider.NONE && vehicleAux.trackingDeviceId) {
            try {

                const position = await TrackingService.getPosition(vehicleAux.id);
                if (position) {
                    setCurrentKmsGps(position.km ?? null);
                }

            } catch (error) {
                setCurrentKmsGps(null)
                Logger.error(LOGGER_LOG_TYPE.VERIFICATIONS, `Couldn't get vehicle gps current Kms`, error);
                addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            }
        }
    }

    const compareKms = () => {
        if (vehicleParam && (form.watch('kilometers') ?? 0) < (vehicleParam?.currentKilometers ?? 0)) {
            setInputKmsBelowCurrentVehicleKms(true);
            return;
        }
        setInputKmsBelowCurrentVehicleKms(false);
    }

    const showField = (feature: string) => {
        return (vehicleParam?.segmentFeatures?.findIndex(x => x == feature) ?? -1) >= 0;
    }

    const typesList: SelectValueLabel[] = Utils.sortAlphabetically(Object.keys(VerificationType).map(type => { 
       return  { value: type, label: t(`car_verification.annotation_types.${type}`.toLowerCase() as any) }
    }));

    useEffect(() => {
        ((!isDetails && genericPage) || (!isDetails && !genericPage && type === 'edit')) && compareKms();
    }, []);

    useEffect(() => {
        if (!isDetails && (vehicle || vehicleParam)) {
              // current kms from tracking
            getVehicleGpsCurrentKms();
          
        }
        setTypesOptions(
            Utils.sortAlphabetically(
                showField(SEGMENT_FEATURES.KM) ? typesList : typesList.filter(x => x.value !== VerificationType.TRAVEL),
            ),
        );
    }, [vehicle, vehicleParam]);


    return (
        <div className={styles.verificationInfos}>
            <Row>
                {genericPage && <Col xs={6} lg={4} xl={3}>
                    <FormItem>
                        <Label className={styles.label}>{t('car_verification.vehicle')} *</Label>
                        <SelectController
                            form={form}
                            name='vehicleId'
                            menuPortalTarget={document.querySelector('body')}
                            options={vehicles}
                            placeholder={t('car_verification.vehicle')}
                            rules={{ required: !isDetails }}
                            isDisabled={isDetails}
                            filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                            onChangeSelect={(data: SelectValueLabel) => {
                                changeVehicle(data);
                            }}
                        />
                        <InputError error={errors.vehicleId} />
                    </FormItem>
                </Col>}
                <Col xs={6} lg={4} xl={3}>
                    <FormItem>
                        <Label className={styles.label}>{t('car_verification.verification.type')} *</Label>
                        <SelectController
                            form={form}
                            isClearable={true}
                            name='type'
                            menuPortalTarget={document.querySelector('body')}
                            options={typesOptions}
                            placeholder={t('car_verification.verification.type')}
                            rules={{ required: !isDetails }}
                            isDisabled={isDetails}
                            filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                            onChange={(item?: any) => {
                                if (!item || item?.value == VerificationType.TRAVEL) {
                                    form.setValue('annotationDate', null);
                                    form.setValue('predictedDateEndImmobilization', null);
                                    form.setValue('immobilized', false);
                                }
                            }}
                        />
                        <InputError error={errors.type} />
                    </FormItem>
                </Col>
                <Col xs={6} lg={4} xl={3}>
                    <FormItem>
                        <Label className={styles.label}>{t('common.responsible')} *</Label>
                        <SelectController
                            form={form}
                            name='responsibleId'
                            menuPortalTarget={document.querySelector('body')}
                            options={responsables}
                            placeholder={t('common.responsible')}
                            rules={{ required: !isDetails }}
                            isDisabled={isDetails}
                            filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                        />
                        <InputError error={errors.responsibleId} />
                    </FormItem>
                </Col>
                {typeWatch === VerificationType.TRAVEL.toString() && <Col xs={6} lg={4} xl={3}>
                    <FormItem>
                        <Label className={styles.label}>{t('car_verification.verification.driver')} *</Label>
                        <SelectController
                            form={form}
                            name='driverId'
                            menuPortalTarget={document.querySelector('body')}
                            options={drivers}
                            placeholder={t('car_verification.verification.driver')}
                            rules={{ required: !isDetails }}
                            isDisabled={isDetails}
                            filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                        />
                        <InputError error={errors.driverId} />
                    </FormItem>
                </Col>}
                <Col xs={6} lg={4} xl={3}>
                    <FormItem>
                        <Label className={styles.label}>
                            {typeWatch == VerificationType.TRAVEL ? t('car_verification.verification.trip_date_start') : t('car_verification.verification.verification_date')} *
                        </Label>
                        <DateTimePickerControllerNew
                            form={form}
                            placeholderText={typeWatch == VerificationType.TRAVEL ? t('car_verification.verification.trip_date_start') : t('car_verification.verification.verification_date')}
                            name="verificationDate"
                            autoComplete='off'
                            disabled={isDetails}
                            //maxDate={new Date()}
                            rules={{ required: !isDetails }}
                        />
                        <InputError error={errors.verificationDate} />
                    </FormItem>
                </Col>
                <Col xs={6} lg={4} xl={3} style={{ maxHeight: '7rem' }}>
                    <FormItem>
                        <Label className={styles.label}>
                            {typeWatch == VerificationType.TRAVEL ? t('car_verification.verification.trip_hour_start') + ' *' : t('car_verification.verification.verification_hour')}
                        </Label>
                        {showTime &&
                            <div style={{ zIndex: 1, position: 'relative' }}>
                                <Timekeeper
                                    closeOnMinuteSelect={true}
                                    hour24Mode={true}
                                    switchToMinuteOnHourSelect
                                    onDoneClick={result => {
                                        setShowTime(false);
                                        form.setValue('verificationHour', `${result.hour > 9 ? result.hour : '0' + result.hour}:${result.minute > 9 ? result.minute : '0' + result.minute}`);
                                    }}
                                />
                            </div>
                        }
                        {!showTime &&
                            <InputGroupController
                                autoComplete={'off'}
                                icon={<FaRegClock />}
                                placeholder={t('common.hour')}
                                name="verificationHour"
                                form={form}
                                rules={{ required: typeWatch == VerificationType.TRAVEL }}
                                disabled={isDetails}
                                onClick={() => setShowTime(true)}
                                isClearable={true}
                                onKeyDown={({ nativeEvent }) => {
                                    if (nativeEvent.key) {
                                        nativeEvent.preventDefault();
                                    }
                                }}
                            />}
                        <InputError error={errors.verificationHour} />
                    </FormItem>
                </Col>
                {((!isDetails || form.getValues('kilometers')) && showField(SEGMENT_FEATURES.KM)) ? <Col xs={6} lg={4} xl={3}>
                    <FormItem>
                        <Label className={styles.label}>{typeWatch == VerificationType.TRAVEL ? t('car_verification.verification.trip_kilometers_start') + ' *' : t('common.kilometers')}</Label>
                        <InputGroupController
                            maxLength={255}
                            type="number"
                            text={t('common.kms')}
                            placeholder={typeWatch == VerificationType.TRAVEL ? t('car_verification.verification.trip_kilometers_start') : t('common.kilometers')}
                            name="kilometers"
                            form={form}
                            format="money"
                            disabled={isDetails}
                            rules={{ ...DEFAULT_INPUT_RULES_WITH_REQUIRED, validate: undefined, required: typeWatch == VerificationType.TRAVEL && showField(SEGMENT_FEATURES.KM) }}
                            onChange={() => compareKms()} />
                        <InputError error={errors.kilometers} />
                        {((!isDetails && form.getValues('vehicleId')) || (!isDetails && vehicleParam)) && vehicleParam?.currentKilometers ? <InputInfoWarning isWarning={inputKmsBelowCurrentVehicleKms} message={t((inputKmsBelowCurrentVehicleKms ? 'common.inserted_kms_below_current_kms' : 'common.insert_kms_below_current_kms'), { kms: Utils.numberFormatter(vehicleParam?.currentKilometers ?? 0) })} /> : null}
                        {hasReadGPSPolicy && ((!isDetails && form.getValues('vehicleId')) || (!isDetails && vehicleParam)) && currentKmsGps != null && <InputInfoWarning isWarning={false} message={t('location.current_km', { kms: Utils.numberFormatter(currentKmsGps ?? 0) })} />}
                    </FormItem>
                </Col> : null}
                <Col xs={6} lg={4} xl={3}>
                    <FormItem>
                        <Label className={styles.label}>{t('car_verification.autonomy')}</Label>
                        <InputGroupController
                            type="number"
                            text={t('car_verification.autonomy_symbol')}
                            placeholder={t('car_verification.autonomy')}
                            name="autonomy"
                            form={form}
                            format="number"
                            disabled={isDetails}
                            rules={{ min: { value: 0, message: t('common.errors.min_value_0') }, max: { value: 100, message: t('common.errors.max_value_100') } }}
                        />
                        <InputError error={errors.autonomy} />
                    </FormItem>
                </Col>
            </Row>
            {typeWatch === VerificationType.TRAVEL.toString() && <Row>
                <Col xs={6} lg={4} xl={3}>
                    <FormItem>
                        <Label className={styles.label}>
                            {typeWatch == VerificationType.TRAVEL ? t('car_verification.verification.trip_date_finish') : t('car_verification.verification.verification_date_finish')}
                        </Label>
                        <DateTimePickerControllerNew
                            form={form}
                            placeholderText={typeWatch == VerificationType.TRAVEL ? t('car_verification.verification.trip_date_finish') : t('car_verification.verification.verification_date_finish')}
                            name="verificationFinishDate"
                            autoComplete='off'
                            disabled={isDetails}
                            //maxDate={new Date()}
                            rules={{ required: false }}
                            onChange={date => {
                                if (!!date) {
                                    setShowKilometersFinish(true)
                                }
                                else {
                                    setShowKilometersFinish(false)
                                }

                                const currFinishHour = form.getValues('verificationFinishHour');
                                if (currFinishHour) {
                                    const currFinishHourSplit = currFinishHour.split(':');
                                    if (currFinishHourSplit && date &&
                                        dayjs(date as Date).set('hour', Number(currFinishHourSplit[0]))
                                            .set('minute', Number(currFinishHourSplit[1]))
                                            .toDate() > new Date()
                                    ) {
                                        form.setValue('verificationFinishHour', '');
                                    }
                                }
                            }}
                        />
                        <InputError error={errors.verificationFinishDate} />
                    </FormItem>
                </Col>
                <Col xs={6} lg={4} xl={3} style={{ maxHeight: '7rem' }}>
                    <FormItem>
                        <Label className={styles.label}>
                            {typeWatch == VerificationType.TRAVEL ? t('car_verification.verification.trip_hour_finish') : t('car_verification.verification.verification_hour_finish')}
                        </Label>
                        {showTimeFinish &&
                            <div style={{ zIndex: 1, position: 'relative' }}>
                                <Timekeeper
                                    closeOnMinuteSelect={true}
                                    hour24Mode={true}
                                    switchToMinuteOnHourSelect
                                    onDoneClick={result => {
                                        setShowTimeFinish(false);
                                        form.setValue('verificationFinishHour', `${result.hour > 9 ? result.hour : '0' + result.hour}:${result.minute > 9 ? result.minute : '0' + result.minute}`);
                                    }}
                                    disabledTimeRange={
                                        verificationFinishDateWatch && dayjs(verificationFinishDateWatch).isSame(new Date(), 'day')
                                            ? { from: dayjs().add(1, 'minute').format('HH:mm'), to: '00:00' }
                                            : undefined
                                    }
                                />
                            </div>
                        }
                        {!showTimeFinish &&
                            <InputGroupController
                                autoComplete={'off'}
                                icon={<FaRegClock />}
                                placeholder={t('common.hour')}
                                name="verificationFinishHour"
                                form={form}
                                rules={{ required: false }}
                                disabled={isDetails || !verificationFinishDateWatch}
                                onClick={() => setShowTimeFinish(true)}
                                isClearable={true}
                                onKeyDown={({ nativeEvent }) => {
                                    if (nativeEvent.key) {
                                        nativeEvent.preventDefault();
                                    }
                                }}
                            />}
                        <InputError error={errors.verificationFinishHour} />
                    </FormItem>
                </Col>
                {showKilometersFinish && <Col xs={6} lg={4} xl={3}>
                    <FormItem>
                        <Label className={styles.label}>{typeWatch == VerificationType.TRAVEL ? t('car_verification.verification.trip_kilometers_finish') : t('car_verification.verification.kilometers_finish')} {'*'}</Label>
                        <InputGroupController
                            maxLength={255}
                            type="number"
                            text={t('common.kms')}
                            placeholder={typeWatch == VerificationType.TRAVEL ? t('car_verification.verification.trip_kilometers_finish') : t('car_verification.verification.kilometers_finish')}
                            name="kilometersFinish"
                            form={form}
                            format="money"
                            disabled={isDetails}
                            rules={{ required: true, validate: undefined }} />
                        <InputError error={errors.kilometersFinish} />
                    </FormItem>
                </Col>}
            </Row>}
        </div>
    );
}

export default InformationForm;
