import { useRef } from 'react';
import { Controller, RegisterOptions, UseFormMethods } from 'react-hook-form';
import { Props as MediaProps } from './HorizontalMediaSelector';
import HorizontalMediaSelector from './HorizontalMediaSelector';

type Props = {
    form: UseFormMethods<any>;
    name: string;
    rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
} & Omit<MediaProps, 'onChange' | 'medias'>;

const HorizontalMediaSelectorController = ({ form, name, rules, ...props }: Props) => {
    const ref = useRef(null);
    return (
        <Controller
            render={({ value, onChange }) => {
                return (
                    <HorizontalMediaSelector
                        {...props}
                        onChange={medias => {
                            onChange(medias);
                        }}
                        medias={value}
                    />
                );
            }}
            onFocus={() => (ref.current as any).input.focus()}
            control={form.control}
            name={name}
            defaultValue={form.getValues(name) || []}
            rules={rules}
        />
    );
};

export default HorizontalMediaSelectorController;
