import Http from '../../common/services/Http';
import { DashboardSearchCriteria } from './criterias/DashboardSearchCriteria';
import { ConsumesDashboardDto } from './dtos/ConsumesDashboardDto';
import { CountersDashboardDto } from './dtos/CountersDashboardDto';
import DashboardDto from './dtos/DashboardDto';
import { ExpensesDashboardDto } from './dtos/ExpensesDashboardDto';
import { MonthlyCostDashboardDto } from './dtos/MonthlyCostDashboardDto';
import { MonthlyRoutesDashboardDto } from './dtos/MonthlyRouteDashboardDto';
import { NextMaintenancesDashboardDto } from './dtos/NextMaintenancesDashboardDto';

class DashboardService {
	public async get(criteria: DashboardSearchCriteria) {
		const data = await Http.get<DashboardDto>('dashboard', criteria);

		// Fix dates
		if (data) {
			data.consumes?.items.forEach(c => c.items.forEach(cc => (cc.date = new Date(cc.date))));
			data.monthlyCost?.items.forEach(c => c.items.forEach(cc => (cc.date = new Date(cc.date))));
			if (data.monthlyRoute && data.monthlyRoute.items) {
				data.monthlyRoute.items.forEach(cc => (cc.date = new Date(cc.date)));
			}
		}

		return data;
	}

	public async getCounters(criteria: DashboardSearchCriteria) {
		return await Http.get<CountersDashboardDto>('dashboard/counters', criteria);
	}

	public async getExpenses(criteria: DashboardSearchCriteria) {
		return await Http.get<ExpensesDashboardDto>('dashboard/expenses', criteria);
	}

	public async getConsumes(criteria: DashboardSearchCriteria) {
		const data = await Http.get<ConsumesDashboardDto>('dashboard/consumes', criteria);
		if (data) {
			data.items.forEach(c => c.items.forEach(cc => (cc.date = new Date(cc.date))));
		}
		return data;
	}

	public async getNextMaintenances(criteria: DashboardSearchCriteria) {
		return await Http.get<NextMaintenancesDashboardDto>('dashboard/nextmaintenances', criteria);
	}

	public async getMonthlyCost(criteria: DashboardSearchCriteria) {
		const data = await Http.get<MonthlyCostDashboardDto>('dashboard/monthlycost', criteria);
		if (data) {
			data.items.forEach(c => c.items.forEach(cc => (cc.date = new Date(cc.date))));
		}
		return data;
	}

	public async getMonthlyRoutes(criteria: DashboardSearchCriteria) {
		const data = await Http.get<MonthlyRoutesDashboardDto>('dashboard/monthlyroutes', criteria);
		if (data && data.items) {
			data.items.forEach(cc => (cc.date = new Date(cc.date)));
		}
		return data;
	}


}

export default new DashboardService();
