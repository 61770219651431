import { AlertType, AlertTypeColorEnum } from 'api/alerts/enums/AlertType';
import { AlertSubjectColorEnum, AlertSubjectEnum } from 'api/alerts/enums/AlertSubjectEnum';
import colors from 'styles/export/colors.module.scss';

export type AlertTypeColor = { backgroundColor: string, textColor: string, rowColor?: string };

export const getAlertTypeColor = (type: AlertType) => {

    return { backgroundColor: colors.danger, textColor: colors.white, rowColor: AlertTypeColorEnum[type] };
}

export const getSubjectTypeColor = (subject: AlertSubjectEnum) => {
    switch (subject) {
        case AlertSubjectEnum.INFORMATION:
            return { backgroundColor: AlertSubjectColorEnum.INFORMATION, textColor: colors.white };
        case AlertSubjectEnum.INTERVENTION:
            return { backgroundColor: AlertSubjectColorEnum.INTERVENTION, textColor: colors.white };
        case AlertSubjectEnum.INTERVENTION_URGENT:
            return { backgroundColor: AlertSubjectColorEnum.INTERVENTION_URGENT, textColor: colors.white };
        default:
            return { backgroundColor: colors.black, textColor: colors.white };
    }
}
