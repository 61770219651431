import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'react-flexbox-grid';
import { Reducers } from 'store/types';
import { updateRefreshToken } from 'store/authentication/action';
import { DEFAULT_INPUT_RULES_WITH_REQUIRED, LOGGER_LOG_TYPE, removeAccents } from 'Config';
import { UserProfile } from 'api/account/models/UserProfile';
import { UserDto } from 'api/users/models/UserDto';
import { RoleDto } from 'api/roles/models/RoleDto';
import UsersService from 'api/users/UsersService';
import RolesService from 'api/roles/RolesService';
import { CheckAvailabilityDto } from 'api/common/models/CheckAvailabilityDto';
import CustomFile from 'common/models/CustomFile';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import CheckBoxController from 'common/components/checkBox/CheckBoxController';
import MediaSelector from 'common/components/mediaSelector/MediaSelector';
import Input from 'common/components/input/Input';
import Label from 'common/components/label/Label';
import FormItem from 'common/components/formItem/FormItem';
import Button from 'common/components/button/Button';
import styles from './DriverUserTab.module.scss';
import InputError from 'common/components/inputError/InputError';
import { Types } from 'screens/vehicle/VehicleScreen';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import SelectController from 'common/components/select/SelectController';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import CountriesService from '../../../../api/countries/CountriesService';


type Props = {
};

const DriverUserTab: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const history = useHistory();
    const dispatch = useDispatch();
    const [countries, setCountries] = useState<SelectValueLabel[]>([]);
    const { id, driverId, type } = useParams<{ id: string, driverId: string, type: Types }>();
    const [allRoles, setAllRoles] = useState<RoleDto[]>([]);
    const [user, setUser] = useState<UserDto>();
    const form = useForm<UserDto>({ shouldUnregister: false, defaultValues: { ...user } });
    const { register, handleSubmit, errors, watch } = form;
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasUsersWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_USERS_WRITE']);
    const hasRolesWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_ROLES_WRITE']);
    const hasRolesReadPolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_ROLES_READ']);
    const [isDetails, setIsDetails] = useState<boolean>(type === 'details');
    const [mediaFile, setMediaFile] = useState<CustomFile | null>(null);
    const [removeMedia, setRemoveMedia] = useState(false);
    const [username, setUsername] = useState('');
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<UserDto | null>(null);

    const getData = async () => {
        Loading.show();

        try {
            const allDBRoles = await RolesService.getAllForCompany();
            let userObj: UserDto = { id: '', realName: '', email: '', roles: [], temporaryRoles: [] };
            if (driverId) {
                userObj = await UsersService.getById(driverId);
            }
            if (id && !driverId) {
                userObj = await UsersService.getById(id);
            }
            setAllRoles([...allDBRoles]);

            userObj.temporaryRoles = allDBRoles.map(role => {
                const __role = { ...role };
                __role.checked = (userObj.roles?.includes(__role.name) ?? false);
                return __role;
            });

            userObj.temporaryRoles.forEach(role => {
                if (role.readOnly || role.system) {
                    role.name = t(('common.roles.' + role.name) as any);
                }
            });

            const coutriesData = await CountriesService.catalog();
            setCountries(coutriesData.map(x=> {
                return { value: x.name, label: (t('common.countries.' + x.name as any)) }
            }));
              
            setUser(userObj);
            setUsername(userObj.realName);
            setIsDetails(type === 'details');

            form.reset(userObj);
            
            Loading.hide();
        }
        catch (error) {
            addToast(t('common.messages.error_load_info'), {
                appearance: 'error',
            });

            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                `Couldn't get catalog users types`,
                error
            );
            Loading.hide();
        }
    }

    const onCancel = () => {
        // if (driverId) {
        //     history.push('/drivers');
        //     return;
        // }
        history.goBack();
    };

    useEffect(() => {
        getData();
    }, [type]);

    const onAddAvatar = (files: CustomFile[]) => {
        if (files.length > 0) {
            setMediaFile(files[0]);
        }
    }

    const onRemoveAvatar = () => {
        setRemoveMedia(true);
        if (user) {
            setUser({
                ...user,
                media: null,
            });
        }
    }

    const onSubmit = async (formData: UserDto) => {
        try {

            if (!formData.temporaryRoles || formData.temporaryRoles?.find(e => e.checked === true) == null) {
                addToast(t('common.messages.complete_required_fields'), { appearance: 'warning' });
                return;
            }

            const emailData: CheckAvailabilityDto = { table: 'asp_net_users', column: 'normalized_email', value: formData.email.toLocaleUpperCase(), companyId: true, itemEdit: user?.id };
            const checkAvailabilityEmail = await UsersService.checkAvailability(emailData);
            if (!checkAvailabilityEmail) {
                addToast(t('common.messages.email_already_exists'), { appearance: 'warning' });
                return;
            }

            emailData.companyId = false;
            const checkAvailabilityEmailAllEntities = await UsersService.checkAvailability(emailData);
            if (!checkAvailabilityEmailAllEntities) {
                addToast(t('common.messages.email_already_exists_all_entities'), { appearance: 'warning' });
                return;
            }

            Loading.show();
            if (user) {
                user.realName = formData.realName;
                user.email = formData.email;
                user.phoneNumber = formData.phoneNumber;
                user.address = formData.address;
                user.zipcode = formData.zipcode;
                user.country = formData.country;
                user.roles = [];
                user.removeMedia = removeMedia;

                if (formData.temporaryRoles) {
                    formData.temporaryRoles.forEach((r, i) => {
                        if (r.checked) {
                            user.roles?.push(allRoles[i].name);
                        }
                    });
                }
            }

            if (user && user.id != '') {
                await UsersService.update(user, mediaFile);
            } else if (user) {
                await UsersService.create(user, mediaFile);
            }

            if (user?.id === loggedUser?.id) {
                dispatch(updateRefreshToken());
            }

            Loading.hide();
            addToast(t('common.messages.record_save_success'), {
                appearance: 'success',
            });
            onCancel();
        } catch (error) {
            if (error?.response?.status === 402) {
                addToast(t('common.errors.pack_limit_exceeded'), { appearance: 'warning' });
            }
            else {
                addToast(t('common.messages.record_save_error'), {
                    appearance: 'error',
                });
            }
            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                `Couldn't create or update user`,
                error
            );
            Loading.hide();
        }
    };

    const onError = async () => {
        addToast(t('common.errors.required_fields_empty'), { appearance: 'warning' });
    }

    const showRemoveItemDialog = async (item: UserDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }

        try {
            await UsersService.remove(itemToRemove);
            onCancelRemove();
            onCancel();
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete user`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.avatarContainer}>
                <div className={styles.avatarChooser}>
                    <MediaSelector
                        isProfilePic={true}
                        isDetails={isDetails}
                        isMulti={false}
                        onAddMedias={onAddAvatar}
                        onRemoveMedia={onRemoveAvatar}
                        initialMedias={user?.media ? [user?.media] : []}
                    />
                </div>
                <span className={styles.usernameCol}>{username}</span>
            </div>

            <form className={styles.form} onSubmit={handleSubmit(onSubmit, onError)}>
                <Row>
                    <Col xs={12} md={6} lg={3}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('users.name')} {!isDetails && '*'}
                            </Label>
                            <Input
                                name='realName'
                                placeholder={t('users.name')}
                                defaultValue={user?.realName}
                                disabled={isDetails}
                                ref={register({ ...DEFAULT_INPUT_RULES_WITH_REQUIRED })}
                                onChange={e => setUsername(e.target.value as any)}
                            />
                            <InputError error={errors.realName} />
                        </FormItem>
                    </Col>
                    <Col xs={12} md={6} lg={3}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('users.email')} {!isDetails && '*'}
                            </Label>
                            <Input
                                name='email'
                                placeholder={t('users.email')}
                                defaultValue={user?.email}
                                disabled={isDetails}
                                ref={register({ ...DEFAULT_INPUT_RULES_WITH_REQUIRED })}
                            />
                            <InputError error={errors.email} />
                        </FormItem>
                    </Col>
                    <Col xs={12} md={6} lg={3}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('users.phone_number')}
                            </Label>
                            <Input
                                name='phoneNumber'
                                placeholder={t('users.phone_number')}
                                defaultValue={user?.phoneNumber}
                                disabled={isDetails}
                                ref={register({ required: false })}
                            />
                        </FormItem>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('users.address')}
                            </Label>
                            <Input
                                name='address'
                                placeholder={t('users.address')}
                                defaultValue={user?.address}
                                disabled={isDetails}
                                ref={register({ required: false })}
                            />
                        </FormItem>
                    </Col>
                    <Col xs={12} md={6} lg={3}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('users.zipcode')}
                            </Label>
                            <Input
                                name='zipcode'
                                placeholder={t('users.zipcode')}
                                defaultValue={user?.zipcode}
                                disabled={isDetails}
                                ref={register({ required: false })}
                            />
                        </FormItem>
                    </Col>
                    <Col xs={12} md={6} lg={3}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('common.country')}
                            </Label>
                            <SelectController
                                form={form}
                                isClearable={true}
                                name='country'
                                options={countries}
                                placeholder={t('users.country')}
                                rules={{ required: false }}
                                isDisabled={isDetails}
                                filterOption={(candidate: any, input: any) => input ? removeAccents(candidate.label).toUpperCase().includes(removeAccents(input).toUpperCase()) : true}
                            />
                            <InputError error={errors.country} />


                        </FormItem>
                    </Col>

                    <Col xs={12}>
                        {(hasRolesReadPolicy || hasRolesWritePolicy) && <FormItem>
                            <Label className={styles.label}>
                                {t('roles.title')} {!isDetails && '*'}
                            </Label>
                            <div className={styles.roles}>
                                {user?.temporaryRoles.map((role, i) => {
                                    return (<CheckBoxController form={form as any}
                                        key={i}
                                        name={`temporaryRoles[${i}].checked`}
                                        label={role.name}
                                        disabled={isDetails || !hasRolesWritePolicy} />);
                                })}

                            </div>
                            <InputError error={form.formState.isSubmitted && watch<string, RoleDto[]>('temporaryRoles').filter(x => x.checked).length === 0 ? { type: 'dateRange', message: t('common.errors.error_no_role_selected') } : undefined} />
                        </FormItem>}
                    </Col>
                </Row>

                <div className={styles.buttonContainer}>
                    <Button preset={'secondary'}
                        type='button'
                        text={t('common.cancel')}
                        onClick={() => onCancel()} />
                    {isDetails && hasUsersWritePolicy &&
                        <Button
                            type="button"
                            text={t('common.remove')}
                            preset={'danger'}
                            onClick={() => showRemoveItemDialog({ id: user?.id } as UserDto)} />
                    }
                    {isDetails && hasUsersWritePolicy &&
                        <Button
                            type='button'
                            text={t('common.edit')}
                            onClick={() => history.push(`/drivers/edit/${driverId ?? id}/user/list`)} />
                    }
                    {!isDetails && hasUsersWritePolicy &&
                        <Button
                            type='submit'
                            text={t('common.save')} />
                    }
                </div>
            </form>
            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('common.messages.remove_record_with_ident', { name: itemToRemove?.realName ?? '' })} />
        </div>
    );
}

export default DriverUserTab;