import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './SuppliersFiltersScreen.module.scss';
import Button from '../../../../../../common/components/button/Button';
import FormItem from '../../../../../../common/components/formItem/FormItem';
import { Col, Row } from 'react-flexbox-grid';
import { SelectValueLabel } from '../../../../../../common/types/SelectValueLabel';
import { Controller, useForm } from 'react-hook-form';
import GenericTypesService from '../../../../../../api/genericTypes/GenericTypesService';
import Select from 'common/components/select/Select';
import Label from 'common/components/label/Label';
import Utils from 'common/services/Utils';

export interface Filters {
    typeId?: string;
}

type Props = {
    filters: Filters;
    onChange: (filters: Filters) => void;
    onFilter: (filters: Filters) => void;
}

const SuppliersFiltersScreen: React.FC<Props> = ({ filters, onChange, onFilter }: Props) => {
    const { t } = useTranslation();

    const form = useForm<Filters>({ shouldUnregister: false, shouldFocusError: true, defaultValues: filters });

    const [typesOptions, setTypes] = useState<SelectValueLabel[]>([]);

    const getData = async () => {
        const typesOptions = await GenericTypesService.catalogSupplier();
        const options = typesOptions.map(x => ({ ...x, name: x.label, label: x.isSystem ? t(`common.generic_types.${x.label}` as any) : x.label }));
        setTypes(Utils.sortAlphabetically(options));
    }

    useEffect(() => {
        getData();
    }, []);

    const clearFilters = () => {
        form.reset();
        form.setValue('typeId', null);
        onInputChange();
        onSubmit(form.getValues());
    }

    const onSubmit = (filters: Filters) => {
        onFilter(filters);
    }

    const onInputChange = () => {
        onChange(form.getValues());
    }

    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className={styles.formContent}>
                <div>
                    <FormItem>
                        <Row>
                            <Col xs={12}>
                                <FormItem>
                                    <Label className={styles.bold}>{t('common.type')}</Label>
                                    <Controller
                                        render={({ onChange, value }) => {
                                            return (
                                                <Select
                                                    options={typesOptions}
                                                    isClearable
                                                    placeholder={t('common.type')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        onChange(data?.value ?? null);
                                                        onInputChange();
                                                    }}
                                                    value={typesOptions.find(x => x.value === value) ? { label: typesOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                                />
                                            );
                                        }}
                                        control={form.control}
                                        name="typeId"
                                        defaultValue={form.getValues('typeId')} />
                                </FormItem>
                            </Col>
                        </Row>
                    </FormItem>
                </div>
                <div className={styles.buttonsFooter}>
                    <FormItem>
                        <Button
                            text={t('common.remove')}
                            size={'normal'}
                            preset={'secondary'}
                            onClick={clearFilters}
                            type='reset' />
                        <Button
                            type='submit'
                            text={t('common.apply')}
                            size={'normal'}
                        />
                    </FormItem>
                </div>
            </div>
        </form>
    );
}

export default memo(SuppliersFiltersScreen);